import { Anchor } from '../Anchor/Anchor.js';
import { CatalogModificationStatus } from '../../common/utils/catalogUtils.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { Loading } from '../Loading/index.js';
import { VirtualCatalogInformationForStatus } from './VirtualCatalogInformation.js';
import { VirtualCatalogProductsSummary } from '../CatalogProductsSummary/VirtualCatalogProductSummary.js';
import { formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import { listOfDevicesMsg, publishedMsg, t } from '../../common/i18n/index.js';
import type { Catalog } from '../../generated/api/models.js';
import type { CatalogProduct } from '../../common/utils/catalogUtils.js';
import type { CommonError } from '../../common/types/errors.js';

import './VirtualCatalogDetails.scss';

export interface VirtualCatalogPublishedDetailsProps {
  breadCrumbs: JSX.Element;
  publishedDate: number | undefined;
  billingAccount: string;
  catalog: Catalog;
  products: Record<string, Array<CatalogProduct>>;
  companyName: string;
  onClickBack: () => void;
  errors?: CommonError[];
}

export const VirtualCatalogPublishedDetailsContent = ({
  publishedDate,
  billingAccount,
  catalog,
  products,
  onClickBack,
}: VirtualCatalogPublishedDetailsProps) => {
  return (
    <>
      <div className="ea-context-notification of-manage-catalog-draft">
        <div className="ea-context-notification__text">
          <div className="of-manage-catalog-draft__container">
            <div className="of-manage-catalog-draft__instructions">
              {t.JN25(publishedMsg)} {formatTimestampToDDMMYYYY(publishedDate)}
              <br />
              <span>
                <Anchor onClick={onClickBack}>{t.D1E9('Return')}</Anchor>
              </span>
            </div>
          </div>
        </div>
      </div>

      <VirtualCatalogInformationForStatus
        catalog={catalog}
        modificationStatus={CatalogModificationStatus.ACTIVE}
        billingAccount={billingAccount}
      />

      {!products || catalog.productCategories.some(value => !products[value]) ? (
        // load all category products first and then show product summary
        <Loading />
      ) : (
        <VirtualCatalogProductsSummary
          {...{
            products,
          }}
        />
      )}
    </>
  );
};

export const VirtualCatalogPublishedDetails = (props: VirtualCatalogPublishedDetailsProps) => {
  const { breadCrumbs, catalog, companyName } = props;

  return (
    <DetailsWrapper
      content={<VirtualCatalogPublishedDetailsContent {...props} />}
      detailsTop={breadCrumbs}
      id={`catalog-details-${catalog.catalogCode}`}
      classes={[`of-catalog-details`]}
      heading={catalog.name}
      headingBottom={companyName}
      headingTop={t.Z0HF(listOfDevicesMsg)}
      heroPicto="billing-account"
    />
  );
};
