import { AdditionalComments } from './AdditionalComments.js';
import { CtaComponent } from './CtaComponent.js';
import type { Question, ResultBlock } from './types.js';

type ResultComponentProps = {
  responses: number[];
  questions: Question[];
  results: ResultBlock[];
  targetBaseUrl: string;
};

export const ResultComponent = ({ responses, targetBaseUrl, questions, results }: ResultComponentProps) => {
  const score = responses.reduce((acc, item) => acc + 2 - item, 0);

  const fixesRequires = questions.some((question, index) => {
    const selection = responses[index];
    const limit = question.limit || 0;
    return 2 - selection <= limit;
  });

  let content = '';
  const sortedResults = results.sort((a, b) => b.passLimit - a.passLimit);
  if (sortedResults.length > 0) {
    if (!fixesRequires) {
      content = sortedResults[0].content;
    } else {
      const filteredResults = sortedResults.filter(item => item.passLimit <= score);
      if (filteredResults.length > 0) {
        content = filteredResults[0].content;
      }
    }
  }

  return (
    <section>
      <p className="ds-text--lead">{content}</p>
      <div>
        <AdditionalComments questions={questions} responses={responses} />
      </div>
      <CtaComponent responses={responses} targetBaseUrl={targetBaseUrl} />
    </section>
  );
};
