import { Loading } from '../Loading/Loading.js';
import { nameMsg, t, userInformationMsg } from '../../common/i18n/index.js';
import type { ContactHeader } from '../../generated/api/models.js';

interface CustomerOrderCustomerInfoParams {
  ordererContact: ContactHeader | undefined;
}

export const CustomerOrderCustomerInfo = ({ ordererContact }: CustomerOrderCustomerInfoParams) => {
  return ordererContact ? (
    <div className="of-customer-order-details__content--customer-info">
      <h3 className="ds-margin-bottom--1">{t.T1WX(userInformationMsg)}</h3>
      <div>
        <div>
          <strong>{t.VGFI(nameMsg)}</strong>
        </div>
        <div>{`${ordererContact.firstName} ${ordererContact.lastName}`}</div>
        <div>{ordererContact.email}</div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};
