import {
  DialogType,
  FiveGMobileBroadbandOfferCode,
  FiveGVoiceOfferCode,
  SubscriptionAdditionalServiceStatusEnum,
} from '../enums.js';
import {
  Subscription,
  SubscriptionAction,
  SubscriptionStatusType,
  SubscriptionType,
  TaapiTechnology,
} from '../../generated/api/models.js';
import {
  activeMsg,
  closedMsg,
  inDeliveryMsg,
  subChangePendingHeaderMsg,
  t,
  temporarilyClosedMsg,
} from '../i18n/index.js';
import { isTimestampDateInFuture } from './dateUtils.js';
import { suspendedCommercialProductCodes } from './commercialProductUtils.js';
import type { AddOn, SubscriptionHeader } from '../../generated/api/models.js';
import type { DialogParams } from '../types/dialog.js';
import type { SubscriptionStatus } from '../types/subscription.js';
import type { SubscriptionsState } from '../types/states.js';

export const getSubscriptionStatus = (
  sub?: Subscription | SubscriptionHeader,
  subActions?: SubscriptionAction[]
): SubscriptionStatus => {
  const subAction = subActions?.find(action => action.subscriptionId === sub?.subscriptionId);
  const statusEnum = SubscriptionAction.StatusEnum;

  if (subAction && [statusEnum.PENDING, statusEnum.PENDING_APPROVAL, statusEnum.ERROR].includes(subAction.status)) {
    // Subscription actions might have other actions as well than pending or error, but other actions should change
    // the value of the subscription's own status field too. Status ERROR means that the subscription action moved
    // to manual handling by sales people, so it's still ongoing.
    return {
      color: 'yellow',
      dsColor: 'warning',
      pendingActionType: subAction.subscriptionActionType,
      pendingActions: true,
      isChangeOfferAllowed: false,
      text: t.I9KT(subChangePendingHeaderMsg),
    };
  } else if ((sub as Subscription)?.details?.asset?.hasPendingActions) {
    // Asset in EOE
    return {
      color: 'yellow',
      dsColor: 'warning',
      pendingActions: true,
      isChangeOfferAllowed: false,
      text: t.I9KT(subChangePendingHeaderMsg),
    };
  }

  // TODO probably no need for this many statuses?
  switch (sub?.subscriptionStatus) {
    case SubscriptionStatusType.CREATED:
    case SubscriptionStatusType.IN_ACTIVATION:
      return {
        color: 'light-orange',
        dsColor: 'orange',
        pendingActions: false,
        isChangeOfferAllowed: false,
        text: t.W59S(inDeliveryMsg),
      };

    case SubscriptionStatusType.ACTIVE:
      return {
        color: 'linkblue',
        dsColor: 'blue-600',
        pendingActions: false,
        isChangeOfferAllowed: true,
        text: t.SFRK(activeMsg),
      };

    case SubscriptionStatusType.SUSPENDED:
      return {
        color: 'red',
        dsColor: 'danger',
        pendingActions: false,
        isChangeOfferAllowed: false,
        text: t.PC3E(temporarilyClosedMsg),
      };

    default:
      return {
        color: 'red',
        dsColor: 'danger',
        pendingActions: false,
        isChangeOfferAllowed: true,
        text: t.UFGV(closedMsg),
      };
  }
};

export const doActionIfNotPendingSubscription = (
  subscriptionStatus: SubscriptionStatus,
  onShowDialog: (params: DialogParams) => void,
  action: () => void
) => (subscriptionStatus.pendingActions ? onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING }) : action());

export const openSubscriptionDialog = (
  subscriptionStatus: SubscriptionStatus,
  onShowDialog: (params: DialogParams) => void,
  dialogParams: DialogParams
) => onShowDialog(subscriptionStatus.pendingActions ? { type: DialogType.SUBSCRIPTION_ACTION_PENDING } : dialogParams);

export const getTaapiTechnologyAsString = (taapiTechnology?: TaapiTechnology): string | undefined => {
  switch (taapiTechnology) {
    case TaapiTechnology.ADSL:
    case TaapiTechnology.ADSL_FTTB:
    case TaapiTechnology.ADSL_FTTC:
      return t.G07Y('ADSL');

    case TaapiTechnology.CRM_KTV:
    case TaapiTechnology.CABLE_MODEM:
      return t.R488('Cable');

    case TaapiTechnology.ETHERNET_FTTB:
    case TaapiTechnology.FTTB_ETH:
    case TaapiTechnology.FTTB_ETH_1G:
    case TaapiTechnology.FTTH:
    case TaapiTechnology.YKI:
    case TaapiTechnology.ETH_FTTB_FIB:
    case TaapiTechnology.YKI_FIB:
      return t.URFP('Optical fibre');

    case TaapiTechnology.VDSL_CO:
    case TaapiTechnology.VDSL2_FTTB:
    case TaapiTechnology.VDSL2_FTTC:
      return t.LG3L('VDSL');

    default:
      return undefined;
  }
};

export const isCommitmentPeriodActive = (endDateTimestamp?: number) =>
  endDateTimestamp ? isTimestampDateInFuture(endDateTimestamp) : false;

export const isInExtensionPeriod = (subscription: Subscription | SubscriptionHeader): boolean =>
  subscription.changeType === 'Extension Period';

export const commercialProductIdForHyllyliittyma = {
  MOBILE: 'a0d2e28a-8ad8-aea3-9929-02aa2498c2fe',
  MOBILE_BROADBAND: '3ca41f56-58b4-c6ca-5b5c-5e492f8b689e',
};

export const isBbOrMbbSubscription = (subscription: Subscription): boolean =>
  subscription.subscriptionType === SubscriptionType.BROADBAND ||
  subscription.subscriptionType === SubscriptionType.MOBILE_BROADBAND;

export const isHyllyLiittyma = (subscription: Subscription): boolean =>
  !!subscription.commercialProductCode && suspendedCommercialProductCodes.includes(subscription.commercialProductCode);

export const findSubscription = (subDisplayId: string, subscriptionsState?: SubscriptionsState) => {
  if (subscriptionsState?.items) {
    // Show primarily ACTIVE one and secondarily SUSPENDED for the same displayId.
    // Not sure though if it is possible that there's an active one and suspended one for the same displayId.
    const subs = subscriptionsState.items;
    const status = SubscriptionStatusType;
    return (
      subs.find(sub => sub.subscriptionDisplayId === subDisplayId && sub.subscriptionStatus === status.ACTIVE) ||
      subs.find(sub => sub.subscriptionDisplayId === subDisplayId && sub.subscriptionStatus === status.CREATED) ||
      subs.find(sub => sub.subscriptionDisplayId === subDisplayId && sub.subscriptionStatus === status.IN_ACTIVATION) ||
      subs.find(sub => sub.subscriptionDisplayId === subDisplayId && sub.subscriptionStatus === status.SUSPENDED)
    );
  }
  return undefined;
};

export const mobiilivarmenneAddonGroup = 'Certificate';

export const isMobileIdAddOn = (addOnGroup?: string): boolean => addOnGroup === mobiilivarmenneAddonGroup;

export const mobiilivarmenneNotInUseAddOn: AddOn = {
  addOnCode: SubscriptionAdditionalServiceStatusEnum.NOT_IN_USE,
  addOnGroup: mobiilivarmenneAddonGroup,
  addOnProductName: 'Mobiilivarmenne',
  addOnType: SubscriptionType.MOBILE_CERTIFICATE,
  display: true,
};

export const simCardChangeAllowed = (subscription: Subscription) =>
  subscription.subscriptionStatus !== SubscriptionStatusType.CREATED &&
  subscription.subscriptionStatus !== SubscriptionStatusType.IN_ACTIVATION;

export const hasEsimQrCode = (subscription: Subscription) =>
  Boolean(subscription.details?.mobile?.eSimQrActivationCode);

export const isSubscriptionInSfdc = (subscription: Subscription | SubscriptionHeader) =>
  Boolean(subscription.sourceSystem === Subscription.SourceSystemEnum.SFDC || subscription.migrated);

export const isFiveGPlusMbbOffer = (offerGuid = '') =>
  [FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_1000_M, FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_600_M].some(
    code => code === offerGuid
  );

export const isFiveGPlusVoiceOffer = (offerGuid = '') =>
  [FiveGVoiceOfferCode.Voice5gPlus1000Mbps, FiveGVoiceOfferCode.Voice5gPlus600Mbps].some(code => code === offerGuid);
