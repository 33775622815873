import { AttachVakio } from '../../../../../components/AttachVakio/AttachVakio.js';
import { ModelType, SubscriptionCategory } from '../../../../../common/enums.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { findSubscription } from '../../../../../common/utils/subscriptionUtils.js';
import {
  loadBillChannels,
  loadOnlineModelByModelType,
  loadSubscriptionAddOnRules,
  loadSubscriptionAddOnRulesMobilePbx,
  loadSubscriptions,
} from '../../../../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import type { State } from '../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const MobileSubAttachVakioPath = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { subscriptionId } = useParams<SubscriptionId>();
  const companyInfo = useSelector((state: State) => state?.selfservice?.companyInfo, deepEqual);
  const numberRanges = useSelector((state: State) => state.resources?.numberRanges, deepEqual);
  const subscriptionActions = useSelector((state: State) => state?.selfservice?.subscriptionActions, deepEqual);
  const voiceSubs = useSelector(({ selfservice }: State) => selfservice?.subscriptions?.voice || undefined, deepEqual);

  useEffect(() => {
    dispatch(loadSubscriptions({ category: SubscriptionCategory.VOICE, displayId: subscriptionId }));
    // Take to use when Ring Association UI is ready
    dispatch(loadOnlineModelByModelType(ModelType.Ring));
    dispatch(loadSubscriptionAddOnRulesMobilePbx());
    dispatch(loadSubscriptionAddOnRules());
    dispatch(loadBillChannels());
    dispatch(loadSubscriptionAddOnRules());
  }, [dispatch, pathname, subscriptionId]);

  if (!subscriptionId) {
    return null;
  }

  return (
    <AttachVakio
      category={SubscriptionCategory.VOICE}
      companyInfo={companyInfo}
      numberRanges={numberRanges}
      saving={Boolean(subscriptionActions?.saving)}
      subscription={findSubscription(subscriptionId, voiceSubs)}
    />
  );
};
