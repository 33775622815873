import { SubscriptionsLayout } from '../../../../../components/SubscriptionsLayout/SubscriptionsLayout.js';
import { SubscriptionsService } from '../../../../../components/Subscriptions/SubscriptionsService.js';
import { omaElisaForCompaniesMsg, servicesMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useState } from 'react';
import type { CommonError } from '../../../../../common/types/errors.js';

export const ServicesPath = () => {
  const [errors, setErrors] = useState<CommonError[]>([]);

  return (
    <SubscriptionsLayout
      breadCrumbs={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.MBG9(servicesMsg) },
      ]}
      errors={errors}
    >
      <SubscriptionsService setErrors={setErrors} />
    </SubscriptionsLayout>
  );
};
