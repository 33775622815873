import { HeaderSectionContent, SectionContent } from '../SectionContent.js';
import { SearchResultRow } from '../SearchResultRow.js';
import {
  billingAccountExtensionNameMsg,
  payerDetailsMsg,
  payerMsg,
  referenceMsg,
  t,
} from '../../../common/i18n/index.js';
import { generatePath } from 'react-router-dom';
import { getDeliveryMethodDisplayValue, isBillingAccountInSfdc } from '../../../common/utils/billingAccountUtils.js';
import { getResultObjectWithHighlightedValues } from '../highlightFunctions.js';
import { paths } from '../../../common/constants/pathVariables.js';
import type { BillingAccountHeader } from '../../../generated/api/billingAccountHeader.js';
import type { ClassicSiteResultProps, SearchResultBaseProps } from '../SearchResultRow.js';

export interface BillingAccountProps extends SearchResultBaseProps, ClassicSiteResultProps {
  billingAccount: BillingAccountHeader;
}

export const BillingAccount = ({
  billingAccount,
  classicSiteUrl,
  matchedFields,
  searchTerms,
  ...rest
}: BillingAccountProps) => {
  const {
    billingContactName,
    billingAccountName,
    billingAccountDisplayId,
    billingAccountExtensionName,
    payerName,
    customerReference1,
    customerReference2,
    customerReference3,
    customerReference4,
    payerNameExtension,
  } = getResultObjectWithHighlightedValues(
    // @ts-ignore
    billingAccount,
    matchedFields,
    searchTerms
  );
  const displayClassicSiteLink = !isBillingAccountInSfdc(billingAccount);
  const classicSiteLinkTarget = `${classicSiteUrl}/laskutustiedot/`;
  return (
    <SearchResultRow
      icon="draft"
      upperSection={<HeaderSectionContent left={billingAccountDisplayId} right={billingAccountName} />}
      lowerSection={
        <SectionContent
          left={
            billingContactName && (
              <>
                {t.VYZS('Contact person')}: {billingContactName}
              </>
            )
          }
          right={getDeliveryMethodDisplayValue(billingAccount.deliveryMethod!)}
        />
      }
      additionalHighlightableFields={[
        {
          title: t.KUTS(billingAccountExtensionNameMsg),
          value: billingAccountExtensionName,
          key: 'billingAccountExtensionName',
        },
        { title: t.PB6S(payerMsg), value: payerName, key: 'payerName' },
        { title: t.YLAI(payerDetailsMsg), value: payerNameExtension, key: 'payerNameExtension' },
        { title: `${t.ZLAU(referenceMsg)} 1`, value: customerReference1, key: 'customerReference1' },
        { title: `${t.ZLAU(referenceMsg)} 2`, value: customerReference2, key: 'customerReference2' },
        { title: `${t.ZLAU(referenceMsg)} 3`, value: customerReference3, key: 'customerReference3' },
        { title: `${t.ZLAU(referenceMsg)} 4`, value: customerReference4, key: 'customerReference4' },
      ]}
      matchedFields={matchedFields}
      displayClassicSiteLink={displayClassicSiteLink}
      linkHref={
        displayClassicSiteLink
          ? classicSiteLinkTarget
          : generatePath(paths.BILLING_ACCOUNT, { billingAccountId: billingAccount.billingAccountDisplayId! })
      }
      {...rest}
    />
  );
};
