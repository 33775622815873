import * as CL from '@design-system/component-library';
import { PRODUCT_DETAILS_CLASS } from '../productDetailsConstants.js';
import {
  addToCartMsg,
  monthlyChargesMsg,
  monthsMsg,
  singleFaresMsg,
  t,
  totalMsg,
  vatPercentageMsg,
} from '../../../common/i18n/index.js';
import { calculatePayments, valueOrZero } from '../utils/productDetailsUtils.js';
import { formatSum } from '../../../common/utils/priceUtils.js';
import { getVatAsString } from '../../../common/utils/taxUtils.js';
import type { CommercialProduct, Offer } from '../../../generated/api/models.js';
import type { ProductDetailsAddOnStateProperties } from './ProductDetailsAddOn.js';

import './ProductDetailsSummary.scss';

export interface ProductDetailsPriceSummaryProps {
  commercialProduct: CommercialProduct;
  offer: Offer;
  addToCart: () => void;
  quantity: number;
  isEmployee: boolean;
  addOns?: ProductDetailsAddOnStateProperties[];
}

/**
 * Show price summary always for employees. Price can be 0 even if the item is purchased
 * Normally Offer should not be needed here, but special conditions with subscription require it
 * as subscription price is created from at least two CommercialProducts
 */
export const ProductDetailsSummary = ({
  addToCart,
  commercialProduct,
  offer,
  quantity,
  isEmployee,
  addOns,
}: ProductDetailsPriceSummaryProps) => {
  const { payments, monthlyRecurringCharge, oneTimeCharge } = calculatePayments(
    commercialProduct,
    offer,
    quantity,
    addOns,
    isEmployee
  );

  const billingPeriod = commercialProduct.billingPeriod;

  return (
    <div
      className={`${PRODUCT_DETAILS_CLASS}-summary ds-font-size--large ds-padding-horizontal--3 ds-padding-vertical--3`}
    >
      <h3 className="ds-h4">{`${t.CEQ2(totalMsg)} (${t.A0OJ(
        vatPercentageMsg,
        isEmployee ? getVatAsString() : '0'
      )})`}</h3>
      {valueOrZero(monthlyRecurringCharge) > 0 || isEmployee ? (
        <div className={`${PRODUCT_DETAILS_CLASS}-summary__price ds-margin-top--4`}>
          <span>
            <span className="ds-display--block">
              {isEmployee ? t.HQC3('Monthly charges to you') : t.P6BC(monthlyChargesMsg)}
            </span>
            <span className="ds-display--block">
              {isEmployee && payments ? `(${payments}${t.XXVX(monthsMsg)})` : ''}
            </span>
          </span>
          <span className="ds-h4 ds-white-space--nowrap">
            {billingPeriod
              ? `${formatSum(monthlyRecurringCharge)}/${billingPeriod} ${t.XXVX(monthsMsg)}`
              : formatSum(monthlyRecurringCharge)}
          </span>
        </div>
      ) : null}
      {valueOrZero(oneTimeCharge) > 0 ? (
        <div className={`${PRODUCT_DETAILS_CLASS}-summary__price ds-margin-top--4`}>
          <span>{t.GOBY(singleFaresMsg)}</span>
          <span className="ds-h4 ds-white-space--nowrap">{formatSum(oneTimeCharge)}</span>
        </div>
      ) : null}
      {isEmployee ? (
        <div className={`${PRODUCT_DETAILS_CLASS}-summary__price ds-margin-top--4`}>
          <span>{t.CEQ2(totalMsg)}</span>
          <span className="ds-h4 ds-white-space--nowrap">
            {formatSum(valueOrZero(monthlyRecurringCharge) * payments!)}
          </span>
        </div>
      ) : null}
      <div className="ds-margin-top--4">
        <CL.Button onClick={addToCart} className={`${PRODUCT_DETAILS_CLASS}-summary__add-to-cart`}>
          {t.JFOS(addToCartMsg)}
        </CL.Button>
      </div>
    </div>
  );
};
