import * as React from 'react';
import { ModalCloseAndSubmitButtons } from '../Modal/ModalCloseAndSubmitButtons.js';
import { t } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useEffect } from 'react';
import type { ForgetPasswordWithForm } from './misc/forgotPasswordInterfaces.js';

export const ForgotPasswordFinishProcessStep = (props: ForgetPasswordWithForm) => {
  const { login } = useAuth();
  const { methods } = props;
  const [isLoading, setLoading] = React.useState(false);
  const [loginClicked, setLoginClicked] = React.useState(false);

  const onSubmit = ({ userName, newPassword }: { userName: string; newPassword: string }) => {
    setLoading(true);
    login(userName, newPassword);
    setLoginClicked(true);
  };

  useEffect(() => {
    return () => {
      if (props.unmountAction && loginClicked) {
        props.unmountAction();
      }
    };
  });

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
      <div className="of-forgot-password-step__header">
        <h3 className="ds-h3--book">{t.ZVDQ('Password changed successfully')}</h3>
      </div>

      <p>{t.CHUM('You can now login to OmaElisa Service.')}</p>

      <ModalCloseAndSubmitButtons
        onSubmit={() => {}}
        submitting={isLoading}
        submitButtonText={t.XOGB('Move to OmaElisa')}
      />
    </form>
  );
};
