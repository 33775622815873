import { HeaderSectionContent, SectionContent } from '../SectionContent.js';
import { SearchResultRow } from '../SearchResultRow.js';
import { descriptionMsg, invoiceNumberMsg, requestorsEmailMsg, resolutionMsg, t } from '../../../common/i18n/index.js';
import { featureTranslation, statusData } from '../../../common/utils/supportCaseUtils.js';
import { formatTimestampToDDMMYYYY } from '../../../common/utils/dateUtils.js';
import { generatePath } from 'react-router-dom';
import { getResultObjectWithHighlightedValues } from '../highlightFunctions.js';
import { getSearchSnippetText } from '../../../common/utils/stringUtils.js';
import { paths } from '../../../common/constants/pathVariables.js';
import type { SearchResultBaseProps } from '../SearchResultRow.js';
import type { SupportCaseHeader } from '../../../generated/api/supportCaseHeader.js';

export interface SupportCaseProps extends SearchResultBaseProps {
  supportCase: SupportCaseHeader;
}

export const SupportCase = ({ supportCase, matchedFields, searchTerms, ...rest }: SupportCaseProps) => {
  const descriptionSnippet =
    supportCase.description && searchTerms && getSearchSnippetText(supportCase.description, searchTerms);
  const resolutionSnippet =
    supportCase.resolution && searchTerms && getSearchSnippetText(supportCase.resolution, searchTerms);
  const { firstName, lastName, email, supportCaseDisplayId, description, resolution, invoiceNumber, featureType } =
    getResultObjectWithHighlightedValues(
      {
        ...supportCase,
        description: descriptionSnippet,
        resolution: resolutionSnippet,
      },
      matchedFields,
      searchTerms
    );
  const { color, text } = statusData(supportCase.status);
  return (
    <SearchResultRow
      icon="draft"
      upperSection={
        <HeaderSectionContent
          left={supportCaseDisplayId}
          right={
            <>
              {featureTranslation(supportCase.feature)}
              {featureType && ': '}
              {featureType}
            </>
          }
        />
      }
      lowerSection={
        <SectionContent
          statusColor={color}
          left={`${text} ${formatTimestampToDDMMYYYY(supportCase.lastModified)}`}
          right={
            <>
              {t.VHFX('Requestor')}: {firstName} {lastName}
            </>
          }
        />
      }
      additionalHighlightableFields={[
        { title: t.DYKC(requestorsEmailMsg), value: email, key: 'email' },
        { title: t.HBW3(descriptionMsg), value: description, key: 'description' },
        { title: t.YOW5(resolutionMsg), value: resolution, key: 'resolution' },
        { title: t.BRFX(invoiceNumberMsg), value: invoiceNumber, key: 'invoiceNumber' },
      ]}
      matchedFields={matchedFields}
      linkHref={generatePath(paths.SUPPORT_CASE, { supportCaseDisplayId: supportCase.supportCaseDisplayId })}
      {...rest}
    />
  );
};
