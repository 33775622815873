import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';
import { pictureStorageBaseUrl } from '../Picture/contentfulImageProxy.js';
import type { PropsWithChildren } from 'react';

import './ImageCards.scss';

type ImageCardProps = PropsWithChildren<{
  image: string;
  title: string;
  buttonText?: string;
  imageSize: 's' | 'm' | 'l';
  type: 'dark' | 'transparent';
  url?: string;
}>;

export const ImageCard = ({
  buttonText,
  children,
  image,
  imageSize,
  title,
  type,
  url,
}: ImageCardProps): JSX.Element => (
  <CL.Card
    content={
      <>
        <img className={`of-card-image--${imageSize}`} alt="" src={`${pictureStorageBaseUrl}${image}`} />
        <div>
          {url != null && buttonText == null ? (
            <Link to={url}>
              <h3>{title}</h3>
            </Link>
          ) : (
            <h3>{title}</h3>
          )}
          <p>{children}</p>
          {buttonText != null && url != null && (
            <Link
              className="ds-link ds-link--button-color-primary ds-link--button-size-l ds-link--style-link-button"
              to={url}
            >
              {buttonText}
            </Link>
          )}
        </div>
      </>
    }
    type={type}
  />
);

type ImageCardsProps = {
  imageSize?: 's' | 'm' | 'l';
  items: {
    buttonText?: string;
    content: string;
    image: string;
    title: string;
    url?: string;
  }[];
  threeWide?: boolean;
  type: 'dark' | 'transparent';
};

// TODO replace this with new CMS element(s) if possible
export const ImageCards = ({ imageSize = 's', items, threeWide, type }: ImageCardsProps): JSX.Element => (
  <CL.CardContainer className={`of-image-cards ${threeWide ? 'three-wide' : ''}`}>
    {items.map((item, idx) => (
      <ImageCard
        buttonText={item.buttonText}
        imageSize={imageSize}
        image={item.image}
        key={idx}
        title={item.title}
        type={type}
        url={item.url}
      >
        {item.content}
      </ImageCard>
    ))}
  </CL.CardContainer>
);
