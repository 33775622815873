import * as CL from '@design-system/component-library';
import { GridColumn } from '../../BillingAccountDetails/components/GridColumn.js';
import { cancelMsg, confirmMsg, t } from '../../../common/i18n/index.js';
import { useFormikContext } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import type { BillingAccount } from '../../../generated/api/models.js';
import type { BillingAccountCreateFormValues } from '../CreateBillingAccount.js';
import type { FormikProps } from 'formik';

interface SaveOrCancelCreateBillingAccountProps {
  submitBillingAccountDetails: (values: BillingAccount) => void;
  emptyBillingAccount: BillingAccount;
  isSaving: boolean;
  subscriptionId?: string;
}

export const SaveOrCancelCreateBillingAccount = ({
  submitBillingAccountDetails,
  emptyBillingAccount,
  isSaving,
}: SaveOrCancelCreateBillingAccountProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { values, isSubmitting } = useFormikContext<FormikProps<BillingAccountCreateFormValues>>();

  return (
    <CL.GridRow className="ds-padding-bottom--8">
      <GridColumn className="of-billing-account-details form-column edit-field bottom">
        <div>
          <CL.Button
            id="submitBillingAccount"
            className="ds-margin-right--8"
            disabled={isSubmitting || isSaving}
            onClick={() => {
              submitBillingAccountDetails({ ...emptyBillingAccount, ...values });
            }}
          >
            {t.QVYK(confirmMsg)}
          </CL.Button>
          <CL.Button
            id="cancelCreateBillingAccount"
            color="light"
            onClick={() => {
              navigate(location.state.originURL, { state: location.state });
            }}
          >
            {t.B2V1(cancelMsg)}
          </CL.Button>
        </div>
      </GridColumn>
    </CL.GridRow>
  );
};
