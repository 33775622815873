import * as CL from '@design-system/component-library';
import { t } from '../../common/i18n/index.js';

import './OrderNewsLetter.scss';

const TEXT_SIZES = {
  colWidthXS: 4,
  colWidthS: 6,
  colWidthM: 3,
  colWidthL: 3,
  colWidthXL: 3,
} as const;

const FORM_SIZES = {
  colWidthXS: 4,
  colWidthS: 6,
  colWidthM: 3,
  colWidthL: 5,
  colWidthXL: 5,
} as const;

export const OrderNewsLetter = () => {
  return (
    <CL.Grid className="ds-grid of-order-newsletter">
      <CL.GridRow justifyCenter>
        <CL.GridCol className="ds-grid__col" {...TEXT_SIZES}>
          <h3>{t.ZV9E('Stay informed!')}</h3>
          <p>{t.W77N('Subscribe to our newsletter.')}</p>
        </CL.GridCol>
        <CL.GridCol className="ds-grid__col" {...FORM_SIZES}>
          <iframe title="uutiskirje" src="https://cloud.viestinta.elisa.fi/sa-newsletter" />
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};
