import { ActionPhase } from '../common/storeUtils.js';
import { InProgressCatalogAction } from '../../common/enums.js';
import { TypeKeys } from '../actions/index.js';
import { getErrorsFromUpsertBillingAccount } from '../../common/utils/billingAccountUtils.js';
import { getErrorsFromVirtualCatalogDetails } from '../../common/utils/catalogUtils.js';
import { getPreviousActionState, updateActionStatePhase } from '../common/index.js';
import {
  getSortedItems,
  mergeArrays,
  reduceCrudAction,
  reduceDisplayItemsLoadAction,
  validateLoadActionFulfilledResponseCounts,
} from './reducerUtils.js';
import type { ActionsHistory } from '../common/store.js';
import type { CatalogsState, VirtualCatalogsState } from '../../common/types/states.js';
import type { CommonError } from '../../common/types/errors.js';
import type { SelfServiceActionTypes } from '../actions/index.js';
import type { VirtualCatalog } from '../../generated/api/models.js';

const sortByLastModifiedAscVirtualCatalog = (a: VirtualCatalog, b: VirtualCatalog) =>
  (a.lastModified ?? 0) - (b.lastModified ?? 0);

export function catalogsReducer(
  state: (CatalogsState & ActionsHistory) | undefined | null,
  action: SelfServiceActionTypes
): (CatalogsState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.RESET_ERRORS:
      return {
        ...state,
        errors: undefined,
        saving: false,
      };

    case TypeKeys.SWITCH_ACCOUNT_FULFILLED:
    case TypeKeys.LOG_OUT: {
      return null;
    }

    default:
      return state;
  }
}

export function virtualCatalogsReducer(
  state: (VirtualCatalogsState & ActionsHistory) | undefined | null,
  action: SelfServiceActionTypes
): (VirtualCatalogsState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.LOAD_VIRTUAL_CATALOGS: {
      const itemsState = reduceDisplayItemsLoadAction<TypeKeys.LOAD_VIRTUAL_CATALOGS, VirtualCatalog>(
        action,
        state,
        'virtualCatalogCode',
        true,
        action.forceLoad ? 0 : undefined,
        action.forceLoad
      );
      return {
        ...itemsState,
        items: action.forceLoad ? undefined : state ? state.items : undefined,
      };
    }

    case TypeKeys.LOAD_VIRTUAL_CATALOGS_FULFILLED: {
      const actionState = getPreviousActionState(TypeKeys.LOAD_VIRTUAL_CATALOGS, state, ActionPhase.IN_PROGRESS)!;
      const catalogs = mergeArrays<VirtualCatalog>('virtualCatalogCode', 'lastModified', state!.items, action.catalogs);
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_VIRTUAL_CATALOGS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: validateLoadActionFulfilledResponseCounts(
          actionState!.query!,
          action.total,
          action.catalogs,
          state!.errors
        ),
        items: catalogs.sort(sortByLastModifiedAscVirtualCatalog),
        total: action.total,
      };
    }

    case TypeKeys.LOAD_VIRTUAL_CATALOGS_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_VIRTUAL_CATALOGS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.DELETE_VIRTUAL_CATALOG: {
      return {
        ...reduceCrudAction(action, state),
      };
    }

    case TypeKeys.DELETE_VIRTUAL_CATALOG_FULFILLED: {
      const virtualCatalogs: VirtualCatalog[] = state?.items ?? [];
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.DELETE_VIRTUAL_CATALOG,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: undefined,
        items: virtualCatalogs.filter(
          virtualCatalog => virtualCatalog.virtualCatalogCode !== action.virtualCatalogCode
        ),
        total: state!.total! - 1,
      };
    }

    case TypeKeys.DELETE_VIRTUAL_CATALOG_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.DELETE_VIRTUAL_CATALOG,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.DELETE_VIRTUAL_CATALOG_DRAFT: {
      return {
        ...reduceCrudAction(action, state),
        inProgressAction: InProgressCatalogAction.DELETE_VIRTUAL_CATALOG_DRAFT,
      };
    }

    case TypeKeys.DELETE_VIRTUAL_CATALOG_DRAFT_FULFILLED: {
      const virtualCatalogs: VirtualCatalog[] = state?.items ?? [];
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.DELETE_VIRTUAL_CATALOG_DRAFT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: undefined,
        items: virtualCatalogs.map(virtualCatalog => {
          if (
            virtualCatalog.virtualCatalogCode === action.virtualCatalogCode &&
            virtualCatalog.draft?.catalogCode === action.catalogCode
          ) {
            virtualCatalog.draft = undefined;
          }
          return virtualCatalog;
        }),
        inProgressAction: undefined,
      };
    }

    case TypeKeys.DELETE_VIRTUAL_CATALOG_DRAFT_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.DELETE_VIRTUAL_CATALOG_DRAFT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        inProgressAction: undefined,
      };
    }

    case TypeKeys.PUBLISH_VIRTUAL_CATALOG_DRAFT: {
      return {
        ...reduceCrudAction(action, state),
        inProgressAction: InProgressCatalogAction.PUBLISH_VIRTUAL_CATALOG_DRAFT,
      };
    }

    case TypeKeys.PUBLISH_VIRTUAL_CATALOG_DRAFT_FULFILLED: {
      const virtualCatalogs: VirtualCatalog[] = state?.items ?? [];
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.PUBLISH_VIRTUAL_CATALOG_DRAFT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: undefined,
        items: virtualCatalogs.map(virtualCatalog => {
          if (virtualCatalog.virtualCatalogCode === action.virtualCatalogCode) {
            virtualCatalog.published = virtualCatalog.draft;
            virtualCatalog.draft = undefined;
          }
          return virtualCatalog;
        }),
        inProgressAction: undefined,
      };
    }

    case TypeKeys.PUBLISH_VIRTUAL_CATALOG_DRAFT_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.PUBLISH_VIRTUAL_CATALOG_DRAFT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        inProgressAction: undefined,
      };
    }

    case TypeKeys.UPSERT_VIRTUAL_CATALOG: {
      if (action.overridePreviousAction) {
        const actions = updateActionStatePhase(
          TypeKeys.UPSERT_VIRTUAL_CATALOG,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        );

        return {
          ...state,
          actions: actions
            ? [
                ...actions,
                {
                  phase: ActionPhase.IN_PROGRESS,
                  value: action,
                },
              ]
            : undefined,
          inProgressAction: action.publishCatalog
            ? InProgressCatalogAction.PUBLISH_VIRTUAL_CATALOG_DRAFT
            : InProgressCatalogAction.UPSERT_VIRTUAL_CATALOG_DRAFT,
        };
      }

      const errors: CommonError[] | undefined = getErrorsFromVirtualCatalogDetails(action.catalog);
      if (errors) {
        return {
          ...state,
          errors: errors,
          inProgressAction: undefined,
        };
      }
      let billingAccountHasErrors: boolean | undefined = false;
      billingAccountHasErrors = action.newBillingAccount
        ? !!getErrorsFromUpsertBillingAccount(
            action.newBillingAccount,
            action.newBillingAccountValidationErrors,
            action.newBillingAccountCommonFunction
          )
        : action.billingAccountRequired && !action.catalog.billingAccountId;
      if (billingAccountHasErrors) {
        return {
          ...state,
          errors: undefined,
          inProgressAction: undefined,
        };
      } else {
        return {
          ...reduceCrudAction(action, state),
          errors: undefined,
          inProgressAction: action.publishCatalog
            ? InProgressCatalogAction.PUBLISH_VIRTUAL_CATALOG_DRAFT
            : InProgressCatalogAction.UPSERT_VIRTUAL_CATALOG_DRAFT,
        };
      }
    }

    case TypeKeys.UPSERT_VIRTUAL_CATALOG_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.UPSERT_VIRTUAL_CATALOG,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: undefined,
        items: getSortedItems(
          {
            ...action.virtualCatalog,
            draft: { ...action.virtualCatalog.draft, ...action.catalog },
          },
          action.isCreate,
          'virtualCatalogCode',
          state!.items
        ),
        total: action.isCreate ? state!.total! + 1 : state!.total,
        editingCatalog: undefined,
        inProgressAction: action.publishCatalog ? InProgressCatalogAction.PUBLISH_VIRTUAL_CATALOG_DRAFT : undefined,
      };
    }

    case TypeKeys.UPSERT_VIRTUAL_CATALOG_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.UPSERT_VIRTUAL_CATALOG,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        inProgressAction: undefined,
      };
    }

    case TypeKeys.COPY_VIRTUAL_CATALOG: {
      return {
        ...reduceCrudAction(action, state),
      };
    }

    case TypeKeys.COPY_VIRTUAL_CATALOG_FULFILLED: {
      if (action.catalog && action.virtualCatalog) {
        const newCatalog = {
          ...action.virtualCatalog,
          draft: { ...action.virtualCatalog.draft, ...action.catalog },
        };
        return {
          ...state,
          actions: updateActionStatePhase(
            TypeKeys.COPY_VIRTUAL_CATALOG,
            state!,
            ActionPhase.IN_PROGRESS,
            ActionPhase.SUCCESS
          ),
          errors: undefined,
          items: getSortedItems(newCatalog, true, 'virtualCatalogCode', state!.items),
          total: state!.total! + 1,
        };
      } else {
        return {
          ...state,
          actions: updateActionStatePhase(
            TypeKeys.COPY_VIRTUAL_CATALOG,
            state!,
            ActionPhase.IN_PROGRESS,
            ActionPhase.SUCCESS
          ),
          errors: undefined,
        };
      }
    }

    case TypeKeys.COPY_VIRTUAL_CATALOG_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.COPY_VIRTUAL_CATALOG,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.SET_SELECTED_VIRTUAL_CATALOG: {
      return {
        ...state,
        selectedCatalogCode: action.selectedCatalogCode,
      };
    }

    case TypeKeys.SET_EDIT_VIRTUAL_CATALOG: {
      return {
        ...state,
        editingCatalog: action.editingCatalog,
      };
    }

    case TypeKeys.SWITCH_ACCOUNT_FULFILLED:
    case TypeKeys.LOG_OUT: {
      return null;
    }

    default:
      return state;
  }
}
