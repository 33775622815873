import * as CL from '@design-system/component-library';
import { PbxType, SubscriptionPbxDetails, SubscriptionStatusType } from '../../generated/api/models.js';
import { SubscriptionDetailsMobileTermination } from './SubscriptionDetailsMobileTermination.js';
import { SuspendSubscriptionOptions } from '../SuspendSubscriptionOptions/SuspendSubscriptionOptions.js';
import { TextWithPopover } from '../TextWithPopover/TextWithPopover.js';
import {
  billingAccountMsg,
  durationOfContractMsg,
  formatNumber,
  idNumberMsg,
  listPriceMsg,
  openMsg,
  pukCodeMsg,
  subscriptionStatusMsg,
  subscriptionTypeMsg,
  t,
} from '../../common/i18n/index.js';
import {
  changeOffer,
  closeSuspendSubscriptionOptions,
  closeTerminateSubscriptionForm,
  suspendSubscription,
} from '../../selfservice/actions/subscriptionActionsActions.js';
import {
  commercialProductIdForHyllyliittyma,
  doActionIfNotPendingSubscription,
  isCommitmentPeriodActive,
  isMobileIdAddOn,
} from '../../common/utils/subscriptionUtils.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getCommitmentText } from '../EmployeeSubscriptionDetails/eppSubscriptionUtils.js';
import { getPeriodicPriceAsTextFromDetails, getSubscriptionStatus } from './subscriptionDetailsCommon.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type {
  AddOn,
  Subscription,
  SubscriptionDetails,
  SubscriptionDetailsMobile as SubscriptionDetailsMobileTypes,
} from '../../generated/api/models.js';
import type { AssociationRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { DialogParams } from '../../common/types/dialog.js';
import type { Dispatch } from 'redux';
import type { State } from '../../selfservice/common/store.js';
import type { StatusFieldAction } from './subscriptionDetailsCommon.js';
import type { SubscriptionStatus } from '../../common/types/subscription.js';
import type { TerminateType } from '../../common/enums.js';

import './subscriptionDetailsMobile.scss';

const SubscriptionFupPopOver = () => {
  return (
    <TextWithPopover
      className="of-subscription-details__popover"
      textContent={t.QH8P(subscriptionTypeMsg)}
      placement="top"
    >
      <p>
        {t.FK90(
          'You can use the internet connection in Europe at domestic prices up to the reasonable usage limit of your subscription.'
        )}
      </p>
      <p>{t.WP9V('Examples:')}</p>
      <ul>
        <li>
          Elisa Yritysliittymä 5G Premium (1000M), {formatNumber(70)} {t.YAB3('GB/mo.')}
        </li>
        <li>
          Elisa Yritysliittymä 5G Perus (600M), {formatNumber(58)} {t.YAB3('GB/mo.')}
        </li>
        <li>
          Elisa Yritysliittymä 5G Perus (300M), {formatNumber(55.5)} {t.YAB3('GB/mo.')}
        </li>
        <li>
          Elisa Yritysliittymä 5G Mini (50M), {formatNumber(38)} {t.YAB3('GB/mo.')}
        </li>
        <li>
          Elisa Yritysliittymä 5G Mini (10M), {formatNumber(35)} {t.YAB3('GB/mo.')}
        </li>
      </ul>
      <p className="ds-font-size--small">
        {t.O854(
          'When you arrive abroad, you will receive a text message about your reasonable use limit. You will also receive a text message when your data usage limit is about to be reached. After this, you pay 1,55 €/Gb (gigabyte) for data transfer.'
        )}
      </p>
    </TextWithPopover>
  );
};

interface SubscriptionDetailsMobileProps {
  dispatch: Dispatch;
  onShowDialog: (params: DialogParams) => void;
  subscription: Subscription;
  subscriptionStatus: SubscriptionStatus;
  details: SubscriptionDetails;
  showSuspendSubscriptionOptions: boolean;
  showTerminateSubscriptionForm: boolean;
  onTerminateSubscription: (
    subscriptionId: string,
    effectiveDate: number,
    donateNumber?: boolean,
    terminateType?: TerminateType
  ) => void;
  onResumeSubscription: (subscriptionId: string) => void;
  billingAccountSelection?: JSX.Element | undefined;
  mobileDetails?: SubscriptionDetailsMobileTypes;
  addOnRulesAssociations?: AssociationRecord[];
}

export const SubscriptionDetailsMobile = (props: SubscriptionDetailsMobileProps) => {
  const {
    onShowDialog,
    subscription,
    subscriptionStatus,
    details,
    showSuspendSubscriptionOptions,
    showTerminateSubscriptionForm,
    onTerminateSubscription,
    onResumeSubscription,
    billingAccountSelection,
    mobileDetails,
  } = props;
  const addOnRulesAssociations: AssociationRecord[] | undefined = useSelector(
    (state: State) => state?.selfservice?.subscriptionAddOnRules?.associations,
    deepEqual
  );
  const subscriptionActions = useSelector(
    (state: State) => state?.selfservice?.pendingSubscriptionActions || undefined,
    deepEqual
  );
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(closeTerminateSubscriptionForm());
    };
  }, [dispatch]);

  const subscriptionType = subscription.subscriptionType as 'MOBILE' | 'MOBILE_BROADBAND';

  useEffect(() => {
    return () => {
      dispatch(closeTerminateSubscriptionForm());
    };
  }, [dispatch]);

  if (showTerminateSubscriptionForm) {
    return (
      <SubscriptionDetailsMobileTermination
        onShowDialog={onShowDialog}
        subscription={subscription}
        onTerminateSubscription={onTerminateSubscription}
        terminateSubscriptionLoading={subscriptionActions?.terminationInProgress || false}
      />
    );
  } else if (showSuspendSubscriptionOptions) {
    const hyllyliittymaAddonCodes =
      addOnRulesAssociations
        ?.filter(assoc => assoc.cspmb__price_item__r.guid__c === commercialProductIdForHyllyliittyma[subscriptionType])
        .filter(assoc => assoc.cspmb__default_quantity__c === 1)
        .map(assoc => assoc.cspmb__add_on_price_item__r.guid__c) || [];

    const selectedAddons: AddOn[] = subscription?.details?.selectedAddOns || [];
    const currentAddonCodes: string[] = selectedAddons.map(addon => addon.addOnCode);
    const isVakio =
      (subscription.compatiblePbxTypes &&
        subscription.compatiblePbxTypes.includes(PbxType.MOBILE_PBX_LITE) &&
        subscription.details?.mobile?.pbxConfiguration?.pbxConfigurationDetails.pbxType ===
          SubscriptionPbxDetails.PbxTypeEnum.VAKIO) ||
      false;
    const hasMobiiliVarmenne = selectedAddons.some(({ addOnGroup, display }) => display && isMobileIdAddOn(addOnGroup));

    return (
      <SuspendSubscriptionOptions
        hasMobiiliVarmenne={hasMobiiliVarmenne}
        onConfirmSuspend={() =>
          dispatch(suspendSubscription(subscription.subscriptionId, subscription.subscriptionDisplayId))
        }
        onConfirmHyllyliittyma={() =>
          dispatch(
            changeOffer(
              subscription.subscriptionId,
              subscription.subscriptionDisplayId,
              subscription.subscriptionType,
              commercialProductIdForHyllyliittyma[subscriptionType],
              hyllyliittymaAddonCodes,
              currentAddonCodes,
              []
            )
          )
        }
        onCloseSuspendSubscriptionOptions={() => dispatch(closeSuspendSubscriptionOptions())}
        isVakio={isVakio}
        isFixedTermSubscription={isCommitmentPeriodActive(subscription.details?.commitmentEndDate)}
      />
    );
  } else {
    const linkAction: StatusFieldAction | undefined =
      subscription.subscriptionStatus === SubscriptionStatusType.SUSPENDED && !subscriptionStatus.pendingActions
        ? {
            link: () =>
              doActionIfNotPendingSubscription(subscriptionStatus, onShowDialog, () =>
                onResumeSubscription(subscription.subscriptionId)
              ),
            text: t.K9K7(openMsg),
          }
        : undefined;

    const puk1: CL.DescriptionItem = {
      title: t.K5TL(pukCodeMsg),
      description: mobileDetails?.puk1,
    };
    const puk2: CL.DescriptionItem = {
      title: t.WHH4('PUK2-code'),
      description: mobileDetails?.puk2,
    };

    const pukItems: Array<CL.DescriptionItem> = mobileDetails ? [puk1, puk2] : [];

    return (
      <CL.Description
        id="subscription-details-mobile-grid"
        className="ds-margin--0 of-subscription-details__description"
        items={[
          {
            title: t.LROP(subscriptionStatusMsg),
            description: getSubscriptionStatus(subscriptionStatus, linkAction),
          },
          ...pukItems,
          { title: t.YTH3(idNumberMsg), description: subscription.subscriptionDisplayId },
          { title: t.IFT9(billingAccountMsg), description: billingAccountSelection },
          { title: t.CAA9(durationOfContractMsg), description: getCommitmentText(details) },
          {
            title: <SubscriptionFupPopOver />,
            description: subscription.subscriptionName,
          },
          {
            title:
              details.monthlyRecurringListCharge &&
              details.monthlyRecurringCharge &&
              details.monthlyRecurringListCharge !== details.monthlyRecurringCharge
                ? t.SYWR('Campaign price')
                : t.N68W(listPriceMsg),
            description: getPeriodicPriceAsTextFromDetails(details),
          },
        ]}
      />
    );
  }
};
