import * as CL from '@design-system/component-library';
import { Grid } from '../Grid/Grid.js';
import { OpenCasesNotification } from '../OpenCasesNotification/OpenCasesNotification.js';
import { SiteContext } from '../../public/site/SiteContext.js';
import {
  invoiceAdditionalInfoMsg,
  invoiceReferenceNumberDisclaimerMsg,
  invoiceStatusUpdateMsg,
  t,
} from '../../common/i18n/index.js';
import { useContext } from 'react';
import type { SupportCaseHeader } from '../../generated/api/models.js';

export interface SupportCaseNotificationProps {
  openSupportCases?: SupportCaseHeader[];
}

export const SupportCaseNotification = ({ openSupportCases = [] }: SupportCaseNotificationProps) => {
  const { siteBaseUrl } = useContext(SiteContext);

  return (
    <Grid>
      <CL.Disclaimer className="ds-margin-top--4">
        <div>{t.MQQC(invoiceStatusUpdateMsg)}</div>
        <div className="ds-margin-top--2">{t.ABRO(invoiceReferenceNumberDisclaimerMsg)}</div>
        <div className="ds-margin-top--2">
          {t.G85J(invoiceAdditionalInfoMsg)}{' '}
          <a href={`${siteBaseUrl}/ohjeet/laskut`} target="_blank" rel="noreferrer">
            {`${siteBaseUrl}/ohjeet/laskut`}.
          </a>
        </div>
      </CL.Disclaimer>
      {openSupportCases.length > 0 && <OpenCasesNotification supportCases={openSupportCases} />}
    </Grid>
  );
};
