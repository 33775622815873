import { InvoiceDetails } from '../../../../../components/InvoiceDetails/InvoiceDetails.js';
import { InvoiceSystemError } from '../../../../../components/Invoices/Invoices.js';
import { useLoaderData } from 'react-router-dom';
import { useStore } from 'react-redux';
import type { InvoiceLoaderResponse } from '../../../../../common/loaders.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const InvoicePath = () => {
  const store = useStore<State>();
  const config = store.getState().config;
  const { invoice, billChannels, openSupportCases, billingAccount } = useLoaderData() as InvoiceLoaderResponse;

  return (
    <InvoiceSystemError>
      <InvoiceDetails
        config={config}
        billChannels={billChannels}
        billingAccount={billingAccount}
        invoice={invoice}
        openSupportCases={openSupportCases}
      />
    </InvoiceSystemError>
  );
};
