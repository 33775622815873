import * as CL from '@design-system/component-library';
import { BarringsAccordionContent } from '../BarringsAccordionContent/BarringsAccordionContent.js';
import { DialogType } from '../../common/enums.js';
import { LinkableAccordion } from '../LinkableAccordion/index.js';
import { Loading } from '../Loading/index.js';
import { PbxAccordions } from './PbxAccordions.js';
import { PbxNumberSettingsAccordionContent } from '../PbxNumberSettingsAccordionContent/PbxNumberSettingsAccordionContent.js';
import { PbxTimeSettingsAccordionContent } from '../PbxTimeSettingsAccordionContent/PbxTimeSettingsAccordionContent.js';
import {
  PbxType,
  Subscription,
  SubscriptionAction,
  SubscriptionPbxDetails,
  SubscriptionType,
} from '../../generated/api/models.js';
import { SimCard } from '../SimCard/SimCard.js';
import {
  additionalServicesMsg,
  barringServicesMsg,
  connectToRingMsg,
  connectToVakioMsg,
  directoryStatusOfUserAndNumberMsg,
  simCardMsg,
  t,
} from '../../common/i18n/index.js';
import { hasEsimQrCode, simCardChangeAllowed } from '../../common/utils/subscriptionUtils.js';
import { isAttachingRingValid, isRejectedAddOns } from './subscriptionDetailsPbxCommon.js';
import { useDispatch, useSelector } from 'react-redux';
import type { CommonError } from '../../common/types/errors.js';
import type {
  CompanyInfoState,
  ConfigState,
  RangeNumbersState,
  SubscriptionActionsState,
} from '../../common/types/states.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { FunctionComponent } from 'react';
import type { PbxAccordionsProps } from './PbxAccordions.js';
import type { ServiceLevel } from '../AttachRing/ServiceLevelAndAddonsInterfaces.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionStatus } from '../../common/types/subscription.js';

import './MobileSubscriptionAccordions.scss';

export interface AccordionProps {
  errors?: CommonError[];
  subscription: Subscription;
  configuredPbxSolution?: Subscription;
  subscriptionActions?: SubscriptionActionsState;
  onEditSectionIfNoActionsPending: (section?: string) => boolean;
  editingSection?: string;
}

interface PbxLiteAccordionsProps extends AccordionProps {
  config: ConfigState;
  pbxSolutions: Subscription[];
  numberRange?: RangeNumbersState;
  extensionNumberRange?: RangeNumbersState;
  forceEditingFor?: string;
}

const PbxLiteAccordions: FunctionComponent<PbxLiteAccordionsProps> = ({
  config,
  forceEditingFor,
  errors,
  subscription,
  numberRange,
  onEditSectionIfNoActionsPending,
  configuredPbxSolution,
  editingSection,
  pbxSolutions,
  subscriptionActions,
  extensionNumberRange,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <LinkableAccordion heading={t.TC62('Vakio time settings')} headingLevel="h3" id="pbx-time-settings">
        <PbxTimeSettingsAccordionContent
          dispatch={dispatch}
          config={config}
          editing={forceEditingFor === 'pbxTimeSettings' || editingSection === 'pbxTimeSettings'}
          errors={errors}
          subscriptionPbxConfiguration={subscription.details!.mobile!.pbxConfiguration!}
          numberRange={numberRange}
          onCancel={() => onEditSectionIfNoActionsPending()}
          onEdit={() => onEditSectionIfNoActionsPending('pbxTimeSettings')}
          pbxSolution={configuredPbxSolution!}
          saving={Boolean(subscriptionActions?.saving)}
          subscriptionId={subscription.subscriptionId}
          companySubscription={pbxSolutions!.find(
            sub => sub.subscriptionId === subscription.details!.mobile!.pbxConfiguration!.pbxSolutionId
          )}
        />
      </LinkableAccordion>
      <LinkableAccordion heading={t.ZVIL('Vakio number settings')} headingLevel="h3" id="pbx-number-settings">
        <PbxNumberSettingsAccordionContent
          config={config}
          editing={forceEditingFor === 'pbxNumberSettings' || editingSection === 'pbxNumberSettings'}
          errors={errors}
          extensionNumberRange={extensionNumberRange}
          subscriptionPbxConfiguration={subscription.details!.mobile!.pbxConfiguration!}
          numberRange={numberRange}
          onCancel={() => onEditSectionIfNoActionsPending()}
          onEdit={() => onEditSectionIfNoActionsPending('pbxNumberSettings')}
          pbxSolution={configuredPbxSolution!}
          saving={Boolean(subscriptionActions?.saving)}
          subscriptionId={subscription.subscriptionId}
          subscriptionDisplayId={subscription.subscriptionDisplayId}
        />
      </LinkableAccordion>
    </>
  );
};

interface AttachSubscriptionAccordionProps {
  subscriptionDisplayId: string;
  subscriptionStatus: SubscriptionStatus;
  rejectedAddOns: boolean;
  onClickAttachSubscriptionToVakio: (subscriptionId: string, category: string, isRing?: boolean) => void;
  category: string;
  onShowDialog: (params: DialogParams) => void;
  headerText: string;
  description: string;
  buttonText: string;
  landLineNotificationText: string;
  isRing: boolean;
  idPrefix?: string;
}

const AttachSubscriptionAccordion: FunctionComponent<AttachSubscriptionAccordionProps> = ({
  subscriptionDisplayId,
  rejectedAddOns,
  subscriptionStatus,
  onClickAttachSubscriptionToVakio,
  category,
  onShowDialog,
  headerText,
  description,
  buttonText,
  landLineNotificationText,
  isRing,
  idPrefix,
}) => {
  const alreadyAttached = useSelector((state: State) =>
    (state.selfservice?.onlineOrders?.actions || []).some(e =>
      e.value.attachRingRedirect?.includes(subscriptionDisplayId)
    )
  );
  return (
    <LinkableAccordion
      className="of-mobile-subscription-accordions__attach-subscription"
      defaultOpen={true}
      heading={headerText}
      headingLevel="h3"
      id={idPrefix ? idPrefix + '-pbx-controls' : 'pbx-controls'}
    >
      <div>
        <>
          <p>{description}</p>
          {rejectedAddOns && <p>{landLineNotificationText}</p>}
          <div>
            <CL.Button
              size="l"
              disabled={rejectedAddOns || alreadyAttached}
              onClick={() =>
                subscriptionStatus.pendingActions
                  ? onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING })
                  : onClickAttachSubscriptionToVakio(subscriptionDisplayId, category, isRing)
              }
            >
              {buttonText}
            </CL.Button>
          </div>
        </>
      </div>
    </LinkableAccordion>
  );
};

interface MobileSubscriptionAccordionsProps extends PbxLiteAccordionsProps, PbxAccordionsProps {
  onClickAttachSubscriptionToVakio: (subscriptionId: string, category: string, isRing?: boolean) => void;
  onClickOrderSim: (subscriptionId: string, category: string) => void;
  onClickSimActivation: (subscriptionId: string, category: string) => void;
  companyInfo?: CompanyInfoState | null;
  category: string;
  userOrPurposeOfUseAccordionContent: JSX.Element;
  additionalServicesAccordionContent: JSX.Element;
  pbxServiceLevels?: ServiceLevel[];
}

export const MobileSubscriptionAccordions: FunctionComponent<MobileSubscriptionAccordionsProps> = ({
  pbxSolutions,
  pbxServiceLevels,
  pbxServiceLevelAddons,
  subscription,
  companyInfo,
  config,
  forceEditingFor,
  editingSection,
  errors,
  numberRange,
  onEditSectionIfNoActionsPending,
  subscriptionActions,
  extensionNumberRange,
  ringModels,
  addOnRulesDependencies,
  addOnRulesAssociations,
  onShowDialog,
  subscriptionStatus,
  onClickAttachSubscriptionToVakio,
  category,
  userOrPurposeOfUseAccordionContent,
  onClickOrderSim,
  onClickSimActivation,
  additionalServicesAccordionContent,
  contacts,
}) => {
  const configuredPbxSolution =
    subscription.details!.mobile &&
    subscription.details!.mobile.pbxConfiguration &&
    pbxSolutions!.find(
      pbxSolution => pbxSolution.subscriptionId === subscription.details!.mobile!.pbxConfiguration!.pbxSolutionId
    );

  const companyHasMobilePbxLite =
    pbxSolutions!.filter(({ subscriptionType }) => subscriptionType === SubscriptionType.MOBILE_PBX_LITE).length > 0;

  const displayPbxLiteSubscription = () =>
    companyHasMobilePbxLite &&
    configuredPbxSolution &&
    subscription.details!.mobile!.pbxConfiguration!.pbxConfigurationDetails.pbxType ===
      SubscriptionPbxDetails.PbxTypeEnum.VAKIO;

  const displayPbxSubscription = () =>
    configuredPbxSolution &&
    subscription.details!.mobile!.pbxConfiguration!.pbxConfigurationDetails.pbxType ===
      SubscriptionPbxDetails.PbxTypeEnum.RING;

  const displayAttachToRing = () => companyInfo && isAttachingRingValid(subscription, companyInfo);

  const displayAttachToVakio = () =>
    companyHasMobilePbxLite &&
    subscription.compatiblePbxTypes &&
    subscription.compatiblePbxTypes.includes(PbxType.MOBILE_PBX_LITE) &&
    !subscription.details!.mobile!.pbxConfiguration;

  return (
    <div>
      {displayPbxLiteSubscription() && (
        <PbxLiteAccordions
          onEditSectionIfNoActionsPending={onEditSectionIfNoActionsPending}
          config={config}
          subscription={subscription}
          pbxSolutions={pbxSolutions}
          configuredPbxSolution={configuredPbxSolution}
          numberRange={numberRange}
          forceEditingFor={forceEditingFor}
          extensionNumberRange={extensionNumberRange}
          subscriptionActions={subscriptionActions}
          errors={errors}
          editingSection={editingSection}
        />
      )}
      {displayPbxSubscription() && (
        <PbxAccordions
          configuredPbxSolution={configuredPbxSolution}
          subscription={subscription}
          subscriptionStatus={subscriptionStatus}
          onShowDialog={onShowDialog}
          errors={errors}
          subscriptionActions={subscriptionActions}
          ringModels={ringModels}
          companyInfo={companyInfo}
          addOnRulesDependencies={addOnRulesDependencies}
          addOnRulesAssociations={addOnRulesAssociations}
          onEditSectionIfNoActionsPending={onEditSectionIfNoActionsPending}
          editingSection={editingSection}
          contacts={contacts}
          pbxServiceLevels={pbxServiceLevels}
          pbxServiceLevelAddons={pbxServiceLevelAddons}
        />
      )}
      {displayAttachToRing() && (
        <AttachSubscriptionAccordion
          onShowDialog={onShowDialog}
          subscriptionStatus={subscriptionStatus}
          onClickAttachSubscriptionToVakio={onClickAttachSubscriptionToVakio}
          rejectedAddOns={isRejectedAddOns(subscription)}
          category={category}
          subscriptionDisplayId={subscription.subscriptionDisplayId}
          headerText={t.U373('Ring settings')}
          description={t.LO5I(`Subscription not connected to your company's Ring solution`)}
          buttonText={t.X0LO(connectToRingMsg)}
          landLineNotificationText={t.ZEU1(
            'Connection is currently not possible due to the Land line extension service'
          )}
          isRing={true}
          idPrefix="ring"
        />
      )}
      {displayAttachToVakio() && (
        <AttachSubscriptionAccordion
          onShowDialog={onShowDialog}
          subscriptionStatus={subscriptionStatus}
          onClickAttachSubscriptionToVakio={onClickAttachSubscriptionToVakio}
          rejectedAddOns={isRejectedAddOns(subscription)}
          category={category}
          subscriptionDisplayId={subscription.subscriptionDisplayId}
          headerText={t.D0GE('Vakio settings')}
          description={t.VH8G(`Subscription not connected to your company's Vakio solution`)}
          buttonText={t.M0TS(connectToVakioMsg)}
          landLineNotificationText={t.ZEU1(
            'Connection is currently not possible due to the Land line extension service'
          )}
          isRing={false}
          idPrefix="vakio"
        />
      )}
      <LinkableAccordion heading={t.U21Y(directoryStatusOfUserAndNumberMsg)} headingLevel="h3" id="user-or-use-purpose">
        {userOrPurposeOfUseAccordionContent}
      </LinkableAccordion>
      <LinkableAccordion heading={t.PIZC(simCardMsg)} headingLevel="h3" id="sim-card-change">
        <SimCard
          pendingSimChanges={
            subscriptionStatus.pendingActionType === SubscriptionAction.SubscriptionActionTypeEnum.CHANGE_SIM
          }
          pendingSubscriptionChanges={subscriptionStatus.pendingActions}
          simCardConfig={{
            simCardNumber: subscription.details!.mobile!.simCardNumber,
            simType: subscription.details!.mobile!.simType,
          }}
          sourceIsTellus={subscription.sourceSystem === Subscription.SourceSystemEnum.TELLUS && !subscription.migrated}
          onClickSimActivation={() => onClickSimActivation(subscription.subscriptionDisplayId, category)}
          onClickOrderSim={() => onClickOrderSim(subscription.subscriptionDisplayId, category)}
          simCardChangeAllowed={simCardChangeAllowed(subscription)}
          hasEsimQrCode={hasEsimQrCode(subscription)}
        />
      </LinkableAccordion>
      <LinkableAccordion heading={t.ZUCA(barringServicesMsg)} headingLevel="h3" id="barring-services">
        {addOnRulesAssociations && addOnRulesDependencies ? (
          <BarringsAccordionContent
            addOnAssociations={addOnRulesAssociations}
            addOnDependencies={addOnRulesDependencies}
            details={subscription.details!}
            forceEditing={forceEditingFor === 'addonsBarrings' || editingSection === 'addonsBarrings'}
            onEditSectionIfNoActionsPending={onEditSectionIfNoActionsPending}
            saving={Boolean(subscriptionActions?.saving)}
            subscription={subscription}
            subscriptionStatus={subscriptionStatus}
          />
        ) : (
          <div className="ds-padding-bottom--4">
            <Loading />
          </div>
        )}
      </LinkableAccordion>
      <LinkableAccordion heading={t.LXSR(additionalServicesMsg)} headingLevel="h3" id="additional-services">
        {additionalServicesAccordionContent}
      </LinkableAccordion>
    </div>
  );
};
