import * as CL from '@design-system/component-library';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';

export const ThankYouContent = () => (
  <div className="ds-text-align--center ds-padding-top--10">
    <div className="of-fixed-broadband-order__thumb-up" />
    <h2 className="ds-h2 ds-margin-bottom--2">{t.O4R6('Thank you for your order!')}</h2>
    <div>{t.AURX(`We'll get back to you as soon as possible.`)}</div>
    <CL.Button
      color="primary"
      size="l"
      className="ds-margin-top--8"
      onClick={() => {
        window.location.href = paths.NETTI_LITE;
      }}
    >
      {t.YZQH('Return to store')}
    </CL.Button>
  </div>
);
