import * as CL from '@design-system/component-library';
import { ContactDropdown } from '../../../common/formik/Fields/Dropdown/ContactDropdown.js';
import { GridColumn } from '../../BillingAccountDetails/components/GridColumn.js';
import { ReceiverTypes } from '../../../common/utils/billingAccountUtils.js';
import { t } from '../../../common/i18n/index.js';
import { useState } from 'react';
import type { BillingAccountDeliveryMethod, Contact, ContactType } from '../../../generated/api/models.js';
import type { ReceiverType } from '../../../common/utils/billingAccountUtils.js';

export interface EmailDeliveryMethodFormValues {
  deliveryMethod: BillingAccountDeliveryMethod;
  billLanguage?: string;
  billReceiverType?: ContactType;
  additionalBillReceiverEmails: string[];
  billReceiverId?: string;
  billReceiverName?: string;
}
export const BillReceiverEdit = ({
  contacts,
  receiverSameAsContact,
}: {
  contacts: Contact[];
  receiverSameAsContact: boolean;
}) => {
  const [receiver, setReceiver] = useState<ReceiverType>(
    receiverSameAsContact ? ReceiverTypes.SAME_AS_CONTACT : ReceiverTypes.OTHER
  );

  return (
    <>
      <CL.GridRow>
        <GridColumn className="of-billing-account-details form-column edit-left">
          <CL.Radio.Group value={receiver} onChange={e => setReceiver(e.currentTarget.value as ReceiverType)}>
            <CL.Radio value={ReceiverTypes.SAME_AS_CONTACT} key="sameAsContact" label={t.ZCX7('Same as contact')} />
            <CL.Radio value={ReceiverTypes.OTHER} key="other" label={t.C6WY('Other')} />
          </CL.Radio.Group>
        </GridColumn>
      </CL.GridRow>
      {receiver === ReceiverTypes.OTHER && (
        <CL.GridRow>
          <GridColumn className="of-billing-account-details form-column edit-left">
            <ContactDropdown
              className="ds-margin-bottom--2"
              canAddNewContacts={true}
              contacts={contacts || []}
              name="billReceiverId"
            />
            <div className="ds-display--flex">
              <CL.Icon icon="information" type="light" size="s" />
              <span className="ds-text--small ds-margin-left--2">
                {t.RJT5('Select a contact from the list or create a new one.')}
              </span>
            </div>
          </GridColumn>
        </CL.GridRow>
      )}
    </>
  );
};
