import { Anchor } from '../Anchor/Anchor.js';
import { CompositeList } from '../CompositeList/CompositeList.js';
import { LinkableAccordion } from '../LinkableAccordion/index.js';
import { buildPathVariable, paths } from '../../common/constants/pathVariables.js';
import { deliveryMethodMsg, numberMsg, payerMsg, t } from '../../common/i18n/index.js';
import { getDeliveryMethod, isBillingAccountInSfdc } from '../../common/utils/billingAccountUtils.js';
import { useNavigate } from 'react-router-dom';
import type { BillingAccount, BillingAccountHeader, BillingAccountSearchResponse } from '../../generated/api/models.js';
import type { CompositeListProps } from '../CompositeList/CompositeList.js';
import type { ConfigState } from '../../common/types/states.js';

import './BillingAccountsAccordion.scss';

export interface BillingAccountsAccordionProps {
  config: ConfigState;
  billingAccounts: BillingAccountSearchResponse[];
}

export const BillingAccountsAccordion = ({ config, billingAccounts }: BillingAccountsAccordionProps) => {
  const navigate = useNavigate();
  const columns = [
    {
      columnId: 'billingAccountDisplayId',
      heading: t.CCB1(numberMsg),
      postfixIcon: (billingAccount: BillingAccount) =>
        !isBillingAccountInSfdc(billingAccount) ? 'northeast-arrow' : undefined,
      ref: 'billingAccountDisplayId',
    },
    {
      columnId: 'payerName',
      heading: t.PB6S(payerMsg),
      ref: 'payerName',
    },
    {
      columnId: 'deliveryMethod',
      heading: t.G0QN(deliveryMethodMsg),
      ref: 'deliveryMethod',
      refFormatString: (deliveryMethod: string) => getDeliveryMethod(deliveryMethod),
    },
  ];

  const compositeListProps: CompositeListProps<BillingAccountHeader> = {
    classes: ['of-billing-account-list'],
    columns,
    getExternalLink: (billingAccount: BillingAccount) =>
      !isBillingAccountInSfdc(billingAccount) ? `${config.classicSiteUrl}/laskutustiedot` : undefined,
    getRowId: (billingAccount: BillingAccount) => billingAccount.billingAccountDisplayId!,
    items: billingAccounts ? billingAccounts.map(ba => ba.result).slice(0, 10) : [],
    onSelectRow: (id: string) => {
      navigate(buildPathVariable(paths.BILLING_ACCOUNT, id));
    },
    rowIcon: 'ba-filled',
  };

  return (
    <LinkableAccordion
      heading={t.ZVMK('Billing Accounts')}
      headingLevel="h3"
      badge={t.UDN6('{} pcs', billingAccounts?.length.toString() || '0')}
      className="of-billing-accounts-accordion"
      id="billing-accounts"
    >
      <p className="of-billing-accounts-accordion__description">
        {t.V62H('Billing accounts where the user is a contact person.')}
      </p>
      <CompositeList {...compositeListProps} />
      {billingAccounts && billingAccounts.length > 10 && (
        <Anchor
          className="link"
          onClick={() => {
            navigate(paths.BILLING_ACCOUNTS);
          }}
        >
          {t.CCZ4('Show all billing accounts')}
        </Anchor>
      )}
    </LinkableAccordion>
  );
};
