import type {
  BillingAccount,
  Document,
  GenericError,
  Invoice,
  InvoiceDocumentsResponse,
  InvoicesResponse,
} from '../../generated/api/models.js';

import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { CompositeListSort } from '../../components/CompositeListHeader/CompositeListHeader.js';
import type { DisplayItemsLoadAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type { ItemsQuery } from '../common/store.js';

export interface LoadInvoicesAction extends DisplayItemsLoadAction<TypeKeys.LOAD_INVOICES, Invoice> {
  category: string;
  loadMore?: boolean;
  offset?: number;
}

export const loadInvoices = (
  category: string,
  displayId?: string,
  sort?: CompositeListSort,
  search?: string,
  loadMore?: boolean,
  offset?: number,
  limit?: number
): LoadInvoicesAction => ({
  category,
  displayId,
  id: generateActionId(),
  loadMore,
  search,
  sort,
  offset,
  limit,
  type: TypeKeys.LOAD_INVOICES,
});

export type LoadInvoicesFulfilledAction = {
  billingAccounts?: BillingAccount[];
  category: string;
  displayId?: string;
  invoices?: Invoice[];
  query: ItemsQuery;
  totalInvoices: number;
  totalPositiveBalance: number;
  type: TypeKeys.LOAD_INVOICES_FULFILLED;
};

export const loadInvoicesFulfilled = (
  { invoices, totalPositiveBalance, total, billingAccounts }: InvoicesResponse,
  query: ItemsQuery,
  category: string,
  displayId?: string
): LoadInvoicesFulfilledAction => ({
  billingAccounts,
  category,
  displayId,
  invoices,
  query,
  totalInvoices: total,
  totalPositiveBalance,
  type: TypeKeys.LOAD_INVOICES_FULFILLED,
});

export type LoadInvoicesFailedAction = ErrorAction<TypeKeys.LOAD_INVOICES_FAILED>;

export const loadInvoicesFailed: ErrorActionCreator<TypeKeys.LOAD_INVOICES_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): LoadInvoicesFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.LOAD_INVOICES_FAILED,
  };
};

export interface LoadInvoiceDocumentsAction extends DisplayItemsLoadAction<TypeKeys.LOAD_INVOICE_DOCUMENTS, Document> {
  offset?: number;
  limit?: number;
}

export const loadInvoiceDocuments = (offset?: number, limit?: number): LoadInvoiceDocumentsAction => ({
  id: generateActionId(),
  offset,
  limit,
  type: TypeKeys.LOAD_INVOICE_DOCUMENTS,
});

export type LoadInvoiceDocumentsFulfilledAction = {
  billingAccounts?: BillingAccount[];
  invoiceDocuments?: Document[];
  totalItems: number;
  type: TypeKeys.LOAD_INVOICE_DOCUMENTS_FULFILLED;
};

export const loadInvoiceDocumentsFulfilled = ({
  invoiceDocuments,
  total,
  billingAccounts,
}: InvoiceDocumentsResponse): LoadInvoiceDocumentsFulfilledAction => ({
  billingAccounts,
  invoiceDocuments,
  totalItems: total,
  type: TypeKeys.LOAD_INVOICE_DOCUMENTS_FULFILLED,
});

export type LoadInvoiceDocumentsFailedAction = ErrorAction<TypeKeys.LOAD_INVOICE_DOCUMENTS_FAILED>;

export const loadInvoiceDocumentsFailed: ErrorActionCreator<TypeKeys.LOAD_INVOICE_DOCUMENTS_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): LoadInvoiceDocumentsFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.LOAD_INVOICE_DOCUMENTS_FAILED,
  };
};

export type InvoicesActionTypes =
  | LoadInvoicesAction
  | LoadInvoicesFulfilledAction
  | LoadInvoicesFailedAction
  | LoadInvoiceDocumentsAction
  | LoadInvoiceDocumentsFulfilledAction
  | LoadInvoiceDocumentsFailedAction;
