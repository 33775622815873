import * as CL from '@design-system/component-library';
import { DeviceCheckoutThankYou } from '../DeviceCheckoutThankYou/DeviceCheckoutThankYou.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { finalizeOnlineOrderCardPayment, resetErrors } from '../../selfservice/actions/index.js';
import { getContactInfo } from '../../common/utils/stateUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { returnToCheckoutMsg, returnToTheShopMsg, t } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type { CheckoutCardPaymentStatus } from '../../common/constants/pathInterfaces.js';
import type { DeviceCheckoutDeliveryDetailsType } from '../DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';
import type { State } from '../../selfservice/common/store.js';

import './DeviceCheckoutCardPayment.scss';

enum CheckoutCardPaymentStatusEnum {
  IN_PROGRESS = 'kaynnissa',
  COMPLETE = 'valmis',
}

// from https://shop.nets.eu/web/partners/response-codes
export enum CardPaymentResponseStatusText {
  OK = 'OK',
  CANCEL = 'Cancel',
}

const getDeliveryDetailsFromSessionStorage = (): DeviceCheckoutDeliveryDetailsType | undefined => {
  const DELIVERY_DETAILS_FOR_ONLINE_ORDER_CARD_PAYMENT_SESSION_STORAGE_KEY =
    'delivery-details-for-online-order-card-payment';
  return (
    sessionStorage.getItem(DELIVERY_DETAILS_FOR_ONLINE_ORDER_CARD_PAYMENT_SESSION_STORAGE_KEY) &&
    JSON.parse(sessionStorage.getItem(DELIVERY_DETAILS_FOR_ONLINE_ORDER_CARD_PAYMENT_SESSION_STORAGE_KEY)!)
  );
};

const PaymentCancellationInProgress = () => (
  <div className="of-device-checkout-card-payment__content">
    <div>
      <CL.LoadingSpinner className="ds-margin-bottom--4" size="l" />
      <h2 className="ds-margin-top--0 ds-margin-bottom--2">{t.TZBP('Processing')}</h2>
      <h3 className="ds-h3--book ds-margin-top--0 ds-margin-bottom--1">{t.C9XG('Wait a moment...')}</h3>
    </div>
  </div>
);

const PaymentInProgress = () => (
  <div className="of-device-checkout-card-payment__content">
    <div>
      <CL.LoadingSpinner className="ds-margin-bottom--4" size="l" />
      <h2 className="ds-margin-top--0 ds-margin-bottom--2">{t.AL0L('The order is being processed')}</h2>
      <h3 className="ds-h3--book ds-margin-top--0 ds-margin-bottom--1">{t.C9XG('Wait a moment...')}</h3>
    </div>
  </div>
);

const PaymentOk = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deliveryDetails = getDeliveryDetailsFromSessionStorage();
  const onRedirectToWebShop = () => {
    dispatch(resetErrors());
    navigate(paths.WEB_SHOP);
  };
  const { authenticatedUser } = useAuth();
  const { cartItems, companyInfo } = useSelector(
    (state: State) => ({
      cartItems: state.deviceCheckout?.cartItems || [],
      companyInfo: state.selfservice?.companyInfo || {},
    }),
    deepEqual
  );
  if (deliveryDetails && authenticatedUser) {
    return (
      <DeviceCheckoutThankYou
        cartItems={cartItems}
        companyInfo={companyInfo}
        contactInfo={getContactInfo(authenticatedUser)}
        deliveryDetails={deliveryDetails}
        isOrderPaidByCard={true}
      />
    );
  }
  // fallback if delivery and / or user details are missing
  return (
    <div className="of-device-checkout-card-payment__content--payment-success">
      <div>
        <div className="of-device-checkout-card-payment__thumb-up" />
        <h3 className="ds-margin-top--0 ds-margin-bottom--1">{t.O4R6('Thank you for your order!')}</h3>
        <CL.Button size="l" className="ds-margin-top--3" onClick={onRedirectToWebShop}>
          {t.PLAW(returnToTheShopMsg)}
        </CL.Button>
      </div>
    </div>
  );
};

const PaymentCancelled = ({ onRedirectToCheckout }: { onRedirectToCheckout: () => void }) => (
  <div className="of-device-checkout-card-payment__content--payment-cancelled">
    <div>
      <CL.Icon icon="information" size="xl" color="red-600" />
      <h2 className="ds-margin-top--0 ds-margin-bottom--2">{t.BF36('Payment cancelled')}</h2>
      <h3 className="ds-h3--book ds-margin-top--0 ds-margin-bottom--1">{t.OFAN('You can continue shopping.')}</h3>
      <CL.Button className="ds-margin-top--3" onClick={onRedirectToCheckout}>
        {t.PLAX(returnToCheckoutMsg)}
      </CL.Button>
    </div>
  </div>
);

const PaymentFailed = ({ onRedirectToCheckout }: { onRedirectToCheckout: () => void }) => (
  <div className="of-device-checkout-card-payment__content--payment-failed">
    <div>
      <CL.Icon icon="information" size="xl" color="red-600" />
      <h2 className="ds-margin-top--0 ds-margin-bottom--2">{t.U7HJ('Payment verification failed')}</h2>
      <h3 className="ds-h3--book ds-ds-margin-top--0 ds-margin-bottom--1">
        {t.UFR3('Unfortunately the payment verification failed. Try again or use some other payment method.')}
      </h3>
      <CL.Button className="ds-margin-top--3" onClick={onRedirectToCheckout}>
        {t.PLAX(returnToCheckoutMsg)}
      </CL.Button>
    </div>
  </div>
);

export const DeviceCheckoutCardPayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { transactionId, responseCode } = useSearchParams<{ transactionId: string; responseCode: string }>();
  const { checkoutCardPaymentStatus = '' } = useParams<CheckoutCardPaymentStatus>();

  const onRedirectToCheckout = () => navigate(paths.DEVICE_CHECKOUT);

  useEffect(() => {
    if (transactionId && responseCode && checkoutCardPaymentStatus === CheckoutCardPaymentStatusEnum.IN_PROGRESS) {
      dispatch(finalizeOnlineOrderCardPayment(responseCode, transactionId));
    }
  }, [dispatch, finalizeOnlineOrderCardPayment]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  if (checkoutCardPaymentStatus === CheckoutCardPaymentStatusEnum.IN_PROGRESS) {
    if (responseCode === CardPaymentResponseStatusText.CANCEL) {
      return <PaymentCancellationInProgress />;
    } else {
      return <PaymentInProgress />;
    }
  } else if (checkoutCardPaymentStatus === CheckoutCardPaymentStatusEnum.COMPLETE) {
    if (responseCode === CardPaymentResponseStatusText.OK) {
      return <PaymentOk />;
    } else if (responseCode === CardPaymentResponseStatusText.CANCEL) {
      return <PaymentCancelled onRedirectToCheckout={onRedirectToCheckout} />;
    } else {
      return <PaymentFailed onRedirectToCheckout={onRedirectToCheckout} />;
    }
  } else {
    throw new Error('Invalid device checkout card payment tab');
  }
};
