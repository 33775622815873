import { Catalog, CommercialProductSubType, EppCategory, OnlineModelCategory } from '../../generated/api/models.js';
import { VAT_MULTIPLIER } from './taxUtils.js';
import { addEmptyFieldValidationError } from './errorUtils.js';
import { categories } from './categoryUtils.js';
import { draftMsg, elisaDevicesServiceMsg, modifiedMsg, monthMsg, monthsMsg, publishedMsg, t } from '../i18n/index.js';
import { formatSum } from './priceUtils.js';
import type {
  CatalogHeader,
  DiscountedPrices,
  OfferBadge,
  OnlineModelHeader,
  VirtualCatalog,
  VirtualCatalogHeader,
} from '../../generated/api/models.js';
import type { CommonError } from '../types/errors.js';
import type { OnlineModelHeadersState } from '../types/states.js';
import type { ProductGridItem } from '../../components/ProductGrid/ProductGrid.js';
import type { RadioProps } from '@design-system/component-library';

export interface CatalogProduct {
  code: string;
  created: number;
  imageUrl: string;
  manufacturer: string;
  monthlyRecurringCharge?: number;
  name: string;
  offerBadge?: OfferBadge;
  oneTimeCharge?: number;
  payments?: number;
  pagePath?: string;
  tags: Array<string>;
  ref?: OnlineModelHeader;
}

export type SupportedModelCategory = keyof typeof SupportedModelCategory;

// PHONE, TABLET, COMPUTERS, NETWORKEQUIPMENT, ACCESSORIES are allowed to show up on online layer yet.
// eslint-disable-next-line no-redeclare
export const SupportedModelCategory = {
  PHONE: OnlineModelCategory.PHONE as 'PHONE',
  TABLET: OnlineModelCategory.TABLET as 'TABLET',
  COMPUTERS: OnlineModelCategory.COMPUTERS as 'COMPUTERS',
  NETWORK_EQUIPMENT: OnlineModelCategory.NETWORK_EQUIPMENT as 'NETWORK_EQUIPMENT',
  ACCESSORIES: OnlineModelCategory.ACCESSORIES as 'ACCESSORIES',
} as const;

export const SupportedModelCategories: readonly SupportedModelCategory[] = Object.values(SupportedModelCategory);

export const ContractPeriod = {
  MONTHS_12: 12,
  MONTHS_24: 24,
  MONTHS_36: 36,
};

const calculatePrice = (
  model: OnlineModelHeader,
  catalog: Partial<Catalog>,
  discountedPrices: DiscountedPrices[],
  isEmployeeUser: boolean
): { lowestOneTimeCharge?: number; lowestRecurringCharge?: number } => {
  let lowestOneTimeCharge: number | undefined;
  let lowestRecurringCharge: number | undefined;

  // Filter prices as per catalog
  const lowestPrices =
    catalog.productType === Catalog.ProductTypeEnum.EPP_RECURRING
      ? model.lowestPrices.find(price => price.productSubType === CommercialProductSubType.EPP_DEVICE)?.prices
      : model.lowestPrices.find(price => price.productSubType === CommercialProductSubType.DEVICE)?.prices;
  if (
    catalog.productType === Catalog.ProductTypeEnum.EPP_RECURRING ||
    catalog.productType === Catalog.ProductTypeEnum.RECURRING
  ) {
    lowestRecurringCharge = lowestPrices?.find(
      price => price.monthlyRecurringCharge && price.payments === catalog.contractPeriod
    )?.monthlyRecurringCharge;
  } else if (catalog.productType === Catalog.ProductTypeEnum.ONETIME) {
    lowestOneTimeCharge = lowestPrices?.find(price => price.oneTimeCharge)?.oneTimeCharge;
  }
  if (!lowestRecurringCharge && !lowestOneTimeCharge) {
    return { lowestOneTimeCharge: undefined, lowestRecurringCharge: undefined };
  }

  // Apply discounts
  const discountedPricesForModel = discountedPrices.find(price => price.model === model.onlineModelCode);
  if (discountedPricesForModel) {
    if (
      catalog.productType === Catalog.ProductTypeEnum.EPP_RECURRING ||
      catalog.productType === Catalog.ProductTypeEnum.RECURRING
    ) {
      const productSubType =
        catalog.productType === Catalog.ProductTypeEnum.EPP_RECURRING
          ? CommercialProductSubType.EPP_DEVICE
          : CommercialProductSubType.DEVICE;
      const discountedRecurringCharges = discountedPricesForModel.prices
        .filter(
          price =>
            price.productSubType === productSubType &&
            price.payments === catalog.contractPeriod &&
            price.monthlyRecurringCharge
        )
        .map(price => price.monthlyRecurringCharge!);
      lowestRecurringCharge = Math.min(...discountedRecurringCharges, lowestRecurringCharge!);
    } else if (catalog.productType === Catalog.ProductTypeEnum.ONETIME) {
      const discountedOneTimeCharges = discountedPricesForModel.prices
        .filter(price => price.productSubType === CommercialProductSubType.DEVICE && price.oneTimeCharge)
        .map(price => price.oneTimeCharge!);
      lowestOneTimeCharge = Math.min(...discountedOneTimeCharges, lowestOneTimeCharge!);
    }
  }

  // Adjust corporate share
  if (isEmployeeUser) {
    if (catalog.productType === Catalog.ProductTypeEnum.EPP_RECURRING) {
      if (catalog.corporateShare) {
        lowestRecurringCharge = Math.max(lowestRecurringCharge! - catalog.corporateShare, 0);
      } else {
        lowestRecurringCharge = 0;
      }
    } else if (catalog.productType === Catalog.ProductTypeEnum.RECURRING) {
      lowestRecurringCharge = 0;
    } else if (catalog.productType === Catalog.ProductTypeEnum.ONETIME) {
      lowestOneTimeCharge = 0;
    }
  }

  // Add tax
  if (isEmployeeUser) {
    if (lowestOneTimeCharge) {
      lowestOneTimeCharge = Math.round(lowestOneTimeCharge * VAT_MULTIPLIER);
    }
    if (lowestRecurringCharge) {
      lowestRecurringCharge = Math.round(lowestRecurringCharge * VAT_MULTIPLIER);
    }
  }

  return {
    lowestOneTimeCharge,
    lowestRecurringCharge,
  };
};

const calculatePriceForAccessories = (
  model: OnlineModelHeader,
  catalog: Partial<Catalog>,
  discountedPrices: DiscountedPrices[],
  isEmployeeUser: boolean
): { lowestOneTimeCharge?: number; lowestRecurringCharge?: number } => {
  let lowestOneTimeCharge: number | undefined;
  let lowestRecurringCharge: number | undefined;

  // Find lowest price from model
  const lowestPrices = model.lowestPrices.map(lowestPrice => lowestPrice.prices).reduce((x, y) => x.concat(y), []);
  lowestOneTimeCharge = lowestPrices?.find(price => price.oneTimeCharge)?.oneTimeCharge;
  // Only one time priced accessories if catalog is EPP_RECURRING type
  if (catalog.productType !== Catalog.ProductTypeEnum.EPP_RECURRING) {
    const recurringCharges = lowestPrices
      ?.filter(price => price.monthlyRecurringCharge)
      .map(price => price.monthlyRecurringCharge!);
    if (recurringCharges.length > 0) {
      lowestRecurringCharge = Math.min(...recurringCharges);
    }
  }

  // Assuming accessories won't have only EPP prices, make employee share 0
  if (isEmployeeUser) {
    return {
      lowestOneTimeCharge: lowestOneTimeCharge === undefined ? undefined : 0,
      lowestRecurringCharge: lowestRecurringCharge === undefined ? undefined : 0,
    };
  }

  // Apply discounts
  const discountedPricesForModel = discountedPrices.find(price => price.model === model.onlineModelCode);
  if (discountedPricesForModel) {
    if (lowestRecurringCharge) {
      const discountedRecurringCharges = discountedPricesForModel.prices
        .filter(price => price.monthlyRecurringCharge)
        .map(price => price.monthlyRecurringCharge!);
      lowestRecurringCharge = Math.min(...discountedRecurringCharges, lowestRecurringCharge);
    }
    if (lowestOneTimeCharge) {
      const discountedOneTimeCharges = discountedPricesForModel.prices
        .filter(price => price.oneTimeCharge)
        .map(price => price.oneTimeCharge!);
      lowestOneTimeCharge = Math.min(...discountedOneTimeCharges, lowestOneTimeCharge);
    }
  }

  return {
    lowestOneTimeCharge: lowestOneTimeCharge,
    lowestRecurringCharge: lowestRecurringCharge,
  };
};

export const toDisplayableProduct = (
  model: OnlineModelHeader,
  catalog: Partial<Catalog>,
  discountedPrices: DiscountedPrices[],
  isEmployeeUser: boolean,
  filterProducts: boolean
): ProductGridItem | null => {
  // Filter product as per catalog
  if (
    filterProducts &&
    (!catalog.products?.includes(model.onlineModelCode) || (catalog.products && catalog.products.length === 0))
  ) {
    return null;
  }

  // Calculate price
  const { lowestOneTimeCharge, lowestRecurringCharge } =
    model.category === OnlineModelCategory.ACCESSORIES
      ? calculatePriceForAccessories(model, catalog, discountedPrices, isEmployeeUser)
      : calculatePrice(model, catalog, discountedPrices, isEmployeeUser);
  const contractPeriod = model.category === OnlineModelCategory.ACCESSORIES ? undefined : catalog.contractPeriod;

  // Model does not contain price type needed by catalog config
  if (lowestRecurringCharge === undefined && lowestOneTimeCharge === undefined) {
    return null;
  }

  return {
    code: model.onlineModelCode,
    name: model.onlineModelName,
    manufacturer: model.manufacturer,
    imageUrl: model.listingImage,
    oneTimeCharge: lowestOneTimeCharge,
    monthlyRecurringCharge: lowestRecurringCharge,
    payments: contractPeriod,
    pagePath: model.pagePath,
    created: model.created,
    tags: model.tags || [],
  };
};

export const getSelectedCatalog = (catalogs?: Catalog[], selectedCatalogCode?: string | null) =>
  selectedCatalogCode !== undefined
    ? catalogs?.find(catalog => catalog.catalogCode === selectedCatalogCode)
    : undefined;

export const collectProductCategoriesOfVirtualCatalog = (vCatalog: VirtualCatalog): Array<OnlineModelCategory> =>
  (vCatalog.draft?.productCategories || []).concat(vCatalog.published?.productCategories || []);

// TODO: Correct return type in below
export const findCatalogById = (
  virtualCatalogs: VirtualCatalog[] = [],
  catalogId?: string | null,
  onlyPublished?: boolean
) => {
  if (catalogId) {
    const catalog = virtualCatalogs.find(
      ({ published, draft }) =>
        published?.catalogCode === catalogId || (!onlyPublished && draft?.catalogCode === catalogId)
    );
    return catalog?.published || catalog?.draft;
  }

  return undefined;
};

export const productTypeDetails = (): { [s in Catalog.ProductTypeEnum]: { displayValue: string } } => ({
  ONETIME: {
    displayValue: t.XED2('Device subject to one-time fee'),
  },
  RECURRING: {
    displayValue: t.OF9U('Devices subject to monthly fee'),
  },
  EPP_RECURRING: {
    displayValue: t.TRE5(elisaDevicesServiceMsg),
  },
});

export const getContractPeriodItems = (): RadioProps[] => {
  return [
    {
      label: '12 ' + t.XXVX(monthsMsg),
      value: ContractPeriod.MONTHS_12.toString(),
    },
    {
      label: '24 ' + t.XXVX(monthsMsg),
      value: ContractPeriod.MONTHS_24.toString(),
    },
    {
      label: '36 ' + t.XXVX(monthsMsg),
      value: ContractPeriod.MONTHS_36.toString(),
    },
  ];
};

export const damageInsuranceItem = [
  { displayValue: () => 'Business Pro', valueRef: EppCategory.BUSINESS_PRO },
  { displayValue: () => 'Business Premium', valueRef: EppCategory.BUSINESS_PREMIUM },
];

export const getDamageInsuranceDisplayableItems = (items: EppCategory[]): string =>
  items.map(item1 => damageInsuranceItem.find(item => item.valueRef === item1)?.displayValue()).join(', ');

export const getCorporateShareAsText = (corporateShare?: number) => {
  return corporateShare != null ? `${formatSum(corporateShare)}/${t.XXVX(monthMsg)}` : '—';
};

export enum CatalogModificationStatus {
  MODIFIED = 'MODIFIED',
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
}

export const getCatalogHeaderModificationStatusAsEnum = (catalog: VirtualCatalog | VirtualCatalogHeader) => {
  if (catalog.published && catalog.draft) {
    return CatalogModificationStatus.MODIFIED;
  } else if (catalog.published && !catalog.draft) {
    return CatalogModificationStatus.ACTIVE;
  } else {
    return CatalogModificationStatus.DRAFT;
  }
};

export const getCatalogModificationStatus = (
  virtualCatalog: VirtualCatalog | VirtualCatalogHeader
): { text: string; color: string; type: CatalogModificationStatus } | undefined => {
  if (virtualCatalog.published && virtualCatalog.draft) {
    return { text: t.RUFL(modifiedMsg), color: 'light-orange', type: CatalogModificationStatus.MODIFIED };
  } else if (virtualCatalog.published && !virtualCatalog.draft) {
    return { text: t.JN25(publishedMsg), color: 'green', type: CatalogModificationStatus.ACTIVE };
  } else if (virtualCatalog.draft) {
    return { text: t.ZJQG(draftMsg), color: 'grey', type: CatalogModificationStatus.DRAFT };
  }
  return undefined;
};

export const getCatalogModificationStatusText = (virtualCatalog: VirtualCatalog | VirtualCatalogHeader) => {
  return getCatalogModificationStatus(virtualCatalog)?.text || '';
};

export const getPublishedCatalogs = (virtualCatalogs?: VirtualCatalog[]): Catalog[] | undefined => {
  if (virtualCatalogs === undefined) {
    return undefined;
  }
  const publishedCatalogs: Catalog[] = [];
  virtualCatalogs.forEach(x => {
    if (x.published) {
      publishedCatalogs.push(x.published);
    }
  });
  return publishedCatalogs;
};

export function getErrorsFromVirtualCatalogDetails(
  catalog: Partial<Catalog>,
  validationErrors?: CommonError[]
): CommonError[] | undefined {
  const errors: CommonError[] = [];
  if (!catalog.name || catalog.name.trim().length === 0) {
    addEmptyFieldValidationError(errors, 'name');
  }
  if (catalog.corporateMessage?.trim().length === 0) {
    addEmptyFieldValidationError(errors, 'corporateMessage');
  }
  if (
    (catalog.productType === Catalog.ProductTypeEnum.EPP_RECURRING ||
      catalog.productType === Catalog.ProductTypeEnum.RECURRING) &&
    !catalog.contractPeriod
  ) {
    addEmptyFieldValidationError(errors, 'agreementPeriod');
  }
  if (errors.length === 0) {
    return validationErrors;
  }
  return errors.concat(validationErrors || []);
}

export function getCopyVersionOfCatalog(catalog: Catalog): Partial<Catalog> {
  return {
    ...catalog,
    billingAccountId: undefined,
    catalogCode: undefined,
    created: undefined,
    lastModified: undefined,
    publishedStatus: undefined,
    versionNumber: undefined,
  };
}

// TODO: Optimize below to process per category, instead of all category in a go.
export const toCatalogProductsPerCategory = (
  catalog: Partial<Catalog>,
  filterProducts: boolean,
  discountedPrices: DiscountedPrices[],
  onlineModelHeaders?: OnlineModelHeadersState | null
): Record<string, Array<CatalogProduct>> => {
  const products: Record<string, Array<CatalogProduct>> = {};
  if (onlineModelHeaders?.items) {
    const productCategories = filterProducts ? catalog.productCategories : SupportedModelCategories;
    if (productCategories) {
      productCategories.forEach(category => {
        if (onlineModelHeaders?.items![category]) {
          products[category] = onlineModelHeaders
            .items![category].map(model =>
              toDisplayableProduct(model, catalog, discountedPrices, false, filterProducts)
            )
            .filter((item): item is CatalogProduct => item !== null);
        }
      });
    }
  }
  return products;
};

export const resolveCategoryUrl = (catalogs: Catalog[], catalogCode: string) => {
  const currentCatalog = catalogs.find(catalog => catalog.catalogCode === catalogCode);
  const catalogProductCategories = currentCatalog ? currentCatalog.productCategories : [];

  if (!catalogProductCategories.includes(OnlineModelCategory.PHONE) && catalogProductCategories.length > 0) {
    const firstProductCategory = catalogProductCategories[0];
    return Object.values(categories).find(category => category?.code === firstProductCategory)?.urlPath;
  }

  return categories.PHONE?.urlPath;
};

export const getCatalogToBeDisplayed = (virtualCatalog: VirtualCatalogHeader): CatalogHeader | undefined =>
  virtualCatalog.draft || virtualCatalog.published;
