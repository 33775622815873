import * as CL from '@design-system/component-library';
import {
  DEFAULT_ITEMS_PER_PAGE,
  ListPagination,
  ListSearch,
  StatusColumn,
  Table,
  TableUrlParams,
} from '../Table/index.js';
import { Grid } from '../Grid/Grid.js';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import {
  OPEN_INVOICES_HASH,
  PAID_INVOICES_HASH,
  billingAccountMsg,
  dueDateMsg,
  paidMsg,
  payerMsg,
  statusMsg,
  t,
} from '../../common/i18n/index.js';
import { formatBalance, formatSum } from '../../common/utils/priceUtils.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getInvoiceState, getStatusColumnInvoiceState } from '../InvoiceDetails/InvoiceDetails.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useMemo } from 'react';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type { BillingAccountHeader, Invoice, InvoiceHeader } from '../../generated/api/models.js';
import type { ClickableText } from '../CompositeList/index.js';
import type { CompositeListColumn } from '../CompositeListHeader/index.js';
import type { DefaultListSearchParams } from '../Table/index.js';

import './InvoiceList.scss';

export interface InvoiceListProps {
  billingAccounts?: BillingAccountHeader[];
  category: string;
  extraEmptySearchResultLink?: ClickableText;
  invoices?: InvoiceHeader[];
  total?: number;
}

interface InvoiceHeaderWithBa extends InvoiceHeader {
  billingAccountDisplayId?: string;
}

const createColumns = (stateColumn: CompositeListColumn<Invoice>): CompositeListColumn<Invoice>[] => [
  {
    columnId: 'invoiceDisplayId',
    heading: t.BRFX('Invoice number'),
    headingHideOnMobile: true,
    ref: 'invoiceDisplayId',
    sortable: true,
  },
  {
    columnId: 'due',
    heading: t.LA93(dueDateMsg),
    ref: 'due',
    refFormatNumber: formatTimestampToUTCDDMMYYYY,
    sortable: true,
  },
  stateColumn,
  {
    columnId: 'sum',
    heading: t.P4RQ('Amount'),
    sortable: true,
    value: (invoice: Invoice) => {
      return [
        <>
          <div className="of-invoice-list sum">{formatSum(invoice.sum)}</div>
          <span className="ea-disclaimertext of-invoice-list disclaimer">
            {t.RJ27(paidMsg)} {formatBalance(invoice.sum, invoice.balance || 0)}
          </span>
        </>,
      ];
    },
  },
];

const nonFilterableStateColumn = (): CompositeListColumn<Invoice> => ({
  columnId: 'state',
  heading: t.ASQT(statusMsg),
  headingHideOnMobile: true,
  value: (invoice: Invoice) => getInvoiceState(invoice.balance || 0, invoice.due).text,
  valueLineClasses: (invoice: Invoice) => [
    'ea-disc',
    'ea-disc--small',
    `ea-disc--${getInvoiceState(invoice.balance || 0, invoice.due).color}`,
  ],
});

// NOTE: this invoiceListColumns plus things above are left for frontpage usage only, not used in actual component anymore.
export const invoiceListColumns = (): CompositeListColumn<Invoice>[] => createColumns(nonFilterableStateColumn());

export const InvoiceList = ({
  billingAccounts,
  category,
  extraEmptySearchResultLink,
  invoices,
  total,
}: InvoiceListProps) => {
  const navigate = useNavigate();
  const searchParams = useSearchParams<DefaultListSearchParams>();
  const { search } = searchParams;

  const invoicesWithBa = useMemo<InvoiceHeaderWithBa[] | undefined>(() => {
    if (!invoices) {
      return undefined;
    }
    if (!billingAccounts) {
      return invoices;
    }
    return invoices.map(item => ({
      ...item,
      billingAccountDisplayId: billingAccounts.find(ba => ba.billingAccountId === item.billingAccountId)
        ?.billingAccountDisplayId,
    }));
  }, [invoices, billingAccounts]);

  const onStateChange = (stateOption = ''): void => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(TableUrlParams.PAGE, '1');
    queryParams.set(TableUrlParams.OFFSET, '0');
    switch (stateOption) {
      case 'open':
        navigate(`${paths.INVOICES}?${queryParams}#${OPEN_INVOICES_HASH}`);
        break;
      case 'paid':
        navigate(`${paths.INVOICES}?${queryParams}#${PAID_INVOICES_HASH}`);
        break;
      default:
        navigate(`${paths.INVOICES}?${queryParams}`);
    }
  };

  const getEmptyListText = () => {
    if (category === 'open') {
      return `${t.HS4R('No')} ${t.FOKG('open')} ${t.VME0('invoices')}`;
    }
    if (category === 'paid') {
      return `${t.HS4R('No')} ${t.QVCE('paid')} ${t.VME0('invoices')}`;
    }
    return `${t.HS4R('No')} ${t.VME0('invoices')}`;
  };

  const dropDown = (
    <CL.Dropdown
      key={category}
      items={[
        { label: t.EY6A('All'), value: 'all' },
        { label: t.GMAP('Open'), value: 'open' },
        { label: t.UQHZ('Paid'), value: 'paid' },
      ]}
      selectedValue={category}
      className="of-table-header-dropdown"
      integrated={true}
      onValueChange={value => {
        onStateChange(value?.dataset?.value);
      }}
    />
  );

  const columns: CL.Column[] = [
    {
      key: 'invoiceDisplayId',
      label: t.BRFX('Invoice number'),
      sortable: true,
    },
    { key: 'billingAccountDisplayId', label: `${t.IFT9(billingAccountMsg)} *`, sortable: true },
    { key: 'payerName', label: `${t.PB6S(payerMsg)} *`, sortable: true },
    { key: 'due', label: t.LA93(dueDateMsg), sortable: true },
    {
      key: 'sum',
      label: t.P4RQ('Amount'),
      sortable: true,
    },
    { key: 'state', label: dropDown, sortable: false },
  ];

  /**
   * TODO:
   *  - Remove billing acount match once the billing account related fields come with the invoices from es.
   *  - Use matchedFields to highlight search.
   */
  const rows = invoicesWithBa?.map(invoice => {
    const billingAccount = billingAccounts?.find(ba => ba.billingAccountId === invoice.billingAccountId);
    return {
      billingAccountDisplayId: (
        <span>
          {billingAccount?.billingAccountDisplayId}
          <br />
          <div className="ds-font-size--small">{billingAccount?.billingAccountName}</div>
          <div className="ds-font-size--small">{billingAccount?.billingAccountExtensionName}</div>
        </span>
      ),
      payerName: (
        <span>
          {billingAccount?.payerName}
          <div className="ds-font-size--small">{billingAccount?.payerNameExtension}</div>
        </span>
      ),
      invoiceDisplayId: (
        <>
          <Link
            to={`${generatePath(paths.INVOICE, { invoiceId: invoice.invoiceDisplayId })}?companyId=${
              invoice.accountMasterId
            }`}
          >
            {highlightKeyword(invoice.invoiceDisplayId, search)}
          </Link>
          {search && (
            <div className="ds-font-size--small">
              {t.IFT9(billingAccountMsg)}: {highlightKeyword(invoice.billingAccountDisplayId || '', search)}
            </div>
          )}
        </>
      ),
      state: (
        <StatusColumn
          status={getStatusColumnInvoiceState(invoice.balance || 0, invoice.due).text}
          color={getStatusColumnInvoiceState(invoice.balance || 0, invoice.due).color}
        />
      ),
      due: formatTimestampToUTCDDMMYYYY(invoice.due),
      sum: (
        <span>
          {formatSum(invoice.sum)}
          <div className="ds-font-size--small">
            {t.RJ27(paidMsg)} {formatBalance(invoice.sum, invoice.balance || 0)}
          </div>
        </span>
      ),
    };
  });

  return (
    <Grid>
      <div className="of-invoice-list">
        <ListSearch
          className="ds-margin-top--4"
          onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
        />
        <Table columns={columns} noItemsText={getEmptyListText()} rows={rows || []} />
        <ListPagination totalItems={search && rows ? rows.length : total || 0} />
        {category !== 'all' && rows?.length === 0 && (
          <p>
            <CL.Button onClick={extraEmptySearchResultLink?.onClick}>{extraEmptySearchResultLink?.text}</CL.Button>
          </p>
        )}
      </div>
      <div className="ds-text--small ds-margin-top--4">
        * {t.YM3W('Showing the current information of the billing account.')}
      </div>
    </Grid>
  );
};
