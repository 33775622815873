import { ChangeBillingAccountDialog } from '../ChangeBillingAccountDialog/ChangeBillingAccountDialog.js';
import { ContactDropdown } from '../../common/react-hook-form/fields/ContactDropdown.js';
import { EppRedeemTerminateRequestType } from '../../generated/api/models.js';
import { FormProvider, useForm } from 'react-hook-form';
import { Loading } from '../Loading/index.js';
import {
  PersonalBillingDetails,
  createPersonBillingDetailsData,
} from '../PersonalBillingDetails/PersonalBillingDetails.js';
import { RedeemOptionDetails } from './EppRedeemOptionDetails.js';
import { RedeemOptions } from './EppRedeemOptions.js';
import { WizardActions } from '../WizardActions/index.js';
import { WizardType } from '../../common/enums.js';
import {
  approverContactMsg,
  cancelMsg,
  eppRedeemOptionTitleMsg,
  forTheCompanyMsg,
  forTheEmployeeMsg,
  redeemIsBinding,
  t,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { findPersonBillingAccount } from '../../common/utils/stateUtils.js';
import { loadBillingAccounts, loadContacts } from '../../selfservice/actions/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useEppRedeemForwardClick } from './EppRedeemUtils.js';
import { useLocation, useNavigate } from 'react-router-dom';
import type { AuthenticatedUserState, BillingAccountsState } from '../../common/types/states.js';
import type { Contact, Subscription } from '../../generated/api/models.js';
import type { EmployeeSubscription } from '../../common/types/subscription.js';
import type { State } from '../../selfservice/common/store.js';

import './EppRedeem.scss';

export interface EppRedeemProps {
  billingAccounts?: BillingAccountsState | null;
  companyName?: string;
  contacts?: Contact[];
  defaultRedeemType?: EppRedeemTerminateRequestType; // For storybook use
  email?: string;
  firstName?: string;
  isRedeemedByAdmin: boolean;
  lastName?: string;
  loggedInUserContactId?: string;
  mobile?: string;
  onBackClick: () => void;
  subscription: EmployeeSubscription | Subscription;
}

const getApproverOptions = (contacts: Contact[] = [], contactIdToFilterOut?: string): Contact[] =>
  contactIdToFilterOut ? contacts.filter(item => item.contactId !== contactIdToFilterOut) : contacts;

export const EppRedeem = ({
  billingAccounts,
  companyName,
  contacts,
  defaultRedeemType,
  email,
  firstName,
  isRedeemedByAdmin,
  lastName,
  loggedInUserContactId,
  mobile,
  onBackClick,
  subscription,
}: EppRedeemProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [approverContact, setApproverContact] = useState('');
  const dialog = useSelector((state: State) => state.dialog, deepEqual);

  const [redeemType, setRedeemType] = useState<EppRedeemTerminateRequestType>(
    defaultRedeemType ||
      (isRedeemedByAdmin ? EppRedeemTerminateRequestType.COMPANY_REDEEM : EppRedeemTerminateRequestType.EMPLOYEE_REDEEM)
  );
  const [billingAccountId, setBillingAccountId] = useState(subscription.billingAccountId);
  const [billingAccountDisplayId, setBillingAccountDisplayId] = useState(subscription.billingAccountDisplayId);
  const personBillingAccount = findPersonBillingAccount(billingAccounts || undefined);
  const personBillingDetailsData = createPersonBillingDetailsData(
    {
      firstName,
      lastName,
      email,
      mobile,
    } as AuthenticatedUserState,
    personBillingAccount
  );
  const methods = useForm({ values: personBillingDetailsData });
  const personalBillingDetails = methods.watch();

  const forwardClick = useEppRedeemForwardClick({
    billingAccountId,
    approverContact,
    isRedeemedByAdmin,
    redeemType,
    subscription,
    companyName,
    personBillingAccount,
    personalBillingDetails,
    firstName,
    lastName,
  });

  useEffect(() => {
    if (!isRedeemedByAdmin) {
      if (!billingAccounts?.items) {
        dispatch(loadBillingAccounts());
      }
      if (!contacts) {
        dispatch(loadContacts());
      }
    }
  }, [billingAccounts?.items, contacts, dispatch, isRedeemedByAdmin]);

  useEffect(() => {
    const baId = location.state?.updatedBillingAccount?.billingAccountId;
    const baDisplayId = location.state?.updatedBillingAccount?.billingAccountDisplayId;
    if (baId && baDisplayId) {
      setBillingAccountId(baId);
      setBillingAccountDisplayId(baDisplayId);
    }
  }, [location.state]);

  return (billingAccounts?.items && contacts) || isRedeemedByAdmin ? (
    <div className="of-epp-redeem">
      {location?.state?.type === WizardType.CHANGE_EPP_REDEEM_BILLING_ACCOUNT && (
        <ChangeBillingAccountDialog
          billingAccounts={billingAccounts!}
          billingAccountId={billingAccountId!}
          changeRequestInProgress={dialog?.submitInProgress}
          headerText={t.H41V('Change invoicing agreement')}
          description=""
          detailedView={true}
          searchable={true}
          onBeginCreateBillingAccount={() => {
            navigate(paths.BILLING_ACCOUNTS_NEW_BA, {
              state: {
                subscription,
                subscriptionId: subscription.subscriptionDisplayId,
                type: WizardType.CHANGE_EPP_REDEEM_BILLING_ACCOUNT,
                originURL: location.pathname,
              },
            });
          }}
          onCloseDialog={() => {
            navigate(''); // Removes `history.state`, thus closing the dialog.
          }}
          onSubmit={(newBillingAccountId: string, newBillingAccountDisplayId: string) => {
            if (newBillingAccountId !== billingAccountId) {
              setBillingAccountId(newBillingAccountId);
              setBillingAccountDisplayId(newBillingAccountDisplayId);
            }
            navigate(''); // Removes `history.state`, thus closing the dialog.
          }}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(forwardClick)}>
          <div className="ds-padding-top--2 ds-padding-bottom--5">
            {`${
              isRedeemedByAdmin
                ? t.D9NS(
                    `You can redeem the device for the company or make a redemption request on behalf of the employee in case an employee has Omalaitelasku in use. The redemption price is calculated in real time. The employee can also redeem the device himself / herself from the Employee's OmaElisa at yrityksille.elisa.fi/työntekijä.`
                  )
                : t.CW9X(
                    'Redemption requires the approval of your employer. Redemption request is automatically sent to your employer. After approval, the invoice will be sent to you via email.'
                  )
            } ${t.UNYV(redeemIsBinding)}`}
          </div>
          {isRedeemedByAdmin && subscription.details?.device?.omaLaiteLasku && (
            <RedeemOptions
              titleText={t.VJNB(eppRedeemOptionTitleMsg)}
              companyLabel={t.VS0Z(forTheCompanyMsg)}
              employeeLabel={t.ACGC(forTheEmployeeMsg)}
              onChange={(selectedType: EppRedeemTerminateRequestType) => setRedeemType(selectedType)}
              selectedOption={redeemType}
            />
          )}
          <RedeemOptionDetails
            billingAccountId={billingAccountId}
            billingAccountDisplayId={billingAccountDisplayId}
            subscription={subscription}
            selectedOption={redeemType}
            companyName={companyName}
          />
          {!isRedeemedByAdmin && (
            <>
              <ContactDropdown
                canAddNewContacts={false}
                className="ds-margin--0"
                name="approverContact"
                labelText={t.GOZ7(approverContactMsg)}
                contacts={getApproverOptions(contacts, loggedInUserContactId)}
                onChangeContact={setApproverContact}
              />
              <PersonalBillingDetails editing={personBillingAccount === undefined} data={personBillingDetailsData} />
            </>
          )}
          <hr className="ds-margin-vertical--4" />
          <WizardActions
            forwardButtonText={t.I62A('Continue')}
            backButtonText={t.B2V1(cancelMsg)}
            onForwardClick={() => undefined}
            onBackClick={onBackClick}
          />
        </form>
      </FormProvider>
    </div>
  ) : (
    <Loading />
  );
};
