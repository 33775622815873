import { CatalogProductsSelection } from '../../../../../components/CatalogProductsSelection/CatalogProductsSelection.js';
import { Loading } from '../../../../../components/Loading/index.js';
import { OnlineModelCategory } from '../../../../../generated/api/models.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { loadOnlineModels } from '../../../../../selfservice/actions/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { toCatalogProductsPerCategory } from '../../../../../common/utils/catalogUtils.js';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { CatalogParams } from '../../../../../components/CatalogConfiguration/CatalogConfiguration.js';
import type { CatalogProduct } from '../../../../../common/utils/catalogUtils.js';
import type { OnlineModelHeadersState } from '../../../../../common/types/states.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const isOnlineModelHeaderMissing = (category: string, onlineModelHeaders?: OnlineModelHeadersState | null) => {
  return !onlineModelHeaders || !onlineModelHeaders.items || !onlineModelHeaders.items[category];
};

export const CompanyInfoCatalogListEditPath = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const config = useStore<State>().getState().config;
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  const onlineModelHeaders = useSelector((state: State) => state.selfservice?.onlineModelHeaders, deepEqual);

  useEffect(() => {
    if (!location.state) {
      navigate(paths.COMPANY_INFO_CATALOGS, { replace: true });
    }
  }, [location.state, navigate]);

  useEffect(() => {
    dispatch(loadOnlineModels(OnlineModelCategory.PHONE, true));
  }, [dispatch]);

  if (isOnlineModelHeaderMissing(OnlineModelCategory.PHONE, onlineModelHeaders) || !companyInfo?.discountedPrices) {
    return <Loading />;
  }

  return (
    <CatalogProductsSelection
      products={toCatalogProductsPerCategory(
        location.state.catalog,
        false,
        companyInfo.discountedPrices,
        onlineModelHeaders
      )}
      onSetProductsForCatalog={(catalogProducts: Record<string, Array<CatalogProduct>>) => {
        const catalogParams: CatalogParams = { ...location.state, products: catalogProducts };
        navigate(paths.COMPANY_INFO_CATALOG_LIST_INFO, { state: catalogParams });
      }}
      preSelectedProducts={location.state.products}
      contractPeriod={location.state.catalog.contractPeriod}
      onClickProductCategory={category => {
        if (isOnlineModelHeaderMissing(category, onlineModelHeaders)) {
          dispatch(loadOnlineModels(category, true));
        }
      }}
      imagesBaseUrl={config.imagesBaseUrl}
    />
  );
};
