import * as CL from '@design-system/component-library';

import './IconButton.scss';

interface IconButtonProps {
  label: string | JSX.Element;
  beginIcon?: string;
  classNames?: string;
  buttonKey: string;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
}

export const IconButton = ({
  label,
  beginIcon,
  classNames,
  buttonKey,
  onClick,
  disabled = false,
  loading,
}: IconButtonProps) => {
  return (
    <div className="of-icon-button-container">
      <CL.Button
        key={buttonKey}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        className={['of-icon-button__button', classNames].join(' ')}
        type="button"
        color="transparent"
        size="l"
      >
        <>
          {beginIcon && (
            <CL.Icon
              className="of-icon-button__button__begin-button-icon ds-font-weight--600 ds-margin-right--1"
              size="l"
              color="blue-600"
              icon={beginIcon}
            />
          )}
          <label className="of-icon-button__button__label ds-font-weight--400 ds-display--inline-block">{label}</label>
        </>
      </CL.Button>
    </div>
  );
};
