import { BillingAccountSourceSystem } from '../../../common/utils/billingAccountUtils.js';
import { CsvReportItem } from './CsvReportItem.js';
import { SubscriptionCategory } from '../../../common/enums.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import {
  isDevicesLoaded,
  isDevicesPartiallyLoaded,
  isLoaded,
  isOrdersLoaded,
  populateRequiredOrderDetails,
} from './reportsUtil.js';
import {
  loadBillingAccounts,
  loadContacts,
  loadCustomerOrders,
  loadSubscriptions,
} from '../../../selfservice/actions/index.js';
import { useAuth } from '../../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { BillingAccount, Contact, SubscriptionHeader } from '../../../generated/api/models.js';
import type { CustomerOrderReportingType } from './reportsUtil.js';
import type { ReportItemProps } from './ReportItem.js';
import type { State } from '../../../selfservice/common/store.js';

interface DeviceReportProps extends Omit<ReportItemProps, 'onClick' | 'loading'> {
  fileName: string;
  prepareReport: (
    companyName: string,
    reportingOrderData: Record<string, CustomerOrderReportingType>,
    deviceSubscriptions: SubscriptionHeader[],
    billingAccounts: BillingAccount[],
    contacts: Contact[]
  ) => string[][];
}

export const DeviceReport = ({ prepareReport, fileName, itemClass, heading, description }: DeviceReportProps) => {
  const dispatch = useDispatch();
  const { authenticatedUser } = useAuth();
  const { billingAccounts, contacts, customerOrders, deviceSubscriptions } = useSelector(
    (state: State) => ({
      billingAccounts: state.selfservice?.billingAccounts,
      contacts: state.selfservice?.contacts,
      customerOrders: state.selfservice?.customerOrders,
      deviceSubscriptions: state.selfservice?.subscriptions?.device,
    }),
    deepEqual
  );

  useEffect(() => {
    // load all subscriptions in case of more than 30 subscriptions
    if (isDevicesPartiallyLoaded(deviceSubscriptions)) {
      dispatch(loadSubscriptions({ category: SubscriptionCategory.DEVICE, reporting: true, getAllItems: true }));
    }
  }, [dispatch, deviceSubscriptions?.total]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CsvReportItem
      isAllDataLoaded={() =>
        isOrdersLoaded(customerOrders) &&
        isDevicesLoaded(deviceSubscriptions) &&
        isLoaded(billingAccounts) &&
        isLoaded(contacts)
      }
      loadData={() => {
        if (!isDevicesLoaded(deviceSubscriptions)) {
          dispatch(loadSubscriptions({ category: SubscriptionCategory.DEVICE, reporting: true, forceLoad: true }));
        }
        if (!isOrdersLoaded(customerOrders)) {
          dispatch(loadCustomerOrders(undefined, undefined, undefined, undefined, true, true));
        }
        if (!isLoaded(contacts)) {
          dispatch(loadContacts());
        }
        if (!isLoaded(billingAccounts)) {
          dispatch(
            loadBillingAccounts(
              undefined,
              undefined,
              undefined,
              true,
              BillingAccountSourceSystem.SFDC,
              true,
              undefined,
              true
            )
          );
        }
      }}
      prepareReport={() =>
        prepareReport(
          authenticatedUser!.companyName!,
          populateRequiredOrderDetails(customerOrders?.items, customerOrders?.searchResults),
          deviceSubscriptions?.searchResults?.map(sub => sub.result) ?? [],
          billingAccounts?.items ?? [],
          contacts?.items ?? []
        )
      }
      sources={[
        billingAccounts?.items?.length,
        contacts?.items?.length,
        customerOrders?.searchResults?.length,
        deviceSubscriptions?.searchResults?.length,
      ]}
      fileName={fileName}
      itemClass={itemClass}
      heading={heading}
      description={description}
    />
  );
};
