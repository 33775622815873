import * as CL from '@design-system/component-library';
import { ConfirmationDialog } from '../ConfirmationDialog/index.js';
import { EppRedeemConfirmation } from '../EppRedeemConfirmation/EppRedeemConfirmation.js';
import { getRedeemPrice } from './EppRedeemUtils.js';
import { payerMsg, t } from '../../common/i18n/index.js';
import { useState } from 'react';
import type { CommonError } from '../../common/types/errors.js';
import type { EppRedeemDialogParams } from '../../common/types/dialog.js';
import type { EppRedeemTerminateRequestType } from '../../generated/api/models.js';

import './EppRedeem.scss';

export type EppRedeemDialogProps = {
  errors?: CommonError[];
  submitInProgress?: boolean;
  onCloseDialog: () => void;
  params: EppRedeemDialogParams;
};

const getHeader = (redeemType: EppRedeemTerminateRequestType): string => {
  switch (redeemType) {
    case 'COMPANY_REDEEM':
      return t.MGPN('Confirm the redemption of the device to the company');
    case 'EMPLOYEE_REDEEM_BY_ADMIN':
      return t.OXI7('Confirm the redemption of the device to the employee');
    default:
      return t.WQ7L('Confirm redemption of the device');
  }
};

export const EppRedeemDialog = ({ errors, submitInProgress, onCloseDialog, params }: EppRedeemDialogProps) => {
  const [isRedeemTermsConfirmed, setRedeemTermsConfirmed] = useState<boolean>();

  const onConfirm = () => {
    if (isRedeemTermsConfirmed) {
      params.onConfirm();
    } else {
      setRedeemTermsConfirmed(false);
    }
  };

  return (
    <ConfirmationDialog
      body={
        <div className="of-epp-redeem-dialog">
          <CL.Description
            className="ds-margin-vertical--5"
            items={[
              {
                title: t.UDSQ('Redeemable device'),
                description: params.subscription.subscriptionName,
              },
              {
                title: t.PB6S(payerMsg),
                description: params.payerName,
              },
              {
                title: t.MH8K('Redemption price'),
                description: getRedeemPrice(
                  params.redeemType,
                  params.subscription.details!.device!.eppModificationPrices!
                ),
              },
            ]}
          />

          <EppRedeemConfirmation
            className="ds-padding-bottom--4"
            confirmed={isRedeemTermsConfirmed}
            onChange={setRedeemTermsConfirmed}
          />
        </div>
      }
      errors={errors}
      header={getHeader(params.redeemType)}
      isLoading={submitInProgress}
      onCloseDialog={onCloseDialog}
      onConfirm={onConfirm}
    />
  );
};
