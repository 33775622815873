import { BreadCrumbs } from '../BreadCrumbs/BreadCrumbs.js';
import { DialogContainer } from '../SelfService/selfServiceDialogs.js';
import { EmptyOrError, getErrorPropsFromCommonErrors } from '../EmptyOrError/EmptyOrError.js';
import { ModelType } from '../../common/enums.js';
import { OnlineModelCategory } from '../../generated/api/models.js';
import { Outlet } from 'react-router-dom';
import { ScrollToTopWhenSearchParamsChange } from '../../common/utils/browserUtils.js';
import { corporateCustomersMsg, internetSubscriptionsMsg, subscriptionsMsg, t } from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import {
  loadOnlineModelByModelType,
  loadOnlineModels,
  resetAddressSearchResult,
  resetCompanySearchResult,
  resetErrors,
} from '../../selfservice/actions/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../selfservice/common/store.js';

import './FixedBroadbandOrder.scss';

interface FixedBroadbandOrderProps {
  currentPath: string;
  disableBreadcrumbs: boolean;
}

const getResetHistoryStack = (page: string) => {
  switch (page) {
    case paths.ANONYMOUS_FIXED_BROADBAND_ORDER_CONFIRMATION:
      return -3;
    case paths.ANONYMOUS_FIXED_BROADBAND_ORDER_CHECKOUT:
      return -2;
    default:
      return -1;
  }
};

export const FixedBroadbandOrder = ({ currentPath, disableBreadcrumbs }: FixedBroadbandOrderProps) => {
  const dispatch = useDispatch();
  const errors = useSelector(
    (state: State) => state.selfservice?.onlineModels?.errors || state.selfservice?.fixedBroadbandOrders?.errors,
    deepEqual
  );

  useEffect(() => {
    dispatch(loadOnlineModels(OnlineModelCategory.FIXED_BROADBAND, false));
    dispatch(loadOnlineModelByModelType(ModelType.MobileBroadband));
  }, [dispatch]);

  const reset = () => {
    dispatch(resetAddressSearchResult());
    dispatch(resetCompanySearchResult());
    dispatch(resetErrors());
    history.go(getResetHistoryStack(currentPath));
  };

  const apiErrors = getErrorPropsFromCommonErrors(errors);

  return (
    <>
      <ScrollToTopWhenSearchParamsChange />
      <div className="of-fixed-broadband-order">
        {disableBreadcrumbs || (
          <BreadCrumbs
            breadCrumbPaths={[
              { name: t.KBLX(corporateCustomersMsg), path: '/' },
              { name: t.XQ5R(subscriptionsMsg), path: paths.SUBSCRIPTIONS_PUBLIC },
              { name: t.SEYV(internetSubscriptionsMsg), path: paths.INTERNET_SUBSCRIPTIONS_PUBLIC },
              { name: 'Netti Lite', path: paths.NETTI_LITE },
              { name: t.CZ89('Availability') },
            ]}
          />
        )}
        <div className="of-fixed-broadband-order__content ds-padding-bottom--6">
          {apiErrors ? (
            <div className="ds-padding-top--10">
              <EmptyOrError
                bottomElement={apiErrors.bottomElement}
                buttonText={apiErrors.buttonText}
                critical={apiErrors.critical}
                id={apiErrors.id}
                onButtonClick={reset}
                text={apiErrors.text}
              />
            </div>
          ) : (
            <Outlet />
          )}
        </div>
        <DialogContainer />
      </div>
    </>
  );
};
