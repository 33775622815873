import * as CL from '@design-system/component-library';
import type { ReactNode } from 'react';

export interface InfoFieldProps {
  name?: string;
  label: string;
  toolTipText?: string;
  highlight?: boolean;
  value: ReactNode;
}

import '../BillingAccountDetails.scss';

export const InfoFieldWithTooltip = ({ label, toolTipText, value, name, highlight }: InfoFieldProps) => {
  return (
    <div className="ds-padding-bottom--2" id={name}>
      <label className="ds-input--labelarea-label">{label}</label>
      {toolTipText && (
        <span className="ds-padding-left--2 of-billing-account-details tooltip">
          <CL.Tooltip
            triggerElement={<CL.Icon color="brand-blue" icon="information" size="s" />}
            placement="top"
            i18n_tooltip_contentText={toolTipText}
            className="ds-padding-left-3 ds-margin-left--3 ds-tooltip--background-color=white"
          />
        </span>
      )}
      <div className="of-info-field__value">
        <span className={highlight ? 'of-info-field__highlight of-info-field__highlight-text' : ''}>
          {value ?? '—'}
        </span>
        {highlight && <CL.Icon color="warning" className="of-info-field__highlight" icon="check" />}
      </div>
    </div>
  );
};
