import * as CL from '@design-system/component-library';
import { FormStateEnum } from '../../common/formik/index.js';
import { Loading } from '../Loading/index.js';
import { POSTAL_CODE_REGEX } from '../../common/utils/validationUtils.js';
import { PostalCodeInput } from '../PostalCodeInput/PostalCodeInput.js';
import { Search } from '../Search/Search.js';
import { SiteContext } from '../../public/site/SiteContext.js';
import {
  addEmptyFieldValidationError,
  addValidationError,
  convertToErrorStringMap,
} from '../../common/utils/errorUtils.js';
import {
  customerServiceNumberLabelMsg,
  customerServiceNumberOpenShortMsg,
  searchMsg,
  t,
} from '../../common/i18n/index.js';
import { resetAddressSearchResult, searchAddress } from '../../selfservice/actions/index.js';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AddressSearchResponse } from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';
import type { State } from '../../selfservice/exports.js';

import './AddressSearch.scss';

export interface AddressSearchMatch {
  addressText: string;
  postalCode: string;
  addressId: string;
}

export interface AddressSearchResult extends AddressSearchResponse {
  postalCode: string;
}

export interface AddressSearchProps {
  addressSearchResult?: AddressSearchResult;
  initialPostalCodeValue?: string;
  initialStreetAddressValue?: string;
  onSubmit: (address: string, postalCode: string) => void;
}

const getErrorsFromAddressSearch = (postalCode?: string, addressSearchResult?: AddressSearchResult) => {
  const errors: CommonError[] = [];
  if (postalCode && !POSTAL_CODE_REGEX.test(postalCode)) {
    addValidationError(errors, t.BX0B('Enter a 5-digit postal code'), 'postalCode');
  } else if (!addressSearchResult?.match?.addressId) {
    addEmptyFieldValidationError(errors, 'streetAddress');
  }
  return errors;
};

export const AddressSearch = ({
  addressSearchResult,
  initialPostalCodeValue,
  initialStreetAddressValue,
  onSubmit,
}: AddressSearchProps) => {
  const { siteLanguage } = useContext(SiteContext);
  const dispatch = useDispatch();
  const [postalCode, setPostalCode] = useState(initialPostalCodeValue || '');
  const [validationErrors, setValidationErrors] = useState<{ [s: string]: string }>();
  const streetAddressInput = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [streetSearchValue, setStreetSearchValue] = useState(initialStreetAddressValue || '');
  const commercialAvailabilityState = useSelector((state: State) => state.resources?.commercialAvailabilityState);

  useEffect(() => {
    if (streetAddressInput?.current && POSTAL_CODE_REGEX.test(postalCode)) {
      streetAddressInput.current.focus();
    }
  }, [postalCode]);

  useEffect(() => {
    const addressSearchErrors = getErrorsFromAddressSearch(postalCode, addressSearchResult);
    setValidationErrors(addressSearchErrors.length ? convertToErrorStringMap(addressSearchErrors) : undefined);
  }, [siteLanguage, addressSearchResult, postalCode]);

  useEffect(() => {
    setIsLoading(commercialAvailabilityState === FormStateEnum.LOADING);
  }, [commercialAvailabilityState]);

  const checkForErrorsAndSubmit = () => {
    const errors = getErrorsFromAddressSearch(postalCode, addressSearchResult);
    if (errors.length) {
      setValidationErrors(convertToErrorStringMap(errors));
    } else {
      onSubmit(streetSearchValue || '', postalCode);
    }
  };

  const onSearch = useCallback(
    (searchText: string) => {
      dispatch(searchAddress(searchText.trim(), postalCode));
    },
    [postalCode, dispatch]
  );

  const getSearchResults = () => {
    if (addressSearchResult?.matchedStreetNames?.length) {
      return addressSearchResult.matchedStreetNames.map(streetName => ({
        key: streetName,
        displayText: streetName + ' ',
      }));
    } else if (addressSearchResult?.matchedAddresses?.length) {
      return addressSearchResult.matchedAddresses
        .filter(({ addressText }) => addressText !== addressSearchResult?.match?.addressText)
        .map(({ addressId, addressText }) => ({
          key: addressId,
          displayText: addressText,
        }));
    }

    return [];
  };

  return (
    <div className="of-address-search__container">
      <h3 className="ds-h3">{t.ZE18('What address do you need the Internet for?')}</h3>
      <div className="of-address-search__search-row">
        {commercialAvailabilityState === FormStateEnum.FAILED && (
          <div className="ds-margin-bottom--3 ds-color--red-600 ds-font-size--small">
            {t.UG6S(
              'An error occurred while checking availability. Please try again after a while. If you have an urgent matter, you can contact us via chat or call customer service on {0} ({1}).',
              customerServiceNumberLabelMsg,
              t.HQWU(customerServiceNumberOpenShortMsg)
            )}
          </div>
        )}
        <div className="of-address-search__postal-code">
          <PostalCodeInput
            postalCode={postalCode}
            setPostalCode={newCode => {
              setStreetSearchValue('');
              setPostalCode(newCode);
              dispatch(resetAddressSearchResult());
            }}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
          />
        </div>
        <Search
          value={streetSearchValue}
          setValue={setStreetSearchValue}
          disabled={!POSTAL_CODE_REGEX.test(postalCode)}
          emptyResultsLabel={t.J6P7('Enter the full address, including the door number')}
          initialValue={initialStreetAddressValue}
          inputLabel={t.GL4R('Street address, e.g. Ritarikatu 42 C 6')}
          inputPlaceholder={t.MX5U('Street address and number')}
          inputRef={streetAddressInput}
          minimumCharacters={1}
          onSearch={onSearch}
          searchResults={getSearchResults()}
          showSuccessIcon={Boolean(streetSearchValue) && !validationErrors?.streetAddress}
          validationError={validationErrors?.streetAddress}
        />
        <div className="of-address-search__search-button-wrapper ds-padding-top--1 ds-display--flex ds-align-items--flex-start">
          <CL.Button
            className="of-address-search__search-button"
            onClick={checkForErrorsAndSubmit}
            disabled={!postalCode || !streetSearchValue || Boolean(validationErrors) || isLoading}
            size="l"
          >
            {t.VQE6(searchMsg)}
          </CL.Button>
          {isLoading && (
            <span className="of-address-search__spinner ds-margin-left--2">
              <Loading topPadding={0} />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
