import * as CL from '@design-system/component-library';
import { Esim } from './Esim.js';
import { EsimPrintout } from './EsimPrintout.js';
import { Printable } from './Printable.js';
import { QrCodeImg } from '../QrCodeImg/QrCodeImg.js';
import { t } from '../../common/i18n/index.js';
import type { Subscription } from '../../generated/api/models.js';

import './EsimActivation.scss';

export const copyToClipboard = (text: string) => {
  const el = document.createElement('textarea');
  el.innerText = text;

  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  el.remove();
};

const ClipboardCopyButton = ({ data, label }: { data: string; label: string }) => (
  <>
    <CL.Link className="of-esim-activation__copy" onClick={() => copyToClipboard(data)}>
      {label}
    </CL.Link>
    : <span className="of-esim-activation__break-word">({data})</span>
  </>
);

export const EsimActivationQR = ({ subscription }: { subscription: Subscription }) => {
  const mobileDetails = subscription.details?.mobile;

  const eSim = new Esim(mobileDetails?.eSimQrActivationCode || '');
  const esimPrint = new Printable(<EsimPrintout subscription={subscription} />);

  return (
    <>
      <p>
        <b>{t.HSJ0('Scan QR code')}</b>{' '}
        {t.IG52(
          'or copy activation code. Your current SIM card stops working immediately after the new one is activated.'
        )}
      </p>
      <div className="of-esim-activation__qr ds-grid__row ds-padding-vertical--4">
        <div className="ds-grid__col--s-6 ds-grid__col--l-3">
          <QrCodeImg src={eSim.getQrCodeUrl()} />
        </div>
        <div className="of-esim-activation__qr-instructions ds-grid__col--s-6 ds-grid__col--l-9 ds-justify-content--flex-start ds-align-items--center">
          <ol>
            <li>{t.YI9C(`Open your phone's camera and point it at the QR code next to it.`)}</li>
            <ul>
              <li>{t.EO7W('For devices other than phones follow the device instructions for eSIM activation.')}</li>
            </ul>
            <li>{t.WE32('Follow the instructions provided by the device.')}</li>
          </ol>
        </div>
      </div>
      <div>
        <p>
          <b>{t.IN3U('Data for manual input')}</b>
          <br />
          <ClipboardCopyButton data={eSim.getSmdpAddress()} label={t.JGV8('Copy SM-DP+ address')} />
          <br />
          <ClipboardCopyButton data={eSim.getAcToken()} label={t.JTXG('Copy activation code')} />
        </p>

        <p>
          <CL.Link onClick={() => esimPrint.print()}>{t.JWO2('Print QR code')}</CL.Link>
        </p>
      </div>
    </>
  );
};
