import {
  TypeKeys,
  loadInvoiceDocumentsFailed,
  loadInvoiceDocumentsFulfilled,
  loadInvoicesFailed,
  loadInvoicesFulfilled,
} from '../actions/index.js';
import { actionToActionState, actionToActionStateFromMultiple } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import { getDocumentsPrivateMethod, getInvoicesPrivateMethod } from '../../generated/api/uiApiMethods.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { InvoicesResponse } from '../../generated/api/models.js';
import type { ItemsQuery, State } from '../common/store.js';
import type { LoadInvoiceDocumentsAction, LoadInvoicesAction, SelfServiceActionTypes } from '../actions/index.js';

const getBalanceRange = (category?: string): string | undefined => {
  if (!category) {
    return undefined;
  }
  switch (category) {
    case 'open':
      return 'positive';
    case 'paid':
      return 'zeroOrNegative';
    case 'all':
    default:
      return undefined;
  }
};

/**
 * Load invoices, Ajax load epic. Added logic to not do getJSON, if all invoices are already loaded.
 */
export const loadInvoicesEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOAD_INVOICES)), (action: LoadInvoicesAction) =>
    actionToActionStateFromMultiple(action, state$, 'invoices', action.category)
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state || !actionAndState.state.query) {
        throw new Error('invalid action state for loading invoices: query missing');
      }
      const loadInvoicesAction = actionAndState.action as LoadInvoicesAction;
      const query: ItemsQuery = actionAndState.state.query;
      const balanceRange = getBalanceRange(loadInvoicesAction.category);
      const queryParams: { [s: string]: string } = {};
      if (balanceRange) {
        queryParams.balanceRange = balanceRange;
      }
      if (loadInvoicesAction.displayId) {
        queryParams.invoiceDisplayId = loadInvoicesAction.displayId;
      }
      const successAction = (res: InvoicesResponse) => {
        if (loadInvoicesAction.displayId && (!res.invoices || res.invoices.length === 0)) {
          return loadInvoicesFailed(
            `invoice ${loadInvoicesAction.displayId} could not be found`,
            404,
            undefined,
            loadInvoicesAction.category !== undefined ? { category: loadInvoicesAction.category } : undefined
          );
        }
        return loadInvoicesFulfilled(res, query, loadInvoicesAction.category, loadInvoicesAction.displayId);
      };
      const failureParams =
        loadInvoicesAction.category !== undefined ? { category: loadInvoicesAction.category } : undefined;

      query.offset = loadInvoicesAction.offset || 0;

      const method = getInvoicesPrivateMethod({
        ...query,
        ...queryParams,
      });
      return callUiApi({
        epicDependencies,
        failureAction: loadInvoicesFailed,
        failureParams,
        method,
        state$,
        successAction,
      });
    })
  );
};

export const loadInvoiceDocumentsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOAD_INVOICE_DOCUMENTS)), (action: LoadInvoiceDocumentsAction) =>
    actionToActionState(action, state$, 'invoiceDocuments')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state || !actionAndState.state.query) {
        throw new Error('invalid action state for loading invoice documents actions');
      }
      const { offset, limit } = actionAndState.action as LoadInvoiceDocumentsAction;
      const queryParams = { offset, limit };

      const method = getDocumentsPrivateMethod(queryParams);
      return callUiApi({
        epicDependencies,
        state$,
        method,
        failureAction: loadInvoiceDocumentsFailed,
        successAction: loadInvoiceDocumentsFulfilled,
      });
    })
  );

export const invoiceEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  loadInvoiceDocumentsEpic,
  loadInvoicesEpic
);
