import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { pushToDataLayer } from '../../common/analytics.js';
import { useEffect, useState } from 'react';
import type { SurveyGAPayload } from './types.js';

const handleHandleSubmissionDataLayerPush = () => {
  const payload: SurveyGAPayload = {
    event: 'form_submit',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    form_name: 'Tietoturvakysely',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    form_type: 'Marketing Cloud',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    form_category: 'Survey',
  };
  pushToDataLayer(payload);
};
export type CtaComponentProps = {
  responses: number[];
  targetBaseUrl: string;
};
export const CtaComponent = ({ responses, targetBaseUrl }: CtaComponentProps) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const params = new URLSearchParams();

  const surveyResultString = responses.reduce((acc, item, index) => [...acc, index, item], [] as number[]).join(',');
  params.set('survey', surveyResultString);
  const targetUrl = `${targetBaseUrl}?${params}`;
  useEffect(() => {
    if (!isInBrowser()) {
      return;
    }
    const handleMessage = (event: MessageEvent) => {
      if (event.origin === 'https://cloud.viestinta.elisa.fi' && event.data === 'formSubmitted') {
        setFormSubmitted(true);
        handleHandleSubmissionDataLayerPush();
      }
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  return (
    <section>
      <h2>
        Varaa ilmainen konsultaatio asiantuntijan kanssa, nyt 0€ kesäkampanja-hintaan muutamalle nopeimmalle varaajalle.
      </h2>
      {formSubmitted ? (
        <p>Kiitos vastauksestanne!</p>
      ) : (
        <iframe className="iframe" src={targetUrl} title="Yhteystietolomake"></iframe>
      )}
    </section>
  );
};
