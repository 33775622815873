import { SiteContext } from '../../../public/site/SiteContext.js';
import { furtherDetailPhonesAbroadMsg, t } from '../../../common/i18n/index.js';
import { useContext } from 'react';

export const DatavartijaTooltipBody = () => (
  <>
    <div className="ds-padding-top--4">
      <h4 className="ea-h4 ea-h4--no-fluid">{t.DIBP('Roaming Datavartija')}</h4>
      {t.VO2Q(
        'The service sets a €50 (VAT 0%) balance limit for roaming data transfers to the subscription when abroad. The service sends an SMS message when the balance limit is reached. You can change the limit (€50-€5,000) by SMS at any time, and the ordered limit will be valid from then on.'
      )}
      <p>
        {t.S74X(
          'If you want, you can track your data usage from your smartphone settings. Some phones also have counters that track internet usage.'
        )}
      </p>
      <p>{t.U65A('Datavartija does not affect the use of local WiFi connections, such as hotel WLANs.')}</p>
      <p>
        <a
          className="of-barrings-content__link"
          href={`${useContext(SiteContext).siteBaseUrl}/verkkovierailu-ulkomailla`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t.XHYX(furtherDetailPhonesAbroadMsg)}
          <i className="ea-icon ea-icon--northeast-arrow" />
        </a>
      </p>
    </div>
  </>
);
