import * as CL from '@design-system/component-library';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { TextInput } from '../../common/react-hook-form/fields/index.js';
import { confirmMsg, t } from '../../common/i18n/index.js';
import { requestMfaOtp, sendMfaOtp } from '../../common/fetch.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useEffect, useState } from 'react';

import './MfaOtp.scss';

export interface MfaOtpFormProps {
  phoneNumber: string;
}

export interface MfaOtpFormValues {
  otp: string;
}

const MfaOtpForm = ({ phoneNumber }: MfaOtpFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm();
  const { handleSubmit, control, setError, reset } = methods;
  const { isDirty } = useFormState({ control: control });
  const { validateAuth } = useAuth();

  useEffect(() => {
    const sendMfaOtpRequest = async () => {
      await requestMfaOtp();
    };
    sendMfaOtpRequest().catch(err => {
      throw err;
    });
  }, []);

  const handleSendNewCode = async () => {
    reset();
    await requestMfaOtp();
  };

  const onSubmitForm = async ({ otp }: MfaOtpFormValues) => {
    setIsSubmitting(true);
    try {
      const validOtp = await sendMfaOtp(otp);

      if (validOtp) {
        validateAuth();
      } else {
        setError('otp', { message: t.A00W('Wrong code given') });
        setIsSubmitting(false);
      }
    } catch (err) {
      setIsSubmitting(false);
      setError('otp', { message: t.A00Y('Unexpected error') });
    }
  };

  return (
    <FormProvider {...methods}>
      <form className="of-mfa-otp-form" onSubmit={handleSubmit(onSubmitForm)} noValidate>
        <div className="picto"></div>
        <h3 className="ds-h3">{t.A00R('Authentication required')}</h3>
        <p>
          {t.A00X(
            'To ensure the secure use of the service, we have sent you a verification code by SMS to the number {}. Please fill in the verification code in the fields below.',
            phoneNumber
          )}
        </p>
        <div className="ds-margin-vertical--5">
          <TextInput name="otp" className="ds-h5 otp-input" maxLength={8}></TextInput>
        </div>
        <div>
          <CL.Button color="link" size="s" block={false} onClick={handleSendNewCode}>
            {t.A00T('Send a new code')}
          </CL.Button>
        </div>
        <p>
          {t.A00U(
            `Didn't receive the text message? Contact your company's administrator to make sure your phone number is correct.`
          )}
        </p>
        <div className="ds-margin-top--6">
          <CL.Button type="submit" size="l" disabled={!isDirty} loading={isSubmitting}>
            {t.A00V(confirmMsg)}
          </CL.Button>
        </div>
      </form>
    </FormProvider>
  );
};

export interface MfaOtpProps {
  phoneNumber: string;
}
export const MfaOtp = ({ phoneNumber }: MfaOtpProps) => <MfaOtpForm phoneNumber={phoneNumber} />;
