import * as CL from '@design-system/component-library';
import { AuxiliaryEsimOrderFailureEnum } from '../auxiliaryEsimOrderEnums.js';
import { closeMsg, t } from '../../../common/i18n/index.js';
import { useSelector } from 'react-redux';
import type { State } from '../../../selfservice/common/store.js';

import './AuxiliaryEsimOrderError.scss';

export interface AuxiliaryEsimOrderErrorProps {
  onClose: () => void;
}

interface AuxiliaryEsimOrderErrorMessage {
  header: string;
  message: string;
  extra?: string;
}

export const AuxiliaryEsimOrderError = ({ onClose }: AuxiliaryEsimOrderErrorProps) => {
  const errorReason = useSelector(
    (state: State) =>
      state.selfservice?.auxiliaryEsimOrder?.errorReason || AuxiliaryEsimOrderFailureEnum.CREDIT_CHECK_ISSUE
  );

  const errorReasons = {
    [AuxiliaryEsimOrderFailureEnum.TOO_MANY_MULTISIMS]: {
      header: t.THKA('You cannot add this device to your subscription'),
      message: t.KGKV('You cannot add more devices to your subscription.'),
    },
    [AuxiliaryEsimOrderFailureEnum.CREDIT_CHECK_ISSUE]: {
      header: t.J6KQ('The service is not available for your subscription'),
      message: t.JL3U(`Please contact your organization's ordering contact person.`),
    },
    [AuxiliaryEsimOrderFailureEnum.ACCOUNT_LEVEL_BARRING]: {
      header: t.J6KQ('The service is not available for your subscription'),
      message: t.JL3U(`Please contact your organization's ordering contact person.`),
    },
    [AuxiliaryEsimOrderFailureEnum.CONFLICT_LANKANUMERO]: {
      header: t.FZNW('The installation of service is not possible'),
      message: t.IR4H(
        'Your mobile subscription has Elisa Lankanumero service which prevents the ordering of this service.'
      ),
      extra: t.JL3U(`Please contact your organization's ordering contact person.`),
    },
    [AuxiliaryEsimOrderFailureEnum.CONFLICT_MULTISIM]: {
      header: t.FZNW('The installation of service is not possible'),
      message: t.MYQX(
        'Your mobile subscription has Elisa MultiSIM service which prevents the ordering of this service.'
      ),
      extra: t.JL3U(`Please contact your organization's ordering contact person.`),
    },
    [AuxiliaryEsimOrderFailureEnum.CONFLICT_RING]: {
      header: t.FZNW('The installation of service is not possible'),
      message: t.PGZ4('Your mobile subscription has Elisa Ring service which prevents the ordering of this service.'),
      extra: t.JL3U(`Please contact your organization's ordering contact person.`),
    },
    [AuxiliaryEsimOrderFailureEnum.REQUIRED_SERVICE_MISSING]: {
      header: t.FZNW('The installation of service is not possible'),
      message: t.WP79(
        `Your mobile subscription doesn't have Elisa WiFi service which is a prerequisite for this service.`
      ),
      extra: t.JL3U(`Please contact your organization's ordering contact person.`),
    },
    [AuxiliaryEsimOrderFailureEnum.SUB_NOT_ACTIVE]: {
      header: t.J6KQ('The service is not available for your subscription'),
      message: t.JL3U(`Please contact your organization's ordering contact person.`),
    },
    [AuxiliaryEsimOrderFailureEnum.SALESFORCE_SUB]: {
      header: t.J6KQ('The service is not available for your subscription'),
      message: t.Z7FX('Unfortunately the service is not yet available to the subscription type in use.'),
    },
    [AuxiliaryEsimOrderFailureEnum.NO_DATA]: {
      header: t.J6KQ('The service is not available for your subscription'),
      message: t.SGLK('Information on your subscription is not found.'),
      extra: t.GDFF(`Please contact Elisa's customer service.`),
    },
    [AuxiliaryEsimOrderFailureEnum.CONNECTION_ERROR]: {
      header: t.U2AK('Connection error'),
      message: t.UC8X(`Please try again and if the issue continues, please contact Elisa's customer service.`),
    },
    [AuxiliaryEsimOrderFailureEnum.SUCCESS_NO_PAIRING]: {
      header: t.GZ5O('Your subscription has not been linked to your details in My OmaElisa'),
      message: t.YMLL('Please login to yrityksille.elisa.fi/työntekijä and add it via Voice subscription page.'),
      extra: t.TGPX(
        `However, if you already see your subscription in the employee portal, please contact Elisa's customer service.`
      ),
    },
    [AuxiliaryEsimOrderFailureEnum.ESIM_OPEN_ORDER]: {
      header: t.Z71F('You have an open order'),
      message: t.BRHY('Please retry after the open order has been completed.'),
    },
  };
  const error: AuxiliaryEsimOrderErrorMessage = errorReasons[errorReason];

  return (
    <>
      <div className="ea-color-container ea-color-container--attention of-auxiliary-esim-order-error">
        <div className="ea-color-container__content">
          <span className="ea-icon ea-icon--attention ea-icon--large" />
          <h4 className="ea-h4">{error.header}</h4>
          <span>{error.message}</span>
          {error.extra !== '' && <span>{error.extra}</span>}
        </div>
      </div>
      <CL.Button block onClick={onClose} size="m">
        {t.WOYD(closeMsg)}
      </CL.Button>
    </>
  );
};
