import { BreadCrumbsWithTitle } from '../../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { ModelType } from '../../../../../../common/enums.js';
import { OrderSubscriptionSelectionWrapper } from './OrderSubscriptionSelectionWrapper.js';
import { internetSubscriptionsMsg, omaElisaForCompaniesMsg, t } from '../../../../../../common/i18n/index.js';
import { paths } from '../../../../../../common/constants/pathVariables.js';

export const OrderM2MSubSelectionPath = () => (
  <>
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.SEYV(internetSubscriptionsMsg), path: paths.PS_BROADBAND_SUBSCRIPTIONS },
        { name: t.A7CH('New Laitenetti subscription') },
      ]}
    />
    <OrderSubscriptionSelectionWrapper onlineModelType={ModelType.MobileM2M} />
  </>
);
