import * as CL from '@design-system/component-library';
import { SUBSCRIPTION_CHANGE_SERVICE_FEE } from './content/SubscriptionCardContent.js';
import { SalesType } from '../../generated/api/models.js';
import { StickyFooter } from '../StickyFooter/index.js';
import {
  changeFeeMsg,
  continueMsg,
  formatNumber,
  monthMsg,
  orderMsg,
  quantityMsg,
  t,
  totalMsg,
} from '../../common/i18n/index.js';
import { getFormattedPricesWithAddons } from './utils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useNavigate } from 'react-router-dom';
import type { AddedAddon } from '../../common/types/addon.js';
import type { CampaignAssociation, CommercialProduct, Subscription } from '../../generated/api/models.js';
import type { SubscriptionUpdateNavigationState } from '../../public/site/path/SelfService/ProductsAndServices/MobileSubUpdateConfirmationPath.js';

interface OrderPriceProps {
  amount: number;
  selectedAddOns: AddedAddon[];
  isChangeOffer: boolean;
  selectedCampaignAssociation?: CampaignAssociation;
  selectedCommercialProduct?: CommercialProduct;
  campaignDescription?: string;
}

const OrderPrice = (props: OrderPriceProps) => {
  const { selectedCommercialProduct, amount, selectedAddOns, selectedCampaignAssociation, isChangeOffer } = props;
  if (!selectedCommercialProduct) {
    return null;
  }

  const { oneTimePrice, recurringPrice } = getFormattedPricesWithAddons(
    selectedCommercialProduct,
    amount,
    selectedAddOns,
    selectedCampaignAssociation,
    SalesType.NEW_SALE
  );

  return (
    <div className="of-order-price">
      <span className="ea-h3 ea-h3--thick">
        {t.CEQ2(totalMsg)}: {recurringPrice}/{t.XXVX(monthMsg)}{' '}
      </span>
      <span className="ea-h4">{t.JZ8R('(0% VAT)')}</span>
      <br />
      {isChangeOffer
        ? `${t.ZRKX(changeFeeMsg)} ${formatNumber(SUBSCRIPTION_CHANGE_SERVICE_FEE / 100)} €`
        : `${t.Y7WJ('Activation fees')}: ${oneTimePrice}`}
    </div>
  );
};

interface SubscriptionSelectionFooterProps extends OrderPriceProps {
  isProductSelected: boolean;
  handleAmountChanged: (value: number) => void;
  handleSubmit: () => void;
  selectedCommercialProduct?: CommercialProduct;
  subscription?: Subscription;
}

export const SubscriptionSelectionFooter = (props: SubscriptionSelectionFooterProps) => {
  const {
    isProductSelected,
    isChangeOffer,
    amount,
    selectedCommercialProduct,
    subscription,
    selectedAddOns,
    selectedCampaignAssociation,
    campaignDescription,
    handleAmountChanged,
    handleSubmit,
  } = props;
  const navigate = useNavigate();

  return (
    <StickyFooter active={isProductSelected} className="high-mobile">
      <CL.Grid>
        <CL.GridRow justifyCenter>
          <CL.GridCol colWidthXS={4} colWidthS={6} colWidthM={3} colWidthL={5}>
            <OrderPrice {...props} />
          </CL.GridCol>
          <CL.GridCol colWidthXS={4} colWidthS={6} colWidthM={3} colWidthL={5} className="ds-text-align--right">
            <form className="sticky-footer-form">
              {!isChangeOffer && (
                <>
                  <label>{t.M0W7(quantityMsg)}</label>
                  <CL.Quantity
                    minValue="1"
                    maxValue="99"
                    currentValue={amount}
                    onChange={value => handleAmountChanged(value)}
                  />
                </>
              )}
              <CL.Button
                size="l"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  isChangeOffer
                    ? navigate(paths.PS_MOBILE_SUBSCRIPTION_UPDATE_CONFIRMATION, {
                        state: {
                          selectedCommercialProduct: { ...selectedCommercialProduct, oneTimeCharge: 0 },
                          subscription,
                          selectedAddOns,
                          campaignAssociation: selectedCampaignAssociation,
                          campaignDescription: campaignDescription,
                        } as SubscriptionUpdateNavigationState,
                      })
                    : handleSubmit();
                }}
              >
                {isChangeOffer ? t.I62A(continueMsg) : t.AQOL(orderMsg)}
              </CL.Button>
            </form>
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
    </StickyFooter>
  );
};
