import * as CL from '@design-system/component-library';
import { Email } from '../../../common/formik/index.js';
import { GridColumn } from '../../BillingAccountDetails/components/GridColumn.js';
import { emailMsg, t } from '../../../common/i18n/index.js';
import { useFormikContext } from 'formik';
import type { EmailDeliveryMethodFormValues } from './BillReceiverEdit.js';
export interface EmailInputFieldsProps {
  additionalBillReceiverEmails?: string[];
}

export const EmailInputFields = ({ additionalBillReceiverEmails }: EmailInputFieldsProps) => (
  <>
    {additionalBillReceiverEmails?.map((item, index) => (
      <GridColumn
        className="'ds-padding-top--3 of-billing-account-details form-column edit-field space_for_error'"
        key={`additionalemail${index + 1}`}
      >
        <Email
          required={false}
          name={`additionalBillReceiverEmails[${index}]`}
          label={`${t.XVHQ(emailMsg)} ${index + 1}`}
        />
      </GridColumn>
    ))}
  </>
);

export const AdditionalBillReceiversEdit = () => {
  const { values, setFieldValue } = useFormikContext<EmailDeliveryMethodFormValues>();

  const MAX_ADDITIONAL_BILL_RECEIVERS = 5;

  const addNewBillReceiverEmail = () => {
    const newEmails = values.additionalBillReceiverEmails ? values.additionalBillReceiverEmails?.concat('') : [''];
    setFieldValue('additionalBillReceiverEmails', newEmails);
  };

  return (
    <>
      <CL.GridRow>
        <GridColumn className="of-billing-account-details form-column view-field ds-padding-top--3  ds-padding-bottom--1">
          <strong>{t.UZ3K('Other receiver')}</strong>
        </GridColumn>
      </CL.GridRow>
      <CL.GridRow>
        <EmailInputFields additionalBillReceiverEmails={values.additionalBillReceiverEmails} />
      </CL.GridRow>
      <CL.GridRow>
        <GridColumn className="of-billing-account-details form-column edit-field">
          <div>
            <CL.Button
              onClick={() => addNewBillReceiverEmail()}
              disabled={values.additionalBillReceiverEmails?.length >= MAX_ADDITIONAL_BILL_RECEIVERS}
              color="light"
            >
              {t.N41Q('+ Add new')}
            </CL.Button>
          </div>
        </GridColumn>
      </CL.GridRow>
    </>
  );
};
