import { CustomerOrderBillingInfo } from './CustomerOrderBillingInfo.js';
import { CustomerOrderCustomerInfo } from './CustomerOrderCustomerInfo.js';
import { CustomerOrderDeviceSubscriptions } from './CustomerOrderDeviceSubscriptions.js';
import { CustomerOrderInfo } from './CustomerOrderInfo.js';
import { CustomerOrderOrderedProductsList } from './CustomerOrderOrderedProductsList.js';
import { CustomerOrderStatus, SubscriptionStatusType } from '../../generated/api/models.js';
import { EppDeviceChangeSummaries } from './EppDeviceChange/EppDeviceChangeSummaries.js';
import { EppPendingRedeemDisclaimers } from './EppDeviceChange/EppPendingRedeemDisclaimers.js';
import type { ContactHeader, CustomerOrder, Subscription } from '../../generated/api/models.js';

import './CustomerOrderDetails.scss';

interface CustomerOrderContentParams {
  buildDeliveryOrderHref: (cid: string, did: string) => string;
  customerOrder: CustomerOrder;
  ordererContact: ContactHeader | undefined;
  deviceSubscriptions: Subscription[] | undefined;
  isInternalCustomer: boolean;
  isEmployee: boolean;
}

// userContactId will be same for all deliverOrder when an employee places an order.
const getEmployeeUserContactId = (customerOrder: CustomerOrder): string | undefined =>
  customerOrder?.deliveryOrders.find(deliveryOrder => deliveryOrder.userContactId)?.userContactId;

const activeEppDeviceSubscriptionsOfEmployee = (
  customerOrdersDetail: CustomerOrder,
  deviceSubscriptions?: Subscription[]
): Subscription[] | undefined => {
  const employeeUserContactId = getEmployeeUserContactId(customerOrdersDetail);
  return deviceSubscriptions?.filter(
    item =>
      item.details?.device?.eppSubscription &&
      item.subscriptionContactId === employeeUserContactId &&
      item.subscriptionStatus === SubscriptionStatusType.ACTIVE
  );
};

const findFirstReplacedSubscriptionDisplayId = (customerOrder: CustomerOrder) =>
  customerOrder?.deliveryOrders?.find(deliveryOrder => !!deliveryOrder.replacedSubscriptionDisplayId)
    ?.replacedSubscriptionDisplayId;

export const CustomerOrderContent = ({
  buildDeliveryOrderHref,
  customerOrder,
  ordererContact,
  deviceSubscriptions,
  isInternalCustomer,
  isEmployee,
}: CustomerOrderContentParams) => {
  const activeDeviceSubscriptions = activeEppDeviceSubscriptionsOfEmployee(customerOrder, deviceSubscriptions);
  const orderPendingApproval = customerOrder.status === CustomerOrderStatus.PENDING_APPROVAL;
  const replacedSubscriptionDisplayId = findFirstReplacedSubscriptionDisplayId(customerOrder);

  return (
    <div className="of-customer-order-details__content">
      <CustomerOrderInfo customerOrder={customerOrder} isEmployee={isEmployee} />
      {!isEmployee && (
        <EppDeviceChangeSummaries customerOrder={customerOrder} deviceSubscriptions={deviceSubscriptions} />
      )}
      {!isEmployee && <EppPendingRedeemDisclaimers deviceSubscriptions={deviceSubscriptions} />}
      {orderPendingApproval && activeDeviceSubscriptions && (
        <CustomerOrderDeviceSubscriptions
          subscriptions={activeDeviceSubscriptions}
          isEmployee={isEmployee}
          replacedSubscriptionDisplayId={replacedSubscriptionDisplayId}
        />
      )}
      <CustomerOrderOrderedProductsList
        buildDeliveryOrderHref={buildDeliveryOrderHref}
        customerOrder={customerOrder}
        isEmployee={isEmployee}
      />
      {!isEmployee && orderPendingApproval && (
        <>
          <CustomerOrderCustomerInfo ordererContact={ordererContact} />
          <CustomerOrderBillingInfo isInternalCustomer={isInternalCustomer} />
        </>
      )}
    </div>
  );
};
