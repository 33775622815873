import * as CL from '@design-system/component-library';
import { DialogWrapper, buttonsForSubmitAndBack } from '../DialogWrapper/index.js';
import { confirmMsg, t } from '../../common/i18n/index.js';
import type { CommonError } from '../../common/types/errors.js';

export interface ConfirmationDialogProps {
  body: string | JSX.Element;
  confirmButtonText?: string;
  disableConfirm?: boolean;
  errors?: CommonError[];
  header: string;
  hideClosingCross?: boolean;
  isLoading?: boolean;
  onCloseDialog: () => void;
  onConfirm: (onCloseDialog: () => void) => void;
  wide?: boolean;
}

export const ConfirmationDialog = ({
  body,
  confirmButtonText,
  disableConfirm,
  errors,
  header,
  hideClosingCross,
  isLoading,
  onCloseDialog,
  onConfirm,
  wide,
}: ConfirmationDialogProps) => {
  return (
    <DialogWrapper
      buttons={buttonsForSubmitAndBack(
        onCloseDialog,
        () => onConfirm(onCloseDialog),
        undefined,
        undefined,
        confirmButtonText || t.QVYK(confirmMsg),
        disableConfirm,
        isLoading
      )}
      closeable={!hideClosingCross}
      header={header}
      onCloseDialog={onCloseDialog}
      size={wide ? 'l' : 'm'}
    >
      {body}
      {errors &&
        errors.map((error, i) => (
          <CL.Disclaimer className="ds-text--m" icon={<CL.Icon icon="warning" />} key={i} text={error.message} />
        ))}
    </DialogWrapper>
  );
};
