import * as CL from '@design-system/component-library';
import { loginMsg, t } from '../../common/i18n/index.js';

export const LoginBanner = () => {
  return (
    <CL.Disclaimer className="ds-margin-vertical--3" icon={<CL.Icon icon="diamond" aria-hidden="true" />}>
      <strong>{t.BMZU('Kirjaudu sisään nähdäksesi parhaat hinnat')}</strong>
      <br />
      <p>
        {t.WDIX(
          'Asiakashinnat ja personoidut etusi näet kirjautumalla sisään. Jos et ole vielä rekisteröitynyt asiakkaaksemme, tee se nyt.'
        )}
      </p>
      <br />
      <CL.Link linkHref="/rekisteroidy" className="ds-margin-top--1 ds-margin-bottom--3">
        <span>{t.LWKZ('Rekisteröidy nyt')}</span>
      </CL.Link>
      <br />
      {/* FIXME this had href="/omaelisa/uusi-ostoskori", it does nothing. */}
      <CL.Button color="light">{t.HYV2(loginMsg)}</CL.Button>
    </CL.Disclaimer>
  );
};
