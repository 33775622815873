import { EMPTY, empty, merge, of, timer } from 'rxjs';
import { SubscriptionCategory, WizardType } from '../../common/enums.js';
import {
  TypeKeys,
  changeSubscriptionBillingAccount as changeSubscriptionBillingAccountAction,
  changeSubscriptionUserInfo,
  loadBillingAccountSubscriptions,
  loadBillingAccountSubscriptionsFailed,
  loadBillingAccountSubscriptionsFulfilled,
  loadBillingAccountsFailed,
  loadBillingAccountsFulfilled,
  upsertBillingAccountFailed,
  upsertBillingAccountFulfilled,
  upsertContactFailed,
  upsertPersonBillingAccountFailed,
  upsertPersonBillingAccountFulfilled,
  upsertVirtualCatalog,
} from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, debounce, filter, mergeMap } from 'rxjs/operators';
import {
  createBillingAccountPrivateMethod,
  createEmployeePersonBillingAccountPrivateMethod,
  getBillingAccountsPrivateMethod,
  getSubscriptionsPrivateMethod,
  updateBillingAccountPrivateMethod,
  updateEmployeePersonBillingAccountPrivateMethod,
} from '../../generated/api/uiApiMethods.js';
import { getSubscriptionTypes } from '../../public/common/util/category.js';
import { loadBillingAccountsUseSearchService } from '../common/elasticsearchUtils.js';
import { push } from 'redux-first-history';
import { upsertContact } from './contactEpic.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { AjaxResponse } from 'rxjs/ajax';
import type {
  BillingAccount,
  BillingAccountsResponse,
  PutBillingAccountResponse,
  SubscriptionsResponse,
} from '../../generated/api/models.js';
import type {
  ChangeCatalogBillingAccountWizardParams,
  ChangeEppOmaLaiteLaskuBillingAccountWizardParams,
  ChangeSubscriptionBillingAccountWizardParams,
  WizardParams,
} from '../../common/types/wizard.js';
import type {
  ChangeSubscriptionBillingAccountFulfilledAction,
  ErrorAction,
  ErrorActionCreator,
  LoadBillingAccountSubscriptionsAction,
  LoadBillingAccountSubscriptionsFulfilledAction,
  LoadBillingAccountsAction,
  SelfServiceActionTypes,
  SubmitOnlineOrderAction,
  UpsertBillingAccountAction,
  UpsertBillingAccountFulfilledAction,
  UpsertPersonBillingAccountAction,
  UpsertPersonBillingAccountFulfilledAction,
  UpsertVirtualCatalogAction,
} from '../actions/index.js';
import type { Observable } from 'rxjs';
import type { State } from '../common/store.js';
import type { SubmitFixedBroadbandOrderAction } from '../actions/fixedBroadbandActions.js';
import type { UpsertPersonBillingAccountRequest } from '../../common/utils/billingAccountUtils.js';

export function upsertBillingAccount<T>(
  billingAccount: BillingAccount,
  epicDependencies: EpicDependencies,
  state$: StateObservable<State>,
  failureAction: ErrorActionCreator<TypeKeys>,
  successAction: (response: PutBillingAccountResponse) => T
): Observable<T | ErrorAction<TypeKeys>> {
  const { billingAccountId, ...payload } = billingAccount;
  if (billingAccountId) {
    return callUiApi({
      epicDependencies,
      failureAction,
      method: updateBillingAccountPrivateMethod(billingAccountId),
      payload,
      state$,
      successAction: (response: AjaxResponse) => successAction(response.response),
    });
  } else {
    return callUiApi({
      epicDependencies,
      failureAction,
      method: createBillingAccountPrivateMethod(),
      payload,
      state$,
      successAction: (response: AjaxResponse) => successAction(response.response),
    });
  }
}

export const createBa = (
  action: SubmitOnlineOrderAction | UpsertVirtualCatalogAction | SubmitFixedBroadbandOrderAction,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies,
  wizardParams?: WizardParams
): Observable<UpsertBillingAccountFulfilledAction | ErrorAction<TypeKeys>> => {
  if (action.newBillingAccount) {
    let obs: Observable<SubmitOnlineOrderAction | UpsertVirtualCatalogAction | ErrorAction<TypeKeys>>;
    if (action.newBillingAccountCommonFunction) {
      obs = upsertContact(
        action.newBillingAccountCommonFunction,
        epicDependencies,
        state$,
        upsertContactFailed,
        putContactResponse => ({
          ...action,
          newBillingAccount: {
            ...action.newBillingAccount,
            billReceiverId: putContactResponse.contactId,
          },
        })
      );
    } else {
      obs = of(action);
    }
    return obs.pipe(
      concatMap(
        (
          nextAction:
            | SubmitOnlineOrderAction
            | UpsertVirtualCatalogAction
            | SubmitFixedBroadbandOrderAction
            | ErrorAction<TypeKeys>
        ) => {
          if (
            nextAction.type === TypeKeys.SUBMIT_ONLINE_ORDER ||
            nextAction.type === TypeKeys.UPSERT_VIRTUAL_CATALOG ||
            nextAction.type === TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER
          ) {
            const typedAction = nextAction as
              | SubmitOnlineOrderAction
              | UpsertVirtualCatalogAction
              | SubmitFixedBroadbandOrderAction;
            return upsertBillingAccount(
              typedAction.newBillingAccount!,
              epicDependencies,
              state$,
              upsertBillingAccountFailed,
              (billingAccountResponse: PutBillingAccountResponse) =>
                upsertBillingAccountFulfilled(
                  typedAction.newBillingAccount!,
                  billingAccountResponse,
                  true,
                  wizardParams
                )
            );
          } else {
            if (nextAction.type === TypeKeys.UPSERT_CONTACT_FAILED) {
              return of(upsertContactFailed('Upserting contact failed', 500));
            }
            return of(upsertBillingAccountFailed('Upserting BA failed', 500));
          }
        }
      )
    );
  } else {
    return of(action);
  }
};

/**
 * Load one billing account by id.
 */
export const loadBillingAccountsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOAD_BILLING_ACCOUNTS)), (action: LoadBillingAccountsAction) =>
    actionToActionState(action, state$, 'billingAccounts')
  ).pipe(
    debounce((actionAndState: ActionAndState) =>
      loadBillingAccountsUseSearchService(actionAndState.action) ? timer(500) : EMPTY
    ),
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state || !actionAndState.state.query) {
        throw new Error('invalid action state for loading billing accounts: query missing');
      }

      const loadBillingAccountsAction = actionAndState.action as LoadBillingAccountsAction;
      const query = actionAndState.state.query;
      const useSearchService = loadBillingAccountsUseSearchService(actionAndState.action);
      const successAction = (res: BillingAccountsResponse) => {
        if (loadBillingAccountsAction.displayId && (!res.billingAccounts || res.billingAccounts.length === 0)) {
          return loadBillingAccountsFailed(
            `billing account ${loadBillingAccountsAction.displayId} could not be found`,
            404
          );
        }
        return loadBillingAccountsFulfilled(
          res,
          loadBillingAccountsAction.billingContactId,
          useSearchService,
          useSearchService && query.offset > 0
        );
      };

      let additionalQueryParams = {};
      if (loadBillingAccountsAction.billingContactId) {
        additionalQueryParams = {
          billingContactId: loadBillingAccountsAction.billingContactId,
        };
      }

      if (useSearchService) {
        const useSearchServiceMethod = getBillingAccountsPrivateMethod({
          ...query,
          limit: loadBillingAccountsAction.limit,
          offset: loadBillingAccountsAction.offset,
          search: loadBillingAccountsAction.search,
          useSearchService: true,
          sourceSystem: loadBillingAccountsAction.sourceSystem,
        });
        return callUiApi({
          epicDependencies,
          failureAction: loadBillingAccountsFailed,
          method: useSearchServiceMethod,
          state$,
          successAction,
        });
      }

      const method = getBillingAccountsPrivateMethod({
        offset: query.offset,
        limit: query.limit,
        billingAccountDisplayId: loadBillingAccountsAction.displayId,
        sourceSystem: loadBillingAccountsAction.sourceSystem,
        ...additionalQueryParams,
      });
      return callUiApi({
        epicDependencies,
        failureAction: loadBillingAccountsFailed,
        method,
        state$,
        successAction,
      });
    })
  );

/**
 * Updates or inserts one billing account.
 */
export const upsertBillingAccountEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.UPSERT_BILLING_ACCOUNT)), (action: UpsertBillingAccountAction) =>
    actionToActionState(action, state$, 'billingAccounts')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state) {
        throw new Error('invalid action state for upserting billing account first phase');
      }
      return of(actionAndState);
    }),
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state) {
        throw new Error('invalid action state for updating billing account second phase');
      }
      const upsertBillingAccountAction = actionAndState.action as UpsertBillingAccountAction;
      const successAction = upsertBillingAccountAction.billingAccount.billingAccountId
        ? (response: PutBillingAccountResponse) =>
            upsertBillingAccountFulfilled(
              upsertBillingAccountAction.billingAccount,
              response,
              false,
              undefined,
              upsertBillingAccountAction.editedSection
            )
        : (response: PutBillingAccountResponse) =>
            upsertBillingAccountFulfilled(
              upsertBillingAccountAction.billingAccount,
              response,
              true,
              upsertBillingAccountAction.wizardParams,
              upsertBillingAccountAction.editedSection
            );

      return upsertBillingAccount(
        upsertBillingAccountAction.billingAccount,
        epicDependencies,
        state$,
        upsertBillingAccountFailed,
        successAction
      );
    }),
    concatMap((action: Action) => {
      if (
        action.type === TypeKeys.UPSERT_BILLING_ACCOUNT_FULFILLED &&
        state$.value.selfservice!.billingAccounts!.redirectToUrl
      ) {
        return merge(of(action), of(push(state$.value.selfservice!.billingAccounts!.redirectToUrl!)));
      }
      return of(action);
    })
  );

export const changeSubscriptionBillingAccount: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.pipe(
    ofType(TypeKeys.UPSERT_BILLING_ACCOUNT_FULFILLED),
    filter(
      (action: UpsertBillingAccountFulfilledAction) =>
        action.wizardParams?.type === WizardType.CHANGE_CATALOG_BILLING_ACCOUNT
    ),
    mergeMap((action: UpsertBillingAccountFulfilledAction) => {
      const params = action.wizardParams as ChangeCatalogBillingAccountWizardParams;
      params.catalog.billingAccountId = action.billingAccount.billingAccountId;
      return of(upsertVirtualCatalog(params.catalog, false, params.virtualCatalog));
    })
  );

export const changeEppOwnDeviceBillingAccount: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.pipe(
    ofType(TypeKeys.UPSERT_BILLING_ACCOUNT_FULFILLED),
    filter(
      (action: UpsertBillingAccountFulfilledAction) =>
        action.wizardParams?.type === WizardType.CHANGE_EPP_OMALAITELASKU_BILLING_ACCOUNT
    ),
    mergeMap((action: UpsertBillingAccountFulfilledAction) => {
      const params = action.wizardParams as ChangeEppOmaLaiteLaskuBillingAccountWizardParams;
      const changeSubscriptionUserInfoParams = params.changeSubscriptionUserInfoParams;
      return of(
        changeSubscriptionUserInfo(...changeSubscriptionUserInfoParams, action.billingAccount.billingAccountDisplayId)
      );
    })
  );

export const cancelBillingAccountEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.ofType(TypeKeys.CANCEL_BILLING_ACCOUNT_EDIT).pipe(
    mergeMap((action: Action) => {
      if (
        action.type === TypeKeys.CANCEL_BILLING_ACCOUNT_EDIT &&
        state$.value.selfservice!.billingAccounts!.redirectToUrl
      ) {
        return of(push(state$.value.selfservice!.billingAccounts!.redirectToUrl!));
      }
      return empty();
    })
  );

export function upsertPersonBillingAccount(
  request: UpsertPersonBillingAccountRequest,
  epicDependencies: EpicDependencies,
  state$: StateObservable<State>,
  billingAccountId?: string,
  successAction?: () => void
): Observable<UpsertPersonBillingAccountFulfilledAction | ErrorAction<TypeKeys>> {
  return callUiApi({
    epicDependencies,
    state$,
    method: billingAccountId
      ? updateEmployeePersonBillingAccountPrivateMethod()
      : createEmployeePersonBillingAccountPrivateMethod(),
    payload: request,
    successAction: (response: AjaxResponse) =>
      upsertPersonBillingAccountFulfilled(
        request,
        {
          ...response.response,
          lastModified: response.response.lastModified,
        },
        !billingAccountId,
        state$.value.user?.authenticated?.contact?.contactId,
        `${state$.value.user?.authenticated?.firstName} ${state$.value.user?.authenticated?.lastName}`,
        successAction
      ),
    failureAction: upsertPersonBillingAccountFailed,
  });
}

export const upsertPersonBillingAccountsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.UPSERT_PERSON_BILLING_ACCOUNT)),
    (action: UpsertPersonBillingAccountAction) => actionToActionState(action, state$, 'billingAccounts')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state) {
        throw new Error('invalid action state for creating person billing accounts');
      }
      const upsertPersonBillingAccountAction = actionAndState.action as UpsertPersonBillingAccountAction;
      return upsertPersonBillingAccount(
        upsertPersonBillingAccountAction.request,
        epicDependencies,
        state$,
        upsertPersonBillingAccountAction.billingAccountId,
        upsertPersonBillingAccountAction.successAction
      );
    })
  );

const upsertPersonBillingAccountsFulfilledEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.pipe(
    ofType(TypeKeys.UPSERT_PERSON_BILLING_ACCOUNT_FULFILLED),
    mergeMap((action: UpsertPersonBillingAccountFulfilledAction) => {
      if (action.successAction) {
        action.successAction();
      }
      return EMPTY;
    })
  );

const getAllSubscriptionTypesQueryParams = (): { [s: string]: string } => {
  const subscriptionTypes = Object.values(SubscriptionCategory).flatMap(cat => getSubscriptionTypes(cat));
  return {
    details: 'true',
    subscriptionType: subscriptionTypes.join(','),
  };
};

export const loadBillingAccountSubscriptionsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS)),
    (action: LoadBillingAccountSubscriptionsAction) => actionToActionState(action, state$, 'billingAccounts')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const loadBaSubscriptionsAction = actionAndState.action as LoadBillingAccountSubscriptionsAction;

      const subscriptionPerFetch = 150;
      const successAction = (res: SubscriptionsResponse) => {
        return loadBillingAccountSubscriptionsFulfilled(
          // If displayId is defined in query, total is unreliable atm. -1 means unknown total.
          { ...res, total: loadBaSubscriptionsAction.displayId ? -1 : res.total },
          loadBaSubscriptionsAction.billingAccountId,
          {
            offset: loadBaSubscriptionsAction.offset || 0,
          },
          loadBaSubscriptionsAction.getAllItems
        );
      };

      if (!loadBaSubscriptionsAction.limit) {
        loadBaSubscriptionsAction.limit = subscriptionPerFetch;
      }
      if (!loadBaSubscriptionsAction.offset) {
        loadBaSubscriptionsAction.offset = 0;
      }

      const additionalQueryParams = {
        billingAccountId: loadBaSubscriptionsAction.billingAccountId,
        offset: loadBaSubscriptionsAction.offset,
        limit: loadBaSubscriptionsAction.limit || subscriptionPerFetch,
        ...getAllSubscriptionTypesQueryParams(),
      };
      const failureParams = { billingAccountId: loadBaSubscriptionsAction.billingAccountId };

      const method = getSubscriptionsPrivateMethod({
        ...additionalQueryParams,
      });

      return callUiApi({
        epicDependencies,
        failureAction: loadBillingAccountSubscriptionsFailed,
        failureParams,
        method,
        state$,
        successAction,
      }).pipe(
        concatMap(finalAction => {
          const fullFilledAction = finalAction as LoadBillingAccountSubscriptionsFulfilledAction;
          if (
            fullFilledAction.getAllItems &&
            Number(loadBaSubscriptionsAction.offset) + Number(loadBaSubscriptionsAction.limit) <
              Number(fullFilledAction.totalSubscriptions)
          ) {
            const continueLoadAction = loadBillingAccountSubscriptions({
              billingAccountId: loadBaSubscriptionsAction.billingAccountId,
              limit: subscriptionPerFetch,
              offset: subscriptionPerFetch + Number(loadBaSubscriptionsAction.offset),
              getAllItems: fullFilledAction.getAllItems,
            });
            return [finalAction, continueLoadAction];
          }
          return of(finalAction);
        })
      );
    })
  );

export const completeCreateBillingAccount: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.pipe(
    ofType(TypeKeys.UPSERT_BILLING_ACCOUNT_FULFILLED),
    filter(
      (action: UpsertBillingAccountFulfilledAction) =>
        action.wizardParams?.type === WizardType.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT ||
        action.wizardParams?.type === WizardType.CHANGE_EPP_REDEEM_BILLING_ACCOUNT
    ),
    concatMap((action: UpsertBillingAccountFulfilledAction) => {
      const params = action.wizardParams as ChangeSubscriptionBillingAccountWizardParams;
      return of(
        changeSubscriptionBillingAccountAction(
          params.subscription,
          action.billingAccount.billingAccountId!,
          action.billingAccount.billingAccountDisplayId!
        )
      );
    })
  );

export const completeChangeBillingAccountOfSubscription: Epic<
  SelfServiceActionTypes,
  Action,
  State,
  EpicDependencies
> = (action$: ActionsObservable<SelfServiceActionTypes>) =>
  action$.pipe(
    ofType(TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FULFILLED),
    mergeMap((action: ChangeSubscriptionBillingAccountFulfilledAction) => {
      const { billingAccountId, billingAccountDisplayId } = action;
      const state =
        billingAccountId && billingAccountDisplayId
          ? { updatedBillingAccount: { billingAccountId, billingAccountDisplayId } }
          : undefined;
      if (history.state.usr?.originURL) {
        return of(push(history.state.usr.originURL, state));
      }
      return EMPTY;
    })
  );

export const billingAccountEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  loadBillingAccountsEpic,
  upsertBillingAccountEpic,
  cancelBillingAccountEpic,
  upsertPersonBillingAccountsEpic,
  upsertPersonBillingAccountsFulfilledEpic,
  loadBillingAccountSubscriptionsEpic,
  changeSubscriptionBillingAccount,
  changeEppOwnDeviceBillingAccount,
  completeCreateBillingAccount,
  completeChangeBillingAccountOfSubscription
);
