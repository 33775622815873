import * as CL from '@design-system/component-library';
import { CompanySearch } from '../../common/react-hook-form/fields/index.js';
import { FormProvider, useForm } from 'react-hook-form';
import { businessIdMsg, nextMsg, t } from '../../common/i18n/index.js';
import type { Company } from '../../generated/api/models.js';
import type { FunctionComponent } from 'react';

export interface RegistrationStep1Attrs {
  companySearchResult: Company[];
  isEditable?: boolean;
  persistedCompany?: Company;
  searchDebounce?: number;
}

export interface RegistrationStep1Dispatchers {
  searchCompany: (query: string) => void;
  storeCompany: (company: Company) => void;
}

type RegistrationStep1Props = RegistrationStep1Attrs & RegistrationStep1Dispatchers;

const CompanyDetailsElement = (company: Company) => {
  return (
    <div>
      <h4>{company.name}</h4>
      <div id="of-registration-step1-business-id">
        {t.MPA5(businessIdMsg)}: <strong>{company.businessId}</strong>
      </div>
      <div id="of-registration-step1-company-address">
        {company.postalAddress}
        {company.postalAddress && (company.postCode || company.postOffice) && ', '}
        {company.postCode}
        {company.postOffice && ` ${company.postOffice}`}
      </div>
    </div>
  );
};

export const RegistrationCompanyForm: FunctionComponent<RegistrationStep1Props> = props => {
  const methods = useForm({ mode: 'all', defaultValues: props.persistedCompany });
  const company = methods.watch();

  return (
    <div className="of-registration-step of-registration-scene-step1">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(values => {
            props.storeCompany(values);
          })}
          noValidate
        >
          <p>{t.HJ9V('Corporate account can be created by a person who is authorized to do that.')}</p>
          <CompanySearch
            fieldNames={{
              businessId: 'businessId',
              businessName: 'name',
              masterId: 'masterId',
              postalAddress: 'postalAddress',
              postCode: 'postCode',
              postOffice: 'postOffice',
            }}
          />
          {company?.businessId && (
            <>
              <CompanyDetailsElement {...company} />
              <div className="ds-padding-top--4">
                <CL.Button size="l" type="submit">
                  {t.F0MY(nextMsg)}
                </CL.Button>
              </div>
            </>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export const RegistrationStep1: FunctionComponent<RegistrationStep1Props> = props => {
  if (!props.isEditable) {
    return <>{props.persistedCompany && <CompanyDetailsElement {...props.persistedCompany} />}</>;
  }
  return <RegistrationCompanyForm {...props} />;
};
