import { DeviceCheckoutDeliveryDetails } from '../../DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetails.js';
import { processDeliveryDetails } from '../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { CommonError } from '../../../common/types/errors.js';

import type { DeviceCheckoutDeliveryDetailsType } from '../../DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';

export interface PunchoutDeliveryDetailsProps {
  onSaveDeliveryDetailsAndNext: (updatedDeliveryDetails: DeviceCheckoutDeliveryDetailsType) => void;
  setDeliveryDetailsObj: (updatedDeliveryDetails: DeviceCheckoutDeliveryDetailsType) => void;
  deliveryDetails: DeviceCheckoutDeliveryDetailsType;
}
export const PunchoutDeliveryDetails = ({
  onSaveDeliveryDetailsAndNext,
  setDeliveryDetailsObj,
  deliveryDetails,
}: PunchoutDeliveryDetailsProps) => {
  const dispatch = useDispatch();

  const onSaveDeliveryDetailsToState = (
    updatedDeliveryDetails: DeviceCheckoutDeliveryDetailsType,
    validationErrors?: CommonError[]
  ) => {
    dispatch(processDeliveryDetails(updatedDeliveryDetails, validationErrors));
    setDeliveryDetailsObj(updatedDeliveryDetails);
  };
  return (
    <DeviceCheckoutDeliveryDetails
      deliveryDetails={deliveryDetails}
      saveDeliveryDetailsAndNext={onSaveDeliveryDetailsAndNext}
      saveDeliveryDetailsToState={onSaveDeliveryDetailsToState}
      isEmployee={true}
      deliveryAddressNeeded={true}
    />
  );
};
