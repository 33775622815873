import * as CL from '@design-system/component-library';
import { useCallback, useEffect, useState } from 'react';
import type { StepperItem } from '@design-system/component-library';

import './FixedBroadbandCheckoutStepManager.scss';

interface CheckoutStepManagerProps {
  defaultActiveStepIndex?: number;
  steps: StepperItem[];
  getNextStepFn: (fn: () => void) => void;
  enableOpenPreviousStep?: boolean;
}

export const FixedBroadbandCheckoutStepManager = (props: CheckoutStepManagerProps) => {
  const { steps, defaultActiveStepIndex = 0, getNextStepFn, enableOpenPreviousStep } = props;

  const [activeStepIndex, setActiveStepIndex] = useState(defaultActiveStepIndex);
  const activeStep = steps[activeStepIndex].id;

  const stepsWithCompleteAndOnBackHandler = steps.map((step, idx) => {
    if (idx < activeStepIndex) {
      step.completed = true;
    } else {
      step.completed = false;
      step.onClick = undefined;
    }
    if (enableOpenPreviousStep && step.completed) {
      step.onClick = () => setActiveStepIndex(idx);
    }
    return step;
  });

  const getNextStepFnCallback = useCallback(() => {
    setActiveStepIndex(idx => idx + 1);
  }, []);

  useEffect(() => {
    getNextStepFn(getNextStepFnCallback);
  }, [getNextStepFn, getNextStepFnCallback]);

  return (
    <div className="ds-reset">
      <CL.Stepper steps={stepsWithCompleteAndOnBackHandler} activeStep={activeStep} />
    </div>
  );
};
