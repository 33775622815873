import * as CL from '@design-system/component-library';
import { CommonErrorType } from '../../common/enums.js';
import { ConfirmationDialog } from '../ConfirmationDialog/index.js';
import { DeliveryAddressForm } from '../DeliveryAddressForm/DeliveryAddressForm.js';
import { EppRedeemTerminateRequestType } from '../../generated/api/models.js';
import { Loading } from '../Loading/index.js';
import {
  contactEmailAddressMsg,
  contactPhoneNumberMsg,
  deviceMsg,
  imeiSlashSerialNumberMsg,
  shippingAddressMsg,
  t,
  terminateAgreementAndReturnDeviceMsg,
  terminationIsBindingMsg,
  vatMsg,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getFullNameFromAuthenticatedUser } from '../EppMaintenance/eppMaintenanceUtils.js';
import { postEppRedeemTerminate } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import type { CommonError } from '../../common/types/errors.js';
import type { DeliveryAddress, Subscription } from '../../generated/api/models.js';
import type { EmployeeSubscription } from '../../common/types/subscription.js';
import type { EppTerminateDialogParams } from '../../common/types/dialog.js';
import type { State } from '../../selfservice/common/store.js';

export interface EppTerminateDialogProps {
  params: EppTerminateDialogParams;
  onCloseDialog: () => void;
  errors?: CommonError[];
  isLoading?: boolean;
  isEmployee: boolean;
}

export interface DeviceDetailsProps {
  subscription: EmployeeSubscription | Subscription;
  mobile: string;
  email: string;
}

export interface TerminationInfoProps {
  terminationPrice?: number;
  isEmployee: boolean;
}

const DeviceDetails = ({ subscription, mobile, email }: DeviceDetailsProps) => (
  <CL.Description
    items={[
      {
        title: t.TPVQ(deviceMsg),
        description: subscription.subscriptionName,
      },
      {
        title: subscription.details?.device?.serialNumber ? t.OE45(imeiSlashSerialNumberMsg) : '',
        description: subscription.details?.device?.serialNumber,
      },
      {
        title: t.HUN9(contactPhoneNumberMsg),
        description: mobile,
      },
      {
        title: t.S5PK(contactEmailAddressMsg),
        description: email,
      },
    ]}
    className="ds-margin--0 ds-padding-bottom--3"
  />
);

const TerminationInfo = ({ terminationPrice, isEmployee }: TerminationInfoProps) => (
  <div className="ds-padding-top--3 ds-padding-bottom--3">
    {terminationPrice !== undefined &&
      terminationPrice > 0 &&
      !isEmployee &&
      `${t.OOWX(
        'If you terminate an Elisa Palvelupäätelaite (service device) agreement mid-term, Elisa will charge you for the remaining monthly fees of the device as a one-time fee, for this device the amount is'
      )} ${formatSum(terminationPrice)} (${t.UZ6X(vatMsg)} 0%). `}
  </div>
);

const ShippingAddressInfo = () => (
  <>
    <div className="ds-padding-top--3 ds-padding-bottom--3">
      <strong>{t.IHO6(shippingAddressMsg)}</strong>
    </div>
    <div className="ds-padding-bottom--4">
      {t.WHHI('Select the delivery address below to which we will deliver the device-return bag.')}
    </div>
  </>
);

const ReturnPolicyInfo = () => (
  <div className="ds-padding-top--4 ds-padding-bottom--2  ">
    {t.SONR(
      'The device must be returned to Elisa within one week. A penalty fee will be charged for unreturned devices.'
    )}
  </div>
);

export const EppTerminateDialog = ({
  params,
  errors,
  onCloseDialog,
  isLoading,
  isEmployee,
}: EppTerminateDialogProps) => {
  const dispatch = useDispatch();
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  const { authenticatedUser } = useAuth();
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddress>();
  const companyName = authenticatedUser?.companyName || '';
  const email = authenticatedUser?.email || '';
  const mobile = authenticatedUser?.mobile || '';
  const apiErrors = errors ? errors.filter(err => err.type !== CommonErrorType.VALIDATION) : undefined;
  const terminationPrice = params.subscription.details!.device!.eppModificationPrices?.terminate;

  return (
    <ConfirmationDialog
      errors={apiErrors}
      wide={true}
      header={t.LIUF(terminateAgreementAndReturnDeviceMsg)}
      body={
        companyInfo ? (
          <div className="ds-padding-bottom--3">
            {t.MB11(terminationIsBindingMsg)}
            <TerminationInfo terminationPrice={terminationPrice} isEmployee={isEmployee} />
            <DeviceDetails subscription={params.subscription} mobile={mobile} email={email} />
            <ShippingAddressInfo />
            <DeliveryAddressForm
              address={companyInfo.address}
              companyName={companyName}
              recipientName={getFullNameFromAuthenticatedUser(authenticatedUser)}
              phoneNumber={mobile}
              onAddressReady={setIsSubmitEnabled}
              setDeliveryAddress={setDeliveryAddress}
            />
            <ReturnPolicyInfo />
          </div>
        ) : (
          <Loading />
        )
      }
      disableConfirm={!isSubmitEnabled || !companyInfo || !deliveryAddress}
      isLoading={isLoading}
      onCloseDialog={onCloseDialog}
      onConfirm={() => {
        dispatch(
          postEppRedeemTerminate(
            {
              requestType: EppRedeemTerminateRequestType.TERMINATE,
              terminate: {
                address: deliveryAddress!, // confirm will be disabled unless deliveryAddress is defined
              },
            },
            params.subscription.subscriptionId,
            undefined,
            undefined
          )
        );
      }}
    />
  );
};
