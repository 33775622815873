import * as CL from '@design-system/component-library';
import { t } from '../../../common/i18n/index.js';
import type { Subscription } from '../../../generated/api/subscription.js';

export interface EppPendingRedeemDisclaimerProps {
  subscription: Subscription;
}

export const EppPendingRedeemDisclaimer = ({ subscription }: EppPendingRedeemDisclaimerProps) => {
  return (
    <CL.Disclaimer
      title={t.WYE7('There is an open redeem request for the current device')}
      icon={<CL.Icon icon="clock" color="blue-600" />}
      className="ds-background-color--secondary-blue-100 ds-margin-bottom--5"
    >
      <div className="ds-text--s">{`${t.LN7R('User has submitted a redeem request of device')}: ${
        subscription.subscriptionName
      }. ${t.VQKT('The redeem request must be approved separately.')}`}</div>
    </CL.Disclaimer>
  );
};
