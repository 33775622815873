import { DeliveryAddressForm } from '../DeliveryAddressForm/DeliveryAddressForm.js';
import { shippingAddressMsg, t } from '../../common/i18n/index.js';
import type { DeliveryAddressFormProps } from '../DeliveryAddressForm/DeliveryAddressForm.js';

export interface DeliveryAddressDetailsProps extends DeliveryAddressFormProps {
  isSupportRequest: boolean;
}

const DestroyedDeviceInfo = (): JSX.Element => (
  <>
    {`${t.LVRQ(
      'Select the delivery address below to which we will deliver the return bag. The destroyed device must be returned to Elisa within a week.'
    )}
    ${t.VKJ4('A penalty fee will be charged for unreturned devices.')}`}
  </>
);

export const DeliveryAddressDetails = (props: DeliveryAddressDetailsProps): JSX.Element => (
  <>
    <div className="ds-padding-top--4">
      <strong>{t.IHO6(shippingAddressMsg)}</strong>
    </div>
    <div className="ds-padding-vertical--4">
      {props.isSupportRequest ? (
        t.J1ZO(
          'We will deliver a replacement device to replace the broken one. Select the shipping address to which the device will be delivered. You will receive instructions for returning the old device with the replacement device.'
        )
      ) : (
        <DestroyedDeviceInfo />
      )}
    </div>
    <DeliveryAddressForm {...props} />
  </>
);
