import { Grid } from '../Grid/Grid.js';
import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { NavigationMenu, NavigationMenuItem } from '../NavigationMenu/NavigationMenu.js';
import { helloMsg, myAccountMsg, myOrdersMsg, orderRequestsMsg, shopMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useTitle } from '../../common/hooks/useTitle.js';

import './EmployeeHomeScene.scss';

export interface EmployeeHomeSceneProps {
  firstName: string;
  isCatalogPresent?: boolean;
  hasApproverRole?: boolean;
  loggedInContactId?: string;
  showUpdateUserInfoHint: boolean;
  showExpiringEppSubscriptionHint: boolean;
  showPendingApprovalsHint: boolean;
  isLinkedWithAccount: boolean;
}

export const IconWithHint = ({ icon, showHint }: { icon: string; showHint: boolean }) => (
  <span className="ds-position--relative ds-display--block">
    {showHint && <span className="of-icon--exclamation-mark" />}
    <span className={`ea-icon ea-icon--medium ea-icon--${icon}`} aria-hidden="true" />
  </span>
);

export const EmployeeHomeScene = ({
  firstName,
  isCatalogPresent,
  hasApproverRole,
  showUpdateUserInfoHint,
  showExpiringEppSubscriptionHint,
  showPendingApprovalsHint,
  isLinkedWithAccount,
}: EmployeeHomeSceneProps) => {
  useTitle();
  return (
    <div className="of-employee-home-scene">
      <HeroHeading
        fullScreen={true}
        center={true}
        heroHeadingType={HeroHeadingType.EMPLOYEE_HOME}
        title={t.WR9Q(helloMsg, firstName)}
        subtitle={t.JTDS('Manage your subscriptions!')}
      />
      <Grid>
        <div className="of-employee-home-scene__navi">
          <NavigationMenu>
            <NavigationMenuItem
              id="category-menu-item-subscriptions"
              icon={<IconWithHint icon="devices" showHint={showExpiringEppSubscriptionHint} />}
              label={t.YRZJ('My Devices and subscriptions')}
              to={paths.EMPLOYEE_SUBSCRIPTIONS}
            />
            {isLinkedWithAccount && (
              <NavigationMenuItem
                id="category-menu-item-my-own-orders"
                icon={<IconWithHint icon="delivery" showHint={false} />}
                to={paths.EMPLOYEE_OWN_ORDERS}
                label={t.EG6F(myOrdersMsg)}
              />
            )}
            {hasApproverRole && (
              <NavigationMenuItem
                id="category-menu-item-order-requests"
                icon={<IconWithHint icon="inbox" showHint={showPendingApprovalsHint} />}
                to={paths.EMPLOYEE_PENDING_REVIEW_ORDERS}
                label={t.Y29L(orderRequestsMsg)}
              />
            )}
            <NavigationMenuItem
              id="category-menu-item-user-details"
              icon={<IconWithHint icon="user" showHint={showUpdateUserInfoHint} />}
              to={paths.EMPLOYEE_OWN_INFO}
              label={t.PBJ3(myAccountMsg)}
            />
            {isCatalogPresent && (
              <NavigationMenuItem
                id="category-menu-item-shop"
                icon={<IconWithHint icon="shop" showHint={false} />}
                to={paths.EMPLOYEE_DEVICE_STORE}
                label={t.BZGP(shopMsg)}
              />
            )}
          </NavigationMenu>
        </div>
      </Grid>
    </div>
  );
};
