import * as CL from '@design-system/component-library';
import { CommercialProductSubType } from '../../generated/api/models.js';
import {
  ID_GROUP_SEPARATOR,
  ID_ITEM_SEPARATOR,
  getAddonsDisplayData,
  getCartItemId,
  getProductDisclaimers,
  getProductPriceDisplayData,
  getTotalProductQuantity,
  productUrl,
  resolveTotalPrices,
} from '../../selfservice/common/shopping-cart/shoppingCartFunctions.js';
import { Picture } from '../Picture/Picture.js';
import { Spinner } from '../../public/site/siteUtils.js';
import {
  addMsg,
  additionalServicesMsg,
  cartIsEmptyMsg,
  checkoutMsg,
  continueShoppingMsg,
  monthsMsg,
  oneTimePaymentMsg,
  paymentPeriodMsg,
  quantityMsg,
  removeMsg,
  shoppingCartContentPluralMsg,
  shoppingCartContentSingularMsg,
  shoppingCartMsg,
  subtractMsg,
  sumPerMonthMsg,
  t,
  totalMsg,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { filterAndSortOffersByAvailability, processOnlineModel } from '../ProductDetails/utils/productDetailsUtils.js';
import { formatSumToString } from '../../common/utils/priceUtils.js';
import { isEmployeeUser } from '../ProductDetails/utils/productDetailsEmployeeUtils.js';
import { loadOnlineModelWithId, updateCartItem, updateCartItemPayment } from '../../selfservice/actions/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { priceSummarySuffix } from '../ProductDetails/utils/formatters.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { AuthenticatedUserState, CompanyInfoState } from '../../common/types/states.js';
import type { CommercialProduct, Offer, OnlineModel } from '../../generated/api/models.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';
import type { State } from '../../selfservice/common/store.js';

import './ShoppingCart.scss';

/**
 * Offer can have multiple commercialProducts with monthly and onetime charges,
 * sum them up, so we can show something sensible to the user.
 * 'maxPayments' is just the largest payments we find, as that makes the most sense to the customer.
 */
const sumCommercialProductsPayments = (
  commercialProducts: CommercialProduct[]
): [monthly: number, maxPayments: number, onetime: number] => {
  const monthly = commercialProducts.reduce((a, c) => a + (c.monthlyRecurringCharge || 0), 0);
  // 0 is the 'largest' payment amount, so this is a bit weird
  const maxPayments = commercialProducts.reduce(
    (a, c) => (c.payments === 0 || a === 0 ? 0 : Math.max(a, c.payments || -1)),
    -1
  );
  const onetime = commercialProducts.reduce((a, c) => a + (c.oneTimeCharge || 0), 0);
  return [monthly, maxPayments, onetime];
};

const getProductImage = (productName: string, imageUrl: string): JSX.Element => {
  // The picture is always 64px, so this renderedImageSize is fine.
  // offerWidthAlternatives is just for high dpi displays.
  return (
    <Picture alt={productName} offerWidthAlternatives={[128]} renderedImageSize={{ onPhone: '64px' }} src={imageUrl} />
  );
};

const findCommercialProducts = (commercialProductCodes: string[], offer: Offer): CommercialProduct[] | null => {
  // Keep original CP order
  return (offer.commercialProducts || []).filter(({ commercialProductCode }) =>
    commercialProductCodes.includes(commercialProductCode)
  );
};

const getPaymentOptionLabel = (
  commercialProducts: CommercialProduct[],
  monthly: number,
  maxPayments: number,
  onetime: number
): string => {
  const cp = commercialProducts[0];
  if (monthly) {
    if (maxPayments > 0) {
      return `${maxPayments}${t.XXVX(monthsMsg)}, ${formatSumToString(monthly, true)} ${t.YO7F(
        sumPerMonthMsg,
        '€'
      )}${priceSummarySuffix(cp)}`;
    } else {
      return `${formatSumToString(monthly, true)} ${t.YO7F(sumPerMonthMsg, '€')}${priceSummarySuffix(cp)}`;
    }
  }
  return `${t.ASEI(oneTimePaymentMsg)} ${formatSumToString(onetime)}`;
};

const priceOptionMatches = (commercialProducts: CommercialProduct[], priceOption: string[]) =>
  commercialProducts.length === priceOption.length &&
  commercialProducts.every(({ commercialProductCode }) => priceOption.includes(commercialProductCode));

type SortablePaymentOption = CL.ShoppingCartPaymentOption & { sorting: { epp: boolean; payments: number } };
const priceOptionToPaymentOption = (
  activeCommercialProducts: CommercialProduct[],
  offer: Offer,
  priceOption: string[]
): SortablePaymentOption => {
  const priceOptionCommercialProducts = (offer.commercialProducts || []).filter(({ commercialProductCode }) =>
    priceOption.includes(commercialProductCode)
  );

  const [monthly, maxPayments, onetime] = sumCommercialProductsPayments(priceOptionCommercialProducts);
  return {
    id: priceOption.join(ID_ITEM_SEPARATOR),
    label: getPaymentOptionLabel(priceOptionCommercialProducts, monthly, maxPayments, onetime),
    selected: priceOptionMatches(activeCommercialProducts, priceOption),
    // Payment options ordering:
    // EPP/non-EPP
    // descending payments (with onetime as last)
    sorting: {
      epp: priceOptionCommercialProducts.some(
        commercialProduct => commercialProduct.productSubType === CommercialProductSubType.EPP_DEVICE
      ),
      // onetime-only should have 1 as payments here,
      // 0 (i.e. unlimited payments) is changed to MAX_SAFE_INTEGER to make sorting easier
      payments: monthly > 0 ? (maxPayments === 0 ? Number.MAX_SAFE_INTEGER : maxPayments) : 1,
    },
  };
};

// We do not want to show price options to employees as the catalogs given them specify the pricing option
const shouldShowPriceOptions = (user?: AuthenticatedUserState) => !isEmployeeUser(user);

const mapPaymentOptions = (
  onlineModel: OnlineModel | undefined,
  activeOffer: Offer | undefined,
  activeCommercialProducts: CommercialProduct[] | null,
  user: AuthenticatedUserState | undefined
): CL.ShoppingCartItem['paymentOptions'] | null => {
  if (!onlineModel || !activeOffer || !activeCommercialProducts || !shouldShowPriceOptions(user)) {
    return null;
  }
  const priceOptions = activeOffer.priceOptions || [];
  const offerCommercialProductCodes = activeOffer.commercialProducts.map(cp => cp.commercialProductCode);
  // online model / offer processing seems to only filter commercialProducts, not priceOptions.
  return priceOptions
    .filter(priceOption =>
      priceOption.every(commercialProductCode => offerCommercialProductCodes.includes(commercialProductCode))
    )
    .map(priceOption => priceOptionToPaymentOption(activeCommercialProducts, activeOffer, priceOption))
    .sort((a: SortablePaymentOption, b: SortablePaymentOption) => {
      const as = a.sorting;
      const bs = b.sorting;
      if (as.epp && !bs.epp) {
        return -1;
      } else if (!as.epp && bs.epp) {
        return 1;
      }
      return bs.payments - as.payments;
    });
};

// This really is the minimum for identifying a single cart item.
const findCartItem = (cartItemId: string, cartItems: ShoppingCartItemForCheckout[]) => {
  const [onlineModelCode, offerCode, commercialProductCodesRaw, addOnCodesRaw] = cartItemId.split(ID_GROUP_SEPARATOR);
  const commercialProductCodes = commercialProductCodesRaw.length
    ? commercialProductCodesRaw.split(ID_ITEM_SEPARATOR)
    : [];
  const addOnCodes = addOnCodesRaw.length ? addOnCodesRaw.split(ID_ITEM_SEPARATOR) : [];
  return cartItems.find(cartItem => {
    if (cartItem.onlineModelCode !== onlineModelCode || cartItem.offerCode !== offerCode) {
      return false;
    }

    if (
      cartItem.commercialProductCodes.length !== commercialProductCodes.length ||
      commercialProductCodes.some(
        commercialProductCode =>
          !cartItem.commercialProductCodes.find(
            cartItemCommercialProductCode => cartItemCommercialProductCode === commercialProductCode
          )
      )
    ) {
      return false;
    }

    return (
      cartItem.selectedAddOns.length === addOnCodes.length &&
      addOnCodes.every(addOnCode => !!cartItem.selectedAddOns.find(addOn => addOn.addOnCode === addOnCode))
    );
  });
};

const findOnlineModel = (onlineModelCode: string, onlineModels: OnlineModel[]) => {
  return onlineModels.find(onlineModel => onlineModel.onlineModelCode === onlineModelCode);
};

const findOffer = (offerCode: string, offers: Offer[]) => {
  return offers.find(offer => offer.offerCode === offerCode);
};

const mapProducts = (
  cartItems: ShoppingCartItemForCheckout[],
  companyInfo: CompanyInfoState | undefined,
  onlineModels: OnlineModel[],
  user: AuthenticatedUserState | undefined
): CL.ShoppingCartItem[] => {
  return cartItems.map((cartItem): CL.ShoppingCartItem => {
    const onlineModel = onlineModels.find(({ onlineModelCode }) => onlineModelCode === cartItem.onlineModelCode);
    const processedOnlineModel = onlineModel && processOnlineModel(onlineModel, companyInfo, user);
    const processedOffers =
      processedOnlineModel?.offers && filterAndSortOffersByAvailability(processedOnlineModel?.offers);
    const activeOffer = processedOffers?.find(({ offerCode }) => offerCode === cartItem.offerCode);
    const activeCommercialProducts = activeOffer
      ? findCommercialProducts(cartItem.commercialProductCodes, activeOffer)
      : [];
    return {
      addons: getAddonsDisplayData(cartItem.selectedAddOns, cartItem.quantity),
      disclaimer: getProductDisclaimers(cartItem.price, cartItem.quantity, undefined, isEmployeeUser(user)),
      id: getCartItemId(cartItem),
      image: getProductImage(cartItem.productName, cartItem.imageListingUrl),
      name: cartItem.productName,
      paymentOptions: mapPaymentOptions(processedOnlineModel, activeOffer, activeCommercialProducts, user) || [],
      price: getProductPriceDisplayData(cartItem.price, cartItem.quantity),
      quantity: cartItem.quantity,
      url: productUrl(user, processedOnlineModel?.category, processedOnlineModel?.pagePath),
    };
  });
};

interface ShoppingCartRouteState {
  previousPathname?: string;
  toCheckout?: string;
}

export const ShoppingCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state: routeState }: { state: ShoppingCartRouteState } = useLocation();
  const previousPathname = routeState?.previousPathname || '/';
  const toCheckout = routeState?.toCheckout || paths.DEVICE_CHECKOUT;
  const { authenticatedUser } = useAuth();
  const { cartItems, companyInfo, onlineModels } = useSelector((state: State) => {
    return {
      cartItems: state.deviceCheckout?.cartItems || [],
      companyInfo: state.selfservice?.companyInfo || undefined,
      onlineModels: state.selfservice?.onlineModels?.items || [],
    };
  }, deepEqual);

  // flatMap removes undefined values
  const onlineModelCodes = onlineModels.flatMap(onlineModel => onlineModel.onlineModelCode) || [];

  /* TODO: rules-of-hooks, copied from CartProductStep, can't see an easy fix */
  useEffect(() => {
    // We don't want to re-run this if onlineModelCodes changes,
    // because we trust that online models are never removed from state.
    cartItems
      .filter(({ onlineModelCode }) => !onlineModelCodes.includes(onlineModelCode))
      .forEach(({ onlineModelCode }) => dispatch(loadOnlineModelWithId(onlineModelCode)));
  }, [cartItems, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const totalProductQuantity = getTotalProductQuantity(cartItems);

  const onPaymentOptionChange = (productId: string, paymentOptionId: string) => {
    const cartItem = findCartItem(productId, cartItems);
    if (!cartItem) {
      // These should never happen: the productId is mapped from existing data.
      return;
    }
    const newCommercialProductCodes = paymentOptionId.split(ID_ITEM_SEPARATOR);
    const [onlineModelCode, offerCode] = productId.split(ID_GROUP_SEPARATOR);
    const onlineModel = findOnlineModel(onlineModelCode, onlineModels);
    if (!onlineModel) {
      // These should never happen: the productId is mapped from existing data.
      return;
    }
    // Filter out addons, offers etc. not relevant for user.
    const processedOnlineModel = processOnlineModel(onlineModel, companyInfo, authenticatedUser);
    const processedOffers = filterAndSortOffersByAvailability(processedOnlineModel.offers);
    const offer = findOffer(offerCode, processedOffers);
    if (offer) {
      const newCommercialProducts = findCommercialProducts(newCommercialProductCodes, offer);
      if (newCommercialProducts && newCommercialProducts.length) {
        dispatch(updateCartItemPayment(cartItem, newCommercialProducts, offer, processedOnlineModel));
      }
    }
  };

  // If discounts are not loaded, do not show items. Otherwise the user might see wrong price first.
  if (companyInfo?.discountedPricesLoading) {
    return <Spinner />;
  }

  return (
    <CL.ShoppingCart
      ariaAddonsLabel={t.LXSR(additionalServicesMsg)}
      ariaPaymentLabel={t.EM2Q(paymentPeriodMsg)}
      ariaQuantityDeleteLabel={t.R3VE(removeMsg)}
      ariaQuantityLabel={t.M0W7(quantityMsg)}
      ariaQuantityMinusLabel={t.C2KQ(subtractMsg)}
      ariaQuantityPlusLabel={t.VKFM(addMsg)}
      ariaTotalsLabel={t.CEQ2(totalMsg)}
      caption={
        totalProductQuantity === 1
          ? t.WPZI(shoppingCartContentSingularMsg)
          : t.YA97(shoppingCartContentPluralMsg, `${totalProductQuantity}`)
      }
      checkoutUrl={paths.DEVICE_CHECKOUT}
      i18nCheckoutLabel={t.UAAP(checkoutMsg)}
      i18nContinueLabel={t.VLZR(continueShoppingMsg)}
      i18nEmptyLabel={t.PRFW(cartIsEmptyMsg)}
      i18nHeading={t.BE8Q(shoppingCartMsg)}
      items={mapProducts(cartItems, companyInfo, onlineModels, authenticatedUser)}
      cancelUrl={previousPathname}
      onCancel={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        navigate(previousPathname);
      }}
      onCheckout={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        navigate(toCheckout);
      }}
      onPaymentOptionChange={onPaymentOptionChange}
      onQuantityChange={(productId: string, newQuantity: number) => {
        const item = findCartItem(productId, cartItems);
        if (item) {
          dispatch(updateCartItem(item, newQuantity));
        }
      }}
      totals={resolveTotalPrices(cartItems)}
      totalProductQuantity={totalProductQuantity}
    />
  );
};
