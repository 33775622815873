import * as CL from '@design-system/component-library';
import { RecordInput } from './RecordInput.js';
import { t } from '../../../../../common/i18n/index.js';
import { validateHostname, validatePort, validatePriority, validateWeight } from './fieldValidation.js';

export const SRVValueField = ({ index }: { index: number }) => (
  <div className="ds-display--flex">
    <CL.GridCol colsXS={2}>
      <RecordInput label={t.DOXL('target')} name="target" validate={validateHostname} index={index} />
    </CL.GridCol>
    <CL.GridCol colsXS={2}>
      <div className="ds-display--flex">
        <div className="ds-margin-right--2">
          <RecordInput label={t.UUO7('Weight')} name="weight" type="number" validate={validateWeight} index={index} />
        </div>
        <div className="ds-margin-right--2">
          <RecordInput label={t.BW68('Port')} name="port" type="number" validate={validatePort} index={index} />
        </div>
        <div className="ds-margin-right--2">
          <RecordInput
            label={t.ZS4S('Priority')}
            name="priority"
            type="number"
            validate={validatePriority}
            index={index}
          />
        </div>
      </div>
    </CL.GridCol>
  </div>
);
