import { fetchAddOnsVisibilities } from '../fetch.js';
import { useEffect, useState } from 'react';
import type { AddOnVisibility } from '../../generated/api/addOnVisibility.js';
import type { AddOnVisibilityOperationType } from '../enums.js';

export interface AddOnVisibilityResult {
  addOnVisibilitiesLoading: boolean;
  addOnVisibilities?: AddOnVisibility[];
  addOnVisibilitiesError?: string;
}

export const useAddOnVisibility = (
  commercialProductCode: string,
  operationType: AddOnVisibilityOperationType,
  preFetchedAddOnVisibilities?: AddOnVisibility[]
): AddOnVisibilityResult => {
  const [addOnVisibilitiesError, setAddOnVisibilitiesError] = useState<string>();
  const [addOnVisibilities, setAddOnVisibilities] = useState<AddOnVisibility[] | undefined>(
    preFetchedAddOnVisibilities
  );
  const [addOnVisibilitiesLoading, setAddOnVisibilitiesLoading] = useState(false);

  useEffect(() => {
    // If pre-fetched visibilities are provided, skip the fetch
    if (preFetchedAddOnVisibilities) {
      setAddOnVisibilities(preFetchedAddOnVisibilities);
      return;
    }

    setAddOnVisibilitiesLoading(true);

    fetchAddOnsVisibilities(commercialProductCode, operationType)
      .then(res => {
        setAddOnVisibilities(res.addOnVisibilities);
        setAddOnVisibilitiesLoading(false);
      })
      .catch((e: Error) => {
        setAddOnVisibilitiesError(e.message);
      })
      .finally(() => setAddOnVisibilitiesLoading(false));
  }, [commercialProductCode, preFetchedAddOnVisibilities, operationType]);

  return { addOnVisibilities, addOnVisibilitiesLoading, addOnVisibilitiesError };
};
