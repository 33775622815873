import * as CL from '@design-system/component-library';
import { Dropdown } from '../../common/react-hook-form/components/Dropdown.js';
import { FormProvider, useForm } from 'react-hook-form';
import { PhoneNumber } from '../../common/react-hook-form/fields/index.js';
import { SelectRadio } from '../../common/react-hook-form/components/SelectRadio.js';
import { SubscriptionPbxConfiguration } from '../../generated/api/subscriptionPbxConfiguration.js';
import { SubscriptionPbxDetails } from '../../generated/api/subscriptionPbxDetails.js';
import {
  cancelMsg,
  companyNumberMsg,
  confirmMsg,
  connectToNumberMsg,
  extensionMsg,
  t,
} from '../../common/i18n/index.js';
import { forceInternationalPhoneNumberFormat, formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import type { DropdownKludgeSubscriptionPbxConfiguration } from './PbxNumberSettingsUtil.js';
import type { RangeNumbersState } from '../../common/types/states.js';

const createCorporateNumberDropdownItems = (
  pbxConfiguration: DropdownKludgeSubscriptionPbxConfiguration,
  numberRange?: RangeNumbersState
): CL.DropDownItem[] => {
  const options: CL.DropDownItem[] =
    numberRange?.allNumbers?.map(phoneNumber => ({
      label: formatPhoneNumber(phoneNumber, true),
      value: forceInternationalPhoneNumberFormat(phoneNumber)!,
    })) ?? [];

  if (pbxConfiguration.corporateNumber) {
    options.push({
      label: formatPhoneNumber(pbxConfiguration.corporateNumber, true),
      value: forceInternationalPhoneNumberFormat(pbxConfiguration.corporateNumber)!,
    });
  }

  return options;
};

const createExtensionNumberOptions = (
  pbxConfiguration: DropdownKludgeSubscriptionPbxConfiguration,
  extensionNumberRange?: RangeNumbersState
): CL.DropDownItem[] => {
  const options: CL.DropDownItem[] =
    extensionNumberRange?.allNumbers?.map(extensionNumber => ({
      label: formatPhoneNumber(extensionNumber),
      value: extensionNumber,
    })) ?? [];

  if (pbxConfiguration.extensionNumber) {
    options.push({
      label: formatPhoneNumber(pbxConfiguration.extensionNumber),
      value: pbxConfiguration.extensionNumber,
    });
  }

  return options;
};

const createCalloutTimeOptions = () => [
  { label: '15 s', value: '15' },
  { label: '20 s', value: '20' },
  { label: '25 s', value: '25' },
  { label: '30 s', value: '30' },
];

const createBusyActionOptions = () => [
  {
    label: t.EGRR('Next extension number'),
    value: SubscriptionPbxConfiguration.BusyActionEnum.NEXT_NUMBER_IN_CHAIN,
  },
  {
    label: t.J5SL('Dial tone'),
    value: SubscriptionPbxConfiguration.BusyActionEnum.BUSY_TONE,
  },
  {
    label: t.HS6F(connectToNumberMsg),
    value: SubscriptionPbxConfiguration.BusyActionEnum.CONNECT_TO_NUMBER,
  },
];

export interface PbxNumberSettingsEditorProps {
  subscriptionPbxConfiguration: DropdownKludgeSubscriptionPbxConfiguration;
  numberRange?: RangeNumbersState;
  extensionNumberRange?: RangeNumbersState;
  onConfirm: (kludgyConfig: DropdownKludgeSubscriptionPbxConfiguration) => void;
  onCancel: () => void;
  saving?: boolean;
}

export const PbxNumberSettingsEditor = ({
  subscriptionPbxConfiguration,
  numberRange,
  extensionNumberRange,
  onConfirm,
  onCancel,
  saving,
}: PbxNumberSettingsEditorProps): JSX.Element => {
  const methods = useForm<DropdownKludgeSubscriptionPbxConfiguration>({
    values: subscriptionPbxConfiguration,
    mode: 'all',
  });

  const findBestMatch = (phoneNumber: string, extensions: string[] = []) => {
    if (extensions.length === 0) {
      return '';
    }

    const lastThreeDigits = phoneNumber.slice(-3);
    const lastTwoDigits = phoneNumber.slice(-2);

    return (
      extensions.find(num => num.toString().slice(-3) === lastThreeDigits) ||
      extensions.find(num => num.toString().slice(-2) === lastTwoDigits) ||
      ''
    );
  };

  const onSelectCorporateNumber = (selectedCorporateNumber: string) => {
    if (selectedCorporateNumber) {
      const bestMatch = findBestMatch(selectedCorporateNumber, extensionNumberRange?.allNumbers);
      methods.setValue('extensionNumber', bestMatch);
    }
  };

  const busyAction = methods.watch('busyAction');
  const connectToNumberRequired = busyAction === SubscriptionPbxConfiguration.BusyActionEnum.CONNECT_TO_NUMBER;

  const onCancelEdit = () => {
    onCancel();
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form className="of-pbx-number-settings" onSubmit={methods.handleSubmit(onConfirm)}>
        <div className="of-pbx-number-settings__row">
          <div id="corporate-number" className="of-pbx-number-settings__row-item">
            <Dropdown
              name="corporateNumber"
              items={createCorporateNumberDropdownItems(subscriptionPbxConfiguration, numberRange)}
              label={t.ZX32(companyNumberMsg)}
              onValueChange={onSelectCorporateNumber}
            />
          </div>
          <div id="extension-number" className="of-pbx-number-settings__row-item narrow">
            <Dropdown
              name="extensionNumber"
              items={createExtensionNumberOptions(subscriptionPbxConfiguration, extensionNumberRange)}
              label={t.NO66(extensionMsg)}
            />
          </div>
        </div>
        <div className="of-pbx-number-settings__row">
          <div id="busy-action" className="of-pbx-number-settings__row-item">
            <SelectRadio
              name="busyAction"
              items={createBusyActionOptions()}
              label={`${
                subscriptionPbxConfiguration.pbxConfigurationDetails.pbxType ===
                SubscriptionPbxDetails.PbxTypeEnum.RYHMAKUTSU
                  ? t.H9TQ('When the group')
                  : t.WMAS('When the number')
              } ${t.HXOW('is busy')}`}
            />
            <div
              id="connect-to-number"
              className={[
                'of-pbx-number-settings__connect-to-number',
                connectToNumberRequired ? '' : 'of-pbx-number-settings__row-item__hidden',
              ].join(' ')}
            >
              <PhoneNumber name="connectToNumber" label="" required={connectToNumberRequired} />
            </div>
          </div>
          <div id="alarm-time" className="of-pbx-number-settings__row-item narrow">
            <Dropdown
              name="delayForChainCalls"
              optional={false}
              items={createCalloutTimeOptions()}
              label={t.DTYT('Callout time')}
            />
          </div>
        </div>
        <div className="of-pbx-number-settings__actions">
          <CL.Button size="l" type="submit" loading={!!saving}>
            {t.QVYK(confirmMsg)}
          </CL.Button>
          <CL.Button onClick={onCancelEdit} size="l" color="link">
            {t.B2V1(cancelMsg)}
          </CL.Button>
        </div>
      </form>
    </FormProvider>
  );
};
