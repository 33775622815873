import { ButtonGroup, SubscriptionDetailsButtonType } from '../SubscriptionDetails/subscriptionDetailsButtons.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { Loading } from '../Loading/index.js';
import {
  SubscriptionDetailsAccordions,
  SubscriptionDetailsBillingAccount,
  getUpdateButtonType,
} from '../SubscriptionDetails/SubscriptionDetails.js';
import { SubscriptionDetailsMobile } from '../SubscriptionDetails/SubscriptionDetailsMobile.js';
import { SubscriptionStatusType } from '../../generated/api/subscriptionStatusType.js';
import { SubscriptionType } from '../../generated/api/subscriptionType.js';
import { buildPathVariable, paths } from '../../common/constants/pathVariables.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { findSubscription, getSubscriptionStatus } from '../../common/utils/subscriptionUtils.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { getSubscriptionDetailsBroadband } from '../SubscriptionDetails/subscriptionDetailsBroadband.js';
import {
  loadSubscriptionAddOnRules,
  loadSubscriptionAddOnRulesMobilePbx,
  resumeSubscription,
  showDialog,
  showTerminateSubscriptionForm,
  terminateSubscriptionAction,
} from '../../selfservice/actions/index.js';
import { mobileBroadbandMsg, mobileM2MMsg, t } from '../../common/i18n/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { DialogParams } from '../../common/types/dialog.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionDetailsProps } from '../SubscriptionDetails/SubscriptionDetails.js';

export const SubscriptionBroadband = (props: SubscriptionDetailsProps) => {
  const { breadCrumbs, subscriptionId, subscriptions } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscription = findSubscription(subscriptionId, subscriptions);
  const subscriptionActions = useSelector(
    (state: State) => state?.selfservice?.pendingSubscriptionActions || undefined,
    deepEqual
  );
  const showSuspendSubscriptionOptions = subscriptionActions?.suspendSubscriptionInitiated || false;
  const isTerminateSubscriptionForm = subscriptionActions?.terminateSubscriptionInitiated || false;
  const onShowSubscriptionTerminationForm = () => dispatch(showTerminateSubscriptionForm());
  const onResumeSubscription = (subId: string) => dispatch(resumeSubscription(subId));
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));

  const onClickSubscriptionChangeOfferSelection = (subDisplayId: string, subscriptionType: SubscriptionType) => {
    const redirectPath = (() => {
      switch (subscriptionType) {
        case SubscriptionType.MOBILE_M2M:
          return paths.PS_BROADBAND_SUBSCRIPTION_UPDATE_M2M;
        case SubscriptionType.MOBILE_BROADBAND:
          return paths.PS_BROADBAND_SUBSCRIPTION_UPDATE;
        default:
          return paths.PS_MOBILE_SUBSCRIPTION_UPDATE;
      }
    })();

    switch (subscriptionType) {
      case SubscriptionType.MOBILE_PBX:
        dispatch(loadSubscriptionAddOnRulesMobilePbx());
        break;
      case SubscriptionType.MOBILE_M2M:
        dispatch(loadSubscriptionAddOnRules());
        break;
      case SubscriptionType.MOBILE_BROADBAND:
      case SubscriptionType.MOBILE:
      default:
        dispatch(loadSubscriptionAddOnRules());
        break;
    }

    navigate(buildPathVariable(redirectPath, subDisplayId));
  };

  const subscriptionDisplayId = subscription ? subscription.subscriptionDisplayId : '…';
  const subscriptionStatus = getSubscriptionStatus(subscription, subscriptionActions?.items);
  const isSuspended = subscription && subscription.subscriptionStatus === SubscriptionStatusType.SUSPENDED;

  if (!subscription?.details) {
    return (
      <DetailsWrapper
        classes={['of-subscription-details']}
        content={<Loading />}
        detailsTop={breadCrumbs}
        heading={subscription?.subscriptionUserFriendlyId || subscription?.subscriptionPurposeOfUse || ''}
        headingBottom=""
        headingTop={!subscription ? undefined : t.VI0P('Broadband')}
        heroPicto={!subscription ? undefined : 'mobilebb'}
        id={`subscription-details-${subscriptionDisplayId}`}
      />
    );
  }

  if (
    subscription.subscriptionType === SubscriptionType.MOBILE_BROADBAND ||
    subscription.subscriptionType === SubscriptionType.MOBILE_M2M
  ) {
    if (!subscription.details.mobile) {
      throw new Error(`Subscription details doesn't contain broadband details`);
    }

    const isLaitenetti = subscription.subscriptionType === SubscriptionType.MOBILE_M2M;

    const buttons = [
      getUpdateButtonType(
        subscription!,
        getSubscriptionStatus(subscription, subscriptionActions?.items).isChangeOfferAllowed
      ),
      SubscriptionDetailsButtonType.SUPPORT_REQUEST,
      isSuspended ? SubscriptionDetailsButtonType.RESUME_SUBSCRIPTION : SubscriptionDetailsButtonType.TEMPORARILY_CLOSE,
      SubscriptionDetailsButtonType.TERMINATE,
    ];

    if (subscription.subscriptionType === SubscriptionType.MOBILE_BROADBAND) {
      buttons.push(SubscriptionDetailsButtonType.MOBILE_FIXER);
    }

    const subscriptionDetails = (
      <div className="of-subscription-details__content of-subscription-details__content--mobile">
        <SubscriptionDetailsMobile
          dispatch={dispatch}
          onShowDialog={onShowDialog}
          subscription={subscription}
          subscriptionStatus={subscriptionStatus}
          details={subscription.details}
          showSuspendSubscriptionOptions={showSuspendSubscriptionOptions}
          showTerminateSubscriptionForm={isTerminateSubscriptionForm}
          onTerminateSubscription={(subId, effectiveDate, donateNumber) =>
            dispatch(terminateSubscriptionAction(subId, effectiveDate, donateNumber))
          }
          onResumeSubscription={onResumeSubscription}
          billingAccountSelection={<SubscriptionDetailsBillingAccount {...props} />}
          mobileDetails={subscription.details.mobile}
        />
        {!isTerminateSubscriptionForm && !showSuspendSubscriptionOptions && (
          <ButtonGroup
            buttonTypes={buttons}
            subscription={subscription}
            subscriptionStatus={subscriptionStatus}
            onShowDialog={onShowDialog}
            onShowSubscriptionTerminationForm={onShowSubscriptionTerminationForm}
            onResumeSubscription={onResumeSubscription}
            onClickCompanyInfoUser={() => {
              navigate(paths.COMPANY_INFO_RING_ADMINS);
            }}
            onClickSubscriptionChangeOfferSelection={onClickSubscriptionChangeOfferSelection}
          />
        )}
        <SubscriptionDetailsAccordions {...props} />
      </div>
    );

    return (
      <DetailsWrapper
        classes={['of-subscription-details']}
        content={subscriptionDetails}
        detailsTop={breadCrumbs}
        heading={(subscription && subscription.subscriptionPurposeOfUse) || '…'}
        headingBottom={formatPhoneNumber(subscription.details.mobile.mobileNumber!) || ''}
        headingTop={isLaitenetti ? t.A7CA(mobileM2MMsg) : t.EQKJ(mobileBroadbandMsg)}
        heroPicto={isLaitenetti ? 'mobile-m2m' : 'mobilebb'}
        id={`subscription-details-${subscriptionDisplayId}`}
      />
    );
  } else {
    if (!subscription.details.broadband) {
      throw new Error(`Subscription details doesn't contain broadband details`);
    }

    const subscriptionDetails = (
      <div className="of-subscription-details__content of-subscription-details__content--broadband">
        {getSubscriptionDetailsBroadband(
          onShowDialog,
          subscription,
          subscriptionStatus,
          subscription.details,
          onResumeSubscription,
          <SubscriptionDetailsBillingAccount {...props} />
        )}
        <ButtonGroup
          buttonTypes={[
            SubscriptionDetailsButtonType.SUPPORT_REQUEST,
            SubscriptionDetailsButtonType.TERMINATE_VIA_DIALOG,
            SubscriptionDetailsButtonType.BROADBAND_FIXER,
          ]}
          subscription={subscription}
          subscriptionStatus={subscriptionStatus}
          onShowDialog={onShowDialog}
        />
        <SubscriptionDetailsAccordions {...props} />
      </div>
    );
    return (
      <DetailsWrapper
        classes={['of-subscription-details']}
        content={subscriptionDetails}
        detailsTop={breadCrumbs}
        heading={subscription.subscriptionUserFriendlyId || subscription.subscriptionPurposeOfUse || ''}
        headingBottom=""
        headingTop={t.VI0P('Broadband')}
        heroPicto={!subscription ? undefined : 'fixedbb'}
        id={`subscription-details-${subscriptionDisplayId}`}
      />
    );
  }
};
