import './Sticker.scss';

export type StickerProps = {
  saving?: string;
  text?: string;
};

export const Sticker = ({ saving, text }: StickerProps) => {
  if (!saving && !text) {
    return null;
  }
  return (
    <div className="of-sticker">
      {text && <div className={saving ? 'ds-text--xs' : 'ds-text--l'}>{text}</div>}
      {saving && <div className={text ? 'ds-text--s' : 'ds-text--l'}>{saving}</div>}
    </div>
  );
};
