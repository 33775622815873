import type { Question, SurveyConfiguration } from './types.js';

export const data: Question[] = [
  {
    question: 'Tiedätkö, minkä datan suojaaminen on liiketoimintanne jatkuvuuden kannalta kriittisintä?', // 1
    content: [
      'Niin pienen kuin suuremmankin yrityksen on tunnistettava yrityksen toiminnan ja jatkuvuuden kannalta elintärkeät tiedot, ohjelmistot ja järjestelmät. Esimerkiksi sensitiiviset henkilötiedot ja asiakkaita koskevat luottamukselliset tiedot kannattaa suojata hyvin.',
      'Vastauksenne perusteella vaikuttaa, että yrityksenne liiketoiminnan jatkuvuus saattaa olla uhattuna. Suosittelemme, että huolehditte kriittisen datan varmuuskopioinnista, salauksesta, suojauksesta ja palauttamisesta. Lisäksi kannattaa dokumentoida yrityksen laitteet, ohjelmistot, järjestelmät ja niiden vaatimat tietoturvaratkaisut.',
    ],
    answers: [
      'Olemme kartoittaneet ja luokitelleet datan kriittisyyden perusteella',
      'Suurinpiirtein tiedossa on, mutta emme ole dokumentoineet asiaa',
      'Ei selkeää kuvaa',
    ],
  },
  {
    question: 'Oletteko kartoittaneet ja arvioineet yrityksenne tietoturvariskit?', // 2
    content: [
      'Hyvän tietoturvan varmistamiseksi yrityksen kannattaa kartoittaa tietoturvariskit sekä arvioida riskien todennäköisyys ja vaikutus. Kartoituksessa käydään läpi kaikki yrityksen käyttämät järjestelmät, ohjelmistot ja laitteet, online palvelut sekä ulkoistetut palvelut. Näiden tietoturvariskien todennäköisyys arvioidaan sekä määritetään vaikuttavuus eli kuinka vakavasti riski toteutuessaan uhkaisi yrityksen toimintaa.',
      'Vastauksenne perusteella suosittelemme, että panostaisitte liiketoimintanne kannalta relevanttien riskin tunnistamiseen ja torjuntaan. Tämä ei aina ole helppoa, ja tällöin kannattaakin käyttää apuna tietoturvan ammattilaista.',
    ],
    answers: ['On tehty tai päivitetty äskettäin', 'On suunniteltu tehtäväksi tai tehty osittain', 'Puuttuu kokonaan'],
  },
  {
    question: 'Onko teillä käytössänne pääsynhallinta ja monivaiheinen tunnistautuminen?', // 3
    content: [
      'Pääsynhallinta ja monivaiheinen tunnistautuminen ovat tärkeitä myös pienille yrityksille. Pääsynhallinta varmistaa, että työntekijät pääsevät käsiksi vain siihen tietoon, joka on oleellista heidän työtehtäviensä suorittamiseksi.',
      'Käyttäjätunnuksen väärinkäyttö voidaan nopeasti estää, jos esimerkiksi tunnus vuotaa tietojenkalastelussa rikollisille. Hyvään pääsynhallintaan kuuluvat riittävän vahvat salasanat ja monivaiheinen tunnistautuminen. Monivaiheinen tunnistautuminen estää esimerkiksi kirjautumisen yrityksen järjestelmään vieraalta laitteelta tilanteessa, jossa yrittäjän tai työntekijän tunnukset joutuvat vääriin käsiin.',
    ],
    answers: [
      'Kaikkiin järjestelmiin on pääsynhallinta ja monivaiheinen tunnistautuminen',
      'Osassa järjestelmistä kyllä',
      'Ei keskitettyä pääsynhallintaa',
    ],
  },
  {
    question: 'Kuinka yrityksenne päätelaitteet on suojattu tietoturvahyökkäyksiä vastaan?', // 4
    content: [
      'Yrityksen päätelaitteet on hyvä suojata suoria hyökkäyksiä, tietojenkalasteluyrityksiä ja käyttäjän virheitä vastaan. Kaikkien päätelaitteiden, niin tietokoneiden kuin mobiililaitteiden, ohjelmallinen suojaus on tärkeä osa teknistä tietoturvaa. Jatkuvasti päivittyvä tietoturvaohjelmisto suojaa niin viruksilta, verkon yli tapahtuvilta hyökkäyksiltä kuin tietojen kalasteluyrityksiltäkin.',
      'Myös mobiililaitteilla pääsee kirjautumaan sisään yrityksen järjestelmiin, joten nekin kannattaa suojata tietoturvaohjelmistolla. Nykyään rikolliset käyttävät mobiililaitteita myös palvelunestohyökkäyksissä.',
      'Koska nykyisine mobiililaitteitta pääsee kirjautumaan sisään yrityksen järjestelmiin ihan samalla tavalla kuin tietokoneille, myös mobiililaitteet kannattaa suojata tietoturvaohjelmistolla. Varsinkin kun rikolliset käyttävät myös mobiililaitteita yhä tehokkaammin hyväkseen esimerkiksi palvelunestohyökkäyksissä',
      'Huolehdithan pian päätelaitteidenne tietoturvan ajan tasalle?',
    ],
    answers: [
      'Sekä tietokoneissa että mobiilaitteissa on ajantasaiset tietoturvaohjelmistot',
      'Tietoturvaohjelmistoja on joskus asennettu',
      'Ei tietoa, mitä laitteissa on',
    ],
    limit: 1,
  },
  {
    question: 'Kuinka usein yrityksenne ohjelmistot päivitetään ajan tasalle?', // 5
    content: [
      'Rikolliset ja ohjelmistoyhtiö käyvät jatkuvaa kilpajuoksua, jossa rikolliset etsivät uusia aukkoja hyödynnettäväksi, ja ohjelmistoyhtiöt tukkivat havaittuja aukkoja uusilla tietoturvapäivityksillä.',
      'Ohjelmistojen ja päätelaitteiden käyttöjärjestelmien automaattinen päivitys uusimpaan versioon tukkii rikollisten löytämät tietoturva-aukot. Esimerkiksi internet-selaimet pitäisi automaattisesti päivittää aina uusimpaan versioonsa.',
      'Aina kun mahdollista, kannattaa ottaa automaatio avuksi, jotta päivittäminen ei ole pelkästään oman tai kollegoidesi muistisi varassa.',
    ],
    answers: [
      'Kaikkia päivitykset asentuvat automaattisesti',
      'Käyttäjät asentavat itse päivitykset',
      'Päivitetään, jos/kun muistetaan',
    ],
    limit: 1,
  },
  {
    question: 'Kuinka moni yrityksessänne osaa käyttää sähköpostia tietoturvallisella tavalla?', // 6
    content: [
      'Huolellisinkin sähköpostin käyttäjä tekee helposti virheitä. Käyttäjä voi esimerkiksi lähettää luottamuksellisen sähköpostin väärälle henkilölle, klikata epähuomiossa tietojenkalastelun linkkiä tai ladata haitallisen liitetiedoston.',
      'Työntekijöitä tuleekin jatkuvasti kouluttaa tietoturvalliseen sähköpostinkäyttöön ja sähköpostin kautta kohdistuvien uhkien tunnistamiseen ja välttämiseen. Luottamuksellinen tieto, kuten sensitiiviset henkilötiedot, tulisi lähettää salattuina viesteinä.',
      'Hyvä tapa pitää kollegat valppaina on jakaa toisillenne tietoa ja esimerkkejä sähköpostiinne tulevista huijausyrityksistä. Näin uudet huijausviestit on helppo tunnistaa.',
    ],
    answers: [
      'Jaamme jatkuvasti tietoa sisäisesti oikeasta sähköpostin käyttötavasta',
      'Olemme joskus asiasta keskustelleet',
      'Emme ole kiinnittäneet asiaan huomiota',
    ],
    limit: 1,
  },
  {
    question: 'Kuinka hyvin teillä on huomioitu pikaviestimien kuten WhatsApp ja Slack turvallinen käyttö?', // 7
    content: [
      'Pikaviestimet, kuten WhatsApp ja Slack, ovat osa modernia hybridityötä, mutta ne myös altistavat erilaisille tietoturvauhkille. Pikaviestipalvelujen kautta rikolliset jakavat haittaohjelmia sekä houkuttelevia haittalinkkejä. Moni pikaviestipalvelu säilyttää dataa EU-alueen ulkopuolella, joten henkilötietojen lähettäminen saattaa rikkoa tietosuojalakeja.',
      'Yrityksessänne kannattaa sopia, mitä ja miten pikaviestipalveluja voi turvallisesti käyttää sekä millaista tietoa pikaviestimissä voi lähettää. Yksi yleisimmin käytettyjä turvallisia viestintäkanavia on Microsoft-ympäristön Teams-palvelu.',
    ],
    answers: [
      'Pikaviestinten käyttö on selkeästi määritelty',
      'Olemme asiasta keskustelleet sisäisesti',
      'Ei selkeitä ohjeita pikaviestimien käyttöön',
    ],
  },
  {
    question: 'Kuinka usein koulutatte yrityksenne henkilöstöä tietoturva-asioista?', // 8
    content: [
      'Vaikka yrityksen tietoturva olisi teknisesti kunnossa, yrittäjän ja työntekijöiden tapa tehdä töitä vaikuttaa yrityksen kokonaisturvallisuuteen merkittävästi. Sekä yrityksen johtoa että työntekijöitä kannattaa jatkuvasti kouluttaa tietoturvasta. Siten lisäätte yleistä tietoisuutta tietoturvasta.',
      'Hyvät tiedot ja taidot tietoturvan käytännöistä sekä tietoisuus kyberuhkista rakentavat turvallisempaa työympäristöä, jossa jokainen tunnistaa oma roolinsa ja vastuunsa koko organisaation tietoturvan tasosta. Tietoturvallinen tapa toimia on yhä tärkeämpi taito kaikenlaisissa työtehtävissä – ja vapaa-ajalla.',
    ],
    answers: [
      'Koulutamme henkilöstöä useamman kerran vuodessa',
      'Pidämme silloin tällöin koulutuksia',
      'Emme ole järjestäneet erityistä koulutusta',
    ],
  },
  {
    question: 'Miten hyvin yrityksessänne on huolehdittu etätöiden tietoturvasta?', // 9
    content: [
      'Etätyön turvallisuudessa korostuvat työtavat, päätelaitteiden tietoturva ja verkon tietoturva.',
      'Etätyöntekijän on huolehdittava, että ulkopuoliset eivät pääse käyttämään työkonetta. Kaikki työtiedostot on tallennettava yrityksen varmuuskopioituun tietojärjestelmään, esimerkiksi valittuun pilvipalveluun, jotta tiedon tahattomalta tai tahalliselta tuhoamiselta vältytään.',
      'Myös kotiverkon tulee olla suojattu. Kodin ulkopuolella kannattaa käyttää turvallista mobiiliverkon yhteyttä avointen WiFi-verkkojen sijaan. Matkoilla kannattaa valita tarkkaan työpiste, jotta ulkopuoliset eivät vahingossa näe tai kuule luottamuksellisia keskusteluja.',
    ],
    answers: [
      'Työskentelytapojen ja yhteyksien valinnassa on käytetty paljon harkintaa',
      'Etätyötä voidaan tehdä melko turvallisesti',
      'Jokainen työntekijä hoitaa etätyöt omalla tyylillään',
    ],
  },
  {
    question: 'Kuinka hyvin olette suojautuneet palvelunestohyökkäyksiä vastaan?', // 10
    content: [
      'Palvelunestohyökkäykset ovat erittäin näkyvä tietoturvauhka. Palvelunestohyökkäyksien määrä on kasvussa ja niiden uhreiksi ovat joutuneet myös pienemmät organisaatiot.',
      'Jos yrityksellä on kriittisiä verkkopalveluja, joiden tulee toimia tilanteessa kuin tilanteessa, palvelunestohyökkäyksiin kannattaa varautua. Jos yrityksen oma tietoturvatiimin resurssit eivät riitä palvelunestohyökkäyksiltä suojautumiseen, kannatta turvautua ulkopuoliseen tietoturvakumppaniin, kuten Elisaan.',
    ],
    answers: ['On suojauduttu', 'Ei ole tarvetta suojautua', 'Olisi tarve, mutta ei ole suojauduttu'],
  },
  {
    question: 'Tiedättekö, mitä tehdä tietomurron tapahtuessa?', // 11
    content: [
      'Timanttisinkaan varautuminen ei aina pelasta erehdyksiltä ja tietoturvamurroilta. Mitä nopeammin tietoturvapoikkeama saadaan haltuun ja ratkaistua, sitä vähemmillä vaurioilla yritys pääsee.',
      'Jos esimerkiksi työntekijä huomaa vahingossa klikanneensa saastuneen viestin linkkiä ja ilmoittaa siitä sovittuun paikkaan välittömästi tapahtuneen jälkeen, yrityksen tietoturvatiimi, IT-tuki tai pienen yrityksen ulkoinen IT-asiantuntija voi nopealla reagoinnilla, estää tunnuksen väärinkäytön, eristää työntekijän päätelaitteen ja estää häiriön laajenemisen.',
      'Yrityksen kannattaa dokumentoida häiriötilanteiden toimenpiteet. Jokaisen on tiedettävä, mitä tehdä ja miten toimia, kun epäillään tietomurtoa. Poikkeustilanteita kannattaa myös harjoitella.',
    ],
    answers: [
      'Meillä on suunnitelma, harjoitukset pidetty ja menetelmä työntekijöiden tiedossa',
      'Osittain tiedetään',
      'Kokonaan tekemättä',
    ],
  },
  {
    question: 'Harjoitteletteko systemaattisesti tietoturvauhkien tunnistamista ja kohtaamista?', // 12
    content: [
      'Tietoturvaharjoitusten avulla työntekijät oppivat tunnistamaan uhkia ja reagoimaan niihin oikealla tavalla. Harjoittelu voi pienissä yrityksissä tapahtua esimerkiksi muutaman kerran vuodessa palaverien yhteydessä.',
      'Isoissa yrityksissä, kuten Elisalla, tietoturvaharjoitukset ovat jatkuva toimintatapa. Työntekijät kohtaavat viikoittain simuloituja hyökkäyksiä, esimerkiksi tiedonkalasteluviestejä sähköpostiin ja mobiililaitteisiin. Työntekijän tehtävänä on oman kiireisen työn arjessa tunnistaa uhkaava viesti ja raportoida siitä.',
    ],
    answers: ['Harjoittelemme säännöllisesti', 'Pari kertaluontoista harjoitusta pidetty', 'Ei olla harjoiteltu'],
  },
];

export const surveyData: SurveyConfiguration = {
  title: 'Tietoturva',
  marketingCloudUrl: 'https://cloud.viestinta.elisa.fi/tietoturva-kysely',
  questions: data,
  results: [
    {
      content:
        'Näyttää siltä että useammat tietoturvan osa-alueet kaipaisivat vahvistamista. Alla näet vinkkejä, miten voisitte kehittää tietoturvaanne ja turvata liiketoiminnan jatkuvuutta. Jos tarvitsette lisää apua tietoturvasi suunnitteluun ja käytäntöönpanoon, ota meihin yhteyttä. Elisan tietoturvan ammattilaiset auttavat mielellään!',
      passLimit: 0,
    },
    {
      content:
        'Olette selvästi panostaneet tietoturvaan, mutta parantamisen varaakin jää. Alla näet suosituksemme, joiden avulla yrityksenne voisi vahvistaa tietoturvaa – kenties jopa tehdä siitä menestystekijän. Jos tarvitsette lisää apua tietoturvasi suunnitteluun ja käytäntöönpanoon, ota yhteyttä Elisan tietoturvan ammattilaisiin!',
      passLimit: 8,
    },
    {
      content:
        'Hienoa, että tietoturva on teille selvästikin tärkeä asia! Kriittisimmät toimenpiteet ovat vastauksiesi perusteella kunnossa. Alla näet vielä vinkkejä, joiden avulla voitte mahdollisia heikompia kohtia vahvistaa. Jos tarvitsette lisää apua tietoturvasi suunnitteluun ja käytäntöönpanoon, ota yhteyttä Elisan tietoturvan ammattilaisiin!',
      passLimit: 16,
    },
    {
      content:
        'Onneksi olkoon, yrityksenne tietoturva-asiat vaikuttavaisivat olevan ihan hyvällä tolalla! Tietoturvauhat ja haavoittuvuudet kehittyvät kuitenkin kovaa vauhtia ja jatkuva valppaus on siksi paikoillaan. Verkko- ja it-ympäristön auditointeja onkin syytä tehdä säännöllisesti jotta tietomurroilta voidaan välttyä myös jatkossa. Jos tietoturvan taso millään tavalla arveluttaa, Elisan asiantuntijat auttavat sinua mielellään kaikessa turvallisuuteen liittyvissä kysymyksissä.',
      passLimit: 24,
    },
  ],
};
