import { TurbonappiOffer } from '../../generated/api/turbonappiOffer.js';
import { getVatAsString } from '../../common/utils/taxUtils.js';
import { t } from '../../common/i18n/index.js';
import type { SubscriptionCardProps, TurbonappiProps } from '../SubscriptionCard/SubscriptionCard.js';

export const getTurboNappiSubscriptions = (): SubscriptionCardProps[] => [
  {
    name: 'Turbonappi 1000 M',
    mainHeader: t.GZ1X('Day'),
    description: t.E12S('Valid for 24 hours'),
    turbonappiPrice: t.FOHO('{}.{}', '1', '99'),
    priceUnit: '€/24 h',
    turbonappiPriceAdditionalInfo: t.FG0H('Valid for 24 hours. Includes VAT {}%', getVatAsString()),
    turbonappiOffer: TurbonappiOffer.DAY,
    turbonappiFullName: `Turbonappi 1000 M ${t.GZ1X('Day')}`,
  },
  {
    name: 'Turbonappi 600 M',
    mainHeader: t.R0BH('Week'),
    description: t.DRVT('Valid for seven days'),
    recommended: true,
    turbonappiPrice: t.FOHO('{}.{}', '9', '99'),
    priceUnit: `€/${t.VY6Q('week')}`,
    turbonappiPriceAdditionalInfo: t.I7P6('Valid for 7 days. Includes VAT {}%', getVatAsString()),
    turbonappiOffer: TurbonappiOffer.WEEK,
    turbonappiFullName: `Turbonappi 600 M ${t.VY6Q('Week')}`,
  },
  {
    name: 'Turbonappi 300 M',
    mainHeader: t.ZB7A('Month'),
    description: t.Q7MZ('Valid for 31 days'),
    turbonappiPrice: t.FOHO('{}.{}', '19', '99'),
    priceUnit: `€/${t.XXVX('month')}`,
    turbonappiPriceAdditionalInfo: t.PAYI('Valid for 31 days. Includes VAT {}%', getVatAsString()),
    turbonappiOffer: TurbonappiOffer.MONTH,
    turbonappiFullName: `Turbonappi 300 M ${t.ZB7A('Month')}`,
  },
];

export const getTurbonappiSubscriptionCard = (offerName?: TurbonappiOffer): SubscriptionCardProps => {
  const turbonappiSubscription = getTurboNappiSubscriptions().find(offer => offer.turbonappiOffer === offerName);
  if (!turbonappiSubscription) {
    throw new Error(`Turbonappi subscription could not be found using offer: ${offerName}`);
  }
  return turbonappiSubscription;
};

export const getTurbonappiSubscriptionData = (offerName?: TurbonappiOffer): TurbonappiProps => {
  const turbonappiSubscription = getTurbonappiSubscriptionCard(offerName);
  return {
    turbonappiPrice: turbonappiSubscription?.turbonappiPrice || '0',
    priceUnit: turbonappiSubscription?.priceUnit || '',
    turbonappiPriceAdditionalInfo: turbonappiSubscription?.turbonappiPriceAdditionalInfo || '',
    turbonappiOffer: turbonappiSubscription?.turbonappiOffer || TurbonappiOffer.DAY,
    turbonappiFullName: turbonappiSubscription?.turbonappiFullName || '',
  };
};
