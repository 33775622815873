import * as CL from '@design-system/component-library';
import { ErrorTooltip } from '../ErrorTooltip/ErrorTooltip.js';
import { t } from '../../common/i18n/index.js';

// TODO: Rename this component as this seems to be specific to OmaLaiteLasku T&C
export const TermsAndConditions = ({
  onAcceptance,
  isAccepted,
}: {
  onAcceptance: () => void;
  isAccepted: boolean | undefined;
}): JSX.Element => {
  return (
    <>
      <div id="termsAndConditions" className="ds-padding-vertical--4">
        <a
          href="https://static.elisa.com/v2/image/2tqybbhjs47b/7nR6wchRAcdv5uWgNYIGZG/Elisa-Oma-Laitelasku-EPP-sopimusehdot.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <CL.Icon icon="draft" size="m" type="regular" color="blue-600" />{' '}
          {t.DVYV('Elisa Oma Laitelasku terms and conditions')}
        </a>
      </div>
      <div className="ds-padding-vertical--4">
        <CL.Checkbox
          className="ds-color--blue"
          checked={!!isAccepted}
          name="termsAndConditionsCheckbox"
          onChange={onAcceptance}
        >
          <div className="ds-margin-left--1">
            <b>{t.FIRO('I accept the terms and conditions')} </b>
            {t.HT1M('and that Elisa will verify my credit information for billing information')}
          </div>
        </CL.Checkbox>
        {isAccepted === false && (
          <ErrorTooltip placement="bottom">{t.YMMM('Accept the terms and conditions')}</ErrorTooltip>
        )}
      </div>
    </>
  );
};
