import { DeviceCheckoutThankYouDeliveryDetails } from '../../DeviceCheckoutThankYou/DeviceCheckoutThankYouDeliveryDetails.js';
import { OrderSummary, OrderSummaryType } from '../../OrderSummary/OrderSummary.js';
import { PunchoutBillingInfo } from './PunchoutBillingInfo.js';
import { PunchoutButton } from './PunchoutButton.js';
import { PunchoutProducts } from './PunchoutProducts.js';
import { PunchoutUserInfo } from './PunchoutUserInfo.js';
import { getConfiguredCommercialProducts } from '../../../common/utils/cartProductUtils.js';
import { t } from '../../../common/i18n/index.js';
import type { Address, BillingAccount } from '../../../generated/api/models.js';
import type { AuthenticatedUserState } from '../../../common/types/states.js';
import type { DeviceChangeRequest } from '../../../common/types/device.js';
import type { DeviceCheckoutDeliveryDetailsType } from '../../DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';
import type { ShoppingCartItemForCheckout } from '../../../common/types/checkout.js';

import './PunchoutCheckoutConfirmation.scss';

type PunchoutCheckoutConfirmationProps = {
  cartItems: ShoppingCartItemForCheckout[];
  catalogCode: string;
  deliveryDetails: DeviceCheckoutDeliveryDetailsType;
  deviceChangeRequest?: DeviceChangeRequest;
  user: AuthenticatedUserState;
  personBillingAccount?: BillingAccount;
  skipDeliveryDetails: boolean;
};

export const PunchoutCheckoutConfirmation = ({
  cartItems,
  catalogCode,
  deliveryDetails,
  deviceChangeRequest,
  personBillingAccount,
  user,
  skipDeliveryDetails,
}: PunchoutCheckoutConfirmationProps) => {
  const cartItemsCommercialProducts = getConfiguredCommercialProducts(cartItems);

  return (
    <div className="punchout-checkout-confirmation">
      <h1 className="of-main-header ds-h2">{t.IH3X('Checkout')} </h1>
      <div className="ds-text--m ds-margin-bottom--5">
        {t.TPTZ(
          'Please check the contents of your order. After that you will be transferred back to your employer´s portal where you can finish and confirm your order.'
        )}
      </div>
      <PunchoutUserInfo user={user} className="ds-margin-top--7 ds-margin-bottom--7" />
      {personBillingAccount && (
        <PunchoutBillingInfo personBillingAccount={personBillingAccount} className="ds-margin-bottom--8" />
      )}
      <PunchoutProducts cartItems={cartItems} />
      {deliveryDetails && (
        <DeviceCheckoutThankYouDeliveryDetails
          deliveryDetails={deliveryDetails}
          skipDelivery={skipDeliveryDetails}
          companyName={user.companyName}
        />
      )}
      <OrderSummary
        summaryType={OrderSummaryType.DETAILS_CLOSED}
        commercialProducts={cartItemsCommercialProducts}
        classes={['ds-margin-top--7 ds-margin-bottom--7']}
        priceIncludesVAT
      />
      <PunchoutButton
        cartItems={cartItems}
        catalogCode={catalogCode}
        personBillingAccountId={personBillingAccount?.billingAccountId}
        deviceChangeRequest={deviceChangeRequest}
        className="ds-padding-top--4"
        deliveryAddress={
          !skipDeliveryDetails
            ? {
                companyName: deliveryDetails.companyName || user.companyName,
                recipient: deliveryDetails.recipientName || '',
                address: deliveryDetails.address ?? ({} as Address),
                phoneNumber: deliveryDetails.recipientPhoneNumber || '',
              }
            : undefined
        }
      />
    </div>
  );
};
