import * as CL from '@design-system/component-library';
import { OrderSummary, OrderSummaryType } from '../OrderSummary/OrderSummary.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getTotalSums } from '../../common/utils/commercialProductUtils.js';
import { monthMsg, oneTimePaymentMsg, recurringChargesMsg, t, totalMsg } from '../../common/i18n/index.js';
import { onEnterOrSpaceKeyPress } from '../../common/utils/handlerUtils.js';
import { useState } from 'react';
import type { ConfiguredCommercialProduct } from '../../common/types/commercialProduct.js';
import type { SalesType } from '../../generated/api/salesType.js';

import './DeviceCheckoutBillSummaryHeader.scss';

export interface DeviceCheckoutBillSummaryHeaderProps {
  commercialProducts: ConfiguredCommercialProduct[];
  deliveryCharges: number;
  hideZeroDeliveryCharges?: boolean;
  open?: boolean;
  priceIncludesVAT?: boolean;
  buttonText?: string;
  redirectToWebShopButton?: () => void;
  salesType?: SalesType;
}
export const DeviceCheckoutBillSummaryHeader = ({
  commercialProducts,
  deliveryCharges,
  hideZeroDeliveryCharges,
  open,
  priceIncludesVAT,
  buttonText,
  redirectToWebShopButton,
  salesType,
}: DeviceCheckoutBillSummaryHeaderProps) => {
  const [isExpanded, setExpanded] = useState(open);

  const totalSums = getTotalSums(commercialProducts, undefined, salesType);

  const isYttPeriodicOrder = commercialProducts.some(cp => cp.commercialProduct.billingPeriod);

  if (deliveryCharges > 0) {
    totalSums.totalOneTimeCharge += deliveryCharges;
  }

  return (
    <div className="of-device-checkout-bill-summary-header">
      <div className="of-device-checkout-bill-summary-header__container">
        <CL.Grid>
          {!isExpanded ? (
            <CL.GridRow className="of-device-checkout-bill-summary-header__container--collapsed">
              {redirectToWebShopButton && (
                <CL.GridCol colWidthXS={1} colWidthS={1} colWidthM={2}>
                  <button
                    className="of-device-checkout-bill-summary-header__button-container"
                    onClick={(event: React.SyntheticEvent) => {
                      event.preventDefault();
                      redirectToWebShopButton();
                    }}
                    onKeyPress={(event: React.KeyboardEvent) =>
                      onEnterOrSpaceKeyPress(event, () => {
                        redirectToWebShopButton();
                      })
                    }
                  >
                    <CL.Icon icon="arrow-left" size="l" color="blue-600" />
                    <span className="of-device-checkout-bill-summary-header__button-container--button">
                      {buttonText}
                    </span>
                  </button>
                </CL.GridCol>
              )}
              <CL.GridCol colWidthXS={1} colWidthS={1} colWidthM={2}>
                <div
                  className={`of-device-checkout-bill-summary-header__${
                    redirectToWebShopButton ? 'text-end' : 'text'
                  } `}
                >
                  <h4 className="ds-margin--0">{t.CEQ2(totalMsg)}</h4>
                </div>
              </CL.GridCol>
              <CL.GridCol
                colWidthXS={redirectToWebShopButton ? 2 : 3}
                colWidthS={4}
                colWidthM={redirectToWebShopButton ? 2 : 4}
              >
                <div
                  onClick={() => setExpanded(true)}
                  onKeyPress={() => {
                    // TODO Sebastian 28.6.2022 - Proper handlers should be given to this, but currently the styles suffer
                    // TODO could be changed to use DS Accordion or some redesign should be done
                  }}
                  role="button"
                  tabIndex={-1}
                >
                  <div className="of-device-checkout-bill-summary-header__prices">
                    <div className="of-device-checkout-bill-summary-header__prices--item" id="recurringPrice">
                      {isYttPeriodicOrder
                        ? `${t.J4WW(recurringChargesMsg)} ${formatSum(totalSums.totalMonthlyRecurringCharge)}`
                        : `${formatSum(totalSums.totalMonthlyRecurringCharge)}/${t.XXVX(monthMsg)}`}
                    </div>
                    <div className="of-device-checkout-bill-summary-header__prices--item" id="oneTimePrice">
                      {isYttPeriodicOrder
                        ? `${t.GOBY(oneTimePaymentMsg)} ${formatSum(totalSums.totalOneTimeCharge)}`
                        : formatSum(totalSums.totalOneTimeCharge)}
                    </div>
                    <div className="of-device-checkout-bill-summary-header__prices--item">
                      <button className="of-device-checkout-bill-summary-header--button-down">
                        <CL.Icon icon="arrow-down" size="l" color="blue-600" />
                      </button>
                    </div>
                  </div>
                </div>
              </CL.GridCol>
            </CL.GridRow>
          ) : (
            <div className="of-device-checkout-bill-summary-header__container--expanded">
              <CL.GridRow className="of-device-checkout-bill-summary-header__table">
                <CL.GridCol colWidthXS={4} colWidthL={12} colWidthM={6}>
                  <button
                    className="of-device-checkout-bill-summary-header--button-up"
                    id="closeSummaryButton"
                    onClick={() => setExpanded(false)}
                  >
                    <CL.Icon icon="arrow-up" size="l" color="blue-600" />
                  </button>
                </CL.GridCol>
                <CL.GridCol colWidthXS={4} colWidthL={12} colWidthM={6}>
                  <OrderSummary
                    hideZeroDeliveryCharges={hideZeroDeliveryCharges}
                    commercialProducts={commercialProducts}
                    summaryType={OrderSummaryType.DETAILS_ONLY}
                    deliveryCharges={deliveryCharges}
                    classes={['of-device-checkout-bill-summary-header__detailed-summary']}
                    priceIncludesVAT={priceIncludesVAT}
                  />
                </CL.GridCol>
              </CL.GridRow>
            </div>
          )}
        </CL.Grid>
      </div>
    </div>
  );
};
