import { GutterStyle, SelectOneList, type SelectOneListItemProps } from '../../SelectOneList/index.js';
import { ShowMoreButton } from './ShowMoreButton.js';
import { formatPhoneNumber } from '../../../common/utils/phoneNumberUtils.js';
import { t } from '../../../common/i18n/index.js';
import { useState } from 'react';
import type { NumberRange } from '../../../generated/api/numberRange.js';

export const CorporateNumberRangeSelect = ({
  currentPbxSolutionNumberRanges,
  onSelect,
  selectedValue,
}: {
  currentPbxSolutionNumberRanges: NumberRange[];
  onSelect: (field: string, value: string) => void;
  selectedValue?: string;
}) => {
  const [amountOfRangesShown, setAmountOfRangesShown] = useState(8);

  const getOption = (numberRange: NumberRange): SelectOneListItemProps => ({
    element: (
      <div className="of-attach-pbx-content__selection of-select-number-list">
        <div className="of-attach-pbx-content__number-range-row">{`${formatPhoneNumber(
          numberRange.startNumber,
          true
        )} – `}</div>
        <div className="of-attach-pbx-content__number-range-row">{`${formatPhoneNumber(
          numberRange.endNumber,
          true
        )}`}</div>
      </div>
    ),
    isSelected: selectedValue === numberRange.rangeId,
    onClick: () => onSelect('corporateNumberRangeId', numberRange.rangeId),
  });

  return (
    <div id="subscription-company-number-space" className="ds-margin-top--4">
      <div className="ds-font-size--large">{t.H094('Corporate numbering space')}:</div>
      <SelectOneList
        disableIcon={true}
        gutterStyle={GutterStyle.Minimal}
        maxItemsAsideDesktop={4}
        maxItemsAsideLaptop={4}
        maxItemsAsideTablet={3}
        options={currentPbxSolutionNumberRanges
          .slice(0, amountOfRangesShown)
          .map(numberRange => getOption(numberRange))}
      />
      {amountOfRangesShown < currentPbxSolutionNumberRanges.length && (
        <ShowMoreButton onClick={() => setAmountOfRangesShown(amountOfRangesShown + 4)} />
      )}
    </div>
  );
};
