import * as CL from '@design-system/component-library';
import { t } from '../../common/i18n/index.js';

import './PriceChangeInfo.scss';

export const PriceChangeInfo = ({ isSubscriptionInfo }: { isSubscriptionInfo?: boolean }) => (
  <div className="of-order-summary-price-change-info">
    <span className="of-order-summary-price-change-info__icon">
      <CL.Icon icon="information" type="regular" color="black" />
    </span>
    {isSubscriptionInfo
      ? t.BVCK(
          'We will update the prices on September 1, 2024, in connection with the VAT change. The list price of subscriptions will increase by 5.4% from September 1st, 2024.'
        )
      : t.MSLU(
          'We will update the prices on September 1, 2024, in connection with the VAT change. The list price of services will increase by 5.4% from September 1st, 2024.'
        )}
  </div>
);
