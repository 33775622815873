import * as CL from '@design-system/component-library';
import { FaqAccordionItemContent } from '../FaqAccordion/FaqAccordionItemContent.js';
import { Picture } from '../Picture/Picture.js';
import { TurbonappiAlignedError } from './TurbonappiError.js';
import { TurbonappiBreadCrumbs } from './TurbonappiBreadCrumbs.js';
import { TurbonappiCards } from './TurbonappiCards.js';
import { pictureStorageBaseUrl } from '../Picture/contentfulImageProxy.js';
import {
  t,
  turbonappiFaqActivationQuestionHeader,
  turbonappiFaqAmountQuestionHeader,
  turbonappiFaqBarringQuestionHeader,
  turbonappiFaqBillingQuestionHeader,
  turbonappiFaqCannotQuestionHeader,
  turbonappiFaqNumberQuestionHeader,
  turbonappiFaqOrderQuestionHeader,
  turbonappiFaqPaymentQuestionHeader,
  turbonappiFaqServiceQuestionHeader,
  turbonappiFaqSpeedQuestionHeader,
  turbonappiFaqWrongNumberQuestionHeader,
  turbonappiMorePowerMsg,
} from '../../common/i18n/index.js';
import { useEffect } from 'react';
import { useTurbonappiContext } from './TurbonappiContextProvider.js';

import './TurbonappiFrontPage.scss';

const TurbonappiHeader = () => (
  <div className="of-turbonappi-front-page__headers">
    <div>
      <h1>{t.Y8GO(turbonappiMorePowerMsg)}</h1>
    </div>
    <div className="of-turbonappi-front-page__introduction">
      {t.P3I9(
        `Turbonappi brings additional speed to your mobile connection, allowing you to browse and work faster on a 4G or 5G network. You can easily activate Turbonappi online for a day, a week, or a month. The service is available for Elisa's 4G and 5G business subscriptions.`
      )}
    </div>
  </div>
);

const TurbonappiIntroduction = () => (
  <div className="of-turbonappi-front-page__information">
    <div className="of-turbonappi-front-page__information__item">
      <div className="of-turbonappi-front-page__icon--mobile-broadband" />
      <h3>{t.O2ZO('What is the Turbonappi service?')}</h3>
      <div>
        {t.V2TA(
          'Turbonappi is Elisa’s additional mobile subscription service that allows you to surf faster in your 4G and 5G networks when you need more speed.'
        )}
      </div>
    </div>
    <div className="of-turbonappi-front-page__information__item">
      <div className="of-turbonappi-front-page__icon--speed-meter" />
      <h3>{t.WDOQ('What do you get with Turbonappi?')}</h3>
      <div>
        {t.U92B(
          'Turbonappi makes remote work easier. It also allows you to make video calls to friends and binge-watch shows on Netflix without interruptions.'
        )}
      </div>
    </div>
    <div className="of-turbonappi-front-page__information__item">
      <div className="of-turbonappi-front-page__icon--shopping-bag" />
      <h3>{t.KDKZ('How do I order it?')}</h3>
      <div>
        {t.T13Y(
          'You can order Turbonappi easily from this page without logging in. Payments can be made with a credit card or MobilePay.'
        )}
      </div>
    </div>
  </div>
);

const TurbonappiServiceInfo = () => (
  <div className="of-turbonappi-front-page__service-info">
    <div>
      <h2>{t.TT99('How does the Turbonappi service work?')}</h2>
    </div>
    <div className="of-turbonappi-front-page__service-info__block">
      <div className="of-turbonappi-front-page__service-info__block__image">
        <Picture
          alt="service info"
          offerWidthAlternatives={[420, 690, 1080, 1280]}
          renderedImageSize={{
            onPhone: '100vw',
            onLaptop: '50vw',
          }}
          src={`${pictureStorageBaseUrl}7B2ZhAyL2bUOzdSTUQUWcF/elisa-yrityksille-turbonappi-kayttoon.jpg`}
        />
      </div>
      <div className="of-turbonappi-front-page__service-info__block__text-box">
        <div className="of-turbonappi-front-page__service-info__block__text-box__text">
          <h3 className="of-turbonappi-front-page__service-info__block__text-box__header">
            {t.ZSCY('How do I get access to the Turbonappi service?')}
          </h3>
          <p>
            {t.RKWS(
              'You can order Turbonappi easily on our website for a day, a week or a month. The service works on Elisa’s 4G and 5G business subscriptions.'
            )}
          </p>
          <p>
            {t.BN1U(
              'The additional speed of the mobile connection will be available right after you place your order, and we will text you when your plan is about to expire.'
            )}
          </p>
          <p>
            {t.FQ21(
              'You can choose the most suitable time period for your needs, and you will always get the best connection included in the package within the scope of your '
            )}
            <CL.Link linkHref="https://elisa.fi/kuuluvuus">{t.PRM4('coverage area')}</CL.Link>
            {t.X7T4(' and the terminal device you are using.')}
          </p>
        </div>
      </div>
    </div>
    <div className="of-turbonappi-front-page__service-info__block of-turbonappi-front-page__service-info__block__sortable">
      <div className="of-turbonappi-front-page__service-info__block__image">
        <Picture
          alt="payment"
          offerWidthAlternatives={[420, 690, 1080, 1280]}
          renderedImageSize={{
            onPhone: '100vw',
            onLaptop: '50vw',
          }}
          src={`${pictureStorageBaseUrl}0U00aOjnsxyvQg5plyiRP/elisa-yrityksille-turbonappi-maksaminen.jpg`}
        />
      </div>
      <div className="of-turbonappi-front-page__service-info__block__text-box">
        <div className="of-turbonappi-front-page__service-info__block__text-box__text">
          <h3 className="of-turbonappi-front-page__service-info__block__text-box__header">
            {t.LVOA('How do I pay for the service?')}
          </h3>
          <p>{t.NSXY('You can select your payment method after entering your phone number and email address.')}</p>
          <p>
            {t.QF8C(
              'You can order Turbonappi on our website. The charge for the service is shown on your credit card invoice or online bank payments according to the payment method you have chosen.'
            )}
          </p>
          <p>{t.BFH0('The payment methods we accept are credit card (Visa or Mastercard) and MobilePay.')}</p>
          <p>
            {t.FCOR('Employees can pay for the additional speed themselves, or the company can purchase it for them.')}
          </p>
        </div>
      </div>
    </div>
  </div>
);

const TurbonappiFAQ = () => (
  <section aria-label={t.URTR('Frequently asked questions about Turbonappi')}>
    <div className="of-turbonappi-front-page__accordion" itemScope itemType="https://schema.org/FAQPage">
      <h2 className="ds-h2">{t.UQUM('Turbonappi FAQ')}</h2>
      <CL.Accordion>
        <CL.AccordionItem heading={t.EWIN(turbonappiFaqServiceQuestionHeader)}>
          <FaqAccordionItemContent heading={t.EWIN(turbonappiFaqServiceQuestionHeader)}>
            <p>
              {t.FLQX(
                'Turbonappi is a turbo button for your internet connection speeds. You can add the service to an Elisa corporate subscription to boost your data speeds for a day, a week or a month. It also works on voice connections and e.g. payment terminals.'
              )}
            </p>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.AQF9(turbonappiFaqOrderQuestionHeader)}>
          <FaqAccordionItemContent heading={t.AQF9(turbonappiFaqOrderQuestionHeader)}>
            <p>
              {t.V3KW(
                'Ordering is easy. To place an order, you only need your phone number, email address and a payment card or MobilePay. There’s no need for a separate login on the ordering page.'
              )}
            </p>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.B3FX(turbonappiFaqPaymentQuestionHeader)}>
          <FaqAccordionItemContent heading={t.B3FX(turbonappiFaqPaymentQuestionHeader)}>
            <p>
              {t.QOV2(
                'You can pay for the service with a payment card or MobilePay directly from the website when you place your order. Your payment card must be a credit or debit card issued by a Finnish bank. If you use MobilePay to pay for your order, you will need to register for the service using Finnish banking credentials.'
              )}
            </p>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.LGYY(turbonappiFaqActivationQuestionHeader)}>
          <FaqAccordionItemContent heading={t.LGYY(turbonappiFaqActivationQuestionHeader)}>
            <p>
              {t.MNXZ(
                'Turbonappi activates immediately once the service has been paid for. You will receive an order confirmation by email.'
              )}
            </p>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.ZKLQ(turbonappiFaqAmountQuestionHeader)}>
          <FaqAccordionItemContent heading={t.ZKLQ(turbonappiFaqAmountQuestionHeader)}>
            <p>
              {t.UJ8I(
                'You can have one Turbonappi package (day, week or month) active at a time, and you can order more packages that will activate automatically when the current one ends. The packages can be of different durations. You can have a maximum of one Turbonappi package in the queue at a time.'
              )}
            </p>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.WJKM(turbonappiFaqNumberQuestionHeader)}>
          <FaqAccordionItemContent heading={t.WJKM(turbonappiFaqNumberQuestionHeader)}>
            <p>
              {t.SS57(
                'We send all messages from the number 18556. The Turbonappi subscriber receives a text message about the activation of the service, the end of the service one hour before and the end of the service at the end of the subscription period.'
              )}
            </p>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.YEHU(turbonappiFaqSpeedQuestionHeader)}>
          <FaqAccordionItemContent heading={t.YEHU(turbonappiFaqSpeedQuestionHeader)}>
            <p>
              {t.HZWN(
                'Turbonappi is affected by all of the normal things that can influence connection speeds, so data transfer speeds can vary depending on the device being used, the location and how busy the network is. You can try ordering the service again, or get in touch with our customer service team to report the problem.'
              )}
            </p>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.OQTL(turbonappiFaqBarringQuestionHeader)}>
          <FaqAccordionItemContent heading={t.OQTL(turbonappiFaqBarringQuestionHeader)}>
            <p>
              {t.ESVV(
                'No, they don’t. We’ve updated Turbonappi so that it can be ordered for all Elisa corporate subscriptions and for all subscriptions with a data connection, such as payment terminals.'
              )}
            </p>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.RBY7(turbonappiFaqCannotQuestionHeader)}>
          <FaqAccordionItemContent heading={t.RBY7(turbonappiFaqCannotQuestionHeader)}>
            <p>
              {t.A1Q4(
                'You can’t order Turbonappi if data transfer has been blocked for your subscription, if you do not have an Elisa corporate subscription, or if your subscription is with a different operator. Your subscription must support 4G and include data transfer. Turbonappi is not compatible with a 5G SA (Stand Alone) connections for the time being.'
              )}
            </p>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.HYBM(turbonappiFaqBillingQuestionHeader)}>
          <FaqAccordionItemContent heading={t.HYBM(turbonappiFaqBillingQuestionHeader)}>
            <p>
              {t.PAI8('The charge for the service appears in your payment card charges or as a MobilePay purchase.')}
            </p>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
        <CL.AccordionItem heading={t.XLY9(turbonappiFaqWrongNumberQuestionHeader)}>
          <FaqAccordionItemContent heading={t.XLY9(turbonappiFaqWrongNumberQuestionHeader)}>
            <p>
              {t.VYIS(
                'You need to make sure the details are correct when you place your order. Unfortunately, if you enter the wrong details when placing your order, it is not possible to issue a refund.'
              )}
            </p>
          </FaqAccordionItemContent>
        </CL.AccordionItem>
      </CL.Accordion>
    </div>
  </section>
);

const TurbonappiInit = () => {
  const { setTurbonappiData } = useTurbonappiContext();
  useEffect(() => {
    setTurbonappiData({});
  }, [setTurbonappiData]);
  return null;
};

const ExternalLinks = () => (
  <div className="of-turbonappi-front-page__link-box">
    <div className="of-turbonappi-front-page__link-box__elements ds-padding-left--4 ds-padding-right--4">
      <div className="of-turbonappi-front-page__link-box__elements__link-container ds-padding-bottom--4">
        {/* Use empty column so that the texts can be aligned to the left and show it in the middle in bigger screens*/}
        <div className="of-turbonappi-front-page__link-box__elements__link-container__column"></div>
        <div className="of-turbonappi-front-page__link-box__elements__link-container__column">
          <div>
            <h3>{t.J0MG('See also')}</h3>
          </div>
          <CL.Link linkHref="https://yrityksille.elisa.fi/yritysliittymat">
            {t.ARE0('Phone subscriptions for businesses')}
          </CL.Link>
          <CL.Link linkHref="https://yrityksille.elisa.fi/mobiililaajakaista">
            {t.WSO1('Mobile broadband subscriptions for businesses')}
          </CL.Link>
        </div>
      </div>
      <div className="of-turbonappi-front-page__link-box__elements__picture-container">
        <img
          className="of-turbonappi-front-page__link-box__elements__picture-container__image"
          alt=""
          src="https://static.elisa.com/v2/image/2tqybbhjs47b/7gNJQSWwgmYfw1BMaQVCBu/i.svg"
        />
      </div>
    </div>
  </div>
);

export const TurbonappiFrontPage = () => {
  const { turbonappiData } = useTurbonappiContext();

  if (turbonappiData?.failureMessage) {
    return <TurbonappiAlignedError validationFailureMessage={turbonappiData.failureMessage} />;
  } else {
    return (
      <div className="of-turbonappi-front-page">
        <TurbonappiInit />
        <TurbonappiBreadCrumbs />
        <TurbonappiHeader />
        <TurbonappiIntroduction />
        <TurbonappiCards />
        <TurbonappiServiceInfo />
        <TurbonappiFAQ />
        <ExternalLinks />
      </div>
    );
  }
};
