import * as CL from '@design-system/component-library';
import { BreadCrumbs } from '../BreadCrumbs/index.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { LinkableAccordion } from '../LinkableAccordion/index.js';
import { Loading } from '../Loading/index.js';
import { callDetailsPeriodMsg, omaElisaForEmployeeMsg, t, voiceSubscriptionsMsg } from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { generatePath } from 'react-router-dom';
import { getSubscriptionTextMessages, getSubscriptionVoiceCalls } from '../../selfservice/actions/index.js';
import { parseMobileSubscriptionAttrs } from '../EmployeeSubscriptionDetails/employeeSubscriptionUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useTitle } from '../../common/hooks/useTitle.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionId } from '../../common/constants/pathInterfaces.js';
import type { TextMessagesResponse, VoiceCallsResponse } from '../../generated/api/models.js';

import './UserCallDetails.scss';

interface UserCallDetailsContentProps {
  subscriptionId: string;
  voiceCalls?: VoiceCallsResponse;
  textMessages?: TextMessagesResponse;
}

const UserCallDetailsContent = ({ subscriptionId, voiceCalls, textMessages }: UserCallDetailsContentProps) => {
  const dispatch = useDispatch();
  return (
    <div>
      <LinkableAccordion
        onOpen={() => dispatch(getSubscriptionVoiceCalls(subscriptionId))}
        defaultOpen={false}
        heading={t.ORN1('Phone calls')}
        headingLevel="h3"
        id="local-call-details"
      >
        {voiceCalls ? (
          <div className="of-user-call-details__roaming">
            {voiceCalls.voiceCalls.length !== 0 ? (
              <>
                <CL.Description
                  id="local-call-details-bill-period"
                  className="ds-margin-horizontal--0 ds-margin-vertical--3"
                  items={[
                    {
                      title: t.OWG1(callDetailsPeriodMsg),
                      description: voiceCalls.billPeriod,
                    },
                  ]}
                />
                <CL.Description
                  className="ds-margin-horizontal--0 ds-margin-vertical--3"
                  items={voiceCalls.voiceCalls.map(callDetail => ({
                    title: callDetail.startTime,
                    description: (
                      <>
                        <div>{callDetail.calledNumber}</div>
                        <div>{callDetail.duration}</div>
                      </>
                    ),
                  }))}
                />
              </>
            ) : (
              <CL.Description
                id="local-call-details-no-details"
                className="ds-margin-horizontal--0 ds-margin-vertical--3"
                items={[{ title: t.DL3L('No data found!'), description: '' }]}
              />
            )}
          </div>
        ) : (
          <Loading />
        )}
      </LinkableAccordion>
      <LinkableAccordion
        onOpen={() => dispatch(getSubscriptionTextMessages(subscriptionId))}
        defaultOpen={false}
        heading={t.MXR5('Text Messages')}
        headingLevel="h3"
        id="local-text-messages-details"
      >
        {textMessages ? (
          <div className="of-user-call-details__roaming">
            {textMessages.textMessages.length !== 0 ? (
              <>
                <CL.Description
                  id="local-text-messages-details-bill-period"
                  className="ds-margin-horizontal--0 ds-margin-vertical--3"
                  items={[
                    {
                      title: t.OWG1(callDetailsPeriodMsg),
                      description: textMessages.billPeriod,
                    },
                  ]}
                />
                <CL.Description
                  className="ds-margin-horizontal--0 ds-margin-vertical--3"
                  items={textMessages.textMessages.map(textMessage => ({
                    title: textMessage.sentTime,
                    description: <div>{textMessage.recipientNumber}</div>,
                  }))}
                />
              </>
            ) : (
              <CL.Description
                id="local-text-messages-details-bill-period"
                className="ds-margin-horizontal--0 ds-margin-vertical--3"
                items={[{ title: t.DL3L('No data found!'), description: '' }]}
              />
            )}
          </div>
        ) : (
          <Loading />
        )}
      </LinkableAccordion>
    </div>
  );
};

export const UserCallDetails = ({ subscriptionId }: SubscriptionId) => {
  const subscriptions = useSelector((state: State) => state.user?.authenticated?.subscriptions?.voice, deepEqual);
  const subscription = subscriptions?.find(s => s.subscriptionId === subscriptionId, deepEqual);
  const mobileNumber = subscription ? parseMobileSubscriptionAttrs(subscription).mobileNumber : undefined;

  useTitle([
    t.H6JV(omaElisaForEmployeeMsg),
    t.AR0B(voiceSubscriptionsMsg),
    subscription?.subscriptionDisplayId || subscription?.subscriptionId || '',
    t.ORN1('Phone calls'),
  ]);

  return (
    <DetailsWrapper
      classes={['of-user-call-details']}
      content={
        <UserCallDetailsContent
          subscriptionId={subscriptionId}
          voiceCalls={subscription?.voiceCalls}
          textMessages={subscription?.textMessages}
        />
      }
      detailsTop={
        <BreadCrumbs
          breadCrumbPaths={
            mobileNumber
              ? [
                  { name: t.H6JV(omaElisaForEmployeeMsg), path: paths.EMPLOYEE_HOME },
                  { name: t.AR0B(voiceSubscriptionsMsg), path: paths.EMPLOYEE_SUBSCRIPTIONS },
                  { name: mobileNumber, path: generatePath(paths.EMPLOYEE_VOICE_SUBSCRIPTION, { subscriptionId }) },
                  { name: t.ORN1('Phone calls') },
                ]
              : [{ name: t.H6JV(omaElisaForEmployeeMsg) }]
          }
        />
      }
      id="user-call-details"
      heading={t.T4FT('Call details')}
      headingBottom={mobileNumber}
      heroPicto="sim"
    />
  );
};
