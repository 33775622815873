import * as CL from '@design-system/component-library';
import { AutoComplete } from '../AutoComplete/AutoComplete.js';
import { companySelectMsg, noSelectionMsg, t } from '../../common/i18n/index.js';
import type { ChangeEvent, KeyboardEvent, MouseEvent } from 'react';
import type { ConfigurationObject, ExternalInputRef } from '../AutoComplete/config.js';

export interface CompanySelectorProps {
  id?: string;
  autoCompleteConfig?: ConfigurationObject;
  userAccounts: CL.HeaderUserAccount[];
  onInputChange: (event: ChangeEvent | KeyboardEvent | MouseEvent, option: Partial<CL.HeaderUserAccount>) => void;
  inputRef?: ExternalInputRef;
  labelText?: string;
}

export const CompanySelector = (props: CompanySelectorProps) => {
  const { autoCompleteConfig, id, inputRef, userAccounts, onInputChange, labelText } = props;

  const ReadOnlyContent = ({ name }: { name: string }) => {
    return <p className="ds-color--blue-800">{name}</p>;
  };

  return (
    <>
      {labelText && (
        <div className="ds-margin-bottom--1">
          <CL.Label>{labelText}</CL.Label>
        </div>
      )}
      <div className="auto-complete-container">
        <AutoComplete<CL.HeaderUserAccount>
          id={id || 'companySelector'}
          config={{ ...autoCompleteConfig, isClearable: false }}
          getDisplayValue={({ name }) => name}
          getUniqueId={({ accountMasterId }) => accountMasterId || ''}
          inputRef={inputRef}
          noOptionsMsg={t.ZW5W(noSelectionMsg)}
          placeholder={t.P3C8(companySelectMsg)}
          onInputChange={onInputChange}
          options={userAccounts}
          defaultOption={userAccounts.find(({ active }) => active)}
          readOnlyContent={userAccounts.length === 1 ? ReadOnlyContent : undefined}
        />
      </div>
    </>
  );
};
