import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { DeliveryOrderDetailsAddons } from './DeliveryOrderDetailsAddons.js';
import { DeliveryOrderDetailsDelivery } from './DeliveryOrderDetailsDelivery.js';
import { DeliveryOrderDetailsGeneral } from './DeliveryOrderDetailsGeneral.js';
import { DeliveryOrderDetailsProduct } from './DeliveryOrderDetailsProduct.js';
import { DeliveryOrderDetailsUser } from './DeliveryOrderDetailsUser.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import type { BreadCrumbList } from '../BreadCrumbs/BreadCrumbs.js';
import type { CustomerOrder, DeliveryOrder } from '../../generated/api/models.js';

import './DeliveryOrderDetails.scss';

export interface DeliveryOrderDetailsAttrs {
  customerOrder: CustomerOrder;
  deliveryOrder: DeliveryOrder;
  firstBreadCrumbName?: string;
  isEmployee?: boolean;
}

export interface DeliveryOrderDetailsRoutes {
  onClickCustomerOrder: () => void;
  breadCrumbPaths?: BreadCrumbList;
}

export type DeliveryOrderDetailsProps = DeliveryOrderDetailsAttrs & DeliveryOrderDetailsRoutes;

export const DeliveryOrderDetails = ({
  customerOrder,
  deliveryOrder,
  breadCrumbPaths,
  onClickCustomerOrder,
  isEmployee = false,
}: DeliveryOrderDetailsProps) => {
  const { deliveryOrderDisplayId, deliveryOrderName, details, purposeOfUse, userContactName } = deliveryOrder;

  const breadCrumbs = breadCrumbPaths && <BreadCrumbsWithTitle breadCrumbPaths={breadCrumbPaths} />;
  const detailsSection = details ? (
    <>
      <DeliveryOrderDetailsGeneral
        customerOrder={customerOrder}
        deliveryOrder={deliveryOrder}
        details={details}
        onClickCustomerOrder={onClickCustomerOrder}
        isEmployee={isEmployee}
      />
      <DeliveryOrderDetailsProduct
        productType={deliveryOrder.productType}
        details={details}
        deliveryOrderName={deliveryOrderName}
      />
      <DeliveryOrderDetailsUser userContactName={userContactName} purposeOfUse={purposeOfUse} />
      <DeliveryOrderDetailsDelivery details={details} />
      <DeliveryOrderDetailsAddons details={details} />
    </>
  ) : null;
  return (
    <DetailsWrapper
      classes={['of-delivery-order-details']}
      content={<div className="of-delivery-order-details__content">{detailsSection}</div>}
      detailsTop={breadCrumbs}
      heading={deliveryOrderName}
      heroWhiteBackground={true}
      id={`delivery-order-${deliveryOrderDisplayId}`}
    />
  );
};
