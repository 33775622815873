import * as CL from '@design-system/component-library';
import {
  corporateCustomersMsg,
  emailMsg,
  employeeOmaElisaMsg,
  logInMsg,
  omaElisaClassicMsg,
  omaElisaForCompaniesMsg,
  privateCustomersMsg,
  shopMsg,
  t,
  viewAllMsg,
} from '../../../common/i18n/index.js';
import { paths } from '../../../common/constants/pathVariables.js';
import type * as CLT from '@design-system/component-library';
import type { NavigateFunction } from 'react-router-dom';

const loginLinks = (
  navigate: NavigateFunction
): Exclude<CLT.HeaderUserMenuProps['userMenuLinkAccordions'], undefined> => {
  return [
    {
      items: [
        {
          onClick: e => {
            e.preventDefault();
            navigate(paths.SELF_SERVICE_HOME);
          },
          icon: <CL.Icon icon="office" />,
          title: t.LOUS(omaElisaForCompaniesMsg),
          url: paths.SELF_SERVICE_HOME,
        },
        {
          onClick: e => {
            e.preventDefault();
            navigate(paths.EMPLOYEE_HOME);
          },
          icon: <CL.Icon icon="user" />,
          title: t.S0FN(employeeOmaElisaMsg),
          url: paths.EMPLOYEE_HOME,
        },
        {
          icon: <CL.Icon icon="external-link" />,
          title: t.SLBP(omaElisaClassicMsg),
          url: 'https://oma.elisa.fi/yrityksille',
        },
      ],
      onToggle: () => {},
      order: 1,
      title: t.HYV2(logInMsg),
      toggled: true,
    },
  ];
};

export const headerAccordions = (
  navigate: NavigateFunction,
  isLoggedIn: boolean,
  ringBaseUrl: string
): CLT.HeaderUserMenuProps['userMenuLinkAccordions'] => {
  const menuLinks = [
    {
      items: [
        {
          onClick: (e: React.SyntheticEvent) => {
            e.preventDefault();
            navigate(paths.WEB_SHOP);
          },
          icon: <CL.Icon icon="cart" />,
          title: t.BZYZ(shopMsg),
          url: paths.WEB_SHOP,
        },
        {
          icon: <CL.Icon icon="email" />,
          title: t.OKYY(emailMsg),
          url: 'https://webmail.elisa.fi',
        },
        {
          icon: <CL.Icon icon="phone" />,
          title: 'OmaRing',
          url: `${ringBaseUrl}/autologin`,
        },
        {
          icon: <CL.Icon icon="display" />,
          title: 'Elisa Fokus',
          url: 'https://fokus.elisa.fi',
        },
        {
          onClick: (e: React.SyntheticEvent) => {
            e.preventDefault();
            navigate('/yritys-palvelut');
          },
          icon: <CL.Icon icon="more" />,
          title: t.SF4C(viewAllMsg),
          url: '/yritys-palvelut',
        },
      ],
      onToggle: () => {},
      order: 1,
      title: t.JM7U(corporateCustomersMsg),
      toggled: true,
    },
    {
      items: [
        {
          icon: <CL.Icon icon="user" />,
          title: 'OmaElisa',
          url: 'https://verkkoasiointi.elisa.fi',
        },
        {
          icon: <CL.Icon icon="email-address" />,
          title: t.OKYY(emailMsg),
          url: 'https://webmail.elisa.fi',
        },
        {
          icon: <CL.Icon icon="display" />,
          title: 'Elisa Viihde',
          url: 'https://elisaviihde.fi',
        },
        {
          icon: <CL.Icon icon="cart" />,
          title: t.BZYZ(shopMsg),
          url: 'https://elisa.fi/kauppa',
        },
      ],
      onToggle: () => {},
      order: 1,
      title: t.IHET(privateCustomersMsg),
      toggled: false,
    },
  ];

  return isLoggedIn ? menuLinks : [...loginLinks(navigate), ...menuLinks];
};
