import { ActionBlock } from '../ActionBlock/ActionBlock.js';
import type { ActionGroupData } from '../cmsSchema.js';

import './ActionGroup.scss';

export const ActionGroup = ({ actionBlocks = [] }: ActionGroupData) => (
  <div className="ds-background-color--neutral-200--light">
    <nav className="ds-display--block ds-padding-vertical--2">
      <ul className="ds-grid__row ds-display--flex of-action-group">
        {actionBlocks.map((contentItem, i) => (
          <ActionBlock key={i} {...contentItem} />
        ))}
      </ul>
    </nav>
  </div>
);
