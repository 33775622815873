import * as CL from '@design-system/component-library';
import { t } from '../../common/i18n/index.js';
import type { ServiceLevel } from './ServiceLevelAndAddonsInterfaces.js';

interface ServiceLevelsDropdownProps {
  serviceLevels?: ServiceLevel[];
  setServiceLevels: (serviceLevels?: ServiceLevel[]) => void;
  editing?: boolean;
}

export const ServiceLevelsDropdown = ({
  serviceLevels,
  setServiceLevels,
  editing = true,
}: ServiceLevelsDropdownProps) => {
  const selectedServiceLevel = serviceLevels?.find(element => element.selected) || (serviceLevels && serviceLevels[0]);
  const selectionOfServiceLevels = serviceLevels?.map(element => element.name);
  const onChange = (selectedItem: string) =>
    setServiceLevels(
      serviceLevels?.map(element => ({
        ...element,
        selected: element.name === selectedItem,
      }))
    );

  return (
    <div>
      <label className="ds-padding-top--4">{t.SLOC('Service level')}</label>
      {!editing && selectedServiceLevel ? (
        <div>{selectedServiceLevel.name}</div>
      ) : (
        <CL.Grid>
          <CL.GridRow className="ds-margin--0">
            <CL.GridCol className="ds-padding--0" colWidthXS={4} colWidthS={3} colWidthM={2}>
              <CL.Dropdown
                items={selectionOfServiceLevels?.map(level => ({ label: level, value: level })) || []}
                label=""
                selectedValue={selectedServiceLevel?.name}
                onValueChange={el => {
                  onChange(el.innerText);
                }}
              />
            </CL.GridCol>
          </CL.GridRow>
        </CL.Grid>
      )}
    </div>
  );
};
