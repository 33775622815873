import * as CL from '@design-system/component-library';
import { Email } from '../../../common/react-hook-form/fields/index.js';
import { GridColumn } from './GridColumn.js';
import { emailMsg, t } from '../../../common/i18n/index.js';
import { useFormContext } from 'react-hook-form';

export interface EmailInputFieldsProps {
  additionalBillReceiverEmails?: string[];
  disabled?: boolean;
}

export const EmailInputFields = ({ additionalBillReceiverEmails, disabled }: EmailInputFieldsProps) => (
  <>
    {additionalBillReceiverEmails?.map((item, index) => (
      <GridColumn
        className="'ds-padding-top--3 of-billing-account-details form-column edit-field space_for_error'"
        key={`additionalemail${index + 1}`}
      >
        <Email
          required={false}
          disabled={disabled}
          name={`billingAccount.additionalBillReceiverEmails[${index}]`}
          label={`${t.XVHQ(emailMsg)} ${index + 1}`}
        />
      </GridColumn>
    ))}
  </>
);

export const AdditionalBillReceiversEdit = ({ disabled = false }: { disabled?: boolean }) => {
  const MAX_ADDITIONAL_BILL_RECEIVERS = 5;
  const { watch, setValue } = useFormContext();
  const billReceiverEmails = watch('billingAccount.additionalBillReceiverEmails');
  const addNewBillReceiverEmail = () => {
    const billReceivers = billReceiverEmails ? billReceiverEmails?.concat('') : [''];
    setValue('billingAccount.additionalBillReceiverEmails', billReceivers);
  };

  return (
    <>
      <CL.GridRow>
        <GridColumn className="of-billing-account-details form-column view-field ds-padding-top--3 ds-padding-bottom--1">
          <strong>{t.UZ3K('Other receiver')}</strong>
        </GridColumn>
      </CL.GridRow>
      <CL.GridRow>
        <EmailInputFields disabled={disabled} additionalBillReceiverEmails={billReceiverEmails} />
      </CL.GridRow>
      <CL.GridRow>
        <GridColumn className="of-billing-account-details form-column edit-field">
          <div>
            <CL.Button
              onClick={() => addNewBillReceiverEmail()}
              disabled={(billReceiverEmails?.length ?? 0) >= MAX_ADDITIONAL_BILL_RECEIVERS || disabled}
              color="light"
            >
              {t.N41Q('+ Add new')}
            </CL.Button>
          </div>
        </GridColumn>
      </CL.GridRow>
    </>
  );
};
