import { PurposeOfUseCostCenterAndEmployeeNumber } from './components/PurposeOfUseCostCenterAndEmployeeNumber.js';
import { PurposeOfUseSelector } from './components/PurposeOfUseSelector.js';
import { useFormContext } from 'react-hook-form';
import type { Contact } from '../../generated/api/models.js';

import './PurposeOfUse.scss';

export interface PurposeOfUseProps {
  fieldPrefix?: string;
  contacts?: Contact[];
  isEmailMandatory?: boolean;
  isPhoneNumberMandatory?: boolean;
  isCostCenterMandatory?: boolean;
  onChangeContact?: (
    selectedContactId: string,
    resetField: (name: string, options?: Record<string, boolean>) => void
  ) => void;
}

export const PurposeOfUse = ({
  contacts,
  fieldPrefix = '',
  isCostCenterMandatory,
  isEmailMandatory,
  isPhoneNumberMandatory,
  onChangeContact,
}: PurposeOfUseProps) => {
  const { resetField } = useFormContext();

  const onContactChange = (selectedContactId: string) => {
    if (onChangeContact) {
      onChangeContact(selectedContactId, resetField);
    }
  };

  return (
    <>
      <PurposeOfUseSelector
        contacts={contacts}
        fieldPrefix={fieldPrefix}
        isEmailMandatory={isEmailMandatory}
        isPhoneNumberMandatory={isPhoneNumberMandatory}
        onChangeContact={onContactChange}
      />
      <PurposeOfUseCostCenterAndEmployeeNumber
        contacts={contacts}
        fieldPrefix={fieldPrefix}
        isCostCenterMandatory={isCostCenterMandatory}
      />
    </>
  );
};
