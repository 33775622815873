import { BreadCrumbsWithTitle } from '../../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { ModelType } from '../../../../../../common/enums.js';
import { OrderSubscriptionSelectionWrapper } from './OrderSubscriptionSelectionWrapper.js';
import { deepEqual } from '../../../../../../common/utils/objectUtils.js';
import {
  newSubscriptionMsg,
  omaElisaForCompaniesMsg,
  t,
  voiceSubscriptionsMsg,
} from '../../../../../../common/i18n/index.js';
import { paths } from '../../../../../../common/constants/pathVariables.js';
import { useSelector } from 'react-redux';
import type { State } from '../../../../../../selfservice/common/store.js';

export const OrderVoiceSubSelectionPath = () => {
  const minuteOnlineModels = useSelector(
    (state: State) =>
      state?.selfservice?.onlineModels?.items?.filter(
        ({ onlineModelCode }) =>
          onlineModelCode === ModelType.VoicePerusliittyma4g || onlineModelCode === ModelType.VoiceYrityspuhe
      ) || [],
    deepEqual
  );

  return (
    <>
      <BreadCrumbsWithTitle
        breadCrumbPaths={[
          { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
          { name: t.AR0B(voiceSubscriptionsMsg), path: paths.PS_MOBILE_SUBSCRIPTIONS },
          { name: t.OK8J(newSubscriptionMsg) },
        ]}
      />
      <OrderSubscriptionSelectionWrapper onlineModelType={ModelType.VoiceSME} minuteOnlineModels={minuteOnlineModels} />
    </>
  );
};
