import { ToggleCheckbox } from '../ToggleCheckbox/ToggleCheckbox.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { t } from '../../common/i18n/index.js';
import type { AddOn, CampaignContext } from '../../generated/api/models.js';

interface FixedBroadbandInstallationServiceProps {
  addon: AddOn;
  onChange: (installationServiceSelected: boolean) => void;
  campaignContextsFromVoucher?: CampaignContext;
  campaignVoucher?: string;
  showRecommendedDisclaimer?: boolean;
}

const getPriceInfo = (addon: AddOn, campaignContextsFromVoucher?: CampaignContext) => {
  const price = addon.price?.listPrice.oneTimeCharge || 0;
  const voucherPrice = campaignContextsFromVoucher?.campaignAssociations[0].oneTimeCharge;
  const discountedPrice = addon.price?.effectivePrice.oneTimeCharge;

  return discountedPrice && discountedPrice < price
    ? {
        value: formatSum(voucherPrice ? discountedPrice - voucherPrice : discountedPrice),
        additionalText: formatSum(price),
      }
    : {
        value: formatSum(voucherPrice ? price - voucherPrice : price),
        additionalText: voucherPrice ? formatSum(price) : undefined,
      };
};

export const FixedBroadbandInstallationService = ({
  addon,
  onChange,
  campaignVoucher,
  campaignContextsFromVoucher,
  showRecommendedDisclaimer,
}: FixedBroadbandInstallationServiceProps) => {
  const basicInfo = t.P4CJ('Start using your broadband with ease.');
  const additionalInformation = showRecommendedDisclaimer
    ? `${basicInfo} ${t.V996(
        'Please note that if the connection is ordered elsewhere than home, the installation service is recommended.'
      )}`
    : basicInfo;

  return (
    <div className="of-fixed-bb-installation-service">
      <div className="of-fixed-bb-installation-service__content">
        <ToggleCheckbox
          title={t.LUCJ('Installation service')}
          additionalInformation={additionalInformation}
          onChange={onChange}
          badge={
            campaignVoucher && campaignContextsFromVoucher
              ? {
                  text: campaignVoucher,
                  color: 'warning',
                  type: 'text',
                }
              : undefined
          }
          priceInfo={getPriceInfo(addon, campaignContextsFromVoucher)}
        >
          <div className="ds-margin-top--3 ds-font-size--small">
            {t.TEVT('Service includes installation and testing.')}
          </div>
        </ToggleCheckbox>
      </div>
    </div>
  );
};
