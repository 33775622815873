import * as React from 'react';
import { ProductDetailsQuantity } from './ProductDetailsQuantity.js';
import { ToggleCheckbox } from '../../ToggleCheckbox/ToggleCheckbox.js';
import { deductibleMsg, squareTradeDeductibleMsg, t } from '../../../common/i18n/index.js';
import { formatSum } from '../../../common/utils/priceUtils.js';
import { isSquareTradeAddOn } from '../../../common/utils/addOnUtils.js';
import { valueOrZero } from '../utils/productDetailsUtils.js';
import type { AddOn, AddOnPurpose } from '../../../generated/api/models.js';

import './ProductDetailsAddOn.scss';

export interface ProductDetailsAddOnStateProperties {
  addOn: AddOn;
  display: boolean;
  addOnPurpose: AddOnPurpose;
  addOnAssociationCode: string;
  addOnAssociationId: string;
  isChecked: boolean;
  isDisabled: boolean;
  quantity: number;
}

export interface ProductDetailsAddOnProperties {
  addOns: ProductDetailsAddOnStateProperties[];
  setStatus: React.Dispatch<React.SetStateAction<ProductDetailsAddOnStateProperties[]>>;
  productQuantity: number;
}

export interface ProductDetailsAddOnItemProperties extends ProductDetailsAddOnProperties {
  addOnState: ProductDetailsAddOnStateProperties;
}

const onAddonStateChangeHandler = (
  { addOnState, setStatus, addOns }: ProductDetailsAddOnItemProperties,
  quantity?: number
) => {
  setStatus(
    [...addOns].map(item => {
      if (item.addOn.addOnProductName === addOnState.addOn.addOnProductName) {
        if (quantity) {
          item.quantity = quantity;
        } else {
          item.isChecked = !item.isChecked;
        }
      }
      return item;
    })
  );
};

const renderAddOnDescription = (addOn: AddOn) => {
  if (isSquareTradeAddOn(addOn)) {
    return (
      <div className="of-product-details-addon-description">
        <p>
          {t.WW2M(
            'Secure your device for accident - on accidental incident you will get new equivalent replacement device with one phone call.'
          )}
          <a
            target="_blank"
            href="https://elisa.fi/asiakaspalvelu/aihe/matkapuhelinliittymat/ohje/square-trade/"
            rel="noreferrer"
          >
            {` ${t.BQ9Y('More details')}.`}
          </a>
        </p>
      </div>
    );
  }

  return null;
};

const getPriceInfo = (addOn: AddOn, productQuantity: number) => {
  return {
    value: (
      <span>
        {formatSum(valueOrZero(addOn.addOnMonthlyRecurringCharge) * productQuantity, true)}
        {`€/${t.XXVX('month')}`}
      </span>
    ),
    additionalText: isSquareTradeAddOn(addOn) ? (
      <div className="ds-text--xs">
        {`${t.VWSV(deductibleMsg)} ${squareTradeDeductibleMsg}. ${t.XJMC('No fixed term.')}`}
      </div>
    ) : undefined,
  };
};

const ProductDetailsAddOnItem = (addOnItem: ProductDetailsAddOnItemProperties) => {
  const {
    addOnState: { isChecked, isDisabled, addOn },
    productQuantity,
  } = addOnItem;

  const quantityChangeHandler = (quantity: number) => {
    onAddonStateChangeHandler(addOnItem, quantity);
  };

  return (
    <ToggleCheckbox
      disabled={isDisabled}
      initialValue={isChecked}
      onChange={() => onAddonStateChangeHandler(addOnItem)}
      title={addOn.addOnProductName}
      priceInfo={getPriceInfo(addOn, productQuantity)}
    >
      <>
        {renderAddOnDescription(addOn)}
        {!isDisabled && (
          <ProductDetailsQuantity quantity={productQuantity} setQuantity={quantityChangeHandler} disabled={true} />
        )}
      </>
    </ToggleCheckbox>
  );
};

export const ProductDetailsAddOn = ({ addOns, ...rest }: ProductDetailsAddOnProperties) => {
  return (
    <>
      {addOns.map(item => (
        <ProductDetailsAddOnItem key={item.addOn.addOnCode} addOnState={item} addOns={addOns} {...rest} />
      ))}
    </>
  );
};
