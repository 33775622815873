import { HeaderSectionContent, SectionContent } from '../SectionContent.js';
import { type SearchResultBaseProps, SearchResultRow } from '../SearchResultRow.js';
import { costCenterMsg, referenceIdMsg, t } from '../../../common/i18n/index.js';
import { generatePath } from 'react-router-dom';
import { getResultObjectWithHighlightedValues } from '../highlightFunctions.js';
import { paths } from '../../../common/constants/pathVariables.js';
import type { ContactHeader } from '../../../generated/api/contactHeader.js';

export interface ContactProps extends SearchResultBaseProps {
  contact: ContactHeader;
}

export const Contact = ({ contact, matchedFields, searchTerms, ...rest }: ContactProps) => {
  const { firstName, lastName, email, phoneNumber, costCenter, referenceOrAdditionalInformation } =
    getResultObjectWithHighlightedValues(
      // @ts-ignore
      contact,
      matchedFields,
      searchTerms
    );
  return (
    <SearchResultRow
      icon="user"
      upperSection={
        <HeaderSectionContent
          left={
            <>
              {firstName ?? ''} {lastName ?? ''}
            </>
          }
          right={email}
        />
      }
      lowerSection={
        <SectionContent
          left={phoneNumber ?? ''}
          right={
            costCenter && (
              <>
                {t.L2OG(costCenterMsg)}: {costCenter}
              </>
            )
          }
        />
      }
      additionalHighlightableFields={[
        {
          title: t.CWR5(referenceIdMsg),
          value: referenceOrAdditionalInformation,
          key: 'referenceOrAdditionalInformation',
        },
      ]}
      matchedFields={matchedFields}
      linkHref={generatePath(paths.COMPANY_INFO_CONTACT, { contactMasterId: contact.contactMasterId || '' })}
      {...rest}
    />
  );
};
