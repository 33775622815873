import * as CL from '@design-system/component-library';
import { AddOrSelectBillingAccounts } from '../../AddOrSelectBillingAccountsV2/AddOrSelectBillingAccounts.js';
import { CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE } from '../../../common/utils/billingAccountUtils.js';
import { CardPaymentDisabledMessages } from '../../DeviceCheckout/CardPaymentDisabledMessages.js';
import { Loading } from '../../Loading/index.js';
import { OrderSummary, OrderSummaryType } from '../../OrderSummary/OrderSummary.js';
import { PaidAmountSummary } from '../../PaidAmountSummary/PaidAmountSummary.js';
import { PaymentMethod, SelectPaymentMethod } from '../../SelectPaymentMethod/SelectPaymentMethod.js';
import { debitOrCreditCardMsg, submitOrderMsg, t } from '../../../common/i18n/index.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { getConfiguredCommercialProducts } from '../../../common/utils/cartProductUtils.js';
import {
  getPriceMetaDataForCardPayment,
  isCardPaymentAllowed,
  isCategoryValidForCardPayment,
  isStockValidForCardPayment,
} from '../../DeviceCheckout/deviceCheckoutCardPaymentUtils.js';
import { loadBillChannels, updateSelectedBaId } from '../../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useLayoutEffect, useState } from 'react';
import type { BillingAccountOrErrorSupplier } from '../../../common/types/errors.js';
import type { CheckoutStepItem } from '../CheckoutSteps.js';
import type { State } from '../../../selfservice/common/store.js';

import './KeyUserBillingStep.scss';

interface KeyUserBillingProps {
  setNewBillingAccountSaveValuesToCheckoutState: (prepareSaveValues?: BillingAccountOrErrorSupplier) => void;
  onSubmit: (selectedPaymentMethod: PaymentMethod) => void;
  replacedSubscriptionBillingAccountId?: string;
  deviceChangeSubscriptionContactId?: string;
}

export const KeyUserBillingStep = ({
  setNewBillingAccountSaveValuesToCheckoutState,
  onSubmit,
  replacedSubscriptionBillingAccountId,
  deviceChangeSubscriptionContactId,
}: KeyUserBillingProps) => {
  const dispatch = useDispatch();
  const { onlineModels, disableSubmit, deliveryDetails, cartItems, contacts, billingAccounts, billChannels } =
    useSelector(
      (state: State) => ({
        onlineModels: state.selfservice?.onlineModels || undefined,
        disableSubmit: Boolean(state.selfservice?.onlineOrders?.saving),
        deliveryDetails: state.deviceCheckout?.deliveryDetails,
        cartItems: state.deviceCheckout?.cartItems || [],
        contacts: state.selfservice?.contacts || undefined,
        billingAccounts: state.selfservice?.billingAccounts || undefined,
        billChannels: state.selfservice?.billChannels || undefined,
      }),
      deepEqual
    );

  const [billingAccountSelected, setBillingAccountSelected] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(PaymentMethod.BILLING_ACCOUNT);

  const cartItemsCommercialProducts = getConfiguredCommercialProducts(cartItems);

  useLayoutEffect(() => {
    dispatch(loadBillChannels());
  }, [dispatch]);

  const addOrSelectBAComponent = billingAccounts?.searchResults ? (
    <AddOrSelectBillingAccounts
      addNewBA={true}
      getBillingAccountSaveValuesFn={selectedBaData => {
        const { selectedBaId, prepareNewBillingAccountSaveValues } = selectedBaData();
        if (selectedBaId) {
          dispatch(updateSelectedBaId(selectedBaId));
          if (selectedBaId !== CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE) {
            setNewBillingAccountSaveValuesToCheckoutState(undefined);
          }
        } else if (prepareNewBillingAccountSaveValues) {
          setNewBillingAccountSaveValuesToCheckoutState(prepareNewBillingAccountSaveValues);
        }
        setBillingAccountSelected(true);
      }}
      isSaving={false}
      selectedBaId={replacedSubscriptionBillingAccountId}
      disabled={Boolean(replacedSubscriptionBillingAccountId || deviceChangeSubscriptionContactId)}
    />
  ) : (
    <Loading />
  );

  const categoryValidForCardPayment = isCategoryValidForCardPayment(cartItems);
  if (categoryValidForCardPayment && onlineModels?.loading) {
    return <Loading />;
  }
  const stockValidForCardPayment = isStockValidForCardPayment(cartItems, onlineModels);
  const cardPaymentAllowed = isCardPaymentAllowed(cartItems, onlineModels);

  const { isPriceTypeValidForCardPayment, totalAmountToPayByCard } = getPriceMetaDataForCardPayment(
    cartItemsCommercialProducts,
    deliveryDetails?.deliveryPrice ?? 0
  );

  if (
    (!categoryValidForCardPayment || !stockValidForCardPayment || !isPriceTypeValidForCardPayment) &&
    selectedPaymentMethod === PaymentMethod.CARD
  ) {
    setSelectedPaymentMethod(PaymentMethod.BILLING_ACCOUNT);
  }

  const content = (
    <>
      <SelectPaymentMethod
        paymentMethod={selectedPaymentMethod}
        onUpdatePaymentMethod={setSelectedPaymentMethod}
        isEligibleForCardPayment={
          categoryValidForCardPayment &&
          isPriceTypeValidForCardPayment &&
          stockValidForCardPayment &&
          cardPaymentAllowed
        }
      />
      <CardPaymentDisabledMessages
        isCategoryValid={categoryValidForCardPayment}
        isPriceTypeValid={isPriceTypeValidForCardPayment}
        isStockValid={stockValidForCardPayment}
        cardPaymentAllowed={cardPaymentAllowed}
      />
      {selectedPaymentMethod === PaymentMethod.BILLING_ACCOUNT && addOrSelectBAComponent}
      {selectedPaymentMethod === PaymentMethod.CARD && (
        <div className="of-device-checkout--card-payment" id="card-payment-information">
          <h3>{t.UKMC(debitOrCreditCardMsg)}</h3>
          <p className="ds-text--s ds-color--neutral-600">{t.XKDW('Eligible payment cards')}:</p>
          <img alt="Visa." height="50" width="78" src="https://kauppa.elisa.fi/kassa/images/checkout/icon_visa.svg" />
          <img
            alt="Mastercard."
            height="50"
            width="78"
            src="https://kauppa.elisa.fi/kassa/images/checkout/icon_mastercard.svg"
          />
          <OrderSummary
            classes={['ds-margin-top--5', 'ds-margin-bottom--3']}
            priceIncludesVAT={false}
            summaryType={OrderSummaryType.DETAILS_CLOSED}
            commercialProducts={cartItemsCommercialProducts}
            deliveryCharges={deliveryDetails?.deliveryPrice}
          />
          <PaidAmountSummary amount={totalAmountToPayByCard} summaryText={t.SWA5('Total payable (incl. VAT)')} />
        </div>
      )}
    </>
  );

  if (!billingAccounts || !billChannels || !contacts) {
    return <Loading />;
  }

  const preventSubmit =
    (selectedPaymentMethod === PaymentMethod.BILLING_ACCOUNT && !billingAccountSelected) ||
    !billingAccounts ||
    disableSubmit;

  return (
    <>
      {content}
      <CL.Button
        size="l"
        className="of-confirm-button"
        onClick={() => {
          onSubmit(selectedPaymentMethod);
        }}
        disabled={preventSubmit}
      >
        {selectedPaymentMethod === PaymentMethod.CARD ? t.BZAA('Confirm and pay') : t.RZU4(submitOrderMsg)}
      </CL.Button>
    </>
  );
};

export const keyUserBillingStep = (props: KeyUserBillingProps): CheckoutStepItem => ({
  id: 'keyUserBillingStep',
  content: <KeyUserBillingStep {...props} />,
  name: t.CBBN('Choose payment method'),
});
