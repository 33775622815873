import { AutoComplete } from '../AutoComplete/AutoComplete.js';
import { type ChangeEvent, type KeyboardEvent, type MouseEvent } from 'react';
import { companySelectMsg, noSelectionMsg, t } from '../../common/i18n/index.js';
import { setActiveAccount } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type * as CLT from '@design-system/component-library';

import './SelectCompany.scss';

export interface SelectCompanyProps {
  userAccounts: CLT.HeaderUserAccount[];
}

export const SelectCompany = ({ userAccounts }: SelectCompanyProps) => {
  const dispatch = useDispatch();

  return userAccounts.length > 1 ? (
    <div className="ds-background-color--neutral-200 ds-padding-vertical--5">
      <div className="of-shopping-cart-select-company-wrapper">
        <AutoComplete<CLT.HeaderUserAccount>
          className="of-shopping-cart-select-company"
          config={{ isClearable: false }}
          getDisplayValue={({ name }) => name}
          getUniqueId={({ accountMasterId }) => accountMasterId || ''}
          label={t.YETE('Select the company you want to place an order with')}
          id="shoppingCartCompanySelector"
          noOptionsMsg={t.ZW5W(noSelectionMsg)}
          placeholder={t.P3C8(companySelectMsg)}
          onInputChange={(_e: ChangeEvent | KeyboardEvent | MouseEvent, option: Partial<CLT.HeaderUserAccount>) => {
            if (option?.accountMasterId) {
              dispatch(setActiveAccount(option.accountMasterId, true));
            }
          }}
          options={userAccounts}
          defaultOption={userAccounts.find(({ active }) => active)}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};
