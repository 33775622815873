import * as CL from '@design-system/component-library';
import { t } from '../../common/i18n/index.js';

import './AddOnExternalLink.scss';

export const AddOnExternalLink = ({ linkHref }: { linkHref: string }) => (
  <CL.Link className="ds-margin-top--1 of-add-on-external-link" linkHref={linkHref} linkTarget="_blank">
    {`${t.ZOES('Read more about services')} `}
    <CL.Icon icon="external-link" className="ds-display--inline-block ds-margin-left--2" />
  </CL.Link>
);
