import * as CL from '@design-system/component-library';
import { CatalogModificationStatus } from '../../common/utils/catalogUtils.js';
import { draftMsg, modifiedMsg, publishedMsg, t } from '../../common/i18n/index.js';

import './ModificationStatus.scss';

interface ModificationStatusProps {
  status: CatalogModificationStatus;
  noSubtext?: boolean;
}

type StatusMap = {
  [c in CatalogModificationStatus]: {
    color: CL.BadgeColor;
    text: string;
    subtext?: string;
  };
};

const getStatusRepresentations = (): StatusMap => {
  return {
    [CatalogModificationStatus.MODIFIED]: {
      color: 'orange-light',
      text: t.RUFL(modifiedMsg),
      subtext: t.BQMC('Unpublished edits'),
    },
    [CatalogModificationStatus.ACTIVE]: {
      color: 'success',
      text: t.JN25(publishedMsg),
    },
    [CatalogModificationStatus.DRAFT]: {
      color: 'secondary',
      text: t.ZJQG(draftMsg),
      subtext: t.L8A5('Unpublished'),
    },
  };
};

export const ModificationStatus = (props: ModificationStatusProps) => {
  const { status, noSubtext } = props;
  const { color, text, subtext } = getStatusRepresentations()[status];

  return (
    <div className="of-modification-status ds-display--flex ds-align-items--center">
      <CL.Badge type="status" color={color} className={`of-modification-status-badge-${color}`} />
      <div>
        <div>{text}</div>
        {!noSubtext && <div className="ds-font-size--smaller">{subtext}</div>}
      </div>
    </div>
  );
};
