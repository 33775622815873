import { CommonErrorType } from '../enums.js';
import { type ContactMasterId } from '../constants/pathInterfaces.js';
import { convertToCommonErrors } from '../utils/errorUtils.js';
import { fetchContact } from '../fetch.js';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { CommonError } from '../types/errors.js';
import type { Contact } from '../../generated/api/contact.js';
import type { ContactsResponse } from '../../generated/api/contactsResponse.js';

export const useContact = () => {
  const [errors, setErrors] = useState<CommonError[]>([]);
  const [contact, setContact] = useState<Contact>();
  const [loading, setLoading] = useState<boolean>(false);

  const { contactMasterId } = useParams<ContactMasterId>();

  useEffect(() => {
    setLoading(true);

    fetchContact({
      contactMasterId: contactMasterId!,
    })
      .then((contactsResponse: ContactsResponse) => {
        if (contactsResponse?.contacts?.length === 1) {
          setContact(contactsResponse.contacts[0]);
        } else {
          setErrors([{ type: CommonErrorType.NOT_FOUND, message: 'Contact not found' }]);
        }
      })
      .catch((e: Error) => {
        setErrors(convertToCommonErrors(e.message));
      })
      .finally(() => setLoading(false));
  }, [contactMasterId]);

  return { contact, loading, errors };
};
