import * as CL from '@design-system/component-library';
import { PRODUCT_DETAILS_CLASS } from '../productDetailsConstants.js';
import { quantityMsg, t } from '../../../common/i18n/index.js';

export interface ProductDetailsQuantityProps {
  quantity: number;
  setQuantity: (amount: number) => void;
  maxValue?: number;
  disabled?: boolean;
}

export const ProductDetailsQuantity = (props: ProductDetailsQuantityProps) => {
  const { quantity, setQuantity, maxValue, disabled = false } = props;

  return (
    <div className={`${PRODUCT_DETAILS_CLASS}-quantity ds-margin-bottom--3`}>
      <div className={`${PRODUCT_DETAILS_CLASS}-quantity__amount`}>
        <label className="ds-color--blue-800 ds-text--s ds-font-weight--bold ds-display--block ds-margin-bottom--1">
          {t.M0W7(quantityMsg)}
        </label>
        <CL.Quantity
          minValue={1}
          maxValue={maxValue || undefined}
          currentValue={quantity}
          onChange={(q: number) => setQuantity(q)}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
