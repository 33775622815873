import { TypeKeys } from '../actions/index.js';
import { callUiApi } from '../common/uiApiUtils.js';
import { combineEpics } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import { startMfPrivateMethod } from '../../generated/api/uiApiMethods.js';
import { startMobileFixerFailed, startMobileFixerFulfilled } from '../actions/mobileFixerActions.js';
import type { Action } from 'redux';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { EpicDependencies } from './epicUtils.js';
import type { SelfServiceActionTypes } from '../actions/index.js';
import type { StartMobileFixerAction } from '../actions/mobileFixerActions.js';
import type { State } from '../common/store.js';

const startMobileFixerEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  action$.ofType(TypeKeys.START_MOBILE_FIXER).pipe(
    concatMap(({ subscriptionId, locale, portal }: StartMobileFixerAction) => {
      return callUiApi({
        epicDependencies,
        state$,
        method: startMfPrivateMethod(subscriptionId, locale, portal),
        successAction: startMobileFixerFulfilled,
        failureAction: startMobileFixerFailed,
      });
    })
  );

export const mobileFixerEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> =
  combineEpics(startMobileFixerEpic);
