import { Input } from '../../../components/Input/Input.js';
import { useController, useFormContext } from 'react-hook-form';

export interface ListInputProps {
  name: string;
  label: string;
}

export const ListInput = ({ label, name }: ListInputProps) => {
  const { control, watch, setValue } = useFormContext();
  const values = watch();
  const { field } = useController({ name, control });
  const keys = name.split('.');
  const list = keys.reduce((current, key) => (current ? current[key] : undefined), values);

  return list.map((value: string, i: number) => {
    return (
      <Input
        key={`${name}${i}`}
        name={`${name}${i}`}
        label={`${label} ${i + 1}`}
        type="text"
        disabled={false}
        required={false}
        onBlur={field.onBlur}
        onChange={event => {
          setValue(name, [...list.slice(0, i), event.target.value, ...list.slice(i + 1)]);
        }}
        value={value}
      />
    );
  });
};
