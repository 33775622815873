import * as CL from '@design-system/component-library';
import * as React from 'react';
import { Field } from 'formik';
import { InlineErrorComponent, RadioComponent } from '../FieldComponents/index.js';
import type { FormikHelpers, FormikState, FormikValues } from 'formik';

export interface ProvidedRadioGroupProps {
  className?: string;
  id?: string;
  name: string;
  withError?: boolean;
  value?: string;
  radioButtons: {
    children?: JSX.Element;
    disabled?: boolean;
    label?: string;
    onChange?: (
      e: React.ChangeEvent<HTMLInputElement>,
      form: FormikState<FormikValues> & FormikHelpers<FormikValues>
    ) => void;
    value: string;
  }[];
}

/**
 * Provided Radio button requires a list of radioButtons
 * Underlying RadioComponent always requires single entity
 * Supports inline error for presenting message after last radio button
 */
export const ProvidedRadio = (props: ProvidedRadioGroupProps) => {
  const { className, id, name, radioButtons, value, withError } = props;

  // TODO: leo 17.9.2023 - using `value` as `key` to force the CL component to remount, it doesn't support changing
  //       values. Change to better CL component once such is available.
  return (
    <CL.Radio.Group key={value} value={value}>
      {radioButtons.map((radioButton, i) => (
        <div key={i}>
          <Field
            {...radioButton}
            type="radio"
            id={id ? `${id}${i}` : `${name}${i}`}
            name={name}
            component={RadioComponent}
            className={className}
          >
            {radioButton.children}
            {withError && radioButtons.length === i + 1 && (
              <Field {...props} name={name} component={InlineErrorComponent} />
            )}
          </Field>
        </div>
      ))}
    </CL.Radio.Group>
  );
};
