import { VirtualCatalogSummary } from '../../../../../components/CatalogSummary/VirtualCatalogSummary.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { onUpsertVirtualCatalog } from '../../../../../common/utils/dispatcherUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useAuth } from '../../../AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { CatalogParams } from '../../../../../components/CatalogConfiguration/CatalogConfiguration.js';
import type { OnlineModelCategory } from '../../../../../generated/api/models.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const CompanyInfoCatalogListInfoPath = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const billChannels = useSelector((state: State) => state.selfservice?.billChannels, deepEqual);
  const billingAccounts = useSelector((state: State) => state.selfservice?.billingAccounts, deepEqual);
  const virtualCatalogs = useSelector((state: State) => state.selfservice?.virtualCatalogs, deepEqual);
  const contacts = useSelector((state: State) => state.selfservice?.contacts, deepEqual);
  const { authenticatedUser } = useAuth();
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  const params: CatalogParams = location.state;

  useEffect(() => {
    if (!location.state) {
      navigate(paths.COMPANY_INFO_CATALOGS, { replace: true });
    }
  }, [location.state, navigate]);

  if (!params) {
    return null;
  }

  return (
    <VirtualCatalogSummary
      catalog={params.catalog}
      onBackClick={() => {
        navigate(paths.COMPANY_INFO_CATALOG_LIST_EDIT, { state: location.state });
      }}
      onSubmit={(publishCatalog, selectedBaId, newBillingAccount, newBillingAccountValidationErrors) => {
        const selectedProducts: Array<string> = [];
        const selectedProductCategories: Array<string> = [];
        Object.entries(params.products!).forEach(([key, value]) => {
          value.forEach(item => selectedProducts.push(item.code));
          selectedProductCategories.push(key);
        });
        onUpsertVirtualCatalog(dispatch)(
          {
            ...params.catalog,
            products: selectedProducts,
            productCategories: selectedProductCategories as Array<OnlineModelCategory>,
            billingAccountId: selectedBaId,
          },
          publishCatalog,
          params.virtualCatalog,
          newBillingAccount,
          newBillingAccountValidationErrors
        );
      }}
      products={params.products!}
      billingAccounts={billingAccounts || undefined}
      inProgressAction={virtualCatalogs?.inProgressAction}
      billChannels={billChannels?.items}
      billingAccountsEditingChanges={billingAccounts?.editingChanges}
      billingAccountsErrors={billingAccounts?.errors}
      companyInfo={companyInfo ?? undefined}
      contacts={contacts?.items}
      companyName={authenticatedUser?.companyName || ''}
    />
  );
};
