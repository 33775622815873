import * as CL from '@design-system/component-library';
import type { ReactNode } from 'react';

import './DeviceCheckoutListingItem.scss';

type DeviceCheckoutListingItemProps = {
  disclaimer?: ReactNode;
  id?: string;
  image: ReactNode;
  description: ReactNode;
  quantity?: ReactNode;
  price: ReactNode;
};

// TODO refactor DeviceCheckoutCartProduct to use this (needs addon support first though...)
/**
 * Used to make sure we have the same layout for products and delivery details.
 */
export const DeviceCheckoutListingItem = ({
  disclaimer,
  id,
  image,
  description,
  quantity,
  price,
}: DeviceCheckoutListingItemProps) => (
  <CL.GridRow className="of-device-checkout-listing-item" id={id} justifyCenter>
    <CL.GridCol className="ds-grid__col" colWidth={8}>
      <div className="of-device-checkout-listing-item__image">{image}</div>
      <div className="of-device-checkout-listing-item__text">
        <div className="of-device-checkout-listing-item__description">{description}</div>
        {quantity != null && <div className="of-device-checkout-listing-item__quantity">{quantity}</div>}
        <div className="of-device-checkout-listing-item__price">{price}</div>
        {disclaimer != null && <div className="of-device-checkout-listing-item__disclaimer">{disclaimer}</div>}
      </div>
    </CL.GridCol>
  </CL.GridRow>
);
