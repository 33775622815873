import { formatNumber } from '../../common/i18n/index.js';

export const VAT_AS_DECIMAL = 0.24;
export const VAT_MULTIPLIER = VAT_AS_DECIMAL + 1;
export const VAT_AS_NUMBER = VAT_AS_DECIMAL * 100;
export const getVatAsString = () => formatNumber(VAT_AS_NUMBER);
// price is in cents and with current vat rate
export const getPriceWithTax = (price: number) => Math.round(price * VAT_MULTIPLIER);
// price is in cents and with current vat rate
export const getTaxAmount = (price: number) => Math.round(price * VAT_AS_DECIMAL);
// price is in cents and with current vat rate
export const getTaxAmountFromPriceWithTax = (price: number) => Math.round(price - price / VAT_MULTIPLIER);
