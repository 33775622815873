import { EmployeeDeviceStoreProducts } from '../../../../../components/EmployeeDeviceStoreProducts/EmployeeDeviceStoreProducts.js';
import {
  getCustomerLevelDiscountedPrices,
  loadOnlineModels,
  loadVirtualCatalogs,
} from '../../../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import type { CategoryProps } from '../../../../../common/types/category.js';

export const EmployeeStoreProducts = ({ category }: CategoryProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadVirtualCatalogs());
    dispatch(getCustomerLevelDiscountedPrices());
    dispatch(loadOnlineModels(category.code, true));
  }, [category.code, dispatch]);
  return <EmployeeDeviceStoreProducts category={category} />;
};
