import * as CL from '@design-system/component-library';
import type { Question } from './types.js';

type QuestionComponentProps = {
  initialValue?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & Question;

export const QuestionComponent = ({ question, answers, initialValue = '', onChange }: QuestionComponentProps) => {
  return (
    <section>
      <h2 className="ds-text--lead">{question}</h2>
      <CL.Radio.Group name={question} value={initialValue} onChange={onChange}>
        {answers.map((item, index) => (
          <CL.Radio key={item} value={String(index)} label={item}></CL.Radio>
        ))}
      </CL.Radio.Group>
    </section>
  );
};
