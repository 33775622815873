import { Anchor } from '../Anchor/Anchor.js';
import { ContactMethod } from '../../common/react-hook-form/fields/ContactMethod.js';
import { EMAIL_REGEX } from '../../common/utils/emailUtils.js';
import { FINNISH_PHONE_NUMBER_REGEX } from '../../common/utils/phoneNumberUtils.js';
import { ModalCloseAndSubmitButtons } from '../Modal/ModalCloseAndSubmitButtons.js';
import { TextInput } from '../../common/react-hook-form/fields/index.js';
import { continueMsg, t, userNameMsg } from '../../common/i18n/index.js';
import { sendOtpToUser, updateForgotPasswordStepNumber } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { ForgetPasswordWithForm } from './misc/forgotPasswordInterfaces.js';

export const ForgotPasswordEnterContactDetailsStep = (props: ForgetPasswordWithForm) => {
  const dispatch = useDispatch();
  const { methods } = props;

  const getMethod = (contactMethod: string) => {
    if (EMAIL_REGEX.test(contactMethod)) {
      return 'email';
    }
    if (FINNISH_PHONE_NUMBER_REGEX.test(contactMethod)) {
      return 'mobile';
    }
    throw new Error('Unknown method that should be either email or mobile');
  };

  const onSubmit = ({ contactMethod, userName }: { contactMethod: string; userName: string }) => {
    const method = getMethod(contactMethod);
    dispatch(
      sendOtpToUser({
        userName,
        [method]: contactMethod,
      })
    );
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
      <div className="of-forgot-password-step__header">
        <h3 className="ds-h3--book">{t.GE17('Forgotten Password')}</h3>
      </div>

      <p>
        {t.NPKT(
          'Enter your username for us to be able to help reset your password. Would you like to receive new password via email or sms?'
        )}
      </p>

      <TextInput name="userName" label={t.J23G(userNameMsg)} placeholder={t.J23G(userNameMsg)} />
      <ContactMethod />
      <ModalCloseAndSubmitButtons
        onCancel={props.closeModal}
        onSubmit={() => {}}
        submitButtonText={t.I62A(continueMsg)}
        submitting={props.anonymousUser.forgotPasswordInProgress}
      />
      <Anchor
        className="link"
        onClick={() => {
          dispatch(updateForgotPasswordStepNumber(5));
        }}
      >
        {t.Y1KA('I cannot remember my username or it has expired.')}
      </Anchor>
    </form>
  );
};
