import { OrderSubscriptionConfig } from '../../../../../../components/OrderSubscription/OrderSubscriptionLayout.js';
import { applyWizardEvent } from '../../../../../../selfservice/actions/index.js';
import { paths } from '../../../../../../common/constants/pathVariables.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const OrderVoiceSubConfigPath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    if (!state?.selectedOffer) {
      navigate(paths.PS_MOBILE_SUBSCRIPTION_NEW);
    }
  }, [navigate, state?.selectedOffer]);
  useEffect(() => {
    dispatch(applyWizardEvent(2, 'NEW_VOICE_SUBSCRIPTION'));
  }, [dispatch]);
  return <OrderSubscriptionConfig />;
};
