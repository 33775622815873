type StepperProps = {
  stepNumber: number;
  totalSteps: number;
};

export const Stepper = ({ stepNumber, totalSteps }: StepperProps) => {
  const steps = [...Array(totalSteps).keys()];
  return (
    <div className="stepper-wrapper">
      {steps.map(item => (
        <div key={item} className={item === stepNumber ? 'stepper-step--selected' : 'stepper-step'}>
          {item + 1}
        </div>
      ))}
    </div>
  );
};
