import * as CL from '@design-system/component-library';
import { AuthenticatedUserRole, CompanyInfoResponse } from '../../generated/api/models.js';
import { COST_CENTER_REGEX } from '../../common/utils/validationUtils.js';
import { ChangePasswordForm } from '../ChangePasswordForm/ChangePasswordForm.js';
import { DialogType } from '../../common/enums.js';
import { Email, LanguageDropdown, PhoneNumber, TextInput } from '../../common/react-hook-form/fields/index.js';
import { FormProvider, useForm } from 'react-hook-form';
import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { LinkableAccordion } from '../LinkableAccordion/index.js';
import { Loading } from '../Loading/index.js';
import { PHONE_NUMBER_REGEX } from '../../common/utils/phoneNumberUtils.js';
import { addEmptyFieldValidationError, convertStringMapToCommonErrors } from '../../common/utils/errorUtils.js';
import {
  cancelMsg,
  changePasswordMsg,
  confirmMsg,
  costCenterAndReferenceMsg,
  costCenterToolTipMsg,
  editMsg,
  emailAddressMsg,
  emailMsg,
  employeeNumberMsg,
  fieldCantBeEmptyMsg,
  firstNameMsg,
  invalidCostCenterNumberMsg,
  invoiceDeliveryMethodMsg,
  lastNameMsg,
  mobilePhoneNumberMsg,
  myAccountMsg,
  referenceMsg,
  referenceToolTipMsg,
  t,
} from '../../common/i18n/index.js';
import {
  changePasswordAction,
  resendEmailVerificationLink,
  showDialog,
  updateUserDetails,
  upsertPersonBillingAccount,
} from '../../selfservice/actions/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { findPersonBillingAccount, getContactInfo } from '../../common/utils/stateUtils.js';
import { isUserDetailsMissing } from '../Employee/Employee.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import type { BillingAccount, ContactPerson } from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';
import type { DialogParams, GenericInfoDialogParams } from '../../common/types/dialog.js';
import type { State } from '../../selfservice/common/store.js';

import './UserDetails.scss';

const isMobileIdLogin = (userName: string) => PHONE_NUMBER_REGEX.test(userName);

const formatAsParagraphs = (text: string, parentClass?: string, paragraphClass?: string): JSX.Element => {
  return (
    <div className={parentClass}>
      {text.split('\n').map((item, i) => (
        <div key={i} className={paragraphClass}>
          {item}
        </div>
      ))}
    </div>
  );
};

export function getErrorsFromUpdateUserDetails(
  user: ContactPerson,
  customerType?: CompanyInfoResponse.CustomerTypeEnum,
  validationErrors?: CommonError[]
): CommonError[] | undefined {
  const errors: CommonError[] = [];
  if (user) {
    if (customerType === CompanyInfoResponse.CustomerTypeEnum.INTERNAL_CUSTOMERS && !user.costCenter) {
      addEmptyFieldValidationError(errors, 'costCenter', t.VPVR(fieldCantBeEmptyMsg));
    }
    if (!user.email?.length) {
      addEmptyFieldValidationError(errors, 'email', t.VPVR(fieldCantBeEmptyMsg));
    }
    if (!user.firstName?.trim().length) {
      addEmptyFieldValidationError(errors, 'firstName', t.VPVR(fieldCantBeEmptyMsg));
    }
    if (!user.lastName?.trim().length) {
      addEmptyFieldValidationError(errors, 'lastName', t.VPVR(fieldCantBeEmptyMsg));
    }
    if (!user.phoneNumber?.length) {
      addEmptyFieldValidationError(errors, 'phoneNumber', t.VPVR(fieldCantBeEmptyMsg));
    }
  }
  if (errors.length === 0) {
    return validationErrors;
  }
  return errors.concat(validationErrors || []);
}

const getEmailVerificationInfoDialog = (email: string): GenericInfoDialogParams => ({
  body: formatAsParagraphs(
    t.BJ43(
      'Confirmation link was sent to your email. {} and subject as "Welcome to Employee OmaElisa". Also check for junk/spam folders. \n Click "Confirm" to verify you email address. After verification you can start using Elisa Employee portal.',
      email
    )
  ),
  header: t.RXQO('Confirm your email address.'),
  type: DialogType.GENERIC_INFO_DIALOG,
});

interface ContactDetailsValues {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  identityProviderEmail?: string;
  identityProviderPhone?: string;
  costCenter?: string;
  employeeNumber?: string;
}

interface ContactDetailsFormProps {
  showUpdateUserInfoHint: boolean;
  resendLink?: JSX.Element;
}

const ContactDetailsForm = ({ showUpdateUserInfoHint, resendLink }: ContactDetailsFormProps) => {
  const { authenticatedUser } = useAuth();
  const [isEdit, setIsEdit] = useState(isUserDetailsMissing(authenticatedUser));
  const dispatch = useDispatch();
  const methods = useForm<ContactDetailsValues>({
    defaultValues: {
      email: authenticatedUser?.email ?? '',
      firstName: authenticatedUser?.firstName ?? '',
      lastName: authenticatedUser?.lastName ?? '',
      phoneNumber: authenticatedUser?.mobile ?? '',
      identityProviderEmail: authenticatedUser?.identityProviderEmail ?? '',
      identityProviderPhone: authenticatedUser?.identityProviderPhone ?? '',
      costCenter: authenticatedUser?.costCenter ?? '',
      employeeNumber: authenticatedUser?.employeeNumber ?? '',
    },
  });
  const { handleSubmit, getValues } = methods;
  const isPunchOutUser = authenticatedUser?.userRole === AuthenticatedUserRole.PUNCHOUT_USER;
  const customerType = useSelector((state: State) => state.selfservice?.companyInfo?.customerType, deepEqual);
  const isCostCenterMandatory = customerType === CompanyInfoResponse.CustomerTypeEnum.INTERNAL_CUSTOMERS;

  const onSubmit = (values: ContactDetailsValues, validationErrors?: { [s: string]: string }) => {
    if (authenticatedUser?.emailVerified === false && !isPunchOutUser) {
      dispatch(showDialog(getEmailVerificationInfoDialog(values.email)));
    }
    dispatch(
      updateUserDetails(
        { ...getContactInfo(authenticatedUser!), ...values },
        customerType,
        convertStringMapToCommonErrors(validationErrors)
      )
    );
    return true;
  };

  return (
    <FormProvider {...methods}>
      <CL.Grid className="ds-margin-top--4">
        <CL.GridRow>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <TextInput
              readonly={!isEdit && !isPunchOutUser}
              name="firstName"
              label={t.AIK7(firstNameMsg)}
              placeholder={t.AIK7(firstNameMsg)}
              maxLength={40}
            />
          </CL.GridCol>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <PhoneNumber
              readonly={!isEdit}
              name="phoneNumber"
              label={`${t.FRYN(mobilePhoneNumberMsg)} (${t.RREM('work')})`}
            />
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <TextInput
              readonly={!isEdit && !isPunchOutUser}
              name="lastName"
              label={t.Y8OY(lastNameMsg)}
              placeholder={t.Y8OY(lastNameMsg)}
              maxLength={100}
            />
          </CL.GridCol>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <Email
              readonly={!isEdit && !isPunchOutUser}
              name="email"
              label={`${t.OKYY(emailAddressMsg)} (${t.RREM('work')})`}
              hint={isEdit ? resendLink : undefined}
            />
          </CL.GridCol>
        </CL.GridRow>
        {authenticatedUser?.businessId && !isPunchOutUser && (
          <CL.GridRow>
            <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
              <TextInput
                readonly={!isEdit}
                name="costCenter"
                required={isCostCenterMandatory}
                label={t.TEYY(costCenterAndReferenceMsg)}
                placeholder={t.TEYY(costCenterAndReferenceMsg)}
                tooltip={t.UE8R(costCenterToolTipMsg)}
                validate={(value: string) => {
                  const validate = (v: string) => (isCostCenterMandatory ? COST_CENTER_REGEX.test(v) : v.length <= 40);
                  if (!validate(value)) {
                    return t.JE8S(invalidCostCenterNumberMsg);
                  }
                  return undefined;
                }}
              />
            </CL.GridCol>
            <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
              <TextInput
                readonly={!isEdit}
                name="employeeNumber"
                required={false}
                label={t.DQHY(referenceMsg)}
                placeholder={t.DQHY(referenceMsg)}
                tooltip={t.HBBD(referenceToolTipMsg)}
                hint={isEdit ? t.FKO8(employeeNumberMsg) : undefined}
                maxLength={80}
              />
            </CL.GridCol>
          </CL.GridRow>
        )}
        {!showUpdateUserInfoHint && !isPunchOutUser && (
          <CL.GridRow>
            <CL.GridCol colWidthXS={4}>
              {formatAsParagraphs(
                t.S0IN(`It is important that your work email address and phone number are up-to-date
              in Employee OmaElisa. We use these contact details when we inform you about Elisa's services that your
              company has acquired. For example, we send you updates when we deliver products you have ordered.\nYour
              company's main user sees your contact details in OmaElisa for companies. There he/she can also make
              updates to your contact details if needed. If your phone number is public, it will also be visible in
              number directory.`)
              )}
            </CL.GridCol>
          </CL.GridRow>
        )}
        <CL.GridRow className="ds-margin-top--7">
          <CL.GridCol colWidthXS={4}>
            {isEdit ? (
              <>
                <CL.Button type="submit" onClick={handleSubmit(() => onSubmit(getValues()))}>
                  {t.QVYK(confirmMsg)}
                </CL.Button>
                <CL.Button type="button" color="link" className="ds-margin-left--4" onClick={() => setIsEdit(false)}>
                  {t.B2V1(cancelMsg)}
                </CL.Button>
              </>
            ) : (
              <CL.Button type="button" color="light" onClick={() => setIsEdit(true)}>
                {t.NVPK(editMsg)}
              </CL.Button>
            )}
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
    </FormProvider>
  );
};

interface LanguageFormValues {
  language: string;
}

const LanguageForm = () => {
  const { authenticatedUser } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const methods = useForm<LanguageFormValues>({
    defaultValues: {
      language: authenticatedUser?.preferredLanguage?.toUpperCase() || 'FI',
    },
  });
  const { handleSubmit, getValues } = methods;
  const onSubmit = (values: { language: string }) => {
    dispatch(updateUserDetails({ ...getContactInfo(authenticatedUser!), preferredLanguage: values.language }));
    setIsEdit(false);
    return true;
  };

  return (
    <FormProvider {...methods}>
      <CL.Grid>
        {isEdit && (
          <CL.GridRow>
            <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
              <LanguageDropdown name="language" />
            </CL.GridCol>
          </CL.GridRow>
        )}
        <CL.GridRow>
          <CL.GridCol colWidthXS={4} colWidthS={6} colWidthL={12}>
            {t.URAQ('The choice affects which language you get e.g. order confirmations and invoices')}
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow className="ds-margin-top--7">
          <CL.GridCol colWidthXS={4}>
            {isEdit ? (
              <>
                <CL.Button type="submit" onClick={handleSubmit(() => onSubmit(getValues()))}>
                  {t.QVYK(confirmMsg)}
                </CL.Button>
                <CL.Button type="button" color="link" className="ds-margin-left--4" onClick={() => setIsEdit(false)}>
                  {t.B2V1(cancelMsg)}
                </CL.Button>
              </>
            ) : (
              <CL.Button type="button" color="light" onClick={() => setIsEdit(true)}>
                {t.NVPK(editMsg)}
              </CL.Button>
            )}
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
    </FormProvider>
  );
};

interface OmaInvoiceFormProps {
  billingAccount: BillingAccount;
}

interface OmaInvoiceFormValues {
  email: string;
}

const OmaInvoiceForm = ({ billingAccount }: OmaInvoiceFormProps) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const methods = useForm<OmaInvoiceFormValues>({
    defaultValues: {
      email: billingAccount.billReceiverEmail || '',
    },
  });
  const { handleSubmit, getValues } = methods;

  const onSubmit = (values: OmaInvoiceFormValues) => {
    dispatch(
      upsertPersonBillingAccount(
        {
          address: billingAccount.payerAddress,
          email: values.email ?? '',
        },
        billingAccount.billingAccountId,
        undefined
      )
    );
    setIsEdit(false);
    return true;
  };

  return (
    <FormProvider {...methods}>
      <CL.Grid>
        {isEdit ? (
          <CL.GridRow>
            <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
              <Email name="email" readonly={!isEdit} required />
            </CL.GridCol>
          </CL.GridRow>
        ) : (
          <CL.GridRow>
            <CL.GridCol colWidthXS={4} colWidthS={6} colWidthL={12}>
              <h4 className="ds-h4">{t.H272(invoiceDeliveryMethodMsg)}</h4>
              <div>{t.OKYY(emailMsg)}</div>
              <div>{billingAccount.billReceiverEmail}</div>
            </CL.GridCol>
          </CL.GridRow>
        )}
        <CL.GridRow className="ds-margin-top--7">
          <CL.GridCol colWidthXS={4}>
            {isEdit ? (
              <>
                <CL.Button type="submit" onClick={handleSubmit(() => onSubmit(getValues()))}>
                  {t.QVYK(confirmMsg)}
                </CL.Button>
                <CL.Button type="button" color="link" className="ds-margin-left--4" onClick={() => setIsEdit(false)}>
                  {t.B2V1(cancelMsg)}
                </CL.Button>
              </>
            ) : (
              <CL.Button type="button" color="light" onClick={() => setIsEdit(true)}>
                {t.NVPK(editMsg)}
              </CL.Button>
            )}
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
    </FormProvider>
  );
};

export const UserDetails = () => {
  const { authenticatedUser } = useAuth();
  const billingAccounts = useSelector((state: State) => state.selfservice?.billingAccounts, deepEqual);

  const isPunchoutUser = authenticatedUser?.userRole === AuthenticatedUserRole.PUNCHOUT_USER;
  const billingAccount = billingAccounts ? findPersonBillingAccount(billingAccounts) : undefined;
  const showUpdateUserInfoHint = isUserDetailsMissing(authenticatedUser);
  const saving = authenticatedUser?.saving || billingAccounts?.saving;

  const dispatch = useDispatch();
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));

  useEffect(() => {
    if (authenticatedUser?.email && authenticatedUser?.emailVerified === false) {
      onShowDialog(getEmailVerificationInfoDialog(authenticatedUser?.email));
    }
  }, [authenticatedUser?.emailVerified]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  const resendLink =
    authenticatedUser?.email && authenticatedUser?.emailVerified === false ? (
      <CL.Button
        type="button"
        color="link"
        className="ds-margin-top--2"
        onClick={() => dispatch(resendEmailVerificationLink())}
      >
        {t.MLUL('Resend confirmation link')}
      </CL.Button>
    ) : undefined;

  const notification = t.S0IN(
    `It is important that your work email address and phone number are up-to-date in Employee OmaElisa. We use these contact details when we inform you about Elisa's services that your company has acquired. For example, we send you updates when we deliver products you have ordered.\nYour company's main user sees your contact details in OmaElisa for companies. There he/she can also make updates to your contact details if needed. If your phone number is public, it will also be visible in number directory.`
  );

  const content = (
    <div className="of-user-details__content-bottom">
      <LinkableAccordion defaultOpen={true} heading={t.SEBD(myAccountMsg)} headingLevel="h3" id="my-account">
        {showUpdateUserInfoHint && (
          <CL.Disclaimer title={t.JE74('Remember to check your contact details')}>
            {formatAsParagraphs(notification, undefined, 'ds-margin-top--3 ds-margin-bottom--2')}
          </CL.Disclaimer>
        )}
        <ContactDetailsForm showUpdateUserInfoHint={showUpdateUserInfoHint} resendLink={resendLink} />
      </LinkableAccordion>
      {authenticatedUser?.businessId && !isPunchoutUser && (
        <LinkableAccordion heading={t.IL1L('Language')} headingLevel="h3" id="language">
          <LanguageForm />
        </LinkableAccordion>
      )}
      {authenticatedUser?.userName && !isMobileIdLogin(authenticatedUser.userName) && !isPunchoutUser && (
        <LinkableAccordion heading={t.R2MM(changePasswordMsg)} headingLevel="h3" id="change-password">
          <ChangePasswordForm
            onSubmit={(data, validationErrors) => dispatch(changePasswordAction(data, validationErrors))}
            saving={saving}
            errors={authenticatedUser!.errors}
          />
        </LinkableAccordion>
      )}
      {billingAccount && !isPunchoutUser && (
        <LinkableAccordion heading={t.FBHJ('Elisa Oma Invoice')} headingLevel="h3" id="my-invoice">
          <div className="billing-email">
            <span>
              <p className="ds-padding-bottom--4">
                {t.YN9F(
                  `Elisa Oma Invoice allows you to choose a more expensive employment device than your employer's standard procedure by paying the price difference on a separate monthly invoice.`
                )}
              </p>
            </span>
            <OmaInvoiceForm billingAccount={billingAccount} />
          </div>
        </LinkableAccordion>
      )}
    </div>
  );

  if (
    authenticatedUser?.identityProviderEmail === undefined &&
    authenticatedUser?.identityProviderPhone === undefined &&
    !isPunchoutUser
  ) {
    return <Loading big={true} bottomPadding={4} />;
  }

  return (
    <div className="of-user-details">
      <HeroHeading
        center={true}
        fullScreen={true}
        heroHeadingType={HeroHeadingType.EMPLOYEE_PROFILE}
        title={authenticatedUser?.firstName ?? ''}
        subtitle={authenticatedUser ? authenticatedUser.companyName : undefined}
      />
      <div className="content">
        <div className="for-margin">
          <div className="for-desktop-margin">
            <CL.Grid>
              <CL.GridRow>
                <CL.GridCol colWidthXS={4} colWidthS={6} colWidthL={11}>
                  <div className="ds-padding-top--7">{content}</div>
                </CL.GridCol>
              </CL.GridRow>
            </CL.Grid>
          </div>
        </div>
      </div>
    </div>
  );
};
