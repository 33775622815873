import { OrderSubscriptionSelection } from '../../../../../../components/OrderSubscriptionSelection/OrderSubscriptionSelection.js';
import { SystemError } from '../../../../../../components/SystemError/SystemError.js';
import { deepEqual } from '../../../../../../common/utils/objectUtils.js';
import {
  loadContacts,
  loadOnlineModelByModelType,
  loadSubscriptionAddOnRules,
} from '../../../../../../selfservice/actions/index.js';
import { paths } from '../../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { ModelType } from '../../../../../../common/enums.js';
import type { OnlineModel } from '../../../../../../generated/api/onlineModel.js';
import type { State } from '../../../../../../selfservice/common/store.js';

interface OrderSubscriptionSelectionWrapperProps {
  onlineModelType: ModelType;
  minuteOnlineModels?: OnlineModel[];
}

export const OrderSubscriptionSelectionWrapper = ({
  onlineModelType,
  minuteOnlineModels,
}: OrderSubscriptionSelectionWrapperProps) => {
  const dispatch = useDispatch();
  const { onlineModelsErrors } = useSelector(
    (state: State) => ({
      onlineModelsErrors: state?.selfservice?.onlineModels?.errors,
    }),
    deepEqual
  );

  useEffect(() => {
    // OrderSubscriptionSelection has its own loading spinner for this
    dispatch(loadOnlineModelByModelType(onlineModelType));
    dispatch(loadSubscriptionAddOnRules());
    // This is required in the next step (OrderSubscriptionConfiguration), and
    // it's a bit slow to load, so let's preload it here. The next step loads it
    // as well if it's not available (so that page refresh won't break things).
    dispatch(loadContacts());
  }, [dispatch, onlineModelType]);

  if (onlineModelsErrors) {
    return <SystemError errors={onlineModelsErrors} homePath={paths.SELF_SERVICE_HOME} />;
  }

  return (
    <OrderSubscriptionSelection
      minuteModels={minuteOnlineModels}
      onlineModelType={onlineModelType}
      subscription={undefined}
    />
  );
};
