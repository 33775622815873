import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { DeliveryAddressDetails } from './DeliveryAddressDetails.js';
import { EmployeeSupportOrServiceHeader } from './EmployeeSupportOrServiceHeader.js';
import { Formik } from 'formik';
import { Loading } from '../Loading/index.js';
import { Message, ProvidedRadio } from '../../common/formik/Fields/index.js';
import { PostEppMaintenanceRequest } from '../../generated/api/models.js';
import { ScrollToTop } from '../../common/utils/browserUtils.js';
import { companyNameMsg, deviceMsg, faultDescriptionPlaceHolderMsg, t, userTextMsg } from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getFullNameFromAuthenticatedUser } from './eppMaintenanceUtils.js';
import { postEppMaintenance } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import type { DeliveryAddress, Subscription } from '../../generated/api/models.js';
import type { State } from '../../selfservice/common/store.js';

import './EppSupportOrServiceRequestForm.scss';

export interface EppSupportOrServiceRequestFormProps {
  onCancel: () => void;
  subscription: Subscription;
  isEmployee: boolean;
}

type EppSupportOrServiceRequestFormFormikType = {
  faultDescription: string;
  deviceCondition: PostEppMaintenanceRequest.DeviceConditionEnum;
};

const ServiceRequestInfo = () => (
  <div className="of-epp-maintenance-service-request-form--radio-group-header">
    <span className="of-epp-maintenance-service-request-form--radio-group-header__title">
      {t.EQI1('Device condition grade')}
    </span>
    <span>{t.AEOA('Choose the option that best describes the current state of your device')}</span>
  </div>
);

const DeviceConditionSelection = () => (
  <div className="ds-padding-bottom--2">
    <ProvidedRadio
      withError={true}
      name="deviceCondition"
      radioButtons={[
        {
          label: t.U1IQ('Good as new'),
          value: PostEppMaintenanceRequest.DeviceConditionEnum.GOOD_AS_NEW,
        },
        {
          label: t.MBNB('Scratches or wear on the screen'),
          value: PostEppMaintenanceRequest.DeviceConditionEnum.SCRATCHES_ON_SCREEN,
        },
        {
          label: t.QNUL('Scratches or wear on the body'),
          value: PostEppMaintenanceRequest.DeviceConditionEnum.SCRATCHES_ON_BODY,
        },
        {
          label: t.HVG8('Scratches or wear on the screen and body'),
          value: PostEppMaintenanceRequest.DeviceConditionEnum.SCRATCHES_ON_BODY_AND_SCREEN,
        },
        {
          label: t.PO7A('Damage case (damage to the device, broken screen, warped body, wet, etc.)'),
          value: PostEppMaintenanceRequest.DeviceConditionEnum.INCIDENT,
        },
      ]}
    />
  </div>
);

const FaultDescription = () => (
  <div className="of-epp-maintenance-service-request-form__message">
    <p>{t.TQ6Z('Fault description')}</p>
    <Message name="faultDescription" placeholder={t.U1WD(faultDescriptionPlaceHolderMsg)} label="" required />
  </div>
);

const DeviceDetails = ({
  companyName,
  subscriptionName,
  fullName,
}: {
  subscriptionName: string;
  fullName: string;
  companyName?: string;
}) => (
  <div className="ds-padding-top--7">
    <div className="ds-grid__row">
      <div className="ds-grid__col--xs-4 ds-grid__col--l-6 of-epp-maintenance-service-request-form-device_details-value">
        <strong>{t.AJ93(companyNameMsg)}</strong>
        {companyName}
      </div>
      <div className="ds-grid__col--xs-4 ds-grid__col--l-6 of-epp-maintenance-service-request-form-device_details-value">
        <strong>{t.U4MA(userTextMsg)}</strong>
        {fullName}
      </div>
    </div>
    <div className="ds-grid__row">
      <div className="ds-grid__col--xs-4 ds-grid__col--l-6 of-epp-maintenance-service-request-form-device_details-value">
        <strong>{t.TPVQ(deviceMsg)}</strong>
        {subscriptionName || '-'}
      </div>
    </div>
  </div>
);

export const EppSupportOrServiceRequestForm = ({
  subscription,
  onCancel,
  isEmployee,
}: EppSupportOrServiceRequestFormProps) => {
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const { authenticatedUser } = useAuth();
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddress>();
  const fullName = getFullNameFromAuthenticatedUser(authenticatedUser);
  const dispatch = useDispatch();

  const handleSubmit = (values: EppSupportOrServiceRequestFormFormikType) => {
    dispatch(
      postEppMaintenance(
        {
          deliveryAddress: deliveryAddress!, // submit will be disabled unless the deliveryAddress is defined
          deviceCondition: values?.deviceCondition,
          faultDescription: values?.faultDescription,
        },
        subscription.subscriptionId
      )
    );
  };

  if (!companyInfo) {
    return <Loading />;
  } else {
    return (
      <Formik
        initialValues={{
          faultDescription: '',
          deviceCondition: '',
        }}
        onSubmit={handleSubmit}
        validate={formikValues =>
          formikValues.deviceCondition ? {} : { deviceCondition: t.CMGJ('Please select one option') }
        }
      >
        {/* Can't use form tags here because there is another form used in child component DeliveryAddressDetails /
      DeliveryAddressForm and nested forms are not allowed in HTML */}
        {formik => (
          <div className="of-epp-maintenance-service-request-form">
            {/* TODO can be removed when the ScrollToTop component works up in the component hierarchy. The current
            solution needs more widely spread React Router usage.
            More info in the ScrollToTop code starting the from https://github.devcloud.elisa.fi/onlinefirst/online/blob/master/online-ui/src/components/common/utils/browserUtils.tsx#L74
            */}
            <ScrollToTop />
            {isEmployee && <EmployeeSupportOrServiceHeader />}
            <ServiceRequestInfo />
            <DeviceConditionSelection />
            <FaultDescription />
            {!isEmployee && (
              <DeviceDetails
                subscriptionName={subscription.subscriptionName}
                companyName={companyInfo?.companyName}
                fullName={fullName}
              />
            )}
            <DeliveryAddressDetails
              address={companyInfo?.address}
              companyName={companyInfo?.companyName}
              recipientName={fullName}
              phoneNumber={authenticatedUser?.mobile || ''}
              setDeliveryAddress={setDeliveryAddress}
              showConfirmAddressCheckbox={true}
              onAddressReady={setIsSubmitEnabled}
              isSupportRequest={true}
            />
            <hr />
            <ButtonGroupForSubmitAndBack
              className="of-epp-maintenance-service-request-form__buttons"
              onSubmit={formik.submitForm}
              onCancel={onCancel}
              submitDisabled={!isSubmitEnabled || !deliveryAddress}
              submitting={formik.isSubmitting}
            />
          </div>
        )}
      </Formik>
    );
  }
};
