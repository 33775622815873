/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ValidateAuxiliaryEsimOrderResponse { 
    /**
     * True if the user can order, otherwise false
     */
    canOrder: boolean;
    /**
     * Machine readable string, only included if not allowed to order
     */
    reasonCode?: ValidateAuxiliaryEsimOrderResponse.ReasonCodeEnum;
    /**
     * Human readable in Finnish, e.g. \'MultiSIM-palvelua ei voi tilata olemassa olevan palvelun vuoksi: Lankanumero\'
     */
    reasonText?: string;
    /**
     * The service that has a conflict. Only provided when reasonCode=CONFLICTING_SERVICE_EXISTS
     */
    conflictingServiceName?: ValidateAuxiliaryEsimOrderResponse.ConflictingServiceNameEnum;
    /**
     * Subscription source system
     */
    sourceSystem: ValidateAuxiliaryEsimOrderResponse.SourceSystemEnum;
    /**
     * True if Elisa ID user has been connected to the sub, otherwise false
     */
    pairingStatus: boolean;
}
export namespace ValidateAuxiliaryEsimOrderResponse {
    export type ReasonCodeEnum = 'SUCCESS_NO_PAIRING' | 'SUCCESS' | 'CONNECTION_ERROR' | 'SALESFORCE_SUB' | 'ACCOUNT_LEVEL_BARRING' | 'NO_DATA' | 'CONFLICTING_SERVICE_EXISTS' | 'REQUIRED_SERVICE_MISSING' | 'TOO_MANY_MULTISIMS' | 'SUB_NOT_ACTIVE';
    export const ReasonCodeEnum = {
        SUCCESS_NO_PAIRING: 'SUCCESS_NO_PAIRING' as ReasonCodeEnum,
        SUCCESS: 'SUCCESS' as ReasonCodeEnum,
        CONNECTION_ERROR: 'CONNECTION_ERROR' as ReasonCodeEnum,
        SALESFORCE_SUB: 'SALESFORCE_SUB' as ReasonCodeEnum,
        ACCOUNT_LEVEL_BARRING: 'ACCOUNT_LEVEL_BARRING' as ReasonCodeEnum,
        NO_DATA: 'NO_DATA' as ReasonCodeEnum,
        CONFLICTING_SERVICE_EXISTS: 'CONFLICTING_SERVICE_EXISTS' as ReasonCodeEnum,
        REQUIRED_SERVICE_MISSING: 'REQUIRED_SERVICE_MISSING' as ReasonCodeEnum,
        TOO_MANY_MULTISIMS: 'TOO_MANY_MULTISIMS' as ReasonCodeEnum,
        SUB_NOT_ACTIVE: 'SUB_NOT_ACTIVE' as ReasonCodeEnum
    };
    export type ConflictingServiceNameEnum = 'RING' | 'MULTISIM' | 'LANKANUMERO';
    export const ConflictingServiceNameEnum = {
        RING: 'RING' as ConflictingServiceNameEnum,
        MULTISIM: 'MULTISIM' as ConflictingServiceNameEnum,
        LANKANUMERO: 'LANKANUMERO' as ConflictingServiceNameEnum
    };
    export type SourceSystemEnum = 'SFDC' | 'TELLUS' | 'NO_DATA' | 'CONNECTION_ERROR';
    export const SourceSystemEnum = {
        SFDC: 'SFDC' as SourceSystemEnum,
        TELLUS: 'TELLUS' as SourceSystemEnum,
        NO_DATA: 'NO_DATA' as SourceSystemEnum,
        CONNECTION_ERROR: 'CONNECTION_ERROR' as SourceSystemEnum
    };
}


