import './IconAndText.scss';

interface IconAndTextProps {
  text: string;
  icon: string;
  id?: string;
}

export const IconAndText = ({ text, icon, id }: IconAndTextProps) => (
  <div className="ea-icon-and-text ds-padding-vertical--4 ds-padding-horizontal--3" id={id}>
    <div className={'ea-icon-and-text__icon ea-icon-and-text__icon--' + icon} />
    <div className="ea-icon-and-text__text">{text}</div>
  </div>
);
