import { DialogType } from '../../common/enums.js';
import { EIP_EXCHANGE_ADDON_ID } from '../../common/constants/commonConstants.js';
import { EMAIL_REGEX } from '../../common/utils/emailUtils.js';
import { LinkableAccordion } from '../LinkableAccordion/index.js';
import { Loading } from '../Loading/index.js';
import { RingSettingsAccordionContent } from '../RingSettingsAccordionContent/RingSettingsAccordionContent.js';
import { centsToEuros, formatNumberToCurrency } from '../../common/utils/priceUtils.js';
import { changeSubscriptionPbxConfiguration } from '../../selfservice/actions/index.js';
import { extensionNumberMsg, monthlyFeeOfSubAfterChangesMsg, t } from '../../common/i18n/index.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { useDispatch } from 'react-redux';
import type { AccordionProps } from './MobileSubscriptionAccordions.js';
import type { AddOn, Contact, OnlineModel, SubscriptionPbxConfiguration } from '../../generated/api/models.js';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { CompanyInfoState } from '../../common/types/states.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { FunctionComponent } from 'react';
import type { ServiceLevel, ServiceLevelAddon } from '../AttachRing/ServiceLevelAndAddonsInterfaces.js';
import type { SubscriptionStatus } from '../../common/types/subscription.js';

import './PbxAccordions.scss';

export interface PbxAccordionsProps extends AccordionProps {
  companyInfo?: CompanyInfoState | null;
  ringModels?: OnlineModel[];
  addOnRulesAssociations?: AssociationRecord[];
  addOnRulesDependencies?: DependencyRecord[];
  subscriptionStatus: SubscriptionStatus;
  pbxServiceLevels?: ServiceLevel[];
  pbxServiceLevelAddons?: ServiceLevelAddon[];
  onShowDialog: (params: DialogParams) => void;
  contacts: Contact[];
}

export const PbxAccordions: FunctionComponent<PbxAccordionsProps> = ({
  companyInfo,
  subscription,
  configuredPbxSolution,
  subscriptionActions,
  ringModels,
  addOnRulesAssociations,
  addOnRulesDependencies,
  pbxServiceLevels,
  pbxServiceLevelAddons,
  onEditSectionIfNoActionsPending,
  editingSection,
  contacts,
  onShowDialog,
}) => {
  const dispatch = useDispatch();
  const subscriptionPbxConfiguration = subscription.details!.mobile
    ? subscription.details!.mobile!.pbxConfiguration
    : subscription.details!.mobilePbxEndUserService;
  const ringUserName = subscriptionPbxConfiguration!.pbxConfigurationDetails.ringUserName;
  const subscriptionContact = contacts.find(
    ({ contactId, person }) => person && contactId === subscription.subscriptionContactId
  );
  const ringUserNames: string[] = [];
  if (ringUserName) {
    ringUserNames.push(ringUserName);
  }
  if (subscriptionContact) {
    const contactEmail = subscriptionContact.person?.email;
    if (contactEmail && !ringUserNames.includes(contactEmail)) {
      ringUserNames.push(subscriptionContact.person!.email);
    }
  }

  const onShowOverviewDialog = (ringModelAddOnsFound: AddOn[], pbxConfig: SubscriptionPbxConfiguration) => {
    const totalAddonsPriceMonthly: number = ringModelAddOnsFound
      .map(addon => addon.addOnMonthlyRecurringCharge || 0)
      .reduce((sum: number, val: number) => sum + val, 0);
    const totalSubscriptionPriceMonthly = subscription?.details?.monthlyRecurringListCharge || 0;
    const totalPriceMonthly = totalAddonsPriceMonthly + totalSubscriptionPriceMonthly;
    const corporateNumber = subscription.details?.mobile?.pbxConfiguration?.corporateNumber;
    onShowDialog({
      body: (
        <div className="of-pbx-ring-settings-accordion">
          <div className="row ds-margin-top--6">
            <div className="fifty">{t.D9TG('Corporate number')}</div>
            <div className="fifty">{t.V972(extensionNumberMsg)}</div>
          </div>
          <div className="row ds-margin-bottom--4">
            <div className="fifty">{corporateNumber && formatPhoneNumber(corporateNumber, true)}</div>
            <div className="fifty">{subscription.details?.mobile?.pbxConfiguration?.extensionNumber}</div>
          </div>

          {ringModelAddOnsFound?.map((addOn, i) => {
            const price = centsToEuros(addOn.addOnMonthlyRecurringCharge || addOn.addOnOneTimeCharge || 0);
            const suffix = ` €${addOn.addOnMonthlyRecurringCharge ? ' /' + t.XXVX('month') : ''}`;
            return (
              <div
                className={`row${i === ringModelAddOnsFound.length - 1 ? ' ds-margin-bottom--4' : ''}`}
                key={addOn.addOnCode}
              >
                <div className="seventy-five">{addOn.addOnProductName}</div>
                <div className="twenty-five">
                  {formatNumberToCurrency(price)}
                  {suffix}
                </div>
              </div>
            );
          })}
          <div className="row">
            {t.CL9O(monthlyFeeOfSubAfterChangesMsg, `${formatNumberToCurrency(centsToEuros(totalPriceMonthly))} €`)}
          </div>
        </div>
      ),
      header: t.JU94('Ring add-ons'),
      onConfirm: onCloseDialog => {
        pbxConfig.pbxConfigurationDetails.ringAdditionalServiceCodes = ringModelAddOnsFound.map(
          addon => addon.addOnCode
        );
        dispatch(changeSubscriptionPbxConfiguration(subscription.subscriptionId, pbxConfig, () => undefined));
        onCloseDialog();
      },
      type: DialogType.GENERIC_CONFIRMATION_DIALOG,
    });
  };

  const findAddons = (pbxConfig: SubscriptionPbxConfiguration, ringOnlineModels?: OnlineModel[]) => {
    const selectedServiceLevelCode = pbxConfig.pbxConfigurationDetails.ringServiceLevelCode;

    const selectedOffer = ringOnlineModels?.[0].offers.filter(
      offer => offer.commercialProducts[0].commercialProductCode === selectedServiceLevelCode
    );

    const addonIdsForSelectedOffer =
      selectedOffer?.[0].commercialProducts?.[0].addOnAssociations?.map(assoc => assoc.addOnCode) || [];

    const validRingAdditionalServiceCodes =
      pbxConfig.pbxConfigurationDetails?.ringAdditionalServiceCodes?.filter(addon =>
        addonIdsForSelectedOffer.includes(addon)
      ) || [];

    return (
      (ringOnlineModels &&
        ringOnlineModels[0].addOns?.filter(addOn => validRingAdditionalServiceCodes?.includes(addOn.addOnCode))) ||
      []
    );
  };

  const onSave = (pbxConfig: SubscriptionPbxConfiguration) => {
    const currentRingUserName = pbxConfig.pbxConfigurationDetails.ringUserName;
    const isGivenEmailValid = currentRingUserName && EMAIL_REGEX.test(currentRingUserName);
    if (
      !isGivenEmailValid &&
      pbxConfig.pbxConfigurationDetails?.ringAdditionalServiceCodes?.includes(EIP_EXCHANGE_ADDON_ID)
    ) {
      onShowDialog({
        type: DialogType.NEW_RING_USERNAME,
        onConfirm: (newRingUsername: string) => {
          const ringModelAddOns: AddOn[] = findAddons(pbxConfig, ringModels);
          // Update Ring username to the one user entered
          if (newRingUsername !== '') {
            pbxConfig.pbxConfigurationDetails.ringUserName = newRingUsername;
          }
          onShowOverviewDialog(ringModelAddOns, pbxConfig);
        },
      });
    } else {
      const ringModelAddOns: AddOn[] = findAddons(pbxConfig, ringModels);
      onShowOverviewDialog(ringModelAddOns, pbxConfig);
    }
  };

  return (
    <>
      <LinkableAccordion heading={t.U373('Ring settings')} headingLevel="h3" id="pbx-ring-settings">
        {ringModels &&
        ringModels.length > 0 &&
        addOnRulesAssociations &&
        addOnRulesDependencies &&
        subscriptionPbxConfiguration ? (
          <RingSettingsAccordionContent
            companyInfo={companyInfo}
            subscriptionPbxConfiguration={subscriptionPbxConfiguration}
            pbxSolution={configuredPbxSolution!}
            pbxServiceLevels={pbxServiceLevels}
            pbxServiceLevelAddons={pbxServiceLevelAddons}
            subscriptionId={subscription.subscriptionId}
            onEdit={() => onEditSectionIfNoActionsPending('pbxRingSettings')}
            editing={editingSection === 'pbxRingSettings'}
            onCancel={() => onEditSectionIfNoActionsPending()}
            onSave={onSave}
            saving={Boolean(subscriptionActions?.saving)}
            ringUserNames={ringUserNames}
            addOnAssociations={addOnRulesAssociations}
            addOnDependencies={addOnRulesDependencies}
            ringModels={ringModels}
            subscription={subscription}
            onShowDialog={onShowDialog}
          />
        ) : (
          <div className="ds-padding-bottom--4">
            <Loading />
          </div>
        )}
      </LinkableAccordion>
    </>
  );
};
