import { CustomerOrderStatus, Subscription } from '../../../generated/api/models.js';
import { ITEMS_QUERY_LIMIT } from '../../../common/constants/commonConstants.js';
import { capitalize } from '../../../common/utils/stringUtils.js';
import type {
  BillingAccount,
  CustomerOrder,
  CustomerOrderHeader,
  CustomerOrderSearchResponse,
  SubscriptionHeader,
} from '../../../generated/api/models.js';
import type { CustomerOrdersState, DisplayItemsState, SubscriptionsState } from '../../../common/types/states.js';

export const toCSV = (data: string[][], separator: string) => {
  return data
    .filter(Boolean)
    .map(row => row.map(column => column ?? '').join(separator))
    .join(`\n`);
};

export const toBinary = (data: string) => new Blob(['\ufeff', data]);

export const buildURI = (data: string[][], separator: string) => {
  const csv = toCSV(data, separator);
  return toBinary(csv);
};

export enum ReportType {
  EPP_SERVICE_REPORT = 'EPP_SERVICE_REPORT',
  EPP_REDEEM_REPORT = 'EPP_REDEEM_REPORT',
}

export const exportDataFromURI = (blob: Blob, fileName: string) => {
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const formatDate = (date?: number) => (date ? `"${new Date(+date!).toLocaleDateString('fi')}"` : '');

export const formatCellValue = (cellValue: string) =>
  cellValue
    .toLowerCase()
    .split('_')
    .map(wordToken => capitalize(wordToken))
    .join(' ');

export const csvValue = (value?: string, prefix?: string) => (value ? `${prefix || ''}"${value}"` : '');

export const getBaAddress = (billingAccount?: BillingAccount) =>
  billingAccount?.payerAddress
    ? `"${billingAccount?.payerAddress.line1} ${billingAccount?.payerAddress.line2 ?? ''}${
        billingAccount?.payerAddress.postalCode ?? ''
      } ${billingAccount?.payerAddress.postOffice ?? ''} ${billingAccount?.payerAddress.countryCode ?? ''}"`
    : '';

export const getSubscriptionPurposeOfUse = (subscriptionItem: SubscriptionHeader) =>
  subscriptionItem.subscriptionPurposeOfUse && !subscriptionItem.subscriptionContactId
    ? `"${subscriptionItem.subscriptionPurposeOfUse}"`
    : '';

export const getSubscriptionContactName = (subscriptionItem: SubscriptionHeader) =>
  subscriptionItem.subscriptionContactName && subscriptionItem.subscriptionContactId
    ? `"${subscriptionItem.subscriptionContactName}"`
    : '';

export const isLoaded = (state?: DisplayItemsState<unknown> | null): boolean =>
  (state?.total ?? 0) === state?.items?.length;

export const isDevicesLoaded = (deviceSubscriptions?: SubscriptionsState | null) =>
  (deviceSubscriptions?.total ?? 0) === deviceSubscriptions?.searchResults?.length;

export const isDevicesPartiallyLoaded = (deviceSubscriptions?: SubscriptionsState | null) =>
  (deviceSubscriptions?.total ?? 0) > ITEMS_QUERY_LIMIT &&
  (deviceSubscriptions?.total ?? 0) !== deviceSubscriptions?.searchResults?.length;

export const isOrdersLoaded = (customerOrders?: CustomerOrdersState | null) =>
  (customerOrders?.total ?? 0) === customerOrders?.searchResults?.length;

export const isOrderCancelled = (customerOrderStatus?: CustomerOrderStatus): boolean =>
  customerOrderStatus !== undefined && customerOrderStatus === CustomerOrderStatus.CANCELLED;

export const formatCustomerOrderNumber = (sourceSystem?: string, customerOrderDisplayId?: string) => {
  const customerOrderNumber =
    sourceSystem !== Subscription.SourceSystemEnum.SFDC ? 'Ordered from OmaElisa Classic' : customerOrderDisplayId;
  return customerOrderNumber ? `"${customerOrderNumber}"` : '';
};

export interface CustomerOrderReportingType {
  customerOrderCreated: number;
  customerOrderDisplayId: string;
  customerOrderStatus?: CustomerOrderStatus;
  deliveryOrderDeliveryDate?: number;
  deliveryOrderId: string;
}

export const populateRequiredOrderDetails = (
  customerOrders?: CustomerOrder[],
  customerOrderSearchResults?: CustomerOrderSearchResponse[]
): Record<string, CustomerOrderReportingType> => {
  const customerOrderReportingDataMap: Record<string, CustomerOrderReportingType> = {};

  customerOrderSearchResults?.forEach(customerOrderSearchResult => {
    const customerOrderHeader: CustomerOrderHeader = customerOrderSearchResult?.result;
    const deliveryOrderIds: string[] = customerOrderHeader.deliveryOrderIds || [];
    for (let i = 0; i < deliveryOrderIds.length; i++) {
      customerOrderReportingDataMap[deliveryOrderIds[i]] = {
        customerOrderDisplayId: customerOrderHeader.customerOrderDisplayId || '',
        customerOrderStatus: customerOrderHeader.status,
        customerOrderCreated: customerOrderHeader.created!,
        deliveryOrderId: deliveryOrderIds[i],
        deliveryOrderDeliveryDate: customerOrderHeader.deliveryOrderDeliveryDates
          ? customerOrderHeader.deliveryOrderDeliveryDates[i]
          : undefined,
      };
    }
  });

  return customerOrderReportingDataMap;
};
