import * as CL from '@design-system/component-library';
import { ContactRole } from '../../generated/api/models.js';
import { DialogType } from '../../common/enums.js';
import { InfiniteScrollCompositeList } from '../InfiniteScrollCompositeList/index.js';
import { buildPathVariable, paths } from '../../common/constants/pathVariables.js';
import { emailMsg, nameMsg, phoneNumberMsg, t } from '../../common/i18n/index.js';
import { loadContacts, showDialog } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { AccountKeyUser, Contact, Subscription } from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';
import type { CompositeListColumn, CompositeListSort } from '../CompositeListHeader/index.js';
import type { ConfigState } from '../../common/types/states.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { InfiniteScrollCompositeListProps } from '../InfiniteScrollCompositeList/index.js';

interface RingAdminItem {
  id?: string;
  fullName?: string;
  phoneNumber?: string;
  email?: string;
}

const ringAdminColumns: CompositeListColumn<RingAdminItem>[] = [
  {
    columnId: 'ringAdminName',
    heading: t.VGFI(nameMsg),
    ref: 'fullName',
  },
  {
    columnId: 'ringAdminPhoneNumber',
    heading: t.AGIJ(phoneNumberMsg),
    ref: 'phoneNumber',
  },
  {
    columnId: 'ringAdminEmail',
    heading: t.XVHQ(emailMsg),
    ref: 'email',
  },
];

const getRingAdminItems = (contacts?: Contact[]): RingAdminItem[] | undefined => {
  return contacts?.map(item => {
    return {
      id: item.contactId,
      fullName: `${item.person?.firstName} ${item.person?.lastName}`,
      phoneNumber: item.person?.phoneNumber,
      email: item.person?.email,
    };
  });
};

const isUserRingAdmin = (contact: Contact): boolean =>
  contact.person !== undefined && (contact.person?.roles || []).includes(ContactRole.MOBILE_PBX_ADMIN);

export interface RingAdminListProps {
  config: ConfigState;
  errors?: CommonError[];
  items?: Contact[];
  sort?: CompositeListSort;
  total?: number;
  accountKeyUsers?: AccountKeyUser[];
  ringPbxSolutions: Subscription[];
  pendingSubscriptionChanges: boolean;
}

const beginAddRingAdmin = (
  onShowDialog: (params: DialogParams) => void,
  ringPbxSolutions: Subscription[],
  accountKeyUsers: AccountKeyUser[]
) =>
  onShowDialog({
    accountKeyUsers,
    ringPbxSolutions,
    type: DialogType.ADD_RING_ADMIN,
  });

export const RingAdminList = ({
  items,
  accountKeyUsers,
  ringPbxSolutions,
  pendingSubscriptionChanges,
  sort,
  total,
}: RingAdminListProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));
  const adminContacts = items ? items.filter(isUserRingAdmin) : undefined;

  const listProps: InfiniteScrollCompositeListProps<RingAdminItem> = {
    classes: ['of-ring-admin-list'],
    columns: ringAdminColumns,
    emptyListText: t.S80W('No Ring administrators'),
    enableInfiniteScroll: items && total !== undefined ? items.length < total : false,
    getRowId: (ringAdminItem: RingAdminItem) => ringAdminItem.id!,
    items: getRingAdminItems(adminContacts),
    onInfiniteScrollLoadMore: () => dispatch(loadContacts(undefined, undefined, undefined, true)),
    onSelectRow: (id: string) => {
      navigate(buildPathVariable(paths.COMPANY_INFO_RING_ADMIN, id));
    },
    onSort: newSort => dispatch(loadContacts(undefined, newSort)),
    rowIcon: 'company-user',
    sort,
    topRight: (
      <CL.Button
        size="l"
        onClick={() => {
          if (!pendingSubscriptionChanges) {
            beginAddRingAdmin(onShowDialog, ringPbxSolutions, accountKeyUsers ? accountKeyUsers : []);
          } else {
            onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING });
            return;
          }
        }}
      >
        {t.E9A9('+ NEW RING ADMINISTRATOR')}
      </CL.Button>
    ),
  };

  return <InfiniteScrollCompositeList {...listProps} />;
};
