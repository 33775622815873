import type { ReactNode } from 'react';

import './FormGridFieldset.scss';

interface FormGridContainerProps {
  children: ReactNode;
}

export const FormGridFieldset = ({ children }: FormGridContainerProps) => {
  return <fieldset className="form-grid-fieldset">{children}</fieldset>;
};
