import { FixedBroadbandSetupDate } from '../FixedBroadbandSetupDate.js';
import { prepareBillingAccountEdit } from '../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { CheckoutStepProps } from '../FixedBroadbandCheckout.js';
import type { FixedBroadbandInstallationDetails } from '../FixedBroadbandSetupDate.js';

interface StepBroadbandSetupDateProps extends CheckoutStepProps {
  isMobileProduct: boolean;
  setBroadbandInstallationDetails: React.Dispatch<React.SetStateAction<FixedBroadbandInstallationDetails>>;
}

export const StepBroadbandSetupDate = (props: StepBroadbandSetupDateProps) => {
  const dispatch = useDispatch();

  const { isMobileProduct, setBroadbandInstallationDetails, enterNextStep } = props;

  return (
    <FixedBroadbandSetupDate
      isMobileProduct={isMobileProduct}
      onNext={values => {
        setBroadbandInstallationDetails(values);
        dispatch(prepareBillingAccountEdit());
        enterNextStep(4);
      }}
    />
  );
};
