import classNames from 'classnames';

import './BlankHeading.scss';

export interface BlankHeadingProps {
  detailsTop?: JSX.Element;
  title: string;
  subtitle?: string;
}

export const BlankHeading = ({ detailsTop, title, subtitle }: BlankHeadingProps) => {
  return (
    <div className="of-blank-heading">
      {detailsTop && <div className="of-details-top">{detailsTop}</div>}
      <div
        className={classNames('titles', {
          'titles--with-subtitle': !!subtitle && !detailsTop, // eslint-disable-line @typescript-eslint/naming-convention
          'titles--title-only': !subtitle && !detailsTop, // eslint-disable-line @typescript-eslint/naming-convention
          'titles--with-details-top': !!detailsTop, // eslint-disable-line @typescript-eslint/naming-convention
        })}
      >
        {subtitle && <h3 className="ds-h3--book ds-margin--0">{subtitle}</h3>}
        <h1
          className={classNames('title', {
            'title--only': !subtitle, // eslint-disable-line @typescript-eslint/naming-convention
          })}
        >
          {title}
        </h1>
      </div>
    </div>
  );
};
