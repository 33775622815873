import { useEffect, useState } from 'react';

const TYPING_SPEED = 5;

/**
 * Used for creating typing effect that shows one letter at a time: l, lo, lor, lore, lorem, etc.
 */
const useTypingEffect = (messages: string[], setIsTypingFinished: () => void): [string[], boolean] => {
  const [rowAndCol, setRowAndCol] = useState<[number, number]>([0, 0]);
  const isLastRow = rowAndCol[0] === messages.length - 1;
  const isLastCol = rowAndCol[1] === messages[rowAndCol[0]].length;
  const isSecondLastCol = rowAndCol[1] === messages[rowAndCol[0]].length - 1;

  useEffect(() => {
    const interval = setInterval(() => {
      if (isLastRow) {
        if (isLastCol) {
          setIsTypingFinished();
          return;
        }
        if (isSecondLastCol) {
          return setRowAndCol(([r, c]) => [r, c + 1]);
        }
      }
      if (messages[rowAndCol[0]].length === rowAndCol[1]) {
        return setRowAndCol(([r]) => [r + 1, 0]);
      }
      setRowAndCol(([r, c]) => [r, c + 1]);
    }, TYPING_SPEED);
    return () => {
      clearInterval(interval);
    };
  }, [isLastCol, isLastRow, isSecondLastCol, messages, rowAndCol, setIsTypingFinished]);

  const array = [
    ...messages.slice(0, rowAndCol[0]).map(paragraph => {
      return paragraph;
    }),
    messages[rowAndCol[0]].substring(0, rowAndCol[1]),
  ].filter(Boolean);
  return [array.length ? array : [''], isLastRow && isLastCol];
};

interface TypingEffectProps {
  messages: string[];
  setIsTypingFinished: () => void;
}

export const TypingEffect = ({ messages, setIsTypingFinished }: TypingEffectProps) => {
  const [paragraphs, isTypingReady] = useTypingEffect(messages, setIsTypingFinished);
  return (
    <>
      {paragraphs.map((paragraph, i) => {
        return (
          <p key={i}>
            {paragraph}
            {i === paragraphs.length - 1 && !isTypingReady && <span className="cursor">|</span>}
          </p>
        );
      })}
    </>
  );
};
