import { ELISA_ICCID_PREFIX } from '../../common/utils/validationUtils.js';
import { dataNotAvailableMsg, simCardNumberMsg, t } from '../../common/i18n/index.js';

import './SimCardNumber.scss';

export interface SimCardNumberProps {
  simCardNumber?: string;
}

export const SimCardNumber = ({ simCardNumber }: SimCardNumberProps): JSX.Element => {
  const simNumberClassName = simCardNumber ? undefined : 'of-sim-card-number--invalid-sim-number';
  const shortSimNumber = simCardNumber?.replace(ELISA_ICCID_PREFIX, '');
  const simNumber = shortSimNumber || t.PJC3(dataNotAvailableMsg);

  return (
    <div id="sim-card-number" className="of-sim-card-number ds-padding-bottom--4">
      <label>{t.VLQ3(simCardNumberMsg)}</label>
      <span className={simNumberClassName}>{simNumber}</span>
    </div>
  );
};
