import { Link } from 'react-router-dom';
import { OnlineModelCategory } from '../../generated/api/onlineModelCategory.js';
import { PromotionBlockContainer } from '../../content-blocks/PromotionBlock/PromotionBlockContainer.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { eCommerceClickEvent, eCommerceImpressionEvent, loadOnlineModels } from '../../selfservice/actions/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { OnlineModelHeader } from '../../generated/api/onlineModelHeader.js';
import type { State } from '../../selfservice/common/store.js';

// TODO move DS stuff to some clClassNames.ts type of thing?
const DS_H3 = 'ds-h3';
const DS_LINK = 'ds-link';
const DS_LINK_STYLE_LINK_BUTTON = 'ds-link--style-link-button';
const DS_LINK_BUTTON_COLOR_LIGHT = 'ds-link--button-color-light';
const DS_LINK_BUTTON_SIZE_L = 'ds-link--button-size-l';
const DS_MARGIN_TOP_6 = 'ds-margin-top--6';

const ROUTER_PRODUCT_ONLINE_MODEL_CODES = [
  '525be665-6dfe-d7f2-d01d-15f56463585c', // Zyxel NR5103 5G -reititin
  '88a50de1-d1b5-4ce2-b56c-8d0db85209b1', // ZTE MU5001 5G WiFi -mobiilireititin
  '5ceab1b2-a1ce-7404-95c9-e1078bc2154e', // ZTE MF286D 4G -reititin
  '4f3a046b-45d9-edfd-4395-52fa80b3770b', // Caterpillar CAT Q10 5G -WiFi mobiilireititin (akullinen)
];

const shareYourNetworkAcrossMultipleDevicesWithRouterMsg = 'Share your network across multiple devices with a router';
export const RoutersPromotionBlock = () => {
  const dispatch = useDispatch();
  const onlineModelHeaders = useSelector(
    (state: State) => state.selfservice?.onlineModelHeaders?.items || [],
    deepEqual
  );

  /* TODO: rules-of-hooks, copied from CartProductStep, can't see an easy fix */
  useEffect(() => {
    // Only load headers not already present
    if (
      ROUTER_PRODUCT_ONLINE_MODEL_CODES.some(
        onlineModelCode =>
          // @ts-ignore
          !onlineModelHeaders[OnlineModelCategory.NETWORK_EQUIPMENT]?.some(
            (h: OnlineModelHeader) => h.onlineModelCode === onlineModelCode
          )
      )
    ) {
      dispatch(loadOnlineModels(OnlineModelCategory.NETWORK_EQUIPMENT, true));
    }
  }, [dispatch, ROUTER_PRODUCT_ONLINE_MODEL_CODES]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <section aria-label={t.SDU1(shareYourNetworkAcrossMultipleDevicesWithRouterMsg)}>
      <div>
        <h2 className={DS_H3}>{t.SDU1(shareYourNetworkAcrossMultipleDevicesWithRouterMsg)}</h2>
        <p>
          {t.SDU2(
            'Get the most out of your mobile broadband with a router. With a router, you can share the network with a wireless WiFi network to various devices, such as computers, tablets and a TV. Choose a 4G router for a 4G subscription and a 5G router for a 5G subscription:'
          )}
        </p>
        <PromotionBlockContainer
          eCommerceEvents={{
            onClickListProduct: (type, position, omhs) => dispatch(eCommerceClickEvent(type, position, omhs)),
            onSeeListProduct: (type, position, omhs) => dispatch(eCommerceImpressionEvent(type, position, omhs)),
          }}
          onAnalyticsEvent={() => {
            /* NOOP, we don't need this */
          }}
          // @ts-ignore
          onlineModelHeaders={onlineModelHeaders[OnlineModelCategory.NETWORK_EQUIPMENT] || []}
          promotions={ROUTER_PRODUCT_ONLINE_MODEL_CODES.map(onlineModelCode => ({
            productGuid: onlineModelCode,
            type: 'small',
          }))}
          title=""
        />
        <Link
          className={`${DS_LINK} ${DS_LINK_STYLE_LINK_BUTTON} ${DS_LINK_BUTTON_COLOR_LIGHT} ${DS_LINK_BUTTON_SIZE_L} ${DS_MARGIN_TOP_6}`}
          to={paths.ROUTERS}
        >
          {t.SDU3('See all routers')}
        </Link>
      </div>
    </section>
  );
};
