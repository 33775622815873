import * as RP from '../../legacy/react-patterns/source/index.js';
import * as React from 'react';
import { Anchor } from '../Anchor/Anchor.js';
import { BreadCrumbs } from '../BreadCrumbs/index.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { HTML_TAG_REGEX } from '../../common/utils/validationUtils.js';
import { Loading } from '../Loading/index.js';
import { Multiselector } from '../Multiselector/index.js';
import { VirtualCatalogConfiguration } from './VirtualCatalogConfiguration.js';
import { VirtualCatalogDraftOptions } from './VirtualCatalogDraftOptions.js';
import { VirtualCatalogManageButtonGroup } from './VirtualCatalogManageButtonGroup.js';
import { VirtualCatalogProductsSummary } from '../CatalogProductsSummary/VirtualCatalogProductSummary.js';
import { VirtualCatalogPublishedDetails } from './VirtualCatalogPublishedDetails.js';
import {
  catalogCorporateMessageExampleLinkMsg,
  catalogCorporateMessageHelpMsg,
  catalogCorporateMessageInstructionMsg,
  catalogCorporateMessageLabelMsg,
  catalogCorporateMessageSelectorMsg,
  deviceListsMsg,
  listOfDevicesMsg,
  omaElisaForCompaniesMsg,
  t,
} from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { resetErrors, setEditingVirtualCatalog, showDialog } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import type { BillChannel, Catalog, Contact, VirtualCatalog } from '../../generated/api/models.js';
import type { BillingAccountsState, CompanyInfoState } from '../../common/types/states.js';
import type { CatalogProduct } from '../../common/utils/catalogUtils.js';
import type { CommonError } from '../../common/types/errors.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { InProgressCatalogAction } from '../../common/enums.js';

import './VirtualCatalogDetails.scss';

export interface VirtualCatalogDetailsProps {
  virtualCatalog: VirtualCatalog;
  catalog: Catalog;
  products: Record<string, Array<CatalogProduct>>;
  productsPublished?: Record<string, Array<CatalogProduct>>;
  companyName: string;
  companyInfo: CompanyInfoState;
  contacts?: Contact[];
  onAddMoreProducts: () => void;
  inProgressAction?: InProgressCatalogAction;
  billChannels?: BillChannel[];
  billingAccounts?: BillingAccountsState;
  errors?: CommonError[];
  isEditingCatalog: boolean;
  replaceCatalogs: boolean;
  accountMasterId?: string;
}

export const CorporateMessageElement = (corporateMessageProps: {
  isCorporateMessageAdded: boolean | undefined;
  corporateMessage: string | undefined;
  inputClassNames: string;
  onShowDialog: () => void;
  onUpdate: (messageSelected: boolean, message: string | undefined) => void;
  getError: () => string | undefined;
  onBlur: () => void;
  onFocus: () => void;
}): JSX.Element => {
  return (
    <div className="of-catalog-configuration--item" id="corporateMessage">
      <h4 className="ea-h4">{t.C8DA(catalogCorporateMessageLabelMsg)}</h4>
      <p>
        {t.Q57Q(catalogCorporateMessageInstructionMsg)}{' '}
        <Anchor onClick={corporateMessageProps.onShowDialog}>{`${t.ZU62(
          catalogCorporateMessageExampleLinkMsg
        )}.`}</Anchor>
      </p>
      <Multiselector
        {...{
          chainCheckboxSelection: false,
          items: [
            {
              displayValue: () => t.FFQZ(catalogCorporateMessageSelectorMsg),
              checked: corporateMessageProps.isCorporateMessageAdded,
            },
          ],
          onItemChange: newItem => {
            corporateMessageProps.onUpdate(!!newItem.checked, newItem.checked ? '' : undefined);
          },
        }}
      />
      <div>
        <RP.Input
          className={corporateMessageProps.inputClassNames}
          elementType="textarea"
          textareaRows={5}
          wide={true}
          type="text"
          helpText={t.HQTV(catalogCorporateMessageHelpMsg)}
          required={true}
          error={corporateMessageProps.getError()}
          tightBottom={true}
          placeholder=""
          name="corporateMessage"
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            if (!HTML_TAG_REGEX.test(e.currentTarget.value)) {
              corporateMessageProps.onUpdate(true, e.currentTarget.value);
            }
          }}
          value={corporateMessageProps.corporateMessage}
          disableAutoStatus={true}
          onBlur={corporateMessageProps.onBlur}
          onFocus={corporateMessageProps.onFocus}
          htmlValidation={true}
          disabled={!corporateMessageProps.isCorporateMessageAdded}
          maxLength={500}
        />
      </div>
    </div>
  );
};

export const VirtualCatalogDetails = ({
  catalog,
  virtualCatalog,
  products,
  productsPublished,
  companyName,
  companyInfo,
  contacts,
  onAddMoreProducts,
  inProgressAction,
  billChannels,
  billingAccounts,
  errors,
  isEditingCatalog,
  replaceCatalogs,
  accountMasterId,
}: VirtualCatalogDetailsProps) => {
  const dispatch = useDispatch();
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));
  const [viewPublished, setViewPublished] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetErrors());
    };
  });

  useEffect(() => {
    dispatch(setEditingVirtualCatalog(false));
  }, [dispatch]);

  const breadCrumbs: JSX.Element = (
    <BreadCrumbs
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.COBB(deviceListsMsg), path: paths.COMPANY_INFO_CATALOGS },
        { name: catalog.name },
      ]}
    />
  );

  const content = (
    <>
      {!isEditingCatalog && (
        <VirtualCatalogDraftOptions
          catalog={catalog}
          virtualCatalog={virtualCatalog}
          viewPublished={viewPublished}
          setViewPublished={setViewPublished}
          inProgressAction={inProgressAction}
        />
      )}

      <VirtualCatalogConfiguration
        billChannels={billChannels}
        billingAccounts={billingAccounts}
        catalog={catalog}
        companyInfo={companyInfo}
        contacts={contacts}
        isEditingCatalog={isEditingCatalog}
        errors={errors}
        inProgressAction={inProgressAction}
        virtualCatalog={virtualCatalog}
        onShowDialog={onShowDialog}
      />
      {!isEditingCatalog && (
        <VirtualCatalogManageButtonGroup
          catalog={catalog}
          virtualCatalog={virtualCatalog}
          onShowDialog={onShowDialog}
          onAddMoreProducts={onAddMoreProducts}
          replaceCatalogs={replaceCatalogs}
          accountMasterId={accountMasterId}
        />
      )}

      {!products || catalog.productCategories.some(value => !products[value]) ? (
        // load all category products first and then show product summary
        <Loading />
      ) : (
        !isEditingCatalog && (
          <VirtualCatalogProductsSummary
            {...{
              products,
            }}
          />
        )
      )}
    </>
  );

  if (viewPublished && virtualCatalog.published && productsPublished) {
    const { searchResults = [] } = billingAccounts || {};
    return (
      <VirtualCatalogPublishedDetails
        breadCrumbs={breadCrumbs}
        publishedDate={virtualCatalog.published?.lastModified}
        billingAccount={
          searchResults
            .map(res => res.result)
            .find(ba => ba.billingAccountId === virtualCatalog.published?.billingAccountId)?.billingAccountDisplayId ||
          '-'
        }
        catalog={virtualCatalog.published}
        products={productsPublished}
        companyName={companyName}
        onClickBack={() => setViewPublished(false)}
      />
    );
  } else {
    return (
      <DetailsWrapper
        content={content}
        detailsTop={breadCrumbs}
        id={`catalog-details-${catalog.catalogCode}`}
        classes={[`of-catalog-details`]}
        heading={catalog.name}
        headingBottom={companyName}
        headingTop={t.Z0HF(listOfDevicesMsg)}
        heroPicto="billing-account"
      />
    );
  }
};
