import { surveyData } from './data.js';
import type { LoaderFunctionArgs } from 'react-router-dom';

export const surveyLoader = async ({ params }: LoaderFunctionArgs) => {
  const slug = params.surveySlug;
  if (!slug) {
    throw new Response('Not found', { status: 404 });
  }
  if (slug === 'tietoturva-kysely') {
    return surveyData;
  }
  throw new Response('Not found', { status: 404 });
};
