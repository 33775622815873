import { AddOnRulesSubscriptionType } from '../enums.js';
import { deepEqual } from '../utils/objectUtils.js';
import { emptyAddOnRulesResponse } from '../utils/addOnRulesUtils.js';
import { fetchAddOnRules } from '../fetch.js';
import { loadSubscriptionAddOnRules, loadSubscriptionAddOnRulesMobilePbx } from '../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import type { AddOnRulesResponse } from '../../generated/api/addOnRulesResponse.js';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { State } from '../../selfservice/common/store.js';

export interface AddOnAssociationResult {
  addOnRulesLoading: boolean;
  associations: AssociationRecord[];
  dependencies: DependencyRecord[];
}

export const useAddOnRules = (loadNettiRules?: boolean): AddOnAssociationResult => {
  const dispatch = useDispatch();
  const addOnRules = useSelector((state: State) => state?.selfservice?.subscriptionAddOnRules, deepEqual);
  const [nettiAddOnRules, setNettiAddOnRules] = useState<AddOnRulesResponse>();
  const [nettiFourGAddOnRules, setNettiFourGAddOnRules] = useState<AddOnRulesResponse>();

  useEffect(() => {
    if (!addOnRules?.associations) {
      dispatch(loadSubscriptionAddOnRules());
    }
    if (!addOnRules?.associationsMobilePbx) {
      dispatch(loadSubscriptionAddOnRulesMobilePbx());
    }
  }, [dispatch, addOnRules]);

  useEffect(() => {
    if (loadNettiRules && !nettiAddOnRules) {
      fetchAddOnRules(AddOnRulesSubscriptionType.ELISA_NETTI)
        .then(res => {
          setNettiAddOnRules(res);
        })
        .catch((_e: Error) => {
          setNettiAddOnRules({ ...emptyAddOnRulesResponse });
        });
    }
  }, [loadNettiRules, nettiAddOnRules]);

  useEffect(() => {
    if (loadNettiRules && !nettiFourGAddOnRules) {
      fetchAddOnRules(AddOnRulesSubscriptionType.ELISA_NETTI_4G)
        .then(res => {
          setNettiFourGAddOnRules(res);
        })
        .catch((_e: Error) => {
          setNettiFourGAddOnRules({ ...emptyAddOnRulesResponse });
        });
    }
  }, [loadNettiRules, nettiFourGAddOnRules]);

  const elisaNettiRulesLoading = loadNettiRules && (!nettiAddOnRules || !nettiFourGAddOnRules);

  const associations: AssociationRecord[] = [
    ...(addOnRules?.associations || []),
    ...(addOnRules?.associationsMobilePbx || []),
    ...((nettiAddOnRules?.associations[0] as AssociationRecord[]) || []),
    ...((nettiFourGAddOnRules?.associations[0] as AssociationRecord[]) || []),
  ];

  const dependencies: DependencyRecord[] = [
    ...(addOnRules?.dependencies || []),
    ...(addOnRules?.dependenciesMobilePbx || []),
    ...((nettiAddOnRules?.dependencies[0] as DependencyRecord[]) || []),
    ...((nettiFourGAddOnRules?.dependencies[0] as DependencyRecord[]) || []),
  ];

  return {
    addOnRulesLoading: elisaNettiRulesLoading || !addOnRules?.associations || !addOnRules.associationsMobilePbx,
    associations,
    dependencies,
  };
};
