import { DEFAULT_ITEMS_PER_PAGE, ListPagination, ListSearch, Table } from '../../../Table/index.js';
import { GridWide } from '../../../Grid/Grid.js';
import { Link, generatePath } from 'react-router-dom';
import { formatTimeToYYYYMMDDHHmm } from '../../../../common/utils/dateUtils.js';
import { getArrayPropertySorter } from '../../../../common/utils/arrayUtils.js';
import { getDnsRecordActionMessage } from '../../dnsManagementUtils.js';
import { identifierMsg, t, topicMsg } from '../../../../common/i18n/index.js';
import { paths } from '../../../../common/constants/pathVariables.js';
import { resetPagingParams, setDefaultItemsPerPage } from '../../../Table/tableUtils.js';
import { tableSearchRow } from '../../../Table/TableDataUtil.js';
import { useSearchParams } from '../../../../common/hooks/useSearchParams.js';
import type * as CL from '@design-system/component-library';
import type { DnsRecordsHistoryResponse } from '../../../../generated/api/models.js';
import type { DnsRecordsSearchParams } from '../../dnsManagementUtils.js';

import './DnsHistoryList.scss';

export const DnsHistoryList = ({
  historyData: { nextCursor, prevCursor, records, total },
  subscriptionId,
}: {
  historyData: DnsRecordsHistoryResponse;
  subscriptionId: string;
}) => {
  const searchParams = useSearchParams<DnsRecordsSearchParams>();
  const { sort, search, order } = searchParams;

  const columns = [
    { key: 'name', label: t.L9QG(identifierMsg), sortable: false },
    { key: 'type', label: t.F9G1('Type'), sortable: false },
    { key: 'action', label: t.X5AB(topicMsg), sortable: true },
    { key: 'id', label: t.Q1DM('Time of edit'), sortable: true }, // Use `id` instead of `created` for sorting
    { key: 'author', label: t.AD92('Editor'), sortable: true },
  ] satisfies CL.Column[];

  const rows = records
    .map(row => ({
      id: row.id,
      name: row.name,
      type: row.type,
      action: row.action,
      created: row.created,
      author: row.author,
    }))
    .filter(row => tableSearchRow(search, row))
    .sort(getArrayPropertySorter(sort, order))
    .map(row => ({
      name: (
        <Link to={generatePath(paths.PS_ELISA_DNS_DOMAIN_HISTORY_ITEM, { subscriptionId, historyId: row.id })}>
          {row.name || '-'}
        </Link>
      ),
      type: row.type,
      action: getDnsRecordActionMessage(row.action),
      id: formatTimeToYYYYMMDDHHmm(row.created),
      author: row.author,
    })) satisfies CL.KeyRow[];

  return (
    <GridWide>
      <div className="of-dns-history-list ds-margin-vertical--3">
        <ListSearch
          onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
        />
        <Table
          columns={columns}
          noItemsText={t.VA1O('No records')}
          onSortChange={() => resetPagingParams(new URLSearchParams(searchParams), true)}
          rows={rows}
        />
        <ListPagination totalItems={total} cursorNavigation={true} prevCursor={prevCursor} nextCursor={nextCursor} />
      </div>
    </GridWide>
  );
};
