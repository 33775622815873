import * as CL from '@design-system/component-library';
import { DeviceChangeOption } from '../../../common/enums.js';
import { FormProvider, useForm } from 'react-hook-form';
import {
  PersonalBillingDetails,
  createPersonBillingDetailsData,
} from '../../PersonalBillingDetails/PersonalBillingDetails.js';
import { TermsAndConditions } from '../../TermsAndConditions/TermsAndConditions.js';
import { billingDetailsMsg, monthMsg, submitOrderMsg, t } from '../../../common/i18n/index.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { findPersonBillingAccount } from '../../../common/utils/stateUtils.js';
import { formatSum } from '../../../common/utils/priceUtils.js';
import { getConfiguredCommercialProducts } from '../../../common/utils/cartProductUtils.js';
import { getFormattedContacts, showEppDeviceChangeStep } from '../../../common/utils/checkoutUtils.js';
import { getPriceWithTax, getVatAsString } from '../../../common/utils/taxUtils.js';
import { getTotalSums } from '../../../common/utils/commercialProductUtils.js';
import { useAuth } from '../../../public/site/AuthProvider.js';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import type { AuthenticatedUserState } from '../../../common/types/states.js';
import type { CheckoutStepItem } from '../CheckoutSteps.js';
import type { DeviceChangeRequest } from '../../../common/types/device.js';
import type { DeviceCheckoutDeliveryDetailsType } from '../../DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';
import type { PersonalBillingDetailsData } from '../../PersonalBillingDetails/PersonalBillingDetails.js';
import type { ShoppingCartItemForCheckout, SubmitOrderProps } from '../../../common/types/checkout.js';
import type { State } from '../../../selfservice/common/store.js';

import './EmployeeUserPersonalBillingStep.scss';

interface EmployeeUserPersonalBillingStepProps {
  deviceChangeRequest?: DeviceChangeRequest;
  submitOrder: (submitOrderProps: SubmitOrderProps) => void;
  deliveryDetails?: DeviceCheckoutDeliveryDetailsType;
}

// Using prefixes allows taking react-hook-forms into use for other parts of
// this step when so desired. The prefixes must match the properties in the form
// data represented by the interface EmployeeUserPersonalBillingStepFormData.
const personBillingDetailsDataFieldPrefix = 'personBillingDetailsData.';

export interface EmployeeUserPersonalBillingStepFormData {
  personBillingDetailsData: PersonalBillingDetailsData;
}

const totalSums = (totalCartItems: Array<ShoppingCartItemForCheckout>) =>
  getTotalSums(getConfiguredCommercialProducts(totalCartItems));

export const EmployeeUserPersonalBillingStep = ({
  deviceChangeRequest,
  submitOrder,
  deliveryDetails,
}: EmployeeUserPersonalBillingStepProps) => {
  const [contractTermsAccepted, setContractTermsAccepted] = useState<boolean>();
  const { authenticatedUser } = useAuth();

  const { cartItems, billingAccounts, isOrderInProgress, contacts } = useSelector(
    (state: State) => ({
      cartItems: state.deviceCheckout?.cartItems || [],
      billingAccounts: state.selfservice?.billingAccounts || undefined,
      isOrderInProgress: Boolean(state.selfservice?.onlineOrders?.saving),
      contacts: state.selfservice?.contacts?.items,
    }),
    deepEqual
  );

  // there can be only 1 person account per user (at least for now), confirmed by SFDC
  const personBillingAccount = findPersonBillingAccount(billingAccounts);
  const formattedContacts = getFormattedContacts(contacts, authenticatedUser);

  const personBillingDetailsData = createPersonBillingDetailsData(
    authenticatedUser as AuthenticatedUserState,
    personBillingAccount
  );

  const methods = useForm<EmployeeUserPersonalBillingStepFormData>({
    values: {
      personBillingDetailsData,
    },
  });

  const submitOnContractTermsNotAccepted = () => setContractTermsAccepted(false);
  const submitOnContractTermsAccepted = (values: EmployeeUserPersonalBillingStepFormData) => {
    submitOrder({
      formattedContactsOptions: formattedContacts?.options,
      personBillingAccountId: personBillingAccount ? personBillingAccount.billingAccountId : undefined,
      personBillingAddress: values.personBillingDetailsData.personalBillingAddress,
      personBillingEmail: values.personBillingDetailsData.email,
      pickupPoint: deliveryDetails?.pickupPoint,
    });
  };

  const onSubmit = (values: EmployeeUserPersonalBillingStepFormData) =>
    contractTermsAccepted ? submitOnContractTermsAccepted(values) : submitOnContractTermsNotAccepted();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="of-employee-user-personal-billing">
          <div className="of-employee-user-personal-billing__disclaimer">
            {t.NQ5G(
              `Please note you have chosen a device which is more expensive than your employer's standard policy. Excess charges are to be paid monthly by you.`
            )}
          </div>
          {!deviceChangeRequest && (
            <div className="of-employee-user-personal-billing__monthly_employee_part">
              <strong>{t.FGE3('Your part of the monthly charge')}</strong>
              <br />
              {formatSum(totalSums(cartItems).totalMonthlyRecurringCharge)}/kk
            </div>
          )}
          {deviceChangeRequest?.deviceChangeOption === DeviceChangeOption.RETURN && (
            <>
              <div className="of-employee-user-personal-billing__return_instructions">
                <strong>{t.RW72('Old device must be returned to Elisa within 2 weeks')}</strong>{' '}
                {t.FVG8(
                  'New device is sent together with return instructions and returnbag for the old device. A  charge will apply if device is not returned in time.'
                )}
              </div>
              <div className="of-employee-user-personal-billing__no-changes-after-confirmation">
                <strong>{t.HRG7('Please note,')}</strong>
                {t.CLAY(
                  ' that once the order is confirmed, you are no longer able to change your mind and redeem your old device.'
                )}
              </div>
            </>
          )}
          {deviceChangeRequest && (
            <div className="of-employee-user-personal-billing__device_employee_part">
              <h4 className="ds-margin--0 ds-padding-bottom--1">
                {t.JFNL('Your share of the monthly charge for the new device')}
              </h4>
              <div>
                {formatSum(totalSums(cartItems).totalMonthlyRecurringCharge)}/{t.XXVX(monthMsg)}
              </div>
            </div>
          )}
          {deviceChangeRequest?.deviceChangeOption === DeviceChangeOption.REDEEM &&
            deviceChangeRequest?.replacedSubscriptionId && (
              <div className="of-employee-user-personal-billing__redemption-price">
                <h4 className="ds-margin--0 ds-padding-bottom--1">{t.ZNGS('Redemption price of the old device')}</h4>
                <div>
                  {deviceChangeRequest &&
                    deviceChangeRequest.deviceChangeRedeemPrice &&
                    `${formatSum(getPriceWithTax(deviceChangeRequest.deviceChangeRedeemPrice))} (${t.U8AN(
                      'incl.'
                    )} ${t.UZ6X('VAT')} ${getVatAsString()}%)`}
                </div>
              </div>
            )}
          <PersonalBillingDetails
            title={showEppDeviceChangeStep(cartItems, authenticatedUser) ? t.RPMR(billingDetailsMsg) : undefined}
            editing={(billingAccounts?.items && personBillingAccount === undefined) ?? false}
            data={personBillingDetailsData}
            fieldPrefix={personBillingDetailsDataFieldPrefix}
            enableEditing={true}
          />
          <>
            <TermsAndConditions
              onAcceptance={() => setContractTermsAccepted(!contractTermsAccepted)}
              isAccepted={contractTermsAccepted}
            />
            <CL.Button
              type="submit"
              size="l"
              className="of-confirm-button"
              disabled={!billingAccounts || isOrderInProgress}
            >
              {t.RZU4(submitOrderMsg)}
            </CL.Button>
          </>
        </div>
      </form>
    </FormProvider>
  );
};

export const employeeUserPersonalBillingStep = (props: EmployeeUserPersonalBillingStepProps): CheckoutStepItem => ({
  id: 'employeeUserPersonalBillingStep',
  content: <EmployeeUserPersonalBillingStep {...props} />,
  name: `${t.EY7T('Billing details and confirmation')}`,
});
