import { CURRENCY_CODE_EUR, EcommerceEventTypeV4 } from '../../common/analytics.js';
import { DEFAULT_ITEM_BRAND, PaymentTypeEnum } from '../../selfservice/common/googleEcommerceEvent.js';
import { TurbonappiOffer } from '../../generated/api/turbonappiOffer.js';
import { centsToEuros } from '../../common/utils/priceUtils.js';
import { getTurbonappiSubscriptionCard } from './turbonappiSubscriptionCardData.js';
import type {
  EcommerceAddToCartEventV4,
  EcommerceBeginCheckoutEventV4,
  EcommerceItem,
  EcommercePurchaseEventV4,
  EcommerceSelectItemEventV4,
  EcommerceViewItemListEventV4,
} from '../../common/analytics.js';
import type { SubscriptionCardProps } from '../SubscriptionCard/SubscriptionCard.js';
import type { TurbonappiOrder } from '../../generated/api/turbonappiOrder.js';

export const TURBONAPPI_ANALYTICS_CATEGORY = 'Turbonappi';

const getTurbonappiCardPosition = (offer: TurbonappiOffer): number => {
  switch (offer) {
    case TurbonappiOffer.DAY:
      return 0;
    case TurbonappiOffer.WEEK:
      return 1;
    case TurbonappiOffer.MONTH:
      return 2;
    default:
      throw new Error(`Unknown offer: ${offer}`);
  }
};

const getEcommerceItemFromTurbonappiProduct = (
  turbonappiOffer?: TurbonappiOffer,
  turbonappiSubscription?: SubscriptionCardProps
): EcommerceItem => {
  const subscription = turbonappiSubscription || getTurbonappiSubscriptionCard(turbonappiOffer);
  const offer = turbonappiOffer || turbonappiSubscription?.turbonappiOffer;
  // Since this function is only used with Turbonappi cards, we can safely use the non-null assertion operator (!) with
  // Turbonappi offers. TurbonappiOffer is optional property in SubscriptionCard, but always present in Turbonappi cards.
  return {
    item_id: offer!, // eslint-disable-line @typescript-eslint/naming-convention
    item_name: subscription.name as string, // eslint-disable-line @typescript-eslint/naming-convention
    item_category: TURBONAPPI_ANALYTICS_CATEGORY, // eslint-disable-line @typescript-eslint/naming-convention
    item_brand: DEFAULT_ITEM_BRAND, // eslint-disable-line @typescript-eslint/naming-convention
    item_list_name: 'Elisa Yrityksille turbonappi', // eslint-disable-line @typescript-eslint/naming-convention
    quantity: 1,
    price: Number.parseFloat(subscription.turbonappiPrice!.replace(/,/g, '.')), // eslint-disable-line @typescript-eslint/naming-convention
    index: getTurbonappiCardPosition(offer!),
  };
};

export const getViewItemListAnalyticsEvent = (
  subscriptions: SubscriptionCardProps[]
): EcommerceViewItemListEventV4 => ({
  event: EcommerceEventTypeV4.VIEW_ITEM_LIST,
  ecommerce: {
    items: subscriptions.map(sub => getEcommerceItemFromTurbonappiProduct(undefined, sub)),
  },
});

export const getTurbonappiSelectItemEvent = (turbonappiOffer?: TurbonappiOffer): EcommerceSelectItemEventV4 => ({
  event: EcommerceEventTypeV4.SELECT_ITEM,
  ecommerce: {
    items: [getEcommerceItemFromTurbonappiProduct(turbonappiOffer)],
  },
});

export const getTurbonappiAddToCartEvent = (turbonappiOffer?: TurbonappiOffer): EcommerceAddToCartEventV4 => ({
  event: EcommerceEventTypeV4.ADD_TO_CART,
  ecommerce: {
    currency: CURRENCY_CODE_EUR,
    items: [getEcommerceItemFromTurbonappiProduct(turbonappiOffer)],
  },
});

export const getTurbonappiCheckoutEvent = (turbonappiOffer?: TurbonappiOffer): EcommerceBeginCheckoutEventV4 => ({
  event: EcommerceEventTypeV4.BEGIN_CHECKOUT,
  ecommerce: {
    items: [getEcommerceItemFromTurbonappiProduct(turbonappiOffer)],
  },
});

export const getTurbonappiPurchaseEvent = (turbonappiOrder?: TurbonappiOrder): EcommercePurchaseEventV4 => ({
  event: EcommerceEventTypeV4.PURCHASE,
  payment_type: PaymentTypeEnum.CREDIT_CARD, // eslint-disable-line @typescript-eslint/naming-convention
  ecommerce: {
    currency: CURRENCY_CODE_EUR,
    items: [getEcommerceItemFromTurbonappiProduct(turbonappiOrder?.offer)],
    shipping: 0,
    tax: 0,
    transaction_id: turbonappiOrder?.orderNumber || '', // eslint-disable-line @typescript-eslint/naming-convention
    value: turbonappiOrder?.price ? centsToEuros(turbonappiOrder.price) : 0,
  },
});
