import * as CL from '@design-system/component-library';
import { Modal } from './Modal.js';
import { ModalButtons } from './ModalButtons.js';
import { cancelMsg, confirmMsg, t } from '../../common/i18n/index.js';
import type { ReactNode } from 'react';

interface ConfirmationModalProps {
  heading: string;
  children: ReactNode;
  confirmText?: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { children, confirmText, heading, onCancel, onConfirm } = props;

  return (
    <Modal closeable heading={heading} onClose={onCancel}>
      {children}
      <ModalButtons>
        <CL.Button color="link" key="btnCancel" onClick={onCancel}>
          {t.B2V1(cancelMsg)}
        </CL.Button>
        <CL.Button color="primary" key="btnConfirm" onClick={onConfirm}>
          {confirmText ?? t.QVYK(confirmMsg)}
        </CL.Button>
      </ModalButtons>
    </Modal>
  );
};
