import * as CL from '@design-system/component-library';
import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { EmployeeSupportOrServiceHeader } from './EmployeeSupportOrServiceHeader.js';
import { continueMsg, t } from '../../common/i18n/index.js';
import { useState } from 'react';

import './EppSupportOrServiceAnnouncement.scss';

export interface EppSupportOrServiceAnnouncementProps {
  onCancel: () => void;
  onNext: () => void;
  isEmployee: boolean;
}

const AnnouncementTexts = (): JSX.Element => (
  <>
    <div>
      {t.JWWE(
        `If the device is defective or damaged (for example, the screen is broken or scratched), we will replace the damaged device with a replacement one. You will receive instructions for returning the old device with the replacement device.`
      )}
    </div>
    <hr />
    <div>
      <p className="of-epp-maintenance__service-announcement-header">
        {t.JCT8('Before filing a service request, check and do the following:')}
      </p>
      <p>{t.OTZV('If the device screen is damaged or the touch screen is not working')}</p>
      <ul>
        <li>
          {t.DPT5(
            'Remove any possible screen protector and inspect whether the screen is broken beneath. Test whether touch screen continues to have issues after removing the screen protector.'
          )}
        </li>
      </ul>
      <p>{t.GFZQ('If the device will not charge or turn on')}</p>
      <ul>
        <li>
          {t.D467(
            'Dirt and dust collect into the device charging port and may cause recharging issues. Inspect the device charging port and clean if necessary (e.g. with a toothpick).'
          )}
        </li>
        <li>{t.IF4R('Inspect the functionality of the cable and power supply.')}</li>
        <li>
          {t.H9V6(
            'Recharge the battery with a functioning charger for at least 30 minutes. Try switching the device on again after charging. When the battery level of the device is very low, the charging may start with a delay.'
          )}
        </li>
      </ul>
      <p>{t.RVRZ('If the device sound is not working or is distorted')}</p>
      <ul>
        <li>
          {t.TM9L(
            'Phone speakers collect dirt over time, which may cause that the sounds on the phone are distorted. Receivers and speakers can be easily cleaned by using tape, Blu-Tack, small brush, microfiber cloth, compressed air bottle, or toothpick. Liquid suitable for cleaning mobile devices can also be used.'
          )}
        </li>
      </ul>
    </div>
    <div className="ds-padding-top--4">
      {t.F4L3('Before ordering the replacement device, ')}
      <b>{t.QGQK('delete the Find my iPhone-functionality from the Apple iPhone or iPad-device. ')}</b>
      {t.ASTA('Instructions can be found from ')}{' '}
      <a href="https://support.apple.com/fi-fi/guide/icloud/mmfc0eeddd/icloud"> {t.FOAX(`Apple's support page.`)}</a>
    </div>
  </>
);

const ReadConfirmation = ({
  onReadConfirmed,
  readConfirmed,
}: {
  onReadConfirmed: (confirmed: boolean) => void;
  readConfirmed: boolean;
}) => (
  <div className="ds-padding-top--5">
    <CL.Checkbox className="ds-color--blue" checked={readConfirmed} onChange={() => onReadConfirmed(!readConfirmed)}>
      {t.LZF8('I have read the maintenance request instructions')}
    </CL.Checkbox>
  </div>
);

export const EppSupportOrServiceAnnouncement = ({
  isEmployee,
  onNext,
  onCancel,
}: EppSupportOrServiceAnnouncementProps): JSX.Element => {
  const [readConfirmed, setReadConfirmed] = useState(false);

  return (
    <div className="of-epp-maintenance__service-announcement">
      {isEmployee && <EmployeeSupportOrServiceHeader />}
      <AnnouncementTexts />
      <ReadConfirmation onReadConfirmed={setReadConfirmed} readConfirmed={readConfirmed} />
      <hr />
      <ButtonGroupForSubmitAndBack
        className="of-epp-maintenance__service-announcement-buttons"
        onSubmit={onNext}
        onCancel={onCancel}
        submitButtonText={t.I62A(continueMsg)}
        submitDisabled={!readConfirmed}
      />
    </div>
  );
};
