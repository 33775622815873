import { ReportType } from '../../components/Reports/common/reportsUtil.js';
import { TypeKeys } from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import {
  getEppServiceAndRecyclementReportPrivateMethod,
  getRedeemedDevicesReportPrivateMethod,
} from '../../generated/api/uiApiMethods.js';
import { loadEppReportFailed, loadEppReportFulfilled } from '../actions/reportsActions.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { AjaxResponse } from 'rxjs/ajax';
import type { LoadEppReportAction } from '../actions/reportsActions.js';
import type { SelfServiceActionTypes } from '../actions/index.js';
import type { State } from '../common/store.js';

const getReportMethod = (reportType: ReportType) => {
  switch (reportType) {
    case ReportType.EPP_SERVICE_REPORT:
      return getEppServiceAndRecyclementReportPrivateMethod();
    case ReportType.EPP_REDEEM_REPORT:
      return getRedeemedDevicesReportPrivateMethod();
  }
};

export const loadEppReportEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOAD_EPP_REPORT)), (action: LoadEppReportAction) =>
    actionToActionState(action, state$, 'reports')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const action = actionAndState.action as LoadEppReportAction;

      return callUiApi({
        epicDependencies,
        state$,
        method: getReportMethod(action.reportType),
        successAction: (ajaxResponse: AjaxResponse) =>
          loadEppReportFulfilled(ajaxResponse.xhr.responseText, action.reportType),
        failureAction: loadEppReportFailed,
        successActionGetAjaxResponse: true,
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Accept: 'application/xml',
        },
      });
    })
  );

export const reportsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> =
  combineEpics(loadEppReportEpic);
