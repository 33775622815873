import { SiteContext } from '../../../public/site/SiteContext.js';
import {
  entertainmentBarringP3Msg,
  premiumRateBarringP4Msg,
  serviceBarringP2Msg,
  t,
  viewBlockedNumbersMsg,
} from '../../../common/i18n/index.js';
import { useContext } from 'react';

export const CallBarringsTooltipBody = () => (
  <>
    <div>
      <h4 className="ea-h4 ea-h4--no-fluid">{t.P0CE(premiumRateBarringP4Msg)}</h4>
      {t.GJLT('Blocks calls to adult entertainment services.')}
    </div>
    <div className="ds-padding-top--4">
      <h4 className="ea-h4 ea-h4--no-fluid">{t.P111(entertainmentBarringP3Msg)}</h4>
      {t.UOYP(
        'Blocks calls, e.g. by blocking calls to various chat lines and marketing campaigns, as well as participation in phone voting and TV quizzes. Includes blocking of calls to entertainment numbers.'
      )}
    </div>
    <div className="ds-padding-top--4">
      <h4 className="ea-h4 ea-h4--no-fluid">{t.YX3Y(serviceBarringP2Msg)}</h4>
      {t.VMNS(
        'Blocks calls e.g. to customer service numbers of airlines and shipping companies, and participation in opinion polls and phone donations. Also blocks the use of ordering services (e.g. food or drink vending machines), as well as pizza and ticket orders. Includes a block on entertainment and adult entertainment numbers.'
      )}
    </div>
    <div className="ds-padding-top--4">
      <h4 className="ea-h4 ea-h4--no-fluid">{t.OMO5('General service barring P1')}</h4>
      {t.VRMK(
        'Blocks calls to e.g. timetable, information and banking services. Also blocks calls to taxi centers and directory enquiries, as well as use of the related connection services. Includes blocking of adult entertainment, entertainment and service numbers.'
      )}
    </div>
    <div className="ds-padding-vertical--4">
      <a
        className="of-barrings-content__link"
        href={`${useContext(SiteContext).siteBaseUrl}/ohje/lisamaksulliset-palvelut-ja-estoluokat`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t.FVU9(viewBlockedNumbersMsg)}
        <i className="ea-icon ea-icon--northeast-arrow" />
      </a>
    </div>
  </>
);
