import type { SearchFilter } from './GlobalSearch.js';

export interface CategoryFilterProps {
  filter: SearchFilter;
  setSelected: (name: string, selected: boolean) => void;
}

export const SearchResultTypeFilter = ({ filter, setSelected }: CategoryFilterProps) => {
  const { name, total, selected } = filter;
  return (
    <button
      onClick={() => setSelected(name, !selected)}
      className={`category-filter ds-text--s ${selected ? 'selected' : ''}`}
    >
      {name} <div className="of-omaelisa-search--filter-count">{total}</div>
    </button>
  );
};
