import * as CL from '@design-system/component-library';
import * as React from 'react';
import type { ActionBlockData } from '../cmsSchema.js';

import './ActionBlock.scss';

// FIXME anchor inside button? Really?
const ActionBlockWithButton = ({ title, label, additionalInformation }: ActionBlockData) => (
  <>
    <h4 className="ds-display--block of-action-block-book of-action-block-title">{title}</h4>
    <button className="ds-font-size--medium ds-button ds-button--color-light ds-button__content--starts_with_icon ds-link--button-size-l">
      <CL.Icon icon="chat" size="xl" color="blue-600--light" aria-hidden="true" />
      <a className="action-block-book-link" href={`${additionalInformation}`} rel="noreferrer" target="_blank">
        {label}
      </a>
    </button>
  </>
);

const ActionBlockWithCall = ({ title, label, additionalInformation, additionalInformationLink }: ActionBlockData) => (
  <div className="of-action-block-call">
    <CL.Icon icon="phone" size="l" color="blue-600--light" aria-hidden="true" />
    <h4 className="ds-h4 ds-margin--2">{title}</h4>
    <a href={`tel:${label}`}>{label}</a>
    <div className="ds-margin--1 ds-description ds-font-size--small action-block-description">
      {additionalInformation}
      {additionalInformationLink && (
        <a
          href={additionalInformationLink}
          id="additional-information-link"
          target="_blank"
          rel="noreferrer"
        >{` ${additionalInformationLink}.`}</a>
      )}
    </div>
  </div>
);

export const ActionBlock = (props: ActionBlockData): React.ReactElement => {
  const render = () => {
    switch (props.type) {
      case 'book':
      case 'contact':
        return <ActionBlockWithButton {...props} />;
      case 'call':
        return <ActionBlockWithCall {...props} />;
      default:
        return <></>;
    }
  };

  return <li className="of-action-block">{render()}</li>;
};
