import type { FieldInputProps, FormikHelpers, FormikState, FormikValues } from 'formik';

export interface InlineErrorComponentProps {
  field: FieldInputProps<string>;
  form: FormikState<FormikValues> & FormikHelpers<FormikValues>;
}

export const InlineErrorComponent = ({ field, form }: InlineErrorComponentProps) => {
  const { name } = field;
  const { errors, touched } = form;
  return errors[name] && touched[name] ? (
    <div className="of-formik-invalid">
      <span className="ds-inputerror">{errors[name]?.toString()}</span>
    </div>
  ) : null;
};
