import {
  dataPackageMsg,
  formatNumber,
  gbMsg,
  monthMsg,
  noDataPackageMsg,
  noSmsPackageMsg,
  smsPriceNordicBalticMsg,
  t,
  unlimitedInternetInFinlandNordicBalticAndRestOfEuMsg,
} from '../../common/i18n/index.js';
import { formatNumberToCurrency, formatSum } from '../../common/utils/priceUtils.js';
import type { Offer } from '../../generated/api/offer.js';
import type { OnlineModel } from '../../generated/api/onlineModel.js';
import type { SubscriptionAddOnRulesState } from '../../common/types/states.js';

export interface MinuteSubscription {
  offer?: string;
  dataPackage?: string;
  dataRoaming?: string;
  smsPackage?: string;
}

export enum MinuteOfferType {
  VoicePerus4g2M = '2f7ded75-6808-57f8-eee3-3de8006a4111',
  VoicePerus4g20M = '5b419842-f909-4dbb-a831-a1149336f976',
  VoiceYrityspuhe = 'a53592b6-1995-97ae-42ee-2599aefecdb9',
  VoiceYrityspuheEU = '579dc4c1-211a-4256-1639-373bb5c6009d',
}

enum CommercialProductType {
  VoicePerus4g2M = '66cac6c7-05c2-776d-f299-d90ab795733f',
  VoicePerus4g20M = 'd676d215-859f-0346-5491-3291fd14c366',
  VoiceYrityspuhe = 'bfed72a7-97e5-1b1c-4f59-7d90104db868',
  VoiceYrityspuheEU = 'a0eb3059-5bb1-b10e-de66-a3fa83522ce5',
}

export const MINUTE_OFFER_DISCLAIMERS = () => [
  {
    offerCode: MinuteOfferType.VoicePerus4g2M,
    cpCode: CommercialProductType.VoicePerus4g2M,
    disclaimer: t.SOXH(
      'Does not include roaming features, works only in Finland. Normal priced domestic calls €0.0565/min, SMS €0.0565/message, multimedia messages €0.156/message.'
    ),
  },
  {
    offerCode: MinuteOfferType.VoicePerus4g20M,
    cpCode: CommercialProductType.VoicePerus4g20M,
    disclaimer: t.SOXH(
      'Does not include roaming features, works only in Finland. Normal priced domestic calls €0.0565/min, SMS €0.0565/message, multimedia messages €0.156/message.'
    ),
  },
  {
    offerCode: MinuteOfferType.VoiceYrityspuheEU,
    cpCode: CommercialProductType.VoiceYrityspuheEU,
    disclaimer: t.PA84(
      'Includes 5,000 min/month of calls to domestic subscriptions. Nine data packages to choose from.'
    ),
  },
  {
    offerCode: MinuteOfferType.VoiceYrityspuhe,
    cpCode: CommercialProductType.VoiceYrityspuhe,
    disclaimer: t.OL6X(
      'Does not include roaming features, works only in Finland. Four data packages to choose from. Normal priced domestic calls €0.0565/min, SMS €0.0565/message, multimedia messages €0.156/message.'
    ),
  },
];

const generateDisplayName = (theOffer: Offer): string => {
  const cpCode = MINUTE_OFFER_DISCLAIMERS().find(it => it.offerCode === theOffer.offerCode)?.cpCode;
  const product = theOffer.commercialProducts.find(it => it.commercialProductCode === cpCode);

  return product
    ? theOffer.offerName + ' (' + formatSum(product.monthlyRecurringCharge) + '/' + t.XXVX(monthMsg) + ')'
    : '';
};

export const getMinuteBasedSubscriptionItems = (minuteModels?: OnlineModel[]) => {
  const minuteOffers = minuteModels?.flatMap(m => m.offers) ?? [];

  return minuteOffers
    .filter(it => Object.values(MinuteOfferType).includes(it.offerCode as MinuteOfferType))
    .map(offer => ({
      label: generateDisplayName(offer),
      value: offer.offerCode,
    }));
};

const getMonthlyPriceForAddOn = (
  addOnCode: string,
  selectedOffer: string,
  selectedCommercialProduct: string,
  addOnRules: SubscriptionAddOnRulesState | null | undefined
): string => {
  if (selectedOffer && addOnRules?.associations) {
    const addOnPriceItem = 'cspmb__add_on_price_item__r';
    const guid = 'guid__c';
    const priceItem = 'cspmb__price_item__r';
    const recurringCharge = 'cspmb__recurring_charge__c';

    const addOn = addOnRules?.associations?.find(
      aos =>
        aos[addOnPriceItem] &&
        aos[addOnPriceItem][guid] === addOnCode &&
        aos[priceItem] &&
        aos[priceItem][guid] === selectedCommercialProduct
    );

    const addOnRecurringPrice = addOn?.[addOnPriceItem]?.[recurringCharge];
    if (addOnRecurringPrice) {
      return ' (' + formatNumberToCurrency(addOnRecurringPrice) + ' €/' + t.XXVX(monthMsg) + ')';
    }
  }
  return '';
};

export const getDataPackageItemsByOffer = (addOnRules?: SubscriptionAddOnRulesState | null) => ({
  [MinuteOfferType.VoiceYrityspuhe.toString()]: [
    {
      label: t.EN2Q(noDataPackageMsg),
      value: '040a55ae-b8dd-ccea-cd94-ed5dabbdc12f',
      disclaimer: t.R8ND('Data usage (3G 21M) €0.20/MB'),
    },
    {
      label: `${t.VSE5(dataPackageMsg)} 10M 10${t.C4YQ(gbMsg)}${getMonthlyPriceForAddOn(
        'fe13ee6c-f9bf-266d-35a2-ad474ccce922',
        MinuteOfferType.VoiceYrityspuhe,
        CommercialProductType.VoiceYrityspuhe,
        addOnRules
      )}`,
      value: 'fe13ee6c-f9bf-266d-35a2-ad474ccce922',
      disclaimer: t.QKAC('Includes 10GB of data in Finland. Data usage exceeding package, €6.50 for each 10GB.'),
    },
    {
      label: `${t.VSE5(dataPackageMsg)} 10M 5${t.C4YQ(gbMsg)}${getMonthlyPriceForAddOn(
        '8fd857b0-1e39-d746-6d41-10b379dad975',
        MinuteOfferType.VoiceYrityspuhe,
        CommercialProductType.VoiceYrityspuhe,
        addOnRules
      )}`,
      value: '8fd857b0-1e39-d746-6d41-10b379dad975',
      disclaimer: t.WNEF('Includes 5GB of data in Finland. Data usage exceeding package €4.50 for each 5GB.'),
    },
    {
      label: `${t.VSE5(dataPackageMsg)} 10M 0.5${t.C4YQ(gbMsg)}${getMonthlyPriceForAddOn(
        'ec6ea682-c821-80c0-388b-b3a54598f80a',
        MinuteOfferType.VoiceYrityspuhe,
        CommercialProductType.VoiceYrityspuhe,
        addOnRules
      )}`,
      value: 'ec6ea682-c821-80c0-388b-b3a54598f80a',
      disclaimer: t.GIRL('Includes 0.5GB of data usage in Finland. Data usage exceeding package €1.50 for each 0.5GB.'),
    },
    {
      label: `${t.VSE5(dataPackageMsg)} 50M ${t.BUMO('unlimited')}${getMonthlyPriceForAddOn(
        'c0a89287-4265-aebd-a041-211666a01462',
        MinuteOfferType.VoiceYrityspuhe,
        CommercialProductType.VoiceYrityspuhe,
        addOnRules
      )}`,
      value: 'c0a89287-4265-aebd-a041-211666a01462',
      disclaimer: t.XV2A('Unlimited internet in Finland'),
    },
  ],
  [MinuteOfferType.VoiceYrityspuheEU.toString()]: [
    {
      label: t.EN2Q(noDataPackageMsg),
      value: '040a55ae-b8dd-ccea-cd94-ed5dabbdc12f d9a82e19-ba56-7e69-c244-1b8efb4b9766',
      disclaimer: t.R8ND('Data usage (3G 21M) €0.20/MB'),
    },
    {
      label: `${t.VSE5(dataPackageMsg)} EU 20M 0.5${t.C4YQ(gbMsg)}${getMonthlyPriceForAddOn(
        '6119f71f-3d25-df71-75c8-267d92b3cb1a',
        MinuteOfferType.VoiceYrityspuheEU,
        CommercialProductType.VoiceYrityspuheEU,
        addOnRules
      )}`,
      value: '6119f71f-3d25-df71-75c8-267d92b3cb1a d9a82e19-ba56-7e69-c244-1b8efb4b9766',
      disclaimer: t.HPMO(
        'Includes {}GB of data usage in the Nordic & Baltics, and the rest of the EU. Data usage exceeding package €1.50 for each 0.5GB.',
        formatNumber(4.4)
      ),
    },
    {
      label: `${t.VSE5(dataPackageMsg)} EU 20M 5${t.C4YQ(gbMsg)}${getMonthlyPriceForAddOn(
        'fe7b432b-f1aa-5eac-0d00-fc76ad3b6939',
        MinuteOfferType.VoiceYrityspuheEU,
        CommercialProductType.VoiceYrityspuheEU,
        addOnRules
      )}`,
      value: 'fe7b432b-f1aa-5eac-0d00-fc76ad3b6939 d9a82e19-ba56-7e69-c244-1b8efb4b9766',
      disclaimer: t.QXUN(
        'Includes {}GB of data usage in the Nordic & Baltics, and the rest of the EU. Data usage exceeding package €4.50 for each 5GB.',
        formatNumber(11.1)
      ),
    },
    {
      label: `${t.VSE5(dataPackageMsg)} EU 20M 10${t.C4YQ(gbMsg)}${getMonthlyPriceForAddOn(
        'f6634d77-9d6b-7c0a-f0be-6e3e1bd31dce',
        MinuteOfferType.VoiceYrityspuheEU,
        CommercialProductType.VoiceYrityspuheEU,
        addOnRules
      )}`,
      value: 'f6634d77-9d6b-7c0a-f0be-6e3e1bd31dce d9a82e19-ba56-7e69-c244-1b8efb4b9766',
      disclaimer: t.LCCX(
        'Includes {}GB of data usage in the Nordic & Baltics, and the rest of the EU. Data usage exceeding package, €6.50 for each 10GB.',
        formatNumber(13.5)
      ),
    },
    {
      label: `${t.VSE5(dataPackageMsg)} EU 200M${getMonthlyPriceForAddOn(
        'cbd66a33-e7fc-5012-4156-a0eaa9349f94',
        MinuteOfferType.VoiceYrityspuheEU,
        CommercialProductType.VoiceYrityspuheEU,
        addOnRules
      )}`,
      value: 'cbd66a33-e7fc-5012-4156-a0eaa9349f94 838bb36e-2034-2e94-dd7e-739192b92e65',
      disclaimer: t.PZV7(unlimitedInternetInFinlandNordicBalticAndRestOfEuMsg, formatNumber(31)),
    },
    {
      label: `${t.VSE5(dataPackageMsg)} EU 100M${getMonthlyPriceForAddOn(
        '42f8d197-718b-3467-2768-f403002cd1ee',
        MinuteOfferType.VoiceYrityspuheEU,
        CommercialProductType.VoiceYrityspuheEU,
        addOnRules
      )}`,
      value: '42f8d197-718b-3467-2768-f403002cd1ee cf047e4f-2c37-ae6c-66ca-a6cd99fe5f79',
      disclaimer: t.PZV7(unlimitedInternetInFinlandNordicBalticAndRestOfEuMsg, formatNumber(25)),
    },
    {
      label: `${t.VSE5(dataPackageMsg)} EU 50M${getMonthlyPriceForAddOn(
        'a48cde78-f05b-6581-adf5-56d48fbac659',
        MinuteOfferType.VoiceYrityspuheEU,
        CommercialProductType.VoiceYrityspuheEU,
        addOnRules
      )}`,
      value: 'a48cde78-f05b-6581-adf5-56d48fbac659 cf047e4f-2c37-ae6c-66ca-a6cd99fe5f79',
      disclaimer: t.PZV7(unlimitedInternetInFinlandNordicBalticAndRestOfEuMsg, formatNumber(21.5)),
    },
    {
      label: `${t.VSE5(dataPackageMsg)} 5G (1000M)${getMonthlyPriceForAddOn(
        '82604d32-b3cb-b763-29e6-d591b9817dfa',
        MinuteOfferType.VoiceYrityspuheEU,
        CommercialProductType.VoiceYrityspuheEU,
        addOnRules
      )}`,
      value: '82604d32-b3cb-b763-29e6-d591b9817dfa 0a789217-1f65-c3c3-12cd-68e142561bd2',
      disclaimer: t.PZV7(unlimitedInternetInFinlandNordicBalticAndRestOfEuMsg, formatNumber(45)),
    },
    {
      label: `${t.VSE5(dataPackageMsg)} 5G (600M)${getMonthlyPriceForAddOn(
        'dd0fbb3e-6d40-6282-3683-6eba6299bcab',
        MinuteOfferType.VoiceYrityspuheEU,
        CommercialProductType.VoiceYrityspuheEU,
        addOnRules
      )}`,
      value: 'dd0fbb3e-6d40-6282-3683-6eba6299bcab 0a789217-1f65-c3c3-12cd-68e142561bd2',
      disclaimer: t.PZV7(unlimitedInternetInFinlandNordicBalticAndRestOfEuMsg, formatNumber(39)),
    },
    {
      label: `${t.VSE5(dataPackageMsg)} 5G (300M)${getMonthlyPriceForAddOn(
        'ba25d1ab-e436-cdd3-c741-7718b91106aa',
        MinuteOfferType.VoiceYrityspuheEU,
        CommercialProductType.VoiceYrityspuheEU,
        addOnRules
      )}`,
      value: 'ba25d1ab-e436-cdd3-c741-7718b91106aa 0a789217-1f65-c3c3-12cd-68e142561bd2',
      disclaimer: t.PZV7(unlimitedInternetInFinlandNordicBalticAndRestOfEuMsg, formatNumber(39)),
    },
  ],
});

const getSmsPackageItems = (addOnRules?: SubscriptionAddOnRulesState | null) => [
  {
    label: t.M8C7(noSmsPackageMsg),
    value: 'NOT_SELECTED',
    disclaimer: t.NLB0(smsPriceNordicBalticMsg),
  },
  {
    label: `${t.EI6P('SMS package 500')}${getMonthlyPriceForAddOn(
      '6734fdd9-b9b0-5263-bc54-c58567efae10',
      MinuteOfferType.VoiceYrityspuhe,
      CommercialProductType.VoiceYrityspuhe,
      addOnRules
    )}`,
    value: '6734fdd9-b9b0-5263-bc54-c58567efae10',
    disclaimer: t.DTLM(
      'Includes 500 messages in the Nordic and Baltic countries, messages exceeding limit: €0.079/message'
    ),
  },
  {
    label: `${t.BCAG('SMS package 5,000')}${getMonthlyPriceForAddOn(
      '9ab9e349-9263-6034-0b94-2b557789186d',
      MinuteOfferType.VoiceYrityspuhe,
      CommercialProductType.VoiceYrityspuhe,
      addOnRules
    )}`,
    value: '9ab9e349-9263-6034-0b94-2b557789186d',
    disclaimer: t.QIHT(
      'Includes 5,000 messages in the Nordic and Baltic countries, messages exceeding limit: €0.079/message'
    ),
  },
];

export const getSmsPackageItemsByOffer = (addOnRules?: SubscriptionAddOnRulesState | null) => ({
  [MinuteOfferType.VoiceYrityspuhe.toString()]: getSmsPackageItems(addOnRules),
  [MinuteOfferType.VoiceYrityspuheEU.toString()]: getSmsPackageItems(addOnRules),
});
