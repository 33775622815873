import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { ModelType, SubscriptionCategory } from '../../../../../common/enums.js';
import { SimActivation } from '../../../../../components/SimActivation/SimActivation.js';
import { SubscriptionAction } from '../../../../../generated/api/models.js';
import {
  activateSimCardMsg,
  omaElisaForCompaniesMsg,
  t,
  voiceSubscriptionsMsg,
} from '../../../../../common/i18n/index.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { findSubscription, getSubscriptionStatus } from '../../../../../common/utils/subscriptionUtils.js';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  loadBillChannels,
  loadOnlineModelByModelType,
  loadSubscriptionAddOnRules,
  loadSubscriptionAddOnRulesMobilePbx,
  loadSubscriptions,
} from '../../../../../selfservice/actions/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const MobileSubActivateSimPath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { subscriptionId } = useParams<SubscriptionId>();
  const voiceSubs = useSelector((state: State) => state.selfservice?.subscriptions?.voice || undefined, deepEqual);
  const subscriptionActions = useSelector((state: State) => state?.selfservice?.subscriptionActions, deepEqual);
  const serviceFees = useSelector((state: State) => state?.selfservice?.subscriptions?.voice?.serviceFees, deepEqual);

  useEffect(() => {
    dispatch(loadSubscriptions({ category: SubscriptionCategory.VOICE, displayId: subscriptionId }));
    // Take to use when Ring Association UI is ready
    dispatch(loadOnlineModelByModelType(ModelType.Ring));
    dispatch(loadSubscriptionAddOnRulesMobilePbx());
    dispatch(loadSubscriptionAddOnRules());
    dispatch(loadBillChannels());
    dispatch(loadSubscriptionAddOnRules());
  }, [dispatch, pathname, subscriptionId]);

  if (!subscriptionId) {
    return null;
  }
  const subscription = findSubscription(subscriptionId, voiceSubs);
  const { pendingActionType, pendingActions } = getSubscriptionStatus(subscription, subscriptionActions?.items);
  const pendingSimChanges = pendingActionType === SubscriptionAction.SubscriptionActionTypeEnum.CHANGE_SIM;

  return (
    <SimActivation
      breadCrumbs={
        <BreadCrumbsWithTitle
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.AR0B(voiceSubscriptionsMsg), path: paths.PS_MOBILE_SUBSCRIPTIONS },
            {
              name: subscriptionId,
              path: generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId }),
            },
            { name: t.M1E7(activateSimCardMsg) },
          ]}
        />
      }
      category={SubscriptionCategory.VOICE}
      disallowBillableSimChanges={false}
      pendingSimChanges={pendingSimChanges}
      pendingSubscriptionChanges={pendingActions}
      onClickOrderSim={() => navigate(generatePath(paths.PS_MOBILE_SUBSCRIPTION_ORDER_SIM, { subscriptionId }))}
      onClickSubscription={() => {
        navigate(generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId: subscription?.subscriptionDisplayId }));
      }}
      subscription={subscription}
      simChangeServiceFee={
        serviceFees?.find(serviceFee => serviceFee.subscriptionDisplayId === subscription?.subscriptionDisplayId)?.price
      }
    />
  );
};
