import { SubscriptionStatusType } from '../../../generated/api/models.js';
import {
  csvValue,
  formatCellValue,
  formatCustomerOrderNumber,
  formatDate,
  getBaAddress,
  getSubscriptionContactName,
  getSubscriptionPurposeOfUse,
  isOrderCancelled,
} from '../common/reportsUtil.js';
import { formatSum } from '../../../common/utils/priceUtils.js';
import type { BillingAccount, Contact, SubscriptionHeader } from '../../../generated/api/models.js';
import type { CustomerOrderReportingType } from '../common/reportsUtil.js';

export const eppPhoneReportHeaderLabels = [
  'Yritys (Company Name)',
  'Maksajan nimi (Payer Name)',
  'Laskutussopimuksen numero (Billing Account Id)',
  'Laskutusosoite (Payer Address)',
  'Viite 1 (Customer Reference 1)',
  'Viite 2 (Customer Reference 2)',
  'Tilauspäivä (Order Date)',
  'Toimituspäivä (Delivery Date)',
  'Tilausnumero (Customer Order Id)',
  'Laitteen nimi (Subscription Name)',
  'IMEI/sarjanumero (IMEI/Serial Number)',
  'Käyttäjä (User Contact Name)',
  'Käyttötarkoitus (Purpose Of Use)',
  'Puhelinnumero (User Contact Phone Number)',
  'Kustannuspaikka (Cost Center)',
  'Henkilönumero (Personnel number)',
  'Sopimuskauden pituus (kk) (Contract Term)',
  'Sopimus alkaa (Commitment start date)',
  'Sopimus päättyy (Commitment end date)',
  'Yrityksen osuus (Company Share)',
  'Käyttäjän osuus (End User Share) ',
  'Palvelun tunnistetieto (Subscription Number)',
  'Laitteen tila (Device Status)',
  'Sopimuksen tila (Subscription Status)',
  'Sopimuskausi (Agreement Period)',
  'Laitehallintaohjelma (Enrollment Program System)',
  'Laitehallintaohjelman tunnus (Enrollment Program External Id)',
];
export const getEppPhoneReport = (
  companyName: string,
  reportingOrderData: Record<string, CustomerOrderReportingType>,
  deviceSubscriptions: SubscriptionHeader[],
  billingAccounts: BillingAccount[],
  contacts: Contact[]
): string[][] => {
  const billingAccountsMap: Record<string, BillingAccount> = {};
  const contactsMap: Record<string, Contact> = {};

  billingAccounts.forEach(billingAccount => {
    billingAccountsMap[billingAccount.billingAccountId!] = billingAccount;
  });

  contacts.forEach(contact => {
    contactsMap[contact.contactId!] = contact;
  });

  const eppPhoneReport: string[][] = deviceSubscriptions
    .filter(
      subscription =>
        (subscription.subscriptionSubType === 'Phone' || subscription.subscriptionSubType === 'Tablet') &&
        subscription.eppSubscription &&
        !isOrderCancelled(reportingOrderData[subscription.deliveryOrderId!]?.customerOrderStatus) &&
        subscription.subscriptionStatus === SubscriptionStatusType.ACTIVE
    )
    .map(subscriptionItem => {
      const billingAccount: BillingAccount | undefined = billingAccountsMap[subscriptionItem.billingAccountId!];
      const customerOrderReportingData: CustomerOrderReportingType | undefined =
        reportingOrderData[subscriptionItem.deliveryOrderId!];
      const contact: Contact | undefined = contactsMap[subscriptionItem.subscriptionContactId!];

      const companyShare = subscriptionItem.corporateShare ?? subscriptionItem.monthlyRecurringCharge;
      const employeeShare = subscriptionItem.employeeShare ?? 0;

      return [
        `"${companyName}"`,
        csvValue(billingAccount?.payerName),
        csvValue(billingAccount?.billingAccountDisplayId, '='),
        getBaAddress(billingAccount),
        billingAccount?.customerReference1 ? `"${billingAccount?.customerReference1}"` : '',
        billingAccount?.customerReference2 ? `"${billingAccount?.customerReference2}"` : '',
        formatDate(customerOrderReportingData?.customerOrderCreated),
        formatDate(customerOrderReportingData?.deliveryOrderDeliveryDate),
        formatCustomerOrderNumber(subscriptionItem.sourceSystem, customerOrderReportingData?.customerOrderDisplayId),
        `"${subscriptionItem.subscriptionName}"`,
        csvValue(subscriptionItem.subscriptionUserFriendlyId, '='),
        getSubscriptionContactName(subscriptionItem),
        getSubscriptionPurposeOfUse(subscriptionItem),
        csvValue(contact?.person?.phoneNumber, '='),
        subscriptionItem?.costCenter !== undefined ? `="${subscriptionItem?.costCenter}"` : '',
        subscriptionItem?.subscriptionReference !== undefined ? `="${subscriptionItem?.subscriptionReference}"` : '',
        subscriptionItem.commitmentPeriod !== undefined ? `"${subscriptionItem.commitmentPeriod}"` : '',
        formatDate(subscriptionItem.commitmentStartDate),
        formatDate(subscriptionItem.commitmentEndDate),
        companyShare !== undefined ? `"${formatSum(companyShare)}"` : '',
        `"${formatSum(employeeShare)}"`,
        csvValue(subscriptionItem.subscriptionDisplayId),
        subscriptionItem.eppDeviceStatus ? `"${formatCellValue(subscriptionItem.eppDeviceStatus)}"` : '',
        subscriptionItem.subscriptionStatus ? `"${formatCellValue(subscriptionItem.subscriptionStatus)}"` : '',
        subscriptionItem.changeType
          ? `"${subscriptionItem.changeType === 'EPP Device Change' ? 'New' : subscriptionItem.changeType}"`
          : '',
        csvValue(subscriptionItem.enrollmentProgramSystem),
        csvValue(subscriptionItem.enrollmentProgramExternalId),
      ];
    });
  eppPhoneReport.splice(0, 0, eppPhoneReportHeaderLabels);
  return eppPhoneReport;
};
