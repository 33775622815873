import * as CL from '@design-system/component-library';
import { Email, Name, PhoneNumber } from '../../common/react-hook-form/fields/index.js';
import {
  companyBillingInformationMsg,
  customFormErrorFailedMsg,
  firstNameMsg,
  lastNameMsg,
  orderMsg,
  t,
} from '../../common/i18n/index.js';
import { formatPrice } from '../../components/ProductDetails/utils/formatters.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import type { CommercialProduct, Price } from '../../generated/api/models.js';

export enum CustomFormType {
  DOMAIN = 'domain',
  OPTION = 'option',
  M365 = 'm365',
}

export interface CustomFormField extends Price {
  id?: string;
  name?: string;
  value?: string;
  isDefault?: boolean;
}

export interface PriceMaps {
  periodicPriceMap?: Map<string, number>;
  onetimePriceMap?: Map<string, number>;
}

interface CustomFormThankYouForOrderProps {
  message: string;
}

interface CustomFormErrorAndButtonProps {
  leadFailed?: boolean;
  leadInProgress?: boolean;
}

export interface CompanyInfoProps {
  title?: string;
}

export const createPrice = (field: CustomFormField) => {
  return field.monthlyRecurringCharge ? formatPrice(field as CommercialProduct) : formatSum(field.oneTimeCharge);
};

export const createPrices = (periodicPriceMap: Map<string, number>, onetimePriceMap: Map<string, number>) => {
  return {
    periodicPriceMap,
    onetimePriceMap,
    periodicPrice: formatPrice({
      payments: 24,
      monthlyRecurringCharge: Array.from(periodicPriceMap.values()).reduce((a, b) => a + b, 0),
    } as CommercialProduct),
    onetimePrice: formatSum(Array.from(onetimePriceMap.values()).reduce((a, b) => a + b, 0)),
  };
};

export const updatePrices = (
  setValue: (key: string, value: Map<string, number> | string | undefined) => void,
  periodicPriceMap: Map<string, number>,
  onetimePriceMap: Map<string, number>
) => {
  const prices = createPrices(periodicPriceMap, onetimePriceMap);
  setValue('periodicPriceMap', periodicPriceMap);
  setValue('onetimePriceMap', onetimePriceMap);
  setValue('periodicPrice', prices.periodicPrice);
  setValue('onetimePrice', prices.onetimePrice);
};

export const CompanyInfo = ({ title }: CompanyInfoProps) => (
  <h3 className="ds-h3">{title || t.IRQS(companyBillingInformationMsg)}</h3>
);

export const CustomFormContactDetails = () => {
  const firstName = t.AIK7(firstNameMsg);
  const lastName = t.Y8OY(lastNameMsg);
  return (
    <>
      <Name name="firstName" label={firstName} placeholder={firstName} />
      <Name name="lastName" label={lastName} placeholder={lastName} />
      <Email />
      <PhoneNumber />
    </>
  );
};

export const CustomFormThankYouForOrder = ({ message }: CustomFormThankYouForOrderProps) => {
  return (
    <div className="of-custom-form">
      <div className="ds-grid__row ds-justify-content--center">
        <CL.Icon color="green-600" icon="check" size="xl" type="filled" />
        <h3 className="ds-padding--3">{message}</h3>
      </div>
    </div>
  );
};

export const CustomFormErrorAndButton = ({ leadFailed, leadInProgress }: CustomFormErrorAndButtonProps) => {
  return (
    <>
      {leadFailed && (
        <CL.Notification hasCloseButton={false} text={t.DLRT(customFormErrorFailedMsg)} type="danger" visible />
      )}
      <CL.Button className="top-margin" loading={leadInProgress} size="l" type="submit">
        {t.AQOL(orderMsg)}
      </CL.Button>
    </>
  );
};
