import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { addToActionHistory, getPreviousActionState, updateActionStatePhase } from '../common/index.js';
import {
  buildHeaderOnlyItemsQuery,
  getItemsQueryForReports,
  mergeArrays,
  mergeArraysWithIdObject,
  reduceDisplayItemsLoadAction,
  validateLoadActionFulfilledResponseCounts,
} from './reducerUtils.js';
import { loadSubscriptionActionsUseSearchService } from '../common/elasticsearchUtils.js';
import { sortArray } from '../../common/utils/arrayUtils.js';
import type { ActionsHistory, ItemsQuery } from '../common/store.js';
import type { SelfServiceActionTypes } from '../actions/index.js';
import type { SubscriptionAction, SubscriptionActionSearchResponse } from '../../generated/api/models.js';
import type { SubscriptionActionsState } from '../../common/types/states.js';

export function subscriptionActionsReducer(
  state: (SubscriptionActionsState & ActionsHistory) | null,
  action: SelfServiceActionTypes
): (SubscriptionActionsState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.USE_DUPLICATE_CONTACT: {
      return {
        ...state,
        saving: false,
        useDuplicateContact: action.useDuplicateContact,
        contactCreated: undefined,
      };
    }
    case TypeKeys.LOAD_SUBSCRIPTIONS:
    case TypeKeys.LOAD_BILLING_ACCOUNTS: {
      return {
        ...state,
        saving: false,
        duplicateContactFound: false,
        useDuplicateContact: undefined,
        contactCreated: undefined,
      };
    }
    case TypeKeys.EDIT_SUBSCRIPTION_SECTION:
    case TypeKeys.CLEAR_DUPLICATE_CONTACT: {
      return {
        ...state,
        saving: false,
        duplicateContactFound: false,
        useDuplicateContact: undefined,
        contactCreated: undefined,
      };
    }
    case TypeKeys.UPSERT_BILLING_ACCOUNT_FULFILLED: {
      return {
        ...state,
        saving: false,
        duplicateContactFound: false,
        useDuplicateContact: undefined,
        contactCreated: undefined,
      };
    }
    case TypeKeys.UPSERT_CONTACT_FULFILLED: {
      return {
        ...state,
        saving: false,
        duplicateContactFound: false,
        useDuplicateContact: undefined,
        contactCreated: action.contact,
      };
    }
    case TypeKeys.REVIEW_FULFILLED: {
      // This is done to re-call the subscription actions
      return null;
    }
    case TypeKeys.LOAD_SUBSCRIPTION_ACTIONS: {
      if (loadSubscriptionActionsUseSearchService(action)) {
        const query: ItemsQuery | undefined = action.reporting
          ? getItemsQueryForReports()
          : buildHeaderOnlyItemsQuery(action, state, state?.total, state?.search, state?.status);

        return {
          ...state,
          actions: query
            ? addToActionHistory(state, {
                phase: ActionPhase.IN_PROGRESS,
                query,
                value: action,
              })
            : state?.actions,
          filter: action.filter,
          search: query?.search,
          sort:
            query?.sort && query?.order
              ? {
                  columnId: query.sort,
                  order: query.order,
                }
              : state?.sort,
          status: query?.status,
          saving: false,
        };
      }
      const getAllItems = !!action.filter || !!action.sort || !!action.search || action.reporting;
      return {
        ...reduceDisplayItemsLoadAction<TypeKeys.LOAD_SUBSCRIPTION_ACTIONS, SubscriptionAction>(
          action,
          state,
          'subscriptionActionDisplayId',
          getAllItems,
          action.reporting && action.forceLoad ? 0 : undefined,
          action.forceLoad
        ),
        saving: false,
      };
    }
    case TypeKeys.LOAD_SUBSCRIPTION_ACTIONS_FULFILLED: {
      const actionState = getPreviousActionState(TypeKeys.LOAD_SUBSCRIPTION_ACTIONS, state!, ActionPhase.IN_PROGRESS);
      const getSearchResultsForSearchService =
        action.useSearchService && action.query.offset > 0
          ? mergeArraysWithIdObject<SubscriptionActionSearchResponse>(
              'result',
              'subscriptionActionDisplayId',
              'created',
              state!.searchResults,
              action.searchResults
            )
          : action.searchResults;
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_SUBSCRIPTION_ACTIONS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: action.useSearchService
          ? validateLoadActionFulfilledResponseCounts(
              actionState!.query!,
              action.total,
              action.searchResults?.map(item => item.result),
              state!.errors
            )
          : validateLoadActionFulfilledResponseCounts(
              actionState!.query!,
              action.total,
              action.subscriptionActionResponse,
              state!.errors
            ),
        items: sortArray(
          mergeArrays<SubscriptionAction>(
            'subscriptionActionDisplayId',
            'lastModified',
            state!.items,
            action.subscriptionActionResponse
          ),
          'created',
          'desc'
        ),
        searchResults: action.useSearchService ? getSearchResultsForSearchService : state!.searchResults,
        total: action.total,
        saving: false,
      };
    }
    case TypeKeys.LOAD_SUBSCRIPTION_ACTIONS_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_SUBSCRIPTION_ACTIONS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
      };
    }
    case TypeKeys.SUBMIT_MOBILEID_CONTRACT:
    case TypeKeys.TERMINATE_MOBILEID_CONTRACT: {
      return {
        ...state,
        saving: true,
      };
    }
    case TypeKeys.SUBMIT_MOBILEID_CONTRACT_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.SUBMIT_MOBILEID_CONTRACT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: undefined,
        saving: false,
      };
    }
    case TypeKeys.SUBMIT_MOBILEID_CONTRACT_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.SUBMIT_MOBILEID_CONTRACT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
      };
    }
    case TypeKeys.TERMINATE_MOBILEID_CONTRACT_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.TERMINATE_MOBILEID_CONTRACT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: undefined,
        saving: false,
      };
    }
    case TypeKeys.TERMINATE_MOBILEID_CONTRACT_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.TERMINATE_MOBILEID_CONTRACT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
      };
    }
    default:
      return state;
  }
}
