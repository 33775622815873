import { EppSupportOrServiceAnnouncement } from './EppSupportOrServiceAnnouncement.js';
import { EppSupportOrServiceRequestForm } from './EppSupportOrServiceRequestForm.js';
import { useState } from 'react';
import type { Subscription } from '../../generated/api/models.js';

interface EppSupportOrServiceRequestProps {
  isEmployee: boolean;
  onCancel: () => void;
  subscription: Subscription;
}

export const EppSupportOrServiceRequest = ({ isEmployee, onCancel, subscription }: EppSupportOrServiceRequestProps) => {
  const [announcementConfirmed, setAnnouncementConfirmed] = useState(false);

  const onNext = () => setAnnouncementConfirmed(true);

  return announcementConfirmed ? (
    <EppSupportOrServiceRequestForm isEmployee={isEmployee} onCancel={onCancel} subscription={subscription} />
  ) : (
    <EppSupportOrServiceAnnouncement isEmployee={isEmployee} onCancel={onCancel} onNext={onNext} />
  );
};
