import * as CL from '@design-system/component-library';
import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { COST_CENTER_REGEX } from '../../common/utils/validationUtils.js';
import { CompanyInfoResponse, CustomerOrderStatus } from '../../generated/api/models.js';
import { CustomerOrderContent } from './CustomerOrderDetailsContent.js';
import { CustomerOrderFooter } from './CustomerOrderDetailsFooter.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { Form, Formik } from 'formik';
import { Loading } from '../Loading/index.js';
import { formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { invalidCostCenterNumberMsg, orderMsg, t } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useEffect, useState } from 'react';
import { validateForm } from '../../common/formik/index.js';
import type {
  BillingAccountsResponse,
  ContactHeader,
  ContactSearchResponse,
  CustomerOrder,
  Subscription,
} from '../../generated/api/models.js';
import type { BreadCrumbList } from '../BreadCrumbs/index.js';

import './CustomerOrderDetails.scss';

export interface CustomerOrderDetailsAttrs {
  buildDeliveryOrderHref: (cid: string, did: string) => string;
  contacts?: ContactSearchResponse[];
  deviceSubscriptions?: Subscription[];
  customerOrder?: CustomerOrder;
  customerType?: CompanyInfoResponse.CustomerTypeEnum;
  firstBreadCrumbName?: string;
  isEmployee?: boolean;
  billingAccounts?: BillingAccountsResponse;
}

export interface CustomerOrderDetailsRoutes {
  breadCrumbPaths?: BreadCrumbList;
}

export interface CustomerOrderDetailsDispatchers {
  onShowContacts?: (displayId?: string) => void;
}

export type CustomerOrderDetailsProps = CustomerOrderDetailsAttrs &
  CustomerOrderDetailsDispatchers &
  CustomerOrderDetailsRoutes;

export interface CustomerOrderDetailsFormValues {
  billingAccountId?: string;
  costCenter?: string;
  employeeNumber?: string;
}

const validateCostCenterForInternal = (value: string) => {
  return !value || COST_CENTER_REGEX.test(value);
};

export const billingInfoFieldValidations = {
  billingAccountId: { required: true },
};

export const billingInfoFieldValidationsForInternal = {
  costCenter: {
    required: true,
    validationFn: validateCostCenterForInternal,
    messages: {
      validationFn: () => t.JE8S(invalidCostCenterNumberMsg),
    },
  },
  billingAccount: { required: true },
};

export const CustomerOrderDetails = ({
  buildDeliveryOrderHref,
  contacts,
  deviceSubscriptions,
  customerOrder,
  breadCrumbPaths,
  customerType,
  onShowContacts,
  billingAccounts,
  isEmployee = false,
}: CustomerOrderDetailsProps) => {
  const [ordererContact, setOrdererContact] = useState<ContactHeader | undefined>(undefined);
  const [isChecked, setIsChecked] = useState(false);
  const showFooter = customerOrder?.status === CustomerOrderStatus.PENDING_APPROVAL && !isEmployee;
  const { authenticatedUser } = useAuth();
  const accountMasterId = billingAccounts?.searchResults?.find(
    ba => ba.result.billingAccountId === customerOrder?.billingAccountId
  )?.result.accountMasterId;
  const companyName = getCompanyName(authenticatedUser, accountMasterId);

  useEffect(() => {
    // We want to load contacts only in case of orders pending for approval.
    if (customerOrder?.status === CustomerOrderStatus.PENDING_APPROVAL && onShowContacts) {
      onShowContacts(customerOrder?.deliveryOrders[0].userContactId);
    }
  }, [customerOrder, isEmployee]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const subContact = contacts
      ?.map(c => c.result)
      .find(cont => cont.contactId === customerOrder?.deliveryOrders[0].userContactId);
    setOrdererContact(subContact);
  }, [customerType, contacts, customerOrder]);

  const isInternalCustomer = customerType === CompanyInfoResponse.CustomerTypeEnum.INTERNAL_CUSTOMERS;

  if (!customerOrder) {
    return <Loading />;
  }

  const content: JSX.Element = (
    <>
      <CustomerOrderContent
        buildDeliveryOrderHref={buildDeliveryOrderHref}
        customerOrder={customerOrder}
        ordererContact={ordererContact}
        deviceSubscriptions={deviceSubscriptions}
        isInternalCustomer={isInternalCustomer}
        isEmployee={isEmployee}
      />
      {showFooter && (
        <>
          <CL.Checkbox
            className="ds-color--blue"
            checked={isChecked}
            onChange={() => {
              setIsChecked(!isChecked);
            }}
          >
            {t.WO28('I have checked the validity of the billing and order details.')}
          </CL.Checkbox>
          <CustomerOrderFooter customerOrder={customerOrder} ordererContact={ordererContact} isChecked={isChecked} />
        </>
      )}
    </>
  );

  const customerOrderDisplayId = customerOrder ? customerOrder.customerOrderDisplayId : '…';
  const fieldValidations = isInternalCustomer ? billingInfoFieldValidationsForInternal : billingInfoFieldValidations;
  const initialFormValues = {
    billingAccountId: customerOrder?.billingAccountId,
    employeeNumber: customerOrder?.deliveryOrders[0].subscriptionReference || '',
    costCenter: customerOrder?.deliveryOrders[0].costCenter || '',
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues}
      validate={formikValues => {
        return validateForm(formikValues, fieldValidations);
      }}
      validateOnMount={true}
      onSubmit={() => {}}
      initialTouched={{
        billingAccountId: true,
        costCenter: true,
      }}
    >
      <Form>
        <DetailsWrapper
          classes={['of-customer-order-details']}
          content={content}
          detailsTop={breadCrumbPaths && <BreadCrumbsWithTitle breadCrumbPaths={breadCrumbPaths} />}
          id={`customer-order-details-${customerOrderDisplayId}`}
          heading={customerOrderDisplayId}
          headingBottom={`${formatTimestampToDDMMYYYY(customerOrder.created)}${companyName ? ` | ${companyName}` : ''}`}
          headingTop={t.C001(orderMsg)}
          heroPicto="order"
        />
      </Form>
    </Formik>
  );
};
