import { BreadCrumbs } from '../BreadCrumbs/BreadCrumbs.js';
import { NewSupportCase } from '../NewSupportCase/NewSupportCase.js';
import {
  contactInfoMsg,
  corporateCustomersMsg,
  createNewMessageMsg,
  leaveMessageMsg,
  t,
} from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';

import './NewSupportCasePublic.scss';

export const NewSupportCasePublic = () => {
  return (
    <div className="of-new-support-case-public">
      <BreadCrumbs
        breadCrumbPaths={[
          { name: t.KBLX(corporateCustomersMsg), path: '/' },
          { name: t.GJS9(contactInfoMsg), path: paths.CONTACT_INFO },
          { name: t.WVNV(leaveMessageMsg), path: paths.CONTACT },
          { name: t.O1AQ('Create a new message') },
        ]}
      />
      <h1 className="ds-text-align--center">{t.WVNV('Leave a message')}</h1>
      <NewSupportCase selfService={false} title={t.J7JN(createNewMessageMsg)} />
    </div>
  );
};
