import * as CL from '@design-system/component-library';
import { CompanyInfoResponse } from '../../generated/api/models.js';
import { Grid } from '../Grid/Grid.js';
import { t } from '../../common/i18n/index.js';
import { updateAccountSettings } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { CompanyInfoState } from '../../common/types/states.js';

import './CompanySettings.scss';

export interface CompanySettingsProps {
  companyInfo?: CompanyInfoState | null;
}

const isSettingsVisible = (salesChannel?: CompanyInfoResponse.SalesChannelEnum) =>
  salesChannel &&
  [
    CompanyInfoResponse.SalesChannelEnum.EDUSTAJA_YA,
    CompanyInfoResponse.SalesChannelEnum.TELESALES,
    CompanyInfoResponse.SalesChannelEnum.YRITYSGURU,
    CompanyInfoResponse.SalesChannelEnum.YRITYSPISTE,
  ].includes(salesChannel);

/**
 * ControlledToggle helps to re-render the CL.Toggle every time. This is needed
 * because CL.Toggle does not care about the `value` property after initial
 * render. This kludge helps to re-render the CL.Toggle every time it is
 * rendered.
 *
 * Note: The `key` property cannot just take the `value` property and use it as
 * a key because if the value stays the same between renders (e.g. because an API
 * call fails), the CL.Toggle is not re-rendered and it shows incorrectly its
 * internal state, which is opposite to the `value`.
 *
 * TODO: Remove this when the CL version is upgraded to a level where the
 *  issue is fixed. As of CL 7.6.1 it is still broken: test
 *  'should not change text when API call fails' fails.
 */
const ControlledToggle = ({ value, onToggle, label }: { value: boolean; onToggle: () => void; label: string }) => {
  return (
    <CL.Toggle
      key={Math.random().toString(36).substring(2)}
      onToggle={onToggle}
      value={value}
      labelPosition="left"
      label={label}
    />
  );
};

export const CompanySettings = ({ companyInfo }: CompanySettingsProps) => {
  const dispatch = useDispatch();

  const onUpdateSmsDueReminder = () => {
    dispatch(
      updateAccountSettings({
        blockInvoiceDueSmsReminder: !companyInfo?.blockInvoiceDueSmsReminder,
      })
    );
  };

  if (!companyInfo || !isSettingsVisible(companyInfo.salesChannel)) {
    return <></>;
  }

  const toggleText = () => {
    return companyInfo?.blockInvoiceDueSmsReminder ? t.M03S('Yes') : t.A37D('No');
  };

  return (
    <Grid>
      <div className="of-company-settings ds-padding-top--4">
        <CL.Grid className="grid">
          <CL.GridRow gutter={false}>
            <CL.GridCol colsL={3}>
              <h3 className="ds-description__title">{t.QI2P('Settings')}</h3>
            </CL.GridCol>
          </CL.GridRow>
          <CL.GridRow gutter={false}>
            <CL.GridCol colsXS={4} colsM={2} colsL={4}>
              <strong>{t.T4MT('Block invoice due SMS-reminder')}</strong>
              <div className="ds-text--s ds-color--neutral-600 ds-padding-vertical--3">
                {t.KOL4(`Block SMS reminder for invoices which are due. Blocking applies to all billing accounts.`)}
              </div>
            </CL.GridCol>
            <CL.GridCol colsXS={4} colsM={2} colsL={3} className="toggle-button">
              <ControlledToggle
                value={!!companyInfo?.blockInvoiceDueSmsReminder}
                onToggle={onUpdateSmsDueReminder}
                label={toggleText()}
              />
            </CL.GridCol>
          </CL.GridRow>
        </CL.Grid>
      </div>
    </Grid>
  );
};
