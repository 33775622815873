import type { ReactNode } from 'react';

import './NettiLiteProductInfoHeader.scss';

interface NettiLiteProductInfoHeaderProps {
  children: ReactNode;
  productName: string;
  productInformation?: string;
  price: string;
  type: 'broadband' | 'yritystupla';
}

export const NettiLiteProductInfoHeader = (props: NettiLiteProductInfoHeaderProps) => {
  const { children, price, productName, productInformation, type } = props;

  return (
    <div className="of-netti-lite-product-info-header__wrapper">
      <div
        className={`of-netti-lite-product-info-header__picture of-netti-lite-product-info-header__picture__${type}`}
      />
      <div className="of-netti-lite-product-info-header__price-information">
        <div className="ds-display--flex ds-justify-content--space-between ds-align-items--baseline">
          <h3>{productName}</h3>
          <h4>{price}</h4>
        </div>
        <span className="ds-font-size--small">{productInformation}</span>
        <div className="of-netti-lite-product-info-header__details ds-margin-top--4">{children}</div>
      </div>
    </div>
  );
};
