import * as CL from '@design-system/component-library';
import { PbxWorkingHours } from '../PbxWorkingHours/PbxWorkingHours.js';
import { ReachabilityChain } from './reachabilitychain/ReachabilityChain.js';
import { SubscriptionPbxDetails } from '../../generated/api/subscriptionPbxDetails.js';
import {
  closeHoursAction,
  getClidMaskingOptions,
  getClidOptions,
  getCurrentActiveChainItems,
  getReachabilityChainLabel,
} from './pbxTimeSettingsUtil.js';
import {
  editMsg,
  numberConversionToBeDisplayedMsg,
  numberToDisplayMsg,
  officeHourChainMsg,
  outOfOfficeChainMsg,
  t,
} from '../../common/i18n/index.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import type { DescriptionItem } from '@design-system/component-library';
import type { PbxTimeSettingFormData } from './pbxTimeSettingsUtil.js';
import type { Subscription } from '../../generated/api/subscription.js';

export interface PbxTimeSettingsViewProps {
  pbxSolution: Subscription;
  timeSettings: PbxTimeSettingFormData;
  onEditWanted: () => void;
}

function getDescriptionItems(
  timeSettings: PbxTimeSettingFormData,
  pbxType?: SubscriptionPbxDetails.PbxTypeEnum,
  specialNumber?: boolean
) {
  const items: DescriptionItem[] = [];

  if (timeSettings.pbxConfigurationDetails.closeHoursAction) {
    items.push({
      title: t.RDSM('During period of absence'),
      description: (
        <>
          <div>{closeHoursAction(timeSettings.pbxConfigurationDetails.closeHoursAction)}</div>
          <div>{timeSettings.pbxConfigurationDetails.closeHoursNumber}</div>
        </>
      ),
    });
  }

  items.push({
    title: t.N4VX('Reachability service in use'),
    description:
      getCurrentActiveChainItems().find(item => item.value === timeSettings.currentActiveChain)?.label ?? '-',
  });

  if (
    pbxType === SubscriptionPbxDetails.PbxTypeEnum.VAKIO ||
    pbxType === SubscriptionPbxDetails.PbxTypeEnum.TAVOITETTAVUUSKETJU
  ) {
    items.push({
      title: t.O1MZ(numberConversionToBeDisplayedMsg),
      description:
        getClidMaskingOptions(pbxType).find(option => option.value === timeSettings.callerLineIdMasking)?.label ?? '-',
    });
  }

  if (specialNumber) {
    items.push({
      title: t.FL34(numberToDisplayMsg),
      description:
        getClidOptions(pbxType).find(option => option.value === timeSettings.callerLineIdTargetNumber)?.label ?? '-',
    });
  }

  if (timeSettings.callerLineIdMasking !== SubscriptionPbxDetails.CallerLineIdMaskingEnum.NOT_IN_USE) {
    items.push({
      title: t.FL34(numberToDisplayMsg),
      description: timeSettings.callerLineIdNumber ? formatPhoneNumber(timeSettings.callerLineIdNumber, true) : '-',
    });
  }

  return items;
}

export const PbxTimeSettingsView = ({ pbxSolution, timeSettings, onEditWanted }: PbxTimeSettingsViewProps) => {
  const { pbxType } = timeSettings.pbxConfigurationDetails;
  const specialNumber =
    pbxSolution?.details?.specialNumber &&
    pbxSolution?.details?.specialNumber?.pbxConfigurationDetails.pbxType !== SubscriptionPbxDetails.PbxTypeEnum.VAKIO;

  const items = getDescriptionItems(timeSettings, pbxType, specialNumber);

  return (
    <div className="of-pbx-time-settings">
      <CL.Description className="ds-margin-left--0" columns={1} items={items} />

      {timeSettings.currentActiveChain === SubscriptionPbxDetails.CurrentActiveChainEnum.CALENDAR && (
        <div className="of-pbx-time-settings__section-title ds-padding-top--7">
          <h4 className="ds-margin--0">{t.YYCY('Office-hour')}</h4>
          <CL.Popover triggerElement={<CL.Icon icon="help" size="m" type="light" color="blue-600" />} placement="top">
            <>
              <h4>{t.A9V3('Duration of the office-hour chain')}</h4>
              {t.HHRE(
                'Specifies the period of validity of the office-hour chain. The out-of-office chain is used at other times. If you want an out-of-office chain for the entire weekend, set the time from 00:00 to 00:00.'
              )}
            </>
          </CL.Popover>
        </div>
      )}
      <div className="of-pbx-time-settings__section">
        <PbxWorkingHours
          pbxWorkingHoursConfig={timeSettings}
          pbxType={pbxType}
          editing={false}
          hidden={timeSettings.currentActiveChain !== SubscriptionPbxDetails.CurrentActiveChainEnum.CALENDAR}
        />
      </div>

      <div className="of-pbx-time-settings__section-title">
        <h4>{getReachabilityChainLabel(pbxType)}</h4>
      </div>
      <div className="of-pbx-time-settings__section">
        <ReachabilityChain
          label={t.WXDM(officeHourChainMsg)}
          name="workingHoursReachabilityChain"
          numbers={timeSettings.workingHoursReachabilityChain}
          editing={false}
          pbxType={pbxType}
        />
        <ReachabilityChain
          label={t.XGWK(outOfOfficeChainMsg)}
          name="offWorkReachabilityChain"
          numbers={timeSettings.offWorkReachabilityChain}
          editing={false}
          pbxType={pbxType}
        />
      </div>

      <div className="of-pbx-time-settings__actions">
        <CL.Button color="light" onClick={onEditWanted}>
          {t.NVPK(editMsg)}
        </CL.Button>
      </div>
    </div>
  );
};
