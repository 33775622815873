import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { DetailsWrapper } from '../../../../../components/DetailsWrapper/index.js';
import { EppRedeem } from '../../../../../components/EppRedeem/EppRedeem.js';
import { SubscriptionAction } from '../../../../../generated/api/models.js';
import { SubscriptionCategory } from '../../../../../common/enums.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { devicesMsg, omaElisaForCompaniesMsg, redemptionMsg, t } from '../../../../../common/i18n/index.js';
import { findSubscription } from '../../../../../common/utils/subscriptionUtils.js';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { isEppDeviceSubscription } from '../../../../../components/EmployeeSubscriptionDetails/eppSubscriptionUtils.js';
import {
  loadBillChannels,
  loadContacts,
  loadSubscriptionAddOnRules,
  loadSubscriptions,
} from '../../../../../selfservice/actions/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { stripActions } from '../../../../../common/utils/stateUtils.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const DeviceRedemptionPath = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { subscriptionId } = useParams<SubscriptionId>();
  const subscriptions = useSelector(
    (s: State) => stripActions(s.selfservice?.subscriptions?.device) || undefined,
    deepEqual
  );
  const subscription = findSubscription(subscriptionId!, subscriptions);
  const billingAccounts = useSelector((state: State) => state.selfservice?.billingAccounts, deepEqual);
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  const contacts = useSelector((state: State) => state.selfservice?.contacts, deepEqual);
  const subscriptionActions = useSelector((state: State) => state?.selfservice?.pendingSubscriptionActions, deepEqual);
  const showSuspendSubscriptionOptions = subscriptionActions?.suspendSubscriptionInitiated;
  const isTerminateSubscriptionForm = subscriptionActions?.terminateSubscriptionInitiated;
  const deviceNotRedeemable = subscriptionActions?.items?.some(
    subscriptionAction =>
      subscriptionAction.subscriptionId === subscription?.subscriptionId &&
      [
        SubscriptionAction.StatusEnum.PENDING,
        SubscriptionAction.StatusEnum.PENDING_APPROVAL,
        SubscriptionAction.StatusEnum.ERROR,
      ].includes(subscriptionAction.status)
  );
  const eppSubscription = subscription?.details?.device?.eppSubscription;

  useEffect(() => {
    dispatch(loadContacts());
    dispatch(loadSubscriptions({ category: SubscriptionCategory.DEVICE, displayId: subscriptionId }));
    dispatch(loadBillChannels());
    dispatch(loadSubscriptionAddOnRules());
  }, [dispatch, pathname, subscriptionId]);

  useEffect(() => {
    if (
      !isEppDeviceSubscription(subscription) ||
      showSuspendSubscriptionOptions ||
      isTerminateSubscriptionForm ||
      (showSuspendSubscriptionOptions && deviceNotRedeemable)
    ) {
      navigate(generatePath(paths.PS_DEVICE, { subscriptionId }), { replace: true });
    }
  }, [
    deviceNotRedeemable,
    eppSubscription,
    navigate,
    showSuspendSubscriptionOptions,
    subscriptionId,
    isTerminateSubscriptionForm,
    subscription,
  ]);

  if (!subscriptionId || !subscription) {
    return null;
  }

  return (
    <DetailsWrapper
      classes={['of-subscription-details']}
      content={
        <EppRedeem
          billingAccounts={billingAccounts}
          companyName={companyInfo?.companyName}
          contacts={contacts?.items}
          isRedeemedByAdmin={true}
          onBackClick={() => navigate(generatePath(paths.PS_DEVICE, { subscriptionId }))}
          subscription={subscription}
        />
      }
      detailsTop={
        <BreadCrumbsWithTitle
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.JJTM(devicesMsg), path: paths.PS_DEVICES },
            {
              name: subscriptionId,
              path: generatePath(paths.PS_DEVICE, { subscriptionId }),
            },
            { name: t.B9EG(redemptionMsg) },
          ]}
        />
      }
      heading={t.KK9D('Redeeming your device')}
      headingBottom={`${subscription?.subscriptionName} | ${subscription?.subscriptionContactName}`}
      heroPicto={undefined}
      heroIcon="invoice"
      id={`subscription-details-${subscription?.subscriptionDisplayId}`}
    />
  );
};
