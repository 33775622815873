import * as CL from '@design-system/component-library';
import { BillingAccountSelection } from './VirtualCatalogPartials.js';
import { Catalog } from '../../generated/api/catalog.js';
import { ModificationStatus } from '../CatalogList/ModificationStatus.js';
import { WizardType } from '../../common/enums.js';
import {
  agreementPeriodMsg,
  alvZeroMsg,
  billingAccountMsg,
  catalogCorporateMessageLabelMsg,
  companysShareOfFeeMsg,
  damageInsuranceMsg,
  inUseMsg,
  monthMsg,
  notInUseMsg,
  productTypeMsg,
  statusMsg,
  t,
} from '../../common/i18n/index.js';
import { formatTimeStampToHHmm, formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import {
  getCatalogModificationStatus,
  getCorporateShareAsText,
  getDamageInsuranceDisplayableItems,
  productTypeDetails,
} from '../../common/utils/catalogUtils.js';
import { useNavigate } from 'react-router-dom';
import type * as CLT from '@design-system/component-library';
import type { BillingAccountsState } from '../../common/types/states.js';
import type { CatalogModificationStatus } from '../../common/utils/catalogUtils.js';
import type { VirtualCatalog } from '../../generated/api/virtualCatalog.js';

interface VirtualCatalogInformationForStatusProps {
  catalog: Catalog;
  modificationStatus?: CatalogModificationStatus;
  billingAccount?: string;
  selectBillingAccount?: {
    billingAccounts?: BillingAccountsState;
    virtualCatalog: VirtualCatalog;
  };
}

export const VirtualCatalogInformationForStatus = (props: VirtualCatalogInformationForStatusProps) => {
  const { catalog, modificationStatus, billingAccount, selectBillingAccount } = props;
  const navigate = useNavigate();
  const items: CLT.DescriptionItem[] = [];

  // Status
  if (modificationStatus) {
    items.push({
      title: t.ASQT(statusMsg),
      description: <ModificationStatus status={modificationStatus} noSubtext />,
    });
  }

  // Last Modified
  items.push({
    title: t.PM1H('Last modified'),
    description: catalog.lastModified
      ? `${formatTimestampToDDMMYYYY(catalog.lastModified)} ${formatTimeStampToHHmm(catalog.lastModified)}`
      : '-',
  });

  // Billing account
  items.push({
    title: t.IFT9(billingAccountMsg),
    description: selectBillingAccount ? (
      <BillingAccountSelection
        billingAccounts={selectBillingAccount.billingAccounts?.searchResults?.map(res => res.result)}
        currentBillingAccountId={catalog.billingAccountId}
        onUpdateBillingAccount={() => {
          navigate('', {
            state: {
              catalog,
              virtualCatalog: selectBillingAccount.virtualCatalog,
              type: WizardType.CHANGE_CATALOG_BILLING_ACCOUNT,
            },
          });
        }}
      />
    ) : (
      billingAccount
    ),
  });

  // Corporate share
  if (catalog.productType === Catalog.ProductTypeEnum.EPP_RECURRING) {
    items.push({
      title: `${t.TH83(companysShareOfFeeMsg)} (${t.S8TX(alvZeroMsg)})`,
      description: getCorporateShareAsText(catalog.corporateShare),
    });
  }

  // Product type
  items.push({
    title: t.R6HP(productTypeMsg),
    description: catalog.productType && productTypeDetails()[catalog.productType].displayValue,
  });

  // Contract period
  items.push({
    title: t.ULI0(agreementPeriodMsg),
    description: catalog.contractPeriod ? `${catalog.contractPeriod} ${t.XXVX(monthMsg)}` : '-',
  });

  // Damage insurance
  items.push({
    title: t.QSXP(damageInsuranceMsg),
    description:
      catalog.damageInsurance && catalog.damageInsurance.length > 0
        ? getDamageInsuranceDisplayableItems(catalog.damageInsurance)
        : t.MUF5(notInUseMsg),
  });

  // Device enrollment program
  items.push({
    title: t.Q03S('Device enrollment program'),
    description: catalog.enrollmentProgramConsent
      ? `${t.V34H(inUseMsg)}, alias: ${catalog.enrollmentProgramAlias || '-'}`
      : t.MUF5(notInUseMsg),
  });

  return (
    <div className="of-virtual-catalog-configuration">
      <CL.Description items={items} />

      <CL.Description
        className="ds-margin-top--5"
        columns={1}
        items={[
          {
            title: t.C8DA(catalogCorporateMessageLabelMsg),
            description: catalog.corporateMessage || t.MUF5(notInUseMsg),
          },
        ]}
      />
    </div>
  );
};

interface VirtualCatalogInformationProps {
  billingAccounts?: BillingAccountsState;
  catalog: Catalog;
  virtualCatalog: VirtualCatalog;
}

export const VirtualCatalogInformation = (props: VirtualCatalogInformationProps) => {
  const { catalog, virtualCatalog, billingAccounts } = props;
  const modificationStatus = getCatalogModificationStatus(virtualCatalog)?.type;
  return (
    <VirtualCatalogInformationForStatus
      catalog={catalog}
      modificationStatus={modificationStatus}
      selectBillingAccount={{
        virtualCatalog: virtualCatalog,
        billingAccounts: billingAccounts,
      }}
    />
  );
};
