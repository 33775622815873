import * as CL from '@design-system/component-library';
import { t } from '../../common/i18n/index.js';
import type { ChangeEvent, ReactNode } from 'react';

interface ShowAllAccountsToggleProps {
  handleSearchAllAccountsChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isSearchAllAccounts?: boolean;
  children?: ReactNode;
  message?: string;
  tooltipText?: string;
  className?: string;
}
export const ShowAllAccountsToggle = ({
  handleSearchAllAccountsChange,
  isSearchAllAccounts = false,
  children,
  message,
  tooltipText,
  className,
}: ShowAllAccountsToggleProps) => (
  <div className={`of-all-accounts-toggle ds-display--flex ${className || ''}`}>
    <div className="ds-display--flex ds-align-items--center">
      <CL.Toggle
        className="ds-margin-bottom--0"
        value={isSearchAllAccounts}
        onToggle={handleSearchAllAccountsChange}
        size="s"
      />
      <span className="ds-margin-left--2 ds-text--s">
        {message ? message : t.EPWH('Search all accessible accounts')}
      </span>
      <div className="ds-margin-left--2 ds-padding-top--1">
        <CL.Tooltip
          triggerElement={<CL.Icon icon="information" size="s" type="light" color="black" />}
          placement="right"
          i18n_tooltip_contentText={
            tooltipText
              ? tooltipText
              : t.B0GQ('You can see search result from all the companies you have admin rights.')
          }
        />
      </div>
    </div>
    {children}
  </div>
);
