import { t } from '../../common/i18n/index.js';

import './AuthorizationSuccessfulScene.scss';

export const AuthorizationSuccessfulScene = () => {
  return (
    <div className="of-authorization-successful-scene ds-margin-top--10" id="authorization-successful-scene">
      <div className="of-authorization-successful-scene__thumb-up" />
      <h2>{t.I281('Thank you, verification succeeded!')}</h2>
      <div>{t.BXJH('Your number will be transferred to the new owner.')}</div>
    </div>
  );
};
