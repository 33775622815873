import { AddOrSelectBillingAccounts } from '../AddOrSelectBillingAccounts/AddOrSelectBillingAccounts.js';
import {
  CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE,
  getEmptyBillingAccount,
  getReceiverType,
  prepareBillingAccountSave,
} from '../../common/utils/billingAccountUtils.js';
import { Catalog } from '../../generated/api/models.js';
import { DialogType, InProgressCatalogAction } from '../../common/enums.js';
import {
  EditAgreementPeriod,
  EditCatalogButtonGroup,
  EditCorporateMessage,
  EditCorporateShare,
  EditDamageInsurance,
  EditName,
  EditProductType,
} from './VirtualCatalogPartials.js';
import { EnrollmentProgramAlias } from '../EnrollmentProgramConsent/EnrollmentProgramConsent.js';
import { Link } from 'react-router-dom';
import { Multiselector } from '../Multiselector/index.js';
import { VirtualCatalogInformation } from './VirtualCatalogInformation.js';
import {
  billingDetailsMsg,
  catalogCorporateMessageExampleMsg,
  catalogDetailsMsg,
  connectDeviceToRegistrationProgramMsg,
  deviceEnrollmentProgramAliasHelpMsg,
  deviceEnrollmentProgramMsg,
  deviceToEnrollmentProgramMsg,
  deviceToEnrollmentReadMoreMsg,
  t,
} from '../../common/i18n/index.js';
import { centsToEuros, parseCurrencyToNumber } from '../../common/utils/priceUtils.js';
import {
  convertStringMapToCommonErrors,
  convertToErrorStringMap,
  getElementsWithErrors,
} from '../../common/utils/errorUtils.js';
import { getTopMostElement } from '../../common/utils/domUtils.js';
import { isEppSolutionActive } from '../../common/utils/stateUtils.js';
import { onUpsertVirtualCatalog } from '../../common/utils/dispatcherUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { productTypeDetails } from '../../common/utils/catalogUtils.js';
import { resetErrors, setEditingVirtualCatalog } from '../../selfservice/actions/index.js';
import { scrollTo } from '../../common/utils/browserUtils.js';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import type { BillChannel, BillingAccount, Contact, EppCategory, VirtualCatalog } from '../../generated/api/models.js';
import type { BillingAccountOrErrorSupplier, CommonError } from '../../common/types/errors.js';
import type { BillingAccountsState, CompanyInfoState } from '../../common/types/states.js';
import type { ChangeEvent } from 'react';
import type { DialogParams } from '../../common/types/dialog.js';
import type { MultiselectorItem } from '../Multiselector/index.js';
import type { RadioProps } from '@design-system/component-library';

import './VirtualCatalogConfiguration.scss';

export interface VirtualCatalogConfigurationProps {
  billChannels?: BillChannel[];
  billingAccounts?: BillingAccountsState;
  catalog: Catalog;
  companyInfo: CompanyInfoState;
  contacts?: Contact[];
  isEditingCatalog: boolean;
  errors?: CommonError[];
  inProgressAction?: InProgressCatalogAction;
  virtualCatalog: VirtualCatalog;
  onShowDialog: (params: DialogParams) => void;
}

export const VirtualCatalogConfiguration = ({
  billChannels,
  billingAccounts,
  catalog,
  companyInfo,
  contacts,
  isEditingCatalog,
  errors,
  inProgressAction,
  virtualCatalog,
  onShowDialog,
}: VirtualCatalogConfigurationProps): JSX.Element => {
  const dispatch = useDispatch();
  const [corporateMessage, setCorporateMessage] = useState(catalog.corporateMessage);
  const [isCorporateMessageAdded, setIsCorporateMessageAdded] = useState(!!catalog.corporateMessage);
  const [name, setName] = useState<string | undefined>(catalog.name);
  const [corporateShare, setCorporateShare] = useState(
    catalog.corporateShare != null ? centsToEuros(catalog.corporateShare).toString().replace('.', ',') : ''
  );
  const [productType, setProductType] = useState<Catalog.ProductTypeEnum>(catalog.productType);
  const [contractPeriod, setContractPeriod] = useState(catalog.contractPeriod || undefined);
  const [damageInsurance, setDamageInsurance] = useState(catalog.damageInsurance || []);
  const [enrollmentProgramConsent, setEnrollmentProgramConsent] = useState(catalog.enrollmentProgramConsent ?? false);
  const [enrollmentProgramAlias, setEnrollmentProgramAlias] = useState(catalog.enrollmentProgramAlias || '');
  const domRefs = useRef<{ [elementKey: string]: HTMLElement }>({});
  const setRefCallback = (key: string, ref: HTMLElement | null) => {
    if (ref) {
      domRefs.current[key] = ref;
    } else {
      delete domRefs.current[key];
    }
  };

  const [touched, setTouched] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [shouldScrollToError, setShouldScrollToError] = useState(false);
  const [selectedBaId, setSelectedBaId] = useState(catalog?.billingAccountId);
  const prepareNewBillingAccountSaveValues = useRef<BillingAccountOrErrorSupplier>();

  useEffect(() => {
    const combinedErrors = [...(errors || []), ...(billingAccounts?.errors || [])];
    if (shouldScrollToError && combinedErrors.length) {
      scrollTo(getTopMostElement(getElementsWithErrors(domRefs.current, combinedErrors)));
      setShouldScrollToError(false);
    }
  }, [errors, billingAccounts?.errors]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  const getError = (key: string) => {
    if (hasFocus || touched) {
      return undefined;
    }
    const errorsMap = convertToErrorStringMap(errors);
    return errorsMap && errorsMap[key];
  };

  const onFocus = () => {
    setHasFocus(true);
    setTouched(true);
  };

  const onBlur = () => {
    setHasFocus(false);
  };

  useEffect(() => {
    setIsCorporateMessageAdded(!!catalog.corporateMessage);
    setCorporateMessage(catalog.corporateMessage);
    setName(catalog.name);
    setCorporateShare(
      catalog.corporateShare != null ? centsToEuros(catalog.corporateShare).toString().replace('.', ',') : ''
    );
    setContractPeriod(catalog.contractPeriod || undefined);
  }, [catalog]);

  const getProductTypeDropDownOption = (value: Catalog.ProductTypeEnum): RadioProps => ({
    value,
    label: productTypeDetails()[value].displayValue,
  });

  const getProductTypeOptions = (companyInfoValue: CompanyInfoState): RadioProps[] => {
    if (isEppSolutionActive(companyInfoValue)) {
      const options = [getProductTypeDropDownOption(Catalog.ProductTypeEnum.EPP_RECURRING)];
      if (companyInfoValue.eppSolution?.allowDevicesWithRecurringCharges) {
        options.push(getProductTypeDropDownOption(Catalog.ProductTypeEnum.RECURRING));
      }
      if (companyInfoValue.eppSolution?.allowDevicesWithOneTimeCharges) {
        options.push(getProductTypeDropDownOption(Catalog.ProductTypeEnum.ONETIME));
      }
      return options;
    } else {
      return [
        getProductTypeDropDownOption(Catalog.ProductTypeEnum.RECURRING),
        getProductTypeDropDownOption(Catalog.ProductTypeEnum.ONETIME),
      ];
    }
  };

  if (!isEditingCatalog) {
    return (
      <VirtualCatalogInformation virtualCatalog={virtualCatalog} billingAccounts={billingAccounts} catalog={catalog} />
    );
  }

  return (
    <div className="of-virtual-catalog-configuration of-virtual-catalog-configuration--editing">
      <div>
        <h4 className="ea-h4">{t.XE4X(catalogDetailsMsg)}</h4>
        <EditName
          {...{
            name,
            onUpdate: (newName: string | undefined) => {
              setName(newName);
            },
            getError: () => getError('name'),
            setRefCallback: (element: HTMLElement | null) => setRefCallback('name', element),
            onBlur,
            onFocus,
          }}
        />
      </div>
      <div>
        <h4 className="ea-h4">{t.RPMR(billingDetailsMsg)}</h4>
        <AddOrSelectBillingAccounts
          addNewBA={true}
          detailedView={false}
          billingAccounts={billingAccounts}
          billingAccountsEditingChanges={billingAccounts?.editingChanges}
          billingAccountsErrors={billingAccounts?.errors}
          billChannels={billChannels}
          companyInfo={companyInfo}
          contacts={contacts}
          prepareNewBillingAccountSaveValues={prepareSaveValues =>
            (prepareNewBillingAccountSaveValues.current = prepareSaveValues)
          }
          getBillingAccountIdOnchange={billingAccountId => {
            if (billingAccountId !== CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE) {
              prepareNewBillingAccountSaveValues.current = undefined;
            }
            setSelectedBaId(billingAccountId);
          }}
          saving={false}
          selectedBaId={selectedBaId}
          setRefCallBack={setRefCallback}
          title=""
        />
      </div>
      <EditProductType
        productTypeOptions={getProductTypeOptions(companyInfo)}
        productType={productType}
        onUpdate={(newValue: Catalog.ProductTypeEnum) => {
          setProductType(newValue);
          if (newValue === Catalog.ProductTypeEnum.ONETIME) {
            setDamageInsurance([]);
            setContractPeriod(undefined);
            setCorporateShare('');
          }
          if (newValue === Catalog.ProductTypeEnum.RECURRING) {
            setDamageInsurance([]);
            setContractPeriod(contractPeriod ?? catalog.contractPeriod ?? 24);
            setCorporateShare('');
          }
          if (newValue === Catalog.ProductTypeEnum.EPP_RECURRING) {
            setDamageInsurance(catalog.damageInsurance || []);
            setContractPeriod(contractPeriod ?? catalog.contractPeriod ?? 24);
          }
        }}
      />
      <EditAgreementPeriod
        {...{
          productType,
          contractPeriod,
          onUpdate: (newContractPeriod: number) => {
            setContractPeriod(newContractPeriod);
          },
          setRefCallback: (element: HTMLElement | null) => setRefCallback('agreementPeriod', element),
          onFocus,
        }}
      />
      {isEppSolutionActive(companyInfo) && (
        <EditCorporateShare
          {...{
            productType,
            corporateShare,
            onUpdate: (newValue: string) => {
              setCorporateShare(newValue.replace('.', ','));
            },
          }}
        />
      )}
      <EditDamageInsurance
        {...{
          damageInsurance,
          productType,
          onUpdate: (newItem: MultiselectorItem) => {
            if (newItem.valueRef) {
              newItem.checked
                ? setDamageInsurance([...damageInsurance, newItem.valueRef as EppCategory])
                : setDamageInsurance([
                    ...damageInsurance.slice(0, damageInsurance.indexOf(newItem.valueRef as EppCategory)),
                    ...damageInsurance.slice(damageInsurance.indexOf(newItem.valueRef as EppCategory) + 1),
                  ]);
            }
          },
        }}
      />
      <EditCorporateMessage
        isCorporateMessageAdded={isCorporateMessageAdded}
        corporateMessage={corporateMessage}
        inputClassNames="of-catalog-details-editable__corporateMessage"
        onShowDialog={() =>
          onShowDialog({
            body: <>{`${t.Y0HD(catalogCorporateMessageExampleMsg)}.`}</>,
            header: '',
            type: DialogType.GENERIC_INFO_DIALOG,
          })
        }
        onUpdate={(messageSelected: boolean, message: string | undefined) => {
          setIsCorporateMessageAdded(messageSelected);
          setCorporateMessage(message);
        }}
        getError={() => getError('corporateMessage')}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      <div id="enrollmentProgramConsent">
        <h4>{t.S14B(deviceEnrollmentProgramMsg)}</h4>
        <p>
          {t.ZIH3(deviceToEnrollmentProgramMsg)}{' '}
          <Link to={paths.DEVICE_ENROLLMENT}>{t.X2UI(deviceToEnrollmentReadMoreMsg)}</Link>
        </p>
        <p>{t.CSOC(deviceEnrollmentProgramAliasHelpMsg)}</p>
        <Multiselector
          {...{
            chainCheckboxSelection: false,
            items: [
              { displayValue: () => t.MNEQ(connectDeviceToRegistrationProgramMsg), checked: enrollmentProgramConsent },
            ],
            onItemChange: newItem =>
              newItem.checked ? setEnrollmentProgramConsent(newItem.checked) : setEnrollmentProgramConsent(false),
          }}
        />
        {enrollmentProgramConsent && (
          <EnrollmentProgramAlias
            onValueChange={(ev: ChangeEvent<HTMLInputElement>) => setEnrollmentProgramAlias(ev?.currentTarget?.value)}
            value={enrollmentProgramAlias}
          />
        )}
      </div>
      <EditCatalogButtonGroup
        {...{
          isSaving: inProgressAction === InProgressCatalogAction.UPSERT_VIRTUAL_CATALOG_DRAFT,
          onSave: () => {
            setHasFocus(false);
            setTouched(false);
            setShouldScrollToError(true);
            let newBillingAccount: BillingAccount | undefined;
            let newBillingAccountValidationErrors: CommonError[] | undefined;
            if (prepareNewBillingAccountSaveValues.current) {
              const { obj, validationErrors } = prepareNewBillingAccountSaveValues.current();
              const values = obj as BillingAccount;
              const receiverType = getReceiverType(values);
              newBillingAccount = prepareBillingAccountSave(
                getEmptyBillingAccount(companyInfo.companyName || ''),
                values,
                receiverType
              );
              newBillingAccountValidationErrors = convertStringMapToCommonErrors(validationErrors);
            }
            onUpsertVirtualCatalog(dispatch)(
              {
                ...catalog,
                ...{
                  name,
                  corporateShare: parseCurrencyToNumber(corporateShare),
                  corporateMessage: corporateMessage ?? undefined,
                  productType,
                  contractPeriod,
                  damageInsurance,
                  enrollmentProgramConsent,
                  enrollmentProgramAlias,
                  billingAccountId: selectedBaId,
                },
              },
              false,
              virtualCatalog,
              newBillingAccount,
              newBillingAccountValidationErrors,
              undefined,
              true
            );
          },
          onCancel: () => {
            dispatch(resetErrors());
            setHasFocus(true);
            setTouched(true);
            dispatch(setEditingVirtualCatalog(false));
            setName(catalog.name);
            setCorporateShare(
              catalog.corporateShare != null ? centsToEuros(catalog.corporateShare).toString().replace('.', ',') : ''
            );
            setProductType(catalog.productType);
            setContractPeriod(catalog.contractPeriod || undefined);
            setDamageInsurance(catalog.damageInsurance || []);
            setEnrollmentProgramConsent(catalog.enrollmentProgramConsent ?? false);
            setEnrollmentProgramAlias(catalog.enrollmentProgramAlias || '');
          },
        }}
      />
    </div>
  );
};
