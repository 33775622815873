import * as CL from '@design-system/component-library';
import { t } from '../../common/i18n/index.js';
import { useState } from 'react';

import './ToggleWithSubComponent.scss';

export interface ToggleWithSubComponentProps {
  name: string;
  title: string;
  infoText?: string;
  initialValue?: boolean;
  disabled?: boolean;
  onToggle: (value: boolean) => void;
  subComponent: JSX.Element;
  showSubComponentWhenToggled?: boolean;
}

export const ToggleWithSubComponent = ({
  name,
  title,
  infoText,
  initialValue = false,
  disabled = false,
  onToggle,
  subComponent,
  showSubComponentWhenToggled = true,
}: ToggleWithSubComponentProps) => {
  const [isToggled, setIsToggled] = useState(initialValue);
  const icon = <CL.Icon className="info-icon" icon="information" type="regular" color="blue-600" />;
  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsToggled(e.currentTarget.checked);
    onToggle(e.currentTarget.checked);
  };

  return (
    <div className="of-toggle-with-subcomponent" id={name}>
      <div className="ds-padding-top--3 ds-margin-bottom--4">
        <h4 className="ds-display--inline">
          {title.split(' ').slice(0, -1).join(' ')}{' '}
          <span className="ds-white-space--nowrap">
            {title.split(' ').slice(-1)}
            {infoText && (
              <span className="info-icon-container">
                <CL.Tooltip
                  triggerElement={icon}
                  placement="right"
                  i18n_tooltip_contentText={infoText}
                  className="tooltip"
                />
              </span>
            )}
          </span>
        </h4>
      </div>
      <div className="ds-display--flex ds-align-items--center ds-padding-bottom--4">
        <CL.Toggle className="ds-margin-bottom--0" onToggle={handleToggle} value={initialValue} disabled={disabled} />
        <span className="ds-margin-left--2">{isToggled ? t.M03S('Yes') : t.A37D('No')}</span>
      </div>
      {isToggled === showSubComponentWhenToggled && <div className="ds-margin-top--1">{subComponent}</div>}
    </div>
  );
};
