import * as CL from '@design-system/component-library';
import * as RP from '../../legacy/react-patterns/source/index.js';
import * as React from 'react';
import { Anchor } from '../Anchor/Anchor.js';
import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { CURRENCY_INPUT_REGEX, HTML_TAG_REGEX } from '../../common/utils/validationUtils.js';
import { Catalog } from '../../generated/api/models.js';
import { Grid } from '../Grid/Grid.js';
import { LabelWithTooltip } from '../LabelWithTooltip/LabelWithTooltip.js';
import { Multiselector } from '../Multiselector/index.js';
import { StickyFooter } from '../StickyFooter/index.js';
import {
  addBillingAgreementMsg,
  agreementPeriodMsg,
  alvZeroMsg,
  catalogCorporateMessageExampleLinkMsg,
  catalogCorporateMessageHelpMsg,
  catalogCorporateMessageInstructionMsg,
  catalogCorporateMessageLabelMsg,
  catalogCorporateMessageSelectorMsg,
  companysShareOfMonthlyFeeMsg,
  damageInsuranceCoverMsg,
  damageInsuranceMsg,
  enterNumberOnlyMsg,
  nameOfCatalogMsg,
  optionalMsg,
  productTypeMsg,
  saveMsg,
  t,
} from '../../common/i18n/index.js';
import { buildPathVariable, paths } from '../../common/constants/pathVariables.js';
import { damageInsuranceItem, getContractPeriodItems } from '../../common/utils/catalogUtils.js';
import { useNavigate } from 'react-router-dom';
import type { BillingAccountHeader, EppCategory } from '../../generated/api/models.js';
import type { MultiselectorItem } from '../Multiselector/index.js';
import type { RadioProps } from '@design-system/component-library';

const IncompatibleProductsWarning = () => (
  <span>
    {t.E1LA(
      'Please note that some products in catalog might be incompatible with the new product type or contract period and will be removed from catalog after saving the changes'
    )}
  </span>
);

export const EditCorporateMessage = (corporateMessageProps: {
  isCorporateMessageAdded?: boolean;
  corporateMessage?: string;
  inputClassNames: string;
  onShowDialog: () => void;
  onUpdate: (messageSelected: boolean, message: string | undefined) => void;
  getError: () => string | undefined;
  onBlur: () => void;
  onFocus: () => void;
}): JSX.Element => {
  const {
    isCorporateMessageAdded,
    corporateMessage,
    inputClassNames,
    onShowDialog,
    onUpdate,
    getError,
    onBlur,
    onFocus,
  } = corporateMessageProps;

  return (
    <div id="corporateMessage">
      <h4 className="ea-h4">{t.C8DA(catalogCorporateMessageLabelMsg)}</h4>
      <p>
        {t.Q57Q(catalogCorporateMessageInstructionMsg)}{' '}
        <Anchor onClick={onShowDialog}>{t.ZU62(catalogCorporateMessageExampleLinkMsg)}</Anchor>
      </p>
      <Multiselector
        {...{
          chainCheckboxSelection: false,
          items: [{ displayValue: () => t.FFQZ(catalogCorporateMessageSelectorMsg), checked: isCorporateMessageAdded }],
          onItemChange: newItem => {
            onUpdate(!!newItem.checked, newItem.checked ? '' : undefined);
          },
        }}
      />
      <div>
        <RP.Input
          className={inputClassNames}
          elementType="textarea"
          textareaRows={5}
          wide={true}
          type="text"
          helpText={t.HQTV(catalogCorporateMessageHelpMsg)}
          required={true}
          error={getError()}
          tightBottom={true}
          placeholder=""
          name="corporateMessage"
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            if (!HTML_TAG_REGEX.test(e.currentTarget.value)) {
              onUpdate(true, e.currentTarget.value);
            }
          }}
          value={corporateMessage}
          disableAutoStatus={true}
          onBlur={onBlur}
          onFocus={onFocus}
          htmlValidation={true}
          disabled={!isCorporateMessageAdded}
          maxLength={500}
        />
      </div>
    </div>
  );
};

export const EditName = (editNameProps: {
  name?: string;
  onUpdate: (newName: string) => void;
  setRefCallback: (element: HTMLElement | null) => void;
  getError: () => string | undefined;
  onBlur: () => void;
  onFocus: () => void;
}): JSX.Element => {
  const { name, onUpdate, setRefCallback, getError, onBlur, onFocus } = editNameProps;
  return (
    <RP.FluidGridItem phone={6}>
      <div id="name" ref={(element: HTMLElement | null) => setRefCallback(element)}>
        <RP.Input
          elementType="input"
          type="text"
          className="of-catalog-details-editable__name--input"
          error={getError()}
          htmlValidation={true}
          required={true}
          tightBottom={true}
          name="catalog-name"
          value={name}
          label={t.M6TP(nameOfCatalogMsg)}
          onChange={(e: React.FormEvent<HTMLInputElement>) => onUpdate(e.currentTarget.value)}
          onBlur={onBlur}
          onFocus={onFocus}
          maxLength={65}
          helpText={t.WYPE('Max 65 characters')}
        />
      </div>{' '}
    </RP.FluidGridItem>
  );
};

export const EditCorporateShare = (eppSolutionProps: {
  productType: string;
  corporateShare: string;
  onUpdate: (newValue: string) => void;
}): JSX.Element => {
  const { productType, corporateShare, onUpdate } = eppSolutionProps;
  return (
    <RP.FluidGridItem phone={6}>
      <div id="corporateShare">
        <h4 className="ea-h4 ">{`${t.H8Q4(companysShareOfMonthlyFeeMsg)} (${t.S8TX(alvZeroMsg)})`}</h4>
        <RP.FluidGrid className="of-catalog-details-editable__corporateShare">
          <RP.FluidGridItem laptop={11} phone={5}>
            <RP.Input
              elementType="input"
              type="text"
              className="of-catalog-details-editable__corporateShare--input"
              tightBottom={true}
              name="catalog-corporateShare"
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                if (e.currentTarget.value === '' || CURRENCY_INPUT_REGEX.test(e.currentTarget.value)) {
                  onUpdate(e.currentTarget.value);
                }
              }}
              value={productType !== Catalog.ProductTypeEnum.EPP_RECURRING ? '' : corporateShare}
              onBlur={() => {
                if (corporateShare?.endsWith(',')) {
                  onUpdate(corporateShare.substring(0, corporateShare.length - 1));
                }
              }}
              optionalText={t.G7RQ(optionalMsg)}
              disableAutoStatus={true}
              htmlValidation={true}
              htmlValidationError={t.L1T4(enterNumberOnlyMsg)}
              disabled={productType !== Catalog.ProductTypeEnum.EPP_RECURRING}
            />
          </RP.FluidGridItem>
          <RP.FluidGridItem phone={1}></RP.FluidGridItem>
        </RP.FluidGrid>
      </div>
    </RP.FluidGridItem>
  );
};

export const EditProductType = (editProductTypeElementProps: {
  productTypeOptions: RadioProps[];
  productType: string;
  onUpdate: (newValue: Catalog.ProductTypeEnum) => void;
}) => {
  const { productTypeOptions, productType, onUpdate } = editProductTypeElementProps;
  return (
    <div>
      <LabelWithTooltip className="ea-h4 ea-m-b-1" labelText={t.R6HP(productTypeMsg)}>
        <IncompatibleProductsWarning />
      </LabelWithTooltip>
      <CL.Radio.Group value={productType}>
        {productTypeOptions.map(option => (
          <CL.Radio {...option} key={option.value} onChange={() => onUpdate(option.value as Catalog.ProductTypeEnum)} />
        ))}
      </CL.Radio.Group>
    </div>
  );
};

export const EditAgreementPeriod = (editAgreementPeriodElementProps: {
  productType: Catalog.ProductTypeEnum;
  contractPeriod?: number;
  onUpdate: (newContractPeriod: number) => void;
  setRefCallback: (element: HTMLElement | null) => void;
  onFocus: () => void;
}): JSX.Element => {
  const { productType, contractPeriod, onUpdate, setRefCallback, onFocus } = editAgreementPeriodElementProps;
  return (
    <div ref={setRefCallback}>
      <LabelWithTooltip className="ea-h4 ea-m-b-1" labelText={t.ULI0(agreementPeriodMsg)}>
        <IncompatibleProductsWarning />
      </LabelWithTooltip>
      <CL.Radio.Group
        disabled={productType === Catalog.ProductTypeEnum.ONETIME}
        value={contractPeriod ? contractPeriod.toString() : ''}
      >
        {getContractPeriodItems().map(option => (
          <CL.Radio
            {...option}
            key={option.value}
            onChange={() => {
              const newContractPeriod = parseInt(option.value as string, 10);
              if (newContractPeriod) {
                onUpdate(newContractPeriod);
                onFocus();
              }
            }}
          />
        ))}
      </CL.Radio.Group>
    </div>
  );
};

export const EditDamageInsurance = (editDamageInsuranceProps: {
  damageInsurance: EppCategory[];
  productType: Catalog.ProductTypeEnum;
  onUpdate: (newItem: MultiselectorItem) => void;
}): JSX.Element => {
  const { damageInsurance, productType, onUpdate } = editDamageInsuranceProps;

  return (
    <div id="damageInsurance">
      <h4 className="ea-h4">{t.QSXP(damageInsuranceMsg)}</h4>
      <p>{t.FLLT(damageInsuranceCoverMsg)}</p>
      <Multiselector
        {...{
          chainCheckboxSelection: false,
          items: damageInsuranceItem.map(item => ({
            ...item,
            checked: damageInsurance.includes(item.valueRef),
          })),
          onItemChange: newItem => {
            onUpdate(newItem);
          },
          disableAll:
            productType === Catalog.ProductTypeEnum.ONETIME || productType === Catalog.ProductTypeEnum.RECURRING,
        }}
      />
    </div>
  );
};

export const EditCatalogButtonGroup = (editCatalogButtonElementProps: {
  isSaving?: boolean;
  onSave?: () => void;
  onCancel: () => void;
  saveDisabled?: boolean;
}): JSX.Element => {
  return (
    <StickyFooter active={true}>
      <Grid>
        <ButtonGroupForSubmitAndBack
          className="ds-text-align--right ds-margin-vertical--3"
          onCancel={() => {
            editCatalogButtonElementProps.onCancel();
          }}
          onSubmit={() => {
            editCatalogButtonElementProps.onSave?.();
          }}
          submitButtonText={t.NIWE(saveMsg)}
          submitting={editCatalogButtonElementProps.isSaving}
          size="l"
          submitDisabled={editCatalogButtonElementProps.saveDisabled}
        />
      </Grid>
    </StickyFooter>
  );
};

export const BillingAccountSelection = (billingAccountSelectionProps: {
  billingAccounts?: BillingAccountHeader[];
  currentBillingAccountId?: string;
  onUpdateBillingAccount: () => void;
}): JSX.Element => {
  const navigate = useNavigate();
  const { billingAccounts, currentBillingAccountId, onUpdateBillingAccount } = billingAccountSelectionProps;

  if (billingAccounts) {
    const currentBillingAccount =
      currentBillingAccountId &&
      billingAccounts.find(billingAccount => billingAccount.billingAccountId === currentBillingAccountId);

    return (
      <span>
        {currentBillingAccount ? (
          <span>
            <Anchor
              className="of-catalog-details__to-billing-account"
              onClick={() => {
                if (currentBillingAccount.billingAccountDisplayId) {
                  navigate(buildPathVariable(paths.BILLING_ACCOUNT, currentBillingAccount.billingAccountDisplayId));
                }
              }}
            >
              {currentBillingAccount.billingAccountDisplayId}
            </Anchor>
          </span>
        ) : (
          <span>
            <Anchor className="of-catalog-details__change-billing-account" onClick={() => onUpdateBillingAccount()}>
              {t.G18X(addBillingAgreementMsg)}
            </Anchor>
          </span>
        )}
      </span>
    );
  }
  return <></>;
};
