import { EmployeeDeviceStoreHomeScene } from '../../../../../components/EmployeeDeviceStoreHomeScene/EmployeeDeviceStoreHomeScene.js';
import {
  clearCartItems,
  loadVirtualCatalogs,
  setSelectedVirtualCatalog,
} from '../../../../../selfservice/actions/index.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { getPublishedCatalogs } from '../../../../../common/utils/catalogUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { Catalog } from '../../../../../generated/api/models.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const useRedirectToDeviceStore = () => {
  const navigate = useNavigate();
  const selectedCatalogCode = useSelector((state: State) => state.selfservice?.virtualCatalogs?.selectedCatalogCode);

  useEffect(() => {
    // undefined is not yet found, null is found and not existing
    if (selectedCatalogCode === null) {
      navigate(paths.EMPLOYEE_DEVICE_STORE, { replace: true });
    }
  }, [navigate, selectedCatalogCode]);
};

export const EmployeeStore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedCatalogCode, virtualCatalogItems, cartItems } = useSelector(
    (state: State) => ({
      selectedCatalogCode: state.selfservice?.virtualCatalogs?.selectedCatalogCode,
      virtualCatalogItems: state.selfservice?.virtualCatalogs?.items,
      cartItems: state.deviceCheckout?.cartItems || [],
    }),
    deepEqual
  );
  const catalogs: Catalog[] = getPublishedCatalogs(virtualCatalogItems) || [];

  useEffect(() => {
    dispatch(loadVirtualCatalogs());
  }, [dispatch]);

  return (
    <EmployeeDeviceStoreHomeScene
      catalogs={catalogs}
      onClickDevices={category => {
        if (category === 'puhelimet') {
          navigate(paths.EMPLOYEE_DEVICE_STORE_PHONES);
        } else if (category === 'tabletit') {
          navigate(paths.EMPLOYEE_DEVICE_STORE_TABLETS);
        } else if (category === 'tietokoneet') {
          navigate(paths.EMPLOYEE_DEVICE_STORE_COMPUTERS);
        } else if (category === 'verkkolaitteet') {
          navigate(paths.EMPLOYEE_DEVICE_STORE_NETWORK_DEVICES);
        } else if (category === 'lisatarvikkeet') {
          navigate(paths.EMPLOYEE_DEVICE_STORE_ACCESSORIES);
        }
      }}
      onSelectCatalog={catalogCode => dispatch(setSelectedVirtualCatalog(catalogCode))}
      selectedCatalogCode={selectedCatalogCode}
      hasCartItems={cartItems.length > 0}
      clearCartItems={() => {
        dispatch(clearCartItems());
      }}
    />
  );
};
