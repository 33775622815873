import { LoadingDialog } from '../../../../components/LoadingDialog/LoadingDialog.js';
import { Outlet } from 'react-router-dom';
import { SubscriptionCategory } from '../../../../common/enums.js';
import {
  getCustomerLevelDiscountedPrices,
  getEmployeeSubscriptions,
  loadCompanyInfo,
  loadVirtualCatalogs,
  setFooterVisibility,
} from '../../../../selfservice/actions/index.js';
import { t } from '../../../../common/i18n/index.js';
import { useAuth } from '../../AuthProvider.js';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../../../selfservice/exports.js';

export const PunchoutPath = () => {
  const store = useStore<State>();
  const dispatch = useDispatch();
  const { authenticatedUser } = useAuth();
  const selfservice = store.getState().selfservice;
  const catalogs = Boolean(selfservice?.virtualCatalogs?.items);
  const discountedPrices = Boolean(selfservice?.companyInfo?.discountedPrices);
  const hasAddress = useSelector((state: State) => Boolean(state.selfservice?.companyInfo?.address));

  useEffect(() => {
    if (authenticatedUser?.userRole) {
      dispatch(setFooterVisibility(false));
      if (!catalogs) {
        dispatch(loadVirtualCatalogs());
      }
      if (!discountedPrices) {
        dispatch(getCustomerLevelDiscountedPrices());
      }
      if (!hasAddress) {
        dispatch(loadCompanyInfo());
      }
      dispatch(getEmployeeSubscriptions(SubscriptionCategory.DEVICE));
    }
  }, [catalogs, discountedPrices, dispatch, hasAddress, authenticatedUser]);

  return (
    <div id="employee" className="of-site--employee">
      {authenticatedUser?.userRole ? <Outlet /> : <LoadingDialog loadingText={t.OQUG('Moving to Employee OmaElisa')} />}
    </div>
  );
};
