import { LanguageSelector } from '../LanguageSelector.js';
import { SiteContext } from '../../../public/site/SiteContext.js';
import { appleWatchLoginScreenFooterMsg, backMsg, t } from '../../../common/i18n/index.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { fetchElisaIdAuthCode } from '../../../public/site/async/fetchElisaIdAuthCode.js';
import { useAuth } from '../../../public/site/AuthProvider.js';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import LoginForm from '@onlinefirst/elisaid-react-login-form';
import type { ElisaIdClientAttrs } from '../../../common/utils/elisaIdUtils.js';
import type { State } from '../../../selfservice/common/store.js';

interface ProcessElisaIdLoginResponseProps {
  authenticationMethod: string;
  token: string;
  isVerified: boolean;
}

export interface AuxiliaryEsimOrderLoginProps {
  client: ElisaIdClientAttrs;
  locale: string;
  onElisaIdLoginSuccess: (token: string, isElisaIdVerified: boolean, authenticationMethod: string) => void;
  onSelectLocale: (locale: string) => void;
}

export const AuxiliaryEsimOrderLogin = ({
  client,
  locale,
  onElisaIdLoginSuccess,
  onSelectLocale,
}: AuxiliaryEsimOrderLoginProps) => {
  const { siteBaseUrl } = useContext(SiteContext);
  const elisaIdProps = useSelector((state: State) => state.config.elisaId, deepEqual);
  const { resetLoginForm } = useAuth();

  return (
    <>
      <LanguageSelector onChangeLocale={onSelectLocale} selectedLocale={locale} />
      <div id="elisaid-login-form-parent" className="ea-color-container ea-color-container--stretch of-login-form">
        <LoginForm
          client={client}
          // Configuration
          disableLinks={true}
          disableMobileLogin={true}
          showOtherSelfServiceChannels={false}
          footer={t.EDOH(appleWatchLoginScreenFooterMsg)}
          heading={t.QQJX('Add Elisa KelloSIM to your subscription by login to My OmaElisa')}
          locale={locale.substring(0, 2)}
          recoverPasswordHeaders={{
            headerTitle: t.SBDY('Add your email address'),
            subHeaderTitle: t.QD79(`We'll email you instructions how to reset your password`),
          }}
          resetLoginForm={resetLoginForm}
          returnToLoginButtonText={t.D1E9(backMsg)}
          // Links
          helpWithLoginLink={{
            title: t.FV8X('Help with login?'),
            url: `${siteBaseUrl}/info-omaelisa-tyontekijalle`,
          }}
          recoveryLink={{
            title: t.CSK0('I cannot remember my email address'),
            url: elisaIdProps?.recoveryUrl,
          }}
          registerLink={{
            title: t.XV9S(`I don't have Elisa ID yet or I can't remember it.`),
            url: elisaIdProps?.registrationUrl,
          }}
          // Functions
          getLoginAuthCode={fetchElisaIdAuthCode}
          getMobileLoginAuthCode={() => {}}
          loginSuccess={(loginResponse: ProcessElisaIdLoginResponseProps) => {
            onElisaIdLoginSuccess(loginResponse.token, loginResponse.isVerified, loginResponse.authenticationMethod);
          }}
        />
      </div>
    </>
  );
};
