import { CREATE_NEW_CONTACT_OPTION_VALUE } from '../../common/formik/Fields/index.js';
import { PurposeOfUse } from '../PurposeOfUse/PurposeOfUse.js';
import { SaveOrCancel } from './components/SaveOrCancel.js';
import { SubscriptionPbxDetails, SubscriptionStatusType, SubscriptionType } from '../../generated/api/models.js';
import { UserNumberAndPublicityFormWrapper } from './UserNumberAndPublicityFormWrapper.js';
import { UserNumberAndPublicityView } from './UserNumberAndPublicityView.js';
import { UserNumberPrivacy } from './components/UserNumberPrivacy.js';
import { WizardType } from '../../common/enums.js';
import {
  changeNumberPrivacyPublicToCustom,
  subscriptionActionsPendingDialog,
  subscriptionChangeContactDialog,
  subscriptionInActivationDialog,
  subscriptionRingSolutionDialog,
  subscriptionSuspendedDialog,
} from './UserNumberPublicityUtils.js';
import { changeSubscriptionUserInfo } from '../../selfservice/actions/index.js';
import { showBroadbandInvitationInfo } from '../SubscriptionDetails/BroadBandInvitation/bbInvitationUtils.js';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import type { ChangeSubscriptionUserInfoPartial } from '../../selfservice/actions/index.js';
import type { CompanyInfoState } from '../../common/types/states.js';
import type { Contact, DirectoryDetails, Subscription } from '../../generated/api/models.js';
import type { PurposeOfUseOrContact, SubscriptionStatus } from '../../common/types/subscription.js';
import type { UserNumberAndPublicityFormValues } from './UserNumberAndPublicityFormWrapper.js';

import './UserAndNumberPublicity.scss';

export interface UserAndNumberPublicityAttrs {
  contacts?: Contact[];
  numberPrivacyMobile?: DirectoryDetails.NumberDirectoryEnum;
  numberPrivacyCorporate?: DirectoryDetails.NumberDirectoryEnum;
  forceEditing?: boolean;
  companyInfo?: CompanyInfoState | null;
  subscription: Subscription;
  pbxType?: string;
  editingSection?: string;
  enableCostCenter?: boolean;
  isEmailMandatory?: boolean;
  isPhoneNumberMandatory?: boolean;
  isCostCenterMandatory?: boolean;
  purposeOfUseOrContact: PurposeOfUseOrContact;
  subscriptionStatus: SubscriptionStatus;
  siteBaseUrl: string;
  isSaving: boolean;
}

export const UserAndNumberPublicity = ({
  contacts,
  companyInfo,
  isCostCenterMandatory,
  isEmailMandatory,
  isPhoneNumberMandatory,
  purposeOfUseOrContact,
  pbxType,
  numberPrivacyMobile,
  numberPrivacyCorporate,
  forceEditing,
  subscription,
  subscriptionStatus,
  siteBaseUrl,
  isSaving,
}: UserAndNumberPublicityAttrs) => {
  const [editing, setEditing] = useState(forceEditing);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const mobileNumberPrivacy = changeNumberPrivacyPublicToCustom(numberPrivacyMobile);
  const corporateNumberPrivacy = changeNumberPrivacyPublicToCustom(numberPrivacyCorporate);

  const onSave = (values: UserNumberAndPublicityFormValues) => {
    const isRingEnabledForCompany = companyInfo?.pbxSolutions?.some(
      sub => sub.subscriptionType === SubscriptionType.MOBILE_PBX
    );
    const isRingEnabledForSub =
      subscription.details?.mobile?.pbxConfiguration?.pbxConfigurationDetails?.pbxType ===
      SubscriptionPbxDetails.PbxTypeEnum.RING;

    if (
      isRingEnabledForCompany &&
      isRingEnabledForSub &&
      (purposeOfUseOrContact.contactId !== values.contactId ||
        purposeOfUseOrContact.purposeOfUse !== values.purposeOfUse)
    ) {
      dispatch(subscriptionRingSolutionDialog(siteBaseUrl));
    } else if (subscription.subscriptionId) {
      // Handle EPP OmaLaiteLasku changes
      if (subscription.details?.device?.omaLaiteLasku && purposeOfUseOrContact.contactId !== values.contactId) {
        const changeSubscriptionUserInfoParams: ChangeSubscriptionUserInfoPartial = [
          subscription.subscriptionId,
          values,
          isCostCenterMandatory || false,
          isEmailMandatory || false,
          isPhoneNumberMandatory || false,
          [],
        ];

        navigate(pathname, {
          replace: true,
          state: {
            type: WizardType.CHANGE_EPP_OMALAITELASKU_BILLING_ACCOUNT,
            wizardType: WizardType.CHANGE_EPP_OMALAITELASKU_BILLING_ACCOUNT,
            billingAccountId: subscription.billingAccountId,
            changeSubscriptionUserInfoParams,
          },
        });
      } else {
        dispatch(
          changeSubscriptionUserInfo(
            subscription.subscriptionId,
            values,
            isCostCenterMandatory || false,
            isEmailMandatory || false,
            isPhoneNumberMandatory || false
          )
        );
      }
    }
  };

  const onEdit = () => {
    if (subscription.subscriptionStatus === SubscriptionStatusType.SUSPENDED) {
      dispatch(subscriptionSuspendedDialog(subscription));
    } else if (subscriptionStatus.pendingActions) {
      dispatch(subscriptionActionsPendingDialog());
    } else if (subscription.subscriptionStatus === SubscriptionStatusType.IN_ACTIVATION) {
      dispatch(subscriptionInActivationDialog());
    } else {
      setEditing(true);
    }
  };

  const onChangeContact = (
    selectedContactId: string,
    resetField: (name: string, options?: Record<string, boolean>) => void
  ) => {
    if (
      selectedContactId &&
      selectedContactId !== purposeOfUseOrContact?.contactId &&
      selectedContactId !== CREATE_NEW_CONTACT_OPTION_VALUE
    ) {
      dispatch(subscriptionChangeContactDialog(subscription, resetField));
    }
  };

  return (
    <div id="purpose-of-use" className="of-purpose-of-use">
      <div className="ds-margin-bottom--8">
        <UserNumberAndPublicityFormWrapper
          purposeOfUseOrContact={purposeOfUseOrContact}
          onSuccess={data => {
            setEditing(false);
            onSave(data);
          }}
          numberPrivacyMobile={mobileNumberPrivacy}
          numberPrivacyCorporate={corporateNumberPrivacy}
          companyInfo={companyInfo}
        >
          <>
            {editing || isSaving ? (
              <>
                <PurposeOfUse
                  contacts={contacts}
                  isCostCenterMandatory={isCostCenterMandatory}
                  isPhoneNumberMandatory={isPhoneNumberMandatory}
                  isEmailMandatory={isEmailMandatory}
                  onChangeContact={onChangeContact}
                />
                <UserNumberPrivacy
                  pbxType={pbxType}
                  corporateDirectoryDetails={
                    purposeOfUseOrContact.directoryListingCorporateNumberPublicity?.pbxDirectoryDetails
                  }
                  voiceDirectoryDetails={
                    purposeOfUseOrContact.directoryListingMobileNumberPublicity?.voiceDirectoryDetails
                  }
                />
                <SaveOrCancel
                  onCancel={() => {
                    setEditing(false);
                  }}
                  isSaving={isSaving}
                />
              </>
            ) : (
              <UserNumberAndPublicityView
                purposeOfUseOrContact={purposeOfUseOrContact}
                contacts={contacts}
                onEdit={onEdit}
                numberPrivacyMobile={mobileNumberPrivacy}
                numberPrivacyCorporate={corporateNumberPrivacy}
                pbxType={pbxType}
                showBroadbandInvitationInfo={showBroadbandInvitationInfo(subscription)}
              />
            )}
          </>
        </UserNumberAndPublicityFormWrapper>
      </div>
    </div>
  );
};
