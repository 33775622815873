import { convertToCommonErrors } from '../utils/errorUtils.js';
import { fetchEsSubscriptions } from '../fetch.js';
import { getFilterValues } from '../../components/SubscriptionLists/subscriptionListUtils.js';
import { getItemsPerPageFromOptionsOrDefault } from '../../components/Table/index.js';
import { getSubscriptionTypes } from '../../public/common/util/category.js';
import { replacePipeWithCommaInQueryParams } from '../utils/filterUtils.js';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from './useSearchParams.js';
import type { CommonError } from '../types/errors.js';
import type { DefaultListSearchParams } from '../../components/Table/index.js';
import type { SubscriptionCategory, SubscriptionFilterValue } from '../enums.js';
import type { SubscriptionSearchResponse } from '../../generated/api/subscriptionSearchResponse.js';
import type { SubscriptionsDeviceMultiFilter } from '../../components/SubscriptionLists/SubscriptionTable.js';
import type { SubscriptionsResponse } from '../../generated/api/subscriptionsResponse.js';

export interface SubscriptionResult {
  loading: boolean;
  subscriptions?: {
    searchResults?: SubscriptionSearchResponse[];
    total?: number;
  };
  errors: CommonError[];
}

// useSubscriptions loads lists of subscriptions from the elastic search index with given filters
export const useSubscriptions = (
  category: SubscriptionCategory,
  selectedFilter?: string,
  selectedMultiFilter?: SubscriptionsDeviceMultiFilter
): SubscriptionResult => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { search, sort, offset = '0', order, limit } = useSearchParams<DefaultListSearchParams>();
  const itemsPerPage = getItemsPerPageFromOptionsOrDefault(limit);

  const [errors, setErrors] = useState<CommonError[]>([]);
  const [subscriptions, setSubscriptions] = useState<SubscriptionsResponse>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const filterSubTypes = getFilterValues(selectedFilter as SubscriptionFilterValue);
    const subscriptionType = filterSubTypes.subscriptionType || getSubscriptionTypes(category);
    const multiFilter = selectedMultiFilter ?? {};

    fetchEsSubscriptions({
      ...(filterSubTypes.subscriptionSubType ? { subscriptionSubType: filterSubTypes.subscriptionSubType } : {}),
      ...replacePipeWithCommaInQueryParams(multiFilter),
      details: true,
      limit: itemsPerPage,
      offset: Number(offset),
      order,
      search,
      sort,
      subscriptionType,
      useSearchService: true,
    })
      .then(setSubscriptions)
      .catch((e: Error) => {
        setErrors(convertToCommonErrors(e.message));
      })
      .finally(() => setLoading(false));
  }, [category, dispatch, itemsPerPage, offset, order, pathname, search, selectedFilter, selectedMultiFilter, sort]);

  return { subscriptions, loading, errors };
};
