import { forwardRef, useId } from 'react';
import type { ReactNode, Ref, TextareaHTMLAttributes } from 'react';

interface InputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  children?: ReactNode;
  error?: string;
  hint?: ReactNode;
  label?: ReactNode;
}

export const TextArea = forwardRef((props: InputProps, ref: Ref<HTMLTextAreaElement>) => {
  const id = useId();
  const { children, error, hint, label, ...rest } = props;

  return (
    <div
      className={[
        'ds-input',
        error && 'ds-input-error',
        rest.disabled && 'ds-input-disabled',
        rest.readOnly && 'ds-input-readonly',
      ]
        .filter(i => i)
        .join(' ')}
    >
      <div className="ds-input--labelarea">
        {label && (
          <label id={`${id}-label`} className="ds-input--labelarea-label" htmlFor={`${id}-textarea`}>
            {label}
          </label>
        )}
      </div>
      <div className="ds-input--inputarea">
        <textarea
          {...rest}
          ref={ref}
          id={`${id}-textarea`}
          style={{ verticalAlign: 'top' }}
          className={['ds-input--inputarea-textarea', props.className].filter(i => i).join(' ')}
          aria-labelledby={props.label ? `${id}-label` : undefined}
          aria-invalid={Boolean(error)}
          aria-errormessage={error ? `${id}-error` : undefined}
          aria-describedby={error ? error && `${id}-error` : undefined}
        />
        {children}
      </div>
      {error && (
        <p id={`${id}-error`} className="ds-inputerror" role="alert" aria-live="polite">
          {error}
        </p>
      )}
      {hint && (
        <p id={`${id}-hint`} className="ds-inputhelp">
          {hint}
        </p>
      )}
    </div>
  );
});
