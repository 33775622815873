import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { LocalNavi } from '../LocalNavi/index.js';
import {
  OPEN_INVOICES_HASH,
  PAID_INVOICES_HASH,
  billingAccountsMsg,
  invoicesMsg,
  invoicingMsg,
  omaElisaForCompaniesMsg,
  t,
} from '../../common/i18n/index.js';
import { SystemError, hasSystemError } from '../SystemError/SystemError.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useSelector } from 'react-redux';
import type { ActionsHistory, State } from '../../selfservice/common/store.js';
import type { BreadCrumbList } from '../BreadCrumbs/index.js';
import type { InvoiceHeader } from 'generated/api/invoiceHeader.js';
import type { InvoicesState } from '../../common/types/states.js';
import type { ReactNode } from 'react';

export enum InvoiceCategoryPerHash {
  'avoimet' = 'open',
  'maksetut' = 'paid',
  'all' = 'all',
}

export const getCategoryFromHash = (hash: string) =>
  InvoiceCategoryPerHash[(hash.slice(1) as 'avoimet' | 'maksetut' | 'all') || 'all'];

export const getInvoiceListCategory = (hash?: string) => {
  if (hash?.includes(OPEN_INVOICES_HASH)) {
    return 'open';
  } else if (hash?.includes(PAID_INVOICES_HASH)) {
    return 'paid';
  }
  return 'all';
};

export const getInvoicesState = (category: string, invoices: InvoicesStrippedOutOfActions) => {
  if (category === 'open') {
    return invoices?.open;
  } else if (category === 'all') {
    return invoices?.all;
  }
  return invoices?.paid;
};

export const getInvoicesForCategory = (category: string, invoices: InvoiceHeader[]) => {
  if (category === 'all') {
    return invoices;
  } else if (category === 'open') {
    return invoices.filter(invoice => invoice.balance > 0);
  } else {
    return invoices.filter(invoice => invoice.balance <= 0);
  }
};

export enum InvoicesSceneTab {
  BILLING_ACCOUNTS = 'BILLING_ACCOUNTS',
  INVOICES = 'INVOICES',
  DOCUMENTS = 'DOCUMENTS',
}

interface InvoicesLocalNaviProps {
  children: ReactNode;
  hideHeadingAndTabs?: boolean;
  tab?: InvoicesSceneTab;
}

export interface InvoicesStrippedOutOfActions {
  all: null | undefined | (InvoicesState & ActionsHistory & { actions: never[] });
  paid: null | undefined | (InvoicesState & ActionsHistory & { actions: never[] });
  open: null | undefined | (InvoicesState & ActionsHistory & { actions: never[] });
}

const getBreadCrumbPaths = (tab?: InvoicesSceneTab): BreadCrumbList | undefined => {
  if (tab === InvoicesSceneTab.INVOICES) {
    return [{ name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME }, { name: t.Y7C0(invoicesMsg) }];
  }
  if (tab === InvoicesSceneTab.BILLING_ACCOUNTS) {
    return [
      { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
      { name: t.ZVMK(billingAccountsMsg) },
    ];
  }
  return;
};

export const Invoices = ({ children, tab }: InvoicesLocalNaviProps) => {
  return (
    <div className="of-invoices-scene">
      <HeroHeading
        breadCrumbPaths={getBreadCrumbPaths(tab)}
        heroHeadingType={HeroHeadingType.INVOICES}
        title={t.AUI8(invoicingMsg)}
      />
      <LocalNavi
        categories={[
          {
            children: t.Y7C0(invoicesMsg),
            to: paths.INVOICES,
          },
          {
            children: t.ZVMK(billingAccountsMsg),
            to: paths.BILLING_ACCOUNTS,
          },
          {
            children: t.OMMT('Invoice letters'),
            to: paths.INVOICE_DOCUMENTS,
          },
        ]}
      />
      {children}
    </div>
  );
};

interface InvoiceSystemErrorProps {
  children: ReactNode;
}

export const InvoiceSystemError = ({ children }: InvoiceSystemErrorProps) => {
  const contacts = useSelector((state: State) => state.selfservice?.contacts, deepEqual);
  const contactUpsertFailed =
    contacts?.errors?.filter(error => error.message === 'Failed to create duplicate contact').length !== 0;

  // Don't show generic "Oops" error page when contact creation fails due to duplicate contact
  // as this is handled in the dialog presented to the user
  if (hasSystemError(contacts?.errors) && !contactUpsertFailed) {
    return <SystemError errors={contacts?.errors} homePath={paths.SELF_SERVICE_HOME} />;
  }
  return <>{children}</>;
};
