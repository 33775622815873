import {
  TypeKeys,
  addSupportCaseFailed,
  addSupportCaseFulfilled,
  loadOpenSupportCasesForInvoicesFailed,
  loadOpenSupportCasesForInvoicesFulfilled,
} from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, tap } from 'rxjs/operators';
import { createSupportCasePrivateMethod, getOpenSupportCasesPrivateMethod } from '../../generated/api/uiApiMethods.js';
import { pushSupportCaseGAEventToDataLayer } from '../../common/analytics.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type {
  AddSupportCaseAction,
  LoadOpenSupportCasesForInvoicesAction,
  SelfServiceActionTypes,
} from '../actions/index.js';
import type { AjaxResponse } from 'rxjs/ajax';
import type { ItemsQuery, State } from '../common/store.js';
import type { SupportCase } from '../../generated/api/models.js';

export const createSupportCaseObservable = (
  supportCase: SupportCase,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies,
  noSuccessNotification?: boolean,
  showInfoDialog?: boolean,
  successNotificationMsg?: string
) => {
  return callUiApi({
    epicDependencies,
    failureAction: addSupportCaseFailed,
    method: createSupportCasePrivateMethod(),
    payload: supportCase,
    state$,
    successAction: ({ response }: AjaxResponse) =>
      addSupportCaseFulfilled(response, noSuccessNotification, showInfoDialog, successNotificationMsg),
  }).pipe(
    tap(() => {
      pushSupportCaseGAEventToDataLayer('form_submit', true, supportCase.feature);
    })
  );
};

export const addSupportCaseEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.ADD_SUPPORT_CASE)), (action: AddSupportCaseAction) =>
    actionToActionState(action, state$, 'supportCases')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state) {
        throw new Error('invalid action state for adding support case');
      }
      const { supportCase, noSuccessNotification, showInfoDialog, successNotificationMsg } =
        actionAndState.action as AddSupportCaseAction;
      return createSupportCaseObservable(
        supportCase,
        state$,
        epicDependencies,
        noSuccessNotification,
        showInfoDialog,
        successNotificationMsg
      );
    })
  );

export const loadOpenSupportCasesRelatedToUserInvoicesEpic: Epic<
  SelfServiceActionTypes,
  Action,
  State,
  EpicDependencies
> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.LOAD_OPEN_SUPPORT_CASES_FOR_INVOICES)),
    (action: LoadOpenSupportCasesForInvoicesAction) => actionToActionState(action, state$, 'supportCases')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const query: ItemsQuery = {
        ...actionAndState.state?.query,
        offset: 0,
      };
      return callUiApi({
        epicDependencies,
        failureAction: loadOpenSupportCasesForInvoicesFailed,
        method: getOpenSupportCasesPrivateMethod(query),
        state$,
        successAction: loadOpenSupportCasesForInvoicesFulfilled,
      });
    })
  );

export const supportCaseEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  addSupportCaseEpic,
  loadOpenSupportCasesRelatedToUserInvoicesEpic
);
