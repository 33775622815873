import { formatTimeStampToHHmm, formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';

interface TimestampColumnProps {
  timestamp?: number;
}

export const TimestampColumn = ({ timestamp }: TimestampColumnProps) => {
  if (!timestamp) {
    return <span>—</span>;
  }

  return (
    <>
      <span>{formatTimestampToDDMMYYYY(timestamp)}</span>
      <br />
      <span className="ds-font-size--smaller">{formatTimeStampToHHmm(timestamp)}</span>
    </>
  );
};
