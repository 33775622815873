import { TextWithPopover } from '../../TextWithPopover/TextWithPopover.js';
import { listedNumberMsg, numberDirectoryStatusMsg, t } from '../../../common/i18n/index.js';

export interface NumberPrivacyHeaderProps {
  headerText?: string;
}
export const NumberPrivacyHeader = ({ headerText }: NumberPrivacyHeaderProps) => {
  return (
    <TextWithPopover textContent={`${t.L8XT(numberDirectoryStatusMsg)} (${headerText || ''})`} placement="right">
      <div className="ds-padding-bottom--3">
        <b>{t.QTYF('Directory-status options for number')}</b>
      </div>
      <p>
        <b>{t.VCX1(listedNumberMsg)}</b>,{' '}
        {t.V97L('your number is listed and its details are available via directory enquiries.')}
      </p>
      <p>
        <b>{t.KZ6N('Unlisted number')}</b>
        {t.F30X(', your number is unlisted and its details are unavailable via directory enquiries.')}
      </p>
      <p>
        {t.T6EF(
          'Please note that when you change a selection via OmaElisa, the updated information will be transferred separately to directory services. It takes a few business days for data to be updated in directory services.'
        )}
      </p>
    </TextWithPopover>
  );
};
