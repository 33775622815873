import {
  EmptyOrError,
  getErrorPropsFromCommonErrors,
  getNotFoundError,
} from '../../../../../components/EmptyOrError/index.js';
import { EppMaintenanceAdmin } from '../../../../../components/EppMaintenance/EppMaintenanceAdmin.js';
import { Loading } from '../../../../../components/Loading/index.js';
import { SubscriptionCategory } from '../../../../../common/enums.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { findSubscription } from '../../../../../common/utils/subscriptionUtils.js';
import {
  loadBillChannels,
  loadSubscriptionAddOnRules,
  loadSubscriptions,
} from '../../../../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import type { State } from '../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const DeviceSupportRequestPath = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { subscriptionId } = useParams<SubscriptionId>();
  const companyInfo = useSelector((state: State) => state?.selfservice?.companyInfo, deepEqual);
  const deviceSubs = useSelector(
    ({ selfservice }: State) => selfservice?.subscriptions?.device || undefined,
    deepEqual
  );

  useEffect(() => {
    dispatch(loadSubscriptions({ category: SubscriptionCategory.DEVICE, displayId: subscriptionId }));
    dispatch(loadBillChannels());
    dispatch(loadSubscriptionAddOnRules());
  }, [dispatch, pathname, subscriptionId]);

  if (deviceSubs?.loading) {
    return <Loading />;
  }

  const subscription = findSubscription(subscriptionId!, deviceSubs);

  if (!(subscription && companyInfo)) {
    return <EmptyOrError {...(getErrorPropsFromCommonErrors(deviceSubs?.errors) || getNotFoundError())} />;
  }

  return <EppMaintenanceAdmin subscription={subscription} subscriptionId={subscriptionId!} />;
};
