import * as CL from '@design-system/component-library';
import { TimeRange } from './TimeRange.js';
import { t } from '../../common/i18n/index.js';
import { weekEndTypes } from './pbxWorkingHoursUtil.js';
import type { CommonError } from '../../common/types/errors.js';
import type { PbxWorkingHoursConfig } from './pbxWorkingHoursUtil.js';
import type { SubscriptionPbxDetails } from '../../generated/api/models.js';

import './PbxWorkingHours.scss';

export interface PbxWorkingHoursProps {
  pbxWorkingHoursConfig: PbxWorkingHoursConfig;
  errors?: CommonError[];
  editing?: boolean;
  hidden?: boolean;
  pbxType?: SubscriptionPbxDetails.PbxTypeEnum;
}

export const PbxWorkingHours = ({ editing, errors, hidden, pbxWorkingHoursConfig, pbxType }: PbxWorkingHoursProps) => {
  if (hidden) {
    return null;
  }

  const genericErrors = errors?.filter(error => !error.field) ?? [];
  const fieldErrors = errors?.filter(error => !!error.field) ?? [];
  const weekdayErrors = fieldErrors.filter(fieldError => fieldError.field && fieldError.field.includes('Weekday'));
  const weekendErrors = fieldErrors.filter(fieldError => fieldError.field && fieldError.field.includes('Weekend'));
  const isWorkingHourWeekEndType = weekEndTypes.includes(pbxType);

  return (
    <div className="of-pbx-work-hours">
      {genericErrors.length > 0 && editing && (
        <div className="of-pbx-work-hours__errors">
          {genericErrors.map(error => (
            <div key={error.message}>{error.message}</div>
          ))}
        </div>
      )}
      <CL.Grid>
        <CL.GridRow className="of-pbx-work-hours__panel-container">
          <CL.GridCol
            id="pbx-work-hours-weekdays"
            className="of-pbx-work-hours__panel"
            colsXS={4}
            colsS={5}
            colsM={5}
            colsL={5}
            colsXL={5}
          >
            <TimeRange
              title={t.EBAT('On weekdays')}
              namePrefix="workingHoursWeekday"
              editing={editing}
              start={pbxWorkingHoursConfig.workingHoursWeekdayStart}
              end={pbxWorkingHoursConfig.workingHoursWeekdayEnd}
              errors={weekdayErrors}
            />
          </CL.GridCol>
          <CL.GridCol className="ds-padding--0" colsM={1} colsL={1} colsXL={1}></CL.GridCol>
          {isWorkingHourWeekEndType && (
            <CL.GridCol
              id="pbx-work-hours-weekends"
              className="of-pbx-work-hours__panel"
              colsXS={4}
              colsS={5}
              colsM={5}
              colsL={5}
              colsXL={5}
            >
              <TimeRange
                title={t.ZZK7('Weekends')}
                namePrefix="workingHoursWeekend"
                editing={editing}
                start={pbxWorkingHoursConfig.workingHoursWeekendStart}
                end={pbxWorkingHoursConfig.workingHoursWeekendEnd}
                errors={weekendErrors}
              />
            </CL.GridCol>
          )}
          {!isWorkingHourWeekEndType && (
            <CL.GridCol className="of-pbx-work-hours__panel" colsXS={4} colsS={5} colsM={5} colsL={5} colsXL={5}>
              <TimeRange
                title={t.KRDZ('Saturday')}
                namePrefix="workingHoursSaturday"
                editing={editing}
                start={pbxWorkingHoursConfig.workingHoursSaturdayStart}
                end={pbxWorkingHoursConfig.workingHoursSaturdayEnd}
                errors={weekendErrors}
              />
              <TimeRange
                title={t.QZ2C('Sunday')}
                namePrefix="workingHoursSunday"
                editing={editing}
                start={pbxWorkingHoursConfig.workingHoursSundayStart}
                end={pbxWorkingHoursConfig.workingHoursSundayEnd}
                errors={weekendErrors}
              />
            </CL.GridCol>
          )}
        </CL.GridRow>
      </CL.Grid>
    </div>
  );
};
