import { ReportItem } from './ReportItem.js';
import { buildURI, exportDataFromURI } from './reportsUtil.js';
import { useEffect, useState } from 'react';
import type { ReportItemProps } from './ReportItem.js';

interface CsvReportItemProps extends Omit<ReportItemProps, 'onClick' | 'loading'> {
  isAllDataLoaded: () => boolean;
  loadData: () => void;
  prepareReport: () => string[][];
  sources: (number | undefined)[]; // Needed to run useEffect on source data change
  fileName: string;
}

export const CsvReportItem = ({
  isAllDataLoaded,
  prepareReport,
  loadData,
  fileName,
  sources,
  ...rest
}: CsvReportItemProps) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading && isAllDataLoaded()) {
      const report = prepareReport();
      exportDataFromURI(buildURI(report, ';'), fileName);
      setIsLoading(false);
    }
  }, [fileName, isAllDataLoaded, isLoading, prepareReport, sources]);

  const onClickReport = () => {
    if (isAllDataLoaded()) {
      const report = prepareReport();
      exportDataFromURI(buildURI(report, ';'), fileName);
    } else {
      loadData();
      setIsLoading(true);
    }
  };

  return <ReportItem {...{ ...rest, onClick: onClickReport, loading: isLoading }} />;
};
