import { TypeKeys, startBroadbandFixerFailed, startBroadbandFixerFulfilled } from '../actions/index.js';
import { callUiApi } from '../common/uiApiUtils.js';
import { combineEpics } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import { startBbfPrivateMethod } from '../../generated/api/uiApiMethods.js';
import type { Action } from 'redux';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { EpicDependencies } from './epicUtils.js';
import type { SelfServiceActionTypes, StartBroadbandFixerAction } from '../actions/index.js';
import type { State } from '../common/store.js';

const startBroadbandFixerEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  action$.ofType(TypeKeys.START_BROADBAND_FIXER).pipe(
    concatMap(({ subscriptionId, locale, portal }: StartBroadbandFixerAction) => {
      return callUiApi({
        epicDependencies,
        state$,
        method: startBbfPrivateMethod(subscriptionId, locale, portal),
        successAction: startBroadbandFixerFulfilled,
        failureAction: startBroadbandFixerFailed,
      });
    })
  );

export const broadbandFixerEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> =
  combineEpics(startBroadbandFixerEpic);
