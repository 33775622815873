import { ApproveOrDeny } from '../ApproveOrDeny/ApproveOrDeny.js';
import { ButtonGroup, SubscriptionDetailsButtonType } from '../SubscriptionDetails/subscriptionDetailsButtons.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { DialogType } from '../../common/enums.js';
import { Grid } from '../Grid/Grid.js';
import { Loading } from '../Loading/index.js';
import { PostReviewRequest } from '../../generated/api/postReviewRequest.js';
import { StickyFooter } from '../StickyFooter/index.js';
import { SubscriptionAction } from '../../generated/api/subscriptionAction.js';
import {
  SubscriptionDetailsAccordions,
  SubscriptionDetailsBillingAccount,
} from '../SubscriptionDetails/SubscriptionDetails.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { findSubscription, getSubscriptionStatus } from '../../common/utils/subscriptionUtils.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { getDeviceTypeText } from '../SubscriptionDetails/subscriptionDetailsCommon.js';
import { getSubscriptionDetailsDevice } from '../SubscriptionDetails/subscriptionDetailsDevice.js';
import { paths } from '../../common/constants/pathVariables.js';
import {
  resumeSubscription,
  review,
  showDialog,
  showTerminateSubscriptionForm,
} from '../../selfservice/actions/index.js';
import { t } from '../../common/i18n/index.js';
import { useDispatch, useSelector } from 'react-redux';
import type { DialogParams } from '../../common/types/dialog.js';
import type { State } from '../../selfservice/common/store.js';
import type { Subscription } from '../../generated/api/subscription.js';
import type { SubscriptionActionsState } from '../../common/types/states.js';
import type { SubscriptionDetailsProps } from '../SubscriptionDetails/SubscriptionDetails.js';
import type { SubscriptionRedeemReview } from '../../generated/api/subscriptionRedeemReview.js';
import type { SupportCase } from '../../generated/api/supportCase.js';

const needEppRedeemAdminReview = (
  subscriptionActions: SubscriptionActionsState | undefined,
  subscription: Subscription
) => {
  const subscriptionActionItem =
    subscriptionActions?.items?.find(item => item.subscriptionId === subscription.subscriptionId) ?? undefined;
  if (subscriptionActionItem) {
    return (
      subscriptionActionItem.subscriptionActionType ===
        SubscriptionAction.SubscriptionActionTypeEnum.EPP_REDEEM_TERMINATE &&
      subscriptionActionItem.status === SubscriptionAction.StatusEnum.PENDING_APPROVAL
    );
  }
  return false;
};

const getButtonTypes = (isAccessory: boolean, subscription: Subscription) => {
  if (isAccessory) {
    return [];
  }

  if (subscription.details?.device?.eppSubscription) {
    if (subscription.subscriptionContactId) {
      return [
        SubscriptionDetailsButtonType.EPP_DAMAGE_OR_SUPPORT_REQUEST,
        SubscriptionDetailsButtonType.EPP_REDEEM,
        SubscriptionDetailsButtonType.EPP_DEVICE_CHANGE,
        SubscriptionDetailsButtonType.EPP_TERMINATE,
      ];
    }
    // Device change is not supported for subscriptions that have purpose of use
    return [
      SubscriptionDetailsButtonType.EPP_DAMAGE_OR_SUPPORT_REQUEST,
      SubscriptionDetailsButtonType.EPP_REDEEM,
      SubscriptionDetailsButtonType.EPP_TERMINATE,
    ];
  }

  return [SubscriptionDetailsButtonType.SUPPORT_REQUEST];
};

export const SubscriptionDevice = (props: SubscriptionDetailsProps) => {
  const { breadCrumbs, subscriptionId, subscriptions } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscription = findSubscription(subscriptionId, subscriptions);
  const subscriptionActions = useSelector(
    (state: State) => state?.selfservice?.pendingSubscriptionActions || undefined,
    deepEqual
  );

  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));
  const subscriptionStatus = getSubscriptionStatus(subscription, subscriptionActions?.items);
  const onShowSubscriptionTerminationForm = () => dispatch(showTerminateSubscriptionForm());
  const onResumeSubscription = (subId: string) => dispatch(resumeSubscription(subId));
  const isAccessory = subscription?.subscriptionSubType?.toLowerCase() === 'accessories' || false;
  const subscriptionDisplayId = subscription ? subscription.subscriptionDisplayId : '…';
  const pendingSubscriptionActions = useSelector(
    (s: State) => s.selfservice?.pendingSubscriptionActions?.items,
    deepEqual
  );

  const getHeroPicto = (subscriptionSubType = '') => {
    switch (subscriptionSubType.toLowerCase()) {
      case 'phone':
        return 'phone-filled';
      case 'tablet':
        return 'tablet-filled';
      case 'computers':
        return 'laptop-filled';
      case 'network equipment':
        return 'wireless-device-filled';
      case 'accessories':
        return 'headphones-filled';
      default:
        // Do not show any placeholder icon while loading the data
        return undefined;
    }
  };

  if (!subscription?.details) {
    return (
      <DetailsWrapper
        classes={['of-subscription-details']}
        content={<Loading />}
        detailsTop={breadCrumbs}
        heading={subscription?.subscriptionName || ''}
        headingBottom=""
        headingTop={getDeviceTypeText(subscription?.subscriptionSubType || '')}
        heroPicto={getHeroPicto(subscription?.subscriptionSubType)}
        id={`subscription-details-${subscriptionDisplayId}`}
      />
    );
  }

  if (!subscription.details.device) {
    throw new Error('Subscription data insufficient!');
  }

  const subscriptionDetails = (
    <>
      <div className="of-subscription-details__content of-subscription-details__content--device">
        {getSubscriptionDetailsDevice(
          subscription,
          subscription.details,
          subscription.details.device,
          <SubscriptionDetailsBillingAccount {...props} />
        )}
        <ButtonGroup
          buttonTypes={getButtonTypes(isAccessory, subscription)}
          subscription={subscription}
          subscriptionStatus={subscriptionStatus}
          onShowDialog={onShowDialog}
          onShowSubscriptionTerminationForm={onShowSubscriptionTerminationForm}
          onResumeSubscription={onResumeSubscription}
          onClickCompanyInfoUser={() => {
            navigate(paths.COMPANY_INFO_RING_ADMINS);
          }}
          onClickEppMaintenanceRequest={(subId: string) => {
            navigate(generatePath(paths.PS_DEVICE_SUPPORT_REQUEST, { subscriptionId: subId }));
          }}
          onClickEppRedeem={() => {
            navigate(generatePath(paths.PS_DEVICE_REDEMPTION, { subscriptionId: subscription?.subscriptionDisplayId }));
          }}
        />
        <SubscriptionDetailsAccordions {...props} />
      </div>
      {needEppRedeemAdminReview(subscriptionActions, subscription) && (
        <StickyFooter active={true}>
          <Grid>
            {/* TODO this logic is almost same as in SubscriptionActionDetailsView.tsx and it could be combined to live in ApproveOrDeny */}
            <ApproveOrDeny
              approveButtonLabel={t.RDZA('Approve redeem request')}
              denyButtonLabel={t.NC6Y('Reject redeem request')}
              onApprove={() => {
                onShowDialog({
                  body: t.AWTH('EPP redeem request'),
                  header: t.KCZ6('Confirm Approve Redeem Request'),
                  onConfirm: () => {
                    dispatch(
                      review(
                        { subscriptionId: subscription.subscriptionId } as SubscriptionRedeemReview,
                        PostReviewRequest.ReviewTypeEnum.SUBSCRIPTION_REDEEM,
                        true,
                        undefined,
                        undefined,
                        undefined,
                        pendingSubscriptionActions
                      )
                    );
                  },
                  type: DialogType.GENERIC_CONFIRMATION_DIALOG,
                });
              }}
              onDeny={() => {
                onShowDialog({
                  type: DialogType.EPP_REDEEM_REJECT,
                  onRedeemReject: (rejectReason: SupportCase) => {
                    dispatch(
                      review(
                        {
                          subscriptionId: subscription.subscriptionId,
                        } as SubscriptionRedeemReview,
                        PostReviewRequest.ReviewTypeEnum.SUBSCRIPTION_REDEEM,
                        false,
                        rejectReason.description,
                        undefined,
                        undefined,
                        pendingSubscriptionActions
                      )
                    );
                  },
                });
              }}
            />
          </Grid>
        </StickyFooter>
      )}
    </>
  );

  return (
    <DetailsWrapper
      classes={['of-subscription-details']}
      content={subscriptionDetails}
      detailsTop={breadCrumbs}
      heading={subscription.subscriptionName}
      headingBottom=""
      headingTop={getDeviceTypeText(subscription?.subscriptionSubType || '')}
      heroPicto={getHeroPicto(subscription.subscriptionSubType)}
      id={`subscription-details-${subscriptionDisplayId}`}
    />
  );
};
