import * as CL from '@design-system/component-library';
import { BreadCrumbs } from '../BreadCrumbs/BreadCrumbs.js';
import { Login } from '../Login/index.js';
import { contactInfoMsg, corporateCustomersMsg, leaveMessageMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { State } from '../../selfservice/common/store.js';

import './ContactPublic.scss';

const PublicContactLoginInfo = () => {
  const navigate = useNavigate();

  return (
    <>
      <h3 className="ds-h3--book">{t.IKPV('You can also continue without logging in')}</h3>
      <p>{t.SD0B('No account? You can also leave a message without logging in.')}</p>
      <CL.Button color="light" className="ds-margin-vertical--2" onClick={() => navigate(paths.CONTACT_NEW_MESSAGE)}>
        {t.DCA8('Continue without login')}
      </CL.Button>
    </>
  );
};

export const ContactPublic = () => {
  const dialog = useSelector((state: State) => state.dialog);

  return (
    <div className="of-contact-public ds-margin-bottom--6">
      <BreadCrumbs
        breadCrumbPaths={[
          { name: t.KBLX(corporateCustomersMsg), path: '/' },
          { name: t.GJS9(contactInfoMsg), path: paths.CONTACT_INFO },
          { name: t.WVNV(leaveMessageMsg) },
        ]}
      />
      <h1 className="ds-text-align--center ds-margin-top--9 heading">{t.WVNV('Leave a message')}</h1>
      <div className="content">
        <Login dialog={dialog} />
        <div className="info">
          <PublicContactLoginInfo />
          <div className="image"></div>
        </div>
      </div>
    </div>
  );
};
