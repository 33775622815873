import * as CL from '@design-system/component-library';
import { DEFAULT_DELIVERY_MODE, DeliveryModeType } from './DeviceCheckoutDeliveryDetailsUtils.js';
import { DeliveryType, OnlineOrder } from '../../generated/api/models.js';
import { DeviceCheckoutDeliveryAddressSection } from './components/DeviceCheckoutDeliveryAddressSection.js';
import { DeviceCheckoutDeliveryMethodsSelectionList } from './components/DeviceCheckoutDeliveryMethodsSelectionList.js';
import { DeviceCheckoutOrderShipmentTypeSelection } from './components/DeviceCheckoutOrderShipmentTypeSelection.js';
import { POBoxWarningModal } from '../Modal/POBoxWarningModal.js';
import { addressConfirmationMsg, nextMsg, submitOrderMsg, t } from '../../common/i18n/index.js';
import { cartContainsDirectDeliveryItems, isAnyCartItemBackordered } from '../DeviceCheckout/deviceCheckoutUtils.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { isEppDevicePresentInCart } from '../../common/utils/checkoutUtils.js';
import { isPOBoxAddress } from '../../common/utils/validationUtils.js';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type {
  DeliveryMode,
  DeviceCheckoutDeliveryDetailsBaseProps,
  DeviceCheckoutDeliveryDetailsType,
} from './DeviceCheckoutDeliveryDetailsUtils.js';
import type { Dispatch, SetStateAction } from 'react';
import type { State } from '../../selfservice/common/store.js';

import './DeviceCheckoutDeliveryDetails.scss';

export interface DeviceCheckoutDeliveryDetailsProps extends DeviceCheckoutDeliveryDetailsBaseProps {
  saveDeliveryDetailsAndNext: (value: DeviceCheckoutDeliveryDetailsType) => void;
  onSubmitOrder?: (deliveryDetails: DeviceCheckoutDeliveryDetailsType) => void;
  deliveryAddressNeeded?: boolean;
  showConfirmAddress?: boolean;
  isDeviceChangeReturn?: boolean;
}

const isPickupPointRequired = (details: DeviceCheckoutDeliveryDetailsType): boolean => {
  return [
    DeliveryType.MATKAHUOLTO_LAHELLA_PAKETTI,
    DeliveryType.POSTI_SMARTPOST,
    DeliveryType.POSTI_SMARTPOST_EXPRESS,
  ].includes(details.deliveryMethodType);
};

const AddressConfirmation = ({
  setIsChecked,
  isChecked,
}: {
  isChecked: boolean;
  setIsChecked: Dispatch<SetStateAction<boolean>>;
}) => (
  <CL.Checkbox
    className="ds-color--blue"
    checked={isChecked}
    name="confirmAddress"
    onChange={() => setIsChecked(!isChecked)}
  >
    {t.T9V1(addressConfirmationMsg)}
  </CL.Checkbox>
);

export const DeviceCheckoutDeliveryDetails = (props: DeviceCheckoutDeliveryDetailsProps) => {
  const {
    saveDeliveryDetailsToState,
    deliveryDetails,
    onSubmitOrder,
    skipDeliveryMethods,
    isEmployee,
    showConfirmAddress,
    isDeviceChangeReturn,
  } = props;
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [shipmentType, setShipmentType] = useState<OnlineOrder.ShipmentTypeEnum>(
    OnlineOrder.ShipmentTypeEnum.TOTAL_DELIVERY
  );
  const [isAddressConfirmationChecked, setIsAddressConfirmationChecked] = useState(false);
  const [selectedDeliveryMode, setSelectedDeliveryMode] = useState(DEFAULT_DELIVERY_MODE);
  const [showPOBoxWarningModal, setShowPOBoxWarningModal] = useState(false);

  const deviceCheckout = useSelector((state: State) => state.deviceCheckout, deepEqual);
  const isOrderInProgress = useSelector((state: State) => Boolean(state.selfservice?.onlineOrders?.saving), deepEqual);
  const onlineModels = useSelector((state: State) => state.selfservice?.onlineModels || undefined, deepEqual);
  const cartItems = useSelector((state: State) => state.deviceCheckout?.cartItems || [], deepEqual);
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo || undefined, deepEqual);

  const errors = deviceCheckout?.errors;
  const listOfDeliveryMethods = deviceCheckout?.deliveryMethods?.lisOfDeliveryMethods;
  const isDirectDelivery = cartContainsDirectDeliveryItems(cartItems, onlineModels);
  const showShipmentType = cartItems?.length > 1 && isAnyCartItemBackordered(cartItems, onlineModels);
  const companyName = companyInfo ? companyInfo.companyName : '';
  const eppDevicePresentInCart = isEppDevicePresentInCart(cartItems);
  const discountedBasketLevelPrices = companyInfo?.discountedBasketLevelPrices;

  useEffect(() => {
    if (isDirectDelivery && deliveryDetails.shipmentType !== OnlineOrder.ShipmentTypeEnum.PARTIAL_DELIVERY) {
      setShipmentType(OnlineOrder.ShipmentTypeEnum.PARTIAL_DELIVERY);
      saveDeliveryDetailsToState({
        ...deliveryDetails,
        shipmentType: OnlineOrder.ShipmentTypeEnum.PARTIAL_DELIVERY,
      });
    }
  }, [cartItems, onlineModels, isDirectDelivery, saveDeliveryDetailsToState, deliveryDetails]);

  const disableNext =
    (((deliveryDetails && !deliveryDetails.address) || !selectedDeliveryMode || !listOfDeliveryMethods) &&
      !skipDeliveryMethods) ||
    (isEditingAddress && selectedDeliveryMode.type === DeliveryModeType.ADDRESS);

  const handleDeliveryModeSelected = useCallback(
    (deliveryMode: DeliveryMode) => {
      const methods = deliveryMode?.getDeliveryMethods(listOfDeliveryMethods, isEmployee || false);
      const selectedMethod = methods?.[0];
      if (methods && selectedMethod) {
        saveDeliveryDetailsToState({
          ...deliveryDetails,
          deliveryMethodDescription: selectedMethod.createDescription(),
          deliveryMethodType: selectedMethod.type,
          deliveryPrice: selectedMethod.price,
          pickupPoint:
            selectedMethod.deliveryModeType === DeliveryModeType.ADDRESS ? undefined : deliveryDetails.pickupPoint,
        });
      }
    },
    [deliveryDetails, isEmployee, listOfDeliveryMethods, saveDeliveryDetailsToState]
  );

  return (
    <div className="of-device-checkout-delivery-details">
      {showPOBoxWarningModal && <POBoxWarningModal setShowModal={setShowPOBoxWarningModal} />}
      {((!isDeviceChangeReturn && showShipmentType) || isDirectDelivery) && (
        <DeviceCheckoutOrderShipmentTypeSelection
          isEmployee={isEmployee}
          shipmentType={shipmentType}
          isDirectDelivery={isDirectDelivery}
          backOrdered={showShipmentType}
          onShipmentTypeSelection={(orderType: OnlineOrder.ShipmentTypeEnum) => {
            setShipmentType(orderType);
            props.saveDeliveryDetailsToState({
              ...deliveryDetails,
              shipmentType: orderType,
            });
          }}
        />
      )}
      <DeviceCheckoutDeliveryAddressSection
        deliveryDetails={deliveryDetails}
        deliveryAddressNeeded={props.deliveryAddressNeeded}
        saveDeliveryDetailsToState={props.saveDeliveryDetailsToState}
        companyName={companyName}
        setIsEditingAddress={setIsEditingAddress}
        errors={errors}
      />

      {showConfirmAddress && (
        <AddressConfirmation isChecked={isAddressConfirmationChecked} setIsChecked={setIsAddressConfirmationChecked} />
      )}

      {!skipDeliveryMethods && (
        <DeviceCheckoutDeliveryMethodsSelectionList
          eppDevicePresentInCart={eppDevicePresentInCart}
          isEmployee={isEmployee}
          discountedBasketLevelPrices={discountedBasketLevelPrices}
          deliveryDetails={deliveryDetails}
          footerNote={props.footerNote}
          saveDeliveryDetailsToState={props.saveDeliveryDetailsToState}
          deliveryMode={selectedDeliveryMode}
          onDeliveryModeChosen={(deliveryModeSelection: DeliveryMode) => {
            setSelectedDeliveryMode(deliveryModeSelection);
            handleDeliveryModeSelected(deliveryModeSelection);
          }}
          listOfDeliveryMethods={listOfDeliveryMethods}
          isDirectDelivery={isDirectDelivery}
        />
      )}
      <CL.Button
        size="l"
        disabled={
          disableNext ||
          (showConfirmAddress && !isAddressConfirmationChecked) ||
          isOrderInProgress ||
          (!deliveryDetails.pickupPoint && isPickupPointRequired(deliveryDetails))
        }
        className={onSubmitOrder ? 'of-confirm-button' : 'of-next-button'}
        onClick={() => {
          if (
            isPOBoxAddress(deliveryDetails.address?.postalCode) &&
            selectedDeliveryMode.type === DeliveryModeType.ADDRESS
          ) {
            setShowPOBoxWarningModal(true);
          } else {
            props.saveDeliveryDetailsAndNext(deliveryDetails);
            if (onSubmitOrder) {
              onSubmitOrder(deliveryDetails);
            }
          }
        }}
      >
        {onSubmitOrder ? t.RZU4(submitOrderMsg) : t.F0MY(nextMsg)}
      </CL.Button>
    </div>
  );
};
