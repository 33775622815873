import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import {
  mergeArrays,
  reduceDisplayItemsLoadAction,
  sortArrayWithQuery,
  validateLoadActionFulfilledResponseCounts,
} from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionState, ActionsHistory } from '../common/store.js';
import type { Invoice } from '../../generated/api/models.js';
import type { InvoicesState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

const filterInvoicesByCategory = (category: string, invoices?: Invoice[]): Invoice[] => {
  if (invoices) {
    return invoices.filter(invoice => {
      const balance: number = invoice.balance !== undefined ? invoice.balance : 0;
      if (category === 'open') {
        return balance > 0;
      } else {
        return balance <= 0;
      }
    });
  }
  return [];
};

const shouldSkipForInvoicesLoading = (category: string, actionCategory?: string) => category !== actionCategory;

const isInvoiceAlreadyPresent = (state: InvoicesState | null, invoiceId?: string) => {
  return invoiceId && state?.items?.some(invoice => invoice.invoiceDisplayId === invoiceId);
};

const getTotalNumberOfInvoices = (category: string, totalInvoices: number, totalPositiveBalance: number) => {
  if (category === 'open') {
    return totalPositiveBalance;
  } else if (category === 'paid') {
    return totalInvoices - totalPositiveBalance;
  } else {
    return totalInvoices;
  }
};

function invoicesReducer(
  state: (InvoicesState & ActionsHistory) | null,
  action: SelfServiceActionTypes,
  category: string
): (InvoicesState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }
  switch (action.type) {
    case TypeKeys.LOAD_INVOICES: {
      if (shouldSkipForInvoicesLoading(category, action.category) || isInvoiceAlreadyPresent(state, action.displayId)) {
        return {
          ...state,
          loading: false,
        };
      }
      let defaultOffset: number | undefined;
      if (category === 'paid' && state && state.items && state.items.length > 0) {
        // For paid invoices, we might have invoices in the items array without a previous query, need to tell
        // the reducer method that use items length as offset if this is the case
        defaultOffset = state.items.length;
        if (!action.loadMore && action.search !== undefined && action.filter !== undefined) {
          // There are already some paid invoices, we only should load more if the spinner has been visible
          return {
            ...state,
            loading: false,
          };
        }
      }
      const newState = reduceDisplayItemsLoadAction<TypeKeys.LOAD_INVOICES, Invoice>(
        action,
        state,
        'invoiceDisplayId',
        false,
        defaultOffset
      );
      return {
        ...newState,
        loading: true,
      };
    }

    case TypeKeys.LOAD_INVOICES_FULFILLED: {
      if (shouldSkipForInvoicesLoading(category, action.category)) {
        return { ...state, loading: false };
      }
      const { query, totalInvoices, totalPositiveBalance } = action;
      const total = getTotalNumberOfInvoices(category, totalInvoices, totalPositiveBalance);
      let existingInvoices = state!.items;
      // If there's only one existing invoice and it's the paid invoice, we don't want to have the
      // in-memory updated invoice in the existing array so that the order isn't changed when fetching all invoices
      if (
        category === 'all' &&
        !action.displayId &&
        existingInvoices?.length === 1 &&
        existingInvoices[0].invoiceId === state!.paidInvoiceId
      ) {
        existingInvoices = undefined;
      }
      const invoices = mergeArrays<Invoice>('invoiceId', 'lastModified', existingInvoices, action.invoices);
      const filteredInvoices = category === 'all' ? invoices : filterInvoicesByCategory(category, invoices);
      const sortedInvoices = sortArrayWithQuery<Invoice>(filteredInvoices, query);
      const errors = validateLoadActionFulfilledResponseCounts(query, total, sortedInvoices, state!.errors);
      const actions: ActionState[] | undefined = updateActionStatePhase(
        TypeKeys.LOAD_INVOICES,
        state!,
        ActionPhase.IN_PROGRESS,
        ActionPhase.SUCCESS
      );

      return {
        ...state,
        actions,
        errors,
        items: sortedInvoices,
        total,
        loading: false,
      };
    }

    case TypeKeys.LOAD_INVOICES_FAILED: {
      if (shouldSkipForInvoicesLoading(category, action.params ? action.params.category : undefined)) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.LOAD_INVOICES, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        errors: action.errors,
        loading: false,
      };
    }

    case TypeKeys.LOG_OUT: {
      return null;
    }

    default:
      return state;
  }
}

export interface InvoiceReducersWrapper {
  open: (s: InvoicesState, a: SelfServiceActionTypes) => InvoicesState | null;
  paid: (s: InvoicesState, a: SelfServiceActionTypes) => InvoicesState | null;
  all: (s: InvoicesState, a: SelfServiceActionTypes) => InvoicesState | null;
}

export const invoicesReducers: InvoiceReducersWrapper = {
  open: (s: InvoicesState, a: SelfServiceActionTypes) => invoicesReducer(s, a, 'open'),
  paid: (s: InvoicesState, a: SelfServiceActionTypes) => invoicesReducer(s, a, 'paid'),
  all: (s: InvoicesState, a: SelfServiceActionTypes) => invoicesReducer(s, a, 'all'),
};
