import * as CL from '@design-system/component-library';
import { DEFAULT_ITEMS_PER_PAGE, ListPagination, ListSearch, Table } from '../Table/index.js';
import { Fragment } from 'react';
import { Grid } from '../Grid/Grid.js';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { ShowAllAccountsToggleForListViews } from '../ShowAllAccountsToggle/ShowAllAccountsToggleForListViews.js';
import {
  billingAccountNameMsg,
  companyMsg,
  numberMsg,
  payerMsg,
  referenceMsg,
  subscriptionCountMsg,
  t,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getCompanyName, isMultiBiz } from '../../common/utils/accountUtils.js';
import {
  getDefaultBillingContactId,
  getDefaultDeliveryMethod,
  getDeliveryMethod,
  isBillingAccountInSfdc,
} from '../../common/utils/billingAccountUtils.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useMultiAccountRowClick } from '../../common/hooks/useMultiAccountRowClick.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { useSelector } from 'react-redux';
import type { BillingAccountHeader, BillingAccountsResponse } from '../../generated/api/models.js';
import type { DefaultListSearchParams } from '../Table/index.js';
import type { ReactNode } from 'react';
import type { State } from '../../selfservice/common/store.js';

import './BillingAccountList.scss';

export interface BillingAccountListProps {
  billingAccounts?: BillingAccountsResponse;
}

interface BillingAccountLinkProps {
  billingAccount: BillingAccountHeader;
  companyName: string;
  children: ReactNode;
}

const HighlightSearchResult = <T extends object>(
  esResults: string[],
  object: T,
  columnsToAdd: { columnId: string; translation: string }[],
  searchWord: string
) => {
  return columnsToAdd.map(column => {
    // @ts-ignore
    const col = object[column.columnId];
    if (esResults.includes(column.columnId) && !!col) {
      return (
        <span key={column.columnId}>
          <span>{column.translation}: </span>
          {highlightKeyword(col, searchWord)}
          <br />
        </span>
      );
    }
    return <Fragment key={column.columnId}></Fragment>;
  });
};

const BillingAccountLink = ({ billingAccount, companyName, children }: BillingAccountLinkProps) => {
  const onLinkClick = useMultiAccountRowClick();
  const config = useSelector((state: State) => state.config, deepEqual);
  const path = isBillingAccountInSfdc(billingAccount)
    ? `${generatePath(paths.BILLING_ACCOUNT, {
        billingAccountId: billingAccount.billingAccountDisplayId || '',
      })}?companyId=${billingAccount.accountMasterId}`
    : `${config.classicSiteUrl}/laskutustiedot`;

  return (
    <Link
      to={path}
      onClick={event => {
        if (isBillingAccountInSfdc(billingAccount)) {
          event.preventDefault();
          onLinkClick(path, companyName, billingAccount.accountMasterId);
        }
      }}
    >
      {children}
    </Link>
  );
};

export const BillingAccountList = ({ billingAccounts }: BillingAccountListProps) => {
  const { authenticatedUser } = useAuth();
  const navigate = useNavigate();
  const searchParams = useSearchParams<DefaultListSearchParams>();
  const { search } = searchParams;

  const billingAccountHeaders = billingAccounts?.searchResults?.map(sr => sr.result);
  const defaultDeliveryMethod = getDefaultDeliveryMethod(billingAccountHeaders);
  const defaultBillingContactId = getDefaultBillingContactId(billingAccountHeaders);

  const columns: CL.Column[] = [
    { key: 'billingAccountDisplayId', label: t.CCB1(numberMsg), sortable: true, width: '15%' },
    { key: 'payerName', label: t.PB6S(payerMsg), sortable: true, width: '20%' },
    { key: 'billingAccountName', label: t.RH6T(billingAccountNameMsg), sortable: true, width: '20%' },
    { key: 'billingContactName', label: t.VYZS('Contact person'), sortable: true, width: '20%' },
    { key: 'subscriptionCount', label: t.J9DM(subscriptionCountMsg), sortable: true, width: '5%' },
    { key: 'companyName', label: t.KJTS(companyMsg), width: '20%' },
  ];

  const rows = billingAccounts?.searchResults?.map(row => {
    const ba = row.result;
    const billingAccountDisplayId = (
      <BillingAccountLink billingAccount={ba} companyName={getCompanyName(authenticatedUser, ba.accountMasterId)}>
        <CL.Description
          columns={1}
          items={[
            {
              title: (
                <div>
                  {highlightKeyword(ba.billingAccountDisplayId || '-', search)}{' '}
                  {!isBillingAccountInSfdc(ba) && (
                    <CL.Icon icon="external-link" className="ds-display--inline-block ds-margin-left--2" />
                  )}
                </div>
              ),
              description: ba.deliveryMethod && (
                <>
                  {getDeliveryMethod(ba.deliveryMethod)}
                  <br />
                  {row.matchedFields &&
                    search &&
                    HighlightSearchResult(
                      row.matchedFields,
                      ba,
                      [
                        { columnId: 'customerReference1', translation: t.DQHY(referenceMsg) + ' 1' },
                        { columnId: 'customerReference2', translation: t.DQHY(referenceMsg) + ' 2' },
                        { columnId: 'customerReference3', translation: t.DQHY(referenceMsg) + ' 3' },
                        { columnId: 'customerReference4', translation: t.DQHY(referenceMsg) + ' 4' },
                      ],
                      search
                    )}
                </>
              ),
            },
          ]}
        />
      </BillingAccountLink>
    );

    return {
      billingAccountDisplayId,
      billingAccountName: (
        <CL.Description
          columns={1}
          items={[
            {
              title: ba.billingAccountName && highlightKeyword(ba.billingAccountName, search),
              description: ba.billingAccountExtensionName && highlightKeyword(ba.billingAccountExtensionName, search),
            },
          ]}
        />
      ),
      payerName: (
        <CL.Description
          columns={1}
          items={[
            {
              title: ba.payerName ? highlightKeyword(ba.payerName, search) : ba.payerName,
              description: ba.payerNameExtension && highlightKeyword(ba.payerNameExtension, search),
            },
          ]}
        />
      ),
      billingContactName: (
        <CL.Description
          columns={1}
          items={[
            {
              title: ba.billingContactName && highlightKeyword(ba.billingContactName, search),
              description: ba.billingContactPhone
                ? `${ba.billingContactPhone}, ${ba.billingContactEmail}`
                : `${ba.billingContactEmail}`,
            },
          ]}
        />
      ),
      subscriptionCount: <>{ba.subscriptionCount || 0}</>,
      companyName: <>{getCompanyName(authenticatedUser, row.result.accountMasterId)}</>,
    };
  });

  return (
    <Grid>
      <div className="of-create-new-billing-account ds-display--flex ds-justify-content--flex-end ds-margin-top--4">
        <CL.Button
          onClick={() =>
            navigate(paths.BILLING_ACCOUNTS_CREATE_NEW_BA, {
              state: {
                defaultDeliveryMethod,
                defaultBillingContactId,
              },
            })
          }
        >
          {t.TFDQ('Create new')}
        </CL.Button>
      </div>
      <div className="of-billing-account-list">
        {isMultiBiz(authenticatedUser) && <ShowAllAccountsToggleForListViews className="ds-margin-bottom--4" />}
        <ListSearch
          onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
        />
        <Table columns={columns} noItemsText={t.NVGB('No billing agreements')} rows={rows || []} />
        <ListPagination
          totalItems={(search && billingAccounts?.searchResults?.length) || billingAccounts?.total || 0}
        />
      </div>
    </Grid>
  );
};
