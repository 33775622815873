import * as CL from '@design-system/component-library';
import { AddOrSelectBillingAccounts } from '../AddOrSelectBillingAccounts/AddOrSelectBillingAccounts.js';
import { BreadCrumbs } from '../BreadCrumbs/index.js';
import {
  CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE,
  filterAndGetDefaultBillingAccountId,
  getEmptyBillingAccount,
  getReceiverType,
  prepareBillingAccountSave,
} from '../../common/utils/billingAccountUtils.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { InProgressCatalogAction } from '../../common/enums.js';
import { VirtualCatalogProductsSummary } from '../CatalogProductsSummary/VirtualCatalogProductSummary.js';
import { WizardActions } from '../WizardActions/index.js';
import {
  agreementPeriodMsg,
  alvZeroMsg,
  cancelMsg,
  catalogCorporateMessageLabelMsg,
  catalogDetailsMsg,
  companysShareOfMonthlyFeeMsg,
  damageInsuranceMsg,
  deviceListsMsg,
  inUseMsg,
  monthMsg,
  nameOfCatalogMsg,
  newDeviceListMsg,
  notInUseMsg,
  omaElisaForCompaniesMsg,
  productTypeMsg,
  t,
} from '../../common/i18n/index.js';
import { convertStringMapToCommonErrors, getElementsWithErrors } from '../../common/utils/errorUtils.js';
import {
  getCorporateShareAsText,
  getDamageInsuranceDisplayableItems,
  productTypeDetails,
} from '../../common/utils/catalogUtils.js';
import { getTopMostElement } from '../../common/utils/domUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { scrollTo } from '../../common/utils/browserUtils.js';
import { useEffect, useRef, useState } from 'react';
import type { BillChannel, BillingAccount, Catalog, Contact } from '../../generated/api/models.js';
import type { BillingAccountOrErrorSupplier, CommonError } from '../../common/types/errors.js';
import type { BillingAccountsState, CompanyInfoState } from '../../common/types/states.js';
import type { CatalogProduct } from '../../common/utils/catalogUtils.js';

interface VirtualCatalogSummaryProps {
  catalog: Partial<Catalog>;
  onBackClick: () => void;
  onSubmit: (
    publishCatalog: boolean,
    selectedBaId?: string,
    newBillingAccount?: BillingAccount,
    newBillingAccountValidationErrors?: CommonError[]
  ) => void;
  products: Record<string, Array<CatalogProduct>>;
  billChannels?: BillChannel[];
  billingAccounts?: BillingAccountsState;
  contacts?: Contact[];
  companyInfo?: CompanyInfoState;
  billingAccountsErrors?: CommonError[];
  billingAccountsEditingChanges?: { [s: string]: string | undefined }[];
  inProgressAction?: InProgressCatalogAction;
  companyName: string;
}

export const VirtualCatalogSummary = ({
  onBackClick,
  onSubmit,
  products,
  catalog,
  billingAccounts,
  inProgressAction,
  billChannels,
  billingAccountsEditingChanges,
  billingAccountsErrors,
  companyInfo,
  contacts,
  companyName,
}: VirtualCatalogSummaryProps) => {
  const [shouldScrollToError, setShouldScrollToError] = useState(false);
  const [selectedBaId, setSelectedBaId] = useState(catalog.billingAccountId);
  const prepareNewBillingAccountSaveValues = useRef<BillingAccountOrErrorSupplier>();
  const domRefs = useRef<{ [elementKey: string]: HTMLElement }>({});

  useEffect(() => {
    if (!selectedBaId && billingAccounts) {
      setSelectedBaId(filterAndGetDefaultBillingAccountId(billingAccounts));
    }
  }, [billingAccounts]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  const setRefCallback = (key: string, ref: HTMLElement | null) => {
    if (ref) {
      domRefs.current[key] = ref;
    } else {
      delete domRefs.current[key];
    }
  };

  useEffect(() => {
    if (shouldScrollToError && billingAccountsErrors) {
      scrollTo(getTopMostElement(getElementsWithErrors(domRefs.current, billingAccountsErrors)));
      setShouldScrollToError(false);
    }
  }, [billingAccountsErrors]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  const breadCrumbs = (
    <BreadCrumbs
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.COBB(deviceListsMsg), path: paths.COMPANY_INFO_CATALOGS },
        { name: t.MPFC(newDeviceListMsg) },
      ]}
    />
  );

  const content: JSX.Element = (
    <div>
      <CL.Description
        columns={1}
        items={[
          {
            title: t.M6TP(nameOfCatalogMsg),
            description: catalog.name,
          },
          {
            title: t.R6HP(productTypeMsg),
            description: catalog.productType && productTypeDetails()[catalog.productType].displayValue,
          },
          ...(catalog.contractPeriod
            ? [
                {
                  title: t.ULI0(agreementPeriodMsg),
                  description: `${catalog.contractPeriod} ${t.XXVX(monthMsg)}`,
                },
              ]
            : []),
          ...(catalog.corporateShare !== undefined
            ? [
                {
                  title: `${t.H8Q4(companysShareOfMonthlyFeeMsg)} (${t.S8TX(alvZeroMsg)})`,
                  description: getCorporateShareAsText(catalog.corporateShare),
                },
              ]
            : []),
          {
            title: t.QSXP(damageInsuranceMsg),
            description:
              catalog.damageInsurance && catalog.damageInsurance.length > 0
                ? getDamageInsuranceDisplayableItems(catalog.damageInsurance)
                : t.MUF5(notInUseMsg),
          },
          {
            title: t.Q03S('Device enrollment program'),
            description: catalog.enrollmentProgramConsent
              ? `${t.V34H(inUseMsg)}, alias: ${catalog.enrollmentProgramAlias || '-'}`
              : t.MUF5(notInUseMsg),
          },
          {
            title: t.C8DA(catalogCorporateMessageLabelMsg),
            description: catalog.corporateMessage ? catalog.corporateMessage : t.MUF5(notInUseMsg),
          },
        ]}
      />
      <VirtualCatalogProductsSummary products={products} />
      <div id="defaultBillingAccount" className="ds-padding-vertical--7">
        <AddOrSelectBillingAccounts
          additionalInformation={
            <p>
              {t.ND39(
                'Select the default billing account for Elisa service terminal devices and device lists. In the future, you can use the default billing account or, if you wish, create a device list or subscription-specific billing account.'
              )}
            </p>
          }
          addNewBA={true}
          detailedView={true}
          billingAccounts={billingAccounts}
          billingAccountsEditingChanges={billingAccountsEditingChanges}
          billingAccountsErrors={billingAccountsErrors}
          billChannels={billChannels}
          companyInfo={companyInfo}
          contacts={contacts}
          prepareNewBillingAccountSaveValues={prepareSaveValues =>
            (prepareNewBillingAccountSaveValues.current = prepareSaveValues)
          }
          getBillingAccountIdOnchange={billingAccountId => {
            if (billingAccountId !== CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE) {
              prepareNewBillingAccountSaveValues.current = undefined;
            }
            setSelectedBaId(billingAccountId);
          }}
          saving={false}
          selectedBaId={selectedBaId}
          setRefCallBack={setRefCallback}
          title={t.IXED('Device catalog billing information')}
        />
      </div>

      <div className="ds-padding-vertical--2">
        <WizardActions
          isLoading={inProgressAction === InProgressCatalogAction.PUBLISH_VIRTUAL_CATALOG_DRAFT}
          onBackClick={onBackClick}
          onForwardClick={() => {
            let newBillingAccount: BillingAccount | undefined;
            let newBillingAccountValidationErrors: CommonError[] | undefined;
            if (prepareNewBillingAccountSaveValues.current) {
              const { obj, validationErrors } = prepareNewBillingAccountSaveValues.current();
              const receiverType = getReceiverType(obj);
              newBillingAccount = prepareBillingAccountSave(getEmptyBillingAccount(companyName), obj, receiverType);
              newBillingAccountValidationErrors = convertStringMapToCommonErrors(validationErrors);
            }
            onSubmit(true, selectedBaId, newBillingAccount, newBillingAccountValidationErrors);
            setShouldScrollToError(true);
          }}
          forwardButtonText={t.JC06('PUBLISH')}
          backButtonText={t.B2V1(cancelMsg).toUpperCase()}
        />
      </div>

      <div className="ds-text-align--right ds-padding-vertical--2">
        <CL.Button
          size="l"
          color="light"
          loading={inProgressAction === InProgressCatalogAction.UPSERT_VIRTUAL_CATALOG_DRAFT}
          onClick={() => {
            let newBillingAccount: BillingAccount | undefined;
            let newBillingAccountValidationErrors: CommonError[] | undefined;
            if (prepareNewBillingAccountSaveValues.current) {
              const { obj, validationErrors } = prepareNewBillingAccountSaveValues.current();
              const receiverType = getReceiverType(obj);
              newBillingAccount = prepareBillingAccountSave(getEmptyBillingAccount(companyName), obj, receiverType);
              newBillingAccountValidationErrors = convertStringMapToCommonErrors(validationErrors);
            }
            setShouldScrollToError(true);
            onSubmit(false, selectedBaId, newBillingAccount, newBillingAccountValidationErrors);
          }}
        >
          {t.TJKD('Save draft')}
        </CL.Button>
        <p className="ds-text--s">
          {t.K6XH('You can save the list as a draft and publish it later.')}
          <br />{' '}
          {t.BZWY('Please note that if you have added a new billing agreement, it will be available immediately.')}
        </p>
      </div>
    </div>
  );
  return (
    <DetailsWrapper
      classes={['of-catalog-products-summary']}
      content={content}
      detailsTop={breadCrumbs}
      heading={t.XE4X(catalogDetailsMsg)}
      heroWhiteBackground={true}
      id="catalog-products-summary"
    />
  );
};
