import * as CL from '@design-system/component-library';
import { Fragment } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import './HierarchicalSelector.scss';

export interface HierarchicalSelectorItem {
  name: string;
  label: string;
  afterLabelItem?: JSX.Element;
  checked?: boolean;
  disabled?: boolean;
}

export interface HierarchicalSelectorProps {
  items: HierarchicalSelectorItem[];
  additionalItems?: HierarchicalSelectorItem[];
  onValueChange?: (changedItem: HierarchicalSelectorItem, values: { [key: string]: boolean }) => void;
}

interface HierarchicalCheckboxProps extends HierarchicalSelectorItem {
  onValueChange: (checked: boolean) => void;
}

const HierarchicalCheckbox = ({
  label,
  name,
  checked,
  disabled,
  afterLabelItem,
  onValueChange,
}: HierarchicalCheckboxProps) => {
  return (
    <div className="of-hierarchical-selector__item">
      <div className="ds-checkbox">
        <input
          type="checkbox"
          checked={checked}
          name={name}
          disabled={disabled}
          onChange={e => onValueChange(e.currentTarget.checked)}
        />
        <label className={`ds-checkbox__label ${disabled ? 'disabled' : ''}`}>
          <CL.Icon icon="check" type="filled" className="ds-checkbox__icon" />
          {label}
        </label>
      </div>
      {afterLabelItem}
    </div>
  );
};

export const HierarchicalSelector = ({ items, additionalItems, onValueChange }: HierarchicalSelectorProps) => {
  const { setValue, getValues } = useFormContext();
  const values = useWatch();

  const handleHierarchicalValueChange = (index: number, selectedValue: boolean) => {
    const previousValues = getValues();
    items.forEach((item, itemIndex) => {
      const shouldToggleUpperValues = selectedValue && itemIndex <= index;
      const shouldToggleLowerValues = !selectedValue && itemIndex >= index;

      if (shouldToggleUpperValues || shouldToggleLowerValues) {
        setValue(item.name, selectedValue);
      }
    });

    const changedItem = { ...items[index], checked: selectedValue };

    onValueChange?.(changedItem, previousValues);
  };

  const hierarchicalCheckboxes = items.map((item, index) => {
    return (
      <Fragment key={item.name}>
        <div className="of-hierarchical-selector__spacer"></div>
        <HierarchicalCheckbox
          {...item}
          checked={values[item.name]}
          onValueChange={selectedValue => handleHierarchicalValueChange(index, selectedValue)}
        />
      </Fragment>
    );
  });

  const additionalCheckboxes = additionalItems?.map(item => (
    <div key={item.name} className="of-hierarchical-selector__additional-item">
      <CL.Checkbox
        name={item.name}
        label={item.label}
        checked={values[item.name]}
        onChange={event => setValue(item.name, event.currentTarget.checked)}
      />
      {item.afterLabelItem}
    </div>
  ));

  return (
    <div className="of-hierarchical-selector">
      {hierarchicalCheckboxes}
      {additionalCheckboxes && <hr />}
      {additionalCheckboxes}
    </div>
  );
};
