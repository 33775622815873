import type { OnlineModelsResponse } from '../../generated/api/onlineModelsResponse.js';
import type { PriceData } from './shoppingCartUtils.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';

export const getPriceForSalesProduct = (
  cartItem: ShoppingCartItemForCheckout,
  onlineModelsHeadersForSalesProducts: OnlineModelsResponse
): PriceData => {
  const onlineModelHeader = onlineModelsHeadersForSalesProducts.headers?.find(
    header => header.onlineModelCode === cartItem.onlineModelCode
  );
  const headerPriceEntry = onlineModelHeader?.lowestPrices[0].prices[0];
  return {
    price: {
      monthlyRecurringCharge: headerPriceEntry?.monthlyRecurringCharge,
      oneTimeCharge: headerPriceEntry?.oneTimeCharge,
      payments: 0,
    },
    isDiscountedPrice: false,
  };
};
