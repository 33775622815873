import * as CL from '@design-system/component-library';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { t } from '../../common/i18n/index.js';

export interface LoadingDialogError {
  header: string;
  message: string;
  buttonAction?: () => void;
  buttonText?: string;
  pdfLink?: string;
}

export interface LoadingDialogProps {
  loadingText: string;
  error?: LoadingDialogError;
}

export const LoadingDialog = ({ error, loadingText }: LoadingDialogProps) => (
  <DialogWrapper
    buttons={
      error?.buttonText
        ? [
            {
              color: 'link',
              onClick: error.buttonAction ? () => error.buttonAction!() : () => {},
              text: error.buttonText,
            },
          ]
        : []
    }
    header={error ? error.header : loadingText}
    onCloseDialog={() => {}}
  >
    <div>
      {!error && (
        <div className="ds-display--flex ds-justify-content--center ds-padding--4">
          <CL.LoadingSpinner size="xl" />
        </div>
      )}
      {error && (
        <p>
          {error.message}
          {error.pdfLink && (
            <b>
              <a href={error.pdfLink} target="_blank" rel="noreferrer">
                {t.Z3L0('More information can be found')}
              </a>
            </b>
          )}
        </p>
      )}
    </div>
  </DialogWrapper>
);
