import * as CL from '@design-system/component-library';
import { AddOnChangeConfirmationDialogContent } from '../SubscriptionDetails/AddOnChangeConfirmationDialogContent.js';
import { DialogType } from '../../common/enums.js';
import { EcommerceEventTypeV4, pushToDataLayer } from '../../common/analytics.js';
import { calculateUpdatedPrice, getMobileVoiceAttributes } from '../SubscriptionDetails/addOnDependencyUtils.js';
import { cancelMsg, confirmMsg, t } from '../../common/i18n/index.js';
import { changeSubscriptionAddons, showDialog } from '../../selfservice/actions/index.js';
import {
  getAddOnPurchaseAnalyticsEvent,
  getAddonAddToCartAnalyticsEvent,
  getRemovedAddOnsAnalyticsEvent,
  useAnalyticsId,
} from './addOnAnalyticsUtils.js';
import { getSubscriptionStatus } from '../../common/utils/subscriptionUtils.js';
import { useDispatch } from 'react-redux';
import type { AddOn } from '../../generated/api/addOn.js';
import type { CategoryKey } from '../../common/utils/categoryUtils.js';
import type { Subscription } from '../../generated/api/subscription.js';
import type { SubscriptionAction } from '../../generated/api/subscriptionAction.js';

import './AddOnManagementButtons.scss';

interface AddOnManagementButtonsProps {
  showEditView: boolean;
  resetEditView: () => void;
  setShowEditView: (show: boolean) => void;
  shouldDisableConfirm: boolean;
  addedAddOns: AddOn[];
  removedAddOns: AddOn[];
  subscription: Subscription;
  category: CategoryKey;
  changedFeatures: string[];
  isEmployee: boolean;
  subscriptionActions?: SubscriptionAction[];
  updatedAddOns: AddOn[];
  selectedLanguage?: string;
}

const calculateServiceFee = (addedAddOns: AddOn[]) => {
  return addedAddOns
    .map(added => added.price?.effectivePrice.oneTimeCharge || 0)
    .reduce((total, val) => total + val, 0);
};

const pushAnalyticsEvent = (
  addOns: AddOn[],
  eventType: EcommerceEventTypeV4,
  analyticsId: string
): undefined | void => {
  if (addOns.length === 0) {
    return;
  }
  switch (eventType) {
    case EcommerceEventTypeV4.ADD_TO_CART:
      return pushToDataLayer(getAddonAddToCartAnalyticsEvent(addOns, analyticsId));
    case EcommerceEventTypeV4.PURCHASE:
      return pushToDataLayer(getAddOnPurchaseAnalyticsEvent(addOns, analyticsId));
    case EcommerceEventTypeV4.REMOVE_ADDITIONAL_SERVICES:
      return pushToDataLayer(getRemovedAddOnsAnalyticsEvent(addOns, analyticsId));
    default:
      throw new Error(`Unknown event type: ${eventType}`);
  }
};

const EditButtons = ({
  resetEditView,
  shouldDisableConfirm,
  addedAddOns,
  removedAddOns,
  subscription,
  category,
  isEmployee,
  updatedAddOns,
  selectedLanguage,
}: AddOnManagementButtonsProps) => {
  const dispatch = useDispatch();
  const analyticsBundleId = useAnalyticsId();

  return (
    <div className="of-addon-management-buttons__edit">
      <CL.Button
        className="ds-margin-right--2"
        onClick={() => {
          const addOnCodesToAdd = addedAddOns.map(addOn => addOn.addOnCode);
          const finalRemovalCodes = removedAddOns.map(addOn => addOn.addOnCode);
          const updatedAddOnCodes = updatedAddOns.map(addOn => addOn.addOnCode);
          const addOnAttributes = getMobileVoiceAttributes(addOnCodesToAdd, updatedAddOnCodes, selectedLanguage);
          const updatedPrice = calculateUpdatedPrice(addedAddOns, removedAddOns, category, subscription);

          pushAnalyticsEvent(addedAddOns, EcommerceEventTypeV4.ADD_TO_CART, analyticsBundleId);

          dispatch(
            showDialog({
              type: DialogType.GENERIC_CONFIRMATION_DIALOG,
              header: t.LYSP('Service changes'),
              onConfirm: () => {
                dispatch(
                  changeSubscriptionAddons(
                    subscription,
                    addOnCodesToAdd,
                    finalRemovalCodes,
                    updatedAddOnCodes,
                    addOnAttributes
                  )
                );
                pushAnalyticsEvent(addedAddOns, EcommerceEventTypeV4.PURCHASE, analyticsBundleId);
                pushAnalyticsEvent(removedAddOns, EcommerceEventTypeV4.REMOVE_ADDITIONAL_SERVICES, analyticsBundleId);
                resetEditView();
              },
              body: (
                <AddOnChangeConfirmationDialogContent
                  addedAddOns={addedAddOns}
                  removedAddOns={removedAddOns}
                  updatedPrice={updatedPrice}
                  serviceFee={calculateServiceFee(addedAddOns)}
                  isEmployee={isEmployee}
                />
              ),
              disableConfirm: updatedPrice === undefined,
            })
          );
        }}
        disabled={shouldDisableConfirm}
      >
        {t.QVYK(confirmMsg)}
      </CL.Button>
      <CL.Button color="link" onClick={() => resetEditView()}>
        {t.B2V1(cancelMsg)}
      </CL.Button>
    </div>
  );
};

const ViewButton = ({
  subscription,
  subscriptionActions,
  changedFeatures,
  setShowEditView,
}: AddOnManagementButtonsProps) => {
  const dispatch = useDispatch();

  return (
    <div className="of-addon-management-buttons__view">
      <CL.Button
        color="light"
        onClick={() => {
          if (
            getSubscriptionStatus(subscription, subscriptionActions).isChangeOfferAllowed &&
            changedFeatures.length === 0
          ) {
            setShowEditView(true);
          } else {
            dispatch(showDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING }));
          }
        }}
      >
        {t.BG4W('Edit additional services')}
      </CL.Button>
    </div>
  );
};

export const AddOnManagementButtons = (props: AddOnManagementButtonsProps) => {
  const { showEditView } = props;

  return (
    <div className="of-addon-management-buttons">
      {showEditView ? <EditButtons {...props} /> : <ViewButton {...props} />}
    </div>
  );
};
