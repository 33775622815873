import { Icon } from '../Icon/Icon.js';
import type { IconColor } from '../Icon/Icon.js';

import './StatusColumn.scss';

export interface StatusColumnProps {
  status?: string;
  color: IconColor;
}

export const StatusColumn = (props: StatusColumnProps) => (
  <div className="of-status-column">
    <div className="of-status-icon">
      <Icon icon="record" type="filled" size="s" color={props.color} />{' '}
    </div>
    <div className="of-status ds-description__list-item-title">{props.status}</div>
  </div>
);
