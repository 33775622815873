import { BlockedErrorTypes, PbxErrorTypes, TypeKeys } from '../actions/index.js';
import { CommonErrorType, CreditDecision, DialogType } from '../../common/enums.js';
import { ConflictedAuthenticationResult, CreditCheckDecision } from '../../generated/api/models.js';
import {
  companyCreditLimitExceededEmployeeMsg,
  corporateOrExtensionNumberIsNotAvailableMsg,
  customerServiceNumberLabelMsg,
  customerServiceNumberOpenLongMsg,
  customerServiceNumberPriceMsg,
  employeeCreditCheckFailedMsg,
  ifProblemPersistsMsg,
  orderCouldNotBeProcessedMsg,
  problemWithProcessingOrderMsg,
  punchoutDeviceLimitMsg,
  somethingWentWrongMsg,
  subscriptionClosedByCreditMsg,
  subscriptionClosedMsg,
  systemErrorOccurredMsg,
  t,
  vakioConfigurationErrorMsg,
} from '../../common/i18n/index.js';
import { getBillingAccountErrorsFromAction } from './fixedBroadbandOrderReducer.js';
import { getErrorsFromCheckout } from '../../common/utils/checkoutUtils.js';
import { getErrorsFromReview } from '../../common/utils/errorUtils.js';
import { getErrorsFromSupportCaseDialog } from '../../components/SupportCaseDialog/SupportCaseDialog.js';
import { getErrorsFromUpdateUserDetails } from '../../components/UserDetails/UserDetails.js';
import { getErrorsFromUpsertBillingAccount } from '../../common/utils/billingAccountUtils.js';
import { getErrorsFromUpsertContactDialog } from '../../components/UpsertContactDialog/UpsertContactDialog.js';
import { getErrorsFromUpsertDeliveryAddress } from '../../components/OrderDeliveryOptions/OrderDeliveryOptions.js';
import type { DialogState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function dialogReducer(
  state: DialogState | undefined | null,
  action: SelfServiceActionTypes
): DialogState | null {
  if (typeof state === 'undefined') {
    return null;
  }

  function verifyBlockType(blockType: string): DialogState | null {
    let body = t.RVSV('Unknown error.');
    switch (blockType) {
      case BlockedErrorTypes.COLLECTION_INITIATED: {
        body = t.VMT9(
          'Subscription cannot be activated due to unpaid invoices. Please check the status of the invoices.'
        );
        break;
      }
      case BlockedErrorTypes.CREDIT_INITIATED: {
        body = t.P5F7('Subscription cannot be activated through OmaElisa.');
        break;
      }
    }
    return {
      ...state!,
      params: {
        body,
        header: t.M59G('An error occurred while activating the subscription'),
        type: DialogType.GENERIC_INFO_DIALOG,
      },
      submitInProgress: false,
      visible: true,
    };
  }

  enum PunchoutOrderFailure {
    DEVICE_LIMIT_EXCEEDED = 'DEVICE_LIMIT_EXCEEDED',
  }

  switch (action.type) {
    case TypeKeys.SHOW_DIALOG: {
      return {
        params: action.params,
        visible: true,
      };
    }

    case TypeKeys.ADD_SUPPORT_CASE: {
      const errors = getErrorsFromSupportCaseDialog(action.supportCase);
      return {
        ...state!,
        errors: errors,
        submitInProgress: !errors,
      };
    }

    case TypeKeys.UPSERT_CONTACT: {
      // Not even in dialog...
      const errors = getErrorsFromUpsertContactDialog(action.contact, action.customerType, action.validationErrors);
      return {
        ...state!,
        errors,
        submitInProgress: !errors,
      };
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION_FAILED: {
      const header = t.SKDZ(vakioConfigurationErrorMsg);
      let body = t.OKCS(ifProblemPersistsMsg);
      if (action.errors?.[0]) {
        const error = action.errors[0];
        if (error.type === (CommonErrorType.BAD_REQUEST || PbxErrorTypes.NUMBER_NOT_AVAILABLE)) {
          body = t.UR1U(corporateOrExtensionNumberIsNotAvailableMsg);
        } else {
          body = t.JCJF(systemErrorOccurredMsg);
        }
      }
      return {
        ...state!,
        params: {
          header,
          body,
          type: DialogType.GENERIC_INFO_DIALOG,
        },
        visible: true,
      };
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT:
    case TypeKeys.SWITCH_ACCOUNT:
    case TypeKeys.TERMINATE_SUBSCRIPTION_DEVICE_SERVICE: {
      return { ...state!, submitInProgress: true, errors: undefined };
    }

    case TypeKeys.TERMINATE_SUBSCRIPTION_DEVICE_SERVICE_FAILED: {
      return {
        ...state!,
        errors: [{ type: CommonErrorType.SYSTEM, message: t.JDTO(somethingWentWrongMsg) }],
        submitInProgress: false,
      };
    }

    case TypeKeys.POLL_FOR_CREDIT_CHECK_FAILED: {
      // Cannot know the state of the credit check -> cannot know if the order is processed or not
      return {
        ...state!,
        errors: [
          {
            message: t.STWG(
              problemWithProcessingOrderMsg,
              customerServiceNumberLabelMsg,
              t.HQWW(customerServiceNumberPriceMsg),
              t.HQWV(customerServiceNumberOpenLongMsg)
            ),
            type: CommonErrorType.FORBIDDEN,
          },
        ],
      };
    }

    case TypeKeys.POLL_FOR_CREDIT_CHECK_FULFILLED: {
      if (
        [CreditDecision.Accepted, CreditDecision.AcceptedInternal, CreditDecision.Error].includes(action.creditDecision)
      ) {
        return null;
      } else {
        let errorMessage;
        let errorType;
        if (
          [
            CreditDecision.DeniedBlacklist,
            CreditDecision.DeniedCreditLimit,
            CreditDecision.DeniedExternal,
            CreditDecision.DeniedInternal,
            CreditDecision.DeniedCompanyRegistrationPending,
          ].includes(action.creditDecision)
        ) {
          errorType = CommonErrorType.FORBIDDEN;
          errorMessage = t.STWG(
            problemWithProcessingOrderMsg,
            customerServiceNumberLabelMsg,
            t.HQWW(customerServiceNumberPriceMsg),
            t.HQWV(customerServiceNumberOpenLongMsg)
          );
        } else {
          errorType = CommonErrorType.SYSTEM;
          errorMessage = t.MOF8(
            orderCouldNotBeProcessedMsg,
            customerServiceNumberLabelMsg,
            t.HQWV(customerServiceNumberOpenLongMsg),
            t.HQWW(customerServiceNumberPriceMsg)
          );
        }

        return {
          ...state!,
          errors: [
            {
              message: errorMessage,
              type: errorType,
            },
          ],
        };
      }
    }

    case TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER:
      if (getBillingAccountErrorsFromAction(action)) {
        return state;
      }
      return {
        ...state!,
        params: {
          type: DialogType.SUBMIT_ORDER,
        },
        visible: true,
        errors: undefined,
      };

    case TypeKeys.SUBMIT_ONLINE_ORDER: {
      if (
        getErrorsFromUpsertDeliveryAddress(
          action.deliveryAddress,
          action.isCompanyNameRequired,
          action.deliveryAddressValidationErrors
        ) ||
        (action.newBillingAccount &&
          getErrorsFromUpsertBillingAccount(
            action.newBillingAccount,
            action.newBillingAccountValidationErrors,
            action.newBillingAccountCommonFunction
          )) ||
        getErrorsFromCheckout(action.approverContact, action.personBillingAddress, action.personBillingEmail) ||
        (action.contactPerson &&
          getErrorsFromUpdateUserDetails(
            action.contactPerson,
            action.customerType,
            action.contactPersonValidationErrors
          ))
      ) {
        return state;
      }

      return {
        ...state!,
        params: {
          type: DialogType.SUBMIT_ORDER,
        },
        visible: true,
        errors: undefined,
      };
    }

    case TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT_FAILED: {
      return {
        ...state!,
        errors: [
          {
            message: t.MOF8(
              orderCouldNotBeProcessedMsg,
              customerServiceNumberLabelMsg,
              t.HQWV(customerServiceNumberOpenLongMsg),
              t.HQWW(customerServiceNumberPriceMsg)
            ),
            type: CommonErrorType.SYSTEM,
          },
        ],
      };
    }

    case TypeKeys.SUBMIT_ONLINE_ORDER_FAILED: {
      if (action.errors?.[0].source?.error?.message.includes(CreditCheckDecision.EMPLOYEE_NEGATIVE_CREDIT_DECISION)) {
        return {
          ...state!,
          errors: [
            {
              message: CreditCheckDecision.EMPLOYEE_NEGATIVE_CREDIT_DECISION,
              type: CommonErrorType.FORBIDDEN,
            },
          ],
        };
      }
      return {
        ...state!,
        errors: [
          {
            message: t.MOF8(
              orderCouldNotBeProcessedMsg,
              customerServiceNumberLabelMsg,
              t.HQWV(customerServiceNumberOpenLongMsg),
              t.HQWW(customerServiceNumberPriceMsg)
            ),
            type: CommonErrorType.SYSTEM,
          },
        ],
      };
    }

    case TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT: {
      return {
        ...state!,
        params: {
          type: DialogType.SUBMIT_ORDER,
        },
        visible: true,
        errors: undefined,
      };
    }

    case TypeKeys.ADD_MISSING_MOBILE_NUMBER_AND_LOG_IN:
    case TypeKeys.ADD_RING_ADMIN:
    case TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX:
    case TypeKeys.CHANGE_ADDONS:
    case TypeKeys.CHANGE_SIM_CARD:
    case TypeKeys.COPY_VIRTUAL_CATALOG:
    case TypeKeys.DELETE_VIRTUAL_CATALOG:
    case TypeKeys.POST_EPP_REDEEM_TERMINATE:
    case TypeKeys.SUSPEND_SUBSCRIPTION:
    case TypeKeys.SELECT_PRIMARY_ACCOUNT_AND_LOG_IN:
    case TypeKeys.LOG_IN:
    case TypeKeys.RESUME_SUBSCRIPTION:
    case TypeKeys.SELECT_USERNAME:
    case TypeKeys.UPSERT_VIRTUAL_CATALOG: {
      return {
        ...state!,
        errors: undefined,
        submitInProgress: true,
      };
    }

    // TODO Sebastian 26.4.2022
    // TODO It seems that when null is returned from this list it means that the DialogWrapper will close
    // TODO This should refactored in a way that the component using DialogWrapper is responsible for closing
    // TODO the dialog with dispatch(closeDialog())
    case TypeKeys.LOG_OUT:
    case TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX_FULFILLED:
    case TypeKeys.CHANGE_ADDONS_FULFILLED:
    case TypeKeys.CHANGE_SIM_CARD_FULFILLED:
    case TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FULFILLED:
    case TypeKeys.CLOSE_DIALOG:
    case TypeKeys.DELETE_VIRTUAL_CATALOG_FULFILLED:
    case TypeKeys.POST_EPP_REDEEM_TERMINATE_FULFILLED:
    case TypeKeys.SUSPEND_SUBSCRIPTION_FULFILLED:
    case TypeKeys.RESUME_SUBSCRIPTION_FULFILLED:
    case TypeKeys.TERMINATE_SUBSCRIPTION_DEVICE_SERVICE_FULFILLED:
    case TypeKeys.REVIEW_FULFILLED:
    case TypeKeys.INITIATE_EMPLOYEE_ONBOARDING_RESEND_FAILED:
    case TypeKeys.INITIATE_EMPLOYEE_ONBOARDING_FULFILLED:
    case TypeKeys.UPSERT_CONTACT_FULFILLED:
    case TypeKeys.LOG_IN_FULFILLED:
    case TypeKeys.SELECT_USERNAME_FULFILLED:
    case TypeKeys.UPSERT_VIRTUAL_CATALOG_FULFILLED:
    case TypeKeys.RESOLVE_MISSING_PRIMARY_ACCOUNT_FULFILLED:
    case TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER_FAILED:
    case TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER_FULFILLED:
    case TypeKeys.SUBMIT_MOBILEID_CONTRACT_FULFILLED:
    case TypeKeys.TERMINATE_MOBILEID_CONTRACT_FULFILLED:
    case TypeKeys.SWITCH_ACCOUNT_FULFILLED: {
      return null;
    }

    case TypeKeys.ADD_SUPPORT_CASE_FULFILLED: {
      if (action.showInfoDialog) {
        return {
          ...state!,
          params: {
            type: DialogType.SUPPORT_CASE_SENT_INFO_DIALOG,
          },
        };
      } else {
        return null;
      }
    }
    case TypeKeys.ADD_SUPPORT_CASE_FAILED:
    case TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX_FAILED:
    case TypeKeys.CHANGE_ADDONS_FAILED:
    case TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FAILED:
    case TypeKeys.CHANGE_SIM_CARD_FAILED:
    case TypeKeys.DELETE_VIRTUAL_CATALOG_FAILED:
    case TypeKeys.POST_EPP_REDEEM_TERMINATE_FAILED:
    case TypeKeys.SUSPEND_SUBSCRIPTION_FAILED:
    case TypeKeys.REVIEW_FAILED:
    case TypeKeys.INITIATE_EMPLOYEE_ONBOARDING_FAILED:
    case TypeKeys.UPSERT_CONTACT_FAILED:
    case TypeKeys.UPSERT_VIRTUAL_CATALOG_FAILED:
    case TypeKeys.RESOLVE_MISSING_PRIMARY_ACCOUNT_FAILED:
    case TypeKeys.SWITCH_ACCOUNT_FAILED: {
      return {
        ...state!,
        errors: [{ type: CommonErrorType.SYSTEM, message: t.JDTO(somethingWentWrongMsg) }],
        submitInProgress: false,
      };
    }

    case TypeKeys.SELECT_USERNAME_FAILED: {
      return {
        ...state!,
        errors: [
          {
            type: CommonErrorType.SYSTEM,
            message: t.JYLX(
              'Something went wrong, please try signing in again. If the problem persists, please call our customer service on {0} ({1}). Our customer service is open {2}.',
              customerServiceNumberLabelMsg,
              t.HQWW(customerServiceNumberPriceMsg),
              t.HQWV(customerServiceNumberOpenLongMsg)
            ),
          },
        ],
        submitInProgress: false,
      };
    }

    case TypeKeys.START_BROADBAND_FIXER_FAILED: {
      return {
        ...state!,
        params: {
          body: `${t.JDTO(somethingWentWrongMsg)}`,
          header: t.R28N('Starting broadband fixer failed'),
          type: DialogType.GENERIC_INFO_DIALOG,
        },
        visible: true,
        submitInProgress: false,
      };
    }

    case TypeKeys.POLL_FOR_CREDIT_CHECK_V2_FULFILLED: {
      switch (action.response.decision) {
        case CreditCheckDecision.PASS: {
          return null;
        }
        case CreditCheckDecision.COMPANY_CREDIT_LIMIT_EXCEEDED: {
          return {
            ...state!,
            errors: [
              {
                message: CreditCheckDecision.COMPANY_CREDIT_LIMIT_EXCEEDED,
                type: CommonErrorType.FORBIDDEN,
              },
            ],
          };
        }
        case CreditCheckDecision.EMPLOYEE_NEGATIVE_CREDIT_DECISION: {
          return {
            ...state!,
            errors: [
              {
                message: CreditCheckDecision.EMPLOYEE_NEGATIVE_CREDIT_DECISION,
                type: CommonErrorType.FORBIDDEN,
              },
            ],
          };
        }
        case CreditCheckDecision.INTERNAL_ERROR: {
          return {
            ...state!,
            errors: [
              {
                message: t.MOF8(
                  orderCouldNotBeProcessedMsg,
                  customerServiceNumberLabelMsg,
                  t.HQWV(customerServiceNumberOpenLongMsg),
                  t.HQWW(customerServiceNumberPriceMsg)
                ),
                type: CommonErrorType.SYSTEM,
              },
            ],
          };
        }
        case CreditCheckDecision.DECLINED_RECOMMEND_CARD_PAYMENT: {
          return {
            ...state!,
            errors: [
              {
                message: CreditCheckDecision.DECLINED_RECOMMEND_CARD_PAYMENT,
                type: CommonErrorType.FORBIDDEN,
              },
            ],
          };
        }
        default: {
          return {
            ...state!,
            errors: [
              {
                message: CreditCheckDecision.COMPANY_NEGATIVE_CREDIT_DECISION,
                type: CommonErrorType.FORBIDDEN,
              },
            ],
          };
        }
      }
    }

    case TypeKeys.REVIEW: {
      const errors = getErrorsFromReview(
        action.request,
        action.reviewType,
        action.approve,
        action.rejectReason,
        action.validationErrors
      );
      if (errors) {
        return {
          ...state!,
          errors,
        };
      }
      return {
        ...state!,
        errors: undefined,
        submitInProgress: true,
      };
    }

    case TypeKeys.INITIATE_EMPLOYEE_ONBOARDING: {
      if (action.validationErrors?.length) {
        return {
          ...state!,
          errors: action.validationErrors,
        };
      }
      return {
        ...state!,
        errors: undefined,
        submitInProgress: true,
      };
    }

    case TypeKeys.RESUME_SUBSCRIPTION_FAILED: {
      if (
        action.errors?.[0].source?.statusCode === 409 &&
        (action.blockType === BlockedErrorTypes.CREDIT_INITIATED ||
          action.blockType === BlockedErrorTypes.COLLECTION_INITIATED)
      ) {
        return {
          ...state!,
          params: {
            body: t.JIXE(
              subscriptionClosedByCreditMsg,
              customerServiceNumberLabelMsg,
              t.HQWV(customerServiceNumberOpenLongMsg)
            ),
            header: t.R28M(subscriptionClosedMsg),
            type: DialogType.GENERIC_INFO_DIALOG,
          },
          visible: true,
          submitInProgress: false,
        };
      }
      if (action.blockType) {
        return verifyBlockType(action.blockType);
      }
      return {
        ...state!,
        errors: [{ type: CommonErrorType.SYSTEM, message: t.JDTO(somethingWentWrongMsg) }],
        submitInProgress: false,
      };
    }

    case TypeKeys.COPY_VIRTUAL_CATALOG_FAILED: {
      return {
        ...state!,
        errors: [
          {
            type: CommonErrorType.SYSTEM,
            message: t.JDTO(somethingWentWrongMsg),
          },
        ],
        submitInProgress: false,
      };
    }

    case TypeKeys.COPY_VIRTUAL_CATALOG_FULFILLED: {
      if (state && state.params.type === DialogType.COPY_CATALOG_MULTI_BUSINESS) {
        return {
          ...state!,
          errors: action.accountsFailedToCopyTo?.length
            ? [
                {
                  type: CommonErrorType.SYSTEM,
                  message: t.UZ2G(
                    'Selected catalog cannot be copied to all of the selected companies. Please try again.'
                  ),
                },
              ]
            : undefined,
          params: {
            ...state.params,
            selectedAccounts: action.accountsFailedToCopyTo,
          },
          submitInProgress: false,
        };
      }
      return {
        ...state!,
        submitInProgress: false,
      };
    }

    case TypeKeys.ADD_MISSING_MOBILE_NUMBER_AND_LOG_IN_FAILED:
    case TypeKeys.SELECT_PRIMARY_ACCOUNT_AND_LOG_IN_FAILED:
    case TypeKeys.AUTH_VALIDATE_FAILED:
    case TypeKeys.LOG_IN_FAILED: {
      // case show selected primary userid OR dialog (JIT)
      if (action && action.errors && action.errors[0]) {
        if (action.errors[0].source && (action.errors[0].source.error as unknown as ConflictedAuthenticationResult)) {
          const error = action.errors[0].source.error as unknown as ConflictedAuthenticationResult;
          if (ConflictedAuthenticationResult.TypeEnum.MULTIPLE_USER_NAMES === error.type && error.userNames) {
            return {
              ...state,
              params: {
                type: DialogType.SELECT_USERNAME,
                usernames: error.userNames,
              },
              submitInProgress: false,
              visible: true,
            };
          } else if (
            ConflictedAuthenticationResult.TypeEnum.PRIMARY_ACCOUNT_NOT_SET === error.type &&
            error.availableAccounts &&
            action.params?.password &&
            action.params.userName
          ) {
            return {
              ...state,
              params: {
                availableAccounts: error.availableAccounts,
                password: action.params.password,
                type: DialogType.SELECT_PRIMARY_COMPANY,
                username: action.params.userName,
              },
              submitInProgress: false,
              visible: true,
            };
          } else if (
            ConflictedAuthenticationResult.TypeEnum.PRIMARY_ACCOUNT_NOT_SET === error.type &&
            error.availableAccounts
          ) {
            return {
              ...state,
              params: {
                availableAccounts: error.availableAccounts,
                type: DialogType.SELECT_PRIMARY_COMPANY,
              },
              submitInProgress: false,
              visible: true,
            };
          } else if (ConflictedAuthenticationResult.TypeEnum.MOBILE_NUMBER_MISSING_MFA_REQUIRED === error.type) {
            return {
              ...state,
              params: {
                type: DialogType.MFA_REQUIRED_PHONE,
              },
              submitInProgress: false,
              visible: true,
            };
          } else if (
            ConflictedAuthenticationResult.TypeEnum.MOBILE_NUMBER_MISSING === error.type &&
            action.params?.password &&
            action.params.userName
          ) {
            return {
              ...state,
              params: {
                password: action.params.password,
                type: DialogType.MISSING_MOBILE_NUMBER,
                username: action.params.userName,
              },
              submitInProgress: false,
              visible: true,
            };
          }
        } else if (state?.visible) {
          return {
            ...state,
            errors: [
              {
                type: CommonErrorType.SYSTEM,
                message: t.JYLX(
                  'Something went wrong, please try signing in again. If the problem persists, please call our customer service on {0} ({1}). Our customer service is open {2}.',
                  customerServiceNumberLabelMsg,
                  t.HQWW(customerServiceNumberPriceMsg),
                  t.HQWV(customerServiceNumberOpenLongMsg)
                ),
              },
            ],
            submitInProgress: false,
          };
        }
      }
      return {
        ...state!,
        errors: [{ type: CommonErrorType.SYSTEM, message: t.JDTO(somethingWentWrongMsg) }],
        submitInProgress: false,
      };
    }

    case TypeKeys.SUBMIT_PUNCHOUT_ORDER_FAILED: {
      const errorMsg = action.errors?.[0].source?.error?.message;

      if (errorMsg?.includes(CreditCheckDecision.EMPLOYEE_NEGATIVE_CREDIT_DECISION)) {
        return {
          ...state!,
          errors: [
            {
              message: t.ZE11(employeeCreditCheckFailedMsg),
              type: CommonErrorType.FORBIDDEN,
              category: CreditCheckDecision.EMPLOYEE_NEGATIVE_CREDIT_DECISION,
            },
          ],
        };
      } else if (
        errorMsg?.includes(CreditCheckDecision.COMPANY_NEGATIVE_CREDIT_DECISION) ||
        errorMsg?.includes(CreditCheckDecision.COMPANY_CREDIT_LIMIT_EXCEEDED) ||
        errorMsg?.includes(CreditCheckDecision.DECLINED)
      ) {
        return {
          ...state!,
          errors: [
            {
              message: t.GCDV(companyCreditLimitExceededEmployeeMsg),
              type: CommonErrorType.FORBIDDEN,
            },
          ],
        };
      } else if (errorMsg?.includes(PunchoutOrderFailure.DEVICE_LIMIT_EXCEEDED)) {
        return {
          ...state!,
          errors: [
            {
              message: t.HC61(punchoutDeviceLimitMsg),
              type: CommonErrorType.FORBIDDEN,
            },
          ],
        };
      }
      return {
        ...state!,
        errors: [
          {
            message: t.MOF8(
              orderCouldNotBeProcessedMsg,
              customerServiceNumberLabelMsg,
              t.HQWV(customerServiceNumberOpenLongMsg),
              t.HQWW(customerServiceNumberPriceMsg)
            ),
            type: CommonErrorType.SYSTEM,
          },
        ],
      };
    }
    case TypeKeys.DUPLICATE_CONTACTS_CHECK_FULFILLED: {
      if (action.response.duplicateContacts && action.response.duplicateContacts.length > 0) {
        return {
          ...state!,
          params: {
            type: DialogType.DUPLICATE_CONTACTS_CHECK,
          },
        };
      }
      return state;
    }

    case TypeKeys.SUBMIT_MOBILEID_CONTRACT:
    case TypeKeys.TERMINATE_MOBILEID_CONTRACT: {
      return {
        ...state!,
        submitInProgress: true,
      };
    }

    case TypeKeys.SUBMIT_MOBILEID_CONTRACT_FAILED:
    case TypeKeys.TERMINATE_MOBILEID_CONTRACT_FAILED: {
      return {
        ...state!,
        errors: action.errors,
        submitInProgress: false,
        visible: false,
      };
    }

    default:
      return state;
  }
}
