import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type {
  Address,
  DeliveryAddress,
  GenericError,
  GenericErrorDuplicateContact,
  PostEppMaintenanceRequest,
  PostEppRedeemTerminateRequest,
  PutSupportCaseResponse,
  SimType,
  Subscription,
  SubscriptionAction,
  SubscriptionActionSearchResponse,
  SubscriptionActionsResponse,
  SubscriptionChangeFeaturesRequest,
  SubscriptionChangeUserInformationRequest,
  SubscriptionPbxConfiguration,
  SubscriptionType,
} from '../../generated/api/models.js';
import type { CategoryKey } from '../../common/utils/categoryUtils.js';
import type { CommonError } from '../../common/types/errors.js';
import type { CompositeListSort } from '../../components/CompositeListHeader/CompositeListHeader.js';
import type { CrudAction, DisplayItemsLoadAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type { ItemsQuery } from '../common/store.js';
import type { PurposeOfUseOrContact } from '../../common/types/subscription.js';
import type { TerminateType } from '../../common/enums.js';

export interface TerminateDeviceServiceAction extends Action {
  id: number;
  subscriptionId: string;
  addOnCode: string;
  type: TypeKeys.TERMINATE_SUBSCRIPTION_DEVICE_SERVICE;
}

export interface TerminateDeviceServiceFulfilledAction extends Action {
  type: TypeKeys.TERMINATE_SUBSCRIPTION_DEVICE_SERVICE_FULFILLED;
  subscriptionAction: SubscriptionAction;
}

export type TerminateDeviceServiceFailedAction = ErrorAction<TypeKeys.TERMINATE_SUBSCRIPTION_DEVICE_SERVICE_FAILED>;

export const terminateDeviceSubscription = (
  subscriptionId: string,
  addOnCode: string
): TerminateDeviceServiceAction => ({
  addOnCode: addOnCode,
  id: generateActionId(),
  subscriptionId: subscriptionId,
  type: TypeKeys.TERMINATE_SUBSCRIPTION_DEVICE_SERVICE,
});

export const terminateDeviceSubscriptionFulfilled = (
  item: SubscriptionAction
): TerminateDeviceServiceFulfilledAction => ({
  subscriptionAction: item,
  type: TypeKeys.TERMINATE_SUBSCRIPTION_DEVICE_SERVICE_FULFILLED,
});

export const terminateDeviceSubscriptionServiceFailed: ErrorActionCreator<
  TypeKeys.TERMINATE_SUBSCRIPTION_DEVICE_SERVICE_FAILED
> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): TerminateDeviceServiceFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.TERMINATE_SUBSCRIPTION_DEVICE_SERVICE_FAILED,
  };
};

export interface ChangeSubscriptionBillingAccountAction
  extends CrudAction<TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT> {
  subscription: Subscription;
  billingAccountId: string;
  billingAccountDisplayId: string;
}

export interface ChangeSubscriptionBillingAccountFulfilledAction extends Action {
  billingAccountId: string;
  billingAccountDisplayId: string;
  subscriptionAction: SubscriptionAction;
  type: TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FULFILLED;
}

export type ChangeSubscriptionBillingAccountFailedAction =
  ErrorAction<TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FAILED>;

export const changeSubscriptionBillingAccount = (
  subscription: Subscription,
  billingAccountId: string,
  billingAccountDisplayId: string
): ChangeSubscriptionBillingAccountAction => {
  return {
    billingAccountDisplayId,
    billingAccountId,
    id: generateActionId(),
    subscription,
    type: TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT,
  };
};

export const changeSubscriptionBillingAccountFulfilled = (
  item: SubscriptionAction,
  billingAccountId: string,
  billingAccountDisplayId: string
): ChangeSubscriptionBillingAccountFulfilledAction => ({
  billingAccountDisplayId,
  billingAccountId,
  subscriptionAction: item,
  type: TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FULFILLED,
});

export const changeSubscriptionBillingAccountFailed: ErrorActionCreator<
  TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FAILED
> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ChangeSubscriptionBillingAccountFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FAILED,
  };
};

export interface TerminateSubscriptionAction extends CrudAction<TypeKeys.TERMINATE_SUBSCRIPTION> {
  subscriptionId: string;
  effectiveDate: number;
  donateNumber?: boolean;
  terminateType?: TerminateType;
}

export const terminateSubscriptionAction = (
  subscriptionId: string,
  effectiveDate: number,
  donateNumber?: boolean,
  terminateType?: TerminateType
): TerminateSubscriptionAction => ({
  donateNumber,
  effectiveDate,
  terminateType,
  id: generateActionId(),
  subscriptionId,
  type: TypeKeys.TERMINATE_SUBSCRIPTION,
});

export interface TerminateSubscriptionActionFulfilled {
  type: TypeKeys.TERMINATE_SUBSCRIPTION_FULFILLED;
  subscriptionAction: SubscriptionAction;
}

export const terminateSubscriptionActionFulfilled = (subscriptionAction: SubscriptionAction) => ({
  subscriptionAction,
  type: TypeKeys.TERMINATE_SUBSCRIPTION_FULFILLED,
});

export interface TerminateSubscriptionActionFailed extends ErrorAction<TypeKeys.TERMINATE_SUBSCRIPTION_FAILED> {
  type: TypeKeys.TERMINATE_SUBSCRIPTION_FAILED;
}

export const terminateSubscriptionActionFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): TerminateSubscriptionActionFailed => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.TERMINATE_SUBSCRIPTION_FAILED,
});

export interface ShowSuspendSubscriptionOptions {
  type: TypeKeys.SHOW_SUSPEND_SUBSCRIPTION_OPTIONS;
}

export const showSuspendSubscriptionOptions = (): ShowSuspendSubscriptionOptions => ({
  type: TypeKeys.SHOW_SUSPEND_SUBSCRIPTION_OPTIONS,
});

export interface CloseSuspendSubscriptionOptions {
  type: TypeKeys.CLOSE_SUSPEND_SUBSCRIPTION_OPTIONS;
}

export const closeSuspendSubscriptionOptions = (): CloseSuspendSubscriptionOptions => ({
  type: TypeKeys.CLOSE_SUSPEND_SUBSCRIPTION_OPTIONS,
});

export interface ShowTerminateSubscriptionForm {
  type: TypeKeys.SHOW_TERMINATE_SUBSCRIPTION_FORM;
}

export const showTerminateSubscriptionForm = (): ShowTerminateSubscriptionForm => ({
  type: TypeKeys.SHOW_TERMINATE_SUBSCRIPTION_FORM,
});

export interface CloseTerminateSubscriptionForm {
  type: TypeKeys.CLOSE_TERMINATE_SUBSCRIPTION_FORM;
}

export const closeTerminateSubscriptionForm = (): CloseTerminateSubscriptionForm => ({
  type: TypeKeys.CLOSE_TERMINATE_SUBSCRIPTION_FORM,
});

export interface SetDonorPhoneNumberAction {
  donorNumber: boolean;
  type: TypeKeys.SET_DONOR_PHONE_NUMBER;
}

export const setDonorPhoneNumber = (donorNumber: boolean): SetDonorPhoneNumberAction => ({
  donorNumber,
  type: TypeKeys.SET_DONOR_PHONE_NUMBER,
});

export interface SuspendSubscriptionAction extends CrudAction<TypeKeys.SUSPEND_SUBSCRIPTION> {
  subscriptionId: string;
  subscriptionDisplayId: string;
}

export interface SuspendSubscriptionFulfilledAction {
  type: TypeKeys.SUSPEND_SUBSCRIPTION_FULFILLED;
  subscriptionAction: SubscriptionAction;
}

export interface SuspendSubscriptionFailedAction extends ErrorAction<TypeKeys.SUSPEND_SUBSCRIPTION_FAILED> {
  type: TypeKeys.SUSPEND_SUBSCRIPTION_FAILED;
}

export const suspendSubscription = (
  subscriptionId: string,
  subscriptionDisplayId: string
): SuspendSubscriptionAction => ({
  id: generateActionId(),
  subscriptionId,
  subscriptionDisplayId,
  type: TypeKeys.SUSPEND_SUBSCRIPTION,
});

export const suspendSubscriptionFulfilled = (subscriptionAction: SubscriptionAction) => ({
  subscriptionAction,
  type: TypeKeys.SUSPEND_SUBSCRIPTION_FULFILLED,
});

export const suspendSubscriptionFailed: ErrorActionCreator<TypeKeys.SUSPEND_SUBSCRIPTION_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): SuspendSubscriptionFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.SUSPEND_SUBSCRIPTION_FAILED,
  };
};

export interface ChangeOfferAction extends CrudAction<TypeKeys.CHANGE_OFFER> {
  subscriptionId: string;
  subscriptionDisplayId: string;
  subscriptionType: SubscriptionType;
  commercialProductCode: string;
  addOnsToAdd: string[];
  addOnsToRemove: string[];
  addOnsToUpdate: string[];
  campaignAssociationCode?: string;
}

export interface ChangeOfferFulfilledAction {
  type: TypeKeys.CHANGE_OFFER_FULFILLED;
  subscriptionDisplayId: string;
  subscriptionType: SubscriptionType;
  subscriptionAction: SubscriptionAction;
}

export interface ChangeOfferFailedAction extends ErrorAction<TypeKeys.CHANGE_OFFER_FAILED> {
  type: TypeKeys.CHANGE_OFFER_FAILED;
}

export const changeOffer = (
  subscriptionId: string,
  subscriptionDisplayId: string,
  subscriptionType: SubscriptionType,
  commercialProductCode: string,
  addOnsToAdd: string[],
  addOnsToRemove: string[],
  addOnsToUpdate: string[],
  campaignAssociationCode?: string
): ChangeOfferAction => ({
  id: generateActionId(),
  subscriptionId,
  subscriptionDisplayId,
  subscriptionType,
  commercialProductCode,
  addOnsToAdd,
  addOnsToRemove,
  addOnsToUpdate,
  campaignAssociationCode,
  type: TypeKeys.CHANGE_OFFER,
});

export const changeOfferFulfilled = (
  subscriptionAction: SubscriptionAction,
  subscriptionDisplayId: string,
  subscriptionType: SubscriptionType
) => ({
  subscriptionAction,
  subscriptionDisplayId,
  subscriptionType,
  type: TypeKeys.CHANGE_OFFER_FULFILLED,
});

export const changeOfferFailed: ErrorActionCreator<TypeKeys.CHANGE_OFFER_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ChangeOfferFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.CHANGE_OFFER_FAILED,
  };
};

export interface ResumeSubscriptionAction extends CrudAction<TypeKeys.RESUME_SUBSCRIPTION> {
  subscriptionId: string;
}

export interface ResumeSubscriptionFulfilledAction {
  type: TypeKeys.RESUME_SUBSCRIPTION_FULFILLED;
  subscriptionAction: SubscriptionAction;
}

export interface ResumeSubscriptionFailedAction extends ErrorAction<TypeKeys.RESUME_SUBSCRIPTION_FAILED> {
  type: TypeKeys.RESUME_SUBSCRIPTION_FAILED;
  blockType?: string | undefined;
}

export const resumeSubscription = (subscriptionId: string): ResumeSubscriptionAction => ({
  id: generateActionId(),
  subscriptionId,
  type: TypeKeys.RESUME_SUBSCRIPTION,
});

export const resumeSubscriptionFulfilledAction = (subscriptionAction: SubscriptionAction) => ({
  subscriptionAction,
  type: TypeKeys.RESUME_SUBSCRIPTION_FULFILLED,
});

export enum BlockedErrorTypes {
  COLLECTION_INITIATED = 'COLLECTION_INITIATED',
  CREDIT_INITIATED = 'CREDIT_INITIATED',
}

export enum PbxErrorTypes {
  NUMBER_NOT_AVAILABLE = 'NUMBER_NOT_AVAILABLE',
}

const checkErrorTypes = (errors: CommonError[], genericErrors: GenericError[] | undefined): string => {
  if (errors && errors[0].source && errors[0].source.error && errors[0].source.error.errorType in BlockedErrorTypes) {
    return errors[0].source.error.errorType;
  } else if (genericErrors?.[0].message.includes(BlockedErrorTypes.CREDIT_INITIATED)) {
    return BlockedErrorTypes.CREDIT_INITIATED;
  } else if (genericErrors?.[0].message.includes(BlockedErrorTypes.COLLECTION_INITIATED)) {
    return BlockedErrorTypes.COLLECTION_INITIATED;
  }
  return '';
};

export const resumeSubscriptionFailedAction: ErrorActionCreator<TypeKeys.RESUME_SUBSCRIPTION_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ResumeSubscriptionFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    blockType: checkErrorTypes(commonErrors, errors),
    errors: commonErrors,
    params,
    type: TypeKeys.RESUME_SUBSCRIPTION_FAILED,
  };
};

export interface EditSubscriptionSectionAction extends Action<TypeKeys.EDIT_SUBSCRIPTION_SECTION> {
  editingSection: string;
  type: TypeKeys.EDIT_SUBSCRIPTION_SECTION;
}

export const editSection = (editingSection: string): EditSubscriptionSectionAction => ({
  editingSection,
  type: TypeKeys.EDIT_SUBSCRIPTION_SECTION,
});

export interface ClearDuplicateContactAction extends Action<TypeKeys.CLEAR_DUPLICATE_CONTACT> {
  type: TypeKeys.CLEAR_DUPLICATE_CONTACT;
}

export const clearDuplicateContact = (): ClearDuplicateContactAction => ({
  type: TypeKeys.CLEAR_DUPLICATE_CONTACT,
});

export interface ChangeSubscriptionUserInfoAction extends CrudAction<TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO> {
  subscriptionId: string;
  purposeOfUseOrContact: PurposeOfUseOrContact;
  isCostCenterMandatory: boolean;
  isEmailMandatory: boolean;
  isPhoneNumberMandatory: boolean;
  validationErrors?: CommonError[];
  billingAccountIdForOmaLaiteLaskuRemoval?: string;
}

export type ChangeSubscriptionUserInfoPartial = [
  subscriptionId: string,
  purposeOfUseOrContact: PurposeOfUseOrContact,
  isCostCenterMandatory: boolean,
  isEmailMandatory: boolean,
  isPhoneNumberMandatory: boolean,
  validationErrors?: CommonError[]
];

export type ChangeSubscriptionUserInfoFull = [
  subscriptionId: string,
  purposeOfUseOrContact: PurposeOfUseOrContact,
  isCostCenterMandatory: boolean,
  isEmailMandatory: boolean,
  isPhoneNumberMandatory: boolean,
  validationErrors?: CommonError[],
  billingAccountIdForOmaLaiteLaskuRemoval?: string
];

export const changeSubscriptionUserInfo = (
  ...args: ChangeSubscriptionUserInfoFull
): ChangeSubscriptionUserInfoAction => {
  const [
    subscriptionId,
    purposeOfUseOrContact,
    isCostCenterMandatory,
    isEmailMandatory,
    isPhoneNumberMandatory,
    validationErrors,
    billingAccountIdForOmaLaiteLaskuRemoval,
  ] = args;
  return {
    billingAccountIdForOmaLaiteLaskuRemoval,
    id: generateActionId(),
    purposeOfUseOrContact,
    subscriptionId,
    isCostCenterMandatory,
    isEmailMandatory,
    isPhoneNumberMandatory,
    validationErrors,
    type: TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO,
  };
};

export interface ChangeSubscriptionUserInfoFulfilledAction extends Action {
  parameters: SubscriptionChangeUserInformationRequest;
  subscriptionAction: SubscriptionAction;
  subscriptionId: string;
  type: TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO_FULFILLED;
  billingAccountIdForOmaLaiteLaskuRemoval?: string;
}

export const changeSubscriptionUserInfoFulfilled = (
  parameters: SubscriptionChangeUserInformationRequest,
  subscriptionAction: SubscriptionAction,
  subscriptionId: string,
  billingAccountIdForOmaLaiteLaskuRemoval?: string
): ChangeSubscriptionUserInfoFulfilledAction => ({
  billingAccountIdForOmaLaiteLaskuRemoval,
  parameters,
  subscriptionAction,
  subscriptionId,
  type: TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO_FULFILLED,
});

export type ChangeSubscriptionUserInfoFailedAction = ErrorAction<TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO_FAILED>;

export const changeSubscriptionUserInfoFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ChangeSubscriptionUserInfoFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO_FAILED,
  };
};

export type ChangeSubscriptionUserInfoWithDuplicateFailedAction =
  ErrorAction<TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO_WITH_DUPLICATE_FAILED>;

export const changeSubscriptionUserInfoWithDuplicateFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ChangeSubscriptionUserInfoWithDuplicateFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO_WITH_DUPLICATE_FAILED,
  };
};

export interface SetDuplicateContactAction extends Action<TypeKeys.USE_DUPLICATE_CONTACT> {
  useDuplicateContact: GenericErrorDuplicateContact;
  type: TypeKeys.USE_DUPLICATE_CONTACT;
}

export const setDuplicateContact = (duplicateContact: GenericErrorDuplicateContact): SetDuplicateContactAction => ({
  useDuplicateContact: duplicateContact,
  type: TypeKeys.USE_DUPLICATE_CONTACT,
});

export interface ChangeSimCardAction extends CrudAction<TypeKeys.CHANGE_SIM_CARD> {
  category?: CategoryKey;
  deliveryAddress?: DeliveryAddress;
  simType: SimType;
  simCardNumber?: string;
  subscriptionDisplayId?: string;
  subscriptionId: string;
}
export interface ChangeSimCardFulfilledAction extends Action {
  category?: string;
  isDelivery?: boolean;
  simType: SimType;
  subscriptionAction: SubscriptionAction;
  subscriptionId?: string;
  subscriptionDisplayId?: string;
  type: TypeKeys.CHANGE_SIM_CARD_FULFILLED;
}

export type ChangeSimCardFailedAction = ErrorAction<TypeKeys.CHANGE_SIM_CARD_FAILED>;

export const changeSimCard = (
  subscriptionId: string,
  simType: SimType,
  simCardNumber?: string,
  subscriptionDisplayId?: string,
  category?: CategoryKey,
  deliveryAddress?: DeliveryAddress
): ChangeSimCardAction => ({
  category,
  id: generateActionId(),
  simCardNumber,
  simType,
  subscriptionDisplayId,
  subscriptionId,
  type: TypeKeys.CHANGE_SIM_CARD,
  deliveryAddress,
});

export const changeSimCardFulfilled = (
  simType: SimType,
  subscriptionAction: SubscriptionAction,
  subscriptionId?: string,
  subscriptionDisplayId?: string,
  category?: string,
  isDelivery?: boolean
): ChangeSimCardFulfilledAction => ({
  category,
  simType,
  subscriptionAction,
  subscriptionId,
  subscriptionDisplayId,
  type: TypeKeys.CHANGE_SIM_CARD_FULFILLED,
  isDelivery,
});

export const changeSimCardFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ChangeSimCardFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.CHANGE_SIM_CARD_FAILED,
  };
};

export interface ChangeSubscriptionPbxConfigurationAction
  extends CrudAction<TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION> {
  subscriptionId: string;
  pbxConfiguration: SubscriptionPbxConfiguration;
  validationFn: (pbxConfig: SubscriptionPbxConfiguration) => CommonError[] | undefined;
}

export const changeSubscriptionPbxConfiguration = (
  subscriptionId: string,
  pbxConfiguration: SubscriptionPbxConfiguration,
  validationFn: (pbxConfig: SubscriptionPbxConfiguration) => CommonError[] | undefined
): ChangeSubscriptionPbxConfigurationAction => ({
  id: generateActionId(),
  pbxConfiguration,
  subscriptionId,
  type: TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION,
  validationFn,
});

export interface ChangeSubscriptionPbxConfigurationFulfilledAction extends Action {
  subscriptionAction: SubscriptionAction;
  subscriptionId: string;
  type: TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION_FULFILLED;
}

export const changeSubscriptionPbxConfigurationFulfilled = (
  subscriptionAction: SubscriptionAction,
  subscriptionId: string
): ChangeSubscriptionPbxConfigurationFulfilledAction => ({
  subscriptionAction,
  subscriptionId,
  type: TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION_FULFILLED,
});

export type ChangeSubscriptionPbxConfigurationFailedAction =
  ErrorAction<TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION_FAILED>;

export const changeSubscriptionPbxConfigurationFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ChangeSubscriptionPbxConfigurationFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION_FAILED,
  };
};

// While this is similar to ChangeSubscriptionPbxConfigurationAction, another action is required
// because the validation in the reducer is different.
export interface AttachSubscriptionToPbxAction extends CrudAction<TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX> {
  subscriptionDisplayId: string;
  subscriptionId: string;
  pbxConfiguration: SubscriptionPbxConfiguration;
}

export const attachSubscriptionToPbx = (
  subscriptionDisplayId: string,
  subscriptionId: string,
  pbxConfiguration: SubscriptionPbxConfiguration
): AttachSubscriptionToPbxAction => ({
  id: generateActionId(),
  pbxConfiguration,
  subscriptionDisplayId,
  subscriptionId,
  type: TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX,
});

export type AttachSubscriptionToPbxFailedAction = ErrorAction<
  TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX_FAILED | TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION_FAILED
>;

export const attachSubscriptionToPbxFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): AttachSubscriptionToPbxFailedAction => {
  const type = errors?.[0].message.includes(PbxErrorTypes.NUMBER_NOT_AVAILABLE)
    ? TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION_FAILED
    : TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX_FAILED;

  return {
    errors: convertToCommonErrors(message, status, errors),
    params,
    type,
  };
};

export interface AttachSubscriptionToPbxFulfilledAction extends Action {
  subscriptionAction: SubscriptionAction;
  subscriptionDisplayId: string;
  subscriptionId: string;
  type: TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX_FULFILLED;
}

export const attachSubscriptionToPbxFulfilled = (
  subscriptionAction: SubscriptionAction,
  subscriptionDisplayId: string,
  subscriptionId: string
): AttachSubscriptionToPbxFulfilledAction => ({
  subscriptionAction,
  subscriptionDisplayId,
  subscriptionId,
  type: TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX_FULFILLED,
});

export interface SubscriptionAddOnAttributesMap {
  [addOnCode: string]: { [attributeName: string]: string };
}

export interface ChangeSubscriptionAddonsAction extends CrudAction<TypeKeys.CHANGE_ADDONS> {
  addOnsToAdd: string[];
  addOnsToRemove: string[];
  subscription: Subscription;
  changeContext?: SubscriptionChangeFeaturesRequest.ChangeContextEnum;
  addOnsToUpdate?: string[];
  addOnAttributes?: SubscriptionAddOnAttributesMap;
  type: TypeKeys.CHANGE_ADDONS;
}

export const changeSubscriptionAddons = (
  subscription: Subscription,
  addOnsToAdd: string[],
  addOnsToRemove: string[],
  addOnsToUpdate?: string[],
  addOnAttributes?: SubscriptionAddOnAttributesMap,
  // TODO Sebastian 29.8.2022 -> Found out that below parameter is never used
  changeContext?: SubscriptionChangeFeaturesRequest.ChangeContextEnum
): ChangeSubscriptionAddonsAction => ({
  addOnsToAdd,
  addOnsToRemove,
  addOnsToUpdate,
  addOnAttributes,
  changeContext,
  id: generateActionId(),
  subscription,
  type: TypeKeys.CHANGE_ADDONS,
});

export interface ChangeSubscriptionAddonsFulfilledAction {
  addOnsToAdd: string[];
  addOnsToRemove: string[];
  changeContext?: string;
  subscriptionAction: SubscriptionAction;
  type: TypeKeys.CHANGE_ADDONS_FULFILLED;
}

export const changeSubscriptionAddonsFulfilled = (
  subscriptionAction: SubscriptionAction,
  addOnsToAdd: string[],
  addOnsToRemove: string[],
  changeContext?: string
): ChangeSubscriptionAddonsFulfilledAction => ({
  addOnsToAdd,
  addOnsToRemove,
  changeContext,
  subscriptionAction,
  type: TypeKeys.CHANGE_ADDONS_FULFILLED,
});

export type ChangeSubscriptionAddonsFailedAction = ErrorAction<TypeKeys.CHANGE_ADDONS_FAILED>;

export const changeSubscriptionAddonsFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ChangeSubscriptionAddonsFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.CHANGE_ADDONS_FAILED,
  };
};

export interface InvalidBarringSelectionsAction extends Action {
  errorInfo: string;
  type: TypeKeys.BARRINGS_VALIDATION_FAILED;
}

// This is not reduced anywhere but the error catcher will send error event to GA
// because the action type ends with _FAILED
export const barringsValidationFailed = (errorInfo: string): InvalidBarringSelectionsAction => ({
  errorInfo,
  type: TypeKeys.BARRINGS_VALIDATION_FAILED,
});

// This is not reduced anywhere but the error catcher will send error event to GA
// because the action type ends with _FAILED
export interface InvalidRingAddonSelectionsAction extends Action {
  errorInfo: string;
  type: TypeKeys.RING_ADDONS_VALIDATION_FAILED;
}

export interface PostEppRedeemTerminateAction extends CrudAction<TypeKeys.POST_EPP_REDEEM_TERMINATE> {
  request: PostEppRedeemTerminateRequest;
  subscriptionId: string;
  personBillingAddress?: Address;
  personBillingEmail?: string;
  validationErrors?: CommonError[];
}

export interface PostEppRedeemTerminateFulfilledAction {
  request: PostEppRedeemTerminateRequest;
  response: SubscriptionAction;
  subscriptionId: string;
  type: TypeKeys.POST_EPP_REDEEM_TERMINATE_FULFILLED;
}

export type PostEppRedeemTerminateFailedAction = ErrorAction<TypeKeys.POST_EPP_REDEEM_TERMINATE_FAILED>;

export const postEppRedeemTerminate = (
  request: PostEppRedeemTerminateRequest,
  subscriptionId: string,
  personBillingAddress?: Address,
  personBillingEmail?: string
): PostEppRedeemTerminateAction => ({
  id: generateActionId(),
  personBillingAddress,
  personBillingEmail,
  request,
  subscriptionId,
  type: TypeKeys.POST_EPP_REDEEM_TERMINATE,
});

export const postEppRedeemTerminateFulfilled = (
  request: PostEppRedeemTerminateRequest,
  response: SubscriptionAction,
  subscriptionId: string
): PostEppRedeemTerminateFulfilledAction => ({
  request,
  response,
  subscriptionId,
  type: TypeKeys.POST_EPP_REDEEM_TERMINATE_FULFILLED,
});

export const postEppRedeemTerminateFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): PostEppRedeemTerminateFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.POST_EPP_REDEEM_TERMINATE_FAILED,
});

export interface PostEppMaintenanceAction extends CrudAction<TypeKeys.POST_EPP_MAINTENANCE> {
  postEppMaintenanceRequest: PostEppMaintenanceRequest;
  subscriptionId: string;
  errors?: CommonError[];
}

export interface PostEppMaintenanceFulfilledAction {
  response: PutSupportCaseResponse;
  postEppMaintenanceRequest: PostEppMaintenanceRequest;
  subscriptionId: string;
  type: TypeKeys.POST_EPP_MAINTENANCE_FULFILLED;
}

export type PostEppMaintenanceFailedAction = ErrorAction<TypeKeys.POST_EPP_MAINTENANCE_FAILED>;

export const postEppMaintenance = (
  postEppMaintenanceRequest: PostEppMaintenanceRequest,
  subscriptionId: string,
  errors?: CommonError[]
): PostEppMaintenanceAction => ({
  errors,
  id: generateActionId(),
  postEppMaintenanceRequest,
  subscriptionId,
  type: TypeKeys.POST_EPP_MAINTENANCE,
});

export const postEppMaintenanceFulfilled = (
  postEppMaintenanceRequest: PostEppMaintenanceRequest,
  response: PutSupportCaseResponse,
  subscriptionId: string
): PostEppMaintenanceFulfilledAction => ({
  postEppMaintenanceRequest,
  response,
  subscriptionId,
  type: TypeKeys.POST_EPP_MAINTENANCE_FULFILLED,
});

export const postEppMaintenanceFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): PostEppMaintenanceFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.POST_EPP_MAINTENANCE_FAILED,
});

// OFI-17614
export interface SetSubscriptionDetailForDeviceChangeAction extends Action {
  type: TypeKeys.SET_SUBSCRIPTION_DETAIL_FOR_DEVICE_CHANGE;
  subscriptionId: string;
  subscriptionContactId?: string;
  redirectUrl?: string;
}

export const setSubscriptionDetailForDeviceChange = (
  subscriptionId: string,
  subscriptionContactId?: string,
  redirectUrl?: string
): SetSubscriptionDetailForDeviceChangeAction => ({
  subscriptionId,
  subscriptionContactId,
  redirectUrl,
  type: TypeKeys.SET_SUBSCRIPTION_DETAIL_FOR_DEVICE_CHANGE,
});

export interface ResetSubscriptionDetailForDeviceChangeAction extends Action {
  type: TypeKeys.RESET_SUBSCRIPTION_DETAIL_FOR_DEVICE_CHANGE;
}

export const resetSubscriptionDetailForDeviceChange = (): ResetSubscriptionDetailForDeviceChangeAction => ({
  type: TypeKeys.RESET_SUBSCRIPTION_DETAIL_FOR_DEVICE_CHANGE,
});

export interface LoadSubscriptionActions
  extends DisplayItemsLoadAction<TypeKeys.LOAD_SUBSCRIPTION_ACTIONS, SubscriptionAction> {
  forceLoad?: boolean;
  useSearchService?: boolean;
}

export interface LoadSubscriptionActionsFulfilled {
  subscriptionActionResponse: SubscriptionAction[];
  searchResults: SubscriptionActionSearchResponse[];
  useSearchService?: boolean;
  query: ItemsQuery;
  total: number;
  type: TypeKeys.LOAD_SUBSCRIPTION_ACTIONS_FULFILLED;
}

export type LoadSubscriptionActionsFailed = ErrorAction<TypeKeys.LOAD_SUBSCRIPTION_ACTIONS_FAILED>;

export const loadSubscriptionActions = (options?: {
  displayId?: string;
  sort?: CompositeListSort;
  search?: string;
  forceLoad?: boolean;
  useSearchService?: boolean;
  limit?: number;
  offset?: number;
}): LoadSubscriptionActions => ({
  ...options,
  id: generateActionId(),
  type: TypeKeys.LOAD_SUBSCRIPTION_ACTIONS,
});

export const loadSubscriptionActionsFulfilled = (
  response: SubscriptionActionsResponse,
  query: ItemsQuery,
  useSearchService?: boolean
): LoadSubscriptionActionsFulfilled => ({
  subscriptionActionResponse: response.subscriptionActions,
  searchResults: response.searchResults,
  query,
  useSearchService,
  total: response.total,
  type: TypeKeys.LOAD_SUBSCRIPTION_ACTIONS_FULFILLED,
});

export const loadSubscriptionActionsFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): LoadSubscriptionActionsFailed => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.LOAD_SUBSCRIPTION_ACTIONS_FAILED,
});

export type SubscriptionActionsActionTypes =
  | AttachSubscriptionToPbxAction
  | AttachSubscriptionToPbxFailedAction
  | AttachSubscriptionToPbxFulfilledAction
  | ChangeSubscriptionBillingAccountAction
  | ChangeSubscriptionBillingAccountFailedAction
  | ChangeSubscriptionBillingAccountFulfilledAction
  | InvalidBarringSelectionsAction
  | InvalidRingAddonSelectionsAction
  | TerminateDeviceServiceAction
  | TerminateDeviceServiceFailedAction
  | TerminateDeviceServiceFulfilledAction
  | TerminateSubscriptionAction
  | TerminateSubscriptionActionFulfilled
  | TerminateSubscriptionActionFailed
  | ShowTerminateSubscriptionForm
  | CloseTerminateSubscriptionForm
  | ShowSuspendSubscriptionOptions
  | CloseSuspendSubscriptionOptions
  | SetDonorPhoneNumberAction
  | SuspendSubscriptionAction
  | SuspendSubscriptionFailedAction
  | SuspendSubscriptionFulfilledAction
  | ResumeSubscriptionAction
  | ResumeSubscriptionFailedAction
  | ResumeSubscriptionFulfilledAction
  | ChangeSubscriptionUserInfoAction
  | ChangeSubscriptionUserInfoFailedAction
  | ChangeSubscriptionUserInfoWithDuplicateFailedAction
  | ChangeSubscriptionUserInfoFulfilledAction
  | EditSubscriptionSectionAction
  | ChangeSubscriptionPbxConfigurationAction
  | ChangeSubscriptionPbxConfigurationFailedAction
  | ChangeSubscriptionPbxConfigurationFulfilledAction
  | ChangeSimCardAction
  | ChangeSimCardFulfilledAction
  | ChangeSimCardFailedAction
  | ChangeSubscriptionAddonsAction
  | ChangeSubscriptionAddonsFailedAction
  | ChangeSubscriptionAddonsFulfilledAction
  | PostEppRedeemTerminateAction
  | PostEppRedeemTerminateFailedAction
  | PostEppRedeemTerminateFulfilledAction
  | PostEppMaintenanceAction
  | PostEppMaintenanceFailedAction
  | PostEppMaintenanceFulfilledAction
  | SetSubscriptionDetailForDeviceChangeAction
  | ResetSubscriptionDetailForDeviceChangeAction
  | SetDuplicateContactAction
  | ChangeOfferAction
  | ChangeOfferFulfilledAction
  | ChangeOfferFailedAction
  | LoadSubscriptionActions
  | LoadSubscriptionActionsFailed
  | LoadSubscriptionActionsFulfilled
  | ClearDuplicateContactAction;
