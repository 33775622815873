import * as CL from '@design-system/component-library';
import { TextInput } from '../../react-hook-form/fields/index.js';

export interface ProvidedInputWithTooltipProps {
  labelForTooltip: string;
  label?: string;
  tooltipText: string;
  optionalText?: string;
  required?: boolean;
  placeHolder?: string;
  name: string;
  maxLength?: number;
  disabled?: boolean;
  validate?: (value: string) => string | undefined;
  className?: string;
}

export const TextInputWithTooltip = (props: ProvidedInputWithTooltipProps) => {
  const {
    labelForTooltip,
    tooltipText,
    className,
    placeHolder,
    label,
    maxLength,
    disabled,
    name,
    optionalText,
    required,
    validate,
  } = props;
  return (
    <div className={className}>
      <div className="ds-input--labelarea">
        <label className="ds-input--labelarea-label" htmlFor={name}>
          {labelForTooltip}
        </label>
        <span className="ds-padding-left--2 of-formik__label tooltip">
          <CL.Tooltip
            triggerElement={<CL.Icon color="brand-blue" icon="information" size="s" />}
            placement="top"
            i18n_tooltip_contentText={tooltipText}
            className="ds-margin-left--3"
          />
        </span>
      </div>
      <TextInput
        name={name}
        label={label}
        disabled={disabled}
        required={required ?? false}
        maxLength={maxLength}
        placeholder={placeHolder}
        hint={optionalText}
        validate={validate}
      />
    </div>
  );
};
