import * as CL from '@design-system/component-library';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { initializePayment } from '../../selfservice/actions/index.js';
import { t } from '../../common/i18n/index.js';
import { useDispatch } from 'react-redux';
import type { CommonError } from '../../common/types/errors.js';

import './BankButtonsDialog.scss';

export interface BankButtonsDialogAttrs {
  balance: number;
  errors?: CommonError[];
  invoiceId: string;
  onCloseDialog: () => void;
}

export type BankButtonsDialogProps = BankButtonsDialogAttrs;

export const BankButtonsDialog = ({ onCloseDialog, invoiceId, balance }: BankButtonsDialogProps) => {
  const dispatch = useDispatch();
  return (
    <DialogWrapper closeable header={t.WAIL('Select bank')} onCloseDialog={onCloseDialog} size="l">
      <CL.BankSelectGroup
        banks={[
          [
            'op',
            'nordea',
            'danskebank',
            'handelsbanken',
            'alandsbanken',
            's-pankki',
            'aktia',
            'pop-pankki',
            'saastopankki',
            'omasp',
          ],
        ]}
        className="ds-padding--0 of-bank-buttons-dialog__buttons"
        onBankSelect={bank => {
          const bankId = bank.htmlId;
          const PAYMENT_METHODS: Record<string, string> = {
            aktia: 'AKTIA',
            alandsbanken: 'ALANDSBANKEN',
            danskebank: 'DANSKE_BANK',
            handelsbanken: 'HANDELSBANKEN',
            nordea: 'NORDEA',
            omasp: 'OMA_SP',
            op: 'OSUUSPANKKI',
            /* eslint-disable @typescript-eslint/naming-convention */
            'pop-pankki': 'POP_PANKKI',
            's-pankki': 'S_PANKKI',
            /* eslint-enable @typescript-eslint/naming-convention */
            saastopankki: 'SAASTOPANKKI',
          };

          if (bankId in PAYMENT_METHODS) {
            dispatch(initializePayment(invoiceId, balance, PAYMENT_METHODS[bankId]));
          }
        }}
      />
    </DialogWrapper>
  );
};
