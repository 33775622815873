import { HeaderSectionContent, SectionContent } from '../SectionContent.js';
import { SearchResultRow } from '../SearchResultRow.js';
import {
  costCenterMsg,
  idNumberMsg,
  numberOfTheBillingAccountMsg,
  referenceMsg,
  simCardNumberMsg,
  subscriptionNumberMsg,
  t,
} from '../../../common/i18n/index.js';
import { generatePath } from 'react-router-dom';
import { getResultObjectWithHighlightedValues } from '../highlightFunctions.js';
import { getSubscriptionStatusText } from '../../SubscriptionDetails/subscriptionDetailsCommon.js';
import { isSubscriptionInSfdc } from '../../../common/utils/subscriptionUtils.js';
import { paths } from '../../../common/constants/pathVariables.js';
import type { ClassicSiteResultProps, SearchResultBaseProps } from '../SearchResultRow.js';
import type { SubscriptionHeader } from '../../../generated/api/subscriptionHeader.js';

export interface InternetSubscriptionProps extends SearchResultBaseProps, ClassicSiteResultProps {
  subscription: SubscriptionHeader;
}

export const InternetSubscription = ({
  subscription,
  classicSiteUrl,
  matchedFields,
  searchTerms,
  ...rest
}: InternetSubscriptionProps) => {
  const {
    subscriptionName,
    subscriptionPurposeOfUse,
    subscriptionContactName,
    installationAddress,
    billingAccountDisplayId,
    simCardNumber,
    costCenter,
    subscriptionReference,
    subscriptionDisplayId,
    subscriptionUserFriendlyId,
  } = getResultObjectWithHighlightedValues(subscription, matchedFields, searchTerms);
  const displayClassicSiteLink = !isSubscriptionInSfdc(subscription);
  const classicSiteLinkTarget = `${classicSiteUrl}/muutos-ja-poisto/`;
  return (
    <SearchResultRow
      icon="broadband"
      upperSection={
        <HeaderSectionContent
          left={subscriptionName || ''}
          right={subscriptionContactName || subscriptionPurposeOfUse}
        />
      }
      lowerSection={
        <SectionContent
          left={getSubscriptionStatusText(subscription.subscriptionStatus) || ''}
          right={installationAddress}
        />
      }
      additionalHighlightableFields={[
        { title: t.VLQ3(simCardNumberMsg), value: simCardNumber, key: 'simCardNumber' },
        { title: t.GQLW(numberOfTheBillingAccountMsg), value: billingAccountDisplayId, key: 'billingAccountDisplayId' },
        { title: t.QJUW(costCenterMsg), value: costCenter, key: 'costCenter' },
        { title: t.ZLAU(referenceMsg), value: subscriptionReference, key: 'subscriptionReference' },
        { title: t.YTH3(idNumberMsg), value: subscriptionDisplayId, key: 'subscriptionDisplayId' },
        { title: t.DP44(subscriptionNumberMsg), value: subscriptionUserFriendlyId, key: 'subscriptionUserFriendlyId' },
      ]}
      matchedFields={matchedFields}
      displayClassicSiteLink={displayClassicSiteLink}
      linkHref={
        displayClassicSiteLink
          ? classicSiteLinkTarget
          : generatePath(paths.PS_BROADBAND_SUBSCRIPTION, { subscriptionId: subscription.subscriptionDisplayId })
      }
      {...rest}
    />
  );
};
