import * as CL from '@design-system/component-library';
import { Header } from '../../components/Header/Header.js';
import { HeroHeading, HeroHeadingType } from '../../components/HeroHeading/HeroHeading.js';
import { QuestionComponent } from './QuestionComponent.js';
import { ResultComponent } from './ResultComponent.js';
import { Stepper } from './Stepper.js';
import { backMsg, nextMsg, t } from '../../common/i18n/index.js';
import { pushToDataLayer } from '../../common/analytics.js';
import { useLoaderData } from 'react-router-dom';
import { useRef, useState } from 'react';
import type { SurveyConfiguration, SurveyIndexChangePayload } from './types.js';

import './SurveyWizard.scss';

const handleIndexChangeDataLayerPush = (stepNumber: number, totalSteps: number, surveyAnswer: number) => {
  const payload: SurveyIndexChangePayload = {
    event: 'form_step',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    form_name: 'Tietoturvakysely',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    form_type: 'Marketing Cloud',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    form_category: 'Survey',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    step_number: stepNumber,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    total_steps: totalSteps, // number of all steps in the form
    // eslint-disable-next-line @typescript-eslint/naming-convention
    survey_answer: surveyAnswer,
  };
  pushToDataLayer(payload);
};

export const SurveyWizard = () => {
  const { questions, results, title, marketingCloudUrl } = useLoaderData() as SurveyConfiguration;
  const modalRef = useRef<CL.ModalOperations>(null);
  const [index, setIndex] = useState(0);
  const [responses, setResponses] = useState<number[]>(Array(questions.length).fill(-1));
  const [showResults, setShowResults] = useState(false);
  const baseUrl = marketingCloudUrl;
  const getCurrentItem = (idx: number) => {
    if (idx < 0 || idx > questions.length) {
      return undefined;
    }
    return questions[index];
  };
  const updateIndex = (value: number) => {
    if (value > questions.length - 1) {
      modalRef?.current?.openModal();
      return;
    }
    const newIndex = Math.max(Math.min(value, questions.length - 1), 0);
    setIndex(newIndex);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResponses(responses.map((item, i) => (i === index ? Number(event.target.value) : item)));
  };

  const selectedItem = getCurrentItem(index);

  return (
    <section className="of-survey-wizard">
      <Header />
      <HeroHeading title={title} heroHeadingType={HeroHeadingType.EMPLOYEE_BROADBANDS} fullScreen center />
      <section className="container">
        {showResults ? (
          <div className="result-container">
            <ResultComponent responses={responses} targetBaseUrl={baseUrl} questions={questions} results={results} />
          </div>
        ) : (
          selectedItem && (
            <div>
              <Stepper stepNumber={index} totalSteps={questions.length} />
              <div className="question-container">
                <QuestionComponent
                  key={selectedItem.question}
                  {...selectedItem}
                  onChange={handleOnChange}
                  initialValue={String(responses[index])}
                ></QuestionComponent>
                <CL.Modal
                  ref={modalRef}
                  heading="Olet vastannut kaikkiin kysymyksiin"
                  content="Haluatko siirtyä tuloksiin?"
                  size="s"
                  buttons={[
                    <CL.Button color="link" key="btnCancel" onClick={() => modalRef.current?.closeModal()}>
                      {t.D1E9(backMsg)}
                    </CL.Button>,
                    <CL.Button color="primary" key="btnConfirm" onClick={() => setShowResults(true)}>
                      {t.F0MY(nextMsg)}
                    </CL.Button>,
                  ]}
                ></CL.Modal>
                <div className="navigation-button-container">
                  <CL.Button disabled={index === 0} onClick={() => updateIndex(index - 1)}>
                    {t.D1E9(backMsg)}
                  </CL.Button>
                  <CL.Button
                    disabled={responses[index] < 0}
                    onClick={() => {
                      handleIndexChangeDataLayerPush(index + 1, questions.length, responses[index] + 1);
                      updateIndex(index + 1);
                    }}
                  >
                    {t.F0MY(nextMsg)}
                  </CL.Button>
                </div>
              </div>
            </div>
          )
        )}
      </section>
    </section>
  );
};
