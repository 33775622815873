import { SelfServiceHomeScene } from '../../../../components/SelfServiceHomeScene/SelfServiceHomeScene.js';
import { SelfServiceLogin } from '../../../../components/SelfServiceLoginScene/SelfServiceLogin.js';
import { loadInvoices } from '../../../../selfservice/actions/index.js';
import { useAuth } from '../../AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SelfServiceAuthenticatedPath = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadInvoices('open'));
  }, [dispatch, location.pathname]);

  return <SelfServiceHomeScene />;
};

export const SelfServiceHomePath = () => {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) {
    return <SelfServiceAuthenticatedPath />;
  }
  return <SelfServiceLogin />;
};
