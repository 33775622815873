import type * as CLT from '@design-system/component-library';
import type { BreadCrumbNameAndPath } from '../../BreadCrumbs/BreadCrumbs.js';

export const publicNavigationLinks = (siteBaseUrl: string): CLT.MegaMenuSubMenu[] => [
  {
    title: 'Laitteet',
    subMenu: [
      [
        {
          title: 'Verkkokauppa',
          url: '/kauppa',
        },
        {
          title: 'Tarjoukset',
          url: '/tarjoukset',
        },
        {
          title: 'Tuotemerkit',
          url: '/tuotemerkit',
        },
      ],
      [
        {
          title: 'Puhelimet',
          url: '/puhelimet',
          subMenu: [
            {
              title: 'Android-puhelimet',
              url: '/android-puhelimet',
            },
            {
              title: 'iOS-puhelimet',
              url: '/apple-puhelimet',
            },
            {
              title: 'Muut puhelimet',
              url: '/muut-puhelimet',
            },
            {
              title: 'Lisätarvikkeet puhelimille',
              url: '/lisatarvikkeet-puhelimet',
            },
          ],
        },
      ],
      [
        {
          title: 'Tietokoneet',
          url: '/tietokoneet',
          subMenu: [
            {
              title: 'Kannettavat tietokoneet',
              url: '/kannettavat-tietokoneet',
            },
            {
              title: 'Pöytätietokoneet',
              url: '/poytakoneet',
            },
            {
              title: 'Pelikoneet',
              url: '/pelikoneet',
            },
            {
              title: 'Näytöt',
              url: '/naytot',
            },
            {
              title: 'Hiiret ja näppäimistöt',
              url: '/oheislaitteet',
            },
            {
              title: 'Lisätarvikkeet tietokoneille',
              url: '/lisatarvikkeet-tietokoneet',
            },
          ],
        },
      ],
      [
        {
          title: 'Tabletit ja iPadit',
          url: '/tabletit',
          subMenu: [
            {
              title: 'Android-tabletit',
              url: '/android-tabletit',
            },
            {
              title: 'iPadit',
              url: '/apple-tabletit',
            },
            {
              title: 'Lisätarvikkeet tableteille',
              url: '/lisatarvikkeet-tabletit',
            },
          ],
        },
      ],
      [
        {
          title: 'Verkkolaitteet',
          url: '/verkkolaitteet',
          subMenu: [
            {
              title: 'Reitittimet',
              url: '/mobiilireitittimet-ja-nettitikut',
            },
            {
              title: 'WiFi-laitteet',
              url: '/wifi-laitteet',
            },
            {
              title: 'Antennit ja verkkolaitteiden tarvikkeet',
              url: '/antennit-ja-tarvikkeet',
            },
            {
              title: 'IoT-laitteet',
              url: '/iot-laitteet',
            },
          ],
        },
      ],
      [
        {
          title: 'Muut laitteet',
          url: '/muut-laitteet',
          subMenu: [
            {
              title: 'Kuulokkeet ja kaiuttimet',
              url: '/kuulokkeet-ja-kaiuttimet',
            },
            {
              title: 'Älykellot ja -rannekkeet',
              url: '/alykellot-ja-rannekkeet',
            },
            {
              title: 'Kamerat',
              url: '/kamerat',
            },
            {
              title: 'Televisiot',
              url: '/televisiot',
            },
            {
              title: 'Dronet',
              url: '/dronet',
            },
            {
              title: 'Sähköpotkulaudat',
              url: '/sahkopotkulaudat',
            },
            {
              title: 'Robotti-imurit',
              url: '/robotti-imurit',
            },
            {
              title: 'Kaikki lisätarvikkeet',
              url: '/lisatarvikkeet',
            },
          ],
        },
      ],
    ],
  },
  {
    title: 'Liittymät',
    subMenu: [
      [
        {
          title: 'Kaikki liittymät',
          url: '/liittymat',
        },
        {
          title: '5G yrityksille',
          url: 'https://elisa.fi/5g/5g-yrityksille/',
        },
      ],
      [
        {
          title: 'Puhelinliittymät',
          url: '/yritysliittymat',
          subMenu: [
            {
              title: 'Työsuhdeliittymän lisäpalvelut',
              url: '/oma-lasku-tyosuhdeliittymaan',
            },
            {
              title: 'Wifi- ja VoLTE -palvelut',
              url: '/wifi-puhelu',
            },
            {
              title: 'Turbonappi',
              url: '/turbonappi',
            },
          ],
        },
      ],
      [
        {
          title: 'Nettiliittymät',
          url: '/nettiliittymat',
          subMenu: [
            {
              title: 'Liikkuvaan työhön – Mobiililaajakaista',
              url: '/mobiililaajakaista',
            },
            {
              title: 'Etätöihin ja pieneen toimistoon',
              url: '/netti-pieneen-toimistoon',
            },
            {
              title: 'Toimipaikoille ja suureen toimistoon',
              url: '/netti-isolle-yritykselle',
            },
            {
              title: 'Laitenetti',
              url: '/laitenetti',
            },
            {
              title: 'IoT-liittymä',
              url: '/m2m-liittymat',
            },
          ],
        },
      ],
      [
        {
          title: 'Liittymien ohjeet',
          url: '/ohjeet/puhelinliittymat',
          subMenu: [
            {
              title: 'Roaming-hinnat',
              url: '/verkkovierailu-ulkomailla',
            },
            {
              title: 'Tarkista PUK-koodi',
              url: '/ohje/pin-ja-puk-koodit',
            },
            {
              title: 'SIM-kortin tilaus ja aktivointi',
              url: '/ohje/sim-kortin-tilaus-ja-aktivointi',
            },
          ],
        },
      ],
      [
        {
          title: 'Vaihderatkaisut',
          url: '/asiakaspalveluratkaisut',
          subMenu: [
            {
              title: 'Elisa Puheratkaisu Vakio',
              url: '/puheratkaisu-vakio',
            },
            {
              title: 'Elisa Ring',
              url: '/ring-mobiilivaihde',
            },
          ],
        },
      ],
    ],
  },
  {
    title: 'Palvelut',
    subMenu: [
      [
        {
          title: 'Yrittäjille',
          url: '/yrittajille',
        },
        {
          title: 'PK-yrityksille',
          url: '/ict-pk-yrityksille',
        },
        {
          title: 'Isoille yrityksille',
          url: '/ict-suurille-yrityksille',
        },
        {
          title: 'Kaikki palvelut',
          url: '/palvelut',
        },
      ],
      [
        {
          title: 'Verkko- ja tietoturvapalvelut',
          url: '/tietoverkot',
          subMenu: [
            {
              title: 'Yritysverkot',
              url: '/yritysverkko',
            },
            {
              title: 'Privaattiverkot',
              url: '/privaattiverkko',
            },
            {
              title: 'Lähiverkot',
              url: '/lahiverkot',
            },
            {
              title: 'Verkon ja laitteiden tietoturva',
              url: '/tietoturva',
            },
            {
              title: 'NOC palveluna',
              url: '/noc',
            },
            {
              title: 'Kyberturva',
              url: '/kyberturvapalvelut',
            },
            {
              title: 'IoT - Esineiden internet',
              url: '/iot',
            },
          ],
        },
      ],
      [
        {
          title: 'IT-palvelut',
          url: '/it-palvelut',
          subMenu: [
            {
              title: 'Pilvipohjaiset työkalut',
              url: '/pilvityokalut',
            },
            {
              title: 'Pilvipalvelut',
              url: '/pilvipalvelut',
            },
            {
              title: 'Hybridipilvi- ja kapasiteettipalvelut',
              url: '/hybridipilvi-ja-kapasiteettipalvelut',
            },
            {
              title: 'Päätelaite- ja loppukäyttäjäpalvelut',
              url: '/paatelaite-ja-loppukayttajapalvelut',
            },
          ],
        },
      ],
      [
        {
          title: 'Asiakaskokemuksen kehittämispalvelut',
          url: '/asiakaskokemus',
          subMenu: [
            {
              title: 'Asiakaspalveluratkaisut',
              url: '/asiakaspalveluratkaisut',
            },
            {
              title: 'Asioinnin kehittäminen',
              url: '/asioinnin-kehittaminen',
            },
            {
              title: 'Liiketoimintaratkaisut',
              url: '/liiketoimintaratkaisut',
            },
            {
              title: 'Tekoälypalvelut',
              url: '/tekoaly',
            },
          ],
        },
      ],
      [
        {
          title: 'Asiantuntijapalvelut',
          url: '/asiantuntijapalvelut',
          subMenu: [
            {
              title: 'Koulutukset',
              url: '/koulutukset',
            },
            {
              title: 'Työpajat',
              url: '/tyopajat',
            },
          ],
        },
        {
          title: 'Sote-alan ratkaisut',
          url: '/sote',
          subMenu: [
            {
              title: 'Terveydenhuollon ratkaisut',
              url: '/terveydenhuolto',
            },
          ],
        },
      ],
      [
        {
          title: 'Suosittelemme',
          url: '/suosittelemme',
          subMenu: [
            {
              title: 'Digitaalinen työpaikka',
              url: '/digitaalinen-tyopaikka',
            },
            {
              title: 'Microsoft 365',
              url: '/microsoft-365',
            },
            {
              title: 'Elisa Yritystietoturva',
              url: '/yritystietoturva',
            },
            {
              title: 'Elisa Ring',
              url: '/ring-mobiilivaihde',
            },
            {
              title: 'Elisa API',
              url: '/elisa-api',
            },
            {
              title: 'Suomen Yrittäjien jäsenedut',
              url: '/yrittajajasenen-edut',
            },
            {
              title: 'Etätyön ratkaisut',
              url: '/etatyo',
            },
            {
              title: 'Ajankohtaista tietoa yrityksille',
              url: '/ajankohtaista',
            },
          ],
        },
      ],
    ],
  },
  {
    title: 'Ideat',
    subMenu: [
      [
        {
          title: 'Tilaa uutiskirje',
          url: `/tilaa-uutiskirje`,
        },
        {
          title: 'Tuote-esittelyt',
          url: `${siteBaseUrl}/ideat/kategoriat/tuote-esittely/`,
        },
      ],
      [
        {
          title: 'Kaikki artikkelit ja vinkit',
          url: `${siteBaseUrl}/ideat/`,
        },
        {
          title: 'Työergononomia',
          url: '/ergonomia',
        },
      ],
      [
        {
          title: 'Asiakascaset',
          url: `${siteBaseUrl}/ideat/kategoriat/caset/`,
        },
        {
          title: 'Webinaarit',
          url: '/webinaarit',
        },
      ],
      [
        {
          title: 'Blogit',
          url: `${siteBaseUrl}/ideat/kategoriat/blogit/`,
        },
      ],
      [
        {
          title: 'Showroom',
          url: '/showroom',
        },
      ],
      [
        {
          title: 'Tapahtumat',
          url: `${siteBaseUrl}/ideat/kategoriat/tapahtumat/`,
        },
      ],
    ],
  },
  {
    title: 'Asiakaspalvelu',
    subMenu: [
      [
        {
          title: 'Tiedotteet',
          url: 'https://elisa.fi/yhtiotieto/uutishuone/',
        },
        {
          title: 'Vikatilanteet',
          url: 'https://elisa.fi/verkkoapuri/',
        },
        {
          title: 'Huoltotyöt',
          url: 'https://elisa.fi/muutostiedotteet',
        },
      ],
      [
        {
          title: 'Kaikki Ohjeet',
          url: '/ohjeet',
          subMenu: [
            {
              title: 'Laskutuksen ohjeet',
              url: '/ohjeet/laskut',
            },
            {
              title: 'PUK-koodi',
              url: '/ohje/pin-ja-puk-koodit',
            },
            {
              title: 'Elisa Ring käyttö',
              url: '/ohjeet/ring',
            },
            {
              title: 'Liittymän käyttö ulkomailla',
              url: '/ohjeet/puhelin-ulkomailla',
            },
            {
              title: 'Laitteiden huolto ja takuu',
              url: '/ohjeet/huolto-ja-takuu',
            },
            {
              title: 'Verkkokaupan tilaus- ja palautusohjeet',
              url: '/ohjeet/tilaus-ja-toimitus',
            },
          ],
        },
      ],
      [
        {
          title: 'Kirjaudu palveluihin',
          url: '/yritys-palvelut',
          subMenu: [
            {
              title: 'Yritysten OmaElisa',
              url: `${siteBaseUrl}/omaelisa`,
            },
            {
              title: 'Työntekijän OmaElisa',
              url: `${siteBaseUrl}/työntekijä`,
            },
          ],
        },
      ],
      [
        {
          title: 'Yhteystiedot yrityksille',
          url: '/yhteystiedot',
        },
      ],
    ],
  },
];

// Root level can't have 'page' current, as root doesn't have a URL.
type RootLevelCurrent = undefined | 'true';
type Current = RootLevelCurrent | 'page';
const CURRENT_TRUE: RootLevelCurrent = 'true';
const CURRENT_PAGE: Current = 'page';

type BreadCrumbMatchesAndCurrent = {
  // How many breadcrumb links are contained in the menuitem and its children.
  breadCrumbMatches: number;
  current?: Current;
};

type SubMenuAndCurrent = {
  subMenu: CLT.NestedSubMenu[];
} & BreadCrumbMatchesAndCurrent;

type RootSubMenuAndCurrent = {
  subMenu?: CLT.NestedSubMenu[][];
} & BreadCrumbMatchesAndCurrent;

const getCurrentLevelValue = (breadCrumbMatchesAndCurrent: BreadCrumbMatchesAndCurrent) => {
  const val = breadCrumbMatchesAndCurrent.breadCrumbMatches || 0;
  // Favors current over breadcrumb, that's why we have hundreds here
  switch (breadCrumbMatchesAndCurrent.current) {
    case CURRENT_PAGE:
      return val + 200;
    case CURRENT_TRUE:
      return val + 100;
  }
  return val;
};

const highestCurrentLevel = (current1: Current, current2: Current): Current => {
  if ([current1, current2].includes(CURRENT_PAGE)) {
    return CURRENT_PAGE;
  }
  return current1 || current2;
};

// Recursively handles submenus, adding the current property to menuitems where
// needed. Returns modified subMenu, as well as information on what 'current
// level' was found in the subMenu and its descendants.
const subMenuWithCurrentProperty = (
  linkStructure: CLT.NestedSubMenu[],
  breadCrumbLinks: BreadCrumbNameAndPath[],
  currentPath: string
): SubMenuAndCurrent => {
  let someContainCurrent: Current;
  let breadCrumbMatches = 0;
  const structure = linkStructure.map((menuItem: CLT.NestedSubMenu) => {
    const { subMenu, ...rest } = menuItem;
    let current: Current;
    if (menuItem.url === currentPath) {
      current = CURRENT_PAGE;
      someContainCurrent = CURRENT_PAGE;
    }

    breadCrumbLinks.forEach(breadCrumbLink => {
      if (breadCrumbLink.path === menuItem.url) {
        breadCrumbMatches += 1;
      }
    });

    if (subMenu) {
      const subMenuData = subMenuWithCurrentProperty(subMenu, breadCrumbLinks, currentPath);
      // some menuitem in submenu matches current path
      if (subMenuData.current) {
        current = current || CURRENT_TRUE;
        someContainCurrent = highestCurrentLevel(someContainCurrent, subMenuData.current);
      }
      breadCrumbMatches += subMenuData.breadCrumbMatches;
      return {
        ...rest,
        ...(current && { current }),
        subMenu: subMenuData.subMenu,
      };
    }
    return {
      ...rest,
      ...(current && { current }),
    };
  });

  return {
    breadCrumbMatches,
    current: someContainCurrent,
    subMenu: structure,
  };
};

// This handles the top level items separately from the rest, as we want to try
// our best to just have one current item on the top level, unlike on the lower
// levels.
const rootSubMenuWithCurrentProperty = (
  linkStructure: CLT.NestedSubMenu[][],
  breadCrumbLinks: BreadCrumbNameAndPath[],
  currentPath: string
): RootSubMenuAndCurrent =>
  linkStructure.reduce(
    (acc, menuItem) => {
      const subArrayData = subMenuWithCurrentProperty(menuItem, breadCrumbLinks, currentPath);
      return {
        breadCrumbMatches: acc.breadCrumbMatches + subArrayData.breadCrumbMatches,
        current: highestCurrentLevel(acc.current, subArrayData.current),
        subMenu: [...acc.subMenu, subArrayData.subMenu],
      };
    },
    { breadCrumbMatches: 0, current: undefined, subMenu: [] }
  );

// We have cases like /ring-mobiilivaihde which is listed under
// Liittymät and under Palvelut. We only want to highlight Palvelut
// as it contains more of the breadcrumb links.
const mapTopLevelCurrents = (subMenuCurrents: BreadCrumbMatchesAndCurrent[]): RootLevelCurrent[] => {
  const highestTopLevelCurrentValue = subMenuCurrents.reduce((a, c) => Math.max(a, getCurrentLevelValue(c)), 0);
  // If the highest value is zero, we don't have any idea which item we should make current.
  if (!highestTopLevelCurrentValue) {
    return subMenuCurrents.map(_c => undefined);
  }
  return subMenuCurrents.map(current =>
    getCurrentLevelValue(current) < highestTopLevelCurrentValue ? undefined : current ? CURRENT_TRUE : undefined
  );
};

// This adds the 'current' property where applicable. It is used by the CL
// Header component for aria-current. We do some fuzzy stuff to minimize cases
// where we have no current or where we have multiple current:s at the top
// level, as that would be confusing.
export const publicNavigationLinksWithCurrentProperty = (
  linkStructure: CLT.MegaMenuSubMenu[],
  breadCrumbLinks: BreadCrumbNameAndPath[],
  currentPath: string
): CLT.MegaMenuSubMenu[] => {
  const subMenuData = linkStructure.map((menuItem: CLT.MegaMenuSubMenu) =>
    menuItem.subMenu
      ? rootSubMenuWithCurrentProperty(menuItem.subMenu, breadCrumbLinks, currentPath)
      : { breadCrumbMatches: 0 }
  );

  const topLevelCurrents = mapTopLevelCurrents(subMenuData);

  return linkStructure.map((menuItem, idx) => {
    return {
      ...menuItem,
      ...(topLevelCurrents[idx] && { current: topLevelCurrents[idx] }),
      ...(subMenuData[idx].subMenu && { subMenu: subMenuData[idx].subMenu }),
    };
  });
};
