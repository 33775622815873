import * as CL from '@design-system/component-library';
import { FormProvider, useForm } from 'react-hook-form';
import { PhoneNumber } from '../../common/react-hook-form/fields/index.js';
import { loginMsg, t } from '../../common/i18n/index.js';

import './MobiilivarmenneForm.scss';

interface MobiilivarmenneFormProps {
  backToBankSelection: (event: React.SyntheticEvent) => void;
  onSubmit: (phoneNumber: string) => void;
  showHeader?: boolean;
}

interface MobiilivarmenneFormFields {
  phoneNumber: string;
}

export const MobiilivarmenneForm = ({ onSubmit, backToBankSelection, showHeader = true }: MobiilivarmenneFormProps) => {
  const methods = useForm<MobiilivarmenneFormFields>();

  return (
    <CL.Grid className="of-mobile-verification-form">
      {showHeader && (
        <CL.GridRow>
          <CL.GridCol colWidth={12} className="ds-no-gutter">
            <h3 className="ds-margin-top--0 ds-margin-left--0 ds-margin-right--0 ds-margin-bottom-5">
              {t.JVCB('Login using Mobile ID')}
            </h3>
          </CL.GridCol>
        </CL.GridRow>
      )}
      <CL.GridRow>
        <CL.GridCol colWidth={12} className="ds-no-gutter">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(data => onSubmit(data.phoneNumber))} noValidate>
              <PhoneNumber />
              <CL.Button type="submit" size="l">
                {t.Z16I(loginMsg)}
              </CL.Button>
            </form>
          </FormProvider>
        </CL.GridCol>
      </CL.GridRow>
      <CL.GridRow>
        <CL.GridCol colWidth={12} className="ds-no-gutter">
          <p className="ds-text--xs ds-text-align--center ds-color--neutral-600">{t.FT2U('Or authenticate with')}</p>
          <CL.Button onClick={backToBankSelection} size="l" color="transparent">
            {t.X2X6('Bank credentials')}
          </CL.Button>
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};
