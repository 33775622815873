import * as CL from '@design-system/component-library';
import { AAAAValueField } from '../Records/AAAAValueField.js';
import { AValueField } from '../Records/AValueField.js';
import { CNAMEValueField } from '../Records/CNAMEValueField.js';
import { MXValueField } from '../Records/MXValueField.js';
import { NSValueField } from '../Records/NSValueField.js';
import { SRVValueField } from '../Records/SRVValueField.js';
import { TXTValueField } from '../Records/TXTValueField.js';
import type { DnsRecordEditableType } from '../../../../../generated/api/dnsRecordEditableType.js';

const getEditableComponent = (type: DnsRecordEditableType, index: number) => {
  switch (type) {
    case 'A':
      return <AValueField index={index} />;
    case 'AAAA':
      return <AAAAValueField index={index} />;
    case 'CNAME':
      return <CNAMEValueField index={index} />;
    case 'MX':
      return <MXValueField index={index} />;
    case 'NS':
      return <NSValueField index={index} />;
    case 'SRV':
      return <SRVValueField index={index} />;
    case 'TXT':
      return <TXTValueField index={index} />;
  }
};

export const DnsRecordEdit = ({
  type,
  fields,
  onRemoveValueRow,
}: {
  type: DnsRecordEditableType;
  fields: Record<'id', string>[];
  onRemoveValueRow: (index: number) => void;
}) => (
  <CL.Grid className="of-dns-record-list-row-edit__value">
    {fields.map(({ id }, index) => (
      <CL.GridRow key={id} className="ds-position--relative">
        {getEditableComponent(type, index)}
        {!!index && (
          <div key={id} className="of-dns-record-list-row-edit__remove">
            <CL.Button
              color="transparent"
              className="ds-border--style-none ds-margin-top--2 ds-margin-bottom--5"
              size="s"
              onClick={() => onRemoveValueRow(index)}
            >
              <CL.Icon icon="delete" color="brand-blue" />
            </CL.Button>
          </div>
        )}
      </CL.GridRow>
    ))}
  </CL.Grid>
);
