import * as CL from '@design-system/component-library';
import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { DeliveryAddress, DeliveryAddressType, getErrorsFromAddress } from '../DeliveryAddress/DeliveryAddress.js';
import { POBoxWarningModal } from '../Modal/POBoxWarningModal.js';
import { SimType, SubscriptionType } from '../../generated/api/models.js';
import { changeSimCard } from '../../selfservice/actions/subscriptionActionsActions.js';
import { deliveredMsg, orderMsg, priceMsg, simCardSizeMsg, t, vatMsg } from '../../common/i18n/index.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { isPOBoxAddress } from '../../common/utils/validationUtils.js';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import type { Address, DeliveryAddress as DeliveryAddressInterface, Subscription } from '../../generated/api/models.js';
import type { AddressWithType } from '../DeliveryAddress/DeliveryAddress.js';
import type { AuthenticatedUserState, CompanyInfoState } from '../../common/types/states.js';
import type { CategoryKey } from '../../common/utils/categoryUtils.js';
import type { CommonError } from '../../common/types/errors.js';
import type { Dispatch } from 'react';

import './OrderPhysicalSim.scss';

interface SubscriptionTypeSpecificContentProps {
  isLaitenetti: boolean;
  simType: SimType;
  setSimType: Dispatch<SimType>;
}

const SubscriptionTypeSpecificContent = ({
  isLaitenetti,
  simType,
  setSimType,
}: SubscriptionTypeSpecificContentProps) => {
  return (
    <>
      {isLaitenetti && (
        <CL.Radio.Group
          value={simType}
          label={t.A7CF(simCardSizeMsg)}
          i18n_radiogroup_infoText={t.A7CL(
            'Please check which SIM-card type fits into your device. Available choices are a combo card Mini (2FF) + Micro (3FF) or a Nano (4FF) card.'
          )}
        >
          <CL.Radio
            id="redeem-type-company"
            label="Mini + Micro"
            onChange={() => setSimType(SimType.PHYSICAL_MINI_MICRO)}
            value={SimType.PHYSICAL_MINI_MICRO}
          />
          <CL.Radio
            id="redeem-type-employee"
            label="Nano"
            onChange={() => setSimType(SimType.PHYSICAL_NANO)}
            value={SimType.PHYSICAL_NANO}
          />
        </CL.Radio.Group>
      )}

      <p>
        {t.IDBW(
          'SIM card will be delivered as letter mail. You can activate the SIM card here in OmaElisa as soon as you receive it. The current SIM card stops working once you activate the new SIM card.'
        )}
      </p>
      {!isLaitenetti && (
        <p>
          {t.CLD7(
            'Combined SIM card includes three SIM card sizes: Mini, Macro and Nano. The default PIN code for SIM cards is 1234. However, remember to change it when you activate the subscription.'
          )}
        </p>
      )}
    </>
  );
};

export interface OrderPhysicalSimProps {
  category: CategoryKey;
  disallowBillableSimChanges: boolean;
  subscription: Subscription;
  onCancel: () => void;
  companyInfo?: CompanyInfoState | null;
  user?: AuthenticatedUserState | null;
  simChangeServiceFee?: number;
  isEmployee?: boolean;
}

export const OrderPhysicalSim = (props: OrderPhysicalSimProps) => {
  const { subscription, category, onCancel, companyInfo, user, simChangeServiceFee, isEmployee } = props;

  const [errors, setErrors] = useState<CommonError[]>();
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddressInterface>({
    ...{ address: companyInfo?.address || ({} as Address) },
    recipient: user?.userName || '',
    phoneNumber: user?.mobile || '',
    companyName: user?.companyName || '',
  });
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  const [addressType, setAddressType] = useState(DeliveryAddressType.COMPANY_ADDRESS);
  const [showPOBoxWarningModal, setShowPOBoxWarningModal] = useState(false);

  const isLaitenetti = subscription.subscriptionType === SubscriptionType.MOBILE_M2M;
  const [simType, setSimType] = useState<SimType>(isLaitenetti ? SimType.PHYSICAL_MINI_MICRO : SimType.PHYSICAL);

  const updateEditMode = (isEditing: boolean) => {
    if (!errors) {
      setIsConfirmDisabled(isEditing);
    }
  };

  const saveAddressToState = (
    address: Address,
    recipient: string,
    phoneNumber: string,
    companyName: string,
    addrType: DeliveryAddressType
  ) => {
    setDeliveryAddress({ ...{ address }, recipient, phoneNumber, companyName });
    setErrors(getErrorsFromAddress(address, recipient, phoneNumber, companyName));
    setAddressType(addrType);
  };

  const dispatch = useDispatch();

  const onSubmit = () => {
    if (isPOBoxAddress(deliveryAddress.address.postalCode)) {
      setShowPOBoxWarningModal(true);
    } else {
      dispatch(
        changeSimCard(
          subscription.subscriptionId,
          simType,
          subscription.details?.mobile?.simCardNumber,
          subscription.subscriptionDisplayId,
          category,
          deliveryAddress
        )
      );
    }
  };

  return (
    <div className="of-order-physical-sim">
      {showPOBoxWarningModal && <POBoxWarningModal setShowModal={setShowPOBoxWarningModal} />}
      <div className="of-order-physical-sim__info">
        <SubscriptionTypeSpecificContent {...{ isLaitenetti, setSimType, simType }} />
        {!isEmployee && (
          <p>
            <strong>{t.V72N(priceMsg)}:</strong>{' '}
            {t.W1D4('The ordering of blank SIM card is free. The charge for SIM card activation is')}{' '}
            <strong>
              {formatSum(simChangeServiceFee)} ({t.UZ6X(vatMsg)} 0%).
            </strong>
          </p>
        )}
        <p>
          <strong>{t.IHO6(deliveredMsg)}:</strong>{' '}
        </p>
        <DeliveryAddress
          address={deliveryAddress.address}
          recipientName={`${user?.firstName ? user?.firstName : ''} ${user?.lastName ? user?.lastName : ''}`}
          recipientPhoneNumber={user?.mobile}
          companyName={companyInfo ? companyInfo.companyName : undefined}
          errors={errors}
          onUpdateEditMode={value => updateEditMode(value)}
          deliveryAddressType={addressType}
          submit={(values: AddressWithType, addrType: DeliveryAddressType) => {
            saveAddressToState(
              {
                countryCode: values.countryCode,
                line1: values.line1,
                line2: values.line2,
                postOffice: values.postOffice,
                postalCode: values.postalCode,
              },
              values.recipientName,
              values.recipientPhoneNumber,
              values.companyName,
              addrType
            );
          }}
        />
        <p>{t.GMKG('Orders placed before 12 noon on weekdays generally have time for next weekday delivery.')}</p>
      </div>
      <ButtonGroupForSubmitAndBack
        className="of-order-physical-sim__actions"
        onCancel={onCancel}
        submitButtonText={t.AQOL(orderMsg)}
        submitDisabled={isConfirmDisabled}
        onSubmit={onSubmit}
      />
    </div>
  );
};
