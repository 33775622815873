import { DnsDomainList } from './DnsDomainList/DnsDomainList.js';
import { useAuth } from '../../../public/site/AuthProvider.js';
import { useLoaderData } from 'react-router-dom';
import type { SubscriptionsResponse } from '../../../generated/api/subscriptionsResponse.js';

export const DnsDomainManagement = () => {
  const { searchResults, total } = useLoaderData() as SubscriptionsResponse;
  const { authenticatedUser } = useAuth();

  return <DnsDomainList searchResults={searchResults} total={total} authenticatedUser={authenticatedUser} />;
};
