import * as CL from '@design-system/component-library';
import * as React from 'react';
import * as ReactDayPickerAll from 'react-day-picker';
import { firstDayOfWeek, months, nextPrevMonthLabels, weekDays, weekDaysShort } from '../../common/i18n/index.js';
import { onEnterOrSpaceKeyPress } from '../../common/utils/handlerUtils.js';
import type { DayModifiers, NavbarElementProps, DayPickerProps as ReactDayPickerProps } from 'react-day-picker';

import './DayPicker.scss';

const ReactDayPicker = ReactDayPickerAll.default as any; // eslint-disable-line @typescript-eslint/no-explicit-any -- Need to do this because of how `react-day-picker` exports. Long term goal is to get rid of `react-day-picker`.

const DayPickerNavBar = ({
  className,
  labels,
  onNextClick = () => {},
  onPreviousClick = () => {},
}: Partial<NavbarElementProps>) => (
  <div className={`of-day-picker__navbar ${className || ''}`}>
    <span
      aria-label={labels?.previousMonth}
      className="of-day-picker__navbar-prev"
      onClick={() => onPreviousClick()}
      onKeyPress={(event: React.KeyboardEvent) => onEnterOrSpaceKeyPress(event, onPreviousClick)}
      role="button"
      tabIndex={0}
    >
      <CL.Icon color="brand-blue" icon="arrow-left" size="l" type="filled" />
    </span>
    <span
      aria-label={labels?.nextMonth}
      className="of-day-picker__navbar-next"
      onClick={() => onNextClick()}
      onKeyPress={(event: React.KeyboardEvent) => onEnterOrSpaceKeyPress(event, onNextClick)}
      role="button"
      tabIndex={0}
    >
      <CL.Icon color="brand-blue" icon="arrow-right" size="l" type="filled" />
    </span>
  </div>
);

export interface DayPickerProps extends Partial<ReactDayPickerProps> {
  selectedDay?: Date;
  onSelectDay: (day: Date) => void;
}

export const DayPicker = (props: DayPickerProps) => (
  <div className="of-day-picker ds-padding-top--2">
    <ReactDayPicker
      months={months()}
      navbarElement={<DayPickerNavBar />}
      weekdaysLong={weekDays()}
      weekdaysShort={weekDaysShort()}
      firstDayOfWeek={firstDayOfWeek()}
      labels={nextPrevMonthLabels()}
      onDayClick={(date: Date, modifiers: DayModifiers) => {
        if (!modifiers.disabled) {
          props.onSelectDay(date);
        }
      }}
      selectedDays={props.selectedDay && [props.selectedDay]}
      {...props}
    />
  </div>
);
