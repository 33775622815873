import { BreadCrumbs } from '../../../../../../components/BreadCrumbs/index.js';
import { Loading } from '../../../../../../components/Loading/index.js';
import { SimActivation } from '../../../../../../components/SimActivation/SimActivation.js';
import { SubscriptionAction } from '../../../../../../generated/api/models.js';
import {
  activateSimCardMsg,
  omaElisaForEmployeeMsg,
  t,
  voiceSubscriptionsMsg,
} from '../../../../../../common/i18n/index.js';
import { deepEqual } from '../../../../../../common/utils/objectUtils.js';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getSubCategoryFromPath } from '../../../../../../common/utils/employeeUtils.js';
import { getSubscriptionStatus } from '../../../../../../common/utils/subscriptionUtils.js';
import { parseMobileSubscriptionAttrs } from '../../../../../../components/EmployeeSubscriptionDetails/employeeSubscriptionUtils.js';
import { paths } from '../../../../../../common/constants/pathVariables.js';
import { useSelector } from 'react-redux';
import { useTitle } from '../../../../../../common/hooks/useTitle.js';
import type { State } from '../../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../../common/constants/pathInterfaces.js';

export const VoiceSubActivateSimPath = ({ isEmployee }: { isEmployee: boolean }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const subCategoryFromPath = getSubCategoryFromPath(pathname);
  const { subscriptionId = '' } = useParams<SubscriptionId>();
  const subscription = useSelector((state: State) =>
    state?.user?.authenticated?.subscriptions?.voice?.find(sub => sub.subscriptionId === subscriptionId)
  );
  const { selfService, companyInfo } = useSelector(
    (state: State) => ({
      selfService: state?.selfservice,
      companyInfo: state?.selfservice?.companyInfo,
    }),
    deepEqual
  );
  const mobileNumber: string = subscription ? parseMobileSubscriptionAttrs(subscription).mobileNumber : '';

  const breadCrumbs: JSX.Element = (
    <BreadCrumbs
      breadCrumbPaths={[
        { name: t.H6JV(omaElisaForEmployeeMsg), path: paths.EMPLOYEE_HOME },
        { name: t.AR0B(voiceSubscriptionsMsg), path: paths.EMPLOYEE_VOICE_SUBSCRIPTIONS },
        { name: mobileNumber, path: generatePath(paths.EMPLOYEE_VOICE_SUBSCRIPTION, { subscriptionId }) },
        { name: t.M1E7(activateSimCardMsg) },
      ]}
    />
  );

  const subscriptionStatus = getSubscriptionStatus(subscription, selfService?.pendingSubscriptionActions?.items || []);
  const pendingSimChanges =
    subscriptionStatus.pendingActionType === SubscriptionAction.SubscriptionActionTypeEnum.CHANGE_SIM;

  useTitle([
    t.H6JV(omaElisaForEmployeeMsg),
    t.AR0B(voiceSubscriptionsMsg),
    subscription?.subscriptionDisplayId || subscription?.subscriptionId || '',
    t.M1E7(activateSimCardMsg),
  ]);

  if (companyInfo?.disallowBillableSimChanges === undefined) {
    return <Loading />;
  }

  return (
    <div className="of-employee-subscriptions-scene">
      <SimActivation
        breadCrumbs={breadCrumbs}
        category={subCategoryFromPath}
        disallowBillableSimChanges={companyInfo.disallowBillableSimChanges}
        pendingSimChanges={pendingSimChanges}
        pendingSubscriptionChanges={subscriptionStatus.pendingActions}
        subscription={subscription}
        simChangeServiceFee={
          selfService?.subscriptions?.voice?.serviceFees?.find(
            serviceFee => serviceFee.subscriptionDisplayId === subscription?.subscriptionDisplayId
          )?.price
        }
        onClickOrderSim={() => navigate(generatePath(paths.EMPLOYEE_VOICE_SUBSCRIPTION_ORDER_SIM, { subscriptionId }))}
        onClickSubscription={() => navigate(generatePath(paths.EMPLOYEE_VOICE_SUBSCRIPTION, { subscriptionId }))}
        isEmployee={isEmployee}
      />
    </div>
  );
};
