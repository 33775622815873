import * as CL from '@design-system/component-library';
import { DeliveryType } from '../../generated/api/models.js';
import {
  deliveryDetailsMsg,
  installationAddressMsg,
  methodOfDeliveryMsg,
  shippingAddressMsg,
  t,
} from '../../common/i18n/index.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import type { DeliveryOrderDetails } from '../../generated/api/models.js';

const getDeliveryDetailValue = (deliveryType?: DeliveryType): string => {
  switch (deliveryType) {
    case DeliveryType.POSTI_ECONOMY:
      return 'Posti Economy';
    case DeliveryType.POSTI_EXPRESS_BUSINESS:
      return 'Posti Business Express';
    case DeliveryType.POSTI_EXPRESS_MORNING:
      return t.E6DY('Posti Express morning');
    case DeliveryType.POSTI_SMARTPOST:
      return 'Posti Smartpost';
    case DeliveryType.POSTI_SMARTPOST_EXPRESS:
      return 'Posti Smartpost Express';
    case DeliveryType.LETTER:
      return t.JCEA('Letter');
    case DeliveryType.MATKAHUOLTO_JAKOPAKETTI:
      return t.PLQC('Matkahuolto Delivery Parcel');
    case DeliveryType.MATKAHUOLTO_LAHELLA_PAKETTI:
      return t.EH18('Matkahuolto Near Parcel');
    default:
      return deliveryType ? deliveryType.toString() : '';
  }
};

interface DeliveryOrderDetailsDeliveryProps {
  details: DeliveryOrderDetails;
}

export const DeliveryOrderDetailsDelivery = ({ details }: DeliveryOrderDetailsDeliveryProps) => {
  const { deliveryType } = details;
  return details.shippingAddress || details.deliveryType ? (
    <div className="of-delivery-order-details--section" key="of-delivery-order--section-delivery">
      <h3 className="ds-h3">{t.T78Y(deliveryDetailsMsg)}</h3>
      {(details?.estimatedInstallationDate || details?.installationAddress) && (
        <h4 className="ds-h4 ds-padding-top--3 ds-padding-bottom--3">{t.NIMY('Device delivery')}</h4>
      )}
      <CL.Description
        className="ds-padding-bottom--5"
        items={[
          {
            title: t.IHO6(shippingAddressMsg),
            description: details.shippingAddress,
          },
          {
            title: t.GKQW('Estimated delivery date'),
            description: formatTimestampToUTCDDMMYYYY(details.estimatedDeliveryDate),
          },
          {
            title: t.G0QN(methodOfDeliveryMsg),
            description: deliveryType ? getDeliveryDetailValue(deliveryType) : undefined,
          },
        ].filter(item => !!item && item.description)}
      />
      {(details?.estimatedInstallationDate || details?.installationAddress) && (
        <div>
          <h4 className="ds-h4 ds-padding-bottom--3">{t.R7VE('Installation')}</h4>
          <CL.Description
            className="ds-padding-bottom--5"
            items={[
              {
                title: t.DI4V(installationAddressMsg),
                description: details.installationAddress,
              },
              {
                title: t.KMG2('Estimated installation date'),
                description: formatTimestampToUTCDDMMYYYY(details.estimatedInstallationDate),
              },
            ].filter(item => !!item && item.description)}
          />
          <p className="ds-padding-bottom--5">
            {t.ZCS1(
              'If you have ordered an installation service for the device, the installer will be in contact with you regarding the installation date.'
            )}
          </p>
        </div>
      )}
    </div>
  ) : null;
};
