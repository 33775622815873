import { ShoppingCartWithVoucher } from '../../../components/ShoppingCartWithVoucher/ShoppingCartWithVoucher.js';
import { Spinner } from '../siteUtils.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { getUserAccounts } from '../../../components/Header/dynamic/headerFunctions.js';
import { isCompanyInfoLoading } from '../../../common/utils/stateUtils.js';
import { isStrictFeatureEnabled } from '../../../common/utils/featureFlagUtils.js';
import { useAuth } from '../AuthProvider.js';
import { useLoaderData } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useShoppingCart } from '../../../common/hooks/useShoppingCart.js';
import type { OnlineModel } from '../../../generated/api/onlineModel.js';
import type { OnlineModelsResponse } from '../../../generated/api/onlineModelsResponse.js';
import type { State } from '../../../selfservice/common/store.js';

export const ShoppingCartWithVoucherPath = () => {
  // TODO: remove after taken into use
  const useShoppingCartWithVoucher = useSelector((s: State) =>
    isStrictFeatureEnabled(s.config.featureFlags.shoppingCartWithVoucher)
  );

  const { authenticatedUser } = useAuth();
  const loaderData = useLoaderData() as {
    onlineModels: OnlineModel[];
    onlineModelsHeadersForSalesProducts: OnlineModelsResponse;
  };

  const { cartItems, companyInfo } = useSelector((state: State) => {
    return {
      cartItems: state.deviceCheckout?.cartItems || [],
      companyInfo: state.selfservice?.companyInfo,
    };
  }, deepEqual);

  // Loads cart items from localstorage to state deviceCheckout.cartItems
  useShoppingCart();

  const isLoading = () => authenticatedUser?.isFetching || isCompanyInfoLoading(companyInfo);

  // TODO: remove after taken into use
  if (!useShoppingCartWithVoucher) {
    return null;
  }

  if (isLoading()) {
    return <Spinner />;
  }

  return (
    <ShoppingCartWithVoucher
      cartItems={cartItems}
      discountedPrices={companyInfo?.discountedPrices || []}
      models={loaderData.onlineModels}
      onlineModelsHeadersForSalesProducts={loaderData.onlineModelsHeadersForSalesProducts}
      loggedIn={!!authenticatedUser}
      userAccounts={getUserAccounts(authenticatedUser)}
    />
  );
};
