import { AttachRing } from '../../../../../components/AttachRing/AttachRing.js';
import { ModelType, SubscriptionCategory } from '../../../../../common/enums.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { findSubscription } from '../../../../../common/utils/subscriptionUtils.js';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  loadBillChannels,
  loadOnlineModelByModelType,
  loadSubscriptionAddOnRules,
  loadSubscriptionAddOnRulesMobilePbx,
  loadSubscriptions,
} from '../../../../../selfservice/actions/index.js';
import { onSubmitOrder } from '../../../../../common/utils/dispatcherUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const MobileSubAttachRingPath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { subscriptionId } = useParams<SubscriptionId>();
  const companyInfo = useSelector((state: State) => state?.selfservice?.companyInfo, deepEqual);
  const numberRanges = useSelector((state: State) => state.resources?.numberRanges, deepEqual);
  const subscriptionActions = useSelector((state: State) => state?.selfservice?.subscriptionActions, deepEqual);
  const onlineModels = useSelector((state: State) => state?.selfservice?.onlineModels, deepEqual);
  const subscriptionAddOnRules = useSelector((state: State) => state?.selfservice?.subscriptionAddOnRules, deepEqual);
  const voiceSubs = useSelector(({ selfservice }: State) => selfservice?.subscriptions?.voice || undefined, deepEqual);

  useEffect(() => {
    dispatch(loadSubscriptions({ category: SubscriptionCategory.VOICE, displayId: subscriptionId }));
    // Take to use when Ring Association UI is ready
    dispatch(loadOnlineModelByModelType(ModelType.Ring));
    dispatch(loadSubscriptionAddOnRulesMobilePbx());
    dispatch(loadSubscriptionAddOnRules());
    dispatch(loadBillChannels());
    dispatch(loadSubscriptionAddOnRules());
  }, [dispatch, pathname, subscriptionId]);

  if (!subscriptionId) {
    return null;
  }

  return (
    <AttachRing
      category={SubscriptionCategory.VOICE}
      companyInfo={companyInfo}
      numberRanges={numberRanges}
      onClickSubscription={(subId: string) =>
        navigate(generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId: subId }))
      }
      onSubmitOrder={onSubmitOrder(dispatch)}
      saving={Boolean(subscriptionActions?.saving)}
      subscription={findSubscription(subscriptionId, voiceSubs)}
      onlineModels={onlineModels}
      associations={subscriptionAddOnRules?.associationsMobilePbx}
      dependencies={subscriptionAddOnRules?.dependenciesMobilePbx}
      ringModels={(onlineModels?.items || []).filter(onlineModel => onlineModel.onlineModelCode === ModelType.Ring)}
    />
  );
};
