import { pictureStorageBaseUrl } from '../Picture/contentfulImageProxy.js';

import './PublicSubscriptionImageCards.scss';

export type PublicSubscriptionImageCardsProps = {
  items: {
    content: JSX.Element;
    image: string;
    imageAlt?: string;
    title: React.ReactNode;
  }[];
  alignLeft?: boolean;
};

// This one done only because DS's card container did not have support for image alt's, can be replaced with that when support is there..
export const PublicSubscriptionImageCards = ({
  items,
  alignLeft = false,
}: PublicSubscriptionImageCardsProps): JSX.Element => (
  <div
    className={`of-public-subscription-image-cards ${
      alignLeft ? 'of-public-subscription-image-cards__align-left' : ''
    } `}
  >
    {items.map(item => {
      const { content, image, imageAlt, title } = item;
      return (
        <div key={item.image}>
          <img alt={imageAlt} src={`${pictureStorageBaseUrl}${image}`} />
          <div>
            <h3>{title}</h3>
            {content}
          </div>
        </div>
      );
    })}
  </div>
);
