import {
  DEFAULT_ITEMS_PER_PAGE,
  ListPagination,
  ListSearch,
  SearchFilters,
  Table,
  TableTopActions,
} from '../Table/index.js';
import { ShowAllAccountsToggleForListViews } from '../ShowAllAccountsToggle/ShowAllAccountsToggleForListViews.js';
import { SubscriptionHiddenFields } from '../SubscriptionLists/SubscriptionHiddenFields.js';
import { SubscriptionLink } from '../SubscriptionLists/SubscriptionLink.js';
import {
  billingAccountMsg,
  companyNameMsg,
  deviceMsg,
  identifierMsg,
  monthsMsg,
  orderMsg,
  t,
} from '../../common/i18n/index.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import {
  getCommitmentPeriodFilterGroup,
  getDeviceUserFilterGroup,
  getEppFilterGroup,
  getExpiringFilterGroup,
  getPaymentTypeFilterGroup,
  getTypeFilterGroup,
} from './SubscriptionsDeviceFilters.js';
import { getCompanyName, isMultiBiz } from '../../common/utils/accountUtils.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import {
  isEppDeviceSubscriptionExpired,
  isEppDeviceSubscriptionExpiring,
} from '../EmployeeSubscriptionDetails/eppSubscriptionUtils.js';
import { isInExtensionPeriod } from '../../common/utils/subscriptionUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useLoaderData } from 'react-router-dom';
import { useMultiAccountRowClick } from '../../common/hooks/useMultiAccountRowClick.js';
import { useMultiFilterUpdate } from '../SubscriptionLists/SubscriptionTable.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { useState } from 'react';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { DeviceSubscriptionsLoaderData } from '../../common/loaders.js';
import type { MultiAccountRowClickFunction } from '../../common/hooks/useMultiAccountRowClick.js';
import type { SearchFilterGroup } from '../Table/SearchFilters.js';
import type {
  SubscriptionAggregationsResponseDeviceAggregations,
  SubscriptionHeader,
  SubscriptionSearchResponse,
} from '../../generated/api/models.js';
import type { SubscriptionDeviceTableSearchParams } from '../SubscriptionLists/SubscriptionTable.js';

const getDeviceSubscriptionHeaderColumnDisclaimerText = (subscriptionHeader: SubscriptionHeader) => {
  const eppDeviceSubscriptionExpired = isEppDeviceSubscriptionExpired(subscriptionHeader);
  const eppDeviceSubscriptionInExtensionPeriod = isInExtensionPeriod(subscriptionHeader);

  if (eppDeviceSubscriptionInExtensionPeriod) {
    return `${t.PVHD('Extension period')} 6 ${t.XXVX(monthsMsg)}`;
  } else if (eppDeviceSubscriptionExpired) {
    return t.CL9N('Contract expired');
  } else if (subscriptionHeader.commitmentPeriod) {
    return `${subscriptionHeader.commitmentPeriod} ${t.XXVX(monthsMsg)}`;
  }
  return '';
};

const getDeviceRows = (
  search?: string,
  searchResults: SubscriptionSearchResponse[] = [],
  authenticatedUser?: AuthenticatedUserState,
  onRowClick?: MultiAccountRowClickFunction
) => {
  return searchResults.map(({ result: subscriptionHeader, matchedFields }) => ({
    subscriptionName: (
      <>
        <SubscriptionLink
          subscription={subscriptionHeader}
          path={paths.PS_DEVICE}
          search={search}
          onRowClick={onRowClick}
        >
          <SubscriptionHiddenFields
            subscriptionHeader={subscriptionHeader}
            search={search}
            matchedFields={matchedFields}
          />
          {subscriptionHeader.serialNumber && (
            <div className="ds-font-weight--400 ds-font-size--small ds-font-style--normal ds-color--neutral-500">
              IMEI {highlightKeyword(subscriptionHeader.serialNumber, search)}
            </div>
          )}
        </SubscriptionLink>
      </>
    ),
    purposeOfUseOrContactName:
      matchedFields?.includes('subscriptionPurposeOfUse') || matchedFields?.includes('subscriptionContactName')
        ? highlightKeyword(
            subscriptionHeader.subscriptionPurposeOfUse || subscriptionHeader.subscriptionContactName || '',
            search
          )
        : subscriptionHeader.subscriptionPurposeOfUse || subscriptionHeader.subscriptionContactName || '',
    billingAccountDisplayId: matchedFields?.includes('billingAccountDisplayId')
      ? highlightKeyword(subscriptionHeader.billingAccountDisplayId || '', search)
      : subscriptionHeader.billingAccountDisplayId || '',
    contractEndDate:
      subscriptionHeader.eppSubscription && subscriptionHeader.contractEndDate ? (
        <>
          <div
            className={`ds-display--block ${
              isEppDeviceSubscriptionExpiring(subscriptionHeader) ? 'ds-color--orange' : ''
            }`}
          >
            {formatTimestampToUTCDDMMYYYY(subscriptionHeader?.contractEndDate)}
          </div>
          <div className="ds-font-weight--400 ds-font-size--small ds-font-style--normal ds-color--neutral-500">
            {getDeviceSubscriptionHeaderColumnDisclaimerText(subscriptionHeader)}
          </div>
        </>
      ) : (
        ''
      ),
    companyName: getCompanyName(authenticatedUser, subscriptionHeader.accountMasterId),
  }));
};

// We do not show the epp filter options unless the user has at least one epp device available
const showEppDeviceFilter = (aggregations?: SubscriptionAggregationsResponseDeviceAggregations) => {
  return aggregations?.eppDevices?.all !== 0;
};

export const SubscriptionsDevice = () => {
  const { authenticatedUser } = useAuth();
  const onRowClick = useMultiAccountRowClick();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const { subscriptions, aggregations } = useLoaderData() as DeviceSubscriptionsLoaderData;

  const searchParams = useSearchParams<SubscriptionDeviceTableSearchParams>();
  const { search, subscriptionSubType, eppFilter, expiringFilter, paymentType, commitmentPeriod, deviceUser } =
    searchParams;

  const deviceFilterGroups: SearchFilterGroup[] = [
    getTypeFilterGroup(subscriptionSubType, aggregations.deviceAggregations?.subscriptionSubType),
    showEppDeviceFilter(aggregations.deviceAggregations)
      ? getEppFilterGroup(eppFilter, aggregations.deviceAggregations?.eppDevices)
      : undefined,
    showEppDeviceFilter(aggregations.deviceAggregations)
      ? getCommitmentPeriodFilterGroup(commitmentPeriod, aggregations.deviceAggregations?.commitmentPeriod)
      : undefined,
    showEppDeviceFilter(aggregations.deviceAggregations)
      ? getExpiringFilterGroup(expiringFilter, aggregations.deviceAggregations?.expiring)
      : undefined,
    getPaymentTypeFilterGroup(paymentType, aggregations.deviceAggregations?.paymentType),
    getDeviceUserFilterGroup(deviceUser, aggregations.deviceAggregations?.deviceUser),
  ].filter((filterGroup?: SearchFilterGroup): filterGroup is SearchFilterGroup => filterGroup !== undefined);

  const [_, onFilterUpdated] = useMultiFilterUpdate({
    subscriptionSubType,
    eppFilter,
    paymentType,
    commitmentPeriod,
    deviceUser,
  });

  const onMultiFilterChange = (queryParameters: URLSearchParams) => {
    onFilterUpdated(Object.fromEntries(queryParameters));
  };

  const getColumns = () => [
    { key: 'subscriptionName', label: t.TPVQ(deviceMsg) },
    { key: 'purposeOfUseOrContactName', label: t.L9QG(identifierMsg), sortable: true },
    { key: 'billingAccountDisplayId', label: t.IFT9(billingAccountMsg), sortable: true },
    { key: 'contractEndDate', label: t.UTIR('Contract ends'), sortable: true },
    { key: 'companyName', label: t.KJTS(companyNameMsg) },
  ];

  return (
    <div className="of-subscription-table">
      <TableTopActions
        links={[
          {
            label: t.AQOL(orderMsg),
            to: '/kauppa',
          },
        ]}
      />
      {isMultiBiz(authenticatedUser) && <ShowAllAccountsToggleForListViews className="ds-margin-bottom--4" />}
      <ListSearch
        filtersOpen={filtersOpen}
        setFiltersOpen={setFiltersOpen}
        onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
      />
      <SearchFilters
        displayActiveFiltersAsTiles={!filtersOpen}
        filterGroups={deviceFilterGroups}
        onFilterChange={onMultiFilterChange}
        onModalClose={() => setFiltersOpen(false)}
      />
      <Table
        columns={getColumns()}
        noItemsText={t.CD0S('No devices')}
        rows={getDeviceRows(search, subscriptions?.searchResults, authenticatedUser, onRowClick)}
      />
      <ListPagination totalItems={subscriptions?.total || 0} />
    </div>
  );
};
