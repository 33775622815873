import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { ModelType, SubscriptionCategory } from '../../../../../common/enums.js';
import { OrderSubscriptionSelection } from '../../../../../components/OrderSubscriptionSelection/OrderSubscriptionSelection.js';
import { Spinner } from '../../../siteUtils.js';
import { SystemError } from '../../../../../components/SystemError/SystemError.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { findSubscription } from '../../../../../common/utils/subscriptionUtils.js';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { internetSubscriptionsMsg, omaElisaForCompaniesMsg, t } from '../../../../../common/i18n/index.js';
import {
  loadOnlineModelByModelType,
  loadSubscriptionAddOnRules,
  loadSubscriptionAddOnRulesMobilePbx,
  loadSubscriptions,
} from '../../../../../selfservice/actions/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const BroadbandSubUpdateM2MPath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subscriptionId } = useParams<SubscriptionId>();
  const onlineModels = useSelector((state: State) => state?.selfservice?.onlineModels, deepEqual);
  const broadbandSubs = useSelector(
    (state: State) => state.selfservice?.subscriptions?.broadband || undefined,
    deepEqual
  );

  useEffect(() => {
    dispatch(loadOnlineModelByModelType(ModelType.MobileM2M));
    dispatch(loadSubscriptionAddOnRulesMobilePbx());
    dispatch(loadSubscriptionAddOnRules());
  }, [dispatch]);

  useEffect(() => {
    if (subscriptionId != null) {
      dispatch(loadSubscriptions({ category: SubscriptionCategory.BROADBAND, displayId: subscriptionId }));
    } else {
      navigate(paths.PS_BROADBAND_SUBSCRIPTIONS);
    }
  }, [dispatch, navigate, subscriptionId]);

  if (subscriptionId == null) {
    return null;
  }

  if (onlineModels?.errors) {
    return <SystemError errors={onlineModels.errors} homePath={paths.SELF_SERVICE_HOME} />;
  }

  const subscription = findSubscription(subscriptionId, broadbandSubs);

  if ((!onlineModels?.items?.length && onlineModels?.loading) || subscription == null) {
    return <Spinner />;
  }

  return (
    <>
      <BreadCrumbsWithTitle
        breadCrumbPaths={[
          { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
          { name: t.SEYV(internetSubscriptionsMsg), path: paths.PS_BROADBAND_SUBSCRIPTIONS },
          {
            name: subscription.subscriptionDisplayId,
            path: generatePath(paths.PS_BROADBAND_SUBSCRIPTION, { subscriptionId: subscription.subscriptionDisplayId }),
          },
          { name: t.A7CG('Laitenetti update') },
        ]}
      />
      <OrderSubscriptionSelection
        onlineModelType={ModelType.MobileM2M}
        isChangeOffer={true}
        subscription={subscription}
      />
    </>
  );
};
