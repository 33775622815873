import * as CL from '@design-system/component-library';
import { TableSortOrder, TableUrlParams } from './tableConstants.js';
import { t } from '../../common/i18n/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

import './Table.scss';

export interface TableProps {
  columns: CL.Column[];
  isLoading?: boolean;
  noItemsText?: string;
  rows: CL.KeyRow[];
  valignText?: 'top' | 'middle' | 'bottom';
  onSortChange?: () => void;
}

export const Table = ({
  columns,
  isLoading = false,
  noItemsText,
  rows,
  valignText = 'middle',
  onSortChange,
}: TableProps) => {
  const { hash, search, pathname } = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const sort = queryParams.get(TableUrlParams.SORT) || undefined;
  const order = queryParams.get(TableUrlParams.ORDER) || undefined;
  const searchInput = queryParams.get(TableUrlParams.SEARCH) || undefined;
  const columnsWithSort = columns.map(column => ({
    ...column,
    sorted: column.key === sort && order ? (order as CL.Column['sorted']) : undefined,
  })) satisfies CL.Column[];

  const setSort = (label: string) => {
    const column = columns.find(c => c.label === label);
    if (column) {
      if (sort && order && sort === column.key) {
        queryParams.set(TableUrlParams.ORDER, order === TableSortOrder.ASC ? TableSortOrder.DESC : TableSortOrder.ASC);
      } else {
        queryParams.set(TableUrlParams.SORT, column.key);
        queryParams.set(TableUrlParams.ORDER, column.sorted === 'asc' ? TableSortOrder.DESC : TableSortOrder.ASC);
      }
      if (onSortChange) {
        onSortChange();
      }
      navigate(`${pathname}?${queryParams}${hash}`, { replace: true });
    }
  };

  return (
    <>
      <div className="of-table-wrapper ds-margin-top--4">
        <div className="of-table-content">
          <div className="of-table">
            {isLoading ? (
              <div className="ds-display--flex ds-padding--8 ds-justify-content--center">
                <CL.LoadingSpinner size="xl" />
              </div>
            ) : (
              <CL.Table
                columns={columnsWithSort}
                hideHeadersOnMobile={false}
                onSortClick={setSort}
                rows={rows}
                tableType="striped"
                valignText={valignText}
              />
            )}
          </div>
        </div>
        {rows.length === 0 && !isLoading && (
          <div className="no-results">
            {searchInput ? (
              <>
                <span>{t.OQBT('No results for')} </span>
                <strong>&quot;{searchInput}&quot;</strong>.
              </>
            ) : (
              noItemsText ?? `${noItemsText}`
            )}
          </div>
        )}
      </div>
    </>
  );
};
