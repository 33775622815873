import * as CL from '@design-system/component-library';
import { ExternalLink } from '../ExternalLink/ExternalLink.js';
import { SimCardNumber } from '../SimCardNumber/SimCardNumber.js';
import { SimCardType } from '../SimCardType/SimCardType.js';
import { activateSimCardMsg, simChangePendingMsg, subChangePendingMsg, t } from '../../common/i18n/index.js';
import type { SimCardConfiguration } from '../../common/types/subscription.js';

import './SimCard.scss';

export interface SimCardProps {
  pendingSimChanges: boolean;
  pendingSubscriptionChanges: boolean;
  simCardConfig: SimCardConfiguration;
  sourceIsTellus: boolean;
  onClickOrderSim: () => void;
  onClickSimActivation: () => void;
  simCardChangeAllowed?: boolean;
  actions?: boolean;
  hasEsimQrCode?: boolean;
}

export const SimCard = ({
  pendingSimChanges,
  pendingSubscriptionChanges,
  simCardConfig,
  onClickSimActivation,
  onClickOrderSim,
  sourceIsTellus,
  simCardChangeAllowed,
  actions = true,
  hasEsimQrCode = false,
}: SimCardProps): JSX.Element => {
  if (pendingSimChanges) {
    return (
      <div>
        <p>{t.O7PT(simChangePendingMsg)}</p>
        <div className="ds-padding-bottom--4" />
      </div>
    );
  } else if (pendingSubscriptionChanges) {
    return (
      <div>
        <p>{t.MQHO(subChangePendingMsg)}</p>
        <div className="ds-padding-bottom--4" />
      </div>
    );
  }

  const normalActions = (
    <>
      <CL.Button
        color="light"
        size="s"
        id="activate-sim-card-button"
        className="of-sim-card__button1"
        onClick={() => onClickSimActivation()}
        disabled={!(simCardChangeAllowed === undefined || simCardChangeAllowed || hasEsimQrCode)}
      >
        {t.M1E7(activateSimCardMsg)}
      </CL.Button>
      <CL.Button
        color="light"
        size="s"
        id="order-new-sim-button"
        className="of-sim-card__button2"
        onClick={() => onClickOrderSim()}
        disabled={simCardChangeAllowed === false}
      >
        {t.PMFC('Order new')}
      </CL.Button>
    </>
  );

  const tellusActions = (
    <div>
      <ExternalLink href="https://oma.elisa.fi/yrityksille/simkortinvaihto">
        {t.QQGR('SIM card change and order')}
      </ExternalLink>
      <div className="of-sim-card__notification">
        <div className="of-sim-card__notification-text">
          {t.S2IK(
            'In the event of a change of SIM, there is a slight delay in updating the number of the new SIM card to this view. The information will be updated within 24 hours at the latest.'
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="of-sim-card">
      <CL.Description
        items={[
          ...(sourceIsTellus ? [] : [{ description: <SimCardType simType={simCardConfig.simType} /> }]),
          { description: <SimCardNumber simCardNumber={simCardConfig.simCardNumber} /> },
        ]}
      />

      <div className="ds-padding-bottom--4">
        {actions && <div className="of-sim-card__container">{sourceIsTellus ? tellusActions : normalActions}</div>}
      </div>
    </div>
  );
};
