import { BillingAccountDetails } from '../../../../../components/BillingAccountDetails/BillingAccountDetails.js';
import { InvoiceSystemError } from '../../../../../components/Invoices/Invoices.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { loadContacts } from '../../../../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import type { BillingAccountLoaderResponse } from '../../../../../common/loaders.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const BillingAccountPath = () => {
  const { billingAccount, billChannels } = useLoaderData() as BillingAccountLoaderResponse;
  const dispatch = useDispatch();
  const { contacts } = useSelector(
    (state: State) => ({
      contacts: state.selfservice?.contacts?.items,
    }),
    deepEqual
  );

  useEffect(() => {
    // TODO: Use Redux for fetching contacts as yet. Otherwise if user adds a new contact from a contacts
    //  dropdown, it would not be saved as a contact for the billing account edited, as the newly added contact is
    //  missing from the data fetched by a loader function. To get rid of Redux here it would require a new version
    //  from CompanyDropdown which would use react-hook-form with fetch for adding a new contact and also returns
    //  the newly added contact via callback function.
    dispatch(loadContacts());
  }, [dispatch]);

  return (
    <InvoiceSystemError>
      <BillingAccountDetails billingAccount={billingAccount} billChannels={billChannels} contacts={contacts} />
    </InvoiceSystemError>
  );
};
