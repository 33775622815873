import { ContactCreationType } from '../ContactOrPurposeOfUse/ContactsOrPurposeOfUseUtils.js';
import { ContactRole, SalesType } from '../../generated/api/models.js';
import { getCommercialProductPrices } from '../../common/utils/commercialProductUtils.js';
import { getContactFromContactPerson } from '../../common/utils/contactUtils.js';
import { isCampaignDateValid } from '../../common/utils/campaignUtils.js';
import type {
  AddOn,
  CampaignAssociation,
  CampaignContext,
  CampaignPromotion,
  CommercialProduct,
  Contact,
  ContactPerson,
  Offer,
} from '../../generated/api/models.js';
import type { AddedAddon } from '../../common/types/addon.js';
import type { CampaignSelectOption } from '../FixedBroadbandProductSelection/CampaignSelectTile.js';
import type { ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { PurposeOfUseOrContact } from '../../common/types/subscription.js';
import type { YritystuplaOfferSelection } from './FixedBroadbandCheckout.js';

export const getNewContactFromPurposeOfUseOrContact = (
  purposeOfUseOrContact?: PurposeOfUseOrContact
): Contact | undefined => {
  if (purposeOfUseOrContact?.contactId === ContactCreationType.CREATE_NEW_CONTACT) {
    const {
      email = '',
      lastName = '',
      firstName = '',
      phoneNumber = '',
      costCenter,
      employeeNumber,
    } = purposeOfUseOrContact;

    const contactPerson: ContactPerson = {
      email,
      lastName,
      firstName,
      phoneNumber,
      costCenter: costCenter,
      employeeNumber: employeeNumber,
      roles: [ContactRole.ENDUSER_CONTACT],
    };

    return getContactFromContactPerson(contactPerson);
  }

  return undefined;
};

export const getAddedAddOns = (addOns: AddOn[]): AddedAddon[] => {
  return addOns.map(addOn => ({
    ...addOn,
    addOnAssociationCode: '',
    displayName: addOn.addOnProductName,
    monthlyPrice: addOn.price?.effectivePrice.monthlyRecurringCharge,
    oneTimePrice: addOn.price?.effectivePrice.oneTimeCharge,
  }));
};

export const getConfiguredOffer = (
  offer: Offer,
  commercialProduct: CommercialProduct,
  campaignPromotions: CampaignPromotion[],
  campaignVoucherPromotions: CampaignPromotion[],
  addOns: AddedAddon[],
  yritystuplaOffer: YritystuplaOfferSelection,
  campaign?: CampaignAssociation | null,
  voucher?: string
): ConfiguredOffer => {
  const campaignPromotion = [
    ...campaignVoucherPromotions.map(cp => ({
      campaignPromotion: cp,
      voucher,
    })),
    ...campaignPromotions.map(cp => ({
      campaignPromotion: cp,
      voucher: undefined,
    })),
  ]?.find(promotion => promotion.campaignPromotion.campaignPromotionCode === campaign?.campaignPromotionCode);

  return {
    offer,
    selectedCommercialProducts: [
      {
        commercialProduct: {
          ...commercialProduct,
          campaignAssociations: campaign ? [campaign] : [],
        },
        purposeOfUseOrContact: {},
        addedAddOns: addOns,
        campaignName: campaignPromotion?.campaignPromotion?.name,
        campaignDescription: campaignPromotion?.campaignPromotion?.description,
      },
      ...(yritystuplaOffer.enabled && yritystuplaOffer.selectedOffer
        ? [{ commercialProduct: yritystuplaOffer.selectedOffer.commercialProducts[0], purposeOfUseOrContact: {} }]
        : []),
    ],
    voucher: campaignPromotion?.voucher,
  };
};

export const getSelectedOffer = (availableOffers: Offer[], selectedOfferCode: string): Offer =>
  availableOffers.find(availableOffer => availableOffer.offerCode === selectedOfferCode)!;

export const getValidCampaigns = (cp: CommercialProduct): CampaignAssociation[] =>
  cp.campaignAssociations?.filter(
    campaign => isCampaignDateValid(campaign) && campaign.salesType !== SalesType.CHANGE_OFFER
  ) || [];

export const getValidVoucherCampaigns = (
  campaignContextsFromVoucher: CampaignContext[],
  commercialProductCode: string
): CampaignAssociation[] => {
  return campaignContextsFromVoucher
    .filter(context => context.commercialProductCode === commercialProductCode)
    .flatMap(context => {
      return context.campaignAssociations.filter(
        campaign => isCampaignDateValid(campaign) && campaign.salesType !== SalesType.CHANGE_OFFER
      );
    });
};

export const getCheapestCampaignAssociation = (
  commercialProduct: CommercialProduct,
  campaignContextsFromVoucher: CampaignContext[]
): CampaignAssociation | undefined => {
  const getMonthlyPrice = (campaign: CampaignAssociation) =>
    getCommercialProductPrices(commercialProduct, campaign, SalesType.NEW_SALE)?.monthlyRecurringCharge || 0;

  const validCampaigns = getValidCampaigns(commercialProduct);
  const validVoucherCampaigns = getValidVoucherCampaigns(
    campaignContextsFromVoucher,
    commercialProduct.commercialProductCode
  );

  return [...validCampaigns, ...validVoucherCampaigns].sort((a, b) => getMonthlyPrice(a) - getMonthlyPrice(b))[0];
};

export const getGFastDevicePackageAddon = (): AddOn => {
  return {
    addOnCode: 'a7d3cd86-42bc-6a47-9ed8-831c9237029b',
    addOnGroup: '',
    addOnProductName: 'Laitepaketti G.Fast',
    addOnType: '',
    campaignAssociations: undefined,
    price: {
      listPrice: {
        monthlyRecurringCharge: 0,
        oneTimeCharge: 0,
      },
      effectivePrice: {
        monthlyRecurringCharge: 0,
        oneTimeCharge: 0,
      },
    },
  };
};

export const getCampaignOption = (
  campaign: CampaignAssociation,
  selectedCommercialProduct: CommercialProduct,
  setSelectedCampaign: (campaign?: CampaignAssociation) => void,
  selectedCampaign?: CampaignAssociation,
  voucher?: string
) => {
  const { monthlyRecurringCharge = 0 } = getCommercialProductPrices(
    selectedCommercialProduct,
    campaign,
    SalesType.NEW_SALE
  );

  return {
    period: campaign.fixedTermPeriod,
    price: monthlyRecurringCharge,
    onClick: () => {
      setSelectedCampaign(campaign);
    },
    isSelected: campaign.campaignAssociationCode === selectedCampaign?.campaignAssociationCode,
    voucher,
  };
};

export const getCampaignOptions = (
  selectedCommercialProduct: CommercialProduct,
  setSelectedCampaign: (campaign?: CampaignAssociation) => void,
  campaignContextsFromVoucher: CampaignContext[],
  selectedCampaign?: CampaignAssociation,
  voucher?: string
): CampaignSelectOption[] => {
  const validVoucherCampaigns = getValidVoucherCampaigns(
    campaignContextsFromVoucher,
    selectedCommercialProduct.commercialProductCode
  ).map(campaign =>
    getCampaignOption(campaign, selectedCommercialProduct, setSelectedCampaign, selectedCampaign, voucher)
  );

  const validCampaigns = getValidCampaigns(selectedCommercialProduct).map(campaign =>
    getCampaignOption(campaign, selectedCommercialProduct, setSelectedCampaign, selectedCampaign)
  );

  // Remove duplicate campaigns based on period
  const campaigns = validCampaigns.filter(option => !validVoucherCampaigns.some(vc => vc.period === option.period));

  const noCommitmentOption = {
    period: undefined,
    price: selectedCommercialProduct.monthlyRecurringCharge || 0,
    onClick: () => {
      setSelectedCampaign(undefined);
    },
    isSelected: selectedCampaign === undefined,
  };

  return [...campaigns, ...validVoucherCampaigns, noCommitmentOption].sort((a, b) => a.price - b.price);
};
