import { BreadCrumbs } from '../../../../../components/BreadCrumbs/index.js';
import { CatalogConfiguration } from '../../../../../components/CatalogConfiguration/CatalogConfiguration.js';
import { DetailsWrapper } from '../../../../../components/DetailsWrapper/index.js';
import { Spinner } from '../../../siteUtils.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import {
  deviceListsMsg,
  listOfDevicesMsg,
  newDeviceListMsg,
  omaElisaForCompaniesMsg,
  t,
} from '../../../../../common/i18n/index.js';
import { isCompanyInfoLoading } from '../../../../../common/utils/stateUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useAuth } from '../../../AuthProvider.js';
import { useSelector } from 'react-redux';
import type { State } from '../../../../../selfservice/common/store.js';

export const CompanyInfoCatalogListNewPath = () => {
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  const { authenticatedUser } = useAuth();

  if (isCompanyInfoLoading(companyInfo)) {
    return <Spinner />;
  }
  const breadCrumbs: JSX.Element = (
    <BreadCrumbs
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.COBB(deviceListsMsg), path: paths.COMPANY_INFO_CATALOGS },
        { name: t.MPFC(newDeviceListMsg) },
      ]}
    />
  );

  return (
    <DetailsWrapper
      classes={['of-catalog-configuration']}
      content={<CatalogConfiguration companyInfo={companyInfo ?? undefined} user={authenticatedUser} />}
      detailsTop={breadCrumbs}
      id="catalog-configuration"
      heading={t.Z0HF(listOfDevicesMsg)}
      headingBottom={companyInfo?.companyName}
      heroPicto="catalog"
    />
  );
};
