import { BillingAccountDeliveryMethod } from '../../../../generated/api/models.js';
import { BillingAccountElectronicDeliveryMethodEdit } from './BillingAccountElectronicDeliveryMethodEdit.js';
import { BillingAccountEmailDeliveryMethodEdit } from './BillingAccountEmailDeliveryMethodEdit.js';
import { BillingAccountPaperDeliveryMethodEdit } from './BillingAccountPaperDeliveryMethodEdit.js';
import { getDeliveryMethodDropDownOptions } from '../../../../common/utils/billingAccountUtils.js';
import type { BillChannel, BillingAccount, Contact } from '../../../../generated/api/models.js';

export interface BillingAccountDeliveryMethodEditProps {
  billingAccount: BillingAccount;
  billChannels: BillChannel[];
  disabled?: boolean;
  selectedDeliveryMethod: BillingAccountDeliveryMethod;
  contacts: Contact[];
}

export const BillingAccountDeliveryMethodEditForm = ({
  billingAccount,
  billChannels,
  disabled = false,
  selectedDeliveryMethod,
  contacts,
}: BillingAccountDeliveryMethodEditProps) => {
  const deliveryMethodOptions = getDeliveryMethodDropDownOptions(billingAccount, billChannels);

  switch (selectedDeliveryMethod) {
    case BillingAccountDeliveryMethod.ELECTRONIC:
      return (
        <BillingAccountElectronicDeliveryMethodEdit
          billChannels={billChannels}
          deliveryMethodOptions={deliveryMethodOptions}
          disabled={disabled}
        />
      );
    case BillingAccountDeliveryMethod.EMAIL:
      return (
        <BillingAccountEmailDeliveryMethodEdit
          deliveryMethodOptions={deliveryMethodOptions}
          disabled={disabled}
          contacts={contacts}
        />
      );
    case BillingAccountDeliveryMethod.PAPER:
      return (
        <BillingAccountPaperDeliveryMethodEdit deliveryMethodOptions={deliveryMethodOptions} disabled={disabled} />
      );
    default:
      return null;
  }
};
