import * as CL from '@design-system/component-library';
import * as React from 'react';
import { BillingAccountDeliveryMethod, ContactType } from '../../generated/api/models.js';
import {
  CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE,
  formatBillingAccountOptions,
  getFilteredBillingAccounts,
} from '../../common/utils/billingAccountUtils.js';
import { CreateBillingAccount } from '../CreateBillingAccount/CreateBillingAccount.js';
import { Loading } from '../Loading/index.js';
import {
  billingDetailsMsg,
  eInvoiceMsg,
  eInvoicingAddressMsg,
  eInvoicingOperatorMsg,
  emailInvoiceMsg,
  invoiceDeliveryMethodMsg,
  invoiceLanguageMsg,
  paperInvoiceMsg,
  payerMsg,
  recipientEmailMsg,
  recipientMsg,
  selectMsg,
  t,
} from '../../common/i18n/index.js';
import { convertToErrorStringMap } from '../../common/utils/errorUtils.js';
import { formatAddress } from '../../common/utils/accountUtils.js';
import { loadBillingAccounts } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import type { BillChannel, BillingAccount, Contact } from '../../generated/api/models.js';
import type { BillingAccountOption } from '../../common/utils/billingAccountUtils.js';
import type { BillingAccountOrErrorSupplier, CommonError } from '../../common/types/errors.js';
import type { BillingAccountsState, CompanyInfoState } from '../../common/types/states.js';

import './AddOrSelectBillingAccounts.scss';

export interface AddOrSelectBillingAccountsProps {
  billChannels?: BillChannel[];
  billingAccounts?: BillingAccountsState;
  contacts?: Contact[];
  companyInfo?: CompanyInfoState;
  getBillingAccountIdOnchange?: (val: string) => void;
  detailedView?: boolean;
  prepareNewBillingAccountSaveValues?: (prepareSaveValues: BillingAccountOrErrorSupplier) => void;
  addNewBA: boolean;
  selectedBaId?: string;
  setRefCallBack?: (key: string, ref: HTMLElement | null) => void;
  billingAccountsErrors?: CommonError[];
  billingAccountsEditingChanges?: { [s: string]: string | undefined }[];
  saving: boolean;
  title?: string;
  additionalInformation?: JSX.Element;
  style?: object;
}

const findSelectedOption = (selected: string, options: BillingAccountOption[]): BillingAccountOption | undefined => {
  return options.find(({ displayValue, value }) => displayValue === selected || value === selected);
};

/**
 * Because our <RP.Combobox> doesn't support display values separated from values but we do support both so --
 * By default we always use the (required) 'value' field, but for presentation we will use
 * 'displayValue' if present.
 */
const pickValueFromOptions = (selected: string, options: BillingAccountOption[]): string | undefined => {
  const selectedOption = findSelectedOption(selected, options);
  return selectedOption && selectedOption.value;
};

const pickDisplayValueFromOption = (option: BillingAccountOption): string => {
  return option.displayValue || option.value;
};

const pickDisplayValueFromValue = (value: string, options: BillingAccountOption[]): string | undefined => {
  const selected = findSelectedOption(value, options);
  return selected && pickDisplayValueFromOption(selected);
};

const BillingAccountInfo = ({ billingAccount }: { billingAccount: BillingAccount }) => {
  const fields: JSX.Element[] = [<span key="payerName">{billingAccount.payerName}</span>, <br key="2" />];
  switch (billingAccount.deliveryMethod) {
    case BillingAccountDeliveryMethod.EMAIL:
      fields.push(<span key="billReceiverName">{billingAccount.billReceiverName}</span>);
      fields.push(<br key="3" />);
      fields.push(<span key="deliveryMethod">{t.NNV9(emailInvoiceMsg)}</span>);
      break;
    case BillingAccountDeliveryMethod.PAPER:
      fields.push(<span key="address">{formatAddress(billingAccount.payerAddress)}</span>);
      fields.push(<br key="3" />);
      fields.push(<span key="deliveryMethod">{t.TER4(paperInvoiceMsg)}</span>);
      break;
    case BillingAccountDeliveryMethod.ELECTRONIC:
      fields.push(
        <span key="electronicAddress">
          {billingAccount.billElectronicOperator + ' ' + billingAccount.billElectronicAddress}
        </span>
      );
      fields.push(<br key="3" />);
      fields.push(<span key="deliveryMethod">{t.IK1D(eInvoiceMsg)}</span>);
      break;
  }
  return <div className="of-order-delivery-options__billing-account-info">{fields}</div>;
};

const getDeliveryMethodItems = (billingAccount: BillingAccount): CL.DescriptionItem[] => {
  switch (billingAccount.deliveryMethod) {
    case BillingAccountDeliveryMethod.EMAIL:
      return [
        { title: t.H272(invoiceDeliveryMethodMsg), description: t.IK1D(eInvoiceMsg) },
        {
          title:
            billingAccount.billReceiverType === ContactType.PERSON ? t.SY1D(recipientMsg) : t.W1PP(recipientEmailMsg),
          description: billingAccount.billReceiverName,
        },
      ];
    case BillingAccountDeliveryMethod.PAPER:
      return [
        { title: t.H272(invoiceDeliveryMethodMsg), description: t.TER4(paperInvoiceMsg) },
        { title: t.OL7B(eInvoicingAddressMsg), description: formatAddress(billingAccount.payerAddress) },
      ];
    case BillingAccountDeliveryMethod.ELECTRONIC:
      return [
        { title: t.H272(invoiceDeliveryMethodMsg), description: t.IK1D(eInvoiceMsg) },
        { title: t.WVLB(eInvoicingOperatorMsg), description: billingAccount.billElectronicOperator },
        { title: t.OL7B(eInvoicingAddressMsg), description: billingAccount.billElectronicAddress },
      ];
    default:
      return [];
  }
};

const BillingAccountDetailedInfo = ({ billingAccount }: { billingAccount: BillingAccount }) => (
  <CL.Description
    items={[
      { title: t.PB6S(payerMsg), description: billingAccount.payerName },
      { title: t.A7DR(invoiceLanguageMsg), description: billingAccount.billLanguage },
      ...getDeliveryMethodItems(billingAccount),
    ]}
  />
);

export const AddOrSelectBillingAccounts = (props: AddOrSelectBillingAccountsProps) => {
  const {
    billingAccounts,
    companyInfo,
    addNewBA,
    getBillingAccountIdOnchange,
    detailedView,
    prepareNewBillingAccountSaveValues,
    selectedBaId,
    setRefCallBack,
    billingAccountsErrors,
    saving,
    title,
    additionalInformation,
  } = props;
  const dispatch = useDispatch();

  const [editNewBillingAccount, setEditNewBillingAccount] = React.useState(false);
  const filteredBillingAccounts = useMemo(
    () => getFilteredBillingAccounts(billingAccounts),
    [billingAccounts?.items?.length, billingAccounts?.searchResults?.length] /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps
  );
  const formattedBillingAccounts = formatBillingAccountOptions(
    filteredBillingAccounts || [],
    addNewBA ? t.NCIJ('+ New billing agreement') : undefined,
    detailedView
  );
  const [selectedBillingAccountId, setSelectedBillingAccountId] = React.useState('');
  const [displayBA, setDisplayBA] = React.useState<string>();
  const selectedBillingAccount = useMemo(
    () => billingAccounts?.items?.find(ba => ba.billingAccountId === selectedBillingAccountId),
    [billingAccounts?.items?.length, selectedBillingAccountId] /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps
  );
  const selectBillingAccountError = useMemo(
    () => convertToErrorStringMap(billingAccountsErrors)?.selectBillingAccount,
    [billingAccountsErrors]
  );

  React.useEffect(() => {
    setSelectedBillingAccountId(selectedBaId!);
  }, [selectedBaId]);

  React.useEffect(() => {
    setDisplayBA(pickDisplayValueFromValue(selectedBillingAccountId, formattedBillingAccounts.options));
  }, [formattedBillingAccounts.options.length, selectedBillingAccountId]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setEditNewBillingAccount(
      selectedBaId === CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE || filteredBillingAccounts?.length === 0
    );
  }, [billingAccounts]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (selectedBillingAccountId) {
      const chosenBillingAccountDisplayId = filteredBillingAccounts?.find(
        ba => ba.billingAccountId === selectedBillingAccountId
      )?.billingAccountDisplayId;
      if (chosenBillingAccountDisplayId) {
        dispatch(loadBillingAccounts(chosenBillingAccountDisplayId));
      }
    }
  }, [selectedBillingAccountId]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeBillingAccountId = (id: string, options: BillingAccountOption[]) => {
    if (id !== 'Valitse') {
      const chosenBillingAccountId = pickValueFromOptions(id, options);
      setDisplayBA(pickDisplayValueFromValue(id, options));
      if (chosenBillingAccountId === CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE) {
        setEditNewBillingAccount(true);
      } else {
        setEditNewBillingAccount(false);
        setSelectedBillingAccountId(
          chosenBillingAccountId ? chosenBillingAccountId : CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE
        );
      }
      if (getBillingAccountIdOnchange) {
        getBillingAccountIdOnchange(
          chosenBillingAccountId ? chosenBillingAccountId : CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE
        );
      }
    }
  };

  if (!companyInfo) {
    return <Loading />;
  }

  return (
    <div className="of-add-or-select-billing-accounts">
      <h3>{title ?? t.RPMR(billingDetailsMsg)}</h3>
      {additionalInformation}
      <div
        className={`of-select-billing-account ${detailedView ? 'of-select-billing-account--fullWidth' : ''}`}
        id="select-billing-account-combobox"
        ref={setRefCallBack && ((element: HTMLElement | null) => setRefCallBack('selectBillingAccount', element))}
      >
        {(!selectedBaId || displayBA) && (
          <CL.Combobox
            i18n_combobox_errorMessage={selectBillingAccountError}
            label={t.HVS2('Selected billing account')}
            onValueSelect={item => {
              // Pressing esc calls onValueSelect without an item, so we have to
              // have this check here...
              if (item) {
                onChangeBillingAccountId(item.innerText.trim(), formattedBillingAccounts.options);
              }
            }}
            items={formattedBillingAccounts.displayOptions}
            selectedValue={displayBA}
            i18n_combobox_placeholderText={t.QRYV(selectMsg)}
          />
        )}
        <br />
        {editNewBillingAccount && (
          <CreateBillingAccount
            commonErrors={billingAccountsErrors}
            getPrepareSaveValuesFn={prepareNewBillingAccountSaveValues}
            payerName={companyInfo.companyName!}
            isSaving={saving}
            setRefCallback={setRefCallBack}
          />
        )}
        {!editNewBillingAccount &&
          selectedBillingAccountId &&
          (selectedBillingAccount ? (
            detailedView ? (
              <BillingAccountDetailedInfo billingAccount={selectedBillingAccount} />
            ) : (
              <BillingAccountInfo billingAccount={selectedBillingAccount} />
            )
          ) : (
            <Loading />
          ))}
      </div>
    </div>
  );
};
