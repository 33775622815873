export const closeAppleWebsheet = () => {
  try {
    // @ts-ignore: This is injected by Apple devices
    // eslint-disable-next-line no-undef
    DataActivationController.doneSelected();
  } catch (e) {
    // TODO: Websheet accessed with non-iOS device - error reporting?
  }
};

export const updateAppleDataPlanAccountWithInfo = (dataPlanInfo: { eid: string; iccid: string }) => {
  try {
    // @ts-ignore: This is injected by Apple devices
    // eslint-disable-next-line no-undef
    DataActivationController.dataPlanAccountUpdatedWithInfo({
      eid: dataPlanInfo.eid,
      iccid: dataPlanInfo.iccid,
    });
  } catch (e) {
    // TODO: Websheet accessed with non-iOS device - error reporting / page!?
  }
};
