import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
  findFromPbxSolutionsByType,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { RingAdminDetails } from '../../../../../components/RingAdminDetails/RingAdminDetails.js';
import { SubscriptionType } from '../../../../../generated/api/models.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { findItemById, stripActions } from '../../../../../common/utils/stateUtils.js';
import { getSubscriptionStatus } from '../../../../../common/utils/subscriptionUtils.js';
import { loadContacts } from '../../../../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import type { AdminId } from '../../../../../common/constants/pathInterfaces.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const CompanyInfoRingAdminPath = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { adminId } = useParams<AdminId>();
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  const contacts = useSelector((state: State) => stripActions(state.selfservice?.contacts), deepEqual);
  const ringSubscriptions = companyInfo ? findFromPbxSolutionsByType(companyInfo, SubscriptionType.MOBILE_PBX) : [];
  const subscriptionActions = useSelector((state: State) => state.selfservice?.pendingSubscriptionActions, deepEqual);
  const ringAdminContact = findItemById('contactId', adminId, contacts);
  const subscriptionIds = (ringSubscriptions || []).map(subscription => subscription.subscriptionId);

  useEffect(() => {
    dispatch(loadContacts());
  }, [dispatch, pathname]);

  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.USERS_NOE} isSingular={true}>
      {ringSubscriptions && ringSubscriptions.length > 0 && (
        <RingAdminDetails
          ringAdminContact={ringAdminContact}
          subscriptionIds={subscriptionIds}
          errors={contacts ? contacts.errors : undefined}
          pendingSubscriptionChanges={
            getSubscriptionStatus(ringSubscriptions[0], subscriptionActions?.items).pendingActions
          }
        />
      )}
    </CompanyInfoContainer>
  );
};
