import * as CL from '@design-system/component-library';
import { t } from '../../common/i18n/index.js';
import classNames from 'classnames';

export interface CursorPaginationProps {
  prevCursor?: string;
  nextCursor?: string;
  onNextPageClick: () => void;
  onPreviousPageClick: () => void;
  i18nPaginationPreviousLabel?: string;
  i18nPaginationNextLabel?: string;
}

const isEmpty = (nextCursor?: string) => {
  return nextCursor === undefined || nextCursor === '';
};

export const CursorPagination = ({
  prevCursor,
  nextCursor,
  onPreviousPageClick,
  onNextPageClick,
  i18nPaginationNextLabel = t.F0MY('Next'),
  i18nPaginationPreviousLabel = t.CACI('Previous'),
}: CursorPaginationProps) => {
  return (
    <nav className="ds-paginationnav">
      <ul>
        <li>
          <CL.Button
            aria-label={i18nPaginationPreviousLabel}
            disabled={isEmpty(prevCursor)}
            className={classNames('ds-nextPrevButton', isEmpty(prevCursor) ? 'ds-disabledButton' : undefined)}
            size="m"
            color="link"
            onClick={onPreviousPageClick}
          >
            <CL.Icon icon="arrow-left" />
          </CL.Button>
        </li>
        <li>
          <CL.Button
            aria-label={i18nPaginationNextLabel}
            disabled={isEmpty(nextCursor)}
            className={classNames('ds-nextPrevButton', isEmpty(nextCursor) ? 'ds-disabledButton' : undefined)}
            size="m"
            color="link"
            onClick={onNextPageClick}
          >
            <CL.Icon icon="arrow-right" />
          </CL.Button>
        </li>
      </ul>
    </nav>
  );
};
