import * as CL from '@design-system/component-library';
import { Grid, GridWide } from '../Grid/Grid.js';
import { Link, useLocation } from 'react-router-dom';
import type { HTMLAttributeAnchorTarget, SyntheticEvent } from 'react';

import './LocalNavi.scss';

export interface LocalNaviItem {
  children: string;
  to: string;
  target?: HTMLAttributeAnchorTarget;
  hint?: boolean;
}

export const LocalNavi = (props: { categories: LocalNaviItem[]; disableSelected?: boolean; wide?: boolean }) => {
  const { pathname } = useLocation();
  const LocalNaviGrid = props.wide ? GridWide : Grid;
  const onClick = (selected: boolean) =>
    props.disableSelected && selected ? (e: SyntheticEvent) => e.preventDefault() : undefined;
  return (
    <LocalNaviGrid>
      <div className="of-local-navi">
        <div className="ds-tabnavigation-tab-container">
          {props.categories.map(({ children, to, target, hint }) => {
            const selected = decodeURI(pathname) === to;
            return (
              <Link
                key={to}
                className={['ds-tab-item', selected && 'ds-tab-item__selected'].filter(i => i).join(' ')}
                to={to}
                target={target}
                aria-label={children}
                onClick={onClick(selected)}
              >
                {children}
                {hint && <CL.Icon icon="error" type="filled" color="warning" />}
              </Link>
            );
          })}
        </div>
      </div>
    </LocalNaviGrid>
  );
};
