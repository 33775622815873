import { getActiveAccountMasterId } from '../../selfservice/common/localStorageUtils.js';
import { setActiveAccount } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export type MultiAccountRowClickFunction = (to: string, companyName: string, accountMasterId?: string) => void;

export const useMultiAccountRowClick = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onRowClick: MultiAccountRowClickFunction = (to: string, companyName: string, accountMasterId?: string) => {
    if (accountMasterId && accountMasterId !== getActiveAccountMasterId()) {
      dispatch(setActiveAccount(accountMasterId, false, to, companyName));
    } else {
      navigate(to);
    }
  };

  return onRowClick;
};
