import * as CL from '@design-system/component-library';
import { COST_CENTER_REGEX } from '../../../common/utils/validationUtils.js';
import { ContactCreationType, formValuesToPurposeOfUseOrContact } from '../ContactsOrPurposeOfUseUtils.js';
import { EMAIL_REGEX } from '../../../common/utils/emailUtils.js';
import { PHONE_NUMBER_REGEX } from '../../../common/utils/phoneNumberUtils.js';
import { copyUserInfoMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import type { ContactOrPurposeOfUseFormValues } from '../ContactsOrPurposeOfUseUtils.js';
import type { PurposeOfUseOrContact } from '../../../common/types/subscription.js';

interface CopyUserInfoButtonProps {
  onCopyUserInfo: (purposeOfUseOrContact: PurposeOfUseOrContact) => void;
  isEmailAndPhoneRequired?: boolean;
  isCostCenterRequired?: boolean;
}

export const CopyUserInfoButton = ({
  onCopyUserInfo,
  isEmailAndPhoneRequired,
  isCostCenterRequired,
}: CopyUserInfoButtonProps) => {
  const { values, dirty } = useFormikContext<ContactOrPurposeOfUseFormValues>();
  const [disabled, setDisabled] = useState(false);

  // Needed as Formik's isValid mechanism doesn't work well with conditionally visible fields
  const isValid = useMemo(
    () =>
      (values.selectContact || values.purposeOfUse) &&
      (values.selectContact !== ContactCreationType.CREATE_NEW_CONTACT ||
        (values.firstName &&
          values.lastName &&
          (!isEmailAndPhoneRequired ||
            (values.email &&
              EMAIL_REGEX.test(values.email) &&
              values.phoneNumber &&
              PHONE_NUMBER_REGEX.test(values.phoneNumber))))) &&
      (!isCostCenterRequired || (values.costCenter && COST_CENTER_REGEX.test(values.costCenter))),
    [
      isCostCenterRequired,
      isEmailAndPhoneRequired,
      values.costCenter,
      values.email,
      values.firstName,
      values.lastName,
      values.phoneNumber,
      values.purposeOfUse,
      values.selectContact,
    ]
  );

  useEffect(() => {
    if (dirty) {
      setDisabled(false);
    }
  }, [dirty]);

  return (
    <CL.Button
      color="link"
      onClick={() => {
        setDisabled(true);
        onCopyUserInfo(formValuesToPurposeOfUseOrContact(values));
      }}
      disabled={!isValid || disabled}
      className="copy-user-info-button ds-font-weight--normal ds-font-size--medium ds-padding-left--0"
      size="l"
    >
      <>
        <CL.Icon icon="update" size="m" className="ds-margin-right--1" />
        {t.GUWZ(copyUserInfoMsg)}
      </>
    </CL.Button>
  );
};
