import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { ModelType, SubscriptionCategory } from '../../../../../common/enums.js';
import { OrderSubscriptionSelection } from '../../../../../components/OrderSubscriptionSelection/OrderSubscriptionSelection.js';
import { Spinner } from '../../../siteUtils.js';
import { SystemError } from '../../../../../components/SystemError/SystemError.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { findSubscription } from '../../../../../common/utils/subscriptionUtils.js';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  loadOnlineModelByModelType,
  loadSubscriptionAddOnRules,
  loadSubscriptionAddOnRulesMobilePbx,
  loadSubscriptions,
} from '../../../../../selfservice/actions/index.js';
import { omaElisaForCompaniesMsg, t, voiceSubscriptionsMsg } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const MobileSubUpdatePath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subscriptionId } = useParams<SubscriptionId>();
  const onlineModels = useSelector((state: State) => state?.selfservice?.onlineModels, deepEqual);
  const voiceSubs = useSelector((state: State) => state.selfservice?.subscriptions?.voice || undefined, deepEqual);

  useEffect(() => {
    dispatch(loadOnlineModelByModelType(ModelType.Voice));
    dispatch(loadOnlineModelByModelType(ModelType.VoiceSME));
    dispatch(loadSubscriptionAddOnRulesMobilePbx());
    dispatch(loadSubscriptionAddOnRules());
  }, [dispatch]);

  useEffect(() => {
    if (subscriptionId != null) {
      dispatch(loadSubscriptions({ category: SubscriptionCategory.VOICE, displayId: subscriptionId }));
    } else {
      navigate(paths.PS_MOBILE_SUBSCRIPTIONS);
    }
  }, [dispatch, navigate, subscriptionId]);

  if (subscriptionId == null) {
    return null;
  }

  if (onlineModels?.errors) {
    return <SystemError errors={onlineModels.errors} homePath={paths.SELF_SERVICE_HOME} />;
  }

  const subscription = findSubscription(subscriptionId, voiceSubs);

  if ((!onlineModels?.items?.length && onlineModels?.loading) || subscription == null) {
    return <Spinner />;
  }

  return (
    <>
      <BreadCrumbsWithTitle
        breadCrumbPaths={[
          { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
          { name: t.AR0B(voiceSubscriptionsMsg), path: paths.PS_MOBILE_SUBSCRIPTIONS },
          {
            name: subscription.subscriptionDisplayId,
            path: generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId: subscription.subscriptionDisplayId }),
          },
          { name: t.MGWG('Subscription update') },
        ]}
      />
      <OrderSubscriptionSelection
        onlineModelType={ModelType.VoiceSME}
        isChangeOffer={true}
        subscription={subscription}
      />
    </>
  );
};
