import { Icon } from '../Icon/Icon.js';
import { Link, generatePath } from 'react-router-dom';
import { SubscriptionIcon } from './SubscriptionIcon.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import { isSubscriptionInSfdc } from '../../common/utils/subscriptionUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useStore } from 'react-redux';
import type { MultiAccountRowClickFunction } from '../../common/hooks/useMultiAccountRowClick.js';
import type { ReactNode } from 'react';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionHeader } from '../../generated/api/models.js';

interface SubscriptionLinkProps {
  path: string;
  subscription: SubscriptionHeader;
  children?: ReactNode;
  search?: string;
  onRowClick?: MultiAccountRowClickFunction;
}
export const SubscriptionLink = ({ children, path, subscription, search, onRowClick }: SubscriptionLinkProps) => {
  const classicSiteUrl = useStore<State>().getState().config.classicSiteUrl;
  const { authenticatedUser } = useAuth();
  const externalLink = `${classicSiteUrl}/muutos-ja-poisto/`;

  return isSubscriptionInSfdc(subscription) ? (
    <Link
      to={generatePath(path, { subscriptionId: subscription.subscriptionDisplayId || '' })}
      className="of-subscription-table__text-column"
      onClick={
        onRowClick
          ? e => {
              e.preventDefault();
              onRowClick(
                generatePath(path, { subscriptionId: subscription.subscriptionDisplayId || '' }),
                getCompanyName(authenticatedUser, subscription.accountMasterId),
                subscription.accountMasterId
              );
            }
          : undefined
      }
    >
      <SubscriptionIcon subscription={subscription} />
      <div>
        {highlightKeyword(subscription.subscriptionName || '', search)}
        {children}
      </div>
    </Link>
  ) : (
    <a href={externalLink} className="of-subscription-table__text-column">
      <SubscriptionIcon subscription={subscription} />
      <div>
        {highlightKeyword(subscription.subscriptionName || '', search)}
        <Icon icon="external-link" type="filled" size="m" color="blue-600" />
        {children}
      </div>
    </a>
  );
};
