import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { DatePicker } from '../../common/react-hook-form/components/DatePicker.js';
import { ErrorTooltip } from '../ErrorTooltip/ErrorTooltip.js';
import { Loading } from '../Loading/index.js';
import { NumberOwnerRHF } from '../NumberOwnerRHF/NumberOwnerRHF.js';
import { PHONE_NUMBER_REGEX } from '../../common/utils/phoneNumberUtils.js';
import { PhoneNumber } from '../../common/react-hook-form/fields/PhoneNumber.js';
import { PhoneNumberType } from '../../common/enums.js';
import { SelectNumberList, isNumberDisabled } from '../SelectNumberList/index.js';
import { SelectRadio } from '../../common/react-hook-form/components/SelectRadio.js';
import { fieldCantBeEmptyMsg, invalidValueMsg, phoneNumberMsg, showMoreMsg, t } from '../../common/i18n/index.js';
import { formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import { useEffect } from 'react';
import type { ConfiguredCommercialProduct } from '../../common/types/commercialProduct.js';
import type { SelectedPhoneNumber } from '../../common/types/subscription.js';
import type { ValidatedPhoneNumber } from '../../common/utils/phoneNumberUtils.js';

import './SelectPhoneNumber.scss';

export interface SelectPhoneNumberProps {
  holidays: Date[];
  cartItemInstanceId: string;
  companyName: string;
  disabledPhoneNumbers: string[];
  getNextAllowedDay: (blockedDates: Date[]) => Date;
  isAllowedTransferDay: (dateToCheck: Date, blockedDates: Date[]) => boolean;
  onSelectUseExistingPhoneNumber: (existingPhoneNumber: string) => void;
  phoneNumbers: string[];
  phoneNumbersLoading: boolean;
  selectedCommercialProduct: ConfiguredCommercialProduct;
  selectedPhoneNumber?: SelectedPhoneNumber;
  validatedPhoneNumbers: ValidatedPhoneNumber[];
}

export const SelectPhoneNumber = ({
  holidays,
  cartItemInstanceId,
  companyName,
  disabledPhoneNumbers,
  getNextAllowedDay,
  onSelectUseExistingPhoneNumber,
  phoneNumbers,
  phoneNumbersLoading,
  selectedCommercialProduct,
  selectedPhoneNumber,
  validatedPhoneNumbers,
}: SelectPhoneNumberProps) => {
  const firstAllowedDay = getNextAllowedDay(holidays);

  const { control, watch, setValue } = useFormContext();
  const phoneNumberType = watch(`configuration.${cartItemInstanceId}.selectPhoneNumber.type`);

  // TODO this breaks validation if dispatched api call takes long time, it won't prevent submit
  const existingPhoneNumber = useWatch({
    name: `configuration.${cartItemInstanceId}.selectPhoneNumber.existingPhoneNumber`,
  });
  useEffect(() => {
    if (existingPhoneNumber) {
      onSelectUseExistingPhoneNumber(existingPhoneNumber);
    }
  }, [onSelectUseExistingPhoneNumber, existingPhoneNumber]);

  const getPhoneNumberValidationError = (phoneNumber: string) => {
    if (!phoneNumber) {
      return t.VPVR(fieldCantBeEmptyMsg);
    } else if (!PHONE_NUMBER_REGEX.test(phoneNumber)) {
      return t.DUPA(invalidValueMsg);
    }
    return validatedPhoneNumbers.find(it => it.phoneNumber === phoneNumber)?.error;
  };

  const newPhoneNumber = watch(`configuration.${cartItemInstanceId}.selectPhoneNumber.newPhoneNumber`);
  useEffect(() => {
    if (phoneNumberType === PhoneNumberType.NEW && newPhoneNumber === undefined) {
      const autoSelectableNumber =
        phoneNumbers && phoneNumbers.find(phoneNumber => !isNumberDisabled(phoneNumber, disabledPhoneNumbers));
      if (autoSelectableNumber) {
        setValue(`configuration.${cartItemInstanceId}.selectPhoneNumber.newPhoneNumber`, autoSelectableNumber);
      }
    }
  }, [cartItemInstanceId, phoneNumberType, newPhoneNumber, phoneNumbers, disabledPhoneNumbers, setValue]);

  return (
    <div id={`select-phone-number-${cartItemInstanceId}`} className="of-select-phone-number">
      <h4 className="ds-margin-top--1 ds-margin-bottom--3">{t.AGIJ(phoneNumberMsg)}</h4>
      <SelectRadio
        name={`configuration.${cartItemInstanceId}.selectPhoneNumber.type`}
        defaultValue={selectedPhoneNumber?.type || PhoneNumberType.EXISTING}
        items={[
          {
            label: t.VPJ3('Transfer current phone number'),
            value: PhoneNumberType.EXISTING,
          },
          {
            label: t.ZDX7('New number'),
            value: PhoneNumberType.NEW,
          },
        ]}
      />
      {phoneNumberType === PhoneNumberType.EXISTING && (
        <>
          <div className="of-select-phone-number__existing">
            <PhoneNumber
              name={`configuration.${cartItemInstanceId}.selectPhoneNumber.existingPhoneNumber`}
              type="text"
              label=""
              placeholder={t.AGIJ(phoneNumberMsg)}
              disabled={false}
              required={true}
              validate={getPhoneNumberValidationError}
            />
          </div>
          <NumberOwnerRHF
            {...{
              companyName,
              cartItemInstanceId: cartItemInstanceId,
              phoneNumberOwner: selectedCommercialProduct.phoneNumberOwner,
            }}
          />
          <h4 className="ds-margin-top--6 ds-margin-bottom--3">{t.BRWO('Select your preferred time of transfer')}</h4>
          <div className="of-select-phone-number__transfer-date-input">
            <DatePicker
              name={`configuration.${cartItemInstanceId}.selectPhoneNumber.transferDate`}
              defaultValue={
                selectedPhoneNumber?.transferDate
                  ? formatTimestampToDDMMYYYY(selectedPhoneNumber.transferDate.getTime())
                  : formatTimestampToDDMMYYYY(getNextAllowedDay(holidays).getTime())
              }
              disableWeekends={true}
              blockedDates={holidays}
              visibleRange={[
                [firstAllowedDay.getFullYear(), firstAllowedDay.getMonth() + 1, firstAllowedDay.getDate()],
                [9999, 12, 31],
              ]}
            />
          </div>
        </>
      )}
      {phoneNumberType === PhoneNumberType.NEW && (
        <>
          <Controller
            name={`configuration.${cartItemInstanceId}.selectPhoneNumber.newPhoneNumber`}
            control={control}
            shouldUnregister={true}
            rules={{ required: { value: true, message: t.VPVR(fieldCantBeEmptyMsg) } }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                {error && <ErrorTooltip placement="top">{error?.message}</ErrorTooltip>}
                <SelectNumberList
                  disabledNumbers={disabledPhoneNumbers}
                  getNewNumbersLinkText={t.H6OA(showMoreMsg)}
                  inset
                  numbers={phoneNumbers}
                  onSelectNumber={(selectedNumber: string) => {
                    onChange(selectedNumber);
                  }}
                  selectedNumber={value}
                  showMoreNumbersLink={phoneNumbers.length < 30 && !phoneNumbersLoading}
                />
              </>
            )}
          />
          {phoneNumbersLoading && <Loading />}
        </>
      )}
    </div>
  );
};
