import * as CL from '@design-system/component-library';
import { ConsolidatedListView } from '../ConsolidatedListView/ConsolidatedListView.js';
import {
  DEFAULT_ITEMS_PER_PAGE,
  ListPagination,
  ListSearch,
  SearchFilters,
  StatusColumn,
  Table,
  TableUrlParams,
} from '../Table/index.js';
import { Highlight } from '../Highlight/Highlight.js';
import { Link, generatePath } from 'react-router-dom';
import { ShowAllAccountsToggleForListViews } from '../ShowAllAccountsToggle/ShowAllAccountsToggleForListViews.js';
import { SupportCase, SupportCasesSearchResponse } from '../../generated/api/models.js';
import { SupportCaseFields } from '../../common/enums.js';
import {
  featureTranslation,
  fieldIsInContentFilter,
  getColumnTranslation,
  hasMatchedFieldsInContent,
  statusData,
  statusTranslation,
} from '../../common/utils/supportCaseUtils.js';
import {
  formatTimeStampToHHmm,
  formatTimestampToDDMMYYYY,
  formatTimestampToUTCDDMMYYYY,
} from '../../common/utils/dateUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { getSearchSnippetText, isValidSearchQuery } from '../../common/utils/stringUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { t } from '../../common/i18n/index.js';
import { useMultiAccountRowClick } from '../../common/hooks/useMultiAccountRowClick.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { useState } from 'react';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { SearchFilterGroup } from '../Table/SearchFilters.js';
import type {
  SupportCaseAggregationsResponse,
  SupportCaseAggregationsResponseFeature,
  SupportCaseAggregationsResponseStatus,
} from '../../generated/api/models.js';

import './CaseList.scss';

export interface CaseListProps {
  supportCases?: SupportCasesSearchResponse[];
  aggregations?: SupportCaseAggregationsResponse;
  totalItems: number;
  authenticatedUser?: AuthenticatedUserState;
}

const generateSupportCaseUrl = (supportCaseDisplayId: string, accountMasterId?: string) =>
  `${generatePath(paths.SUPPORT_CASE, {
    supportCaseDisplayId: supportCaseDisplayId,
  })}?companyId=${accountMasterId}`;

export const CaseList = ({ supportCases, aggregations, totalItems, authenticatedUser }: CaseListProps) => {
  const searchParams = useSearchParams<{
    search?: string;
    feature?: string;
    status?: string;
  }>();
  const { search, feature, status } = searchParams;
  const searchTerms = search?.split(' ') || [];
  const [filtersOpen, setFiltersOpen] = useState(false);
  const onRowClick = useMultiAccountRowClick();

  const columns: CL.Column[] = [
    {
      key: SupportCaseFields.SUPPORT_CASE_DISPLAY_ID,
      label: getColumnTranslation(SupportCaseFields.SUPPORT_CASE_DISPLAY_ID),
      sortable: true,
      width: '10%',
    },
    {
      key: SupportCaseFields.CREATED_AT,
      label: getColumnTranslation(SupportCaseFields.CREATED_AT),
      sortable: true,
      width: '10%',
    },
    {
      key: SupportCaseFields.FEATURE,
      label: getColumnTranslation(SupportCaseFields.FEATURE),
      sortable: true,
      width: '20%',
    },
    {
      key: SupportCaseFields.CONTACT_DETAILS,
      label: getColumnTranslation(SupportCaseFields.CONTACT_DETAILS),
      sortable: true,
      width: '20%',
    },
    {
      key: SupportCaseFields.LAST_MODIFIED,
      label: getColumnTranslation(SupportCaseFields.LAST_MODIFIED),
      sortable: true,
      width: '10%',
    },
    {
      key: SupportCaseFields.STATUS,
      label: getColumnTranslation(SupportCaseFields.STATUS),
      sortable: true,
      width: '10%',
    },
    {
      key: SupportCaseFields.COMPANY,
      label: getColumnTranslation(SupportCaseFields.COMPANY),
      sortable: false,
      width: '20%',
    },
  ];

  const rows = supportCases?.map(value => ({
    [SupportCaseFields.SUPPORT_CASE_DISPLAY_ID]: (
      <Link
        to={generateSupportCaseUrl(value.result.supportCaseDisplayId, value.result.accountMasterId)}
        onClick={() => {
          onRowClick(
            generateSupportCaseUrl(value.result.supportCaseDisplayId, value.result.accountMasterId),
            getCompanyName(authenticatedUser, value.result.accountMasterId),
            value.result.accountMasterId
          );
        }}
      >
        <CL.Description
          className="ds-description__list-item-title-override"
          columns={1}
          items={[
            {
              description: t.EKG9('Support case'),
              title: <Highlight text={value.result.supportCaseDisplayId} highlights={searchTerms} />,
            },
          ]}
        />
        {value.matchedFields && hasMatchedFieldsInContent(value.matchedFields) && isValidSearchQuery(search) && (
          <div className="additional-field-content">
            <strong>{t.NBNH('Content')}: </strong>
            {value.matchedFields?.filter(fieldIsInContentFilter).map(matchedField => (
              <span key={matchedField}>
                {getColumnTranslation(SupportCaseFields[matchedField])}:&nbsp;
                {matchedField === SupportCasesSearchResponse.MatchedFieldsEnum.DESCRIPTION ||
                matchedField === SupportCasesSearchResponse.MatchedFieldsEnum.RESOLUTION ? (
                  <Highlight
                    // @ts-ignore
                    text={getSearchSnippetText(value.result[SupportCaseFields[matchedField]], searchTerms)}
                    highlights={searchTerms}
                  />
                ) : (
                  <Highlight
                    // @ts-ignore
                    text={value.result[SupportCaseFields[matchedField]]}
                    highlights={searchTerms}
                  />
                )}
                &nbsp;
              </span>
            ))}
          </div>
        )}
      </Link>
    ),
    [SupportCaseFields.CREATED_AT]: (
      <CL.Description
        className="ds-description__list-item-title-override"
        columns={1}
        items={[
          {
            description: value.result.createdAt ? formatTimeStampToHHmm(value.result.createdAt) : '',
            title: formatTimestampToDDMMYYYY(value.result.createdAt),
          },
        ]}
      />
    ),
    [SupportCaseFields.FEATURE]: (
      <CL.Description
        columns={1}
        items={[
          {
            description: <Highlight text={value.result.featureType ?? ''} highlights={searchTerms} />,
            title: featureTranslation(value.result.feature),
          },
        ]}
      />
    ),
    [SupportCaseFields.CONTACT_DETAILS]: (
      <CL.Description
        columns={1}
        items={[
          {
            description: <Highlight text={value.result.email || ''} highlights={searchTerms} />,
            title: <Highlight text={`${value.result.firstName} ${value.result.lastName}`} highlights={searchTerms} />,
          },
        ]}
      />
    ),
    [SupportCaseFields.LAST_MODIFIED]: (
      <CL.Description
        className="ds-description__list-item-title-override"
        columns={1}
        items={[
          {
            description: value.result.lastModified ? formatTimeStampToHHmm(value.result.lastModified) : '',
            title: formatTimestampToUTCDDMMYYYY(value.result.lastModified),
          },
        ]}
      />
    ),
    [SupportCaseFields.STATUS]: (
      <StatusColumn status={statusData(value.result.status).text} color={statusData(value.result.status).color} />
    ),
    [SupportCaseFields.COMPANY]: getCompanyName(authenticatedUser, value.result.accountMasterId),
  }));

  const isContactInfoBillingFeature = (featureEnum: SupportCase.FeatureEnum) =>
    featureEnum === SupportCase.FeatureEnum.CONTACT_INFO_BILLING;

  const FEATURES_FOR_FILTERING = [
    SupportCase.FeatureEnum.CONTACT_INFO_OFFER_REQUEST,
    SupportCase.FeatureEnum.CONTACT_INFO_OPEN_ORDERS,
    SupportCase.FeatureEnum.CONTACT_INFO_BILLING,
    SupportCase.FeatureEnum.BILLING_E_OPERATOR_CHANGES,
    SupportCase.FeatureEnum.INVOICES,
    SupportCase.FeatureEnum.CONTACT_INFO_SERVICES,
    SupportCase.FeatureEnum.CONTACT_INFO_TECHNICAL,
    SupportCase.FeatureEnum.CONTACT_INFO_COVERAGE,
    SupportCase.FeatureEnum.CONTACT_INFO_CANCEL,
    SupportCase.FeatureEnum.CONTACT_INFO_PUK,
    SupportCase.FeatureEnum.CONTACT_INFO_OTHER,
  ];

  const getFeatureFilterGroup = (): SearchFilterGroup => {
    const items = aggregations?.feature
      .filter(featureAggregation => FEATURES_FOR_FILTERING.includes(featureAggregation.name))
      ?.map((featureAggregation: SupportCaseAggregationsResponseFeature) => {
        // CONTACT_INFO_BILLING and INVOICES are combined into one filter so items count is the sum of both
        const itemsCount = isContactInfoBillingFeature(featureAggregation.name)
          ? featureAggregation.count +
            (aggregations?.feature.find(agg => agg.name === SupportCase.FeatureEnum.INVOICES)?.count ?? 0)
          : featureAggregation.count;
        return {
          checked: feature?.split('|').includes(featureAggregation.name),
          itemsCount: itemsCount,
          label: () => featureTranslation(featureAggregation.name),
          value: isContactInfoBillingFeature(featureAggregation.name)
            ? `${SupportCase.FeatureEnum.CONTACT_INFO_BILLING}|${SupportCase.FeatureEnum.INVOICES}`
            : featureAggregation.name,
        };
      })
      .filter(item => item.value !== SupportCase.FeatureEnum.INVOICES);

    return {
      items: items || [],
      label: () => t.SF9V('Topic'),
      value: TableUrlParams.FEATURE,
    };
  };

  const getStatusFilterGroup = (): SearchFilterGroup => {
    const items = aggregations?.status?.map((statusAggregation: SupportCaseAggregationsResponseStatus) => {
      return {
        checked: status?.split('|').includes(statusAggregation.name),
        itemsCount: statusAggregation.count,
        label: () => statusTranslation(statusAggregation.name),
        value: statusAggregation.name,
      };
    });

    return {
      items: items || [],
      label: () => t.UNK4('Status'),
      value: TableUrlParams.STATUS,
    };
  };

  const getCaseListSearchFilterGroups = () => {
    const featureFilterGroup = getFeatureFilterGroup();
    const statusFilterGroup = getStatusFilterGroup();
    return featureFilterGroup.items.length > 0 && statusFilterGroup.items.length > 0
      ? [featureFilterGroup, statusFilterGroup]
      : [];
  };

  return (
    <div className="of-case-list">
      <ConsolidatedListView
        authenticatedUser={authenticatedUser}
        listElement={<Table columns={columns} noItemsText={t.YD2N('No support cases')} rows={rows || []} />}
        listPagination={<ListPagination totalItems={totalItems} />}
        listSearch={
          <ListSearch
            filtersOpen={filtersOpen}
            setFiltersOpen={setFiltersOpen}
            onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
          />
        }
        searchFilters={
          <SearchFilters
            displayActiveFiltersAsTiles={!filtersOpen}
            filterGroups={getCaseListSearchFilterGroups()}
            onModalClose={() => setFiltersOpen(false)}
          />
        }
        toggleElement={<ShowAllAccountsToggleForListViews className="ds-margin-bottom--4" />}
      />
    </div>
  );
};
