import * as CL from '@design-system/component-library';
import { AnalyticsListNames } from '../OrderSubscriptionSelection/subscriptionOrderAnalyticsUtils.js';
import { Anchor } from '../Anchor/Anchor.js';
import { ContactSupportBlock } from './ContactSupportBlock.js';
import { CoverageDisclaimer } from './CoverageDisclaimer.js';
import {
  FixedBroadbandContent,
  nettiLiteMobileBroadbandContent,
  resolveBullets,
} from '../OrderSubscriptionSelection/content/NettiLiteOfferSpecification.js';
import { SalesType } from '../../generated/api/models.js';
import { SubscriptionCards } from '../SubscriptionCards/SubscriptionCards.js';
import { Voucher } from '../OrderSubscriptionSelection/Voucher.js';
import {
  addressMsg,
  availabilityResultsMsg,
  changeMsg,
  fixedBroadBandNettiLiteOffersMsg,
  fixedBroadBandNettiLiteParagraphMsg,
  mobileBroadbandNettiLiteOffersMsg,
  mobileBroadbandNettiLiteOffersParagraphMsg,
  nettiLiteWeRecommendMsg,
  selectMsg,
  t,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getCommercialProductPrices } from '../../common/utils/commercialProductUtils.js';
import { getOfferCardContent } from '../OrderSubscriptionSelection/CampaignProductCard.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { OfferCard } from '../OrderSubscriptionSelection/OrderSubscriptionSelection.js';
import type { OnlineModel, Subscription } from '../../generated/api/models.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubCardProps } from '../SubscriptionCard/SubscriptionCard.js';
import type { SubCardWithImpressionProps } from '../SubscriptionCards/subscriptionCardsAnalyticsUtils.js';

import '../OrderSubscriptionSelection/OrderSubscriptionSelection.scss';
import './NettiLiteOffers.scss';

export interface NettiLiteOffersProps {
  subscription?: Subscription | null;
  isBreadCrumbEnabled?: boolean;
  fixedBroadBandOnlineModels: OnlineModel;
  mobileBroadBandOnlineModels: OnlineModel;
  availabilityAddress: string;
  postalCode?: string;
}

// TODO we might benefit from doing this mapping at a higher level and without mapping it first to offercard as it is now a redundant step
const mapToSubscriptionCard = (offerCards: OfferCard[]): SubCardProps[] => {
  return offerCards.map(card => {
    const prices = getCommercialProductPrices(
      card.offer.commercialProducts[0],
      card.campaignAssociation,
      card.campaignAssociation?.salesType
    );
    return {
      onlineModel: card.onlineModel,
      offer: card.offer,
      bullets: resolveBullets(card.offer.offerCode),
      name: card.offer.offerName,
      description: card.content.body,
      mainHeader: card?.content?.title || '',
      oneTimePrice: prices.oneTimeCharge,
      originalOneTimePrice: prices.oneTimeChargeBeforeDiscount,
      monthlyPrice: prices.monthlyRecurringCharge,
      originalMonthlyPrice: prices.monthlyRecurringChargeBeforeDiscount,
      fixedTermMonths: card.campaignAssociation?.fixedTermPeriod,
      voucher: card.voucher,
      pricePreamble: t.XT3B('From'),
      showSticker: false,
    };
  });
};

export const NettiLiteOffers = (props: NettiLiteOffersProps) => {
  const { fixedBroadBandOnlineModels, mobileBroadBandOnlineModels, availabilityAddress, postalCode } = props;
  const navigate = useNavigate();

  const campaigns = useSelector((state: State) => state.campaigns, deepEqual);
  const campaignPromotionsFromVoucher = campaigns?.campaigns || [];
  const campaignContextsFromVoucher = campaigns?.campaignContexts || [];

  const fixedBroadbandCampaignPromotions = [
    ...campaignPromotionsFromVoucher,
    ...(fixedBroadBandOnlineModels.campaigns || []),
  ];
  const mobileBroadbandCampaignPromotions = [
    ...campaignPromotionsFromVoucher,
    ...(mobileBroadBandOnlineModels.campaigns || []),
  ];

  const offerCardContentsForFixedBroadband = getOfferCardContent(
    fixedBroadBandOnlineModels,
    undefined,
    false,
    { campaignContexts: campaignContextsFromVoucher, voucher: campaigns?.voucher },
    true,
    fixedBroadbandCampaignPromotions,
    FixedBroadbandContent()
  );

  const offerCardContentsForMobileBroadband = getOfferCardContent(
    mobileBroadBandOnlineModels,
    undefined,
    false,
    { campaignContexts: campaignContextsFromVoucher, voucher: campaigns?.voucher },
    true,
    mobileBroadbandCampaignPromotions,
    nettiLiteMobileBroadbandContent()
  );

  const handleSelectOffer = (offerCode: string, isMobileProduct: boolean) => {
    const onlineModel = !isMobileProduct ? fixedBroadBandOnlineModels : mobileBroadBandOnlineModels;
    const selectedOffer = onlineModel.offers.find(offer => offer.offerCode === offerCode);

    if (!selectedOffer) {
      return;
    }

    navigate(paths.ANONYMOUS_FIXED_BROADBAND_ORDER_CHECKOUT, {
      state: {
        selectedOfferCode: offerCode,
        isMobileProduct,
        disableHeader: true, // The header and breadcrumbs should be hidden during checkout step
        searchAddress: availabilityAddress,
        postalCode,
        previousUrl: location.pathname,
      },
    });
  };

  return (
    <CL.Grid id="of-order-subscription-selection" className="of-order-subscription-selection of-nettilite-container">
      <CL.GridRow className="of-change-availablity-address-row">
        <CL.GridCol colWidth={12}>
          <div>
            <h1>{t.IGS3(availabilityResultsMsg)}</h1>
          </div>
        </CL.GridCol>
        <CL.GridCol colWidth={12}>
          <h4>
            {`${t.GWZ6(addressMsg)}: ${availabilityAddress}`}{' '}
            <Anchor className="of-change-address-link" onClick={() => history.back()}>
              {t.SEER(changeMsg)}
            </Anchor>
          </h4>
        </CL.GridCol>
        <CL.GridCol className="title-voucher-row" colWidth={12} colWidthM={6} colWidthL={6}>
          <Voucher onlineModelCode={fixedBroadBandOnlineModels.onlineModelCode} salesType={SalesType.NEW_SALE} />
        </CL.GridCol>
      </CL.GridRow>

      <CL.GridRow>
        <CL.GridCol colWidthXS={4}>
          {offerCardContentsForFixedBroadband.length > 0 && (
            <div>
              <h2>{t.PKML(fixedBroadBandNettiLiteOffersMsg)}</h2>
              <p>{t.WQ2D(fixedBroadBandNettiLiteParagraphMsg)}</p>
            </div>
          )}
        </CL.GridCol>
      </CL.GridRow>
      {offerCardContentsForFixedBroadband.length > 0 && (
        <CL.GridRow>
          <CL.GridCol colWidth={12}>
            <h3>{t.E82U(nettiLiteWeRecommendMsg)} </h3>
          </CL.GridCol>
          <CL.GridCol colWidth={12}>
            <SubscriptionCards
              listName={AnalyticsListNames.NETTI_LITE_LIST_NAME}
              buttonText={t.QRYV(selectMsg)}
              subscriptions={mapToSubscriptionCard(offerCardContentsForFixedBroadband)}
              onButtonClick={(subCard: SubCardWithImpressionProps) => handleSelectOffer(subCard.offer.offerCode, false)}
            />
          </CL.GridCol>
        </CL.GridRow>
      )}
      <CL.GridRow>
        <CL.GridCol colWidth={12} className="heading-margin">
          <h1>{t.A575(mobileBroadbandNettiLiteOffersMsg)}</h1>
          <p>{t.WQ2D(mobileBroadbandNettiLiteOffersParagraphMsg)}</p>
          <CoverageDisclaimer />
        </CL.GridCol>
      </CL.GridRow>

      <CL.GridRow>
        <CL.GridCol colWidth={12}>
          <h3>{t.E82U(nettiLiteWeRecommendMsg)} </h3>
        </CL.GridCol>
        <CL.GridCol colWidth={12}>
          <SubscriptionCards
            listName={AnalyticsListNames.NETTI_LITE_LIST_NAME}
            buttonText={t.QRYV(selectMsg)}
            subscriptions={mapToSubscriptionCard(offerCardContentsForMobileBroadband)}
            onButtonClick={(subCard: SubCardWithImpressionProps) => handleSelectOffer(subCard.offer.offerCode, true)}
          />
        </CL.GridCol>
      </CL.GridRow>

      <CL.GridRow className="of-contact-support">
        <CL.GridCol colWidth={12}>
          <ContactSupportBlock />
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};
