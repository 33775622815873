import * as CL from '@design-system/component-library';

export interface ToggleButtonProps {
  isOpen: boolean;
  buttonLabel: string;
  className?: string;
  onClick: () => void;
}

export const ToggleButton = ({ isOpen, buttonLabel, onClick, className }: ToggleButtonProps): JSX.Element => (
  <CL.Button color="link" size="l" onClick={onClick} className={className}>
    <span className="ds-margin-right--1">{buttonLabel}</span>
    <CL.Icon key={isOpen.toString()} icon={isOpen ? 'arrow-up' : 'arrow-down'} />
  </CL.Button>
);
