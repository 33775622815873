import * as CL from '@design-system/component-library';
import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { Contract } from '../../generated/api/models.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { DialogType } from '../../common/enums.js';
import { Loading } from '../Loading/index.js';
import { MobileIdDisabled } from './MobileIdDisabled.js';
import {
  cancelMsg,
  confirmMsg,
  confirmOrderMsg,
  contractsMsg,
  mobiiliivarmenneTunnistauduMsg,
  mobiilivarmenneForEmployeeMsg,
  mobiilivarmennePricePerUserMsg,
  omaElisaForCompaniesMsg,
  orderMsg,
  priceMsg,
  t,
} from '../../common/i18n/index.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { showDialog } from '../../selfservice/actions/index.js';
import { submitMobileIdContract } from '../../selfservice/actions/mobileIdContractActions.js';
import { useDispatch, useStore } from 'react-redux';
import type { GenericConfirmationDialogParams } from '../../common/types/dialog.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionsState } from '../../common/types/states.js';

import './MobileId.scss';

export interface MobileIdNewProps {
  onCancel?: () => void;
  subscriptions?: SubscriptionsState;
}

export const MobileIdNew = (props: MobileIdNewProps) => {
  const { onCancel, subscriptions } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeContract: Contract | undefined = subscriptions?.mobileIdContracts?.find(
    contract => contract.contractStatus === Contract.ContractStatusEnum.ACTIVATED
  );

  const confirmationDialogParams: GenericConfirmationDialogParams = {
    type: DialogType.GENERIC_CONFIRMATION_DIALOG,
    header: t.RZU4(confirmOrderMsg),
    hideClosingCross: true,
    confirmButtonText: t.QVYK(confirmMsg),
    onConfirm: () => dispatch(submitMobileIdContract()),
    body: (
      <>
        <div className="ds-margin-vertical--2 ds-font-weight--bold ds-padding-top--1">
          {t.T425('It may take few minutes for the service to activate.')}
        </div>
        <div className="ds-margin-vertical--2">
          {t.VJI9(
            'Note that all the employees who have Elisa mobile subscription can take Elisa Mobiilivarmenne into use without separate approval.'
          )}
        </div>
        <div className="ds-margin-vertical--2">
          {t.GQBC('By confirming you will accept the ')}
          <a href="https://oma.elisa.fi/yrityksille/tilaukset">{t.BV5F('terms and conditions')}</a>.
        </div>
      </>
    ),
  };

  const onClickShowActiveContract = () => {
    if (activeContract?.contractNumber) {
      navigate(generatePath(paths.PS_CONTRACT_MOBILE_ID, { contractDisplayId: activeContract.contractNumber }));
    }
  };

  return (
    <>
      {subscriptions?.loadingContracts ? (
        <Loading />
      ) : (
        <>
          <div className="of-mobileid__title">{t.WFXG('Order Elisa Mobiilivarmenne for Employee service')}</div>
          <div className="ds-margin-vertical--4">
            {t.QSAJ('By providing ')}
            <a href="https://elisa.fi/mobiilivarmenne">{t.KM1R(mobiiliivarmenneTunnistauduMsg)}</a>
            {t.DZ5D(', for your employees you will enable strong authentication to be used in different services.')}
          </div>
          <div className="ds-margin-vertical--4">
            <span>{t.V72N(priceMsg)}</span>
            {': '}
            <span className="ds-font-weight--bold">{t.BJ25(mobiilivarmennePricePerUserMsg)}</span>
          </div>
          <div className="ds-margin-vertical--4">
            {t.JTZL('The service is charged in the same invoice than the actual subscription.')}
          </div>

          {activeContract ? (
            <>
              <hr className="ds-margin-vertical--4" />
              <div className="ds-margin-bottom--2 ds-display--flex">
                <CL.Icon icon="check" size="l" color="success" />{' '}
                <div className="ds-margin-top--1 ds-font-weight--bold">{t.EKK1('The service is active')} </div>
              </div>
              <CL.Button
                className="of-mobileid__thick-button"
                onClick={() => onClickShowActiveContract()}
                type="button"
              >
                {t.MIL5('See details')} {'\u27f6'}
              </CL.Button>
            </>
          ) : (
            <div className="of-mobileid__buttons ds-display--flex">
              <CL.Button
                className="ds-margin-right--1"
                id="mobileid-confirmation-confirm"
                onClick={() => dispatch(showDialog(confirmationDialogParams))}
              >
                {t.AQOL(orderMsg)}
              </CL.Button>
              <CL.Button
                className="ds-margin-left--2"
                id="mobileid-confirmation-cancel"
                onClick={onCancel}
                color="link"
              >
                {t.B2V1(cancelMsg)}
              </CL.Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export const MobileIdNewContent = ({ subscriptions }: MobileIdNewProps) => {
  const navigate = useNavigate();
  const { featureFlags } = useStore<State>().getState().config;

  if (!isFeatureEnabled(featureFlags.mobileId)) {
    return <MobileIdDisabled />;
  }

  const breadCrumbs = (
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.SW0J(contractsMsg), path: paths.PS_CONTRACTS },
        { name: 'Mobiilivarmenne' },
      ]}
    />
  );

  return (
    <DetailsWrapper
      classes={['of-subscription-details']}
      content={<MobileIdNew onCancel={() => navigate(paths.PS_CONTRACTS)} subscriptions={subscriptions} />}
      detailsTop={breadCrumbs}
      id="mobileid-confirmation"
      heading={t.XX5A(mobiilivarmenneForEmployeeMsg)}
      headingBottom={t.DA7N('Provide strong authentication tool for your employees')}
      heroIcon="mobiilivarmenne-hero"
    />
  );
};
