import { SimType } from '../../generated/api/models.js';
import { getSimTypeAsString } from '../../common/utils/simCardUtils.js';
import { simCardTypeMsg, t } from '../../common/i18n/index.js';

import './SimCardType.scss';

export interface SimCardTypeProps {
  simType?: SimType;
}

export const SimCardType = ({ simType }: SimCardTypeProps): JSX.Element => {
  const validSimType: boolean = simType !== undefined ? Object.values(SimType).includes(simType) : false;
  const simTypeClassName = validSimType ? undefined : 'of-sim-card-type--invalid-sim-type';

  return (
    <div id="sim-card-type" className="of-sim-card-type ds-padding-bottom--4">
      <label>{t.OLP0(simCardTypeMsg)}</label>
      <span className={simTypeClassName}>{getSimTypeAsString(simType)}</span>
    </div>
  );
};
