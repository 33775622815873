import * as React from 'react';
import { Loading } from '../../Loading/Loading.js';
import { onEnterOrSpaceKeyPress } from '../../../common/utils/handlerUtils.js';
import { t } from '../../../common/i18n/index.js';

export interface ReportItemProps {
  itemClass: string;
  heading: string;
  description: string;
  onClick: () => void;
  loading: boolean;
}

export const ReportItem = ({ itemClass, heading, description, onClick, loading }: ReportItemProps): JSX.Element => {
  return (
    <div className="of-report-list-item">
      <div className={`of-report-list-item__image ${itemClass}`} />
      <div className="of-report-list-item__title">
        <h1 className="ea-h3 ea-h3--thick">{heading}</h1>
      </div>
      <div className="of-report-list-item__description ea-disclaimertext ea-text--grey">{description}</div>
      <div className="of-report-list-item__footer">
        {loading ? (
          <Loading />
        ) : (
          <h3 className="ds-h3 ds-button ds-button--color-link">
            <span
              onClick={onClick}
              onKeyPress={(event: React.KeyboardEvent) =>
                onEnterOrSpaceKeyPress(event, () => {
                  onClick();
                })
              }
              role="button"
              tabIndex={0}
            >
              {t.Z9IV('Download report')}
            </span>
          </h3>
        )}
      </div>
    </div>
  );
};
