import { t } from '../../common/i18n/index.js';

interface InfoElementOnNeutralBackgroundProps {
  child: JSX.Element;
}

const InfoElementOnNeutralBackground = ({ child }: InfoElementOnNeutralBackgroundProps) => (
  <div className="search-info">
    <div className="search-info-text">{child}</div>
  </div>
);

export const SearchTip = () => (
  <InfoElementOnNeutralBackground
    child={
      <p className="ds-text--s">
        {t.SF9W(
          'You can search e.g. by name, phone number, identifier or billing account number. You can search with more than one keyword by separating the words with a space. Type at least three characters.'
        )}
      </p>
    }
  />
);

export interface NoResultsAndSearchTipProps {
  searchTermUsed: string;
}

export const NoResultsAndSearchTip = ({ searchTermUsed }: NoResultsAndSearchTipProps) => {
  return (
    <>
      <div className="no-results">
        {t.O4R1('No results were found for')} <span className="ds-font-weight--bold">{searchTermUsed}</span>
        {t.FXKW('.')}
      </div>
      <SearchTip />
    </>
  );
};

export interface TooMuchResultsProps {
  isMultiBiz?: boolean;
}

export const TooMuchResults = ({ isMultiBiz }: TooMuchResultsProps) => (
  <div className="too-much-results">
    <InfoElementOnNeutralBackground
      child={
        <>
          <h5>
            {isMultiBiz
              ? t.L7CX('There are too many search results. Refine your search.')
              : t.HD6M('There are too many search results.')}
          </h5>
          <p className="ds-text--s">
            {isMultiBiz
              ? t.L7DX('You can search with more than one keyword by separating the words with a space.')
              : t.B0MA('Refine your search or view all results through the button below.')}
          </p>
        </>
      }
    />
  </div>
);
