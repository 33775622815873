import * as CL from '@design-system/component-library';
import { CommercialProductType } from '../../generated/api/commercialProductType.js';
import { LoginBanner } from './LoginBanner.js';
import { Picture } from '../Picture/Picture.js';
import { SelectCompany } from './SelectCompany.js';
import { Voucher } from './Voucher.js';
import {
  activationFeeMsg,
  addMsg,
  additionalServicesMsg,
  cartIsEmptyMsg,
  checkoutMsg,
  continueShoppingMsg,
  contractPriceCapitalizedMsg,
  oneTimePaymentMsg,
  paymentPeriodMsg,
  quantityMsg,
  removeMsg,
  shoppingCartContentPluralMsg,
  shoppingCartContentSingularMsg,
  shoppingCartMsg,
  subtractMsg,
  t,
  totalMsg,
  totalWithCurlyBracketsMsg,
  vatPercentageMsg,
} from '../../common/i18n/index.js';
import {
  calculateTotalPrices,
  getPaymentOptions,
  getPriceForGuid,
  getPriceForGuidToDisplay,
  getPriceToDisplay,
} from './shoppingCartUtils.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import {
  getAddonsDisplayData,
  getTotalProductQuantity,
} from '../../selfservice/common/shopping-cart/shoppingCartFunctions.js';
import { getPriceForSalesProduct } from './ShoppingCartWithVoucherUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { DiscountedPrices } from '../../generated/api/discountedPrices.js';
import type { OnlineModel } from '../../generated/api/onlineModel.js';
import type { OnlineModelsResponse } from '../../generated/api/onlineModelsResponse.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';
import type CLT from '@design-system/component-library';

import './ShoppingCartWithVoucher.scss';

const getProductImage = (productName: string, imageUrl: string): JSX.Element => {
  return (
    <Picture alt={productName} offerWidthAlternatives={[128]} renderedImageSize={{ onPhone: '64px' }} src={imageUrl} />
  );
};

export interface ShoppingCartWithVoucherProps {
  cartItems: ShoppingCartItemForCheckout[];
  discountedPrices: DiscountedPrices[];
  models: OnlineModel[];
  onlineModelsHeadersForSalesProducts: OnlineModelsResponse;
  loggedIn: boolean;
  userAccounts: CLT.HeaderUserAccount[];
}

const mapItems = (
  cartItems: ShoppingCartItemForCheckout[],
  models: OnlineModel[],
  onlineModelsHeadersForSalesProducts: OnlineModelsResponse,
  discountedPrices: DiscountedPrices[],
  loggedIn: boolean
) => {
  return cartItems.map((cartItem): CL.ShoppingCartItem => {
    const onlineModelForItem = models?.find(model => model.onlineModelCode === cartItem.onlineModelCode);
    // Customer level price
    const discountedPricesForModel = discountedPrices?.find(
      priceEntry => priceEntry.model === cartItem.onlineModelCode
    );

    const isSalesProduct = onlineModelForItem?.category === CommercialProductType.SALES_PRODUCT;
    const priceData = isSalesProduct
      ? getPriceForSalesProduct(cartItem, onlineModelsHeadersForSalesProducts)
      : getPriceForGuid(cartItem.price.guid, onlineModelForItem, discountedPrices);

    const hasOneTimeCharge = !!priceData?.price?.oneTimeCharge;

    const priceDisplayData = isSalesProduct
      ? {
          shoppingCartPrice: getPriceToDisplay(priceData?.price, cartItem.quantity),
        }
      : getPriceForGuidToDisplay(cartItem.price.guid, onlineModelForItem, discountedPricesForModel, cartItem.quantity);

    const formatOneTimeCharge = formatSum(priceData?.price?.oneTimeCharge) || '';
    const oneTimePaymentText = isSalesProduct
      ? cartItem.quantity > 1
        ? t.HWDV(activationFeeMsg, formatOneTimeCharge)
        : t.HWDU(activationFeeMsg, formatOneTimeCharge)
      : t.ASEI(oneTimePaymentMsg);

    // Corporate VAT is 0
    // NOTE: elements 1-3 for pricing texts, after those badges (orange, turquoise, and rest light-blue), use empty string to skip
    const oneTimeChargeFields = ['', oneTimePaymentText, t.A0OJ(vatPercentageMsg, '0')];
    const payments = priceData?.price?.payments || 0;
    const monthlyRecurringFields = [
      t.CF93('{} month agreement', String(payments)),
      t.W1RX(totalWithCurlyBracketsMsg, formatSum(Number(priceData?.price?.monthlyRecurringCharge) * payments) || ''),
      t.A0OJ(vatPercentageMsg, '0'),
    ];

    const badges = priceDisplayData.isDiscountedPrice ? [t.JPKP(contractPriceCapitalizedMsg)] : [];

    const disclaimerFields = [
      ...(hasOneTimeCharge ? oneTimeChargeFields : []),
      ...(!hasOneTimeCharge ? monthlyRecurringFields : []),
      ...badges,
    ];

    const paymentOptions = getPaymentOptions(cartItem.price.guid, onlineModelForItem, loggedIn, discountedPrices);

    return {
      addons: getAddonsDisplayData(cartItem.selectedAddOns, cartItem.quantity),
      id: cartItem.id,
      image: getProductImage(cartItem.productName, cartItem.imageListingUrl),
      name: cartItem.productName,
      disclaimer: disclaimerFields,
      paymentOptions: isSalesProduct ? [] : paymentOptions,
      price: priceDisplayData.shoppingCartPrice,
      quantity: cartItem.quantity,
      url: onlineModelForItem?.pagePath,
    };
  });
};

export const ShoppingCartWithVoucher = ({
  cartItems,
  discountedPrices,
  models,
  onlineModelsHeadersForSalesProducts,
  loggedIn,
  userAccounts,
}: ShoppingCartWithVoucherProps) => {
  const totalProductQuantity = getTotalProductQuantity(cartItems);

  return (
    <>
      {loggedIn && <SelectCompany userAccounts={userAccounts} />}
      <div className="of-shopping-cart-wrapper">
        {!loggedIn && (
          <div className="of-login-banner">
            <LoginBanner />
          </div>
        )}
        <CL.ShoppingCart
          ariaAddonsLabel={t.LXSR(additionalServicesMsg)}
          ariaPaymentLabel={t.EM2Q(paymentPeriodMsg)}
          ariaQuantityDeleteLabel={t.R3VE(removeMsg)}
          ariaQuantityLabel={t.M0W7(quantityMsg)}
          ariaQuantityMinusLabel={t.C2KQ(subtractMsg)}
          ariaQuantityPlusLabel={t.VKFM(addMsg)}
          ariaTotalsLabel={t.CEQ2(totalMsg)}
          checkoutUrl={paths.DEVICE_CHECKOUT}
          i18nCheckoutLabel={t.UAAP(checkoutMsg)}
          i18nContinueLabel={t.VLZR(continueShoppingMsg)}
          i18nEmptyLabel={t.PRFW(cartIsEmptyMsg)}
          i18nHeading={t.BE8Q(shoppingCartMsg)}
          // TODO: cancelUrl={previousPathname}
          caption={
            totalProductQuantity === 1
              ? t.I23L(shoppingCartContentSingularMsg)
              : t.PS1E(shoppingCartContentPluralMsg, `${totalProductQuantity}`)
          }
          items={mapItems(cartItems, models, onlineModelsHeadersForSalesProducts, discountedPrices, loggedIn)}
          onPaymentOptionChange={() => alert('onPaymentOptionChange()')}
          onQuantityChange={() => alert('onQuantityChange()')}
          totals={calculateTotalPrices(cartItems, models, onlineModelsHeadersForSalesProducts, discountedPrices)}
          totalProductQuantity={totalProductQuantity}
        />
        <Voucher
          onDisableClick={() => {
            alert('Disable clicked');
          }}
        />
      </div>
    </>
  );
};
