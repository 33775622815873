import { AddonDetails } from '../../../../../components/AddonDetails/AddonDetails.js';
import { BreadCrumbs } from '../../../../../components/BreadCrumbs/index.js';
import { SubscriptionCategory } from '../../../../../common/enums.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { findSubscription } from '../../../../../common/utils/subscriptionUtils.js';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { internetSubscriptionsMsg, omaElisaForCompaniesMsg, t } from '../../../../../common/i18n/index.js';
import {
  loadBillChannels,
  loadSubscriptionAddOnRules,
  loadSubscriptions,
} from '../../../../../selfservice/actions/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { AddonId, SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const BroadbandSubItemPath = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { subscriptionId, addonId } = useParams<SubscriptionId & AddonId>();
  const subscriptionActions = useSelector((state: State) => state?.selfservice?.subscriptionActions, deepEqual);
  const subs = useSelector((state: State) => state.selfservice?.subscriptions?.broadband || undefined, deepEqual);

  useEffect(() => {
    dispatch(loadSubscriptions({ category: SubscriptionCategory.BROADBAND, displayId: subscriptionId }));
    dispatch(loadBillChannels());
    dispatch(loadSubscriptionAddOnRules());
  }, [dispatch, pathname, subscriptionId]);

  if (!subscriptionId || !addonId) {
    return null;
  }

  const subscription = findSubscription(subscriptionId, subs);
  const addOn = (subscription?.details?.selectedAddOns || []).find(it => it.addOnCode === addonId);

  return (
    <AddonDetails
      addOn={addOn}
      breadCrumbs={
        <BreadCrumbs
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.SEYV(internetSubscriptionsMsg), path: paths.PS_BROADBAND_SUBSCRIPTIONS },
            { name: subscriptionId, path: generatePath(paths.PS_BROADBAND_SUBSCRIPTION, { subscriptionId }) },
            { name: addOn?.addOnProductName || '...' },
          ]}
        />
      }
      subscription={subscription}
      subscriptionActions={subscriptionActions?.items}
      subscriptionPath={paths.PS_BROADBAND_SUBSCRIPTION}
      subscriptionsState={subs}
    />
  );
};
