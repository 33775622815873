import { ActionPhase } from '../common/storeUtils.js';
import { AuthenticatedUserRole } from '../../generated/api/models.js';
import { getActiveAccountMasterId } from '../common/localStorageUtils.js';
import { getLocale } from '../../common/i18n/index.js';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { of } from 'rxjs';
import { paths } from '../../common/constants/pathVariables.js';
import type { ActionState, ActionWithId, ActionsHistory, ItemsQuery, State } from '../common/store.js';
import type { AjaxError } from 'rxjs/ajax';
import type { ErrorAction, ErrorActionCreator, TypeKeys } from '../actions/index.js';
import type { Observable } from 'rxjs';
import type { StateObservable } from 'redux-observable';

/**
 * Separate http calls to dependencies so they can be injected manually when testing.
 */
export interface EpicDependencies {
  get<T>(url: string, headers?: Record<string, string>): Observable<T>;
  getJSON<T>(url: string, headers?: Record<string, string>): Observable<T>;
  post<T>(url: string, body?: object, headers?: Record<string, string>): Observable<T>;
  put<T>(url: string, body?: object, headers?: Record<string, string>): Observable<T>;
  delete<T>(url: string, body?: object, headers?: Record<string, string>): Observable<T>;
}

export interface ActionAndState {
  action: ActionWithId;
  state?: ActionState;
}

const getFromState = (state$: StateObservable<State>, key: string): object | undefined => {
  // @ts-ignore
  if (state$ && state$.value.selfservice && state$.value.selfservice[key]) {
    // @ts-ignore
    return state$.value.selfservice[key];
    // @ts-ignore
  } else if (state$ && state$.value.user && state$.value.user[key]) {
    // @ts-ignore
    return state$.value.user[key];
    // @ts-ignore
  } else if (state$ && state$.value.authentication && state$.value.authentication[key]) {
    // @ts-ignore
    return state$.value.authentication[key];
    // @ts-ignore
  } else if (state$ && state$.value[key]) {
    // @ts-ignore
    return state$.value[key];
  }
  return undefined;
};

export const getActionState = (
  action: ActionWithId,
  state: ActionsHistory | undefined | null
): ActionState | undefined => {
  if (state && state.actions) {
    return state.actions.find((previousActionState: ActionState) => previousActionState.value.id === action.id);
  }
  return undefined;
};

// TODO: this can be generic, just requires some refactoring. Then we can get rid off some casting stuff in epics.
// function actionToActionState<T extends ActionWithId>(action: T, state$: StateObservable<State>, key: string): ActionAndState<T> {
export function actionToActionState(action: ActionWithId, state$: StateObservable<State>, key: string): ActionAndState {
  const selfserviceState = getFromState(state$, key);
  let actionState: ActionState | undefined;
  if (selfserviceState) {
    actionState = getActionState(action, selfserviceState as ActionsHistory);
  }
  return { action: action, state: actionState };
}

export const getActionStateFromMultiple = (
  action: ActionWithId,
  category: string,
  states: { [s: string]: ActionsHistory | undefined | null }
) => {
  // eslint-disable-next-line no-prototype-builtins
  if (states && states.hasOwnProperty(category)) {
    const actionState = getActionState(action, states[category]);
    if (actionState) {
      return actionState;
    }
  }
  return undefined;
};

export function actionToActionStateFromMultiple(
  action: ActionWithId,
  state$: StateObservable<State>,
  key: string,
  category: string
): ActionAndState {
  const selfserviceState = getFromState(state$, key);
  let actionState: ActionState | undefined;
  if (selfserviceState) {
    actionState = getActionStateFromMultiple(
      action,
      category,
      selfserviceState as {
        [s: string]: ActionsHistory;
      }
    );
  }
  return { action: action, state: actionState };
}

export function requireStateInProgress(actionAndState: ActionAndState) {
  return actionAndState.state !== undefined && actionAndState.state.phase === ActionPhase.IN_PROGRESS;
}

export const getOnboardingId = () => (isInBrowser() ? sessionStorage.getItem('onboardingId') : undefined);

export const clearOnboardingId = () => {
  if (isInBrowser()) {
    sessionStorage.removeItem('onboardingId');
  }
};

export const getAccountMasterIdHeader = (state$: StateObservable<State>): Record<string, string> | undefined => {
  const activeAccountMasterId = getActiveAccountMasterId();
  const accountMasterId = activeAccountMasterId || state$.value.user?.authenticated?.mdmId;

  if (accountMasterId) {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-API-Account-Master-ID': accountMasterId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-Elisa-Company-MDM-ID': accountMasterId,
    };
  }

  return undefined;
};

export const getJsonPayloadHeaders = (state$: StateObservable<State>): Record<string, string> | undefined => {
  const jsonContentType = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Content-Type': 'application/json',
  };
  return { ...jsonContentType, ...getAccountMasterIdHeader(state$) };
};

export function getError<T extends TypeKeys>(
  error: AjaxError,
  errorActionCreator: ErrorActionCreator<T>,
  params?: { [s: string]: string }
): Observable<ErrorAction<T>> {
  return of(errorActionCreator(error.message, error.status, error.response, params));
}

export const itemsQueryToQueryParams = ({
  offset,
  limit,
  order,
  sort,
}: ItemsQuery): { [s: string]: string | number } => {
  const queryParams: { [s: string]: string | number } = {};
  queryParams.offset = offset !== undefined ? offset : 0;
  if (limit) {
    queryParams.limit = limit;
  }
  if (order) {
    queryParams.order = order;
  }
  if (sort) {
    queryParams.sort = sort;
  }
  return queryParams;
};

export const UI_API_PRIVATE_BASE_PATH = '/api/ui/v2/private';

export const isNewDeviceCheckout = (path?: string) => (path ? path.startsWith('/kassa') : false);

export const isAuxiliaryEsimOrderPage = (path?: string) =>
  path ? path.startsWith(`${paths.SELF_SERVICE_HOME}/multisim`) : false;

export const isEmployeePortal = (path?: string) => (path ? decodeURI(path).startsWith(paths.EMPLOYEE_HOME) : false);

export const isPunchout = (path?: string) => (path ? path.startsWith(paths.PUNCHOUT_HOME) : false);

// This method determines the override user role. For example if a user has access to both NOE and EOE,
// then role in Salesforce is KEY_USER (Admin profile). When such a user accesses EOE, then override
// role should be EMPLOYEE so that user gets only employee relevant stuff from APIs
export const getUserRoleHeader = (state$: StateObservable<State>): Record<string, string> => {
  const path = window.location.pathname;
  const userRole = state$.value.user?.authenticated?.userRole;
  return {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'X-API-User-Role':
      isEmployeePortal(path) || isAuxiliaryEsimOrderPage(path)
        ? AuthenticatedUserRole.EMPLOYEE
        : isPunchout(path)
        ? AuthenticatedUserRole.PUNCHOUT_USER
        : userRole || AuthenticatedUserRole.KEY_USER,
  };
};

export const getLocaleHeader = (): { [s: string]: string } => {
  return {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Accept-Language': getLocale(),
  };
};

export const resolveStatusCode = (pagePath = '/404', pageStatusCode = 404) => {
  // Path: /404 returns 200 and valid content from UI-API. Therefore fake /404 with status code 404
  if (pagePath === paths.NOT_FOUND_404) {
    return 404;
  }
  // 204's coming from UI-API will be converted to 301's
  if (pageStatusCode === 204) {
    return 301;
  }
  return pageStatusCode;
};
