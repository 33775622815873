import * as CL from '@design-system/component-library';
import { t } from '../../../common/i18n/index.js';

export const ProductDetailsPriceDisclaimer = () => {
  return (
    <span className="ds-display--flex ds-margin-top--3">
      <span>
        <CL.Icon icon="information" size="m" type="regular" color="white--dark" />
      </span>
      <span className="ds-margin-left--2">
        {t.COPO(
          'The charge does not include the redemption price if you decide to redeem the device after the end of the contract period.'
        )}
      </span>
    </span>
  );
};
