import { DeviceReport } from '../common/DeviceReport.js';
import { elisaDevicesServiceMsg, t } from '../../../common/i18n/index.js';
import { getEppComputerReport } from './eppComputerReportUtil.js';

export const EppComputerReport = () => {
  return (
    <DeviceReport
      prepareReport={getEppComputerReport}
      fileName="EppComputerReport.csv"
      itemClass="ea-picto--epp-computer"
      heading={t.TRE5(elisaDevicesServiceMsg)}
      description={t.D1EO('This report covers the computers used in your company’s Elisa Devices Service')}
    />
  );
};
