import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
  filterAccountKeyUsers,
  findFromPbxSolutionsByType,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { RingAdminDetails } from '../../../../../components/RingAdminDetails/RingAdminDetails.js';
import { SubscriptionType } from '../../../../../generated/api/models.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { findItemById, stripActions } from '../../../../../common/utils/stateUtils.js';
import { getSubscriptionStatus } from '../../../../../common/utils/subscriptionUtils.js';
import { loadContacts } from '../../../../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import type { AccountKeyUser } from '../../../../../generated/api/models.js';
import type { State } from '../../../../../selfservice/common/store.js';
import type { UserName } from '../../../../../common/constants/pathInterfaces.js';

export const CompanyInfoRingAdminNewUserPath = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { userName } = useParams<UserName>();
  const adminId = 'add-new-ring-admin';
  const accountKeyUsers = useSelector((state: State) => state.selfservice?.accountKeyUsers, deepEqual);
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  const contacts = useSelector((state: State) => stripActions(state.selfservice?.contacts), deepEqual);
  const ringSubscriptions = companyInfo ? findFromPbxSolutionsByType(companyInfo, SubscriptionType.MOBILE_PBX) : [];
  const subscriptionActions = useSelector((state: State) => state.selfservice?.pendingSubscriptionActions, deepEqual);
  const ringAdminContact = findItemById('contactId', adminId, contacts);
  const subscriptionIds = (ringSubscriptions || []).map(subscription => subscription.subscriptionId);
  const newAccountKeyUsers: AccountKeyUser[] | undefined = filterAccountKeyUsers(
    contacts ? contacts : undefined,
    accountKeyUsers ? accountKeyUsers : undefined
  );
  const newAccountKeyUser =
    newAccountKeyUsers && userName ? newAccountKeyUsers.filter(u => u.userName === userName).shift() : undefined;

  useEffect(() => {
    dispatch(loadContacts());
  }, [dispatch, pathname]);

  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.USERS_NOE} isSingular={true}>
      {ringSubscriptions && ringSubscriptions.length > 0 && (
        <RingAdminDetails
          ringAdminContact={ringAdminContact}
          newAccountKeyUser={newAccountKeyUser}
          subscriptionIds={subscriptionIds}
          errors={contacts ? contacts.errors : undefined}
          pendingSubscriptionChanges={
            getSubscriptionStatus(ringSubscriptions[0], subscriptionActions?.items).pendingActions
          }
        />
      )}
    </CompanyInfoContainer>
  );
};
