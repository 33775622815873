import * as CL from '@design-system/component-library';
import { myAccountMsg, t } from '../../common/i18n/index.js';
import type { SupportCaseContactInfo } from './types.js';

export interface PrefilledContactSupportDetailsProps {
  contactInfo: SupportCaseContactInfo;
  consolidatedViewsEnabled?: boolean;
}

/**
 * Contact details of a user
 *
 * These are prefilled and not part of the form as we likely
 * get them from user's account details.
 */
export const PrefilledContactSupportDetails = ({
  contactInfo,
  consolidatedViewsEnabled,
}: PrefilledContactSupportDetailsProps): JSX.Element => (
  <div className="ds-padding-bottom--6">
    {!consolidatedViewsEnabled ? (
      <div className="contact-info_company-name">
        <strong>{contactInfo.businessName}</strong>
      </div>
    ) : (
      <div>
        <CL.Label>{t.SEBD(myAccountMsg)}</CL.Label>
      </div>
    )}
    <div>{`${contactInfo.firstName} ${contactInfo.lastName}`}</div>
    <div>{contactInfo.email}</div>
    <div>{contactInfo.phoneNumber}</div>
    {!consolidatedViewsEnabled && <div>{contactInfo.businessId}</div>}
  </div>
);
