import { AuthenticationLevel } from '../../../../generated/api/models.js';
import {
  DEFAULT_ITEMS_PER_PAGE,
  type DefaultListSearchParams,
  ListPagination,
  ListSearch,
  Table,
} from '../../../Table/index.js';
import { DnsDomainDisclaimerMfaRequired } from './DnsDomainDisclaimerMfaRequired.js';
import { Grid } from '../../../Grid/Grid.js';
import { Link, generatePath } from 'react-router-dom';
import { ShowAllAccountsToggleForListViews } from '../../../ShowAllAccountsToggle/ShowAllAccountsToggleForListViews.js';
import { billingAccountMsg, companyMsg, t } from '../../../../common/i18n/index.js';
import { getArrayPropertySorter } from '../../../../common/utils/arrayUtils.js';
import { getCompanyName, isMultiBiz } from '../../../../common/utils/accountUtils.js';
import { paths } from '../../../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../../../Table/tableUtils.js';
import { tableSearchRow } from '../../../Table/TableDataUtil.js';
import { useMultiAccountRowClick } from '../../../../common/hooks/useMultiAccountRowClick.js';
import { useSearchParams } from '../../../../common/hooks/useSearchParams.js';
import type * as CL from '@design-system/component-library';
import type { AuthenticatedUserState } from '../../../../common/types/states.js';
import type { DnsRecordsSearchParams } from '../../dnsManagementUtils.js';
import type { SubscriptionSearchResponse } from '../../../../generated/api/models.js';

export const DnsDomainList = ({
  searchResults = [],
  total = 0,
  authenticatedUser,
}: {
  searchResults?: SubscriptionSearchResponse[];
  total?: number;
  authenticatedUser?: AuthenticatedUserState;
}) => {
  const { sort, search, order } = useSearchParams<DnsRecordsSearchParams>();
  const onRowClick = useMultiAccountRowClick();
  const searchParams = useSearchParams<DefaultListSearchParams>();
  const mfaEnabled = authenticatedUser?.authenticationLevel === AuthenticationLevel.TWO_FACTOR;

  const columns = [
    { key: 'subscriptionName', label: t.ES5D('Domain'), sortable: true },
    { key: 'subscriptionId', label: t.YTH3('ID Number'), sortable: true },
    { key: 'oldContractId', label: t.Z223('Billing code'), sortable: true },
    { key: 'billingAccountDisplayId', label: t.IFT9(billingAccountMsg), sortable: true },
    { key: 'company', label: t.KJTS(companyMsg) },
  ] satisfies CL.Column[];

  const rows = searchResults
    .map(({ result }) => ({
      subscriptionName: result.subscriptionName,
      subscriptionId: result.subscriptionId,
      oldContractId: result.oldContractId,
      billingAccountDisplayId: result.billingAccountDisplayId,
      accountMasterId: result.accountMasterId,
    }))
    .filter(row => tableSearchRow(search, row))
    .sort(getArrayPropertySorter(sort, order))
    .map(row => ({
      subscriptionName: !mfaEnabled ? (
        row.subscriptionName
      ) : (
        <Link
          to={generatePath(paths.PS_ELISA_DNS_DOMAIN, { subscriptionId: row.subscriptionId })}
          onClick={event => {
            event.preventDefault();
            onRowClick(
              generatePath(paths.PS_ELISA_DNS_DOMAIN, {
                subscriptionId: row.subscriptionId,
              }),
              getCompanyName(authenticatedUser, row.accountMasterId),
              row.accountMasterId
            );
          }}
        >
          {row.subscriptionName}
        </Link>
      ),
      subscriptionId: row.subscriptionId,
      oldContractId: row.oldContractId,
      billingAccountDisplayId: row.billingAccountDisplayId,
      company: getCompanyName(authenticatedUser, row.accountMasterId),
    })) satisfies CL.KeyRow[];

  return (
    <Grid>
      <div className="ds-margin-vertical--3">
        {!mfaEnabled && <DnsDomainDisclaimerMfaRequired href="/yhteystiedot" />}
        {isMultiBiz(authenticatedUser) && (
          <ShowAllAccountsToggleForListViews className="ds-margin-top--4 ds-margin-bottom--4" />
        )}
        <ListSearch
          className="ds-margin-top--4"
          onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
        />
        <Table columns={columns} noItemsText={t.VA1O('No records')} rows={rows} />
        <ListPagination totalItems={total} />
      </div>
    </Grid>
  );
};
