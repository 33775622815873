import { Checkbox } from '../../common/react-hook-form/components/Checkbox.js';
import { Email } from '../../common/react-hook-form/fields/Email.js';
import { PhoneNumberOwnerType } from '../../common/enums.js';
import { SelectRadio } from '../../common/react-hook-form/components/SelectRadio.js';
import { t } from '../../common/i18n/index.js';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import type { PhoneNumberOwner } from '../../common/types/subscription.js';

import './NumberOwnerRHF.scss';

export interface NumberOwnerProps {
  phoneNumberOwner?: PhoneNumberOwner;
  companyName: string;
  cartItemInstanceId: string;
  topPaddingClassName?: 'ds-padding-top--2' | 'ds-padding-top--4';
  headerLevel?: 'h4' | 'h5';
}

export const NumberOwnerRHF = ({
  phoneNumberOwner,
  cartItemInstanceId,
  companyName,
  topPaddingClassName = 'ds-padding-top--2',
  headerLevel = 'h4',
}: NumberOwnerProps) => {
  const Header = headerLevel;

  const { watch, setValue } = useFormContext();
  const numberOwnerType = watch(`configuration.${cartItemInstanceId}.selectPhoneNumber.numberOwner.type`);
  useEffect(() => {
    if (numberOwnerType === undefined) {
      // TODO watch is not able to see Controller defaultValue, so need to update it
      setValue(`configuration.${cartItemInstanceId}.selectPhoneNumber.numberOwner.type`, PhoneNumberOwnerType.PERSON);
    }
  }, [numberOwnerType, cartItemInstanceId, setValue]);

  return (
    <div className={`phone-number-owner ${topPaddingClassName || ''}`} id="number-privacy">
      <Header className="ds-margin-bottom--3">{t.O6Q1('Current owner of number?')}</Header>
      <SelectRadio
        name={`configuration.${cartItemInstanceId}.selectPhoneNumber.numberOwner.type`}
        defaultValue={phoneNumberOwner?.ownerType || PhoneNumberOwnerType.PERSON}
        items={[
          {
            label: t.PCB2('Person'),
            value: PhoneNumberOwnerType.PERSON,
          },
          {
            label: companyName,
            value: PhoneNumberOwnerType.COMPANY,
          },
        ]}
      />
      {numberOwnerType === PhoneNumberOwnerType.PERSON && (
        <div className="phone-number-owner-rhf-person-email">
          <Email
            name={`configuration.${cartItemInstanceId}.selectPhoneNumber.numberOwner.personEmail`}
            hint={t.P5H4(
              'A verification request will be sent to the address given. The user accepts the number transfer, by using online banking credentials for authentication.'
            )}
            label=""
          />
        </div>
      )}
      {numberOwnerType === PhoneNumberOwnerType.COMPANY && (
        <Checkbox
          name={`configuration.${cartItemInstanceId}.selectPhoneNumber.numberOwner.permitDonoringOnFixedContract`}
          label={t.KD2L(
            'I give permission for the transfer of my number, even though a fixed-term contract may be ongoing.'
          )}
        />
      )}
    </div>
  );
};
