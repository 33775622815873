import { BreadCrumbs } from '../BreadCrumbs/BreadCrumbs.js';
import { Icon } from '../Icon/Icon.js';
import {
  contactInfoMsg,
  corporateCustomersMsg,
  leaveMessageMsg,
  supportCaseThankYouInfoMsg,
  supportCaseThankYouMsg,
  t,
} from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setCaseSentStatus } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import './NewSupportCasePublicThankYou.scss';

export const NewSupportCasePublicThankYou = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCaseSentStatus(false));
  }, [dispatch]);

  return (
    <>
      <BreadCrumbs
        breadCrumbPaths={[
          { name: t.KBLX(corporateCustomersMsg), path: '/' },
          { name: t.GJS9(contactInfoMsg), path: paths.CONTACT_INFO },
          { name: t.WVNV(leaveMessageMsg), path: paths.CONTACT },
          { name: t.CBCC('Thank you for the message') },
        ]}
      />
      <div className="of-support-case-public-thank-you ds-margin-horizontal--5">
        <h2 className="ds-padding-left--6">
          <Icon color="green-600" icon="check" size="l" /> {t.JUCA(supportCaseThankYouMsg)}
        </h2>
        <p>{t.AFXP(supportCaseThankYouInfoMsg)}</p>
      </div>
    </>
  );
};
