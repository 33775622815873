import * as CL from '@design-system/component-library';
import { GridColumn } from '../BillingAccountDetails/components/GridColumn.js';
import { SubscriptionDetailsMobile } from '../../generated/api/subscriptionDetailsMobile.js';
import {
  costCenterAndReferenceMsg,
  costCenterMsg,
  editMsg,
  listedNumberMsg,
  noUserOrPurposeOfUseSetMsg,
  notToDirectoryMsg,
  numberDirectoryStatusMsg,
  purposeOfUseMsg,
  referenceIdMsg,
  t,
  unlistedMsg,
  userTextMsg,
} from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { Contact } from '../../generated/api/contact.js';
import type { DirectoryDetails } from '../../generated/api/directoryDetails.js';
import type { PurposeOfUseOrContact } from '../../common/types/subscription.js';

interface UserNumberAndPublicityViewProps {
  contacts?: Contact[];
  purposeOfUseOrContact: PurposeOfUseOrContact;
  numberPrivacyMobile?: DirectoryDetails.NumberDirectoryEnum;
  numberPrivacyCorporate?: DirectoryDetails.NumberDirectoryEnum;
  onEdit: () => void;
  pbxType?: string;
  showBroadbandInvitationInfo?: boolean;
}

const getUserInfo = (selectedContact?: Contact) => {
  if (selectedContact?.person) {
    return {
      firstName: selectedContact.person.firstName,
      lastName: selectedContact.person.lastName,
      email: selectedContact.person.email,
    };
  } else if (selectedContact?.userContact) {
    return {
      firstName: selectedContact.userContact.firstName,
      lastName: selectedContact.userContact.lastName,
      email: selectedContact.userContact.email,
    };
  }

  return undefined;
};

const directoryListingViewOptions = [
  {
    displayValue: t.KC80(notToDirectoryMsg),
    value: SubscriptionDetailsMobile.DirectoryListingEnum.NOT_TO_DIRECTORY,
  },
  {
    displayValue: t.ZGKE(unlistedMsg),
    value: SubscriptionDetailsMobile.DirectoryListingEnum.SECRET,
  },
  {
    displayValue: t.SCL4(listedNumberMsg),
    value: SubscriptionDetailsMobile.DirectoryListingEnum.CUSTOM,
  },
  {
    displayValue: t.SCL4(listedNumberMsg),
    value: SubscriptionDetailsMobile.DirectoryListingEnum.PUBLIC_DIRECTORY,
  },
];

export const UserNumberAndPublicityView = ({
  contacts,
  purposeOfUseOrContact,
  onEdit,
  numberPrivacyMobile,
  numberPrivacyCorporate,
  pbxType,
  showBroadbandInvitationInfo,
}: UserNumberAndPublicityViewProps) => {
  const selectedContact = contacts?.find(contact => contact.contactId === purposeOfUseOrContact.contactId);
  const userInfo = getUserInfo(selectedContact);

  const purposeOfUse = purposeOfUseOrContact.purposeOfUse;

  const purposeOfUseleLabel = purposeOfUseOrContact.purposeOfUse
    ? t.Y8KX(purposeOfUseMsg)
    : t.NE0O(noUserOrPurposeOfUseSetMsg);

  return (
    <CL.Grid className="ds-margin-top--4">
      {selectedContact ? (
        <CL.GridRow className="of-purpose-of-use__grid_row of-purpose-of-use__cost_center_employee_number">
          <GridColumn className="of-purpose-of-use__grid_col">
            <div>
              <label className="ds-font-style--normal ds-font-weight--bold">{t.LUZM(userTextMsg)}</label>
            </div>
            <div>
              <span>{`${userInfo?.firstName?.concat(' ') || ''}${userInfo?.lastName || ''}`}</span>
            </div>
            {showBroadbandInvitationInfo && (
              <>
                <div>
                  <span>{`${userInfo?.email || ''}`}</span>
                </div>
                <div>
                  <a href={paths.COMPANY_INFO_CONTACTS}>{t.INTO('User information details')}</a>
                </div>
              </>
            )}
          </GridColumn>
        </CL.GridRow>
      ) : (
        <CL.GridRow className="of-purpose-of-use__grid_row of-purpose-of-use__cost_center_employee_number">
          <GridColumn className="of-purpose-of-use__grid_col">
            <div>
              <label className="ds-font-style--normal ds-font-weight--bold">{purposeOfUseleLabel}</label>
            </div>
            <div>
              <span>{purposeOfUse}</span>
            </div>
          </GridColumn>
        </CL.GridRow>
      )}
      <h4 className="ds-margin-bottom--1 ds-margin-top--6">{t.JBEM(costCenterAndReferenceMsg)}</h4>
      <CL.GridRow className="of-purpose-of-use__grid_row of-purpose-of-use__cost_center_employee_number ds-padding-top--2">
        <GridColumn className="of-purpose-of-use__grid_col">
          <div>
            <label className="ds-font-style--normal ds-font-weight--bold">{t.QJUW(costCenterMsg)}</label>
          </div>
          <div>
            <span>{purposeOfUseOrContact.costCenter || '-'}</span>
          </div>
        </GridColumn>
        <GridColumn className="of-purpose-of-use__grid_col">
          <div>
            <label className="ds-font-style--normal ds-font-weight--bold">{t.CWR5(referenceIdMsg)}</label>
          </div>
          <div>
            <span>{purposeOfUseOrContact.employeeNumber || '-'}</span>
          </div>
        </GridColumn>
      </CL.GridRow>
      <CL.GridRow className="of-purpose-of-use__grid_row of-purpose-of-use__cost_center_employee_number">
        {numberPrivacyMobile && (
          <GridColumn className="of-purpose-of-use__grid_col ds-padding-top--8">
            <div>
              <label className="ds-font-style--normal ds-font-weight--bold">
                {t.L8XT(numberDirectoryStatusMsg)} ({t.OWF1('Mobile phone')})
              </label>
            </div>
            <div>
              <span>
                {directoryListingViewOptions.find(option => option.value === numberPrivacyMobile)?.displayValue}
              </span>
            </div>
          </GridColumn>
        )}
        {numberPrivacyCorporate && pbxType && (
          <GridColumn className="of-purpose-of-use__grid_col ds-padding-top--8">
            <div>
              <label className="ds-font-style--normal ds-font-weight--bold">
                {t.L8XT(numberDirectoryStatusMsg)} ({t.WL8J('Corporate phone')})
              </label>
            </div>
            <div>
              <span>
                {directoryListingViewOptions.find(option => option.value === numberPrivacyCorporate)?.displayValue}
              </span>
            </div>
          </GridColumn>
        )}
      </CL.GridRow>
      <CL.GridRow className="of-purpose-of-use__grid_row of-purpose-of-use__cost_center_employee_number ds-padding-top--6">
        <GridColumn className="of-billing-account-details form-column view-field bottom">
          <CL.Button id="editUserNumberAndPublicity" color="light" onClick={() => onEdit()}>
            {t.NVPK(editMsg)}
          </CL.Button>
        </GridColumn>
      </CL.GridRow>
    </CL.Grid>
  );
};
