import * as CL from '@design-system/component-library';
import * as React from 'react';
import { Input } from '../Input/Input.js';
import { type Ref, forwardRef } from 'react';
import { t } from '../../common/i18n/index.js';
import type { InputProps } from '../Input/Input.js';

interface CloseButtonProps {
  onClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
}

export const CloseButton = ({ onClick }: CloseButtonProps) => (
  <button
    onClick={onClick}
    className="ds-input--inputarea-clear"
    title={t.DW0G('Clear the field')}
    type="button"
    style={{
      top: 0,
      transform: 'none',
    }}
  >
    <CL.Icon icon="close" size="s" type="filled" />
  </button>
);

export const InputSearch = forwardRef((props: InputProps, ref: Ref<HTMLInputElement>) => {
  return (
    <Input {...props} ref={ref} name={props.name || 'search'} type="search" style={{ paddingLeft: '48px' }}>
      <span
        style={{
          height: '24px',
          left: 0,
          padding: '12px 8px 12px 17px',
          position: 'absolute',
          top: 0,
          width: '24px',
          pointerEvents: 'none',
          boxSizing: 'content-box',
        }}
      >
        <CL.Icon icon="search" size="m" />
      </span>
      {props.children}
    </Input>
  );
});
