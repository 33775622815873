import * as CL from '@design-system/component-library';
import { Login } from '../Login/index.js';
import { REDIRECT_PATH_KEY } from '../../common/constants/commonConstants.js';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useNavigate } from 'react-router-dom';
import type { DialogState } from '../../common/types/states.js';

import './DeviceCheckoutLoginAndRegistration.scss';

export interface DeviceCheckoutLoginAndRegistrationProps {
  dialog?: DialogState;
}

export const DeviceCheckoutLoginAndRegistration = (props: DeviceCheckoutLoginAndRegistrationProps) => {
  const navigate = useNavigate();
  const { anonymousUser } = useAuth();
  return (
    <div className="of-login-registration-container">
      <div className="of-login-registration-container__login">
        <Login dialog={props.dialog} />
      </div>
      {!anonymousUser?.mfaRequired && (
        <div className="of-login-registration-container__registration">
          <h3>{t.ELJA('I am a new customer.')}</h3>
          <CL.Button
            size="l"
            onClick={() => {
              navigate(`${paths.REGISTER_PAGE}?${REDIRECT_PATH_KEY}=${encodeURIComponent(paths.DEVICE_CHECKOUT)}`);
            }}
          >
            {t.LJTY('Register').toUpperCase()}
          </CL.Button>
        </div>
      )}
    </div>
  );
};
