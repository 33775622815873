import * as CL from '@design-system/component-library';
import { useState } from 'react';
import type { BadgeProps } from '@design-system/component-library';
import type { ReactNode } from 'react';

import './ToggleCheckbox.scss';

interface PriceInfo {
  value: ReactNode;
  additionalText?: ReactNode;
}

export interface ToggleCheckboxProps {
  badge?: BadgeProps;
  children?: ReactNode;
  initialValue?: boolean;
  onChange: (checked: boolean) => void;
  priceInfo?: PriceInfo;
  title: string;
  additionalInformation?: string | ReactNode;
  disabled?: boolean;
}

const Price = ({ badge, priceInfo }: { badge?: BadgeProps; priceInfo?: PriceInfo }) => {
  if (!priceInfo?.additionalText && !badge) {
    return priceInfo?.value ? (
      <div className="ds-font-weight--bold of-toggle-checkbox__price__basic">{priceInfo.value}</div>
    ) : null;
  }
  return (
    <div className="of-toggle-checkbox__price__additional-info">
      {badge && (
        <div>
          <CL.Badge {...badge} />
        </div>
      )}
      {priceInfo?.value && <div className="ds-font-weight--bold">{priceInfo?.value}</div>}
      {priceInfo?.additionalText && (
        <div className="ds-font-size--small of-toggle-checkbox__price__additional-info__additional-text">
          {priceInfo?.additionalText}
        </div>
      )}
    </div>
  );
};

export const ToggleCheckbox = (props: ToggleCheckboxProps) => {
  const {
    additionalInformation,
    badge,
    initialValue = false,
    children,
    title,
    onChange,
    priceInfo,
    disabled = false,
  } = props;
  const [checked, setChecked] = useState(initialValue);

  // Choose style class depending on how much stuff there is to render, so that the used space can be optimized
  // If a badge or additional text exists, more space is needed
  const containerClass =
    priceInfo?.additionalText || badge
      ? 'of-toggle-checkbox__container__additional-info'
      : 'of-toggle-checkbox__container__basic';

  return (
    <div className="of-toggle-checkbox ds-padding--3">
      <div className={containerClass}>
        <div className="of-toggle-checkbox__header">
          <CL.Checkbox
            className="ds-font-weight--bold"
            checked={initialValue}
            onChange={e => {
              setChecked(e.target.checked);
              onChange(e.target.checked);
            }}
            label={title}
            disabled={disabled}
          />
          {additionalInformation && (
            <div className="ds-padding-left--5 ds-font-size--small">{additionalInformation}</div>
          )}
        </div>
        <Price badge={badge} priceInfo={priceInfo} />
      </div>

      <div
        className={`${
          checked && !disabled ? 'of-toggle-checkbox__content__open' : 'of-toggle-checkbox__content__closed'
        } of-toggle-checkbox__content ds-padding-left--5`}
      >
        {children}
      </div>
    </div>
  );
};
