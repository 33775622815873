import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { ModelType, SubscriptionCategory } from '../../../../../common/enums.js';
import { SubscriptionLayout } from '../../../../../components/SubscriptionLayout/SubscriptionLayout.js';
import { SubscriptionVoice } from '../../../../../components/Subscription/SubscriptionVoice.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import {
  loadBillChannels,
  loadContacts,
  loadOnlineModelByModelType,
  loadSubscriptionAddOnRules,
  loadSubscriptionAddOnRulesMobilePbx,
  loadSubscriptions,
} from '../../../../../selfservice/actions/index.js';
import { omaElisaForCompaniesMsg, t, voiceSubscriptionsMsg } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { stripActions } from '../../../../../common/utils/stateUtils.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import type { State } from '../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const MobileSubPath = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { subscriptionId } = useParams<SubscriptionId>();
  const subs = useSelector((s: State) => stripActions(s.selfservice?.subscriptions?.voice) || undefined, deepEqual);

  useEffect(() => {
    dispatch(loadContacts());
    dispatch(loadSubscriptions({ category: SubscriptionCategory.VOICE, displayId: subscriptionId }));
    // Take to use when Ring Association UI is ready
    dispatch(loadOnlineModelByModelType(ModelType.Ring));
    dispatch(loadSubscriptionAddOnRulesMobilePbx());
    dispatch(loadSubscriptionAddOnRules());
    dispatch(loadBillChannels());
    dispatch(loadSubscriptionAddOnRules());
  }, [dispatch, pathname, subscriptionId]);

  if (!subscriptionId) {
    return null;
  }

  const breadCrumbs = (
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.AR0B(voiceSubscriptionsMsg), path: paths.PS_MOBILE_SUBSCRIPTIONS },
        { name: subscriptionId },
      ]}
    />
  );

  return (
    <SubscriptionLayout subscriptions={subs}>
      <SubscriptionVoice
        breadCrumbs={breadCrumbs}
        category={SubscriptionCategory.VOICE}
        subscriptionId={subscriptionId}
        subscriptions={subs}
      />
    </SubscriptionLayout>
  );
};
