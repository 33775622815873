import { DeviceReport } from '../common/DeviceReport.js';
import { deviceShopMsg, t } from '../../../common/i18n/index.js';
import { getOneTimeAndRecurringDeviceReport } from './oneTimeAndRecurringDeviceReportUtil.js';

export const OneTimeAndRecurringDeviceReport = () => {
  return (
    <DeviceReport
      prepareReport={getOneTimeAndRecurringDeviceReport}
      fileName="OneTimeAndRecurringDeviceReport.csv"
      itemClass="ea-picto--device-store"
      heading={t.C6OR(deviceShopMsg)}
      description={t.CU0C('This report covers the mobile phones and accessories used in your company')}
    />
  );
};
