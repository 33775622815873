import * as CL from '@design-system/component-library';
import { DeliveryOrder } from '../../generated/api/models.js';
import {
  contractEndDateMsg,
  durationOfContractMsg,
  installationAddressMsg,
  simCardNumberMsg,
  subscriptionNumberMsg,
  t,
} from '../../common/i18n/index.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getTaapiTechnologyAsString } from '../../common/utils/subscriptionUtils.js';
import type { DeliveryOrderDetails } from '../../generated/api/models.js';

interface DeliveryOrderDetailsProductProps {
  productType: DeliveryOrder.ProductTypeEnum;
  details: DeliveryOrderDetails;
  deliveryOrderName?: string;
}

export const DeliveryOrderDetailsProduct = ({
  productType,
  details,
  deliveryOrderName,
}: DeliveryOrderDetailsProductProps) => {
  const { contractTermMonths, fixedTermContractEndDate, taapiTechnology } = details;
  return (
    <div className="of-delivery-order-details--section" key="of-delivery-order--section-product">
      <h3 className="ds-h3">{t.B4ZV('Product details')}</h3>
      <CL.Description
        items={[
          {
            title: t.XSQ0('Product name'),
            description: deliveryOrderName,
          },
          {
            title: t.CAA9(durationOfContractMsg),
            description: contractTermMonths ? `${contractTermMonths} ${t.XXVX('month')}` : undefined,
          },
          {
            title: t.LHJ4(contractEndDateMsg),
            description: fixedTermContractEndDate ? formatTimestampToUTCDDMMYYYY(fixedTermContractEndDate) : undefined,
          },
          {
            title: t.HOPN('Administrator’s email address'),
            description:
              productType === DeliveryOrder.ProductTypeEnum.SOFTWARE_PRODUCT ? details.adminEmailAddress : undefined,
          },
          {
            title: t.R4O4('Downloading speed'),
            description: details.downloadSpeed,
          },
          {
            title: t.DI4V(installationAddressMsg),
            description: details.installationAddress,
          },
          {
            title: t.DP44(subscriptionNumberMsg),
            description: details.mobileNumber,
          },
          {
            title: t.FDQN('IMEI code or serial number'),
            description: details.serialNumber,
          },
          {
            title: t.VLQ3(simCardNumberMsg),
            description: details.simCardNumber,
          },
          {
            title: t.BBPX('Software version'),
            description: details.softwareEditionName,
          },
          {
            title: t.FWV1('Broadband technology'),
            description: getTaapiTechnologyAsString(taapiTechnology),
          },
          {
            title: t.CQQ7('Transmission speed'),
            description: details.uploadSpeed,
          },
          {
            title: t.ECMJ('Device model'),
            description: details.deviceModel,
          },
        ].filter(item => !!item && item.description)}
      />
    </div>
  );
};
