import * as CL from '@design-system/component-library';
import { Field, useFormikContext } from 'formik';
import { Loading } from '../../../../components/Loading/index.js';
import { SourceSystem } from '../../../../generated/api/sourceSystem.js';
import { billingAccountMsg, t } from '../../../i18n/index.js';
import { fetchBillingAccounts } from '../../../fetch.js';
import { getBillingAccountListItems } from '../../../../components/AddOrSelectBillingAccountsV2/AddOrSelectBillingAccounts.js';
import { useDebounce } from '../../../hooks/useDebounce.js';
import { useEffect, useState } from 'react';
import type { BillingAccountSearchResponse } from '../../../../generated/api/billingAccountSearchResponse.js';
import type { CustomerOrderDetailsFormValues } from '../../../../components/CustomerOrderDetails/CustomerOrderDetails.js';
import type { FocusEvent } from 'react';

export const BillingAccountDropDown = () => {
  const { setFieldValue, values } = useFormikContext<CustomerOrderDetailsFormValues>();

  const [selectedBillingAccountId, setSelectedBillingAccountId] = useState(values.billingAccountId);
  const [currentBillingAccounts, setCurrentBillingAccounts] = useState<BillingAccountSearchResponse[]>();
  const [allBillingAccounts, setAllBillingAccounts] = useState<BillingAccountSearchResponse[]>();
  const [searchText, setSearchText] = useState('');

  const searchBillingAccountsDebounce = useDebounce(async value => {
    const bas = await fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC, search: value });
    if (bas.total && bas.searchResults?.length) {
      setCurrentBillingAccounts(bas.searchResults);
    }
  }, 1000);

  useEffect(() => {
    fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC }).then(bs => {
      const searchResults = bs.searchResults || [];
      setCurrentBillingAccounts(searchResults);
      setAllBillingAccounts(searchResults);
    });
  }, []);

  const onChangeBillingAccountId = (evt: HTMLLIElement) => {
    const selected = evt.dataset.value;
    if (selected) {
      setSearchText('');
      setCurrentBillingAccounts(allBillingAccounts);
      setSelectedBillingAccountId(selected);
      setFieldValue('billingAccountId', selected);
    }
  };

  const onChange = async (value: string) => {
    await searchBillingAccountsDebounce(value);
    setSearchText(value);
  };

  const billingAccountItems = getBillingAccountListItems(false, currentBillingAccounts, searchText);

  // If selected billing account not found, set the first from the list
  const billingAccountFound = currentBillingAccounts?.some(
    response => response.result.billingAccountId === selectedBillingAccountId
  );
  const selectedItem = billingAccountFound ? selectedBillingAccountId : billingAccountItems[0].value;

  return !currentBillingAccounts ? (
    <Loading />
  ) : (
    <Field
      id="billingAccountId"
      name="billingAccountId"
      label={t.IFT9(billingAccountMsg)}
      items={billingAccountItems}
      onSearch={onChange}
      onValueChange={onChangeBillingAccountId}
      selectedValue={selectedItem}
      ariaLabel={t.IFT9(billingAccountMsg)}
      component={CL.Search}
      onFocus={(event: FocusEvent<HTMLInputElement>) => {
        event.target.select();
      }}
    />
  );
};
