import * as CL from '@design-system/component-library';
import { CloseButton, InputSearch } from '../InputSearch/InputSearch.js';
import { TableUrlParams } from './tableConstants.js';
import { ToggleButton } from '../ToggleButton/ToggleButton.js';
import { filterMsg, searchPlaceHolderMsg, t } from '../../common/i18n/index.js';
import { resetPagingParams } from './tableUtils.js';
import { useDebounce } from '../../common/hooks/useDebounce.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import './ListSearch.scss';

const MIN_SEARCH_QUERY_LENGTH = 2;

interface ListSearchProps {
  className?: string;
  defaultItemsPerPageOption?: number;
  filtersOpen?: boolean;
  hasCursorNavigation?: boolean;
  setFiltersOpen?: (open: boolean) => void;
  onSearch?: () => void;
}

export interface DefaultListSearchParams {
  search?: string;
  sort?: string;
  offset?: string;
  order?: string;
  limit?: string;
}

export const ListSearch = ({
  className,
  filtersOpen,
  hasCursorNavigation = false,
  setFiltersOpen,
  onSearch,
}: ListSearchProps) => {
  const navigate = useNavigate();
  const { hash, search, pathname } = useLocation();
  const queryParams = new URLSearchParams(search);
  const searchQueryParam = queryParams.get(TableUrlParams.SEARCH);
  const [currentSearch, setCurrentSearch] = useState(searchQueryParam || '');

  const doSearchDebounced = useDebounce(() => {
    navigate(`${pathname}?${queryParams}${hash}`, { replace: true });
  }, 1000);

  const handleSearch = (query: string) => {
    setCurrentSearch(query);
    if (query && query.length >= MIN_SEARCH_QUERY_LENGTH) {
      queryParams.set(TableUrlParams.SEARCH, query);
      resetPagingParams(queryParams, hasCursorNavigation);
      if (onSearch) {
        onSearch();
      }
    } else {
      queryParams.delete(TableUrlParams.SEARCH);
    }
    doSearchDebounced();
  };

  const toggleFilters = () => {
    if (setFiltersOpen) {
      setFiltersOpen(!filtersOpen);
    }
  };

  return (
    <div className={`of-list-search ${setFiltersOpen ? 'ds-margin-bottom--2' : ''} ${className || ''}`}>
      <div className="ds-inputgroup__inputcontainer">
        <div className="ds-input ds-input-withicon of-list-search__input">
          <InputSearch
            name="tableSearch"
            type="search"
            placeholder={t.GGLB(searchPlaceHolderMsg)}
            onChange={e => handleSearch(e.target.value)}
            value={currentSearch}
          >
            {!!currentSearch && <CloseButton onClick={() => handleSearch('')} />}
          </InputSearch>
        </div>
        {setFiltersOpen && (
          <div className="of-toggle-filters-button-container">
            <div className="of-toggle-filters-button">
              <ToggleButton buttonLabel={t.XUFV(filterMsg)} isOpen={!!filtersOpen} onClick={toggleFilters} />
            </div>
            <div className="of-toggle-filters-button-mobile">
              <CL.Button size="l" onClick={toggleFilters}>
                <CL.Icon color="blue-600" icon="filter" />
              </CL.Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
