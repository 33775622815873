import * as CL from '@design-system/component-library';
import * as React from 'react';
import { additionalServicesMsg, includedMsg, priceMsg, t } from '../../common/i18n/index.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import type { AssociationRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { ServiceLevelAddon } from './ServiceLevelAndAddonsInterfaces.js';

import './Addons.scss';

interface AddonRowProps {
  addon: ServiceLevelAddon;
  openingFeeAddons?: AssociationRecord[];
  incompatibleAddonIds: string[];
  selectCheckbox: (id: string) => void;
  editing?: boolean;
}

const AddonRow = ({ addon, openingFeeAddons, incompatibleAddonIds, selectCheckbox, editing = true }: AddonRowProps) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    selectCheckbox(event.target.id);
  };

  // List of addon codes for Ring opening fees
  const openingFeeCodes = openingFeeAddons?.map(assoc => {
    return assoc.cspmb__add_on_price_item__r.guid__c;
  });

  // If addon is opening fee, don't render this addon
  if (openingFeeCodes?.includes(addon.addonCode)) {
    return null;
  }

  let disabled = addon.disabled;
  let checked = addon.selected;

  if (incompatibleAddonIds.includes(addon.addonCode)) {
    checked = false;
    disabled = true;
  }

  if (addon.monthlyPrice === 0) {
    checked = true;
    disabled = true;
  }

  let monthlyPrice = formatSum(Number(addon.monthlyPrice)) + '/kk';
  if (addon.monthlyPrice === 0) {
    monthlyPrice = t.A3GB(includedMsg);
  }

  return (
    <tr className="ds-font-size--large">
      <td>
        {editing ? (
          <CL.Checkbox
            disabled={disabled}
            checked={checked}
            key={String(checked)}
            name={addon.name}
            id={addon.name}
            label={addon.name}
            onChange={onChange}
          />
        ) : (
          <>{addon.name}</>
        )}
      </td>
      <td className="pricing-column">{monthlyPrice}</td>
    </tr>
  );
};

interface AddonsProps {
  addonsList?: ServiceLevelAddon[];
  openingFeeAddons?: AssociationRecord[];
  incompatibleAddonIds: string[];
  selectedCheckBoxIdCallback: (id: string) => void;
  editing?: boolean;
}

export const Addons = ({
  addonsList,
  editing = true,
  openingFeeAddons,
  incompatibleAddonIds,
  selectedCheckBoxIdCallback,
}: AddonsProps) => {
  const selectCheckbox = (checkboxName: string) => {
    const selectedAddon = addonsList?.filter(addon => addon.name === checkboxName);
    if (selectedAddon?.length === 1) {
      selectedCheckBoxIdCallback(selectedAddon[0].id);
    }
  };

  return (
    <div className="of-service-level-addons">
      <div className="ds-font-size--large ds-padding-top--5">{t.LXSR(additionalServicesMsg)}</div>
      <table className="ea-table ea-table-striped">
        <thead>
          <tr>
            <th scope="col" />
            <th scope="col" className="pricing-column">
              {t.V72N(priceMsg)}
            </th>
          </tr>
        </thead>
        <tbody>
          {addonsList?.map(addon => (
            <AddonRow
              key={addon.addonCode}
              addon={addon}
              incompatibleAddonIds={incompatibleAddonIds}
              openingFeeAddons={openingFeeAddons}
              selectCheckbox={selectCheckbox}
              editing={editing}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
