import * as CL from '@design-system/component-library';
import { AddOnExternalLink } from './AddOnExternalLink.js';
import { SubscriptionAdditionalService } from './SubscriptionAdditionalService.js';
import { SubscriptionAdditionalServiceStatusEnum } from '../../common/enums.js';
import { ToggleCheckbox } from '../ToggleCheckbox/ToggleCheckbox.js';
import { inUseMsg, mobiilivarmennePricePerUserMsg, notInUseMsg, t } from '../../common/i18n/index.js';
import type { AddOn } from '../../generated/api/addOn.js';

import './MobileIdAdditionalService.scss';

export const MOBILE_ID_EXTERNAL_LINK_URL = 'https://elisa.fi/tietoturva/mobiilivarmenne';

const MobileIdLinkButton = ({ buttonText }: { buttonText?: string }) => (
  <div className="ds-margin-top--2 of-mobile-id-addon__button">
    <a href={MOBILE_ID_EXTERNAL_LINK_URL}>
      <CL.Button type="button" className="ds-button--color-light" size="s">
        <span className="of-mobile-id-addon__button__text">{buttonText}</span>
        <span className="ds-margin-left--2">
          <CL.Icon icon="external-link" color="black" type="filled" />
        </span>
      </CL.Button>
    </a>
  </div>
);

const NonActiveMobileAdditionalInfo = () => (
  <>
    <div>{t.O4D7('You can take Mobiilivarmenne in use for your corporation subscription at your own expense.')}</div>
    <AddOnExternalLink linkHref={MOBILE_ID_EXTERNAL_LINK_URL} />
  </>
);

export const MobileIdAdditionalService = ({
  selectedAddOn,
  additionalServicePath,
  showEditView,
  isEmployee,
}: {
  selectedAddOn: AddOn;
  additionalServicePath?: string;
  showEditView: boolean;
  isEmployee: boolean;
}) => {
  const mobileIdInUse = !(selectedAddOn.addOnCode === SubscriptionAdditionalServiceStatusEnum.NOT_IN_USE);
  const serviceStatus = mobileIdInUse
    ? SubscriptionAdditionalServiceStatusEnum.IN_USE
    : SubscriptionAdditionalServiceStatusEnum.NOT_IN_USE;
  const addOnInfoText = mobileIdInUse ? t.V34H(inUseMsg) : t.MUF5(notInUseMsg);
  const mobileIdButtonText = mobileIdInUse ? t.TIWU('Manage the service') : t.XFA9('Enable the service');
  const subTitle = mobileIdInUse
    ? t.D6IE('You can manage the service in OmaElisa for Consumer customers.')
    : t.O4D7('You can take Mobiilivarmenne in use for your corporation subscription at your own expense.');

  const priceInfo = isEmployee
    ? undefined
    : {
        value: t.BJ25(mobiilivarmennePricePerUserMsg),
      };
  const priceInfoOrLink = isEmployee
    ? { value: <MobileIdLinkButton buttonText={mobileIdButtonText} /> }
    : {
        value: t.BJ25(mobiilivarmennePricePerUserMsg),
      };

  if (showEditView && mobileIdInUse) {
    return null;
  }

  if (showEditView) {
    return (
      <div className="ds-margin-vertical--2">
        <ToggleCheckbox
          onChange={() => undefined}
          title={selectedAddOn.addOnProductName}
          priceInfo={priceInfoOrLink}
          initialValue={false}
          disabled={true}
          additionalInformation={<NonActiveMobileAdditionalInfo />}
        />
      </div>
    );
  }

  return (
    <SubscriptionAdditionalService
      title={selectedAddOn.addOnProductName}
      addOnStatusInfo={{ status: serviceStatus, text: addOnInfoText }}
      subTitle={subTitle}
      priceInfo={priceInfo}
      additionalServicePath={additionalServicePath}
      isMobileId={true}
      isEmployee={isEmployee}
      mobileIdLinkButton={<MobileIdLinkButton buttonText={mobileIdButtonText} />}
    >
      <AddOnExternalLink linkHref={MOBILE_ID_EXTERNAL_LINK_URL} />
    </SubscriptionAdditionalService>
  );
};
