import * as CL from '@design-system/component-library';
import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { EsimActivationQR } from './EsimActivationQR.js';
import { Loading } from '../Loading/index.js';
import { SimType } from '../../generated/api/models.js';
import { Stepper } from '../Stepper/Stepper.js';
import { StepperItem, StepperItemStyle } from '../StepperItem/StepperItem.js';
import { orderEsimMsg, returnToSubscriptionPageMsg, t } from '../../common/i18n/index.js';
import type { Subscription } from '../../generated/api/models.js';

import './EsimActivation.scss';

export interface EsimActivationProps {
  subscription?: Subscription;

  onClickOrderSim: () => void;
  onClickSubscription: () => void;
}

export const EsimActivation = (props: EsimActivationProps) => {
  const { subscription, onClickOrderSim, onClickSubscription } = props;

  if (!subscription?.details?.mobile) {
    return <Loading />;
  }

  const mobileDetails = subscription.details.mobile;

  if (mobileDetails.simType !== SimType.ESIM) {
    if (mobileDetails.esimAllowed === false) {
      return (
        <>
          <div className="of-esim-activation__info">
            <p>{t.CJOF('eSIM is not available for this subscription.')}</p>
          </div>
          <div className="of-esim-activation__actions">
            <CL.Button color="link" onClick={onClickSubscription}>
              {t.D30R(returnToSubscriptionPageMsg)}
            </CL.Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="of-esim-activation__info">
            <p>{t.BC3P('There is no eSIM ordered for this subscription.')}</p>
          </div>
          <ButtonGroupForSubmitAndBack
            className="of-esim-activation__actions"
            onCancel={onClickSubscription}
            onSubmit={onClickOrderSim}
            submitButtonText={t.DD3Q(orderEsimMsg)}
          />
        </>
      );
    }
  }

  return (
    <>
      <p>
        <b>{t.KN84('Check first that your device is connected to the Internet')}</b> ({t.MU1K('WiFi connection')}).
      </p>
      <p>
        {t.ZW5Q(
          `eSIM is activated with a QR code. It can be read with the phone's camera or a specially designed application. You can also enter the information manually by adding an eSIM in the device settings.`
        )}
      </p>
      <p>
        <b>{t.BEKE('Note')}:</b>
        <br />
        {t.G5HB('The Mobile Certificate service is not compatible with eSIM.')}
        <br />
        {t.F6BI(
          'If you change your device, you must order a new eSIM using the SIM-card change process and activate it on the new device. The old QR code does not work on the new device.'
        )}
        <br />
        {t.T0KV(
          'At the end of the activation, the phone can shut down the screen, after which the phone asks for a new PIN-code 1234 (not the same as the phone security code).'
        )}
      </p>
      <Stepper className="ds-padding-vertical--4">
        <StepperItem stepperStyle={StepperItemStyle.ACTIVE}>
          <EsimActivationQR subscription={subscription} />
        </StepperItem>

        <StepperItem stepperStyle={StepperItemStyle.ACTIVE}>
          <div>
            <b>{t.K1BD('Follow the instructions on your device.')}</b>{' '}
            {t.K1J3('You may need to wait a moment for the eSIM data to load to your device.')}
          </div>
        </StepperItem>

        <StepperItem stepperStyle={StepperItemStyle.ACTIVE}>
          <div>
            <b>{t.KES0('Activate eSIM')}</b> {t.KM32('with PIN code')} 1234.
          </div>
        </StepperItem>

        <StepperItem stepperStyle={StepperItemStyle.ACTIVE}>
          <div>
            <b>{t.KMSU('Done!')}</b>{' '}
            {t.LDM3(
              'If you only have one eSIM profile in use, then it is immediately in use. You can find these instructions and the QR code from this page in your subscriptions. You can use the QR code on the same device unlimited number of times. Please note that the eSIM can only be used on the first device on which it is activated.'
            )}
          </div>
        </StepperItem>

        <CL.Button color="link" onClick={onClickSubscription}>
          {t.D30R(returnToSubscriptionPageMsg)}
        </CL.Button>
      </Stepper>
    </>
  );
};
