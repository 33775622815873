import { ActionPhase } from './storeUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { ActionState, ActionsHistory, ItemsQuery } from './store.js';
import type { TypeKeys } from '../actions/index.js';

export const addToActionHistory = (
  actionsHistory: ActionsHistory | undefined | null,
  actionState: ActionState
): ActionState[] => {
  if (!actionsHistory || !actionsHistory.actions) {
    return [actionState];
  } else {
    return [actionState, ...actionsHistory.actions];
  }
};

export const getPreviousActionState = (
  actionType: TypeKeys,
  state: ActionsHistory | undefined | null,
  phase?: ActionPhase,
  key?: string,
  value?: string
): ActionState | undefined => {
  if (state && state.actions) {
    return state.actions.find(
      (previousActionState: ActionState) =>
        previousActionState.value.type === actionType &&
        (!phase || previousActionState.phase === phase) &&
        // @ts-ignore
        (!key || previousActionState.value[key] === value)
    );
  }
  return undefined;
};

export const updateActionStatePhase = (
  actionType: TypeKeys,
  state: ActionsHistory,
  oldPhase: ActionPhase,
  newPhase: ActionPhase
): ActionState[] | undefined => {
  const oldActionState = getPreviousActionState(actionType, state, oldPhase);
  if (!oldActionState) {
    return state.actions;
  } else {
    return [
      { ...oldActionState, phase: newPhase } as ActionState,
      ...state
        .actions!.filter(oldAction => oldAction.value.id !== oldActionState.value.id)
        .map(actionState => ({ ...actionState } as ActionState)),
    ];
  }
};

export const isPreviousActionInProgress = (
  actionType: TypeKeys,
  state: ActionsHistory | undefined | null,
  key?: string,
  value?: string
): boolean => {
  return getPreviousActionState(actionType, state, ActionPhase.IN_PROGRESS, key, value) !== undefined;
};

export const getPreviousSuccessfulItemsQuery = (
  actionType: TypeKeys,
  state: ActionsHistory | undefined | null,
  actionConditionKey?: string,
  actionConditionValue?: string
): ItemsQuery | undefined => {
  const actionState = getPreviousActionState(actionType, state, ActionPhase.SUCCESS);
  if (actionState) {
    // @ts-ignore
    if (!actionConditionKey || actionState.value[actionConditionKey] === actionConditionValue) {
      return actionState.query;
    }
  }
  return undefined;
};

export const isOmaElisa = (path?: string) => (path ? path.startsWith(paths.SELF_SERVICE_HOME) : false);

export const DELIVERY_DETAILS_FOR_ONLINE_ORDER_CARD_PAYMENT_SESSION_STORAGE_KEY =
  'delivery-details-for-online-order-card-payment';
