import { ChangeBillingAccountDialog } from '../ChangeBillingAccountDialog/ChangeBillingAccountDialog.js';
import { SystemError } from '../SystemError/SystemError.js';
import { WizardType } from '../../common/enums.js';
import {
  changeSubscriptionBillingAccount,
  changeSubscriptionUserInfo,
  resetErrors,
} from '../../selfservice/actions/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getMobileIdContracts } from '../../selfservice/actions/mobileIdContractActions.js';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { t, transferSubToAnotherBillingAccountMsg } from '../../common/i18n/index.js';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { ChangeSubscriptionUserInfoPartial } from '../../selfservice/actions/index.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionsState } from '../../common/types/states.js';

interface SubscriptionProps {
  children: JSX.Element;
  subscriptions?: SubscriptionsState;
}

const SubscriptionInitBillingAccountChange = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const billingAccounts = useSelector((s: State) => s.selfservice?.billingAccounts, deepEqual);
  const dialog = useSelector((s: State) => s.dialog, deepEqual);
  const { subscription, type } = state;
  const { billingAccountId } = subscription;

  return (
    <ChangeBillingAccountDialog
      billingAccounts={billingAccounts!}
      billingAccountId={subscription.billingAccountId}
      changeRequestInProgress={dialog?.submitInProgress}
      headerText={t.EEYF(transferSubToAnotherBillingAccountMsg)}
      description={t.AIUJ(
        'Please note that, after the change, invoicing will still be based on two invoices for the next payment. Any supplementary services will be transferred with the main product.'
      )}
      detailedView={true}
      searchable={true}
      onBeginCreateBillingAccount={() => {
        navigate(paths.BILLING_ACCOUNTS_NEW_BA, { state: { subscription, type, originURL: pathname } });
      }}
      onCloseDialog={() => {
        navigate(pathname, { replace: true });
      }}
      onSubmit={(bid: string, bdid: string) => {
        if (bid === billingAccountId) {
          // same location without state, billing account stays the same
          navigate(pathname, { replace: true });
        } else {
          navigate(pathname, { replace: true, state: { originURL: pathname } });
          dispatch(changeSubscriptionBillingAccount(subscription, bid, bdid));
        }
      }}
    />
  );
};

const SubscriptionInitEppBillingAccountChange = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dialog = useSelector((s: State) => s.dialog, deepEqual);
  const billingAccounts = useSelector((s: State) => s.selfservice?.billingAccounts, deepEqual);
  const billingAccountId = location.state.billingAccountId;

  return (
    <ChangeBillingAccountDialog
      billingAccounts={billingAccounts!}
      billingAccountId={billingAccountId}
      changeRequestInProgress={dialog?.submitInProgress}
      headerText={t.CNY8('This device has Elisa Oma Laitelasku')}
      description={
        <div>
          <p>
            {t.WMWY(
              'You are about to change the user for a device that has Elisa Oma Laitelasku service. The remaining employee share will be invoiced from the company.'
            )}
          </p>
          <p>
            {t.YFPB(
              'The remaining employee share will be invoiced from the company.\nThe billing account can be changed within the process.'
            )}
          </p>
        </div>
      }
      detailedView={true}
      searchable={true}
      onBeginCreateBillingAccount={() => {
        navigate(`${location.pathname}/uusi-laskutussopimus`, { state: location.state });
      }}
      onCloseDialog={() => {
        navigate(location.pathname, { replace: true });
      }}
      onSubmit={(bid: string, bdid: string) => {
        const changeSubscriptionUserInfoParam: ChangeSubscriptionUserInfoPartial =
          location.state.changeSubscriptionUserInfoParams;
        dispatch(changeSubscriptionUserInfo(...changeSubscriptionUserInfoParam, bdid));
      }}
    />
  );
};

export const SubscriptionLayout = ({ children, subscriptions }: SubscriptionProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const store = useStore<State>();
  const config = store.getState().config;
  const resources = useSelector((state: State) => state.resources, deepEqual);

  useEffect(() => {
    if (isFeatureEnabled(config?.featureFlags?.mobileId) && !subscriptions?.mobileIdContracts) {
      dispatch(getMobileIdContracts());
    }
  }, [config.featureFlags.mobileId, dispatch, subscriptions?.mobileIdContracts]);

  if (subscriptions?.errors) {
    return (
      <SystemError
        errors={subscriptions.errors}
        onButtonClick={() => {
          dispatch(resetErrors());
          navigate(paths.SELF_SERVICE_HOME);
        }}
      />
    );
  }
  if (resources?.errors) {
    return <SystemError errors={resources.errors} homePath={paths.SELF_SERVICE_HOME} />;
  }

  return (
    <>
      {location.state?.type === WizardType.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT.valueOf() && (
        <SubscriptionInitBillingAccountChange />
      )}
      {location.state?.wizardType === WizardType.CHANGE_EPP_OMALAITELASKU_BILLING_ACCOUNT.valueOf() && (
        <SubscriptionInitEppBillingAccountChange />
      )}
      <div className="of-subscriptions-scene">{children}</div>
    </>
  );
};
