import * as CL from '@design-system/component-library';
import { Grid } from '../Grid/Grid.js';
import { Loading } from '../Loading/Loading.js';
import { addressMsg, businessIdMsg, companyDetailsMsg, phoneNumberMsg, t } from '../../common/i18n/index.js';
import { formatAddress } from '../../common/utils/accountUtils.js';
import type { CompanyInfoState } from '../../common/types/states.js';

export interface CompanyInfoAttrs {
  companyInfo?: CompanyInfoState | null;
}
export type CompanyInfoProps = CompanyInfoAttrs;

export const CompanyInfo = ({ companyInfo }: CompanyInfoProps): JSX.Element => {
  if (!companyInfo || !companyInfo.businessId) {
    return <Loading />;
  }

  return (
    <Grid>
      <div className="of-company-info ds-margin-top--4">
        <CL.Description
          id="company-info-grid"
          listTitle={t.EHOL(companyDetailsMsg)}
          className="ds-margin--0"
          items={[
            { title: t.GWZ6(addressMsg), description: formatAddress(companyInfo.address) },
            { title: t.AGIJ(phoneNumberMsg), description: companyInfo.phoneNumber },
            { title: t.MPA5(businessIdMsg), description: companyInfo.businessId },
          ]}
        />
      </div>
    </Grid>
  );
};
