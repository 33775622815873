import { DEFAULT_ITEMS_PER_PAGE, ListPagination, ListSearch, Table, TableTopActions } from '../Table/index.js';
import { SubscriptionCategory } from '../../common/enums.js';
import { SubscriptionHiddenFields } from '../SubscriptionLists/SubscriptionHiddenFields.js';
import { SubscriptionLink } from '../SubscriptionLists/SubscriptionLink.js';
import { SubscriptionListFilterOptions, useFilterUpdate } from '../SubscriptionLists/SubscriptionTable.js';
import { billingAccountMsg, idNumberMsg, orderMsg, statusMsg, t } from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getSubscriptionStatus } from '../../common/utils/subscriptionUtils.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { useSelector, useStore } from 'react-redux';
import { useSubscriptions } from '../../common/hooks/useSubscriptions.js';
import type { CommonError } from '../../common/types/errors.js';
import type { DefaultListSearchParams } from '../Table/index.js';
import type { Dispatch, SetStateAction } from 'react';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionAction, SubscriptionSearchResponse } from '../../generated/api/models.js';

export interface SubscriptionsServiceProps {
  setErrors: Dispatch<SetStateAction<CommonError[]>>;
}

const getServiceRows = (
  searchResults: SubscriptionSearchResponse[] = [],
  search?: string,
  subscriptionActions?: SubscriptionAction[]
) => {
  return searchResults.map(({ result: subscriptionHeader, matchedFields }) => ({
    subscriptionName: (
      <SubscriptionLink subscription={subscriptionHeader} path={paths.PS_SERVICE} search={search}>
        <SubscriptionHiddenFields
          subscriptionHeader={subscriptionHeader}
          search={search}
          matchedFields={matchedFields}
        />
      </SubscriptionLink>
    ),
    subscriptionUserFriendlyId: matchedFields?.includes('subscriptionUserFriendlyId')
      ? highlightKeyword(subscriptionHeader.subscriptionUserFriendlyId || '', search)
      : subscriptionHeader.subscriptionUserFriendlyId || '',
    billingAccountDisplayId: matchedFields?.includes('billingAccountDisplayId')
      ? highlightKeyword(subscriptionHeader.billingAccountDisplayId || '', search)
      : subscriptionHeader.billingAccountDisplayId || '',
    subscriptionStatus: getSubscriptionStatus(subscriptionHeader, subscriptionActions).text,
  }));
};

export const SubscriptionsService = ({ setErrors }: SubscriptionsServiceProps) => {
  const config = useStore<State>().getState().config;
  const subscriptionActions = useSelector((state: State) => state?.selfservice?.pendingSubscriptionActions, deepEqual);
  const searchParams = useSearchParams<DefaultListSearchParams>();
  const [selectedFilter, onFilterUpdated] = useFilterUpdate();
  const { subscriptions, loading, errors } = useSubscriptions(SubscriptionCategory.SERVICE, selectedFilter);
  const { search } = searchParams;

  if (errors?.length) {
    setErrors(errors);
    return null;
  }

  const subscriptionFilter = (
    <SubscriptionListFilterOptions
      category={SubscriptionCategory.SERVICE}
      setSelectedSubType={filterValue => onFilterUpdated(filterValue)}
      selectedFilter={selectedFilter}
    />
  );

  const serviceColumns = [
    { key: 'subscriptionName', label: subscriptionFilter },
    { key: 'subscriptionUserFriendlyId', label: t.YTH3(idNumberMsg), sortable: true },
    { key: 'billingAccountDisplayId', label: t.IFT9(billingAccountMsg), sortable: true },
    { key: 'subscriptionStatus', label: t.ASQT(statusMsg), sortable: true },
  ];

  return (
    <div className="of-subscription-table">
      <TableTopActions
        links={[
          {
            label: t.AQOL(orderMsg),
            to: `${config.classicSiteUrl}/tilaukset`,
          },
        ]}
      />
      <ListSearch onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)} />
      <Table
        columns={serviceColumns}
        isLoading={loading}
        noItemsText={t.XZA8('No services')}
        rows={getServiceRows(subscriptions?.searchResults, search, subscriptionActions?.items)}
      />
      <ListPagination totalItems={subscriptions?.total || 0} />
    </div>
  );
};
