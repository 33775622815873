import * as CL from '@design-system/component-library';
import {
  AccordionsMain,
  TechnicalInformationBroadBand,
  TechnicalInformationMobile,
} from '../../common/utils/accordionUtils.js';
import { AdditionalServicesAccordionContent } from './AdditionalServicesAccordionContent.js';
import { Anchor } from '../Anchor/Anchor.js';
import { BarringsAccordionContent } from '../BarringsAccordionContent/BarringsAccordionContent.js';
import { CATEGORY_URL } from '../../common/utils/categoryUtils.js';
import {
  CompanyInfoResponse,
  Contract,
  Subscription,
  SubscriptionAction,
  SubscriptionPbxDetails,
  SubscriptionStatusType,
  SubscriptionType,
} from '../../generated/api/models.js';
import { CompositeList } from '../CompositeList/index.js';
import { DialogType, ModelType, WizardType } from '../../common/enums.js';
import { LinkableAccordion } from '../LinkableAccordion/index.js';
import { Loading } from '../Loading/index.js';
import { MobileSubscriptionAccordions } from './MobileSubscriptionAccordions.js';
import { PbxAccordions } from './PbxAccordions.js';
import { PbxNumberSettingsAccordionContent } from '../PbxNumberSettingsAccordionContent/PbxNumberSettingsAccordionContent.js';
import { PbxTimeSettingsAccordionContent } from '../PbxTimeSettingsAccordionContent/PbxTimeSettingsAccordionContent.js';
import { SimCard } from '../SimCard/SimCard.js';
import { SiteContext } from '../../public/site/SiteContext.js';
import { SubscriptionContactInfoBbInvitation } from './SubscriptionContactInfoBbInvitation.js';
import { SubscriptionDetailsButtonType } from './subscriptionDetailsButtons.js';
import { UserAndNumberPublicity } from '../UserAndNumberPublicity/UserAndNumberPublicity.js';
import {
  additionalServicesMsg,
  barringServicesMsg,
  changeMsg,
  directoryStatusOfUserAndNumberMsg,
  editMsg,
  noAdditionalServicesMsg,
  purposeOfUseMsg,
  settingsMsg,
  simCardMsg,
  t,
  technicalDataMsg,
  userInformationMsg,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { editSection, showDialog } from '../../selfservice/actions/index.js';
import {
  findSubscription,
  getSubscriptionStatus,
  hasEsimQrCode,
  isMobileIdAddOn,
  simCardChangeAllowed,
} from '../../common/utils/subscriptionUtils.js';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { getAdminUserAccordionContent, getDomainAccordionContent } from './subscriptionDetailsSoftwareProduct.js';
import { getContactPersonByContactId } from '../../common/utils/contactUtils.js';
import { getPbxSettingsFields } from './subscriptionDetailsPbx.js';
import { getPbxTechnicalDetailsContent, renderContent } from './subscriptionDetailsPbxCommon.js';
import { getPeriodicPriceAsText } from './subscriptionDetailsCommon.js';
import { getRingServiceLevelsFromOnlineModel } from '../AttachRing/attachRingDataConverter.js';
import { isFeatureEnabledForUser } from '../../common/utils/featureFlagUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { showBroadbandInvitationInfo } from './BroadBandInvitation/bbInvitationUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useContext } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import type { Accordion } from '../../common/utils/accordionUtils.js';
import type {
  AddOn,
  Contact,
  OnlineModel,
  SubscriptionDetails as SubscriptionDetailsModel,
  SubscriptionDetailsPbxSolution,
  SubscriptionDetailsSoftwareProduct,
} from '../../generated/api/models.js';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { CategoryKey } from '../../common/utils/categoryUtils.js';
import type {
  CompanyInfoState,
  ConfigState,
  OnlineModelsState,
  SubscriptionActionsState,
  SubscriptionsState,
} from '../../common/types/states.js';
import type { CompositeListProps } from '../CompositeList/index.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type {
  DirectoryListingCorporateNumberPublicity,
  DirectoryListingMobileNumberPublicity,
  DirectoryListingNumberPublicity,
  PurposeOfUseOrContact,
  SubscriptionStatus,
} from '../../common/types/subscription.js';
import type { ServiceLevel } from '../AttachRing/ServiceLevelAndAddonsInterfaces.js';
import type { State } from '../../selfservice/common/store.js';

import './SubscriptionDetails.scss';

export interface SubscriptionDetailsProps {
  breadCrumbs: JSX.Element;
  category: CategoryKey;
  subscriptionId: string;
  subscriptions?: SubscriptionsState;
}

const renderAccordions = (accordions: JSX.Element[]) => <div>{accordions}</div>;

const createMobilePbxSettingsAccordion = (headerName: string, detailsInput?: SubscriptionDetailsPbxSolution) => (
  <LinkableAccordion heading={headerName} headingLevel="h3" id="barring-services-settings">
    {detailsInput && renderContent(getPbxSettingsFields(detailsInput))}
  </LinkableAccordion>
);

const createMobilePbxAccordion = (
  headerName: string,
  subscriptionInput: Subscription,
  detailsInput?: SubscriptionDetailsPbxSolution
) => (
  <LinkableAccordion heading={headerName} headingLevel="h3" id="barring-services">
    {detailsInput && getPbxTechnicalDetailsContent(subscriptionInput, detailsInput)}
  </LinkableAccordion>
);

const getRingUserAccordionContent = (
  subscription: Subscription,
  subscriptionPendingStatus: boolean,
  onShowDialog: (params: DialogParams) => void
) => {
  return (
    <div>
      <CL.Description
        items={[
          {
            title: t.U4MA('User'),
            description: subscription.subscriptionPurposeOfUse,
          },
        ]}
      />
      <div>
        <CL.Button
          size="l"
          color="light"
          id="ea-composite-field-grid__edit-button"
          onClick={() =>
            subscriptionPendingStatus
              ? onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING })
              : onShowDialog({
                  subscriptionId: subscription.subscriptionId,
                  type: DialogType.RING_SUBSCRIPTION_UPDATE_USER,
                })
          }
        >
          {t.NVPK(editMsg)}
        </CL.Button>
      </div>
    </div>
  );
};

const getUserOrPurposeOfUseAccordionContent = (
  contacts: Contact[] | undefined,
  purposeOfUseObj: PurposeOfUseOrContact,
  subscription: Subscription,
  subscriptionActions: SubscriptionActionsState | undefined,
  subscriptionStatus: SubscriptionStatus,
  onShowDialog: (params: DialogParams) => void,
  siteBaseUrl: string,
  companyInfo?: CompanyInfoState | null
) => {
  const pbxType = subscription.details?.mobile?.pbxConfiguration
    ? subscription.details.mobile.pbxConfiguration.pbxConfigurationDetails.pbxType
    : undefined;
  const getNumberPrivacyCorporate = () => {
    if (
      subscription.subscriptionType === SubscriptionType.MOBILE &&
      purposeOfUseObj.directoryListingCorporateNumberPublicity &&
      !pbxType
    ) {
      return purposeOfUseObj.directoryListingCorporateNumberPublicity.pbxDirectoryDetails?.numberDirectory;
    } else if (
      subscription.subscriptionType === SubscriptionType.MOBILE &&
      purposeOfUseObj.directoryListingCorporateNumberPublicity &&
      (pbxType === SubscriptionPbxDetails.PbxTypeEnum.RING || pbxType === SubscriptionPbxDetails.PbxTypeEnum.VAKIO)
    ) {
      return purposeOfUseObj.directoryListingCorporateNumberPublicity.pbxDirectoryDetails?.numberDirectory;
    }
    return undefined;
  };

  const isDeviceSubscription = subscription.subscriptionType === SubscriptionType.DEVICE;
  const isInternalCustomer = companyInfo?.customerType === CompanyInfoResponse.CustomerTypeEnum.INTERNAL_CUSTOMERS;
  const isEppSubscription = !!subscription.details?.device?.eppSubscription;
  const isCostCenterMandatory = isDeviceSubscription && isInternalCustomer;
  const isEmailMandatory = isDeviceSubscription && (isEppSubscription || isInternalCustomer);
  const isPhoneNumberMandatory = isDeviceSubscription && (isEppSubscription || isInternalCustomer);
  // Keep the form in edit state when duplicate contact is found
  const duplicateContactFound = subscriptionActions?.duplicateContactFound || false;
  const currentEditingSection = duplicateContactFound ? 'purposeOfUse' : subscriptionActions?.editingSection;
  const forceEditing = duplicateContactFound || subscriptionActions?.editingSection !== undefined;

  return (
    <div>
      <UserAndNumberPublicity
        subscription={subscription}
        subscriptionStatus={subscriptionStatus}
        contacts={contacts}
        editingSection={currentEditingSection}
        forceEditing={forceEditing}
        purposeOfUseOrContact={purposeOfUseObj}
        numberPrivacyMobile={
          subscription.subscriptionType === SubscriptionType.MOBILE &&
          purposeOfUseObj.directoryListingMobileNumberPublicity
            ? purposeOfUseObj.directoryListingMobileNumberPublicity.voiceDirectoryDetails?.numberDirectory
            : undefined
        }
        numberPrivacyCorporate={getNumberPrivacyCorporate()}
        pbxType={pbxType}
        companyInfo={companyInfo}
        isCostCenterMandatory={isCostCenterMandatory}
        isEmailMandatory={isEmailMandatory}
        isPhoneNumberMandatory={isPhoneNumberMandatory}
        siteBaseUrl={siteBaseUrl}
        isSaving={Boolean(subscriptionActions?.saving)}
      />
      {showBroadbandInvitationInfo(subscription) && (
        <SubscriptionContactInfoBbInvitation
          onShowDialog={onShowDialog}
          subscriptionId={subscription.subscriptionId}
          contactPerson={getContactPersonByContactId(contacts, subscription?.subscriptionContactId)}
        />
      )}
    </div>
  );
};

const isSelfPayMobileIdActive = (isMobileIdEnabledForCompany: boolean, addOnGroup: string): boolean =>
  isMobileIdAddOn(addOnGroup) && !isMobileIdEnabledForCompany;

const getAdditionalServicesAccordionContent = (
  details: SubscriptionDetailsModel,
  onClickSubscriptionAddonDetails: (id: string, addOnId: string, category: string) => void,
  category: string,
  subscription: Subscription,
  isMobileIdEnabledForCompany = false
) => {
  const addOnListProps: CompositeListProps<AddOn> = {
    classes: ['of-subscription-details__add-on-list'],
    columns: [
      {
        columnClasses: ['of-subscription-details__add-on-list__link'],
        columnId: 'addOnProductName',
        ref: 'addOnProductName',
      },
      {
        columnId: 'addOnMonthlyRecurringCharge',
        ref: 'addOnMonthlyRecurringCharge',
        refFormatNumber: getPeriodicPriceAsText,
      },
    ],
    emptyListElement: <span>{t.EIYK(noAdditionalServicesMsg)}</span>,
    getRowId: (addOn: AddOn) => addOn.addOnCode,
    items: details?.selectedAddOns
      ?.filter(addon => addon.display === true)
      ?.filter(addon => !isSelfPayMobileIdActive(isMobileIdEnabledForCompany, addon.addOnGroup)),
    onSelectRow: (addOnCode: string) => {
      onClickSubscriptionAddonDetails(subscription.subscriptionDisplayId, addOnCode, category);
    },
  };
  return <CompositeList {...addOnListProps} />;
};

const getPbxServiceLevels = (companyInfo?: CompanyInfoState, onlineModels?: OnlineModelsState) => {
  if (companyInfo && companyInfo.pbxSolutions) {
    const ringSolution = companyInfo.pbxSolutions.find(
      pbxSolution => pbxSolution.subscriptionType === SubscriptionType.MOBILE_PBX
    );

    if (ringSolution) {
      const productCodes =
        ringSolution.details?.mobilePbx?.mobilePbxServiceLevelProduct?.userServiceLevelCommercialProductCodes;

      const productAddons = ringSolution.details?.mobilePbx?.mobilePbxServiceLevelProduct?.addOnServiceLevelSelections;

      if (productCodes && productAddons) {
        const getRingServicesModel = (onlineModelsInput: OnlineModelsState): OnlineModel | undefined =>
          onlineModelsInput.items && onlineModelsInput.items.find(model => model.onlineModelCode === ModelType.Ring);
        const ringServicesModel = onlineModels ? getRingServicesModel(onlineModels) : undefined;
        const serviceLevelsAndAddons = getRingServiceLevelsFromOnlineModel(
          productCodes,
          productAddons,
          ringServicesModel
        );
        return serviceLevelsAndAddons.pbxServiceLevels;
      }
    }
  }
  return [];
};

const Accordions = ({
  subscription,
  details,
  onClickSubscriptionAddonDetails,
  subscriptionStatus,
  category,
  config,
  onEditSectionIfNoActionsPending,
  onShowDialog,
  onClickAttachSubscriptionToVakio,
  siteBaseUrl,
  addOnRulesAssociations,
  addOnRulesDependencies,
  isMobileIdEnabledForCompany,
}: {
  subscription: Subscription;
  details: SubscriptionDetailsModel;
  onClickSubscriptionAddonDetails: (id: string, addOnId: string, category: string) => void;
  subscriptionStatus: SubscriptionStatus;
  category: CategoryKey;
  config: ConfigState;
  onEditSectionIfNoActionsPending: (section?: string) => boolean;
  onShowDialog: (params: DialogParams) => void;
  onClickAttachSubscriptionToVakio: (subscriptionId: string, category: string, isRing?: boolean) => void;
  siteBaseUrl: string;
  addOnRulesAssociations?: AssociationRecord[];
  addOnRulesDependencies?: DependencyRecord[];
  isMobileIdEnabledForCompany?: boolean;
}) => {
  const companyInfo = useSelector((state: State) => state?.selfservice?.companyInfo || undefined, deepEqual);
  const contacts = useSelector((state: State) => state?.selfservice?.contacts?.items, deepEqual);
  const resources = useSelector((state: State) => state.resources, deepEqual);
  const subscriptionActions = useSelector(
    (state: State) => state?.selfservice?.pendingSubscriptionActions || undefined,
    deepEqual
  );
  const editingSection = subscriptionActions?.editingSection || '';
  const onlineModels = useSelector((state: State) => state?.selfservice?.onlineModels || undefined, deepEqual);
  const ringModels = onlineModels?.items
    ? onlineModels.items.filter(onlineModel => onlineModel.onlineModelCode === ModelType.Ring)
    : undefined;
  const pbxServiceLevels: ServiceLevel[] = getPbxServiceLevels(companyInfo, onlineModels);
  const numberRange =
    subscription?.details?.mobile?.pbxConfiguration?.corporateNumberRangeId && resources?.numberRanges
      ? resources.numberRanges[subscription.details.mobile.pbxConfiguration.corporateNumberRangeId]
      : undefined;
  const extensionNumberRange =
    subscription?.details?.mobile?.pbxConfiguration?.extensionRangeId && resources?.numberRanges
      ? resources.numberRanges[subscription.details.mobile.pbxConfiguration.extensionRangeId]
      : undefined;

  const errors = subscriptionActions?.errors;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authenticatedUser } = useAuth();

  const onClickOrderSim = (subscriptionId: string, cat: CategoryKey) => {
    navigate(`${paths.PS_HOME}/${CATEGORY_URL[cat]}/${subscriptionId}/tilaa-sim`);
  };
  const onClickSimActivation = (subscriptionId: string, cat: CategoryKey) => {
    navigate(`${paths.PS_HOME}/${CATEGORY_URL[cat]}/${subscriptionId}/aktivoi-sim-kortti`);
  };

  const getDirectoryListingNumberPublicity = (
    inputSubscription: Subscription
  ): DirectoryListingNumberPublicity | undefined =>
    inputSubscription &&
    inputSubscription.details &&
    inputSubscription.details.mobile &&
    inputSubscription.details.mobile.directoryListing && {
      address: inputSubscription.details.mobile.directoryAddress,
      companyName: inputSubscription.details.mobile.companyName,
      numberDirectory: inputSubscription.details.mobile.directoryListing,
      recipientName: inputSubscription.details.mobile.recipientName,
    };

  const getDirectoryListingMobileNumberPublicity = (
    inputSubscription: Subscription
  ): DirectoryListingMobileNumberPublicity | undefined => {
    if (inputSubscription?.details?.mobile?.directoryListing) {
      return {
        voiceDirectoryDetails: {
          address: inputSubscription.details.mobile.directoryAddress,
          companyName: inputSubscription.details.mobile.companyName,
          numberDirectory: inputSubscription.details.mobile.directoryListing,
          recipientName: inputSubscription.details.mobile.recipientName,
        },
      };
    } else {
      return {
        voiceDirectoryDetails: undefined,
      };
    }
  };

  const getDirectoryListingCorporateNumberPublicity = (
    inputSubscription: Subscription
  ): DirectoryListingCorporateNumberPublicity | undefined => {
    if (
      inputSubscription?.details?.mobile?.pbxConfiguration?.pbxConfigurationDetails.corporateNumberDirectoryDetails &&
      (inputSubscription.details.mobile.pbxConfiguration.pbxConfigurationDetails.pbxType ===
        SubscriptionPbxDetails.PbxTypeEnum.RING ||
        inputSubscription.details.mobile.pbxConfiguration.pbxConfigurationDetails.pbxType ===
          SubscriptionPbxDetails.PbxTypeEnum.VAKIO)
    ) {
      return {
        pbxDirectoryDetails: {
          address:
            inputSubscription.details.mobile.pbxConfiguration.pbxConfigurationDetails.corporateNumberDirectoryDetails
              .address,
          companyName:
            inputSubscription.details.mobile.pbxConfiguration.pbxConfigurationDetails.corporateNumberDirectoryDetails
              .companyName,
          numberDirectory:
            inputSubscription.details.mobile.pbxConfiguration.pbxConfigurationDetails.corporateNumberDirectoryDetails
              .numberDirectory,
          recipientName:
            inputSubscription.details.mobile.pbxConfiguration.pbxConfigurationDetails.corporateNumberDirectoryDetails
              .recipientName,
        },
      };
    } else {
      return undefined;
    }
  };

  const purposeOfUseObj: PurposeOfUseOrContact = {
    contactId: subscription.subscriptionContactId,
    contactName: subscription.subscriptionContactName,
    directoryListingNumberPublicity: getDirectoryListingNumberPublicity(subscription),
    directoryListingMobileNumberPublicity: getDirectoryListingMobileNumberPublicity(subscription),
    directoryListingCorporateNumberPublicity: getDirectoryListingCorporateNumberPublicity(subscription),
    purposeOfUse: subscription.subscriptionPurposeOfUse,
    costCenter: subscription.costCenter,
    employeeNumber: subscription.subscriptionReference,
  };
  const userOrPurposeOfUseAccordionContent = getUserOrPurposeOfUseAccordionContent(
    contacts,
    purposeOfUseObj,
    subscription,
    subscriptionActions,
    subscriptionStatus,
    onShowDialog,
    siteBaseUrl
  );

  const ringUserAccordionContent = getRingUserAccordionContent(
    subscription,
    subscriptionStatus.pendingActions,
    onShowDialog
  );

  const getBarringsContent = () =>
    addOnRulesAssociations && addOnRulesDependencies ? (
      <BarringsAccordionContent
        addOnAssociations={addOnRulesAssociations}
        addOnDependencies={addOnRulesDependencies}
        details={details}
        forceEditing={editingSection === 'addonsBarrings'}
        onEditSectionIfNoActionsPending={onEditSectionIfNoActionsPending}
        saving={Boolean(subscriptionActions?.saving)}
        subscription={subscription}
        subscriptionStatus={subscriptionStatus}
      />
    ) : (
      <div className="ds-padding-bottom--4">
        <Loading />
      </div>
    );

  function specialNumberOnEdit(section: string, isEditable: boolean | undefined) {
    return () => {
      if (isEditable) {
        return onEditSectionIfNoActionsPending(section);
      }
      return onShowDialog({
        subscriptionId: subscription.subscriptionId,
        type: DialogType.SUBSCRIPTION_CHANGE_SPECIAL_NUMBER,
      });
    };
  }

  const isYttInstructionsContentVisible = (selectedAddOns?: AddOn[]) => {
    if (
      !isFeatureEnabledForUser('yttInstructionsAccordion', config.featureFlags, authenticatedUser?.enabledFeatureFlags)
    ) {
      return false;
    }
    if (!selectedAddOns) {
      return false;
    }

    // We don't want to show instructions for products not part of Elisa Yritystietoturva and certain types
    const filteredAddOns = selectedAddOns.filter(addOn => {
      const regex = /Elisa Yritystietoturva (?:25|35|45) laitteeseen/;
      const addOnProductName = addOn.addOnProductName;
      return addOnProductName.startsWith('Elisa Yritystietoturva') && !regex.test(addOnProductName);
    });

    // If even one addOn is part of accepted Yritystietoturva products, show the accordion
    return filteredAddOns.length > 0;
  };

  const getYttInstructionsContent = () => {
    return (
      <>
        <h5 className="yttSectionHeader">{t.M64T(`Elisa Yritystietoturva's data recovery`)}</h5>
        <div>
          {t.B8KE(
            'You have an Elisa Yritystietoturva subscription, which includes data recovery service if your device or files are damaged. Please read the instructions in the link carefully.'
          )}
          <div className="ds-padding-bottom--4">
            <a
              href="https://yrityksille.elisa.fi/ohje/yritystietoturva-tietojenpalautus"
              target="_BLANK"
              rel="noreferrer"
            >
              {t.SMAR('Instructions for data recovery (in Finnish)')}.
            </a>
          </div>
        </div>
        <h5 className="yttSectionHeader">{t.Q9G5('Phone support for security problems')}</h5>
        <p>
          {t.XTU4(
            'You have an Elisa Yritystietoturva subscription, which includes separate telephone support for security problems. Our partner F-Secure is responsible for phone support.'
          )}
        </p>
        <p>
          {t.OXWO(
            'The support line is open from 08.00-17.00. You can get the service in Finnish, Swedish or English. If you wish, the telephone support team can remotely contact your device to resolve the issue.'
          )}
        </p>
        <div>
          <a href="https://yrityksille.elisa.fi/ohje/yritystietoturva-puhelintuki" target="_BLANK" rel="noreferrer">
            {t.N4TR('See the telephone support number and in what situation the support can help (in Finnish)')}.
          </a>
        </div>
      </>
    );
  };

  switch (subscription.subscriptionType) {
    case SubscriptionType.MOBILE:
      return (
        <MobileSubscriptionAccordions
          pbxSolutions={companyInfo?.pbxSolutions || []}
          subscription={subscription}
          additionalServicesAccordionContent={
            <AdditionalServicesAccordionContent
              category={category}
              subscription={subscription}
              isMobileIdEnabledForCompany={isMobileIdEnabledForCompany}
              subscriptionActions={subscriptionActions?.items}
              addOnAssociations={addOnRulesAssociations}
              addOnDependencies={addOnRulesDependencies}
              isEmployee={false}
              pbxSolutions={companyInfo?.pbxSolutions}
            />
          }
          category={category}
          config={config}
          editingSection={editingSection}
          errors={errors}
          onClickAttachSubscriptionToVakio={onClickAttachSubscriptionToVakio}
          onClickOrderSim={onClickOrderSim}
          onClickSimActivation={onClickSimActivation}
          onEditSectionIfNoActionsPending={onEditSectionIfNoActionsPending}
          onShowDialog={onShowDialog}
          subscriptionActions={subscriptionActions}
          subscriptionStatus={subscriptionStatus}
          userOrPurposeOfUseAccordionContent={userOrPurposeOfUseAccordionContent}
          addOnRulesAssociations={addOnRulesAssociations}
          addOnRulesDependencies={addOnRulesDependencies}
          companyInfo={companyInfo}
          extensionNumberRange={extensionNumberRange}
          numberRange={numberRange}
          ringModels={ringModels}
          contacts={contacts || []}
        />
      );

    case SubscriptionType.MOBILE_BROADBAND:
      return (
        <div>
          <LinkableAccordion heading={t.PIZC(simCardMsg)} headingLevel="h3" id="sim-card-change">
            <SimCard
              pendingSimChanges={
                subscriptionStatus.pendingActionType === SubscriptionAction.SubscriptionActionTypeEnum.CHANGE_SIM
              }
              pendingSubscriptionChanges={subscriptionStatus.pendingActions}
              simCardConfig={{
                simCardNumber: subscription.details!.mobile!.simCardNumber,
                simType: subscription.details!.mobile!.simType,
              }}
              sourceIsTellus={
                subscription.sourceSystem === Subscription.SourceSystemEnum.TELLUS && !subscription.migrated
              }
              onClickSimActivation={() => onClickSimActivation(subscription.subscriptionDisplayId, category)}
              onClickOrderSim={() => onClickOrderSim(subscription.subscriptionDisplayId, category)}
              simCardChangeAllowed={simCardChangeAllowed(subscription)}
              hasEsimQrCode={hasEsimQrCode(subscription)}
            />
          </LinkableAccordion>
          <LinkableAccordion
            heading={t.U21Y(directoryStatusOfUserAndNumberMsg)}
            headingLevel="h3"
            id="user-or-use-purpose"
          >
            {userOrPurposeOfUseAccordionContent}
          </LinkableAccordion>
          <LinkableAccordion heading={t.ZUCA(barringServicesMsg)} headingLevel="h3" id="barring-services">
            {' '}
            {getBarringsContent()}
          </LinkableAccordion>
          <LinkableAccordion heading={t.LXSR(additionalServicesMsg)} headingLevel="h3" id="additional-services">
            <AdditionalServicesAccordionContent
              category={category}
              subscription={subscription}
              subscriptionActions={subscriptionActions?.items}
              addOnAssociations={addOnRulesAssociations}
              addOnDependencies={addOnRulesDependencies}
              isEmployee={false}
            />
          </LinkableAccordion>
          {(details.mobile?.deviceModel || details.mobile?.serialNumber) && (
            <AccordionsMain
              accordions={[
                {
                  headerName: t.L2MX(technicalDataMsg),
                  id: 'technical-information',
                  includedData: TechnicalInformationMobile(details.mobile),
                  displayed: true,
                },
              ]}
            />
          )}
        </div>
      );
    case SubscriptionType.MOBILE_M2M:
      return (
        <div>
          <LinkableAccordion heading={t.PIZC(simCardMsg)} headingLevel="h3" id="sim-card-change">
            <SimCard
              pendingSimChanges={
                subscriptionStatus.pendingActionType === SubscriptionAction.SubscriptionActionTypeEnum.CHANGE_SIM
              }
              pendingSubscriptionChanges={subscriptionStatus.pendingActions}
              simCardConfig={{
                simCardNumber: subscription.details!.mobile!.simCardNumber,
                simType: subscription.details!.mobile!.simType,
              }}
              sourceIsTellus={
                subscription.sourceSystem === Subscription.SourceSystemEnum.TELLUS && !subscription.migrated
              }
              onClickSimActivation={() => onClickSimActivation(subscription.subscriptionDisplayId, category)}
              onClickOrderSim={() => onClickOrderSim(subscription.subscriptionDisplayId, category)}
              simCardChangeAllowed={simCardChangeAllowed(subscription)}
              hasEsimQrCode={hasEsimQrCode(subscription)}
            />
          </LinkableAccordion>
          <LinkableAccordion
            heading={t.U21Y(directoryStatusOfUserAndNumberMsg)}
            headingLevel="h3"
            id="user-or-use-purpose"
          >
            {userOrPurposeOfUseAccordionContent}
          </LinkableAccordion>
        </div>
      );
    case SubscriptionType.LANDLINE:
      return (
        <AccordionsMain
          accordions={[
            {
              headerName: t.U21Y(directoryStatusOfUserAndNumberMsg),
              id: 'user-or-use-purpose',
              includedData: userOrPurposeOfUseAccordionContent,
              displayed: true,
            },
            {
              headerName: t.ZUCA(barringServicesMsg),
              id: 'barring-services',
              includedData: getBarringsContent(),
              displayed: true,
            },
            {
              headerName: t.LXSR(additionalServicesMsg),
              id: 'additional-services',
              includedData: (
                <AdditionalServicesAccordionContent
                  category={category}
                  subscription={subscription}
                  subscriptionActions={subscriptionActions?.items}
                  addOnAssociations={addOnRulesAssociations}
                  addOnDependencies={addOnRulesDependencies}
                  isEmployee={false}
                />
              ),
              displayed: true,
            },
          ]}
        />
      );

    case SubscriptionType.DEVICE:
      return (
        <AccordionsMain
          accordions={[
            {
              headerName: t.T1WX(userInformationMsg),
              id: 'user-or-use-purpose',
              includedData: userOrPurposeOfUseAccordionContent,
              displayed: true,
            },
            {
              headerName: t.LXSR(additionalServicesMsg),
              id: 'additional-services',
              includedData: getAdditionalServicesAccordionContent(
                details,
                onClickSubscriptionAddonDetails,
                category,
                subscription
              ),
              displayed: true,
            },
          ]}
        />
      );

    case SubscriptionType.MOBILE_PBX_LITE:
      return renderAccordions([createMobilePbxAccordion(t.L2MX(technicalDataMsg), subscription, details.mobilePbx)]);

    case SubscriptionType.MOBILE_PBX:
      return renderAccordions([
        createMobilePbxSettingsAccordion(t.EJQF(settingsMsg), details.mobilePbx),
        createMobilePbxAccordion(t.LJQ3('Numbering ranges'), subscription, details.mobilePbx),
      ]);

    case SubscriptionType.BROADBAND:
    case SubscriptionType.CORPORATE_NETWORK:
    case SubscriptionType.HOUSING_COMPANY_BROADBAND:
      return (
        <AccordionsMain
          accordions={[
            {
              headerName: t.T1WX(userInformationMsg),
              id: 'user-or-use-purpose',
              includedData: userOrPurposeOfUseAccordionContent,
              displayed: true,
            },
            {
              headerName: t.L2MX(technicalDataMsg),
              id: 'technical-information',
              includedData: details.broadband ? TechnicalInformationBroadBand(details.broadband) : <></>,
              displayed: true,
            },
          ]}
        />
      );

    case SubscriptionType.SPECIAL_NUMBER:
      // eslint-disable-next-line no-case-declarations
      const isEditable =
        subscription.details!.specialNumber?.pbxConfigurationDetails.pbxType ===
          SubscriptionPbxDetails.PbxTypeEnum.TAVOITETTAVUUSKETJU ||
        subscription.details!.specialNumber?.pbxConfigurationDetails.pbxType ===
          SubscriptionPbxDetails.PbxTypeEnum.VAKIO;
      return (
        <div>
          <LinkableAccordion heading={t.SC8P('Time settings')} headingLevel="h3" id="special-number-time-settings">
            <PbxTimeSettingsAccordionContent
              dispatch={dispatch}
              config={config}
              editing={editingSection === 'pbxTimeSettings'}
              errors={errors}
              subscriptionPbxConfiguration={subscription.details!.specialNumber!}
              numberRange={numberRange}
              onCancel={() => onEditSectionIfNoActionsPending()}
              onEdit={specialNumberOnEdit('pbxTimeSettings', isEditable)}
              pbxSolution={subscription}
              saving={Boolean(subscriptionActions?.saving)}
              subscriptionId={subscription.subscriptionId}
            />
          </LinkableAccordion>
          <LinkableAccordion heading={t.AN17('Number settings')} headingLevel="h3" id="special-number-settings">
            <PbxNumberSettingsAccordionContent
              config={config}
              editing={editingSection === 'pbxNumberSettings'}
              errors={errors}
              extensionNumberRange={extensionNumberRange}
              subscriptionPbxConfiguration={subscription.details!.specialNumber!}
              numberRange={numberRange}
              onCancel={() => onEditSectionIfNoActionsPending()}
              onEdit={specialNumberOnEdit('pbxNumberSettings', isEditable)}
              pbxSolution={subscription}
              saving={Boolean(subscriptionActions?.saving)}
              subscriptionId={subscription.subscriptionId}
              subscriptionDisplayId={subscription.subscriptionDisplayId}
            />
          </LinkableAccordion>
          <LinkableAccordion heading={t.Y8KX(purposeOfUseMsg)} headingLevel="h3" id="user-or-use-purpose">
            {userOrPurposeOfUseAccordionContent}
          </LinkableAccordion>
          <LinkableAccordion heading={t.LXSR(additionalServicesMsg)} headingLevel="h3" id="additional-services">
            <AdditionalServicesAccordionContent
              category={category}
              subscription={subscription}
              subscriptionActions={subscriptionActions?.items}
              addOnAssociations={addOnRulesAssociations}
              addOnDependencies={addOnRulesDependencies}
              isEmployee={false}
            />
          </LinkableAccordion>
        </div>
      );

    case SubscriptionType.MOBILE_PBX_END_USER_SERVICE:
      return (
        <div>
          <LinkableAccordion heading={t.U4MA('User')} headingLevel="h3" id="ring-subscription-user">
            {ringUserAccordionContent}
          </LinkableAccordion>
          <PbxAccordions
            companyInfo={companyInfo}
            configuredPbxSolution={
              subscription.details!.mobilePbxEndUserService &&
              subscription.details!.mobilePbxEndUserService &&
              (companyInfo?.pbxSolutions || []).find(
                pbxSolution =>
                  pbxSolution.subscriptionId === subscription.details!.mobilePbxEndUserService!.pbxSolutionId
              )
            }
            subscription={subscription}
            subscriptionStatus={subscriptionStatus}
            onShowDialog={onShowDialog}
            errors={errors}
            subscriptionActions={subscriptionActions}
            ringModels={ringModels}
            addOnRulesDependencies={addOnRulesDependencies}
            addOnRulesAssociations={addOnRulesAssociations}
            onEditSectionIfNoActionsPending={onEditSectionIfNoActionsPending}
            editingSection={editingSection}
            contacts={contacts || []}
            pbxServiceLevels={pbxServiceLevels}
          />
        </div>
      );
    case SubscriptionType.CABLE_TV:
    case SubscriptionType.E_FAX:
    case SubscriptionType.FIREWALL:
    case SubscriptionType.LAN:
    case SubscriptionType.NEGOTIATION:
    case SubscriptionType.SOFTWARE_PRODUCT:
      // eslint-disable-next-line no-case-declarations
      const getUncategorizedAccordions = (softwareProduct: SubscriptionDetailsSoftwareProduct = {}): Accordion[] => [
        {
          headerName: t.E112('Administrator'),
          id: 'admin-user',
          includedData: getAdminUserAccordionContent(softwareProduct, subscription.subscriptionContactName),
          displayed: !!softwareProduct.adminUserEmail,
        },
        {
          headerName: t.WSA8(`Elisa Yritystietoturva's data recovery and phone support`),
          id: 'domain',
          includedData: getYttInstructionsContent(),
          displayed: isYttInstructionsContentVisible(subscription.details?.selectedAddOns),
        },
        {
          headerName: t.ES5D('Domain name'),
          id: 'domain',
          includedData: getDomainAccordionContent(softwareProduct),
          displayed: !!softwareProduct.domain,
        },
        {
          headerName: t.LXSR(additionalServicesMsg),
          id: 'additional-services',
          includedData: getAdditionalServicesAccordionContent(
            details,
            onClickSubscriptionAddonDetails,
            category,
            subscription
          ),
          displayed: true,
        },
      ];
      return <AccordionsMain accordions={getUncategorizedAccordions(details.softwareProduct)} />;

    default:
      throw new Error('Invalid subscriptionType');
  }
};

// Unfortunately there's nothing separating netti lite subscriptions from regular ones in the data model and thus this is needed.
// TODO: if netti lite subscriptions acquire fields that separate them from regular mobilebroandband-type connections, this should be refactored.
const isNettiLiteSubscription = (subscriptionName?: string) =>
  /(netti\s*lite\s*[45]\s*g)/.test(subscriptionName?.toLowerCase() || '');

export const getUpdateButtonType = (subscription: Subscription, isChangeOfferAllowed?: boolean) => {
  const allowedSubscriptionStatuses = [SubscriptionStatusType.ACTIVE, SubscriptionStatusType.SUSPENDED, undefined];
  if (isChangeOfferAllowed && isNettiLiteSubscription(subscription?.subscriptionName)) {
    return SubscriptionDetailsButtonType.CHANGE_NETTI_LITE_SUBSCRIPTION;
  }
  if (allowedSubscriptionStatuses.includes(subscription!.subscriptionStatus) && isChangeOfferAllowed) {
    return SubscriptionDetailsButtonType.CHANGE_OFFER;
  }
  return SubscriptionDetailsButtonType.PENDING_ACTIONS;
};

export const SubscriptionDetailsAccordions = (props: SubscriptionDetailsProps) => {
  const { category, subscriptionId, subscriptions } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscription = findSubscription(subscriptionId, subscriptions);
  const config = useStore<State>().getState().config;
  const subscriptionAddOnRules = useSelector((state: State) => state?.selfservice?.subscriptionAddOnRules, deepEqual);
  const subscriptionActions = useSelector((state: State) => state?.selfservice?.pendingSubscriptionActions, deepEqual);
  const isTerminateSubscriptionForm = subscriptionActions?.terminateSubscriptionInitiated || false;

  const { siteBaseUrl } = useContext(SiteContext);
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));
  const onClickSubscriptionAddonDetails = (id: string, addOnId: string, cat: CategoryKey) => {
    navigate(`${paths.PS_HOME}/${CATEGORY_URL[cat]}/${id}/${addOnId}`);
  };
  const subscriptionStatus = getSubscriptionStatus(subscription, subscriptionActions?.items);
  const onEditSectionIfNoPendingSubscriptionActions = (section: string) => {
    if (subscription?.subscriptionStatus === SubscriptionStatusType.IN_ACTIVATION) {
      onShowDialog({ type: DialogType.MACD_FORBIDDEN_SUBSCRIPTION_IN_ACTIVATION });
      return false;
    }
    if (subscriptionStatus.pendingActions) {
      onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING });
      return false;
    }
    dispatch(editSection(section));
    return true;
  };
  const onClickAttachSubscriptionToVakio = (subId: string, cat: CategoryKey, isRing: boolean) => {
    if (subscription?.subscriptionStatus === SubscriptionStatusType.IN_ACTIVATION) {
      onShowDialog({ type: DialogType.MACD_FORBIDDEN_SUBSCRIPTION_IN_ACTIVATION });
    } else {
      const redirectionPage = isRing ? 'liitä-ring' : 'liitä-vakio';
      navigate(`${paths.PS_HOME}/${CATEGORY_URL[cat]}/${subId}/${redirectionPage}`);
    }
  };

  const ringServiceLevelName =
    subscription?.details?.mobile?.pbxConfiguration?.pbxConfigurationDetails.ringServiceLevelName;
  const associations =
    subscription && ringServiceLevelName
      ? subscriptionAddOnRules?.associationsMobilePbx
      : subscriptionAddOnRules?.associations;
  const dependencies =
    subscription && ringServiceLevelName
      ? subscriptionAddOnRules?.dependenciesMobilePbx
      : subscriptionAddOnRules?.dependencies;

  const showSuspendSubscriptionOptions = subscriptionActions?.suspendSubscriptionInitiated || false;
  if (!subscription?.details || showSuspendSubscriptionOptions || isTerminateSubscriptionForm) {
    return null;
  }

  const isMobileIdEnabledForCompany = Boolean(
    subscriptions?.mobileIdContracts?.some(
      contract => contract.contractStatus === Contract.ContractStatusEnum.ACTIVATED
    )
  );

  return (
    <Accordions
      subscription={subscription}
      details={subscription.details}
      onClickSubscriptionAddonDetails={onClickSubscriptionAddonDetails}
      subscriptionStatus={subscriptionStatus}
      category={category}
      config={config}
      onEditSectionIfNoActionsPending={onEditSectionIfNoPendingSubscriptionActions}
      onShowDialog={onShowDialog}
      onClickAttachSubscriptionToVakio={onClickAttachSubscriptionToVakio}
      siteBaseUrl={siteBaseUrl}
      addOnRulesAssociations={associations as AssociationRecord[]}
      addOnRulesDependencies={dependencies}
      isMobileIdEnabledForCompany={isMobileIdEnabledForCompany}
    />
  );
};

export const SubscriptionDetailsBillingAccount = ({ subscriptionId, subscriptions }: SubscriptionDetailsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const subscription = findSubscription(subscriptionId, subscriptions);
  const subscriptionActions = useSelector((state: State) => state?.selfservice?.pendingSubscriptionActions, deepEqual);
  const isAccessory = subscription?.subscriptionSubType?.toLowerCase() === 'accessories' || false;
  const isSwitchingBillingAccountAvailable = (sub: Subscription): boolean =>
    sub.subscriptionType !== SubscriptionType.SOFTWARE_PRODUCT && !isAccessory;
  const subscriptionStatus = getSubscriptionStatus(subscription, subscriptionActions?.items);
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));

  if (!(subscription?.billingAccountDisplayId && subscription.billingAccountId)) {
    return null;
  }

  return (
    <span>
      <Anchor
        className="of-subscription-details__to-billing-account"
        onClick={() => {
          if (subscription.billingAccountDisplayId) {
            navigate(generatePath(paths.BILLING_ACCOUNT, { billingAccountId: subscription.billingAccountDisplayId }));
          }
        }}
      >
        {subscription.billingAccountDisplayId}
      </Anchor>
      {isSwitchingBillingAccountAvailable(subscription) && (
        <span>
          <span className="of-vertical-bar"> | </span>
          <Anchor
            className="of-subscription-details__change-billing-account"
            onClick={() => {
              if (subscriptionStatus.pendingActions) {
                onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING });
              } else {
                navigate(pathname, {
                  replace: true,
                  state: {
                    subscription,
                    type: WizardType.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT,
                  },
                });
              }
            }}
          >
            {t.SEER(changeMsg)}
          </Anchor>
        </span>
      )}
    </span>
  );
};
