import * as CL from '@design-system/component-library';
import * as React from 'react';
import { EMAIL_REGEX } from '../../common/utils/emailUtils.js';
import { Email } from '../../common/react-hook-form/fields/index.js';
import { FormProvider, useForm } from 'react-hook-form';
import { Password } from '../../common/react-hook-form/fields/Password.js';
import { SSO_PASSWORD_REGEX } from '../../common/utils/validationUtils.js';
import {
  changePasswordMsg,
  customerServiceNumberLabelMsg,
  customerServiceNumberOpenLongMsg,
  fieldCantBeEmptyMsg,
  hereMsg,
  nextMsg,
  pleaseCheckEmailMsg,
  t,
} from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { CommonError } from '../../common/types/errors.js';

interface UserCredentials {
  email: string;
  password: string;
  newUser: boolean;
}

export interface RegistrationStep3Attrs {
  isEditable?: boolean;
  existingUserId?: string;
  errors: CommonError[];
  validationErrors: { email?: boolean };
  initialEmail?: string;
  registrationInProgress: boolean;
  redirectUri: string;
  redirectButtonText: string;
  isInExternalSystem: boolean;
}

interface RegistrationStep3Dispatchers {
  registerUser: (accountDetails: UserCredentials) => void;
  validateEmail: (email: string) => void;
}

type RegistrationStep3Props = RegistrationStep3Attrs & RegistrationStep3Dispatchers;

const ExistingUserActionButtons = ({
  redirectUri,
  redirectButtonText,
}: {
  redirectUri: string;
  redirectButtonText: string;
}) => {
  return (
    <>
      <div className="ds-margin-top--5">
        <CL.Link linkStyle="link-button" buttonColor="primary" linkHref={redirectUri}>
          {redirectButtonText}
        </CL.Link>
      </div>
      <div className="ds-margin-top--4">
        <CL.Link linkStyle="link-button" buttonColor="light" linkHref={paths.FORGOT_PASSWORD}>
          {t.R2MM(changePasswordMsg)}
        </CL.Link>
      </div>
    </>
  );
};

interface RegistrationFormProps {
  registrationInProgress: boolean;
  onSubmit: (values: UserCredentials) => void;
  validationErrors: { email?: boolean };
  validateEmail: (email: string) => void;
}
const UserVerificationForm = (props: RegistrationFormProps) => {
  const methods = useForm({ mode: 'all' });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(props.onSubmit)} noValidate style={{ maxWidth: '432px' }}>
        <div>{t.VDE4('Your email works as your username.')}</div>
        <div className="ds-padding-bottom--4">
          {t.HV0T('OmaElisa username is personal, do not share the details with anyone.')}
        </div>
        <Email
          validate={value => {
            if (!value) {
              return t.VPVR(fieldCantBeEmptyMsg);
            } else if (!EMAIL_REGEX.test(value)) {
              return t.ROMH(pleaseCheckEmailMsg);
            } else {
              // TODO Implement this as async function when it comes time to migrate this in fetch
              props.validateEmail(value);
              // Currently this error can only appear after user clicks submit
              if (props.validationErrors.email) {
                return t.I9QZ('This email has been linked to a username already');
              }
            }
            return undefined;
          }}
        />
        {props.validationErrors.email && (
          <div
            className="disclaimer-text ds-font-style--normal ds-font-weight--400 ds-font-size--small ds-padding-top--2 ds-padding-bottom--4"
            id="of-registration-step3-change-email"
          >
            {t.HQWX(
              'Use another email to register or contact our customer service by calling {0} ({1}). You can login to OmaElisa Service ',
              customerServiceNumberLabelMsg,
              t.HQWV(customerServiceNumberOpenLongMsg)
            )}
            <CL.Link linkHref={paths.SELF_SERVICE_HOME}>{`${t.BBNI(hereMsg)}`}</CL.Link>.
          </div>
        )}
        <Password
          label={t.B0M1('Create password')}
          placeholder={t.B0M1('Create password')}
          validate={(value: string) => {
            if (!value) {
              return t.VPVR(fieldCantBeEmptyMsg);
            } else if (!SSO_PASSWORD_REGEX.test(value)) {
              return t.G304(
                'The password must have at least 8 characters, one small letter, a capital letter and two numbers or special characters.'
              );
            }
            return undefined;
          }}
        />
        <div className="ds-padding-top--2">
          <CL.Button loading={props.registrationInProgress} size="l" type="submit">
            {t.F0MY(nextMsg)}
          </CL.Button>
        </div>
      </form>
    </FormProvider>
  );
};

const EditMode = ({
  email,
  existingUserId,
  isInExternalSystem,
  registrationInProgress,
  redirectUri,
  redirectButtonText,
  validationErrors,
  handleSubmit,
  validateEmail,
}: {
  email: string;
  existingUserId?: string;
  isInExternalSystem: boolean;
  registrationInProgress: boolean;
  redirectUri: string;
  redirectButtonText: string;
  validationErrors: { email?: boolean };
  handleSubmit: (values: UserCredentials) => void;
  validateEmail: (email: string) => void;
}) => {
  if (existingUserId) {
    return (
      <>
        <h4 id="of-registration-step3-existing-account" className="ds-margin--0">
          {t.SMSV('It seems that you have already credentials to corporate OmaElisa.')}
        </h4>
        <div className="ds-margin-top--0" id="of-registration-step3-existing-user-id">
          {t.QIK1('Username')}: <strong>{existingUserId}</strong>
        </div>
        <ExistingUserActionButtons redirectButtonText={redirectButtonText} redirectUri={redirectUri} />
      </>
    );
  }

  if (isInExternalSystem) {
    return (
      <>
        <h4 id="of-registration-step3-existing-account" className="ds-margin--0">
          {t.AS6N('You have an Elisa Ring ID.')}
        </h4>
        <p className="ds-margin-top--0">{t.G5NF('You can log in to Oma Elisa with the same credentials.')}</p>
        <div id="of-registration-step3-existing-user-id">
          {t.QIK1('Username')}: <strong>{email}</strong>
        </div>
        <ExistingUserActionButtons redirectButtonText={redirectButtonText} redirectUri={redirectUri} />
      </>
    );
  }

  return (
    <UserVerificationForm
      onSubmit={handleSubmit}
      registrationInProgress={registrationInProgress}
      validationErrors={validationErrors}
      validateEmail={validateEmail}
    />
  );
};

const ViewMode = ({ email, registrationInProgress }: { email?: string; registrationInProgress: boolean }) =>
  !registrationInProgress && email ? (
    <div id="of-registration-step3-user-email">
      {t.QIK1('Username')}: {email}
    </div>
  ) : null;

export const RegistrationStep3 = ({
  isEditable,
  registerUser,
  existingUserId,
  errors,
  validateEmail,
  validationErrors,
  initialEmail,
  registrationInProgress,
  redirectUri,
  redirectButtonText,
  isInExternalSystem,
}: React.PropsWithChildren<RegistrationStep3Props>) => {
  const [email, setEmail] = React.useState(initialEmail);

  const handleSubmit = (values: UserCredentials) => {
    const userCredentials: UserCredentials = { ...values, newUser: true };
    setEmail(userCredentials.email);
    registerUser(userCredentials);
  };

  const hasErrors = errors.length > 0 && !isInExternalSystem;

  return (
    <div className="of-registration-step of-registration-scene-step3">
      {isEditable && hasErrors && (
        <div className="ds-margin-bottom--3">
          <CL.Disclaimer
            disclaimerType="alert"
            icon={<CL.Icon icon="warning" size="m" />}
            title={t.RX8Z('Username creation failed')}
            text={t.XLTQ(
              'Unfortunately something went wrong, we are sorry about this. Please retry soon. In case of an urgent matter, please contact our customer service by calling {0} ({1}).',
              customerServiceNumberLabelMsg,
              t.HQWV(customerServiceNumberOpenLongMsg)
            )}
          />
        </div>
      )}
      {isEditable ? (
        <EditMode
          email={email || ''}
          existingUserId={existingUserId}
          isInExternalSystem={isInExternalSystem}
          registrationInProgress={registrationInProgress}
          redirectUri={redirectUri}
          redirectButtonText={redirectButtonText}
          validationErrors={validationErrors}
          handleSubmit={handleSubmit}
          validateEmail={validateEmail}
        />
      ) : (
        <ViewMode email={email} registrationInProgress={registrationInProgress} />
      )}
    </div>
  );
};
