import { DnsRecordEditableType } from '../../generated/api/dnsRecordEditableType.js';
import { TableUrlParams } from '../Table/index.js';
import { t } from '../../common/i18n/index.js';
import type { DnsRecordEditableValue } from '../../generated/api/dnsRecordEditableValue.js';
import type { DnsRecordRequest } from '../../generated/api/dnsRecordRequest.js';
import type { DnsRecordSupportedValue } from '../../generated/api/dnsRecordSupportedValue.js';
import type { LoaderFunctionArgs } from 'react-router-dom';
import type { SortOrder } from '../../common/utils/searchUtils.js';

export type DnsRecordEditableForm = {
  name: string;
  ttl: number;
  type: DnsRecordEditableType;
  values: DnsRecordEditableValue[];
};

export type DnsRecordEditable = DnsRecord<DnsRecordEditableType, DnsRecordEditableValue>;

export type DnsRecordSupported = DnsRecord<string, DnsRecordSupportedValue>;

export type DnsRecord<Type = string, Value = object> = {
  id: number;
  name: string;
  rawValues: Value[];
  ttl: number;
  type: Type;
  values: string;
};

export type DnsRecordsRequestQuery = {
  nextCursor?: string;
  sortField?: string;
  sortOrder?: string;
  limit?: number;
  type?: string[];
};

export type DnsRecordsSearchParams = {
  [TableUrlParams.SORT]: string;
  [TableUrlParams.ORDER]: SortOrder;
  [TableUrlParams.LIMIT]: string;
  [TableUrlParams.SEARCH]: string;
  recordType: string;
};

export const DnsRecordEditableTypes: readonly DnsRecordEditableType[] = Object.values(DnsRecordEditableType);

export const DnsRecordEditableDropdown = DnsRecordEditableTypes.map(type => ({
  label: type,
  value: type,
}));

export const DnsRecordEditableFields = {
  name: 'name',
  ttl: 'ttl',
  type: 'type',
  values: 'values',
} as const;

export const createDnsRecordsLoader =
  <T>(
    loader: (subscriptionId: string, query: DnsRecordsRequestQuery) => Promise<T>,
    defaults: Pick<DnsRecordsSearchParams, TableUrlParams.SORT | TableUrlParams.ORDER | TableUrlParams.LIMIT>
  ) =>
  ({ params, request }: LoaderFunctionArgs) => {
    const search = new URL(request.url).searchParams;

    return loader(params.subscriptionId!, {
      nextCursor: search.get(TableUrlParams.CURSOR)?.toString(),
      sortField: search.get(TableUrlParams.SORT) ?? defaults.sort,
      sortOrder: (search.get(TableUrlParams.ORDER) ?? defaults.order).toUpperCase(),
      limit: Number(search.get(TableUrlParams.LIMIT) ?? defaults.limit),
      type: search.get('recordType')?.split('|'),
    });
  };

export const getDnsRecordActionMessage = (action: string) => {
  switch (action) {
    case 'update':
      return t.JKG2('Record value edited');
    case 'create':
      return t.KG30('New record created');
    case 'delete':
      return t.B9GC('Record deleted');
    default:
      return action;
  }
};

// FormValues are always of type 'string' but our API requires the numeric values to be sent as proper numbers.
export const getDnsRecordRequest = ({ name, type, ttl, values }: DnsRecordEditableForm) =>
  ({
    name: name,
    ttl: ttl,
    type: type,
    values: values.map(value => {
      switch (type) {
        case 'MX':
          return { ...value, priority: Number(value.priority) };
        case 'SRV':
          return { ...value, port: Number(value.port), priority: Number(value.priority), weight: Number(value.weight) };
        default:
          return value;
      }
    }),
  } satisfies DnsRecordRequest);

export const isDnsRecordEditable = (record: DnsRecord): record is DnsRecordEditable =>
  DnsRecordEditableTypes.some(type => type === record.type);

/**
 * Even though the record is of `DnsRecordEditable`, there can be
 * special conditions under which we don't allow it to be modified.
 *
 * - NS types can only be edited for the subdomains, not for the main domain.
 */
export const isDnsRecordEditingAllowed = (record: Pick<DnsRecordEditable, 'name' | 'type'>, domainName: string) =>
  record.name !== `${domainName}.` || record.type !== DnsRecordEditableType.NS;

/* At least one record type is only allowed a single value */
export const isDnsRecordTypeValueSingular = (type: unknown) => type === DnsRecordEditableType.CNAME;
