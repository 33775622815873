import type { Action } from 'redux';
import type { ActionWithId } from '../common/store.js';
import type { CommonError } from '../../common/types/errors.js';
import type { CompositeListSort } from '../../components/CompositeListHeader/index.js';
import type { GenericError } from '../../generated/api/models.js';
import type { ItemFilter } from '../../common/types/subscription.js';

export enum TypeKeys {
  // Direct analytic events without any other interaction events
  APPLY_ANALYTICS_EVENT = 'APPLY_ANALYTICS_EVENT',
  APPLY_WIZARD_EVENT = 'APPLY_WIZARD_EVENT',

  // Forgot password
  FORGOT_PASSWORD_STEP_NUMBER = 'FORGOT_PASSWORD_STEP_NUMBER',
  SEND_OTP_TO_USER = 'SEND_OTP_TO_USER',
  SEND_OTP_TO_USER_FULFILLED = 'SEND_OTP_TO_USER_FULFILLED',
  SEND_OTP_TO_USER_FAILED = 'SEND_OTP_TO_USER_FAILED',
  VALIDATE_OTP = 'VALIDATE_OTP',
  VALIDATE_OTP_FULFILLED = 'VALIDATE_OTP_FULFILLED',
  VALIDATE_OTP_FAILED = 'VALIDATE_OTP_FAILED',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_PASSWORD_FULFILLED = 'RESET_PASSWORD_FULFILLED',
  RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED',

  // Myself
  POLL_FOR_MYSELF_CHANGES = 'POLL_FOR_MYSELF_CHANGES',
  POLL_FOR_MYSELF_CHANGES_FAILED = 'POLL_FOR_MYSELF_CHANGES_FAILED',
  POLL_FOR_MYSELF_CHANGES_FULFILLED = 'POLL_FOR_MYSELF_CHANGES_FULFILLED',
  STOP_POLL_FOR_MYSELF_CHANGES = 'STOP_POLL_FOR_MYSELF_CHANGES',

  // Billing accounts
  LOAD_BILLING_ACCOUNTS = 'LOAD_BILLING_ACCOUNTS',
  LOAD_BILLING_ACCOUNTS_FAILED = 'LOAD_BILLING_ACCOUNTS_FAILED',
  LOAD_BILLING_ACCOUNTS_FULFILLED = 'LOAD_BILLING_ACCOUNTS_FULFILLED',
  EDIT_BILLING_ACCOUNT = 'EDIT_BILLING_ACCOUNT',
  UPSERT_BILLING_ACCOUNT = 'UPSERT_BILLING_ACCOUNT',
  UPSERT_BILLING_ACCOUNT_FAILED = 'UPSERT_BILLING_ACCOUNT_FAILED',
  UPSERT_BILLING_ACCOUNT_FULFILLED = 'UPSERT_BILLING_ACCOUNT_FULFILLED',
  UPSERT_BILLING_ACCOUNT_SCHEDULED_CHANGE_FULFILLED = 'UPSERT_BILLING_ACCOUNT_SCHEDULED_CHANGE_FULFILLED',
  UPSERT_BILLING_ACCOUNT_SCHEDULED_CHANGE_FAILED = 'UPSERT_BILLING_ACCOUNT_SCHEDULED_CHANGE_FAILED',
  CANCEL_BILLING_ACCOUNT_EDIT = 'CANCEL_BILLING_ACCOUNT_EDIT',
  LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS = 'LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS',
  LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS_FAILED = 'LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS_FAILED',
  LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS_FULLFILLED = 'LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS_FULLFILLED',
  PREPARE_BILLING_ACCOUNT_EDIT = 'PREPARE_BILLING_ACCOUNT_EDIT',

  // Bill channels
  LOAD_BILL_CHANNELS = 'LOAD_BILL_CHANNELS',
  LOAD_BILL_CHANNELS_FAILED = 'LOAD_BILL_CHANNELS_FAILED',
  LOAD_BILL_CHANNELS_FULFILLED = 'LOAD_BILL_CHANNELS_FULFILLED',

  // Commercial products
  LOAD_ONLINE_MODEL = 'LOAD_ONLINE_MODEL',
  LOAD_ONLINE_MODEL_BY_MODEL_TYPE = 'LOAD_ONLINE_MODEL_BY_MODEL_TYPE',
  LOAD_ONLINE_MODEL_FAILED = 'LOAD_ONLINE_MODEL_FAILED',
  LOAD_ONLINE_MODEL_FULFILLED = 'LOAD_ONLINE_MODEL_FULFILLED',
  LOAD_ONLINE_MODELS = 'LOAD_ONLINE_MODELS',
  LOAD_ONLINE_MODELS_FAILED = 'LOAD_ONLINE_MODELS_FAILED',
  LOAD_ONLINE_MODELS_FULFILLED = 'LOAD_ONLINE_MODELS_FULFILLED',
  LOAD_ONLINE_MODEL_WITH_EFFECTIVE_PRICES = 'LOAD_ONLINE_MODEL_WITH_EFFECTIVE_PRICES',
  CLEAR_CAMPAIGNS = 'CLEAR_CAMPAIGNS',
  GET_CAMPAIGNS = 'GET_CAMPAIGNS',
  GET_CAMPAIGNS_FAILED = 'GET_CAMPAIGNS_FAILED',
  GET_CAMPAIGNS_FULFILLED = 'GET_CAMPAIGNS_FULFILLED',

  // Company info
  LOAD_COMPANY_INFO = 'LOAD_COMPANY_INFO',
  LOAD_COMPANY_INFO_FULFILLED = 'LOAD_COMPANY_INFO_FULFILLED',
  LOAD_COMPANY_INFO_FAILED = 'LOAD_COMPANY_INFO_FAILED',
  GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND = 'GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND',
  GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND_FULFILLED = 'GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND_FULFILLED',
  GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND_FAILED = 'GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND_FAILED',
  GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES = 'GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES',
  GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES_FULFILLED = 'GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES_FULFILLED',
  GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES_FAILED = 'GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES_FAILED',
  UPDATE_ACCOUNT_SETTINGS = 'UPDATE_ACCOUNT_SETTINGS',
  UPDATE_ACCOUNT_SETTINGS_FULFILLED = 'UPDATE_ACCOUNT_SETTINGS_FULFILLED',
  UPDATE_ACCOUNT_SETTINGS_FAILED = 'UPDATE_ACCOUNT_SETTINGS_FAILED',

  // Contacts
  LOAD_CONTACTS = 'LOAD_CONTACTS',
  LOAD_CONTACTS_FAILED = 'LOAD_CONTACTS_FAILED',
  LOAD_CONTACTS_FULFILLED = 'LOAD_CONTACTS_FULFILLED',
  UPSERT_CONTACT = 'UPSERT_CONTACT',
  UPSERT_CONTACT_FAILED = 'UPSERT_CONTACT_FAILED',
  UPSERT_CONTACT_FULFILLED = 'UPSERT_CONTACT_FULFILLED',
  RESET_CONTACTS = 'RESET_CONTACTS',

  UPDATE_USER_RIGHTS = 'UPDATE_USER_RIGHTS',
  UPDATE_USER_RIGHTS_FULFILLED = 'UPDATE_USER_RIGHTS_FULFILLED',
  UPDATE_USER_RIGHTS_FAILED = 'UPDATE_USER_RIGHTS_FAILED',

  // Customer orders
  LOAD_CUSTOMER_ORDERS = 'LOAD_CUSTOMER_ORDERS',
  LOAD_CUSTOMER_ORDERS_FAILED = 'LOAD_CUSTOMER_ORDERS_FAILED',
  LOAD_CUSTOMER_ORDERS_FULFILLED = 'LOAD_CUSTOMER_ORDERS_FULFILLED',
  LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO = 'LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO',
  LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO_FAILED = 'LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO_FAILED',
  LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO_FULFILLED = 'LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO_FULFILLED',

  // Employee customer orders
  LOAD_EMPLOYEE_CUSTOMER_ORDERS = 'LOAD_EMPLOYEE_CUSTOMER_ORDERS',
  LOAD_EMPLOYEE_CUSTOMER_ORDERS_FAILED = 'LOAD_EMPLOYEE_CUSTOMER_ORDERS_FAILED',
  LOAD_EMPLOYEE_CUSTOMER_ORDERS_FULFILLED = 'LOAD_EMPLOYEE_CUSTOMER_ORDERS_FULFILLED',

  // Subscription Actions
  LOAD_SUBSCRIPTION_ACTIONS = 'LOAD_SUBSCRIPTION_ACTIONS',
  LOAD_SUBSCRIPTION_ACTIONS_FAILED = 'LOAD_SUBSCRIPTION_ACTIONS_FAILED',
  LOAD_SUBSCRIPTION_ACTIONS_FULFILLED = 'LOAD_SUBSCRIPTION_ACTIONS_FULFILLED',

  // Employee Subscription Actions
  LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS = 'LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS',
  LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS_FAILED = 'LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS_FAILED',
  LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS_FULFILLED = 'LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS_FULFILLED',

  // review
  REVIEW = 'REVIEW',
  REVIEW_FAILED = 'REVIEW_FAILED',
  REVIEW_FULFILLED = 'REVIEW_FULFILLED',

  // General
  SHOW_DIALOG = 'SHOW_DIALOG',
  CLOSE_DIALOG = 'CLOSE_DIALOG',
  START_NOTIFICATION = 'START_NOTIFICATION',
  CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION',
  OTHER_ACTION = '__any_other_action_type__',

  // Invoices
  LOAD_INVOICES = 'LOAD_INVOICES',
  LOAD_INVOICES_FULFILLED = 'LOAD_INVOICES_FULFILLED',
  LOAD_INVOICES_FAILED = 'LOAD_INVOICES_FAILED',
  INITIALIZE_PAYMENT = 'INITIALIZE_PAYMENT',
  INITIALIZE_PAYMENT_FULFILLED = 'INITIALIZE_PAYMENT_FULFILLED',
  INITIALIZE_PAYMENT_FAILED = 'INITIALIZE_PAYMENT_FAILED',
  FINALIZE_PAYMENT = 'FINALIZE_PAYMENT',
  FINALIZE_PAYMENT_FULFILLED = 'FINALIZE_PAYMENT_FULFILLED',
  FINALIZE_PAYMENT_FAILED = 'FINALIZE_PAYMENT_FAILED',
  INITIALIZE_ONLINE_ORDER_CARD_PAYMENT = 'INITIALIZE_ONLINE_ORDER_CARD_PAYMENT',
  INITIALIZE_ONLINE_ORDER_CARD_PAYMENT_FULFILLED = 'INITIALIZE_ONLINE_ORDER_CARD_PAYMENT_FULFILLED',
  INITIALIZE_ONLINE_ORDER_CARD_PAYMENT_FAILED = 'INITIALIZE_ONLINE_ORDER_CARD_PAYMENT_FAILED',
  FINALIZE_ONLINE_ORDER_CARD_PAYMENT = 'FINALIZE_ONLINE_ORDER_CARD_PAYMENT',
  FINALIZE_ONLINE_ORDER_CARD_PAYMENT_FULFILLED = 'FINALIZE_ONLINE_ORDER_CARD_PAYMENT_FULFILLED',
  FINALIZE_ONLINE_ORDER_CARD_PAYMENT_FAILED = 'FINALIZE_ONLINE_ORDER_CARD_PAYMENT_FAILED',
  SET_DELIVERY_DETAILS_FOR_ONLINE_ORDER_CARD_PAYMENT = 'SET_DELIVERY_DETAILS_FOR_ONLINE_ORDER_CARD_PAYMENT',
  LOAD_INVOICE_DOCUMENTS = 'LOAD_INVOICE_DOCUMENTS',
  LOAD_INVOICE_DOCUMENTS_FULFILLED = 'LOAD_INVOICE_DOCUMENTS_FULFILLED',
  LOAD_INVOICE_DOCUMENTS_FAILED = 'LOAD_INVOICE_DOCUMENTS_FAILED',

  // Layout
  FOOTER_SET_VISIBILITY = 'FOOTER_SET_VISIBILITY',

  // Online order
  SUBMIT_ONLINE_ORDER = 'SUBMIT_ONLINE_ORDER',
  SUBMIT_ONLINE_ORDER_FULFILLED = 'SUBMIT_ONLINE_ORDER_FULLFILLED',
  SUBMIT_ONLINE_ORDER_FAILED = 'SUBMIT_ONLINE_ORDER_FAILED',
  POLL_FOR_CREDIT_CHECK = 'POLL_FOR_CREDIT_CHECK',
  POLL_FOR_CREDIT_CHECK_FAILED = 'POLL_FOR_CREDIT_CHECK_FAILED',
  POLL_FOR_CREDIT_CHECK_FULFILLED = 'POLL_FOR_CREDIT_CHECK_FULFILLED',
  POLL_FOR_CREDIT_CHECK_V2_FULFILLED = 'POLL_FOR_CREDIT_CHECK_V2_FULFILLED',
  SET_ONLINE_ORDER_SUBSCRIPTION_CONFIGURATION = 'SET_ONLINE_ORDER_SUBSCRIPTION_CONFIGURATION',

  SEARCH_PICKUP_POINT = 'SEARCH_PICKUP_POINT',
  SEARCH_PICKUP_POINT_FULFILLED = 'SEARCH_PICKUP_POINT_FULFILLED',
  SEARCH_PICKUP_POINT_FAILED = 'SEARCH_PICKUP_POINT_FAILED',

  // Site actions (should actually not be under selfservice but are needed also for selfservice to work)
  LOG_OUT = 'LOG_OUT',
  // Forces the browser to load the given URL instead of application preforming internal redirect to another component.
  HARD_REDIRECT = 'HARD_REDIRECT',

  // Subscriptions
  LOAD_SUBSCRIPTIONS = 'LOAD_SUBSCRIPTIONS',
  LOAD_SUBSCRIPTIONS_FAILED = 'LOAD_SUBSCRIPTIONS_FAILED',
  LOAD_SUBSCRIPTIONS_FULFILLED = 'LOAD_SUBSCRIPTIONS_FULFILLED',

  // Change offer
  CHANGE_OFFER = 'CHANGE_OFFER',
  CHANGE_OFFER_FAILED = 'CHANGE_OFFER_FAILED',
  CHANGE_OFFER_FULFILLED = 'CHANGE_OFFER_FULFILLED',

  // Subscription actions
  TERMINATE_SUBSCRIPTION_DEVICE_SERVICE = 'TERMINATE_SUBSCRIPTION_DEVICE_SERVICE',
  TERMINATE_SUBSCRIPTION_DEVICE_SERVICE_FAILED = 'TERMINATE_SUBSCRIPTION_DEVICE_SERVICE_FAILED',
  TERMINATE_SUBSCRIPTION_DEVICE_SERVICE_FULFILLED = 'TERMINATE_SUBSCRIPTION_DEVICE_SERVICE_FULFILLED',
  CHANGE_SUBSCRIPTION_BILLING_ACCOUNT = 'CHANGE_SUBSCRIPTION_BILLING_ACCOUNT',
  CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FAILED = 'CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FAILED',
  CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FULFILLED = 'CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FULFILLED',
  TERMINATE_SUBSCRIPTION = 'TERMINATE_SUBSCRIPTION',
  TERMINATE_SUBSCRIPTION_FAILED = 'TERMINATE_SUBSCRIPTION_FAILED',
  TERMINATE_SUBSCRIPTION_FULFILLED = 'TERMINATE_SUBSCRIPTION_FULFILLED',
  SHOW_SUSPEND_SUBSCRIPTION_OPTIONS = 'SHOW_SUSPEND_SUBSCRIPTION_OPTIONS',
  CLOSE_SUSPEND_SUBSCRIPTION_OPTIONS = 'CLOSE_SUSPEND_SUBSCRIPTION_OPTIONS',
  SHOW_TERMINATE_SUBSCRIPTION_FORM = 'SHOW_TERMINATE_SUBSCRIPTION_FORM',
  CLOSE_TERMINATE_SUBSCRIPTION_FORM = 'CLOSE_TERMINATE_SUBSCRIPTION_FORM',
  SET_DONOR_PHONE_NUMBER = 'SET_DONOR_PHONE_NUMBER',
  SUSPEND_SUBSCRIPTION = 'SUSPEND_SUBSCRIPTION',
  SUSPEND_SUBSCRIPTION_FULFILLED = 'SUSPEND_SUBSCRIPTION_FULFILLED',
  SUSPEND_SUBSCRIPTION_FAILED = 'SUSPEND_SUBSCRIPTION_FAILED',
  RESUME_SUBSCRIPTION = 'RESUME_SUBSCRIPTION',
  RESUME_SUBSCRIPTION_FULFILLED = 'RESUME_SUBSCRIPTION_FULFILLED',
  RESUME_SUBSCRIPTION_FAILED = 'RESUME_SUBSCRIPTION_FAILED',
  EDIT_SUBSCRIPTION_SECTION = 'EDIT_SUBSCRIPTION_SECTION',
  CLEAR_DUPLICATE_CONTACT = 'CLEAR_DUPLICATE_CONTACT',
  CHANGE_SUBSCRIPTION_USER_INFO = 'CHANGE_SUBSCRIPTION_USER_INFO',
  CHANGE_SUBSCRIPTION_USER_INFO_FAILED = 'CHANGE_SUBSCRIPTION_USER_INFO_FAILED',
  CHANGE_SUBSCRIPTION_USER_INFO_WITH_DUPLICATE_FAILED = 'CHANGE_SUBSCRIPTION_USER_INFO_WITH_DUPLICATE_FAILED',
  CHANGE_SUBSCRIPTION_USER_INFO_FULFILLED = 'CHANGE_SUBSCRIPTION_USER_INFO_FULFILLED',
  USE_DUPLICATE_CONTACT = 'USE_DUPLICATE_CONTACT',
  ATTACH_SUBSCRIPTION_TO_PBX = 'ATTACH_SUBSCRIPTION_TO_PBX',
  ATTACH_SUBSCRIPTION_TO_PBX_FAILED = 'ATTACH_SUBSCRIPTION_TO_PBX_FAILED',
  ATTACH_SUBSCRIPTION_TO_PBX_FULFILLED = 'ATTACH_SUBSCRIPTION_TO_PBX_FULFILLED',
  CHANGE_SUBSCRIPTION_PBX_CONFIGURATION = 'CHANGE_SUBSCRIPTION_PBX_CONFIGURATION',
  CHANGE_SUBSCRIPTION_PBX_CONFIGURATION_FAILED = 'CHANGE_SUBSCRIPTION_PBX_CONFIGURATION_FAILED',
  CHANGE_SUBSCRIPTION_PBX_CONFIGURATION_FULFILLED = 'CHANGE_SUBSCRIPTION_PBX_CONFIGURATION_FULFILLED',
  CHANGE_SIM_CARD = 'CHANGE_SIM_CARD',
  CHANGE_SIM_CARD_FAILED = 'CHANGE_SIM_CARD_FAILED',
  CHANGE_SIM_CARD_FULFILLED = 'CHANGE_SIM_CARD_FULFILLED',
  CHANGE_ADDONS = 'CHANGE_ADDONS',
  CHANGE_ADDONS_FAILED = 'CHANGE_ADDONS_FAILED',
  CHANGE_ADDONS_FULFILLED = 'CHANGE_ADDONS_FULFILLED',
  BARRINGS_VALIDATION_FAILED = 'BARRINGS_VALIDATION_FAILED',
  POST_EPP_MAINTENANCE = 'POST_EPP_MAINTENANCE',
  POST_EPP_MAINTENANCE_FULFILLED = 'POST_EPP_MAINTENANCE_FULFILLED',
  POST_EPP_MAINTENANCE_FAILED = 'POST_EPP_MAINTENANCE_FAILED',
  POST_EPP_REDEEM_TERMINATE = 'POST_EPP_REDEEM_TERMINATE',
  POST_EPP_REDEEM_TERMINATE_FULFILLED = 'POST_EPP_REDEEM_TERMINATE_FULFILLED',
  POST_EPP_REDEEM_TERMINATE_FAILED = 'POST_EPP_REDEEM_TERMINATE_FAILED',
  RING_ADDONS_VALIDATION_FAILED = 'RING_ADDONS_VALIDATION_FAILED',
  VALIDATE_PHONE_NUMBER = 'VALIDATE_PHONE_NUMBER',
  VALIDATE_PHONE_NUMBER_FAILED = 'VALIDATE_PHONE_NUMBER_FAILED',
  VALIDATE_PHONE_NUMBER_FULFILLED = 'VALIDATE_PHONE_NUMBER_FULFILLED',
  VALIDATE_SIM = 'VALIDATE_SIM',
  VALIDATE_SIM_FAILED = 'VALIDATE_SIM_FAILED',
  VALIDATE_SIM_FULFILLED = 'VALIDATE_SIM_FULFILLED',
  START_BROADBAND_FIXER = 'START_BROADBAND_FIXER',
  START_BROADBAND_FIXER_FAILED = 'START_BROADBAND_FIXER_FAILED',
  START_BROADBAND_FIXER_FULFILLED = 'START_BROADBAND_FIXER_FULFILLED',
  START_MOBILE_FIXER = 'START_MOBILE_FIXER',
  START_MOBILE_FIXER_FAILED = 'START_MOBILE_FIXER_FAILED',
  START_MOBILE_FIXER_FULFILLED = 'START_MOBILE_FIXER_FULFILLED',
  // Device change by admin
  SET_SUBSCRIPTION_DETAIL_FOR_DEVICE_CHANGE = 'SET_SUBSCRIPTION_DETAIL_FOR_DEVICE_CHANGE',
  RESET_SUBSCRIPTION_DETAIL_FOR_DEVICE_CHANGE = 'RESET_SUBSCRIPTION_DETAIL_FOR_DEVICE_CHANGE',

  // MobileId contract actions
  SUBMIT_MOBILEID_CONTRACT = 'SUBMIT_MOBILEID_CONTRACT',
  SUBMIT_MOBILEID_CONTRACT_FULFILLED = 'SUBMIT_MOBILEID_CONTRACT_FULFILLED',
  SUBMIT_MOBILEID_CONTRACT_FAILED = 'SUBMIT_MOBILEID_CONTRACT_FAILED',
  TERMINATE_MOBILEID_CONTRACT = 'TERMINATE_MOBILEID_CONTRACT',
  TERMINATE_MOBILEID_CONTRACT_FULFILLED = 'TERMINATE_MOBILEID_CONTRACT_FULFILLED',
  TERMINATE_MOBILEID_CONTRACT_FAILED = 'TERMINATE_MOBILEID_CONTRACT_FAILED',
  GET_MOBILEID_CONTRACTS = 'GET_MOBILEID_CONTRACTS',
  GET_MOBILEID_CONTRACTS_FULFILLED = 'GET_MOBILEID_CONTRACTS_FULFILLED',
  GET_MOBILEID_CONTRACTS_FAILED = 'GET_MOBILEID_CONTRACTS_FAILED',

  // Subscription barrings and addons
  LOAD_SUBSCRIPTION_ADD_ON_RULES = 'LOAD_SUBSCRIPTION_ADD_ON_RULES',
  LOAD_SUBSCRIPTION_ADD_ON_RULES_FULFILLED = 'LOAD_SUBSCRIPTION_ADD_ON_RULES_FULFILLED',
  LOAD_SUBSCRIPTION_ADD_ON_RULES_FAILED = 'LOAD_SUBSCRIPTION_ADD_ON_RULES_FAILED',

  LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX = 'LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX',
  LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX_FULFILLED = 'LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX_FULFILLED',
  LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX_FAILED = 'LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX_FAILED',

  // Support cases
  ADD_SUPPORT_CASE = 'ADD_SUPPORT_CASE',
  ADD_SUPPORT_CASE_FULFILLED = 'ADD_SUPPORT_CASE_FULFILLED',
  ADD_SUPPORT_CASE_FAILED = 'ADD_SUPPORT_CASE_FAILED',
  LOAD_SUPPORT_CASES = 'LOAD_SUPPORT_CASES',
  LOAD_SUPPORT_CASES_FULFILLED = 'LOAD_SUPPORT_CASES_FULFILLED',
  LOAD_SUPPORT_CASES_FAILED = 'LOAD_SUPPORT_CASES_FAILED',
  LOAD_SUPPORT_CASE_FULFILLED = 'LOAD_SUPPORT_CASE_FULFILLED',
  LOAD_SUPPORT_CASE_HISTORY = 'LOAD_SUPPORT_CASE_HISTORY',
  LOAD_SUPPORT_CASE_HISTORY_FULFILLED = 'LOAD_SUPPORT_CASE_HISTORY_FULFILLED',
  LOAD_SUPPORT_CASE_HISTORY_FAILED = 'LOAD_SUPPORT_CASE_HISTORY_FAILED',
  LOAD_OPEN_SUPPORT_CASES_FOR_INVOICES = 'LOAD_OPEN_SUPPORT_CASES_FOR_INVOICES',
  LOAD_OPEN_SUPPORT_CASES_FOR_INVOICES_FULFILLED = 'LOAD_OPEN_SUPPORT_CASES_FOR_INVOICES_FULFILLED',
  LOAD_OPEN_SUPPORT_CASES_FOR_INVOICES_FAILED = 'LOAD_OPEN_SUPPORT_CASES_FOR_INVOICES_FAILED',

  // Anonymous support cases
  ADD_ANONYMOUS_SUPPORT_CASE = 'ADD_ANONYMOUS_SUPPORT_CASE',
  ADD_ANONYMOUS_SUPPORT_CASE_FULFILLED = 'ADD_ANONYMOUS_SUPPORT_CASE_FULFILLED',
  ADD_ANONYMOUS_SUPPORT_CASE_FAILED = 'ADD_ANONYMOUS_SUPPORT_CASE_FAILED',
  SET_CASE_SENT_STATUS = 'SET_CASE_SENT_STATUS',

  // Leads
  ADD_ANONYMOUS_LEAD = 'ADD_ANONYMOUS_LEAD',
  ADD_ANONYMOUS_LEAD_FULFILLED = 'ADD_ANONYMOUS_LEAD_FULFILLED',
  ADD_ANONYMOUS_LEAD_FAILED = 'ADD_ANONYMOUS_LEAD_FAILED',
  ADD_ANONYMOUS_LEAD_CLEAR = 'ADD_ANONYMOUS_LEAD_CLEAR',
  ADD_LEAD = 'ADD_LEAD',
  ADD_LEAD_FULFILLED = 'ADD_LEAD_FULFILLED',
  ADD_LEAD_FAILED = 'ADD_LEAD_FAILED',
  SET_LEAD_SENT_STATUS = 'SET_LEAD_SENT_STATUS',

  // User
  LOG_IN = 'LOG_IN',
  LOG_IN_FULFILLED = 'LOG_IN_FULFILLED',
  LOG_IN_FAILED = 'LOG_IN_FAILED',
  MFA_REQUIRED = 'MFA_REQUIRED',
  SELECT_USERNAME = 'SELECT_USERNAME',
  SELECT_USERNAME_FAILED = 'SELECT_USERNAME_FAILED',
  SELECT_USERNAME_FULFILLED = 'SELECT_USERNAME_FULFILLED',
  SET_ACTIVE_ACCOUNT = 'SET_ACTIVE_ACCOUNT',
  IDENTIFY_STRONGLY = 'IDENTIFY_STRONGLY',
  IDENTIFY_STRONGLY_FAILED = 'IDENTIFY_STRONGLY_FAILED',
  IDENTIFY_STRONGLY_FULFILLED = 'IDENTIFY_STRONGLY_FULFILLED',
  SWITCH_ACCOUNT = 'SWITCH_ACCOUNT',
  SWITCH_ACCOUNT_FULFILLED = 'SWITCH_ACCOUNT_FULFILLED',
  SWITCH_ACCOUNT_FAILED = 'SWITCH_ACCOUNT_FAILED',
  RESOLVE_MISSING_PRIMARY_ACCOUNT = 'RESOLVE_MISSING_PRIMARY_ACCOUNT',
  RESOLVE_MISSING_PRIMARY_ACCOUNT_FULFILLED = 'RESOLVE_MISSING_PRIMARY_ACCOUNT_FULFILLED',
  RESOLVE_MISSING_PRIMARY_ACCOUNT_FAILED = 'RESOLVE_MISSING_PRIMARY_ACCOUNT_FAILED',
  GET_MYSELF_SECONDARY_ACCOUNT = 'GET_MYSELF_SECONDARY_ACCOUNT',
  GET_MYSELF_SECONDARY_ACCOUNT_FULFILLED = 'GET_MYSELF_SECONDARY_ACCOUNT_FULFILLED',
  GET_MYSELF_SECONDARY_ACCOUNT_FAILED = 'GET_MYSELF_SECONDARY_ACCOUNT_FAILED',

  // Ring Admin
  ADD_RING_ADMIN = 'ADD_RING_ADMIN',
  ADD_RING_ADMIN_FULFILLED = 'ADD_RING_ADMIN_FULFILLED',
  ADD_RING_ADMIN_FAILED = 'ADD_RING_ADMIN_FAILED',
  REMOVE_RING_ADMIN = 'REMOVE_RING_ADMIN',
  REMOVE_RING_ADMIN_FULFILLED = 'REMOVE_RING_ADMIN_FULFILLED',
  REMOVE_RING_ADMIN_FAILED = 'REMOVE_RING_ADMIN_FAILED',

  // Employee
  PROCESS_ELISAID_LOGIN = 'PROCESS_ELISAID_LOGIN',
  PROCESS_ELISAID_LOGIN_FULFILLED = 'PROCESS_ELISAID_LOGIN_FULFILLED',
  PROCESS_ELISAID_LOGIN_FAILED = 'PROCESS_ELISAID_LOGIN_FAILED',
  LOGOUT_FULFILLED = 'LOGOUT_FULFILLED',
  LOGOUT_FAILED = 'LOGOUT_FAILED',
  GET_ELISA_ID_SESSION = 'GET_ELISA_ID_SESSION',
  GET_ELISA_ID_SESSION_FULFILLED = 'GET_ELISA_ID_SESSION_FULFILLED',
  GET_ELISA_ID_SESSION_FAILED = 'GET_ELISA_ID_SESSION_FAILED',
  GET_ELISA_ID_ACCESS_TOKEN = 'GET_ELISA_ID_ACCESS_TOKEN',
  GET_ELISA_ID_ACCESS_TOKEN_FULFILLED = 'GET_ELISA_ID_ACCESS_TOKEN_FULFILLED',
  GET_ELISA_ID_ACCESS_TOKEN_FAILED = 'GET_ELISA_ID_ACCESS_TOKEN_FAILED',
  ELISA_ID_LOGOUT = 'ELISA_ID_LOGOUT',
  ELISA_ID_LOGOUT_FULFILLED = 'ELISA_ID_LOGOUT_FULFILLED',
  ELISA_ID_LOGOUT_FAILED = 'ELISA_ID_LOGOUT_FAILED',
  PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING = 'PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING',
  PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING_FAILED = 'PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING_FAILED',
  PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING_FULFILLED = 'PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING_FULFILLED',
  VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING = 'VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING',
  VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING_FULFILLED = 'VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING_FULFILLED',
  VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING_FAILED = 'VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING_FAILED,',
  GET_USER = 'GET_USER',
  GET_USER_FULFILLED = 'GET_USER_FULFILLED',
  GET_USER_FAILED = 'GET_USER_FAILED',
  UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS',
  UPDATE_USER_DETAILS_FULFILLED = 'UPDATE_USER_DETAILS_FULFILLED',
  UPDATE_USER_DETAILS_FAILED = 'UPDATE_USER_DETAILS_FAILED',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED',
  CHANGE_PASSWORD_FULFILLED = 'CHANGE_PASSWORD_FULFILLED',
  CANCEL_USER_DETAILS_EDIT = 'CANCEL_USER_DETAILS_EDIT',
  GET_EMPLOYEE_SUBSCRIPTIONS = 'GET_EMPLOYEE_SUBSCRIPTIONS',
  GET_EMPLOYEE_SUBSCRIPTIONS_FULLFILLED = 'GET_EMPLOYEE_SUBSCRIPTIONS_FULLFILLED',
  GET_EMPLOYEE_SUBSCRIPTIONS_FAILED = 'GET_EMPLOYEE_SUBSCRIPTIONS_FAILED',
  CHECK_VALID_EMPLOYEE_SUBSCRIPTION = 'CHECK_VALID_EMPLOYEE_SUBSCRIPTION',
  GET_SUBSCRIPTION_LOCAL_USAGE = 'GET_SUBSCRIPTION_LOCAL_USAGE',
  GET_SUBSCRIPTION_LOCAL_USAGE_FULFILLED = 'GET_SUBSCRIPTION_LOCAL_USAGE_FULFILLED',
  GET_SUBSCRIPTION_LOCAL_USAGE_FAILED = 'GET_SUBSCRIPTION_LOCAL_USAGE_FAILED',
  GET_SUBSCRIPTION_ROAMING_USAGE = 'GET_SUBSCRIPTION_ROAMING_USAGE',
  GET_SUBSCRIPTION_ROAMING_USAGE_FULFILLED = 'GET_SUBSCRIPTION_ROAMING_USAGE_FULFILLED',
  GET_SUBSCRIPTION_ROAMING_USAGE_FAILED = 'GET_SUBSCRIPTION_ROAMING_USAGE_FAILED',
  GET_SUBSCRIPTION_VOICE_CALLS = 'GET_SUBSCRIPTION_VOICE_CALLS',
  GET_SUBSCRIPTION_VOICE_CALLS_FULFILLED = 'GET_SUBSCRIPTION_VOICE_CALLS_FULFILLED',
  GET_SUBSCRIPTION_VOICE_CALLS_FAILED = 'GET_SUBSCRIPTION_VOICE_CALLS_FAILED',
  RESET_ERRORS = 'RESET_ERRORS',
  GET_SUBSCRIPTION_TEXT_MESSAGES = 'GET_SUBSCRIPTION_TEXT_MESSAGES',
  GET_SUBSCRIPTION_TEXT_MESSAGES_FULFILLED = 'GET_SUBSCRIPTION_TEXT_MESSAGES_FULFILLED',
  GET_SUBSCRIPTION_TEXT_MESSAGES_FAILED = 'GET_SUBSCRIPTION_TEXT_MESSAGES_FAILED',
  UPDATE_ELISAID_SESSION = 'UPDATE_ELISAID_SESSION',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  VERIFY_EMAIL_FULFILLED = 'VERIFY_EMAIL_FULFILLED',
  VERIFY_EMAIL_FAILED = 'VERIFY_EMAIL_FAILED',
  RESEND_EMAIL_VERIFICATION_LINK = 'RESEND_EMAIL_VERIFICATION_LINK',
  RESEND_EMAIL_VERIFICATION_LINK_FULFILLED = 'RESEND_EMAIL_VERIFICATION_LINK_FULFILLED',
  RESEND_EMAIL_VERIFICATION_LINK_FAILED = 'RESEND_EMAIL_VERIFICATION_LINK_FAILED',
  // Onboarding Employee
  INITIATE_EMPLOYEE_ONBOARDING = 'INITIATE_EMPLOYEE_ONBOARDING',
  INITIATE_EMPLOYEE_ONBOARDING_FULFILLED = 'INITIATE_EMPLOYEE_ONBOARDING_FULFILLED',
  INITIATE_EMPLOYEE_ONBOARDING_FAILED = 'INITIATE_EMPLOYEE_ONBOARDING_FAILED',
  INITIATE_EMPLOYEE_ONBOARDING_RESEND_FAILED = 'INITIATE_EMPLOYEE_ONBOARDING_RESEND_FAILED',

  // Onboarding requests
  LOAD_ONBOARDING_REQUESTS = 'LOAD_ONBOARDING_REQUESTS',
  LOAD_ONBOARDING_REQUESTS_FULFILLED = 'LOAD_ONBOARDING_REQUESTS_FULFILLED',
  LOAD_ONBOARDING_REQUESTS_FAILED = 'LOAD_ONBOARDING_REQUESTS_FAILED',

  // Resources
  DISPLAY_MORE_NUMBERS_FROM_RANGE = 'DISPLAY_MORE_NUMBERS_FROM_RANGE',
  LOAD_HOLIDAYS = 'LOAD_HOLIDAYS',
  LOAD_HOLIDAYS_FAILED = 'LOAD_HOLIDAYS_FAILED',
  LOAD_HOLIDAYS_FULFILLED = 'LOAD_HOLIDAYS_FULFILLED',
  LOAD_NUMBER_RANGE = 'LOAD_NUMBER_RANGE',
  LOAD_NUMBER_RANGE_FAILED = 'LOAD_NUMBER_RANGE_FAILED',
  LOAD_NUMBER_RANGE_FULFILLED = 'LOAD_NUMBER_RANGE_FULFILLED',
  PRERESERVE_NUMBERS = 'PRERESERVE_NUMBERS',
  PRERESERVE_NUMBERS_FULFILLED = 'PRERESERVE_NUMBERS_FULFILLED',
  PRERESERVE_NUMBERS_FAILED = 'PRERESERVE_NUMBERS_FAILED',
  SELECTED_COMMERCIAL_PRODUCTS_AMOUNT_CHANGE = 'SELECTED_COMMERCIAL_PRODUCTS_AMOUNT_CHANGE',
  SEARCH_COMPANY = 'SEARCH_COMPANY',
  SEARCH_COMPANY_FULFILLED = 'SEARCH_COMPANY_FULFILLED',
  SEARCH_COMPANY_FAILED = 'SEARCH_COMPANY_FAILED',
  SEARCH_DOMAIN = 'SEARCH_DOMAIN',
  SEARCH_DOMAIN_FULFILLED = 'SEARCH_DOMAIN_FULFILLED',
  SEARCH_DOMAIN_FAILED = 'SEARCH_DOMAIN_FAILED',
  RESET_COMPANY_SEARCH_RESULT = 'RESET_COMPANY_SEARCH_RESULT',
  SEARCH_PUBLIC_PAGES = 'SEARCH_PUBLIC_PAGES',
  SEARCH_PUBLIC_PAGES_FULFILLED = 'SEARCH_PUBLIC_PAGES_FULFILLED',
  SEARCH_PUBLIC_PAGES_FAILED = 'SEARCH_PUBLIC_PAGES_FAILED',
  RESET_SEARCH_PUBLIC_PAGES_RESULT = 'RESET_SEARCH_PUBLIC_PAGES_RESULT',
  GET_PUBLIC_PAGE = 'GET_PUBLIC_PAGE',
  GET_PUBLIC_PAGE_FAILED = 'GET_PUBLIC_PAGE_FAILED',
  GET_PUBLIC_PAGE_FULFILLED = 'GET_PUBLIC_PAGE_FULFILLED',
  GET_PUBLIC_PAGE_NOT_FOUND = 'GET_PUBLIC_PAGE_NOT_FOUND',

  // External authentication
  EXTERNAL_AUTHENTICATION_FAILED = 'EXTERNAL_AUTHENTICATION_FAILED',

  GET_AUTHORIZATION_REQUEST = 'GET_AUTHORIZATION_REQUEST',
  GET_AUTHORIZATION_REQUEST_FULFILLED = 'GET_AUTHORIZATION_REQUEST_FULFILLED',
  GET_AUTHORIZATION_REQUEST_FAILED = 'GET_AUTHORIZATION_REQUEST_FAILED',

  PROCESS_AUTHORIZATION_REQUEST = 'PROCESS_AUTHORIZATION_REQUEST',
  PROCESS_AUTHORIZATION_REQUEST_FULFILLED = 'PROCESS_AUTHORIZATION_REQUEST_FULFILLED',
  PROCESS_AUTHORIZATION_REQUEST_FAILED = 'PROCESS_AUTHORIZATION_REQUEST_FAILED',

  VALIDATE_USER_AUTHORITY = 'VALIDATE_USER_AUTHORITY',
  VALIDATE_USER_AUTHORITY_FULFILLED = 'VALIDATE_USER_AUTHORITY_FULFILLED',
  VALIDATE_USER_AUTHORITY_FAILED = 'VALIDATE_USER_AUTHORITY_FAILED',

  REGISTER_USER = 'REGISTER_USER',
  REGISTER_USER_FAILED = 'REGISTER_USER_FAILED',
  REGISTER_USER_FULFILLED = 'REGISTER_USER_FULFILLED',
  REGISTER_USER_ERROR_FULFILLED = 'REGISTER_USER_ERROR_FULFILLED',

  USER_EXISTS = 'USER_EXISTS',
  USER_EXISTS_FAILED = 'USER_EXISTS_FAILED',
  USER_EXISTS_FULFILLED = 'USER_EXISTS_FULFILLED',

  // DEVICE CHECKOUT
  ADD_TO_CART = 'ADD_TO_CART',
  ADD_CART_EVENT_UPDATE_TO_ANALYTICS = 'ADD_CART_EVENT_UPDATE_TO_ANALYTICS',
  DUPLICATE_CONTACTS_CHECK = 'DUPLICATE_CONTACTS_CHECK',
  DUPLICATE_CONTACTS_CHECK_FAILED = 'DUPLICATE_CONTACTS_CHECK_FAILED',
  DUPLICATE_CONTACTS_CHECK_FULFILLED = 'DUPLICATE_CONTACTS_CHECK_FULFILLED',
  DUPLICATE_CONTACTS_CHECK_SUBMIT = 'DUPLICATE_CONTACTS_CHECK_SUBMIT',
  UPDATE_CART_ITEM = 'UPDATE_CART_ITEM',
  UPDATE_CART_ITEM_PAYMENT = 'UPDATE_CART_ITEM_PAYMENT',
  POST_PROCESS_CART_ITEMS = 'POST_PROCESS_CART_ITEMS',
  PROCESS_CART_ITEMS = 'PROCESS_CART_ITEMS',
  PROCESS_BILLING_ACCOUNT = 'PROCESS_BILLING_ACCOUNT',
  PROCESS_DELIVERY_DETAILS = 'PROCESS_DELIVERY_DETAILS',
  PROCESS_ADDITIONAL_PARAMETER_IN_CART_ITEMS = 'PROCESS_ADDITIONAL_PARAMETER_IN_CART_ITEMS',
  UPDATE_SELECTED_BA_ID = 'UPDATE_SELECTED_BA_ID',
  RESET_MOVE_TO_NEXT_STEP = 'RESET_MOVE_TO_NEXT_STEP',
  CLEAR_CART_ITEMS = 'CLEAR_CART_ITEMS',
  CLEAR_DELIVERY_DETAILS = 'CLEAR_DELIVERY_DETAILS',
  UPSERT_PERSON_BILLING_ACCOUNT = 'UPSERT_PERSON_BILLING_ACCOUNT',
  UPSERT_PERSON_BILLING_ACCOUNT_FULFILLED = 'UPSERT_PERSON_BILLING_ACCOUNT_FULFILLED',
  UPSERT_PERSON_BILLING_ACCOUNT_FAILED = 'UPSERT_PERSON_BILLING_ACCOUNT_FAILED',
  SET_CART_UPDATED = 'SET_CART_UPDATED',
  GET_DELIVERY_METHODS = 'GET_DELIVERY_METHODS',
  GET_DELIVERY_METHODS_FULFILLED = 'GET_DELIVERY_METHODS_FULFILLED',
  GET_DELIVERY_METHODS_FAILED = 'GET_DELIVERY_METHODS_FAILED',

  // PRIMARY COMPANY
  SELECT_PRIMARY_ACCOUNT_AND_LOG_IN = 'SELECT_PRIMARY_ACCOUNT_AND_LOG_IN',
  SELECT_PRIMARY_ACCOUNT_AND_LOG_IN_FAILED = 'SELECT_PRIMARY_ACCOUNT_AND_LOG_IN_FAILED',
  SELECT_PRIMARY_ACCOUNT_AND_LOG_IN_FULFILLED = 'SELECT_PRIMARY_ACCOUNT_AND_LOG_IN_FULFILLED',
  // MISSING MOBILE
  ADD_MISSING_MOBILE_NUMBER_AND_LOG_IN = 'ADD_MISSING_MOBILE_NUMBER_AND_LOG_IN',
  ADD_MISSING_MOBILE_NUMBER_AND_LOG_IN_FAILED = 'ADD_MISSING_MOBILE_NUMBER_AND_LOG_IN_FAILED',
  ADD_MISSING_MOBILE_NUMBER_AND_LOG_IN_FULFILLED = 'ADD_MISSING_MOBILE_NUMBER_AND_LOG_IN_FULFILLED',

  // Virtual Catalogs - replaces product catalog
  LOAD_VIRTUAL_CATALOGS = 'LOAD_VIRTUAL_CATALOGS',
  LOAD_VIRTUAL_CATALOGS_FULFILLED = 'LOAD_VIRTUAL_CATALOGS_FULFILLED',
  LOAD_VIRTUAL_CATALOGS_FAILED = 'LOAD_VIRTUAL_CATALOGS_FAILED',
  DELETE_VIRTUAL_CATALOG = 'DELETE_VIRTUAL_CATALOG',
  DELETE_VIRTUAL_CATALOG_FAILED = 'DELETE_VIRTUAL_CATALOG_FAILED',
  DELETE_VIRTUAL_CATALOG_FULFILLED = 'DELETE_VIRTUAL_CATALOG_FULFILLED',
  DELETE_VIRTUAL_CATALOG_DRAFT = 'DELETE_VIRTUAL_CATALOG_DRAFT',
  DELETE_VIRTUAL_CATALOG_DRAFT_FULFILLED = 'DELETE_VIRTUAL_CATALOG_DRAFT_FULFILLED',
  DELETE_VIRTUAL_CATALOG_DRAFT_FAILED = 'DELETE_VIRTUAL_CATALOG_DRAFT_FAILED',
  PUBLISH_VIRTUAL_CATALOG_DRAFT = 'PUBLISH_VIRTUAL_CATALOG_DRAFT',
  PUBLISH_VIRTUAL_CATALOG_DRAFT_FULFILLED = 'PUBLISH_VIRTUAL_CATALOG_DRAFT_FULFILLED',
  PUBLISH_VIRTUAL_CATALOG_DRAFT_FAILED = 'PUBLISH_VIRTUAL_CATALOG_DRAFT_FAILED',
  UPSERT_VIRTUAL_CATALOG = 'UPSERT_VIRTUAL_CATALOG',
  UPSERT_VIRTUAL_CATALOG_FAILED = 'UPSERT_VIRTUAL_CATALOG_FAILED',
  UPSERT_VIRTUAL_CATALOG_FULFILLED = 'UPSERT_VIRTUAL_CATALOG_FULFILLED',
  COPY_VIRTUAL_CATALOG = 'COPY_VIRTUAL_CATALOG',
  COPY_VIRTUAL_CATALOG_FAILED = 'COPY_VIRTUAL_CATALOG_FAILED',
  COPY_VIRTUAL_CATALOG_FULFILLED = 'COPY_VIRTUAL_CATALOG_FULFILLED',
  SET_EDIT_VIRTUAL_CATALOG = 'SET_EDIT_VIRTUAL_CATALOG',
  SET_SELECTED_VIRTUAL_CATALOG = 'SET_SELECTED_VIRTUAL_CATALOG',
  REPLACE_VIRTUAL_CATALOGS_FULFILLED = 'REPLACE_VIRTUAL_CATALOGS_FULFILLED',
  REPLACE_VIRTUAL_CATALOGS_FAILED = 'REPLACE_VIRTUAL_CATALOGS_FAILED',

  // AUTHENTICATION VALIDATION
  AUTH_VALIDATE = 'AUTH_VALIDATE',
  AUTH_VALIDATE_FULFILLED = 'AUTH_VALIDATE_FULFILLED',
  AUTH_VALIDATE_FAILED = 'AUTH_VALIDATE_FAILED',

  // Address
  SEARCH_ADDRESS = 'SEARCH_ADDRESS',
  SEARCH_ADDRESS_FULFILLED = 'SEARCH_ADDRESS_FULFILLED',
  SEARCH_ADDRESS_FAILED = 'SEARCH_ADDRESS_FAILED',
  RESET_ADDRESS_SEARCH_RESULT = 'RESET_ADDRESS_SEARCH_RESULT',
  GET_COMMERCIAL_AVAILABILITY = 'GET_COMMERCIAL_AVAILABILITY',
  GET_COMMERCIAL_AVAILABILITY_FULFILLED = 'GET_COMMERCIAL_AVAILABILITY_FULFILLED',
  GET_COMMERCIAL_AVAILABILITY_FAILED = 'GET_COMMERCIAL_AVAILABILITY_FAILED',

  // User accounts from SSO by MDMID
  LOAD_ACCOUNT_KEY_USERS = 'LOAD_ACCOUNT_KEY_USERS',
  LOAD_ACCOUNT_KEY_USERS_FULFILLED = 'LOAD_ACCOUNT_KEY_USERS_FULFILLED',
  LOAD_ACCOUNT_KEY_USERS_FAILED = 'LOAD_ACCOUNT_KEY_USERS_FAILED',

  // Google Ecommerce Events
  GOOGLE_ECOMMERCE_EVENT = 'GOOGLE_ECOMMERCE_EVENT',
  GOOGLE_ECOMMERCE_ADD_PAYMENT_INFO = 'GOOGLE_ECOMMERCE_ADD_PAYMENT_INFO',
  GOOGLE_ECOMMERCE_ADD_SHIPPING_INFO = 'GOOGLE_ECOMMERCE_ADD_SHIPPING_INFO',
  GOOGLE_ECOMMERCE_ADD_TO_CART = 'GOOGLE_ECOMMERCE_ADD_TO_CART',
  GOOGLE_ECOMMERCE_CHECKOUT = 'GOOGLE_ECOMMERCE_CHECKOUT',
  GOOGLE_ECOMMERCE_IMPRESSIONS_PUSHED = 'GOOGLE_ECOMMERCE_IMPRESSIONS_PUSHED',
  GOOGLE_ECOMMERCE_PRODUCT_CLICK = 'GOOGLE_ECOMMERCE_PRODUCT_CLICK',
  GOOGLE_ECOMMERCE_PRODUCT_DETAIL = 'GOOGLE_ECOMMERCE_PRODUCT_DETAIL',
  GOOGLE_ECOMMERCE_PURCHASE = 'GOOGLE_ECOMMERCE_PURCHASE',
  GOOGLE_ECOMMERCE_REMOVE_FROM_CART = 'GOOGLE_ECOMMERCE_REMOVE_FROM_CART',

  // DNS Management
  ADD_DNS_RECORD = 'ADD_DNS_RECORD',
  ADD_DNS_RECORD_FULFILLED = 'ADD_DNS_RECORD_FULFILLED',
  ADD_DNS_RECORD_FAILED = 'ADD_DNS_RECORD_FAILED',
  UPDATE_DNS_RECORD_FULFILLED = 'UPDATE_DNS_RECORD_FULFILLED',
  UPDATE_DNS_RECORD_FAILED = 'UPDATE_DNS_RECORD_FAILED',
  DELETE_DNS_RECORD_FULFILLED = 'DELETE_DNS_RECORD_FULFILLED',
  DELETE_DNS_RECORD_FAILED = 'DELETE_DNS_RECORD_FAILED',

  // Used to save shopping-cart for post processing for reminders
  NOTIFY_SHOPPING_CART_CHANGE = 'NOTIFY_SHOPPING_CART_CHANGE',
  SUBMIT_SHOPPING_CART_CHANGE = 'SUBMIT_SHOPPING_CART_CHANGE',
  SUBMIT_SHOPPING_CART_CHANGE_FULFILLED = 'SUBMIT_SHOPPING_CART_CHANGE_FULFILLED',
  SUBMIT_SHOPPING_CART_CHANGE_FAILED = 'SUBMIT_SHOPPING_CART_CHANGE_FAILED',

  // Chat
  SET_CHAT_SCRIPT_LOAD_STATUS = 'SET_CHAT_SCRIPT_LOAD_STATUS',
  SET_CHAT_STYLE_LOAD_STATUS = 'SET_CHAT_STYLE_LOAD_STATUS',

  // Auxiliary eSim
  AUXILIARY_ESIM_ORDER_SET_PHASE = 'AUXILIARY_ESIM_ORDER_SET_PHASE',
  AUXILIARY_ESIM_ORDER_GET_SUBSCRIPTION_FULFILLED = 'AUXILIARY_ESIM_ORDER_GET_SUBSCRIPTION_FULFILLED',
  AUXILIARY_ESIM_ORDER_GET_SUBSCRIPTION_FAILED = 'AUXILIARY_ESIM_ORDER_GET_SUBSCRIPTION_FAILED',
  AUXILIARY_ESIM_ORDER_SEND = 'AUXILIARY_ESIM_ORDER_SEND',
  AUXILIARY_ESIM_ORDER_SEND_FAILED = 'AUXILIARY_ESIM_ORDER_SEND_FAILED',
  AUXILIARY_ESIM_ORDER_SEND_FULFILLED = 'AUXILIARY_ESIM_ORDER_SEND_FULFILLED',
  AUXILIARY_ESIM_ORDER_VALIDATE = 'AUXILIARY_ESIM_ORDER_VALIDATE',
  AUXILIARY_ESIM_ORDER_VALIDATE_FULFILLED = 'AUXILIARY_ESIM_ORDER_VALIDATE_FULFILLED',
  AUXILIARY_ESIM_ORDER_VALIDATE_FAILED = 'AUXILIARY_ESIM_ORDER_VALIDATE_FAILED',

  // Punchout
  SUBMIT_PUNCHOUT_ORDER = 'SUBMIT_PUNCHOUT_ORDER',
  SUBMIT_PUNCHOUT_ORDER_FULFILLED = 'SUBMIT_PUNCHOUT_ORDER_FULLFILLED',
  SUBMIT_PUNCHOUT_ORDER_FAILED = 'SUBMIT_PUNCHOUT_ORDER_FAILED',

  // Reports
  LOAD_EPP_REPORT = 'LOAD_EPP_REPORT',
  LOAD_EPP_REPORT_FULFILLED = 'LOAD_EPP_REPORT_FULFILLED',
  LOAD_EPP_REPORT_FAILED = 'LOAD_EPP_REPORT_FAILED',

  // Fixed broadband order
  SUBMIT_FIXED_BROADBAND_ORDER = 'SUBMIT_FIXED_BROADBAND_ORDER',
  SUBMIT_FIXED_BROADBAND_ORDER_FULFILLED = 'SUBMIT_FIXED_BROADBAND_ORDER_FULFILLED',
  SUBMIT_FIXED_BROADBAND_ORDER_FAILED = 'SUBMIT_FIXED_BROADBAND_ORDER_FAILED',

  // SERVICE FEE
  GET_SERVICE_FEE = 'GET_SERVICE_FEE',
  GET_SERVICE_FEE_FULFILLED = 'GET_SERVICE_FEE_FULFILLED',
  GET_SERVICE_FEE_FAILED = 'GET_SERVICE_FEE_FAILED',
}

export interface OtherAction {
  type: TypeKeys.OTHER_ACTION;
}

export interface CrudAction<T extends TypeKeys> extends ActionWithId {
  type: T;
}

export interface DisplayItemsLoadAction<T extends TypeKeys, U> extends CrudAction<T> {
  displayId?: string;
  filter?: ItemFilter<U>;
  limit?: number;
  search?: string;
  reporting?: boolean;
  sort?: CompositeListSort | CompositeListSort[];
  status?: string;
}

export interface ErrorAction<T extends TypeKeys> extends Action {
  errors?: CommonError[];
  params?: { [s: string]: string };
  type: T;
}

export type ErrorActionCreator<T extends TypeKeys> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
) => ErrorAction<T>;

export const generateActionId = (): number => {
  if (typeof performance !== 'undefined') {
    return performance.now();
  } else {
    return Date.now();
  }
};
