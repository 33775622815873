import type { ReactNode } from 'react';

import './StickyFooter.scss';

export interface StickyFooterProps {
  active: boolean;
  className?: string;
  children: ReactNode;
}

export const StickyFooter = ({ active, className, children }: StickyFooterProps) => (
  <div
    className={['of-sticky-footer', `of-sticky-footer--${active ? 'visible' : 'hidden'}`, className]
      .filter(i => i)
      .join(' ')}
  >
    {children}
  </div>
);
