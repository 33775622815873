import {
  CommercialProductSubType,
  OnlineModelCategory,
  StockAvailabilityStatus,
} from '../../../generated/api/models.js';
import { calculateVatIfApplicable } from './productDetailsUtils.js';
import { formatSum } from '../../../common/utils/priceUtils.js';
import { monthlyChargeSummaryMsg, t } from '../../../common/i18n/index.js';
import type { CommercialProduct, Offer, Range } from '../../../generated/api/models.js';

const priceSummaryForMonthlyPrice = (p: CommercialProduct, effectiveVAT: string) =>
  t.O108(
    monthlyChargeSummaryMsg,
    (p.payments || '').toString(),
    formatSum(p.payments === 1 ? p.oneTimeCharge : (p.payments || 0) * (p.monthlyRecurringCharge || 0)) || '',
    effectiveVAT
  );

export const priceSummaryForOnetimePrice = (effectiveVAT: string) => t.V7I7('Single fare, VAT {}%', effectiveVAT);

export const formatPriceSummary = (cp: CommercialProduct, effectiveVAT: string) =>
  cp.payments === 1 ? priceSummaryForOnetimePrice(effectiveVAT) : priceSummaryForMonthlyPrice(cp, effectiveVAT);

export const formatPrice = (cp: CommercialProduct, quantity = 1, includeVat?: boolean) => {
  if (!cp) {
    return '';
  } else if (cp.payments === 1) {
    return formatSum(calculateVatIfApplicable(cp.oneTimeCharge!, includeVat) * quantity);
  }
  return (
    `${formatSum(calculateVatIfApplicable(cp.monthlyRecurringCharge!, includeVat) * quantity)}/${t.XXVX('month')}` || ''
  );
};

export const paymentBoxLabel = (cp: CommercialProduct) =>
  cp.payments === 1 ? t.ASEI('Single fare') : `${cp.payments?.toString()} ${t.XXVX('month')}`;

export const priceSummaryLabel = (cp: CommercialProduct) =>
  cp.payments === 1 ? t.GOBY('Single fares') : ` ${t.P6BC('Monthly charges')}`;

export const priceSummarySuffix = (cp: CommercialProduct) => {
  return cp.productSubType === CommercialProductSubType.EPP_DEVICE ? ' (EPP)' : '';
};

export const createAvailability = (offer: Offer) => {
  const colors = ['red', 'orange', 'green'];
  const textFns = {
    [StockAvailabilityStatus.SHIPMENTS_ARRIVING]: [
      () => t.HGEL('Temporarily out of stock, will be delivered by backorder'),
      () => t.UJRH('Order now, restricted availability'),
      () => t.V2YZ('In Stock'),
      () => t.MADI('In Stock'),
    ],
    [StockAvailabilityStatus.NO_SHIPMENTS_ARRIVING]: [
      () => t.T3CO('Out of Stock'),
      () => t.PLPS('Last ones in the stock, act fast'),
      () => t.L80Q('Restricted availability - act fast'),
      () => t.MADI('In Stock'),
    ],
    [StockAvailabilityStatus.DIRECT_DELIVERY]: [
      () => t.RAO4('Direct delivery product. Can not guarantee the product will be delivered by the next weekday.'),
    ],
    [StockAvailabilityStatus.UNKNOWN]: [() => t.BC0X('No availability information')],
  };

  /**
   * Get string by a index from array
   * Last item will be picked if array has not enough items
   * @param index number
   * @param array string[]
   */
  const getStringItem = (index: number, array: string[]) => {
    return array.slice(0, index + 1).pop();
  };

  /**
   * Check lower and upper bound and specify place in x axis
   * @param range
   */
  const getRangeIndex = ({ lower, upper }: Range) => {
    if (upper === 10 && lower === 1) {
      return 1;
    } else if (upper === 24 && lower === 11) {
      return 2;
    } else if (lower === 25) {
      return 3;
    }
    return 0;
  };

  const init = () => {
    if (!offer.stockAvailabilityStatus) {
      return null;
    }
    const textFn = textFns[offer.stockAvailabilityStatus];
    if (textFn.length === 1) {
      return { text: textFn[0]() };
    }
    if (!offer.stockAvailabilityRange) {
      return null;
    }
    const index = getRangeIndex(offer.stockAvailabilityRange);
    return { text: textFn[index](), color: getStringItem(index, colors), isBackordered: index === 0 };
  };

  return init();
};

/**
 * Add ontology to page if category matches any of these
 * No ontology found for the rest of the categories
 * @param category
 */
export const createOntology = (category: string | undefined) => {
  switch (category) {
    case OnlineModelCategory.PHONE:
      return 'http://www.productontology.org/id/Mobile_phone';
    case OnlineModelCategory.TABLET:
      return 'http://www.productontology.org/id/Tablet_computer';
    case OnlineModelCategory.COMPUTERS:
      return 'http://www.productontology.org/id/Computer';
    case OnlineModelCategory.NETWORK_EQUIPMENT:
      return 'http://www.productontology.org/id/Networking_hardware';
    default:
      return null;
  }
};
