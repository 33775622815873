/**
 * SFDC Community API
 * # Summary Elisa B2B API for an administrator of a company to query and modify the company information.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients.  # Identifiers All entities in the API have a globally unique, internal identifier field named with the format [object_type]Id, e.g. `invoiceId`, which is also used in some of the API endpoint urls, e.g. `/api/v1/selfservice/invoices/{invoiceId}`. This value is not meaningful to the end user but is only used as an internally in the API.  Most entities also have a displayable identifier named in the format [object_type]DisplayId. This value can and should be shown to the end user, as it is also printed in relevant documents. It is also used in some of the public URLs, e.g. `https://yrityksille.elisa.fi/omaelisa/laskut/{invoiceDisplayId}`. Because of the user might land directly into a URL of a single entity with a display id, some of the API endpoints here also support querying via the display id, e.g. `/api/v1/selfservice/invoices?invoiceDisplayId={invoiceDisplayId}`.  The reason for using two identifiers instead of only one display id, is to make sure that every object will always have exactly one REST API URL that will never collide with another. While collisions of display ids should not happen in production, the id fields are guaranteed to be unique even across different test environments. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address.js';


export interface ChangeRequest { 
    /**
     *  Internal, unique id of the subscription change request. SFDC: Non_MACD_Subscription_Change_Request__c.Id
     */
    changeRequestId?: string;
    /**
     *  User-readable id of the change request (CR-XXXXXXXXXX). SFDC: Non_MACD_Subscription_Change_Request__c.Name
     */
    changeRequestDisplayId: string;
    /**
     * Type of subscription change request. SFDC: Non_MACD_Subscription_Change_Request__c.Record_Type_Name__c
     */
    changeRequestType: string;
    /**
     * Status of this subscription change request. SFDC: Non_MACD_Subscription_Change_Request__c.Status__c
     */
    changeRequestStatus?: ChangeRequest.ChangeRequestStatusEnum;
    /**
     * New Serial Number of the device in case of Device Maintenance MACD. SFDC: Non_MACD_Subscription_Change_Request__c.Serial_Number__c
     */
    serialNumber?: string;
    /**
     * Old Serial Number of the device in case of Device Maintenance MACD. SFDC: Non_MACD_Subscription_Change_Request__c.Old_Serial_Number__c
     */
    oldSerialNumber?: string;
    /**
     * Delivery tracking code if there is any ship-able item with the MACD. SFDC: Non_MACD_Subscription_Change_Request__c.Delivery_Tracking_Code__c
     */
    deliveryTrackingCode?: string;
    /**
     * New EPP Device Status when Device Maintenance MACD is performed. SFDC: Non_MACD_Subscription_Change_Request__c.New_Device_Status__c
     */
    newEppDeviceStatus?: string;
    /**
     * Old EPP Device Status when Device Maintenance MACD is performed. SFDC: Non_MACD_Subscription_Change_Request__c.Old_Device_Status__c
     */
    oldEppDeviceStatus?: string;
    /**
     * New device information when Device Maintenance MACD is performed. SFDC: Non_MACD_Subscription_Change_Request__c.Device_Information__c
     */
    deviceInformation?: string;
    Address?: Address;
    /**
     * Name of recipient. SFDC: Non_MACD_Subscription_Change_Request__c.Recipient_Name__c
     */
    recipientName?: string;
    /**
     * Phone number of recipient. SFDC: Non_MACD_Subscription_Change_Request__c.Recipient_Phone_Number__c
     */
    recipientPhoneNumber?: string;
    /**
     * Unix timestamp of last modification. SFDC: Non_MACD_Subscription_Change_Request__c.CreatedDate
     */
    created: number;
    /**
     * Unix timestamp of last modification. SFDC: Non_MACD_Subscription_Change_Request__c.LastModifiedDate
     */
    lastModified: number;
}
export namespace ChangeRequest {
    export type ChangeRequestStatusEnum = 'PENDING_APPROVAL' | 'PENDING' | 'COMPLETED' | 'CANCELLED' | 'ERROR';
    export const ChangeRequestStatusEnum = {
        PENDING_APPROVAL: 'PENDING_APPROVAL' as ChangeRequestStatusEnum,
        PENDING: 'PENDING' as ChangeRequestStatusEnum,
        COMPLETED: 'COMPLETED' as ChangeRequestStatusEnum,
        CANCELLED: 'CANCELLED' as ChangeRequestStatusEnum,
        ERROR: 'ERROR' as ChangeRequestStatusEnum
    };
}


