import { TypeKeys, loadAccountKeyUsersFailed, loadAccountKeyUsersFulfilled } from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import type { AccountKeyUsersResponse } from '../../generated/api/models.js';
import type { Action } from 'redux';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { EpicDependencies } from './epicUtils.js';
import type { LoadAccountKeyUsersAction, SelfServiceActionTypes } from '../actions/index.js';

import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { concatMap } from 'rxjs/operators';
import { getAccountKeyUsersPrivateMethod } from '../../generated/api/uiApiMethods.js';
import type { State } from '../common/store.js';

export const loadAccountKeyUsersEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOAD_ACCOUNT_KEY_USERS)), (action: LoadAccountKeyUsersAction) =>
    actionToActionState(action, state$, 'accountKeyUsers')
  ).pipe(
    concatMap(() => {
      return callUiApi({
        epicDependencies,
        state$,
        method: getAccountKeyUsersPrivateMethod(),
        successAction: (res: AccountKeyUsersResponse) => loadAccountKeyUsersFulfilled(res),
        failureAction: loadAccountKeyUsersFailed,
      });
    })
  );

export const accountKeyUsersEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> =
  combineEpics(loadAccountKeyUsersEpic);
