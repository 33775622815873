import { BreadCrumbs } from '../BreadCrumbs/index.js';
import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { ContactForm } from '../ContactForm/ContactForm.js';
import { ContactRole, ContactType, UserProfile } from '../../generated/api/models.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { DialogType } from '../../common/enums.js';
import { FormikProvider, useFormik } from 'formik';
import { UserRolesAndAdminRights } from '../UserRolesAndAdminRights/UserRolesAndAdminRights.js';
import {
  accessRightsMsg,
  addUserMsg,
  notAbleToProcessContactRequestMsg,
  omaElisaForCompaniesMsg,
  t,
  usersMsg,
} from '../../common/i18n/index.js';
import { createAdminUser, upsertContactUser } from '../../common/fetch.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { showDialog, updateUserRightsFulfilled, upsertContactFailed } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import type {
  AccountContactRelationship,
  Contact,
  ContactCommonFunction,
  ContactPerson,
  ContactStatus,
} from '../../generated/api/models.js';
import type { ConfigState } from '../../common/types/states.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { FormikValues } from 'formik';
import type { State } from '../../selfservice/common/store.js';

import './CreateNewContact.scss';

export interface CreateNewContactAttrs {
  config: ConfigState;
}

interface CreateNewContactFormProps {
  config: ConfigState;
}

export type CreateNewContactProps = CreateNewContactAttrs;

// A function to generate correct contact payload. If the contactType is common function and also person
// object is sent, then SFDC returns an exception. Same for contactType of Person, commonFunction needs to be
// left out. Also, same happens if the value for either one is undefined.
// todo: Perhaps prune out the undefined person or common function in online-ui-api
const generateContactBasedOnContactType = (
  contact?: Contact,
  contactStatus?: ContactStatus,
  formValues?: FormikValues,
  person?: ContactPerson
): Contact => {
  const { accountContactRelationships, contactId, contactType } = contact!;
  if (contactType === ContactType.COMMON_FUNCTION) {
    const commonFunction = formValues as ContactCommonFunction;
    return { contactId, contactType, contactStatus, commonFunction };
  } else {
    return {
      accountContactRelationships,
      contactId,
      contactStatus,
      contactType,
      person: { ...person, ...(formValues as ContactPerson) },
    };
  }
};

const CreateNewContactForm = ({ config }: CreateNewContactFormProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const [isNewContactAdmin, setIsNewContactAdmin] = useState(false);
  const [isNewContactApprover, setIsNewContactApprover] = useState(false);
  const [newContactACRs, setNewContactACRs] = useState<AccountContactRelationship[]>([]);

  const { authenticatedUser } = useAuth();

  const { submitting } = useSelector(
    (state: State) => ({
      submitting: state.selfservice?.contacts?.saving,
    }),
    deepEqual
  );

  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));

  const onChangeAdmin = (isAdmin: boolean) => {
    setIsNewContactAdmin(isAdmin);
  };

  const onChangeApproverRole = (isApprover: boolean) => {
    setIsNewContactApprover(isApprover);
  };

  const onChangeContactACRs = (acrs: AccountContactRelationship[]) => {
    setNewContactACRs(acrs);
  };

  const generateNewContact = (values: FormikValues, isAdmin: boolean, isApprover: boolean) => {
    const contactObj: Contact = {
      contactType: ContactType.PERSON,
      person: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        userProfile: UserProfile.EMPLOYEE,
        roles: [ContactRole.ENDUSER_CONTACT],
      },
    };

    if (isAdmin && contactObj.person) {
      contactObj.accountContactRelationships = newContactACRs;
      contactObj.person.userProfile = UserProfile.KEY_USER;
    }

    if (isApprover) {
      contactObj.person!.roles!.push(ContactRole.APPROVER);
    }

    return contactObj;
  };

  const onSubmit = async (values: FormikValues) => {
    const contactForUpsert = generateNewContact(values, isNewContactAdmin, isNewContactApprover);
    const updatedContactInformation = generateContactBasedOnContactType(
      contactForUpsert,
      undefined,
      values,
      contactForUpsert.person
    );
    setIsSaving(true);

    try {
      const res = await (await upsertContactUser(updatedContactInformation, false, false)).json();
      if (isNewContactAdmin) {
        await createAdminUser({ ...updatedContactInformation, contactId: res.contactId });
      }
      dispatch(updateUserRightsFulfilled());
      navigate(paths.COMPANY_INFO_CONTACTS);
    } catch (err) {
      if (err.status === 409) {
        onShowDialog({
          header: t.AYKR('Please contact customer service'),
          body: t.EGFA(notAbleToProcessContactRequestMsg),
          type: DialogType.DUPLICATE_CONTACT,
        });
      } else {
        dispatch(upsertContactFailed('Failed to create contact', 500));
      }
    } finally {
      setIsSaving(false);
    }
  };

  const onReset = () => {
    navigate(paths.COMPANY_INFO_CONTACTS);
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      costCenter: '',
      employeeNumber: '',
    },
    onReset,
    onSubmit,
  });

  return (
    <div className="of-create-new-contact__content">
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} noValidate>
          <h3>{t.AS6H('Personal information')}</h3>
          <ContactForm isEdit={true} />
          {(isFeatureEnabled(config.featureFlags.showUserRightsAccordion) ||
            authenticatedUser?.enabledFeatureFlags?.includes('showUserRightsAccordion')) && (
            <>
              <h3 className="of-create-new-contact__h3-with-separator">{t.Y8LP(accessRightsMsg)}</h3>
              <UserRolesAndAdminRights
                isNewDisconnectedContact={true}
                ignoreAccordion={true}
                onShowDialog={onShowDialog}
                onChangeAdmin={onChangeAdmin}
                onChangeApproverRole={onChangeApproverRole}
                onChangeContactACRs={onChangeContactACRs}
              />
            </>
          )}
          <div className="of-create-new-contact__options">
            <ButtonGroupForSubmitAndBack
              onCancel={onReset}
              onSubmit={() => {}}
              submitButtonText={t.V7KF(addUserMsg)}
              submitting={submitting || isSaving}
              submitOnLeft={true}
            />
          </div>
        </form>
      </FormikProvider>
    </div>
  );
};

export const CreateNewContact = (props: CreateNewContactProps) => {
  const { authenticatedUser } = useAuth();
  const displayName = t.QQSJ('Add new user');
  const backLinkTitle = t.BE4A(usersMsg);
  const detailsWrapperId = 'contact-details';

  const breadCrumbs: JSX.Element = (
    <BreadCrumbs
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: backLinkTitle, path: paths.COMPANY_INFO_CONTACTS },
        { name: t.QQSJ('Add new user') },
      ]}
    />
  );

  return (
    <DetailsWrapper
      classes={['of-create-new-contact']}
      content={<CreateNewContactForm config={props.config} />}
      detailsTop={breadCrumbs}
      id={detailsWrapperId}
      heading={displayName}
      heroPicto="cl-user"
      styledHeadingTop={
        <span className="ds-text--lead ds-color--blue-800">{authenticatedUser?.companyName || ''}</span>
      }
      styledHeading={<h1 className="of-create-new-contact__h1 ds-color--blue-800">{displayName}</h1>}
    />
  );
};
