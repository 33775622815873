import { ActionBlock } from '../../content-blocks/ActionBlock/ActionBlock.js';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { allCallsAreRecordedMsg, t } from '../../common/i18n/index.js';

export interface ChangeNettiLiteSubscriptionDialogDispatchers {
  onCloseDialog: () => void;
}

export type ChangeNettiLiteSubscriptionDialogProps = ChangeNettiLiteSubscriptionDialogDispatchers;
export const ChangeNettiLiteSubscriptionDialog = (props: ChangeNettiLiteSubscriptionDialogProps) => {
  const { onCloseDialog } = props;

  return (
    <DialogWrapper closeable onCloseDialog={onCloseDialog}>
      <h4 className="ds-text-align--center">
        {t.IRUO(
          'If you wish to change the data rates of your Netti Lite subscription, you can contact to our sales service'
        )}
      </h4>
      <ActionBlock
        additionalInformation={`${t.LJ4Y(
          'Our sales service is reachable during weekdays in hours between 8-16.00.'
        )} ${t.SCDP(allCallsAreRecordedMsg)}`}
        additionalInformationLink="https://elisa.fi/tietosuoja"
        id="sales-service"
        label="0800 04411"
        title={t.NMQU('Sales service')}
        type="call"
      />
      <ActionBlock
        additionalInformation="https://www.yrityksille.elisa.fi/yhteydenotto"
        id="contact-request"
        label={t.D030('Leave a contact request')}
        title={t.N8JO('Or leave a contact request')}
        type="contact"
      />
    </DialogWrapper>
  );
};
