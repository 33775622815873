import { AuxiliaryEsimOrderPhaseEnum } from '../../../components/AuxiliaryEsimOrderScene/auxiliaryEsimOrderEnums.js';
import { AuxiliaryEsimOrderScene } from '../../../components/AuxiliaryEsimOrderScene/AuxiliaryEsimOrderScene.js';
import { processElisaIdLogin, updateElisaIdSession } from '../../../selfservice/exports.js';
import { setAuxiliaryEsimOrderPhase } from '../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useElisaIdClient } from '../../../common/hooks/useElisaIdClient.js';
import { useLocation } from 'react-router-dom';

export const AuxiliaryEsimOrderPath = () => {
  const client = useElisaIdClient();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.endsWith('/manage')) {
      dispatch(setAuxiliaryEsimOrderPhase(AuxiliaryEsimOrderPhaseEnum.MANAGE));
    }
  }, [dispatch, location]);

  return (
    <div id="auxiliary-esim-order">
      <AuxiliaryEsimOrderScene
        client={client}
        onElisaIdLoginSuccess={(token: string) => {
          dispatch(updateElisaIdSession());
          dispatch(processElisaIdLogin(token));
          const subscriptionToken = new URLSearchParams(location.search).get('subscriptionToken') ?? undefined;
          dispatch(setAuxiliaryEsimOrderPhase(AuxiliaryEsimOrderPhaseEnum.VALIDATE, subscriptionToken));
        }}
      />
    </div>
  );
};
