import { deepEqual } from '../../common/utils/objectUtils.js';
import { getElisaIdAccessToken } from '../../selfservice/actions/employeeActions.js';
import { useAuth } from './AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { ElisaIdClientAttrs } from '../../common/utils/elisaIdUtils.js';

export const AuthorizationStatus = {
  AUTHORIZED: 'AUTHORIZED',
  UNAUTHORIZED: 'UNAUTHORIZED',
};

export const useEmployeeAuthenticationStatus = (elisaIdClient: ElisaIdClientAttrs): string | undefined => {
  const dispatch = useDispatch();
  const { anonymousUser } = useAuth();

  const getNewAuthStatus = () => {
    const elisaIdSessionValid = anonymousUser?.elisaIdSessionValid;
    const ssoSessionValid = anonymousUser?.ssoSessionValid;
    const elisaIdLoginProcessed = anonymousUser?.elisaIdLoginProcessed;
    if (elisaIdSessionValid === undefined) {
      return { status: undefined, dispatchGetElisaIdAccessToken: false };
    } else if (elisaIdSessionValid && !elisaIdLoginProcessed) {
      return { status: undefined, dispatchGetElisaIdAccessToken: true };
    } else {
      return {
        status:
          elisaIdSessionValid && ssoSessionValid ? AuthorizationStatus.AUTHORIZED : AuthorizationStatus.UNAUTHORIZED,
        dispatchGetElisaIdAccessToken: false,
      };
    }
  };

  const { status, dispatchGetElisaIdAccessToken } = useSelector(getNewAuthStatus, deepEqual);

  useEffect(() => {
    if (dispatchGetElisaIdAccessToken) {
      dispatch(getElisaIdAccessToken(elisaIdClient));
    }
  }, [dispatch, dispatchGetElisaIdAccessToken, elisaIdClient]);

  return status;
};
