import * as CL from '@design-system/component-library';
import { CommercialProductType } from '../../../generated/api/models.js';
import { DeviceCheckoutCartProduct } from '../../DeviceCheckoutCartProduct/DeviceCheckoutCartProduct.js';
import { FormWrapper } from '../FormWrapper.js';
import { OrderSummary, OrderSummaryType } from '../../OrderSummary/OrderSummary.js';
import { SquareTradeErrorModal, hasCartForbiddenSquareTradeAddOn } from '../../SquareTradeErrorModal/index.js';
import { SubscriptionCategory } from '../../../common/enums.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import {
  evaluateAdditionalParametersFns,
  evaluateDomRefFns,
  evaluateGetContactOrPurposeOfUseFns,
  getCartItemUniqueKey,
  saveAdditionalParametersInCartItems,
} from '../../DeviceCheckoutCartProduct/deviceCheckoutCartProductUtil.js';
import { formatSum } from '../../../common/utils/priceUtils.js';
import { getConfiguredCommercialProducts, physicalProducts } from '../../../common/utils/cartProductUtils.js';
import {
  getCustomerLevelDiscountedPrices,
  loadContacts,
  loadOnlineModelWithId,
  loadSubscriptions,
} from '../../../selfservice/actions/index.js';
import { getElementsWithErrors } from '../../../common/utils/errorUtils.js';
import { getIndexFromParent, getTopMostElement } from '../../../common/utils/domUtils.js';
import { getTotalSums } from '../../../common/utils/commercialProductUtils.js';
import { monthMsg, nextMsg, productInformationsMsg, recurringChargesMsg, t } from '../../../common/i18n/index.js';
import { scrollTo } from '../../../common/utils/browserUtils.js';
import { useAuth } from '../../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import type { ActionsHistory, State } from '../../../selfservice/common/store.js';
import type { AuthenticatedUserState } from '../../../common/types/states.js';
import type { CheckoutStepItem } from '../CheckoutSteps.js';
import type { ConfiguredCommercialProduct } from '../../../common/types/commercialProduct.js';
import type { ContactOrPurposeOfUseCallables } from '../../ContactOrPurposeOfUse/ContactOrPurposeOfUse.js';
import type { DeviceChangeRequest } from '../../../common/types/device.js';
import type {
  DeviceChangeSelectedContactIndex,
  InitialPurposeOfUseOrContact,
} from '../../DeviceCheckoutCartProduct/DeviceCheckoutCartProduct.js';
import type { DeviceCheckoutDeliveryDetailsType } from '../../DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';
import type { MouseEvent } from 'react';
import type { OnlineModelCategory } from '../../../generated/api/models.js';
import type { OrderConfiguration } from '../../OrderSubscriptionConfiguration/FormWrapper.js';
import type { SelectedPhoneNumber } from '../../../common/types/subscription.js';
import type { ShoppingCartItemForCheckout } from '../../../common/types/checkout.js';

export interface CartProductsStepProps {
  holidays: Date[];
  isEmployee: boolean;
  shouldScrollToError: boolean;
  setShouldScrollToError: (value: boolean) => void;
  setDeviceChangeRequest: (value?: DeviceChangeRequest) => void;
  deviceChangeRequest?: DeviceChangeRequest;
  deliveryDetails?: DeviceCheckoutDeliveryDetailsType;
  subscriptionDetailForDeviceChange?: {
    contactId?: string;
    subscriptionId?: string;
  };
  user?: AuthenticatedUserState & ActionsHistory;
}

export type AliasSupplier = () => {
  obj: string | boolean | SelectedPhoneNumber;
};

const includesSalesProduct = (configuredCommercialProducts: ConfiguredCommercialProduct[]) =>
  configuredCommercialProducts.some(ccp => ccp.commercialProduct.productType === CommercialProductType.SALES_PRODUCT);

export const CartProductsStepContent = ({
  holidays,
  isEmployee,
  shouldScrollToError,
  setShouldScrollToError,
  setDeviceChangeRequest,
  deviceChangeRequest,
  deliveryDetails,
  subscriptionDetailForDeviceChange,
  user,
}: CartProductsStepProps) => {
  const dispatch = useDispatch();
  const cartProductDetailsFns = useRef<Record<string, Record<string, AliasSupplier>>>({});
  const [deviceChangeSelectedContactIndex, setDeviceChangeSelectedContactIndex] =
    useState<DeviceChangeSelectedContactIndex>();
  const [isDeviceChangeSelectedWithSubscriptionId, setIsDeviceChangeSelectedWithSubscriptionId] = useState<boolean>();
  const [initialPurposeOfUseOrContact, setInitialPurposeOfUseOrContact] = useState<InitialPurposeOfUseOrContact>({});
  const [enrollmentProgramAlias, setEnrollmentProgramAlias] = useState<string | undefined>('');
  const [showSquareTradeErrorModal, setShowSquareTradeErrorModal] = useState(false);

  const domRefFns = useRef<Record<string, () => { [parentKey: string]: { [elementKey: string]: HTMLElement } }>>({});

  let getContactOrPurposeOfUseFns: Record<string, Record<number, ContactOrPurposeOfUseCallables['getValues']>> = {};

  const isLoadingUserData = (state: State) => {
    return state?.selfservice?.contacts?.loading || state?.selfservice?.billingAccounts?.loading;
  };

  const { ssoSessionValid } = useAuth();

  const {
    cartItems,
    enrollmentPrograms,
    onlineModels,
    contacts,
    deviceSubscriptions,
    duplicateCheckInProgress,
    validatedSims,
    validatedPhoneNumbers,
    phoneNumbers,
    phoneNumbersLoading,
    disableNextButton,
  } = useSelector(
    (state: State) => ({
      cartItems: state.deviceCheckout?.cartItems || [],
      enrollmentPrograms: state.selfservice?.companyInfo?.enrollmentPrograms,
      onlineModels: state.selfservice?.onlineModels || undefined,
      contacts: state.selfservice?.contacts?.items,
      deviceSubscriptions: state.selfservice?.subscriptions?.device,
      duplicateCheckInProgress: state.deviceCheckout?.duplicateContactCheck?.inProgress,
      validatedSims: state.resources?.validatedSims,
      validatedPhoneNumbers: state?.resources?.validatedPhoneNumbers || [],
      phoneNumbers: state?.resources?.numbers || [],
      phoneNumbersLoading: state?.resources?.numbersLoading || false,
      disableNextButton: isLoadingUserData(state),
    }),
    deepEqual
  );

  // TODO refactor loadOnlineModelWithId to include all applicable discounts.
  const items = onlineModels?.items;
  useEffect(() => {
    const onlineModelCodes: Array<string> = items?.flatMap(onlineModel => onlineModel.onlineModelCode) || [];
    const listOfCartItemsNotPresentInOnlineModels = cartItems.filter(
      cartItem =>
        !onlineModelCodes.includes(cartItem.onlineModelCode) &&
        physicalProducts.includes(cartItem.category.toUpperCase() as OnlineModelCategory)
    );
    listOfCartItemsNotPresentInOnlineModels.forEach(cartItem =>
      dispatch(loadOnlineModelWithId(cartItem.onlineModelCode))
    );
    if (isEmployee && ssoSessionValid && listOfCartItemsNotPresentInOnlineModels.length > 0) {
      dispatch(getCustomerLevelDiscountedPrices());
    }
  }, [dispatch, cartItems, isEmployee, ssoSessionValid, items]);

  useEffect(() => {
    if (!isEmployee && ssoSessionValid) {
      if (!deviceSubscriptions?.items) {
        dispatch(getCustomerLevelDiscountedPrices());
        dispatch(loadSubscriptions({ category: SubscriptionCategory.DEVICE, expiringOrExpired: true, eppOnly: true }));
      } else {
        dispatch(
          loadSubscriptions({
            category: SubscriptionCategory.DEVICE,
            getAllItems: true,
            expiringOrExpired: true,
            eppOnly: true,
          })
        );
      }
    }
  }, [dispatch, isEmployee, ssoSessionValid]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const contactIdForDeviceChange = subscriptionDetailForDeviceChange?.contactId;
    if (
      contactIdForDeviceChange &&
      contacts &&
      contacts.some(contact => contact.contactId === contactIdForDeviceChange)
    ) {
      setInitialPurposeOfUseOrContact({
        contactId: contactIdForDeviceChange,
        isCopiedUserInfo: false,
      });
    }
  }, [contacts, subscriptionDetailForDeviceChange]);

  useEffect(() => {
    if (cartItems.length > 0 && shouldScrollToError) {
      const topMostCartItemWithErrors = cartItems.findIndex(
        cartItem =>
          cartItem.errors !== undefined &&
          cartItem.errors
            .map(error => error.parent)
            .filter(
              parent => parent !== undefined && cartItem.errors?.find(error => error.parent === parent) !== undefined
            )
            .sort((parent, otherParent) => getIndexFromParent(parent!) - getIndexFromParent(otherParent!))[0] !==
            undefined
      );
      if (topMostCartItemWithErrors !== -1) {
        const validationErrors = cartItems[topMostCartItemWithErrors].errors;
        const cartItemUniqueKey = getCartItemUniqueKey(cartItems[topMostCartItemWithErrors]);

        if (validationErrors) {
          const topMostParentWithErrors = validationErrors
            .map(error => error.parent)
            .filter(
              parent => parent !== undefined && validationErrors.find(error => error.parent === parent) !== undefined
            )
            .sort((parent, otherParent) => getIndexFromParent(parent!) - getIndexFromParent(otherParent!))[0];

          if (topMostParentWithErrors) {
            scrollTo(
              getTopMostElement(
                getElementsWithErrors(
                  domRefFns.current![`cartItemDomRef[${cartItemUniqueKey}]`]()[topMostParentWithErrors],
                  validationErrors
                )
              )
            );
            setShouldScrollToError(false);
          }
        }
      }
    }
  }, [shouldScrollToError, cartItems, domRefFns.current]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetCartItemDomRefFns = (
    cartItemDomRefs: () => { [parentKey: string]: { [elementKey: string]: HTMLElement } },
    cartItemUniqueKey: string,
    cartProductUnMounted?: boolean
  ) => {
    domRefFns.current = evaluateDomRefFns(domRefFns.current, cartItemDomRefs, cartItemUniqueKey, cartProductUnMounted);
  };

  const handleGetAdditionalParametersFns = (
    cartItemUniqueKey: string,
    collectAdditionalParametersFn: Record<string, AliasSupplier>,
    cartProductUnMounted?: boolean
  ) => {
    cartProductDetailsFns.current = evaluateAdditionalParametersFns(
      cartProductDetailsFns.current,
      collectAdditionalParametersFn,
      cartItemUniqueKey,
      cartProductUnMounted
    );
  };

  const handleSetGetContactOrPurposeOfUseFn = (
    cartItemUniqueKey: string,
    productItemIndex?: number,
    fn?: ContactOrPurposeOfUseCallables['getValues']
  ) => {
    const value = evaluateGetContactOrPurposeOfUseFns(
      getContactOrPurposeOfUseFns,
      cartItemUniqueKey,
      productItemIndex,
      fn
    );
    if (value) {
      getContactOrPurposeOfUseFns = value;
    }
  };

  const handleSubmit = async (validateOnly: boolean, data?: OrderConfiguration) => {
    // Do no scroll to error if form has errors as in that case scroll to error is handled within form
    setShouldScrollToError(
      !(await saveAdditionalParametersInCartItems(
        validateOnly,
        cartProductDetailsFns.current,
        getContactOrPurposeOfUseFns,
        cartItems,
        dispatch,
        deliveryDetails,
        enrollmentPrograms,
        onlineModels,
        validatedSims,
        data
      ))
    );
    if (!validateOnly) {
      dispatch(loadContacts());
    }
  };

  const handleNextClicked = (event: MouseEvent<HTMLButtonElement>) => {
    if (hasCartForbiddenSquareTradeAddOn(cartItems, user)) {
      event.preventDefault();
      setShowSquareTradeErrorModal(true);
      return false;
    }

    return true;
  };

  const configuredCommercialProducts = getConfiguredCommercialProducts(cartItems);

  return (
    <FormWrapper onSuccess={data => handleSubmit(false, data)} onError={() => handleSubmit(true)} cartItems={cartItems}>
      <div>
        <SquareTradeErrorModal isOpen={showSquareTradeErrorModal} onClose={() => setShowSquareTradeErrorModal(false)} />
        <div className="of-checkout__product-list">
          {cartItems.map((cartItem, index) => {
            const cartItemUniqueKey = getCartItemUniqueKey(cartItem);
            return (
              <div key={cartItemUniqueKey} className="of-checkout__product-list--bordered">
                <DeviceCheckoutCartProduct
                  user={user}
                  holidays={holidays}
                  phoneNumbers={phoneNumbers}
                  phoneNumbersLoading={phoneNumbersLoading}
                  validatedPhoneNumbers={validatedPhoneNumbers}
                  cartItem={cartItem}
                  employeeUserMode={isEmployee}
                  getCartItemDomRefFns={(
                    cartItemDomRefs: () => { [parentKey: string]: { [elementKey: string]: HTMLElement } },
                    cartProductUnMounted?: boolean
                  ) => handleGetCartItemDomRefFns(cartItemDomRefs, cartItemUniqueKey, cartProductUnMounted)}
                  getAdditionalParametersFns={(
                    collectAdditionalParametersFn: Record<string, AliasSupplier>,
                    cartProductUnMounted?: boolean
                  ) =>
                    handleGetAdditionalParametersFns(
                      cartItemUniqueKey,
                      collectAdditionalParametersFn,
                      cartProductUnMounted
                    )
                  }
                  setGetContactOrPurposeOfUseFn={(productItemIndex, fn) =>
                    handleSetGetContactOrPurposeOfUseFn(cartItemUniqueKey, productItemIndex, fn)
                  }
                  cartProductIndex={index}
                  deviceChangeRequest={deviceChangeRequest}
                  deviceChangeSelectedContactIndex={deviceChangeSelectedContactIndex}
                  onUpdateDeviceChangeSelection={(
                    value?: DeviceChangeRequest,
                    selectedIndex?: DeviceChangeSelectedContactIndex
                  ) => {
                    setDeviceChangeRequest(value);
                    setDeviceChangeSelectedContactIndex(selectedIndex);
                    setIsDeviceChangeSelectedWithSubscriptionId(true);
                  }}
                  subscriptionDetailForDeviceChange={subscriptionDetailForDeviceChange}
                  isDeviceChangeSelectedWithSubscriptionId={isDeviceChangeSelectedWithSubscriptionId}
                  initialPurposeOfUseOrContact={initialPurposeOfUseOrContact}
                  setInitialPurposeOfUseOrContact={setInitialPurposeOfUseOrContact}
                  setEnrollmentProgramAlias={alias => setEnrollmentProgramAlias(alias)}
                  enrollmentProgramAlias={enrollmentProgramAlias}
                  shoppingCartMode
                />
              </div>
            );
          })}
        </div>
        <OrderSummary
          priceIncludesVAT={isEmployee}
          summaryType={OrderSummaryType.DETAILS_CLOSED}
          commercialProducts={configuredCommercialProducts}
          showPriceChangeInfo={includesSalesProduct(configuredCommercialProducts)}
        />
        <div className="ds-padding-top--4">
          <CL.Button
            className="of-next-button"
            disabled={disableNextButton && !duplicateCheckInProgress}
            loading={Boolean(duplicateCheckInProgress)}
            onClick={handleNextClicked}
            size="l"
            type="submit"
          >
            {t.F0MY(nextMsg).toUpperCase()}
          </CL.Button>
        </div>
      </div>
    </FormWrapper>
  );
};

export const cartProductsStep = (
  props: CartProductsStepProps,
  cartItems: Array<ShoppingCartItemForCheckout>
): CheckoutStepItem => {
  const totalSums = (totalCartItems: Array<ShoppingCartItemForCheckout>) =>
    getTotalSums(getConfiguredCommercialProducts(totalCartItems));

  const isYttPeriodicOrder = cartItems.some(item => item.price.periodic?.billingPeriod);
  const completedName = isYttPeriodicOrder
    ? `${cartItems.length} ${t.UJRP('products')}: ${t.J4WW(recurringChargesMsg)} ${formatSum(
        totalSums(cartItems).totalMonthlyRecurringCharge
      )}, ${t.OD0K('one-time payments')} ${formatSum(totalSums(cartItems).totalOneTimeCharge)}`
    : `${cartItems.length} ${t.UJRP('products')}: ${formatSum(
        totalSums(cartItems).totalMonthlyRecurringCharge
      )}/${t.XXVX(monthMsg)}, ${t.OD0K('one-time payments')} ${formatSum(totalSums(cartItems).totalOneTimeCharge)}`;

  return {
    id: 'cartProductStep',
    content: <CartProductsStepContent {...props} />,
    name: t.R4EV(productInformationsMsg),
    completedName: completedName,
  };
};
