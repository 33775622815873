import * as CL from '@design-system/component-library';
import { AdditionalBillReceiversEdit } from './AdditionalBillReceiversEdit.js';
import { BillElectronicAddress } from '../../../common/formik/Fields/BillElectronicAddress.js';
import { BillElectronicOperatorDropdown } from '../../../common/formik/Fields/Dropdown/BillElectronicOperatorDropdown.js';
import { BillReceiverEdit } from './BillReceiverEdit.js';
import { BillingAccountDeliveryMethod } from '../../../generated/api/models.js';
import { DeliveryMethodAndLanguageSelection } from './DeliveryMethodAndLanguageSelection.js';
import { GridColumn } from '../../BillingAccountDetails/components/GridColumn.js';
import { getDeliveryMethodDropDownOptions } from '../../../common/utils/billingAccountUtils.js';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import type { BillChannel, BillingAccount, Contact } from '../../../generated/api/models.js';
import type { BillingAccountCreateFormValues } from '../CreateBillingAccount.js';

export interface BillingAccountDeliveryMethodFormProps {
  billingAccount: BillingAccount;
  billChannels: BillChannel[];
  contacts: Contact[];
}

export const BillingAccountDeliveryMethodForm = ({
  billingAccount,
  billChannels,
  contacts,
}: BillingAccountDeliveryMethodFormProps) => {
  const deliveryMethodOptions = getDeliveryMethodDropDownOptions(billingAccount, billChannels);
  const { setFieldValue, setFieldTouched, values } = useFormikContext<BillingAccountCreateFormValues>();

  const setDeliveryType = (deliveryMethod: BillingAccountDeliveryMethod) => {
    setFieldValue('deliveryMethod', deliveryMethod);
  };

  useEffect(() => {
    setFieldTouched('billElectronicAddress', true, true);
    setFieldTouched('billElectronicOperator', true, true);
    setFieldTouched('billReceiverId', true, true);
  }, [setFieldTouched, values.deliveryMethod]);

  switch (values.deliveryMethod) {
    case BillingAccountDeliveryMethod.ELECTRONIC:
      return (
        <CL.Grid>
          <DeliveryMethodAndLanguageSelection
            deliveryMethodOptions={deliveryMethodOptions}
            setDeliveryType={setDeliveryType}
          />
          <CL.GridRow>
            <GridColumn className="of-billing-account-details form-column edit-field">
              <BillElectronicOperatorDropdown billChannels={billChannels} />
            </GridColumn>
            <GridColumn className="of-billing-account-details form-column edit-field ds-margin-bottom--4">
              <BillElectronicAddress />
            </GridColumn>
          </CL.GridRow>
        </CL.Grid>
      );

    case BillingAccountDeliveryMethod.EMAIL:
      return (
        <>
          <DeliveryMethodAndLanguageSelection
            deliveryMethodOptions={deliveryMethodOptions}
            setDeliveryType={setDeliveryType}
          />
          <div className="of-billing-account-details">
            <CL.Grid className="ds-padding-0">
              <BillReceiverEdit
                contacts={contacts}
                receiverSameAsContact={
                  billingAccount.billReceiverId === undefined ||
                  billingAccount.billReceiverId === billingAccount.billingContactId
                }
              />
              <CL.GridRow>
                <AdditionalBillReceiversEdit />
              </CL.GridRow>
            </CL.Grid>
          </div>
        </>
      );

    case BillingAccountDeliveryMethod.PAPER:
      return (
        <DeliveryMethodAndLanguageSelection
          deliveryMethodOptions={deliveryMethodOptions}
          setDeliveryType={setDeliveryType}
        />
      );
    default:
      return null;
  }
};
