import { fixedTermEndsMsg, t } from '../../common/i18n/index.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import { isCommitmentPeriodActive } from '../../common/utils/subscriptionUtils.js';

interface ContractValiditySummaryProps {
  commitmentEndDate?: number;
}

export const ContractValiditySummary = ({ commitmentEndDate }: ContractValiditySummaryProps) => {
  if (commitmentEndDate && isCommitmentPeriodActive(commitmentEndDate)) {
    const formattedEndDate = formatTimestampToUTCDDMMYYYY(commitmentEndDate);
    return (
      <span>
        {t.VEGK(fixedTermEndsMsg)} {formattedEndDate}
      </span>
    );
  }
  return <>{t.F1JW('Open-ended')}</>;
};
