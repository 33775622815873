import { OrderDeliveryOptions } from '../OrderDeliveryOptions/OrderDeliveryOptions.js';
import { OrderSubscriptionConfiguration } from '../OrderSubscriptionConfiguration/OrderSubscriptionConfiguration.js';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Spinner } from '../../public/site/siteUtils.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import {
  loadBillChannels,
  loadBillingAccounts,
  loadCompanyInfo,
  loadContacts,
  loadHolidays,
  loadOnlineModelByModelType,
} from '../../selfservice/actions/index.js';
import { onSubmitOrder } from '../../common/utils/dispatcherUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { AddOn } from '../../generated/api/models.js';
import type { ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { ModelType } from '../../common/enums.js';
import type { State } from '../../selfservice/common/store.js';

type OrderSubscriptionProps = {
  modelType: ModelType;
};

export interface OrderSubscriptionStateParams {
  addOns?: AddOn[];
  selectedBaId?: string;
  selectedOffer?: ConfiguredOffer;
  showTaxes?: boolean;
}

export const useOrderSubscriptionOutletContext = () => useOutletContext<OrderSubscriptionProps>();

export const OrderSubscriptionLayout = ({ modelType }: OrderSubscriptionProps) => {
  return <Outlet context={{ modelType } satisfies OrderSubscriptionProps} />;
};

export const OrderSubscriptionConfig = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { modelType } = useOrderSubscriptionOutletContext();
  const { authenticatedUser } = useAuth();
  const companyName = authenticatedUser?.companyName || '';
  const {
    holidays,
    contacts,
    contactsLoading,
    companyInfo,
    numberRanges,
    onlineModels,
    phoneNumbers,
    phoneNumbersLoading,
    validatedPhoneNumbers,
  } = useSelector((s: State) => {
    const selfservice = s?.selfservice;
    const resources = s?.resources;
    return {
      // USED BY ALL-----------------------------------------------------------

      // Used in OrderSubscriptionConfigurationFields PurposeOfUse
      contacts: selfservice?.contacts?.items,
      contactsLoading: selfservice?.contacts?.loading,
      // Used in OrderSubscriptionConfiguration for scrolling to
      // OrderSubscriptionConfigurationFields errors
      // (i.e. both use it)
      validationErrors: selfservice?.onlineOrders?.errors || [],

      // ONLY USED BY Voice (i.e. MobileSubNewPath)-----------------------------

      // OrderSubscriptionConfigurationFields AttachRing------------------------
      numberRanges: resources?.numberRanges,
      onlineModels: selfservice?.onlineModels,
      // (and for deciding whether to show it or not)
      companyInfo: selfservice?.companyInfo || {},

      // OrderSubscriptionConfigurationFields SelectPhoneNumber-----------------
      // (also for configuring it in OrderSubscriptionConfiguration)
      holidays: resources?.holidays,
      // (NumberOwnerRHF radio label)
      phoneNumbers: resources?.numbers || [],
      phoneNumbersLoading: resources?.numbersLoading || false,
      // (Contains validation errors for phone number(s))
      validatedPhoneNumbers: resources?.validatedPhoneNumbers || [],
    };
  }, deepEqual);

  // OrderSubscriptionSelectionPath preloads these, but make sure they're
  // loaded.
  const contactsNeedToBeLoaded = contacts == null && !contactsLoading;
  useEffect(() => {
    if (contactsNeedToBeLoaded) {
      dispatch(loadContacts());
    }
  }, [contactsNeedToBeLoaded, dispatch]);

  useEffect(() => {
    dispatch(loadOnlineModelByModelType(modelType));
  }, [dispatch, modelType]);

  useEffect(() => {
    dispatch(loadCompanyInfo());
    dispatch(loadHolidays());
    // This is required in the next step (OrderDeliveryOptions), and
    // it's a bit slow to load, so let's preload it here. The next step loads it
    // as well if it's not available (so that page refresh won't break things).
    dispatch(loadBillingAccounts());
  }, [dispatch]);

  if (!contacts || holidays === undefined) {
    return <Spinner />;
  }
  // TODO what creates onlineOrders.errors, is it really the
  // shabby purposeOfUseOrContact error thing?
  return (
    <OrderSubscriptionConfiguration
      dispatch={dispatch}
      location={location}
      navigate={navigate}
      orderModelType={modelType}
      holidays={holidays}
      companyName={companyName}
      contacts={contacts}
      phoneNumbers={phoneNumbers}
      phoneNumbersLoading={phoneNumbersLoading}
      validatedPhoneNumbers={validatedPhoneNumbers}
      orderStateParams={{ ...state } as OrderSubscriptionStateParams}
      companyInfo={companyInfo}
      onlineModels={onlineModels}
      numberRanges={numberRanges}
    />
  );
};

export const OrderSubscriptionDeliveryOptions = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { authenticatedUser } = useAuth();
  const {
    billingAccounts,
    billingAccountsLoading,
    billChannels,
    companyInfo,
    contacts,
    contactsLoading,
    onlineOrders,
    useDuplicateContacts,
  } = useSelector((s: State) => {
    const selfservice = s?.selfservice;
    return {
      billingAccounts: selfservice?.billingAccounts,
      billingAccountsLoading: selfservice?.billingAccounts?.loading,
      billChannels: selfservice?.billChannels,
      companyInfo: selfservice?.companyInfo,
      contacts: selfservice?.contacts,
      contactsLoading: selfservice?.contacts?.loading,
      onlineOrders: selfservice?.onlineOrders,
      useDuplicateContacts: selfservice?.subscriptionActions?.useDuplicateContact,
    };
  }, deepEqual);

  // This is also a bit slow, but as there doesn't seem to be  a surefire way of
  // telling whether it is currently loading or not, it's not preloaded in the
  // previous step.
  useEffect(() => {
    dispatch(loadBillChannels());
  }, [dispatch]);

  // OrderSubscriptionConfigurationPath preloads these, but make sure they're
  // loaded.
  const billingAccountsNeedToBeLoaded = billingAccounts == null && !billingAccountsLoading;
  useEffect(() => {
    dispatch(loadBillingAccounts());
  }, [billingAccountsNeedToBeLoaded, dispatch]);

  // OrderSubscriptionSelectionPath preloads these, but make sure they're
  // loaded.
  const contactsNeedToBeLoaded = contacts == null && !contactsLoading;
  useEffect(() => {
    if (contactsNeedToBeLoaded) {
      dispatch(loadContacts());
    }
  }, [dispatch, contactsNeedToBeLoaded]);

  const companyInfoNeedsToBeLoaded = companyInfo == null;
  useEffect(() => {
    if (companyInfoNeedsToBeLoaded) {
      dispatch(loadCompanyInfo());
    }
  }, [dispatch, companyInfoNeedsToBeLoaded]);

  const selectedOffer = state?.selectedOffer;
  if (selectedOffer == null) {
    return null;
  }

  // billChannels.items is required by
  // AddOrSelectBillingAccounts -> CreateBillingAccount,
  // which to my understanding can be visible on first render if user has no BAs.
  //
  // AddOrSelectBillingAccounts needs billingAccounts on first load, as they are
  // used to select the active/default BA then. They seem to be loaded in
  // multiple steps, but there's no way to tell when they're all loaded, so we
  // just check if some are loaded. It looks like billingAccounts.items loads in
  // later on, that one isn't required immediately, and
  // AddOrSelectBillingAccounts shows a spinner until that data is available.
  if (!billChannels?.items || !billingAccounts?.searchResults) {
    return <Spinner />;
  }

  return (
    <OrderDeliveryOptions
      companyInfo={companyInfo || undefined}
      contacts={contacts?.items}
      onSubmitOrder={onSubmitOrder(dispatch)}
      onlineOrdersErrors={onlineOrders?.errors}
      saving={!!onlineOrders && onlineOrders.saving}
      user={authenticatedUser?.contact}
      orderStateParams={{ ...state } as OrderSubscriptionStateParams}
      useDuplicateContact={useDuplicateContacts}
    />
  );
};
