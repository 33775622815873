import * as CL from '@design-system/component-library';
import { loginToOmaElisaMsg, omaElisaForCompaniesMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';

import './PublicSelfServiceBanner.scss';

export const PublicSelfServiceBanner = (): JSX.Element => (
  <aside className="of-public-selfservice-banner">
    <div>
      <div>
        <h2 className="ds-h3 ds-text-transform--uppercase">{t.ELJB('Are you already an Elisa customer?')}</h2>
        <p>
          {t.YV0E(
            'In Corporate OmaElisa, you can manage your current subscriptions, review your invoices and order new subscriptions.'
          )}
        </p>
      </div>
      <CL.Link buttonColor="primary" buttonSize="l" linkHref={paths.SELF_SERVICE_HOME} linkStyle="link-button">
        {t.JOM3(loginToOmaElisaMsg)}
      </CL.Link>
    </div>
    <div>
      <img
        alt={t.LOUS(omaElisaForCompaniesMsg)}
        src="https://static.elisa.com/v2/image/2tqybbhjs47b/2y7BUUAjW60GtSyUKKpcnW/woman_and_table.svg"
      />
    </div>
  </aside>
);
