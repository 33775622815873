import { OrderSubscriptionDeliveryOptions } from '../../../../../../components/OrderSubscription/OrderSubscriptionLayout.js';
import { applyWizardEvent } from '../../../../../../selfservice/actions/index.js';
import { paths } from '../../../../../../common/constants/pathVariables.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const OrderM2MSubDeliveryOptionsPath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    if (!state?.selectedOffer) {
      navigate(paths.PS_BROADBAND_SUBSCRIPTION_NEW_M2M);
    }
  }, [navigate, state]);
  useEffect(() => {
    dispatch(applyWizardEvent(3, 'NEW_MOBILE_M2M'));
  }, [dispatch]);
  return <OrderSubscriptionDeliveryOptions />;
};
