import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';
import { Loading } from '../Loading/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';
import type { CommonError } from '../../common/types/errors.js';

export interface NumberFetchingErrorProps {
  errors?: CommonError[];
  loading?: boolean;
  retryAction: () => void;
}

const NO_NUMBERS_AVAILABLE_MESSAGE = 'No available numbers or extension found.';

const isNoNumbersAvailableError = (errors?: CommonError[]) =>
  Boolean(errors?.some(error => error.message.includes(NO_NUMBERS_AVAILABLE_MESSAGE)));

export const NumberFetchingError = ({ errors, loading, retryAction }: NumberFetchingErrorProps) => {
  if (loading) {
    return <Loading />;
  }

  // If the error is "no available numbers", we show that in detail and encourage contacting support
  if (isNoNumbersAvailableError(errors)) {
    return (
      <div className="of-attach-pbx-content__error">
        <CL.Disclaimer className="ds-margin-top--2 ds-margin-bottom--3" icon={<CL.Icon icon="information" />}>
          <>
            <span>
              {`${t.DBJG('Your company has no more company numbers available. You can order more numbers using the')} `}
            </span>
            <Link className="ds-font-size--medium ds-margin--0" to={paths.SUPPORT_CASE_NEW}>
              {t.XW4L('support request')}
            </Link>
            <span>{` ${t.RCVR('form')}. `}</span>
            <span>
              {t.THX7(
                'If you have an urgent matter, you can contact us via chat or call customer service. All our contact details can be found on our '
              )}
            </span>
            <Link to={t.XUE0('https://yrityksille.elisa.fi/en/contact-information')}>{t.W2ZR('website')}</Link>
          </>
        </CL.Disclaimer>
      </div>
    );
  }

  // Other, generic errors can be retried
  return (
    <div className="of-attach-pbx-content__error">
      <div className="ds-margin-bottom--3">{t.KZ8Y('Content retrieval failed...')}</div>
      <CL.Button loading={loading} onClick={retryAction}>
        {t.JGP6('Search again')}
      </CL.Button>
    </div>
  );
};
