import type { Question } from './types.js';

type AdditionalCommentsProps = {
  responses: number[];
  questions: Question[];
};
export const AdditionalComments = ({ responses, questions }: AdditionalCommentsProps) => {
  const content = questions.reduce((acc, question, index) => {
    const maxPoints = question.answers.length - 1;
    const selection = responses[index];
    const limit = question.limit || 0;
    const score = maxPoints - selection;
    if (score > limit) {
      return acc;
    }
    const result = (
      <section className="additional-comment-section" key={question.question}>
        <p className="ds-text--l">
          <b>Kysymys {index + 1}:</b> {question.question}
        </p>
        <p className="ds-text--l">
          <b>Vastauksesi:</b> {question.answers[selection]}
        </p>
        <div>
          {question.content.map(item => (
            <p key={item}>{item}</p>
          ))}
        </div>
      </section>
    );

    return [...acc, result];
  }, []);
  return (
    <section>
      {content.length > 0 && <h2>Näiden aihealueiden osalta teillä vaikuttaisi olevan parannettavaa:</h2>}
      {content}
    </section>
  );
};
