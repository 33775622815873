import { ContactSupportForm } from '../ContactSupportForm/index.js';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Loading } from '../Loading/index.js';
import { SiteContext } from '../../public/site/SiteContext.js';
import { paths } from '../../common/constants/pathVariables.js';
import { pushSupportCaseGAEventToDataLayer } from '../../common/analytics.js';
import { startNotification } from '../../selfservice/actions/index.js';
import { t, yourMessageWasSentMsg } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { Address, SupportCase } from '../../generated/api/models.js';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { State } from '../../selfservice/common/store.js';
import type { SupportCaseContactInfo } from '../ContactSupportForm/index.js';

import './NewSupportCase.scss';

export interface NewSupportCaseProps {
  selfService: boolean;
  title?: string;
}

const NoeSupportCaseInfo = () => {
  return (
    <>
      <h3 className="ds-h3--book">{t.IYBA('Follow support case progress')}</h3>
      <p>
        {t.YAA5(
          'Your support case will be visible in Support Cases page and it will be visible to all OmaElisa users in your company.'
        )}
      </p>
      <p>
        {t.YNUS('You can follow support case progress on')}{' '}
        <Link to={paths.SUPPORT_CASES}>{t.RN44('Support Cases page')}</Link>
        {'. '}
        {t.NXHY('You will always receive replies in your email as well.')}
      </p>
    </>
  );
};

const PublicSupportCaseInfo = () => {
  return (
    <>
      <h3 className="ds-h3--book">{t.W52I('Want smoother experience?')}</h3>
      <p>
        <Link to={paths.REGISTER_PAGE}>{t.XE0P('Create an account')}</Link>{' '}
        {t.QG2G('for OmaElisa for Companies so we can give you a more personal service.')}
      </p>
      <p>
        {t.DB2P(
          'In OmaElisa for Companies you can send attachments with your request and track the progress of your support requests.'
        )}
      </p>
    </>
  );
};

const supportCaseContactInfo = (
  { firstName, lastName, mobile, email, businessId, companyName, mdmId }: AuthenticatedUserState,
  { line1, line2, postalCode, postOffice }: Address
): SupportCaseContactInfo | undefined => {
  return lastName && mobile && email && businessId && mdmId
    ? {
        firstName: firstName,
        lastName: lastName,
        phoneNumber: mobile,
        email: email,
        businessId: businessId,
        businessName: companyName,
        masterId: mdmId,
        postalAddress: `${line1}${' ' && line2}`,
        postCode: postalCode,
        postOffice: postOffice,
      }
    : undefined;
};
export const NewSupportCase = ({ selfService, title }: NewSupportCaseProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { siteBaseUrl } = useContext(SiteContext);
  const { pathname } = useLocation();
  const { authenticatedUser } = useAuth();
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo);

  const contactInfo =
    authenticatedUser && companyInfo?.address && supportCaseContactInfo(authenticatedUser, companyInfo.address);

  const onSendCaseAuthenticated = (feature: SupportCase.FeatureEnum) => {
    pushSupportCaseGAEventToDataLayer('form_submit', true, feature);
    dispatch(startNotification(t.Q61D(yourMessageWasSentMsg), 'success'));
    navigate(paths.SUPPORT_CASES);
  };

  const onSendCaseAnonymous = (feature: SupportCase.FeatureEnum) => {
    pushSupportCaseGAEventToDataLayer('form_submit', false, feature);
    navigate(paths.CONTACT_THANK_YOU);
  };

  if (selfService && !contactInfo) {
    return <Loading />;
  } else {
    return (
      <div className="of-new-support-case ds-margin-vertical--8">
        <div className="contact-form">
          {title && <h2>{title}</h2>}
          <ContactSupportForm
            selfService={selfService && contactInfo !== undefined}
            sourceUrl={`${siteBaseUrl}${pathname}`}
            onSendCaseAuthenticated={onSendCaseAuthenticated}
            onSendCaseAnonymous={onSendCaseAnonymous}
            contactInfo={contactInfo}
          />
        </div>
        <div className="info">
          <div className={`image image--${selfService ? 'noe' : 'public'}`}></div>
          <div className="info-text">{selfService ? <NoeSupportCaseInfo /> : <PublicSupportCaseInfo />}</div>
        </div>
      </div>
    );
  }
};
