import * as CL from '@design-system/component-library';
import { t } from '../../common/i18n/index.js';

import './ItemsPerPage.scss';

export const DEFAULT_ITEMS_PER_PAGE = 30;
export const DEFAULT_ITEMS_PER_PAGE_ALL = 'all';
export const DEFAULT_ITEMS_PER_PAGE_OPTIONS = [DEFAULT_ITEMS_PER_PAGE, 60, 100, 1000];

export interface ItemsPerPageProps {
  id: string;
  totalItems: number;
  currentPage: number;
  cursorNavigation?: boolean;
  itemsPerPage?: number;
  itemsPerPageOptions: number[];
  onItemsPerPageUpdated: (itemsPerPage: string) => void;
}

export const getItemsPerPageFromOptionsOrDefault = (
  itemsPerPage?: string,
  itemsPerPageOptions = DEFAULT_ITEMS_PER_PAGE_OPTIONS
) => {
  if (itemsPerPage === DEFAULT_ITEMS_PER_PAGE_ALL) {
    return undefined;
  }
  const option = Number(itemsPerPage);
  return itemsPerPageOptions.includes(option) ? option : itemsPerPageOptions[0];
};

export const getItemsPerPageOptions = (totalItems: number, itemsPerPageOptions = DEFAULT_ITEMS_PER_PAGE_OPTIONS) =>
  itemsPerPageOptions.filter((_, index, options) => index === 0 || totalItems > options[index - 1]);

export const getItemsPerPageDropdownOptions = (
  totalItems: number,
  itemsPerPageOptions: number[],
  cursorNavigation?: boolean
) =>
  getItemsPerPageOptions(totalItems, itemsPerPageOptions).map((option, index, { length }) => {
    const value = option.toString();
    return index === length - 1 && totalItems <= option
      ? { label: t.EY6A('All'), value: cursorNavigation ? value : DEFAULT_ITEMS_PER_PAGE_ALL }
      : { label: value, value: value };
  });

export const getItemsPerPageRowStats = (total: number, option = total, page: number) => {
  const index = Math.max(page - 1, 0) * option;
  const head = Math.min(option, 1) + index;
  const last = Math.min(total, option + index);
  return `${t.F2BF('Rows')} ${total >= head ? head : 1} - ${last} / ${total}`;
};

export const ItemsPerPage = ({
  id,
  totalItems,
  currentPage,
  cursorNavigation,
  itemsPerPage,
  itemsPerPageOptions,
  onItemsPerPageUpdated,
}: ItemsPerPageProps) => {
  const items = getItemsPerPageDropdownOptions(totalItems, itemsPerPageOptions, cursorNavigation);
  const value = (itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE_ALL).toString();
  return (
    <div className="of-items-per-page">
      <div className="of-items-per-page__label ds-margin-right--2">{t.X49N('Show')}</div>
      <CL.Dropdown
        key={`${id}-${value}-${items.length}`}
        className="ds-pagination__dropdown ds-margin--0"
        disabled={items.length === 1}
        items={items}
        onValueChange={(el: HTMLLIElement) => el.dataset.value && onItemsPerPageUpdated(el.dataset.value)}
        selectedValue={(items.find(o => o.value === value) ?? items[0]).value}
      />
      <div className="of-items-per-page__label ds-margin-left--3 ds-text--small">
        {getItemsPerPageRowStats(totalItems, itemsPerPage, currentPage)}
      </div>
    </div>
  );
};
