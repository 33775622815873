import * as CL from '@design-system/component-library';
import { ModalCloseAndSubmitButtons } from '../Modal/ModalCloseAndSubmitButtons.js';
import { Password } from '../../common/react-hook-form/fields/Password.js';
import { SSO_PASSWORD_REGEX } from '../../common/utils/validationUtils.js';
import { incorrectPasswordMsg, passwordsDontMatchMsg, readyMsg, t } from '../../common/i18n/index.js';
import { resetPassword } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { ForgetPasswordWithForm } from './misc/forgotPasswordInterfaces.js';

export const ForgotPasswordEnterSamePasswordTwiceStep = (props: ForgetPasswordWithForm) => {
  const dispatch = useDispatch();
  const { methods } = props;

  const onSubmit = ({ newPassword, otp, userName }: { newPassword: string; otp: string; userName: string }) => {
    dispatch(
      resetPassword({
        userName,
        otp,
        newPassword,
      })
    );
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
      <div className="of-forgot-password-step__header">
        <h3 className="ds-h3--book">{t.BON5('Create new password')}</h3>
      </div>

      <CL.List
        className="ds-margin-bottom--3"
        items={[
          t.VKRZ('At least 8 characters long'),
          t.YK9W('Contains both uppercase and lowercase letters'),
          t.FVJ4('Contains at least 2 numbers or special characters'),
        ]}
      />

      <Password
        name="newPassword"
        label={t.EMCQ('Password')}
        placeholder={t.EMCQ('Password')}
        validate={(value: string, values: { newPassword: string; newPasswordConfirm: string }) => {
          const array: string[] = [];
          if (!SSO_PASSWORD_REGEX.test(value)) {
            array.push(t.NH72(incorrectPasswordMsg));
          }
          if (values.newPasswordConfirm && value !== values.newPasswordConfirm) {
            array.push(t.HWDL(passwordsDontMatchMsg));
          }
          return array.length > 0 ? array.join(' ') : undefined;
        }}
      />
      <Password
        name="newPasswordConfirm"
        label={t.OTX5('Confirm password')}
        placeholder={t.OTX5('Confirm password')}
        validate={(value: string, values: { newPassword: string; newPasswordConfirm: string }) => {
          const array: string[] = [];
          if (!SSO_PASSWORD_REGEX.test(value)) {
            array.push(t.NH72(incorrectPasswordMsg));
          }
          if (values.newPassword && value !== values.newPassword) {
            array.push(t.HWDL(passwordsDontMatchMsg));
          }
          return array.length > 0 ? array.join(' ') : undefined;
        }}
      />

      {props.anonymousUser.forgotPasswordErrored && (
        <CL.Notification
          hasCloseButton={false}
          text={t.Y6JY('Failed to create password. Please try again')}
          type="warning"
          visible
        />
      )}
      <ModalCloseAndSubmitButtons
        onCancel={props.closeModal}
        onSubmit={() => {}}
        submitting={props.anonymousUser.forgotPasswordInProgress}
        submitButtonText={t.L75K(readyMsg)}
      />
    </form>
  );
};
