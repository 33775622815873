import * as CL from '@design-system/component-library';
import {
  consumerCustomersMsg,
  corporateCustomersMsg,
  corporateInfoURLMsg,
  employeeOmaElisaMsg,
  t,
} from '../../../common/i18n/index.js';
import { paths } from '../../../common/constants/pathVariables.js';
import type * as CLT from '@design-system/component-library';
import type { ActionsHistory } from '../../../selfservice/common/store.js';
import type { AuthenticatedUserState } from '../../../common/types/states.js';

export const siteSelect = (siteBaseUrl: string) => {
  return {
    sites: [
      { title: t.DW3T(consumerCustomersMsg), url: 'https://elisa.fi' },
      { current: true, title: t.KBLX(corporateCustomersMsg), url: `${siteBaseUrl}/` },
      { title: 'Elisa Oyj', url: t.CATY(corporateInfoURLMsg) },
    ],
  };
};

export const LoggedInAsEmployeeNotification = (
  <CL.Notification
    text={t.LM60(
      'You are logged in to Employee OmaElisa. You can handle your own employment mobile subscriptions and devices in'
    )}
    id="notification-logged-in-employee"
    type="info"
    link={{
      text: `${t.ZPZR(employeeOmaElisaMsg)}.`,
      href: paths.EMPLOYEE_HOME,
    }}
  />
);

export const getUserAccounts = (
  authUser: (AuthenticatedUserState & ActionsHistory) | undefined
): CLT.HeaderUserAccount[] => {
  if (!authUser) {
    return [];
  }

  const primaryAccount =
    authUser.mdmId && authUser.companyName
      ? [
          {
            name: authUser.companyName,
            accountMasterId: authUser.mdmId,
            active: !authUser.activeAccountMasterId || authUser.activeAccountMasterId === authUser.mdmId,
            email: authUser.email,
          },
        ]
      : [];

  const secondaryAccounts =
    authUser.secondaryAccounts?.map(secondaryAccount => ({
      name: secondaryAccount.accountName,
      accountMasterId: secondaryAccount.accountMasterId,
      active: authUser.activeAccountMasterId === secondaryAccount.accountMasterId,
      email: authUser.email,
    })) ?? [];

  return [...primaryAccount, ...secondaryAccounts];
};

const fullMatch = (navigationItemList: CLT.NestedSubMenu[], pathname: string): CLT.NestedSubMenu | undefined => {
  const decodedURI = decodeURI(pathname);
  return navigationItemList.find(
    menuItem =>
      menuItem.url === decodedURI ||
      menuItem.subMenu?.some(subMenuItem => subMenuItem.url && decodedURI === subMenuItem.url)
  );
};

const partialMatch = (navigationItemList: CLT.NestedSubMenu[], pathname: string): CLT.NestedSubMenu | undefined => {
  const decodedURI = decodeURI(pathname);
  return navigationItemList.find(
    menuItem =>
      menuItem.url === decodedURI ||
      menuItem.subMenu?.some(subMenuItem => subMenuItem.url && decodedURI.startsWith(subMenuItem.url))
  );
};

export const navigationItemListWithCurrentProperty = (
  navigationItemList: CLT.NestedSubMenu[],
  currentPath: string
): CLT.NestedSubMenu[] => {
  const currentItem = fullMatch(navigationItemList, currentPath) ?? partialMatch(navigationItemList, currentPath);
  if (currentItem) {
    currentItem.current = currentItem === fullMatch(navigationItemList, currentPath) ? 'page' : 'true';
  }
  return navigationItemList;
};
