import { t } from '../../../common/i18n/index.js';
import type { AuthenticatedUserState } from '../../../common/types/states.js';

import './PunchoutUserInfo.scss';

type PunchoutUserInfoProps = {
  user: AuthenticatedUserState;
  className?: string;
};

export const PunchoutUserInfo = ({ user, className }: PunchoutUserInfoProps) => (
  <div className={`of-punchout-checkout-user-info ${className || ''}`}>
    <h2 className="of-sub-header ds-h2">{t.HS3T('Customer details')}</h2>
    <div className="of-user-details">
      <div>{user.companyName}</div>
      <div>{`${user.firstName} ${user.lastName}`}</div>
      <div>{user.email}</div>
    </div>
  </div>
);
