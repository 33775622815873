import * as CL from '@design-system/component-library';
import { CompanySearch, Email, Name, PhoneNumber } from '../../common/react-hook-form/fields/index.js';
import { businessIdOrCompanyMsg, firstNameMsg, informantBISMsg, lastNameMsg, t } from '../../common/i18n/index.js';
import type { ContactSupportFormValues } from './ContactSupportForm.js';
import type { UseFormGetValues } from 'react-hook-form';

export interface ContactInfoFieldsProps {
  readonly: boolean;
  readonlyValues?: UseFormGetValues<ContactSupportFormValues>;
}

/**
 * Form where user can input their contact details
 */
export const ContactSupportInfoFields = ({ readonly, readonlyValues }: ContactInfoFieldsProps) => {
  const firstNameLabel = t.AIK7(firstNameMsg);
  const lastNameLabel = t.Y8OY(lastNameMsg);

  return (
    <div className="ds-padding-bottom--6">
      <div className="contact-info_company-name ds-margin-bottom--4">
        {readonly && readonlyValues ? (
          <div className="ds-input">
            <div className="ds-input--labelarea">
              <label className="ds-input--labelarea-label ">{t.GSAD(businessIdOrCompanyMsg)}</label>
            </div>
            <div className="ds-input--inputarea">
              <CL.Icon className="ds-position--absolute" icon="search" />
              <span className="ds-padding-left--5">{`${readonlyValues().businessName}, ${
                readonlyValues().businessId
              }`}</span>
              <p className="ds-margin-top--2 ds-text--s">{t.V9OQ(informantBISMsg)}</p>
            </div>
          </div>
        ) : (
          <CompanySearch />
        )}
      </div>
      <div className="contact-info-details">
        <Name name="firstName" label={firstNameLabel} placeholder={firstNameLabel} readonly={readonly} />
        <Name name="lastName" label={lastNameLabel} placeholder={lastNameLabel} readonly={readonly} />
        <Email readonly={readonly} />
        <PhoneNumber readonly={readonly} />
      </div>
    </div>
  );
};
