import * as CL from '@design-system/component-library';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { EmptyOrError } from '../EmptyOrError/index.js';
import { Loading } from '../Loading/index.js';
import { OrderEsim } from '../OrderEsim/OrderEsim.js';
import { OrderPhysicalSim } from '../OrderPhysicalSim/OrderPhysicalSim.js';
import { ServiceFeeRequest, SimType } from '../../generated/api/models.js';
import { getChangeSimServiceFeePayload } from '../../common/utils/simCardUtils.js';
import { getServiceFee } from '../../selfservice/actions/serviceFeeActions.js';
import { returnMsg, simCardMsg, subChangePendingMsg, t } from '../../common/i18n/index.js';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import type { AuthenticatedUserState, CompanyInfoState } from '../../common/types/states.js';
import type { Subscription } from '../../generated/api/models.js';
import type { SubscriptionCategory } from '../../common/enums.js';

import './OrderSim.scss';

export interface OrderSimAttrs {
  breadCrumbs: JSX.Element;
  category: SubscriptionCategory;
  disallowBillableSimChanges: boolean;
  pendingSubscriptionChanges: boolean;
  simTypeInitialSelection?: SimType;
  subscription: Subscription;
  companyInfo?: CompanyInfoState | null;
  user?: AuthenticatedUserState | null;
  simChangeServiceFee?: number;
  isEmployee?: boolean;
}

export interface OrderSimRoutes {
  onClickSubscription: () => void;
}

export type OrderSimProps = OrderSimAttrs & OrderSimRoutes;

export const OrderSimComponent = (props: OrderSimProps) => {
  const [simType, setSimType] = useState<SimType | undefined>(props.simTypeInitialSelection);

  const { subscription, companyInfo, user, simChangeServiceFee, isEmployee } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmployee && simChangeServiceFee === undefined) {
      dispatch(
        getServiceFee(
          ServiceFeeRequest.ServiceTypeEnum.SIM,
          subscription?.subscriptionDisplayId || '',
          getChangeSimServiceFeePayload(simType, subscription, companyInfo?.address, user)
        )
      );
    }
  }, [simChangeServiceFee, companyInfo, dispatch, subscription, user, simType, isEmployee]);

  if (!isEmployee && simChangeServiceFee === undefined) {
    return <Loading />;
  }

  const products = [
    {
      id: SimType.ESIM,
      name: 'eSIM',
      selected: simType === SimType.ESIM,
    },
    {
      id: SimType.PHYSICAL,
      name: t.PIZC(simCardMsg),
      selected: simType !== SimType.ESIM,
    },
  ];
  return (
    <>
      <h3 className="ea-h3 ea-h3--thick ds-padding-bottom--4">{t.RPVC('Choose SIM card type')}</h3>
      <CL.SelectGroup
        className="ds-padding--0"
        onChange={selectedId => {
          if (selectedId) {
            setSimType(selectedId as SimType);
          }
        }}
        products={products}
        value={products.find(i => i.selected)?.id}
      />
      {simType === SimType.ESIM ? (
        <OrderEsim {...props} onCancel={props.onClickSubscription} />
      ) : (
        <OrderPhysicalSim {...props} onCancel={props.onClickSubscription} />
      )}
    </>
  );
};

export const OrderSim = (props: OrderSimProps) => {
  const { breadCrumbs, pendingSubscriptionChanges, subscription, onClickSubscription } = props;

  if (!subscription) {
    return <Loading />;
  }

  if (pendingSubscriptionChanges) {
    return (
      <EmptyOrError
        id="pending-sim-changes"
        text={t.MQHO(subChangePendingMsg)}
        onButtonClick={onClickSubscription}
        buttonText={t.VH59(returnMsg)}
      />
    );
  }

  const { subscriptionContactName, subscriptionPurposeOfUse, subscriptionUserFriendlyId } = subscription;

  return (
    <DetailsWrapper
      content={<OrderSimComponent {...props} />}
      detailsTop={breadCrumbs}
      id="of-order-sim"
      heading={t.QMMA('Order new SIM')}
      headingBottom={(subscriptionContactName || subscriptionPurposeOfUse) + ' | ' + subscriptionUserFriendlyId}
      heroPicto="order-sim"
    />
  );
};
