import type { CSSProperties } from 'react';

import './Ribbon.scss';

export type RibbonProps = {
  color?: 'turquoise' | 'orange';
  side?: 'left' | 'right';
  text: string[];
  width?: number;
};

// How many 'extra' characters fit to the line compared to the first line.
// Magic numbers tuned by hand: they basically match the aspect ratio of the
// used font.
const extraChars = (rowIndex: number) => (rowIndex * 16) / 5;

export const ribbonLineLength = (text: string[]) =>
  text.reduce((a, c, idx) => Math.max(a, Math.round(c.length - extraChars(idx))), 0);

export const Ribbon = ({ color = 'orange', side = 'right', text, width }: RibbonProps) => {
  const lineLength = width ?? ribbonLineLength(text);
  // eslint doesn't like setting custom variables in the style
  const style = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '--ribbon-line-count': text.length,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '--ribbon-needed-line-length': lineLength,
  } as CSSProperties;

  // Outermost div is used for positioning and cutting off the corners of the ribbon.
  // Container is used as the actual ribbon visuals.
  // Content divs just force each entry to its own row.
  return (
    <div className={`of-ribbon of-ribbon--${color} of-ribbon--${side}`} style={style}>
      <div className="of-ribbon__container">
        {text.map((line, idx) => (
          <div className="of-ribbon__content" key={idx}>
            {line}
          </div>
        ))}
      </div>
    </div>
  );
};
