import { ActionPhase } from '../common/storeUtils.js';
import { CommonErrorType, SelectedPurposeOfUseOrContact } from '../../common/enums.js';
import { ConflictedAuthenticationResult, ContactRole } from '../../generated/api/models.js';
import { ContactCreationType } from '../../components/ContactOrPurposeOfUse/ContactsOrPurposeOfUseUtils.js';
import { ITEMS_QUERY_LIMIT } from '../../common/constants/commonConstants.js';
import { TypeKeys } from '../actions/index.js';
import { addEmptyFieldValidationErrorWithParent, addValidationErrorWithParent } from '../../common/utils/errorUtils.js';
import { addToActionHistory, getPreviousSuccessfulItemsQuery, isPreviousActionInProgress } from '../common/index.js';
import { cartItemIndex } from '../../components/DeviceCheckoutCartProduct/deviceCheckoutCartProductUtil.js';
import { invalidPhoneNumberMsg, t } from '../../common/i18n/index.js';
import { isInvalidExistingPhoneNumber } from '../../components/RetainExistingPhoneNumber/RetainExistingPhoneNumber.js';
import { sortArray } from '../../common/utils/arrayUtils.js';
import type { ActionState, ActionsHistory, ItemsQuery } from '../common/store.js';
import type {
  BillingAccount,
  BillingAccountHeader,
  LocalUsageResponse,
  OnlineModel,
  RoamingUsageResponse,
  SubscriptionsResponse,
  TextMessagesResponse,
  VoiceCallsResponse,
} from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';
import type { CompositeListSort } from '../../components/CompositeListHeader/index.js';
import type {
  CrudAction,
  DisplayItemsLoadAction,
  LoadBillingAccountsAction,
  LoadCustomerOrdersAction,
  LoadEmployeeCustomerOrdersAction,
  LoadEmployeeSubscriptionActions,
  LoadSubscriptionActions,
  LoadSubscriptionsAction,
} from '../actions/index.js';
import type { DisplayItemsState, ErrorableState } from '../../common/types/states.js';
import type {
  EmployeeSubscriptions,
  PhoneNumberOwner,
  PurposeOfUseOrContact,
  SelectedPhoneNumber,
} from '../../common/types/subscription.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';

const combineSort = (sort?: CompositeListSort | CompositeListSort[]): CompositeListSort | undefined => {
  if (Array.isArray(sort)) {
    return sort.length > 0
      ? {
          columnId: sort.map(s => s.columnId).join(','),
          order: sort[0].order,
        }
      : undefined;
  }
  return sort;
};

const ignoreItemsAndTotal = (ignoreAction: boolean, forceLoad = false) => !ignoreAction && forceLoad;

export const createItemsQuery = (
  previousQuery?: ItemsQuery,
  limit?: number,
  sort?: CompositeListSort | CompositeListSort[],
  defaultOffset?: number,
  forceLoad?: boolean
): ItemsQuery => {
  let offset = 0;
  if (previousQuery && previousQuery.limit && !forceLoad) {
    offset = previousQuery.offset + previousQuery.limit;
  } else if (defaultOffset !== undefined) {
    offset = defaultOffset;
  }
  const query: ItemsQuery = {
    offset,
  };
  if (limit) {
    query.limit = limit;
  }
  const combinedSort = combineSort(sort);
  if (combinedSort) {
    query.sort = combinedSort.columnId;
    // Order based on first column sort
    query.order = combinedSort.order;
  } else if (previousQuery && previousQuery.sort) {
    // If the previous query had a sort, use that here too
    query.sort = previousQuery.sort;
  }
  return query;
};

export function sortArrayWithQuery<T>(array: T[], params?: ItemsQuery): T[] {
  if (!params || !params.sort) {
    return array;
  }
  return sortArray<T>(array, params.sort, params.order);
}

// Looks at the 'idField' in all objects of both 'existing' and 'additions', where the object has a unique 'idField'
// it gets included in the resulting array, for non-unique we pick the object from 'additions'.
//
// The resulting array will contain all objects from 'existing' in order ("updated" with values from 'additions'),
// followed by all objects from 'additions' that had a unique 'idField' value (as the objects with non-unique 'idField'
// were used to "update" values in the previous part of the array).
export function combineArrays<T>(idField: string, existing: T[], additions: T[]): T[] {
  return existing
    .map(obj => additions.find(o => o[idField as keyof T] === obj[idField as keyof T]) || obj)
    .concat(additions.filter(obj => !existing.some(o => o[idField as keyof T] === obj[idField as keyof T])));
}

export function mergeArrays<T>(idField: string, comparationField: string, existing?: T[], additions?: T[]): T[] {
  const objectIdMap = {};

  if (existing) {
    existing.forEach((value: T) => {
      // @ts-ignore
      objectIdMap[value[idField]] = value;
    });
  }

  if (additions) {
    additions.forEach((value: T) => {
      // @ts-ignore
      if (!objectIdMap[value[idField]] || value[comparationField] > objectIdMap[value[idField]][comparationField]) {
        // @ts-ignore
        objectIdMap[value[idField]] = value;
      }
    });
  }

  const mergedArray: T[] = [];

  for (const property in objectIdMap) {
    // eslint-disable-next-line no-prototype-builtins
    if (objectIdMap.hasOwnProperty(property)) {
      // @ts-ignore
      mergedArray.push(objectIdMap[property]);
    }
  }

  return mergedArray;
}

// mergeArrays is used to merge, and compare ->  object.[fieldName]
// This is like mergeArrays, with an exception that we need to compare -> object.idObject.[fieldName]
export function mergeArraysWithIdObject<T>(
  idObject: string,
  idField: string,
  comparationField: string,
  existing?: T[],
  additions?: T[]
): T[] {
  const objectIdMap = {};

  if (existing) {
    existing.forEach((value: T) => {
      // @ts-ignore
      objectIdMap[value[idObject][idField]] = value;
    });
  }

  if (additions) {
    additions.forEach((value: T) => {
      if (
        // @ts-ignore
        !objectIdMap[value[idObject][idField]] ||
        // @ts-ignore
        value[idObject][comparationField] > objectIdMap[value[idObject][idField]][idObject][comparationField]
      ) {
        // @ts-ignore
        objectIdMap[value[idObject][idField]] = value;
      }
    });
  }

  const mergedArray: T[] = [];

  for (const property in objectIdMap) {
    // eslint-disable-next-line no-prototype-builtins
    if (objectIdMap.hasOwnProperty(property)) {
      // @ts-ignore
      mergedArray.push(objectIdMap[property]);
    }
  }

  return mergedArray;
}

export function reduceCrudAction<T extends TypeKeys>(
  action: CrudAction<T>,
  state: (ErrorableState & ActionsHistory) | undefined | null
): ErrorableState & ActionsHistory {
  const actionState: ActionState = {
    phase: ActionPhase.IN_PROGRESS,
    value: action,
  };

  if (isPreviousActionInProgress(action.type, state)) {
    return {
      ...state,
    };
  } else {
    return {
      ...state,
      actions: addToActionHistory(state, actionState),
    };
  }
}

export function reduceDisplayItemsLoadAction<T extends TypeKeys, U>(
  action: DisplayItemsLoadAction<T, U>,
  state: (DisplayItemsState<U> & ActionsHistory) | undefined | null,
  displayIdFieldName: string,
  getAllItems = false,
  defaultOffset: number | undefined = undefined,
  forceLoad?: boolean
): DisplayItemsState<U> & ActionsHistory {
  // Get previous successful query from action that has displayId same as now
  const previousQuery = getPreviousSuccessfulItemsQuery(action.type, state, 'displayId', action.displayId);
  let query: ItemsQuery;
  if (action.displayId) {
    let existingItem: U | undefined;
    if (state && state.items) {
      // @ts-ignore
      existingItem = state.items.find(item => item[displayIdFieldName] === action.displayId);
    }
    if (existingItem) {
      // We already have an item with this displayId, no need to search
      return {
        ...state,
      };
    } else {
      // Make a vanilla query with no extra limits
      query = createItemsQuery();
    }
  } else {
    const limit = action.limit || ITEMS_QUERY_LIMIT;
    query = createItemsQuery(previousQuery, getAllItems ? undefined : limit, action.sort, defaultOffset, forceLoad);
  }

  const actionState: ActionState = {
    phase: ActionPhase.IN_PROGRESS,
    query,
    value: action,
  };
  if (!state) {
    return {
      actions: addToActionHistory(state, actionState),
      filter: action.filter,
      search: action.search,
      sort: combineSort(action.sort),
    };
  } else {
    let ignoreAction = false;
    // Don't process if loading already in progress for action with the same displayId
    if (action.displayId && isPreviousActionInProgress(action.type, state, 'displayId', action.displayId)) {
      ignoreAction = true;
    }
    // Don't process search if there are already all the items there
    if (state.items && state.items.length === state.total && !forceLoad) {
      ignoreAction = true;
    }
    // Skip querying if for some reason the offset is bigger than the total amount: nothing can be returned in those
    // cases. TODO: This should be fixed so that a query like this is never triggered in the first place, and then this
    // check can be removed.
    if (query.offset !== undefined && state.total !== undefined && state.total !== -1 && query.offset >= state.total) {
      ignoreAction = true;
    }

    if (!ignoreAction) {
      if (action.search !== undefined) {
        // When searching, need to query the rest
        actionState.query = createItemsQuery(previousQuery, undefined, action.sort);
      }
    }
    // Sorting is also handled with the load actions, we want to do it also before load
    // for more responsive feel, and also because this load call might be ignored
    const items: U[] | undefined = state.items
      ? sortArrayWithQuery<U>(state.items, actionState ? actionState.query : undefined)
      : undefined;
    const itemsAndTotalCountToBeReset = ignoreItemsAndTotal(ignoreAction, forceLoad);
    return {
      ...state,
      actions: ignoreAction ? state.actions : addToActionHistory(state, actionState),
      errors: ignoreAction ? state.errors : undefined,
      filter: action.filter !== undefined ? action.filter : state.filter,
      items: itemsAndTotalCountToBeReset ? undefined : items,
      total: itemsAndTotalCountToBeReset ? undefined : state.total,
      search: action.search !== undefined ? action.search : state.search,
      sort: combineSort(action.sort) || state.sort,
    };
  }
}

export function validateLoadActionFulfilledResponseCounts<T>(
  query: ItemsQuery,
  total: number,
  items?: T[],
  errors?: CommonError[]
): CommonError[] | undefined {
  let errorMessage: string | undefined;
  const limit: string = query.limit ? `${query.limit}` : 'unlimited';
  if (!items || items.length === 0) {
    if (total > 0 && query.offset < total && (!query.limit || query.limit > 0)) {
      // The server sent a malformed response where total count does not work with the current offset
      errorMessage = `Malformed load items response received: total count is ${total} but got no items with offset ${query.offset} and limit ${limit}`;
    } else {
      // Handle case where we have a runaway load more poller
      if (query.offset > total + ITEMS_QUERY_LIMIT) {
        errorMessage = `Trying to load with offset ${query.offset} and limit ${limit} even though there are only a total of ${total} items`;
      }
    }
  } else if (total >= 0 && items.length > 0 && total < items.length) {
    errorMessage = `Malformed load items response received: total count is only ${total} but got ${items.length} items with offset ${query.offset} and limit ${limit}`;
  }
  if (errorMessage) {
    const error: CommonError = {
      message: errorMessage,
      type: CommonErrorType.SYSTEM,
    };
    return errors ? [error, ...errors] : [error];
  }
  return errors;
}

export const reduceSubscriptionUsage = (
  usageType: string,
  subId: string,
  response: VoiceCallsResponse | LocalUsageResponse | RoamingUsageResponse | TextMessagesResponse,
  subs?: EmployeeSubscriptions
): EmployeeSubscriptions | undefined => {
  if (subs?.voice) {
    return {
      ...subs,
      voice: subs.voice.map(e => (e.subscriptionId === subId ? { ...e, [usageType]: response } : e)),
    };
  }
  return subs;
};

export const reduceSubscriptions = (
  response: SubscriptionsResponse,
  subscriptionType: string,
  subscriptions?: EmployeeSubscriptions
) => Object.assign({}, subscriptions, { [subscriptionType]: response.total > 0 ? response.subscriptions : [] });

export const mapPurposeOfUseOrContactsToConfiguredCommercialProducts = (
  item: ShoppingCartItemForCheckout,
  purposeOfUseOrContacts: PurposeOfUseOrContact[]
) => {
  // Map action validation object purpose of use to selected commercial product purpose of use
  item.purposeOfUseOrContacts = purposeOfUseOrContacts;
  purposeOfUseOrContacts.forEach((purposeOfUseOrContact, index) => {
    if (purposeOfUseOrContact && purposeOfUseOrContact.selected === SelectedPurposeOfUseOrContact.CONTACT) {
      if (purposeOfUseOrContact.contactId === ContactCreationType.CREATE_NEW_CONTACT) {
        item.purposeOfUseOrContacts[index].newContact = {
          email: purposeOfUseOrContact.email!,
          firstName: purposeOfUseOrContact.firstName!,
          lastName: purposeOfUseOrContact.lastName!,
          phoneNumber: purposeOfUseOrContact.phoneNumber!,
          costCenter: purposeOfUseOrContact.costCenter!,
          employeeNumber: purposeOfUseOrContact.employeeNumber!,
          roles: [ContactRole.ENDUSER_CONTACT],
        };
        delete item.purposeOfUseOrContacts[index].purposeOfUse;
      } else if (purposeOfUseOrContact.contactId === ContactCreationType.COPIED_CONTACT) {
        delete item.purposeOfUseOrContacts[index].purposeOfUse;
        delete item.purposeOfUseOrContacts[index].newContact;
      } else {
        delete item.purposeOfUseOrContacts[index].purposeOfUse;
        delete item.purposeOfUseOrContacts[index].newContact;
        delete item.purposeOfUseOrContacts[index].firstName;
        delete item.purposeOfUseOrContacts[index].lastName;
      }
    } else {
      delete item.purposeOfUseOrContacts[index].contactId;
      delete item.purposeOfUseOrContacts[index].newContact;
      delete item.purposeOfUseOrContacts[index].firstName;
      delete item.purposeOfUseOrContacts[index].lastName;
    }
  });
  return item.purposeOfUseOrContacts;
};

export const mapEnrollmentProgramConsentToConfiguredCommercialProducts = (
  item: ShoppingCartItemForCheckout,
  enrollmentProgramConsents: boolean[]
) => {
  // Map action validation object enrollment program to selected commercial product
  item.enrollmentProgramConsents = enrollmentProgramConsents;
  enrollmentProgramConsents.forEach((enrollmentProgramConsent, index) => {
    if (item.enrollmentProgramConsents !== undefined) {
      item.enrollmentProgramConsents[index] = enrollmentProgramConsent;
    }
  });
  return item.enrollmentProgramConsents;
};

export const determineExistingPhoneNumberErrors = (
  selectedPhoneNumbers: SelectedPhoneNumber[],
  phoneNumberOwners: PhoneNumberOwner[],
  errors: CommonError[],
  cartIndexUUID: string
) => {
  if (selectedPhoneNumbers) {
    selectedPhoneNumbers.forEach((selectedPhoneNumber, i) => {
      const index = cartItemIndex(cartIndexUUID, i);
      if (!selectedPhoneNumber.existingPhoneNumber) {
        // SIM card number can't be empty
        addEmptyFieldValidationErrorWithParent(errors, 'existingPhoneNumber', `existingPhoneNumber[${index}]`);
      } else if (isInvalidExistingPhoneNumber(selectedPhoneNumber.existingPhoneNumber)) {
        addValidationErrorWithParent(
          errors,
          t.YLCX(invalidPhoneNumberMsg),
          'existingPhoneNumber',
          `existingPhoneNumber[${index}]`
        );
      }
    });
  }
};

export function getSortedItems<T>(item: T, isCreate: boolean, key: string, items?: T[]): T[] | undefined {
  if (items) {
    if (!isCreate) {
      // Update
      return sortArray(
        items.map((arrayItem: T) => {
          // @ts-ignore
          if (arrayItem[key] === item[key]) {
            return { ...arrayItem, ...item };
          } else {
            return arrayItem;
          }
        }),
        'created',
        'desc'
      );
    } else {
      // Create
      return sortArray(items.concat([item]), 'created', 'desc');
    }
  }
  return undefined;
}

export function getSortedItemsWithIdObject<T>(
  item: T,
  isCreate: boolean,
  idObject: string,
  key: string,
  items?: T[]
): T[] | undefined {
  if (items) {
    if (!isCreate) {
      // Update
      return sortArray(
        items.map((arrayItem: T) => {
          // @ts-ignore
          if (arrayItem[idObject][key] === item[idObject][key]) {
            return { ...arrayItem, ...item };
          } else {
            return arrayItem;
          }
        }),
        'created',
        'desc'
      );
    } else {
      // Create
      return sortArray(items.concat([item]), 'created', 'desc');
    }
  }
  return undefined;
}

// filter errors which are not needed to be set in the state, because they are handled separately, e.g. showing a dialog on HTTP Status CONFLICT (409)
export const filterErrors = (errors?: CommonError[]) => {
  return errors?.filter(
    error =>
      error.source?.statusCode !== 409 ||
      (error.source?.statusCode === 409 &&
        (error.category === ConflictedAuthenticationResult.TypeEnum.ONBOARDING_LINK_USED_OR_EXPIRED ||
          error.category === ConflictedAuthenticationResult.TypeEnum.ONBOARDING_LINK_OLD_OR_INVALID))
  );
};

export const processConflictedErrors = (errors?: CommonError[]) => {
  return errors?.map(error => {
    if (
      error.source?.statusCode === 409 &&
      error.source?.error &&
      (error.source?.error as unknown as ConflictedAuthenticationResult)
    ) {
      const conflictingError = error.source.error as unknown as ConflictedAuthenticationResult;
      if (
        conflictingError?.type === ConflictedAuthenticationResult.TypeEnum.ONBOARDING_LINK_USED_OR_EXPIRED ||
        conflictingError?.type === ConflictedAuthenticationResult.TypeEnum.ONBOARDING_LINK_OLD_OR_INVALID
      ) {
        error.category = conflictingError.type;
      }
    }
    return error;
  });
};

export const mergeBillingAccountResponseItems = (
  billingAccounts?: BillingAccount[],
  billingAccountHeaders?: BillingAccountHeader[]
): Array<BillingAccount | BillingAccountHeader> | undefined => {
  if (!billingAccounts) {
    return billingAccountHeaders;
  }
  if (!billingAccountHeaders) {
    return billingAccounts;
  }
  return [...billingAccounts, ...billingAccountHeaders];
};

export const getItemsQueryForReports = (): ItemsQuery => ({ offset: 0 });

export const buildHeaderOnlyItemsQuery = (
  action:
    | LoadCustomerOrdersAction
    | LoadBillingAccountsAction
    | LoadSubscriptionActions
    | LoadEmployeeCustomerOrdersAction
    | LoadEmployeeSubscriptionActions,
  state: ActionsHistory | undefined | null,
  total: number | undefined,
  searchTerm?: string,
  status?: string,
  filterText?: string
): ItemsQuery | undefined => {
  const getAllItems =
    action.type !== TypeKeys.LOAD_CUSTOMER_ORDERS &&
    action.type !== TypeKeys.LOAD_SUBSCRIPTION_ACTIONS &&
    action.type !== TypeKeys.LOAD_EMPLOYEE_CUSTOMER_ORDERS &&
    action.type !== TypeKeys.LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS &&
    action.getAllItems;
  const previousQuery = getPreviousSuccessfulItemsQuery(action.type, state);

  const actionSort = combineSort(action.sort);

  const resetSearchCriteria = !!(
    !previousQuery ||
    (action.status !== undefined && previousQuery?.status && action.status !== previousQuery?.status) ||
    (action.filter?.filterValue !== undefined && action.filter?.filterValue !== previousQuery?.filterText) ||
    (action.search !== undefined && action.search !== previousQuery?.search) ||
    (actionSort?.columnId && actionSort?.columnId !== previousQuery.sort) ||
    (actionSort?.order && actionSort?.order !== previousQuery.order) ||
    action?.forceLoad
  );
  const loadAllRows = !!(getAllItems && !(previousQuery?.offset === 0 && previousQuery?.limit === undefined));
  const loadMoreRowsWithSameCriteria = previousQuery && previousQuery.offset + (previousQuery.limit || 0) < total!;
  const retainSearchCriteria = !!(!action.search && searchTerm);

  if (loadAllRows) {
    // Load all rows
    return {
      offset: 0,
    };
  } else if (resetSearchCriteria) {
    // Load first 30 (or given limit) rows with new criteria
    return {
      offset: 0,
      limit: action?.limit || 30,
      sort: actionSort?.columnId ?? previousQuery?.sort,
      order: actionSort?.order ?? previousQuery?.order,
      search: action.search ?? previousQuery?.search,
      status: action.status ?? previousQuery?.status,
      filterText: action.filter?.filterValue ?? previousQuery?.filterText,
    };
  } else if (loadMoreRowsWithSameCriteria && previousQuery) {
    // Load more rows with same criteria
    return {
      offset: previousQuery?.offset + (previousQuery?.limit || 0),
      limit: 30,
      sort: previousQuery?.sort,
      order: previousQuery?.order,
      search: previousQuery?.search,
      status: previousQuery?.status,
      filterText: previousQuery?.filterText,
    };
  } else if (retainSearchCriteria) {
    return {
      offset: previousQuery?.offset ?? 0,
      limit: previousQuery?.limit ?? 30,
      sort: previousQuery?.sort,
      order: previousQuery?.order,
      search: searchTerm,
      status,
      filterText,
    };
  }
  return undefined;
};

export const buildHeaderOnlyItemsQueryV2 = (action: LoadSubscriptionsAction): ItemsQuery | undefined => {
  const getAllItems = action.getAllItems || action.reporting;

  if (getAllItems) {
    return {
      offset: 0,
    };
  }
  return {
    offset: action.offset ?? 0,
    limit: action.limit,
    sort: action.sortColumn,
    order: action.sortOrder,
    search: action.search,
    status: action.status,
    filterText: action.filter?.filterValue,
  };
};

export const getOnlineModelFilteringOutNonOfferAddOns = (onlineModel: OnlineModel): OnlineModel => {
  return {
    ...onlineModel,
    offers: onlineModel?.offers?.map(offer => ({
      ...offer,
      commercialProducts: offer?.commercialProducts?.map(cp => {
        // SF has enriched selfservice with `isOfferAddon` flag, and rest of the online models will not have the flag
        // i.e, if the flag is missing then all the associations are applicable
        const filteredAssociations = cp.addOnAssociations?.filter(
          assoc => assoc.isOfferAddOn === undefined || assoc.isOfferAddOn
        );
        return {
          ...cp,
          addOnAssociations: filteredAssociations,
          associatedAddOns: cp.associatedAddOns?.filter(addOn =>
            filteredAssociations?.some(assoc => assoc.addOnCode === addOn.addOnCode)
          ),
        };
      }),
    })),
  };
};
