import { CompanySelector } from '../CompanySelector/CompanySelector.js';
import { TableUrlParams } from '../Table/index.js';
import { allCompaniesMsg, pcsMsg, t } from '../../common/i18n/index.js';
import {
  getActiveAccountMasterId,
  isSearchAllAccounts,
  setActiveAccountMasterId,
  setIsSearchAllAccounts,
} from '../../selfservice/common/localStorageUtils.js';
import { getUserAccounts } from '../Header/dynamic/headerFunctions.js';
import { isMultiBiz } from '../../common/utils/accountUtils.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import type * as CL from '@design-system/component-library';
import type { ChangeEvent, KeyboardEvent, MouseEvent } from 'react';
import type { RealAuthenticatedUserState } from '../../common/types/states.js';

import './ConsolidatedListViewSelector.scss';

export interface CompanySelectorForListViewsProps {
  authenticatedUser?: RealAuthenticatedUserState;
}

const getUserAccountsWithAllOption = (authenticatedUser?: RealAuthenticatedUserState) => {
  const userAccounts = getUserAccounts(authenticatedUser);
  return [
    {
      accountMasterId: undefined,
      active: isSearchAllAccounts(),
      name: `${t.WW6V(allCompaniesMsg)} (${userAccounts.length} ${t.B3MG(pcsMsg)})`,
    },
    ...userAccounts.map(acc => {
      return { ...acc, active: !isSearchAllAccounts() && acc.accountMasterId === getActiveAccountMasterId() };
    }),
  ];
};

export const ConsolidatedListViewSelector = ({ authenticatedUser }: CompanySelectorForListViewsProps) => {
  const { hash, pathname, search } = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  if (!isMultiBiz(authenticatedUser)) {
    return null;
  }

  return (
    <div className="company-selector-for-list-views">
      <CompanySelector
        autoCompleteConfig={{ isSortable: false }}
        inputRef={inputRef}
        onInputChange={(_e: ChangeEvent | KeyboardEvent | MouseEvent, option: Partial<CL.HeaderUserAccount>) => {
          if (option?.accountMasterId) {
            setActiveAccountMasterId(option.accountMasterId);
            setIsSearchAllAccounts(false);
          } else {
            setIsSearchAllAccounts(true);
          }
          const queryParams = new URLSearchParams(search);
          queryParams.set(TableUrlParams.PAGE, '1');
          queryParams.set(TableUrlParams.OFFSET, '0');
          // Use setTimeout without delay to wait for the call stack to be empty in order to execute the callback.
          // Otherwise blur() will cause the selected company to remain the same regardless of user selection.
          setTimeout(() => {
            navigate(`${pathname}?${queryParams}${hash}`, { replace: true });
            inputRef.current?.blur();
          });
        }}
        userAccounts={getUserAccountsWithAllOption(authenticatedUser)}
      />
    </div>
  );
};
