import { ButtonGroup, SubscriptionDetailsButtonType } from '../SubscriptionDetails/subscriptionDetailsButtons.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { Loading } from '../Loading/index.js';
import {
  SubscriptionDetailsAccordions,
  SubscriptionDetailsBillingAccount,
  getUpdateButtonType,
} from '../SubscriptionDetails/SubscriptionDetails.js';
import { SubscriptionDetailsMobile } from '../SubscriptionDetails/SubscriptionDetailsMobile.js';
import { SubscriptionPbxDetails } from '../../generated/api/subscriptionPbxDetails.js';
import { SubscriptionStatusType } from '../../generated/api/subscriptionStatusType.js';
import { SubscriptionType } from '../../generated/api/subscriptionType.js';
import { buildPathVariable, paths } from '../../common/constants/pathVariables.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { findSubscription, getSubscriptionStatus } from '../../common/utils/subscriptionUtils.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { getContactDisplayValue } from '../../common/utils/billingAccountUtils.js';
import {
  loadSubscriptionAddOnRules,
  loadSubscriptionAddOnRulesMobilePbx,
  resumeSubscription,
  showDialog,
  showTerminateSubscriptionForm,
  terminateSubscriptionAction,
} from '../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { DialogParams } from '../../common/types/dialog.js';
import type { State } from '../../selfservice/common/store.js';
import type { Subscription } from '../../generated/api/subscription.js';
import type { SubscriptionActionsState } from '../../common/types/states.js';
import type { SubscriptionDetailsProps } from '../SubscriptionDetails/SubscriptionDetails.js';

const getVoiceButtonTypes = (
  specialNumber?: SubscriptionPbxDetails.PbxTypeEnum,
  subscription?: Subscription,
  subscriptionActions?: SubscriptionActionsState
) => {
  const isSuspended = subscription?.subscriptionStatus === SubscriptionStatusType.SUSPENDED;
  const buttons = [
    getUpdateButtonType(
      subscription!,
      getSubscriptionStatus(subscription, subscriptionActions?.items).isChangeOfferAllowed
    ),
    isSuspended ? SubscriptionDetailsButtonType.RESUME_SUBSCRIPTION : SubscriptionDetailsButtonType.TEMPORARILY_CLOSE,
    SubscriptionDetailsButtonType.SUPPORT_REQUEST,
    SubscriptionDetailsButtonType.TERMINATE,
  ];

  if (subscription?.subscriptionType === SubscriptionType.MOBILE) {
    buttons.push(SubscriptionDetailsButtonType.MOBILE_FIXER);
  }

  if (specialNumber !== undefined) {
    const noResume = [
      SubscriptionPbxDetails.PbxTypeEnum.RYHMAKUTSU,
      SubscriptionPbxDetails.PbxTypeEnum.VASTAUSSARJA,
      SubscriptionPbxDetails.PbxTypeEnum.TAVOITETTAVUUSKETJU,
    ];
    return noResume.includes(specialNumber) ? buttons.slice(2) : buttons.slice(1);
  }
  return buttons;
};
export const SubscriptionVoice = (props: SubscriptionDetailsProps) => {
  const { breadCrumbs, subscriptionId, subscriptions } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscription = findSubscription(subscriptionId, subscriptions);
  const subscriptionActions = useSelector(
    (state: State) => state?.selfservice?.pendingSubscriptionActions || undefined,
    deepEqual
  );
  const contacts = useSelector((state: State) => state?.selfservice?.contacts || undefined, deepEqual);
  const selectedContact = contacts?.items?.find(c => c.contactId === subscription?.subscriptionContactId);
  const selectedContactName = getContactDisplayValue(selectedContact) || undefined;
  const showSuspendSubscriptionOptions = subscriptionActions?.suspendSubscriptionInitiated || false;
  const isTerminateSubscriptionForm = subscriptionActions?.terminateSubscriptionInitiated || false;
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));

  const heroPicto = 'sim';
  const heading = selectedContactName ?? subscription?.subscriptionPurposeOfUse ?? '…';
  let headingBottom: string | JSX.Element = '';
  const subscriptionDisplayId = subscription ? subscription.subscriptionDisplayId : '…';
  const subscriptionStatus = getSubscriptionStatus(subscription, subscriptionActions?.items);
  const onShowSubscriptionTerminationForm = () => dispatch(showTerminateSubscriptionForm());
  const onResumeSubscription = (subId: string) => dispatch(resumeSubscription(subId));

  if (!subscription?.details) {
    return (
      <DetailsWrapper
        classes={['of-subscription-details']}
        content={<Loading />}
        detailsTop={breadCrumbs}
        heading={heading}
        headingBottom={headingBottom}
        headingTop={subscription?.subscriptionName}
        heroPicto={heroPicto}
        id={`subscription-details-${subscriptionDisplayId}`}
      />
    );
  }

  const onClickSubscriptionChangeOfferSelection = (subDisplayId: string, subscriptionType: SubscriptionType) => {
    const redirectPath = (() => {
      switch (subscriptionType) {
        case SubscriptionType.MOBILE_M2M:
          return paths.PS_BROADBAND_SUBSCRIPTION_UPDATE_M2M;
        case SubscriptionType.MOBILE_BROADBAND:
          return paths.PS_BROADBAND_SUBSCRIPTION_UPDATE;
        default:
          return paths.PS_MOBILE_SUBSCRIPTION_UPDATE;
      }
    })();

    switch (subscriptionType) {
      case SubscriptionType.MOBILE_PBX:
        dispatch(loadSubscriptionAddOnRulesMobilePbx());
        break;
      case SubscriptionType.MOBILE_M2M:
        dispatch(loadSubscriptionAddOnRules());
        break;
      case SubscriptionType.MOBILE_BROADBAND:
      case SubscriptionType.MOBILE:
      default:
        dispatch(loadSubscriptionAddOnRules());
        break;
    }

    navigate(buildPathVariable(redirectPath, subDisplayId));
  };

  const specialNumber = subscription.subscriptionType === SubscriptionType.SPECIAL_NUMBER;
  const pbxType = subscription?.details?.specialNumber?.pbxConfigurationDetails?.pbxType;
  const subscriptionDetails = (
    <div className="of-subscription-details__content of-subscription-details__content--mobile">
      <SubscriptionDetailsMobile
        dispatch={dispatch}
        onShowDialog={onShowDialog}
        subscription={subscription}
        subscriptionStatus={subscriptionStatus}
        details={subscription.details}
        showSuspendSubscriptionOptions={showSuspendSubscriptionOptions}
        showTerminateSubscriptionForm={isTerminateSubscriptionForm}
        onTerminateSubscription={(subId, effectiveDate, donateNumber, terminateType) =>
          dispatch(terminateSubscriptionAction(subId, effectiveDate, donateNumber, terminateType))
        }
        onResumeSubscription={(subId: string) => dispatch(resumeSubscription(subId))}
        billingAccountSelection={<SubscriptionDetailsBillingAccount {...props} />}
        mobileDetails={subscription.details.mobile}
      />
      {!isTerminateSubscriptionForm && !showSuspendSubscriptionOptions && (
        <ButtonGroup
          buttonTypes={getVoiceButtonTypes(pbxType, subscription, subscriptionActions)}
          subscription={subscription}
          subscriptionStatus={subscriptionStatus}
          onShowDialog={onShowDialog}
          onShowSubscriptionTerminationForm={onShowSubscriptionTerminationForm}
          onResumeSubscription={onResumeSubscription}
          onClickCompanyInfoUser={() => {
            navigate(paths.COMPANY_INFO_RING_ADMINS);
          }}
          onClickSubscriptionChangeOfferSelection={onClickSubscriptionChangeOfferSelection}
        />
      )}
      <SubscriptionDetailsAccordions {...props} />
    </div>
  );
  headingBottom = subscription.subscriptionUserFriendlyId || '';
  if (
    subscription.details.mobilePbxEndUserService?.pbxConfigurationDetails.pbxType ===
      SubscriptionPbxDetails.PbxTypeEnum.RING &&
    subscription.details.mobilePbxEndUserService.corporateNumber
  ) {
    headingBottom = <>{formatPhoneNumber(subscription.details.mobilePbxEndUserService.corporateNumber, true)}</>;
  }
  if (!specialNumber && subscription.details!.mobile?.pbxConfiguration && subscription.subscriptionUserFriendlyId) {
    const pbxTypeFromDetails = subscription.details!.mobile!.pbxConfiguration.pbxConfigurationDetails.pbxType;
    if (pbxTypeFromDetails && pbxTypeFromDetails.length > 0) {
      headingBottom = (
        <>
          {formatPhoneNumber(subscription.subscriptionUserFriendlyId, true)} <span className="of-vertical-bar">|</span>{' '}
          {pbxTypeFromDetails === SubscriptionPbxDetails.PbxTypeEnum.VAKIO ? 'Vakio' : ''}
          {pbxTypeFromDetails === SubscriptionPbxDetails.PbxTypeEnum.RING ? 'Ring' : ''}{' '}
          {formatPhoneNumber(subscription.details.mobile!.pbxConfiguration.corporateNumber, true)}
        </>
      );
    }
  }

  return (
    <DetailsWrapper
      classes={['of-subscription-details']}
      content={subscriptionDetails}
      detailsTop={breadCrumbs}
      heading={heading}
      headingBottom={headingBottom}
      headingTop={subscription.subscriptionName}
      heroPicto={heroPicto}
      id={`subscription-details-${subscriptionDisplayId}`}
    />
  );
};
