import * as CL from '@design-system/component-library';
import { BreadCrumbs } from '../BreadCrumbs/index.js';
import { ContactRole } from '../../generated/api/models.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { DialogType } from '../../common/enums.js';
import { Email, Name, PhoneNumber } from '../../common/react-hook-form/fields/index.js';
import { FormProvider, useForm } from 'react-hook-form';
import { Loading } from '../Loading/index.js';
import { addRingAdmin, showDialog } from '../../selfservice/actions/index.js';
import {
  cancelMsg,
  firstNameMsg,
  lastNameMsg,
  multipleRingSolutionErrorDialogHeader,
  multipleRingSolutionErrorDialogMsg,
  omaElisaForCompaniesMsg,
  saveMsg,
  t,
  userInformationMsg,
} from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { AccountKeyUser, Contact, PostChangeMobilePbxSolutionRequest } from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';
import type { DialogParams } from '../../common/types/dialog.js';

import './RingAdminDetails.scss';

export interface RingAdminDetailsProps {
  ringAdminContact?: Contact;
  newAccountKeyUser?: AccountKeyUser;
  subscriptionIds?: string[];
  pendingSubscriptionChanges: boolean;
  errors?: CommonError[];
}

const getNameParams = (accountKeyUser: AccountKeyUser): { [p: string]: string } => {
  if (accountKeyUser.firstName && accountKeyUser.lastName) {
    return {
      firstName: accountKeyUser.firstName,
      lastName: accountKeyUser.lastName,
    };
  } else {
    return {
      firstName: accountKeyUser.displayName.split(' ').slice(0, -1).join(' '),
      lastName: accountKeyUser.displayName.split(' ').slice(-1).join(' '),
    };
  }
};

interface RingAdminDetailsData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

export const RingAdminDetails = ({
  ringAdminContact,
  newAccountKeyUser,
  subscriptionIds,
  pendingSubscriptionChanges,
}: RingAdminDetailsProps) => {
  const newRingAdminFullName = newAccountKeyUser && getNameParams(newAccountKeyUser);
  const ringAdminDisplayId: string | undefined = ringAdminContact ? ringAdminContact.contactId : '…';
  const ringAdminPerson = ringAdminContact && ringAdminContact.person ? ringAdminContact.person : undefined;

  const methods = useForm<RingAdminDetailsData>({
    values: {
      email: newAccountKeyUser?.email ?? '',
      firstName: newRingAdminFullName?.firstName ?? '',
      lastName: newRingAdminFullName?.lastName ?? '',
      phoneNumber: newAccountKeyUser?.mobile ?? '',
    },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let content: JSX.Element;
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));
  const fullName = newRingAdminFullName
    ? `${newRingAdminFullName?.firstName} ${newRingAdminFullName?.lastName}`
    : ringAdminContact
    ? `${ringAdminContact.person?.firstName} ${ringAdminContact.person?.lastName}`
    : '…';

  const removeAdminUser = (request: PostChangeMobilePbxSolutionRequest) => {
    if (subscriptionIds && subscriptionIds.length > 1) {
      onShowDialog({
        body: <>{t.NZ9L(multipleRingSolutionErrorDialogMsg)}</>,
        header: t.BJQR(multipleRingSolutionErrorDialogHeader),
        type: DialogType.GENERIC_INFO_DIALOG,
      });
    } else if (subscriptionIds && subscriptionIds.length === 1) {
      onShowDialog({
        request,
        ringSubscriptionId: subscriptionIds[0],
        fullName,
        type: DialogType.REMOVE_RING_ADMIN,
      });
    }
  };

  const onSubmit = (data: RingAdminDetailsData) => {
    if (subscriptionIds && subscriptionIds.length > 1) {
      onShowDialog({
        body: <>{t.NZ9L(multipleRingSolutionErrorDialogMsg)}</>,
        header: t.BJQR(multipleRingSolutionErrorDialogHeader),
        type: DialogType.GENERIC_INFO_DIALOG,
      });
    } else if (subscriptionIds && subscriptionIds.length === 1) {
      dispatch(
        addRingAdmin(
          {
            firstName: data.firstName.trim(),
            lastName: data.lastName.trim(),
            phoneNumber: data.phoneNumber.trim(),
            email: data.email.trim(),
            userName: newAccountKeyUser ? newAccountKeyUser.userName : '',
            roles: [ContactRole.MOBILE_PBX_ADMIN],
          },
          subscriptionIds[0]
        )
      );
    }
  };

  if (ringAdminPerson && ringAdminDisplayId) {
    content = (
      <div className="of-ring-admin-details__content">
        <CL.Description
          id="ring-admin-details-grid"
          items={[
            { title: t.AIK7(firstNameMsg), description: ringAdminPerson.firstName },
            { title: t.U0VC('Phone number (work)'), description: ringAdminPerson.phoneNumber },
            { title: t.Y41S(lastNameMsg), description: ringAdminPerson.lastName },
            { title: t.JYZO('Email (work)'), description: ringAdminPerson.email },
          ]}
        />
        <div className="ds-description">
          <CL.Button
            size="l"
            onClick={() => {
              if (subscriptionIds && !pendingSubscriptionChanges) {
                removeAdminUser({
                  removeAdminContacts: [
                    {
                      contactId: ringAdminDisplayId,
                      userName: ringAdminPerson.userName ? ringAdminPerson.userName : '',
                    },
                  ],
                });
              } else {
                onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING });
                return;
              }
            }}
          >
            {t.KFBL('Remove Ring admin rights')}
          </CL.Button>
        </div>
      </div>
    );
  } else if (newAccountKeyUser) {
    content = (
      <div className="of-ring-admin-details__content">
        <CL.NotificationBanner
          className="ds-text--m"
          title={t.SU0B('Confirm name and contact details')}
          text={t.OF04(
            `It's important to keep the contact details up to date, they are used for password recovery. Note, the login username remains the same even if e-mail address changes.`
          )}
        />
        <br />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CL.Grid>
              <CL.GridRow>
                <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
                  <Name name="firstName" label={t.AIK7(firstNameMsg)} />
                </CL.GridCol>
                <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
                  <Name name="lastName" label={t.Y41S(lastNameMsg)} />
                </CL.GridCol>
              </CL.GridRow>
              <CL.GridRow>
                <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
                  <PhoneNumber label={t.U0VC('Phone number (work)')} />
                </CL.GridCol>
                <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
                  <Email label={t.JYZO('Email (work)')} />
                </CL.GridCol>
              </CL.GridRow>
            </CL.Grid>
            <CL.Grid className="ds-margin-top--6">
              <CL.GridRow>
                <CL.Button type="submit" className="ds-margin-right--2">
                  {t.BOXP(saveMsg)}
                </CL.Button>
                <CL.Button color="light" type="button" onClick={() => navigate(paths.COMPANY_INFO_RING_ADMINS)}>
                  {t.B2V1(cancelMsg)}
                </CL.Button>
              </CL.GridRow>
            </CL.Grid>
          </form>
        </FormProvider>
      </div>
    );
  } else {
    content = <Loading />;
  }

  const breadCrumbs = (
    <BreadCrumbs
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.T1WX(userInformationMsg), path: paths.COMPANY_INFO_RING_ADMINS },
        { name: newAccountKeyUser ? t.CT9V('Update contact person information') : fullName },
      ]}
    />
  );

  return (
    <DetailsWrapper
      classes={['of-ring-admin-details']}
      content={content}
      detailsTop={breadCrumbs}
      id="ring-admin-details"
      heading={fullName}
      heroPicto="user"
    />
  );
};
