import { ModelType } from '../../common/enums.js';
import { mobileBroadbandMsg, mobileM2MMsg, t, voiceSubscriptionsMsg } from '../../common/i18n/index.js';

import './CardSelectionHeader.scss';

export const getIconDataByOnlineModelType = (type: ModelType) => {
  switch (type) {
    case ModelType.MobileBroadband:
      return {
        icon: 'picto-mobilebb',
        header: t.EQKJ(mobileBroadbandMsg),
        description: t.AZ26(
          `The mobile broadband travels along with you, whether you're working remotely or on the go`
        ),
      };
    case ModelType.VoiceSME:
      return {
        icon: 'picto-sim',
        header: t.AR0B(voiceSubscriptionsMsg),
        description: t.RDAU(
          `Elisa's efficient and superfast telephone subscriptions for business use ensure smooth working`
        ),
      };
    case ModelType.MobileM2M:
      return {
        icon: 'picto-broadband-m2m',
        header: t.A7CA(mobileM2MMsg),
        description: t.A7CB('Laitenetti is a good choice for your IP cameras, security systems and heating devices.'),
      };
    default:
      return undefined;
  }
};

interface CardSelectionHeaderProps {
  data?: {
    description: string;
    header: string;
    icon: string;
  };
}

export const CardSelectionHeader = ({ data }: CardSelectionHeaderProps) => {
  if (!data) {
    return null;
  }
  return (
    <div className="card-selection-header">
      <div className="icon">
        <div className={`picto ${data.icon}`}></div>
      </div>
      <div className="text">
        <h1 className="ds-margin-vertical--0 ds-padding-bottom--2">{data.header}</h1>
        <p className="ds-margin-vertical--0">{data.description}</p>
      </div>
    </div>
  );
};
