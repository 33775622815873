import * as CL from '@design-system/component-library';
import { CompanyInfo, CustomFormContactDetails, CustomFormErrorAndButton } from '../CustomFormFields.js';
import { CompanySearch, TextInput } from '../../../common/react-hook-form/fields/index.js';
import { CustomFormRadio } from '../partials/CustomFormRadio.js';
import { PriceSummary } from '../partials/PriceSummary.js';
import {
  additionalInformationMsg,
  mainUserEmailMsg,
  mainUserNameMsg,
  mainUserPhoneMsg,
  possibleExtraDetailsMsg,
  selectProductMsg,
  t,
} from '../../../common/i18n/index.js';
import type { CustomFormProps } from '../CustomForm.js';
import type { CustomFormRadioItem } from '../partials/CustomFormRadio.js';

const serviceOptions: CustomFormRadioItem[] = [
  {
    value: 'Yksisuuntainen palvelu',
    label: 'Yksisuuntainen palvelu',
    monthlyRecurringCharge: 4000,
    oneTimeCharge: undefined,
    isDefault: false,
  },
  {
    value: 'Kaksisuuntainen palvelu',
    label: 'Kaksisuuntainen palvelu',
    monthlyRecurringCharge: 10000,
    oneTimeCharge: undefined,
    isDefault: false,
  },
];

export const DialogiMessagingServiceForm = (props: CustomFormProps) => {
  const { leadFailed, leadInProgress, title, enableCompanyFields = true } = props;
  return (
    <CL.Grid className="of-custom-form">
      <CL.GridRow>
        <CL.GridCol className="title" colWidthS={6} colWidthM={6} colWidthL={12} colWidthXL={12}>
          <h2 className="ds-h2">{title}</h2>
        </CL.GridCol>
        <CL.GridCol colWidthS={6} colWidthM={6} colWidthL={6} colWidthXL={6}>
          <CustomFormRadio title={t.V9HK(selectProductMsg)} options={serviceOptions} />
          <PriceSummary {...props} />
        </CL.GridCol>
        <CL.GridCol colWidthS={6} colWidthM={6} colWidthL={6} colWidthXL={6}>
          {enableCompanyFields && (
            <>
              <CompanyInfo />
              <CompanySearch />
            </>
          )}
          <CustomFormContactDetails />
          <h3 className="ds-h3">{t.GZUK(additionalInformationMsg)}</h3>
          <TextInput
            name="mainUserName"
            required={true}
            label={t.WLNI(mainUserNameMsg)}
            placeholder={t.WLNI(mainUserNameMsg)}
          />
          <TextInput
            name="mainUserEmail"
            required={true}
            label={t.CI5R(mainUserEmailMsg)}
            placeholder={t.CI5R(mainUserEmailMsg)}
          />
          <TextInput
            name="mainUserPhone"
            required={false}
            label={t.BMK4(mainUserPhoneMsg)}
            placeholder={t.BMK4(mainUserPhoneMsg)}
          />
          <TextInput
            name="messageSenderName"
            required={false}
            label={t.RLGI(possibleExtraDetailsMsg)}
            placeholder={t.RLGI(possibleExtraDetailsMsg)}
          />
          <CustomFormErrorAndButton leadInProgress={leadInProgress} leadFailed={leadFailed} />
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};
