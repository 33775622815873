import * as CL from '@design-system/component-library';
import { CostCenter } from '../../../common/react-hook-form/fields/CostCenter.js';
import { GridColumn } from '../../BillingAccountDetails/components/GridColumn.js';
import { Reference } from '../../../common/react-hook-form/fields/Reference.js';
import { costCenterAndReferenceMsg, costCenterToolTipMsg, referenceToolTipMsg, t } from '../../../common/i18n/index.js';
import type { Contact } from '../../../generated/api/contact.js';
interface CostCenterAndEmployeeNumberProps {
  contacts?: Contact[];
  fieldPrefix?: string;
  isCostCenterMandatory?: boolean;
}

export const PurposeOfUseCostCenterAndEmployeeNumber = ({
  fieldPrefix = '',
  isCostCenterMandatory = false,
}: CostCenterAndEmployeeNumberProps) => {
  return (
    <div className="ds-padding-top--2">
      <h5 className="ds-margin-bottom--1 ds-margin-top--3">{t.JBEM(costCenterAndReferenceMsg)}</h5>
      <CL.Grid className="ds-padding-top--2">
        <CL.GridRow className="of-purpose-of-use__grid_row of-purpose-of-use__cost_center_employee_number">
          <GridColumn className="of-purpose-of-use__grid_col">
            <CostCenter
              name={`${fieldPrefix}costCenter`}
              tooltip={`${t.UE8R(costCenterToolTipMsg)}`}
              required={isCostCenterMandatory}
            />
          </GridColumn>
          <GridColumn className="of-purpose-of-use__grid_col">
            <Reference name={`${fieldPrefix}employeeNumber`} tooltip={t.HBBD(referenceToolTipMsg)} />
          </GridColumn>
        </CL.GridRow>
      </CL.Grid>
    </div>
  );
};
