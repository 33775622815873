import { HeroHeading, HeroHeadingType } from '../../../../../components/HeroHeading/index.js';
import { InvoiceDocumentList } from '../../../../../components/InvoiceDocuments/InvoiceDocumentList.js';
import { LocalNavi } from '../../../../../components/LocalNavi/index.js';
import {
  billingAccountsMsg,
  invoicesMsg,
  invoicingMsg,
  omaElisaForCompaniesMsg,
  t,
} from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useLoaderData } from 'react-router-dom';
import type { DocumentsLoaderData } from 'common/loaders.js';

export const InvoiceDocumentsPath = () => {
  const { documents } = useLoaderData() as DocumentsLoaderData;
  return (
    <>
      <HeroHeading
        breadCrumbPaths={[
          { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
          { name: t.OMMT('Invoice letters') },
        ]}
        heroHeadingType={HeroHeadingType.INVOICES}
        title={t.AUI8(invoicingMsg)}
      />
      <LocalNavi
        categories={[
          {
            children: t.Y7C0(invoicesMsg),
            to: paths.INVOICES,
          },
          {
            children: t.ZVMK(billingAccountsMsg),
            to: paths.BILLING_ACCOUNTS,
          },
          {
            children: t.OMMT('Invoice letters'),
            to: paths.INVOICE_DOCUMENTS,
          },
        ]}
      />
      <InvoiceDocumentList documentSearchResults={documents.searchResults || []} total={documents.total} />
    </>
  );
};
