import { TurbonappiBreadCrumbs } from './TurbonappiBreadCrumbs.js';
import { TurbonappiFrontPageLink } from './TurbonappiFrontPageLink.js';
import { TurbonappiOffer } from '../../generated/api/turbonappiOffer.js';
import { getTurbonappiPurchaseEvent } from './turbonappiAnalyticsUtils.js';
import { pushToDataLayer } from '../../common/analytics.js';
import { t } from '../../common/i18n/index.js';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './TurbonappiOrderConfirmation.scss';

const getOrderPeriodText = (offer?: TurbonappiOffer): string => {
  switch (offer) {
    case TurbonappiOffer.DAY:
      return t.PIGQ('one day');
    case TurbonappiOffer.WEEK:
      return t.DKN6('one week');
    case TurbonappiOffer.MONTH:
      return t.L62R('one month');
    default:
      throw new Error(`Unknown offer: ${offer}`);
  }
};

const ConfirmationView = ({ orderPeriodText }: { orderPeriodText: string }) => (
  <>
    <div className="ds-margin-left--5">
      <h1>{t.M5EN('Thank you for your order')}</h1>
    </div>
    <div className="of-turbonappi-order-confirmation">
      <div>
        <p>
          {t.OBMY(
            `Thank you for your order. You now have Turbonappi service in use, which gives extra speed to your subscription for ${orderPeriodText}.`,
            orderPeriodText
          )}
        </p>
        <p>{t.OI7J('You will receive a text message whenever your Turbonappi service is about to expire.')}</p>
        <p>{t.QUWJ('Order Confirmation is sent to you by email.')}</p>
        <TurbonappiFrontPageLink />
      </div>
      <div className="of-turbonappi-order-confirmation__icon-man-working" />
    </div>
  </>
);

export const TurbonappiOrderConfirmation = () => {
  const location = useLocation();
  const { turbonappiOrder } = location.state || {};

  useEffect(() => {
    pushToDataLayer(getTurbonappiPurchaseEvent(turbonappiOrder));
  }, [turbonappiOrder]);

  const orderPeriodText = getOrderPeriodText(turbonappiOrder?.offer);

  return (
    <>
      <TurbonappiBreadCrumbs additionalCrumb={true} />
      <ConfirmationView orderPeriodText={orderPeriodText} />
    </>
  );
};
