import { Link } from 'react-router-dom';

import './Table.scss';

export interface TableTopButton {
  label: string;
  onClick: () => void;
}

export interface TableTopLink {
  label: string;
  to: string;
}

export const TableTopActions = ({ buttons, links }: { buttons?: TableTopButton[]; links?: TableTopLink[] }) => (
  <div className="of-table__input-bar ds-display--flex ds-justify-content--flex-end">
    {buttons?.map(({ label, onClick }, i) => (
      <button
        className="ds-link ds-link--style-link-button ds-link--button-color-primary ds-link--button-size-m"
        key={i}
        onClick={onClick}
      >
        <span>{label}</span>
      </button>
    ))}
    {links?.map(({ label, to }, i) => (
      <Link
        className="ds-link ds-link--style-link-button ds-link--button-color-primary ds-link--button-size-m"
        key={i}
        to={to}
      >
        {label}
      </Link>
    ))}
  </div>
);
