import {
  AuthenticatedUserRole,
  Catalog,
  CommercialProductSubType,
  IdentityProvider,
  OnlineModelCategory,
} from '../../../generated/api/models.js';
import type { AuthenticatedUserState } from '../../../common/types/states.js';
import type { CommercialProduct, Offer } from '../../../generated/api/models.js';

// A Admin + Employee user can login to employee portal with userRole as KEY_USER
// Punchout user behaves same as employee user with respect to product details
export const isEmployeeUser = (user?: AuthenticatedUserState): boolean =>
  user?.userRole === AuthenticatedUserRole.EMPLOYEE ||
  user?.identityProvider === IdentityProvider.ELISA_ID ||
  user?.userRole === AuthenticatedUserRole.PUNCHOUT_USER;

const checkCpEligibilityForEmployeeForCatalog = (
  cp: CommercialProduct,
  catalogProductType?: Catalog.ProductTypeEnum,
  catalogContractPeriod?: number
) => {
  if (catalogProductType === Catalog.ProductTypeEnum.ONETIME && cp.payments === 1) {
    return true;
  }
  return (
    catalogProductType === Catalog.ProductTypeEnum.RECURRING &&
    cp.payments === catalogContractPeriod &&
    cp.productSubType === CommercialProductSubType.DEVICE
  );
};

const checkCpEligibilityForEmployeeForNonAccessoryProducts = (
  cp: CommercialProduct,
  catalogProductType?: Catalog.ProductTypeEnum,
  catalogContractPeriod?: number
) =>
  checkCpEligibilityForEmployeeForCatalog(cp, catalogProductType, catalogContractPeriod) ||
  (catalogProductType === Catalog.ProductTypeEnum.EPP_RECURRING &&
    cp.payments === catalogContractPeriod &&
    cp.productSubType === CommercialProductSubType.EPP_DEVICE);

/**
 * Only non-epp recurring priced accessories aren't allowed for epp catalog.
 * Assumption: Accessory never has Epp Prices.
 */
const checkCpEligibilityForEmployeeForAccessoryProducts = (
  cp: CommercialProduct,
  catalogProductType?: Catalog.ProductTypeEnum,
  catalogContractPeriod?: number
) =>
  checkCpEligibilityForEmployeeForCatalog(cp, catalogProductType, catalogContractPeriod) ||
  (catalogProductType === Catalog.ProductTypeEnum.EPP_RECURRING &&
    cp.payments === 1 &&
    cp.productSubType === CommercialProductSubType.DEVICE);

export const checkCpEligibilityForEmployee = (
  cp: CommercialProduct,
  catalog: Catalog,
  onlineModelCategory?: string
): boolean => {
  const { productType, contractPeriod } = catalog;
  if ((onlineModelCategory as OnlineModelCategory) === OnlineModelCategory.ACCESSORIES) {
    return checkCpEligibilityForEmployeeForAccessoryProducts(cp, productType, contractPeriod);
  }
  return checkCpEligibilityForEmployeeForNonAccessoryProducts(cp, productType, contractPeriod);
};

export const filterOffersAndCommercialProductsForEmployee = (
  offers: Offer[],
  catalog: Catalog,
  onlineModelCategory?: string
) =>
  offers
    .map(offer => {
      const newOffer = { ...offer };
      newOffer.commercialProducts = offer.commercialProducts.filter(
        cp => checkCpEligibilityForEmployee(cp, catalog, onlineModelCategory) && cp.active
      );
      return newOffer;
    })
    .filter(offer => offer.commercialProducts.length)
    .filter(offer => offer.active);
