import { AuthenticatedUserRole } from '../../generated/api/models.js';
import { authValidate, logIn, logOut } from '../../selfservice/actions/index.js';
import { createContext, useContext, useEffect, useState } from 'react';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { fetchExternalAuthMethods } from '../../common/fetch.js';
import { stripActions } from '../../common/utils/stateUtils.js';
import { useChangesPoller } from '../../common/hooks/useChangesPoller.js';
import { useDispatch, useSelector } from 'react-redux';
import type {
  AnonymousUserState,
  AuthorizationRequestState,
  ExternalAuthenticationState,
  RealAuthenticatedUserState,
} from '../../common/types/states.js';
import type { AuthenticationMethod } from '../../generated/api/models.js';
import type { ReactNode } from 'react';
import type { State } from '../../selfservice/common/store.js';

export interface AuthProps {
  anonymousUser?: AnonymousUserState | null | undefined;
  authenticatedUser?: RealAuthenticatedUserState | undefined;
  authorizationRequest?: AuthorizationRequestState | null;
  elisaIdSessionValid: boolean;
  externalAuthMethods: AuthenticationMethod[] | 'failed' | 'loading';
  externalAuthentication?: ExternalAuthenticationState | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  login: (username: string, password: string) => void;
  logout: (redirectTo?: string) => void;
  validateAuth: () => void;
  resetLoginForm: boolean;
  ssoSessionValid: boolean;
}

const defaultValues: AuthProps = {
  anonymousUser: undefined,
  authorizationRequest: undefined,
  elisaIdSessionValid: false,
  externalAuthMethods: [],
  externalAuthentication: undefined,
  isAuthenticated: false,
  isLoading: false,
  login: (_username: string, _password: string) => {},
  logout: (_redirectTo: string | undefined) => {},
  validateAuth: () => {},
  resetLoginForm: false,
  ssoSessionValid: false,
};

/**
 * @deprecated This exists solely for TestProvider and should not be used elsewhere.
 *             Once all auth data and functionality is moved away from redux this can be deleted.
 **/
// eslint-disable-next-line @typescript-eslint/naming-convention
export const useReduxAuthState_DO_NOT_USE = () => {
  const anonymousUser = useSelector((state: State) => stripActions(state.user?.anonymous), deepEqual) || undefined;
  const authenticatedUser =
    useSelector((state: State) => stripActions(state.user?.authenticated), deepEqual) || undefined;
  const authorizationRequest = useSelector((state: State) => state.authentication?.authorizationRequest, deepEqual);
  const externalAuthentication = useSelector((state: State) => state.authentication?.externalAuthentication, deepEqual);
  const elisaIdSessionValid = !!anonymousUser?.elisaIdSessionValid;
  const ssoSessionValid = !!anonymousUser?.ssoSessionValid;
  const resetLoginForm = !!anonymousUser?.resetLoginForm;
  const { companyName, firstName, userRole } = authenticatedUser || {};
  const isAuthenticated = Boolean(companyName && firstName && userRole === AuthenticatedUserRole.KEY_USER);
  const isLoading = authenticatedUser?.isFetching || (authenticatedUser == null && anonymousUser == null);

  return {
    anonymousUser,
    authenticatedUser,
    authorizationRequest,
    externalAuthentication,
    elisaIdSessionValid,
    ssoSessionValid,
    resetLoginForm,
    isAuthenticated,
    isLoading,
  };
};

// NOTE!
// - DO NOT use the `AuthContext` outside of this file, except for tests. It is only exported for tests.
// - We have to use default value because `AuthProvider` is placed very high in the React tree and during errors we render
//   `<RootErrorBoundary>` which uses `AuthProvider`. When that happens the `AuthProvider` is not rendered, thus a default
//   context needs to exist so that  `useAuth()` still works. So the above default values should be reasonable ones.
export const AuthContext = createContext(defaultValues);

export const AuthProvider = ({ children }: { children?: ReactNode }) => {
  const reduxAuth = useReduxAuthState_DO_NOT_USE();
  const [extAuthMethods, setExtAuthMethods] = useState<AuthenticationMethod[] | 'failed' | 'loading'>('loading');
  const dispatch = useDispatch();

  useChangesPoller(reduxAuth.authenticatedUser);

  useEffect(() => {
    const fn = async () => setExtAuthMethods(await fetchExternalAuthMethods());
    void fn();
  }, []);

  const login = (username: string, password: string) => {
    dispatch(logIn(username, password));
  };
  const logout = (redirectTo?: string) => {
    dispatch(logOut(redirectTo));
  };

  const validateAuth = () => {
    dispatch(authValidate());
  };

  useEffect(() => {
    dispatch(authValidate());
  }, [dispatch]);

  return (
    <AuthContext.Provider
      value={{
        ...reduxAuth,
        externalAuthMethods: extAuthMethods,

        login,
        logout,
        validateAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthProps => {
  return useContext(AuthContext);
};
