import { ButtonGroup, SubscriptionDetailsButtonType } from '../SubscriptionDetails/subscriptionDetailsButtons.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { Loading } from '../Loading/index.js';
import {
  SubscriptionDetailsAccordions,
  SubscriptionDetailsBillingAccount,
} from '../SubscriptionDetails/SubscriptionDetails.js';
import { SubscriptionType } from '../../generated/api/subscriptionType.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { findSubscription, getSubscriptionStatus } from '../../common/utils/subscriptionUtils.js';
import { getSubscriptionDetailsPbx } from '../SubscriptionDetails/subscriptionDetailsPbxCommon.js';
import { getSubscriptionDetailsSoftwareProduct } from '../SubscriptionDetails/subscriptionDetailsSoftwareProduct.js';
import { paths } from '../../common/constants/pathVariables.js';
import { showDialog } from '../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { DialogParams } from '../../common/types/dialog.js';
import type { State } from '../../selfservice/common/store.js';
import type { Subscription } from '../../generated/api/subscription.js';
import type { SubscriptionDetailsProps } from '../SubscriptionDetails/SubscriptionDetails.js';

export const SubscriptionService = (props: SubscriptionDetailsProps) => {
  const { breadCrumbs, subscriptionId, subscriptions } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscription = findSubscription(subscriptionId, subscriptions);
  const subscriptionActions = useSelector(
    (state: State) => state?.selfservice?.pendingSubscriptionActions || undefined,
    deepEqual
  );
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));
  const subscriptionStatus = getSubscriptionStatus(subscription, subscriptionActions?.items);
  const subscriptionDisplayId = subscription ? subscription.subscriptionDisplayId : '…';

  if (!subscription?.details) {
    return (
      <DetailsWrapper
        classes={['of-subscription-details']}
        content={<Loading />}
        detailsTop={breadCrumbs}
        heading={subscription?.subscriptionName || '…'}
        headingBottom={subscription?.details?.softwareProduct?.editionName || ''}
        headingTop=""
        heroPicto="generic-service-filled"
        id={`subscription-details-${subscriptionDisplayId}`}
      />
    );
  }

  const getHeroPicto = (sub: Subscription) => {
    if (sub?.details?.mobilePbx && subscription.subscriptionType === SubscriptionType.MOBILE_PBX) {
      return 'pbx';
    }
    if (sub.details?.mobilePbx && subscription.subscriptionType === SubscriptionType.MOBILE_PBX_LITE) {
      return 'pbx-lite';
    }
    return 'generic-service-filled';
  };

  const softwareServiceButtonTypes = subscription?.subscriptionSubType?.includes('Elisa Yritystietoturva')
    ? [SubscriptionDetailsButtonType.YTT_SUPPORT, SubscriptionDetailsButtonType.SUPPORT_REQUEST]
    : [SubscriptionDetailsButtonType.SUPPORT_REQUEST];

  const subscriptionDetails = (
    <div className="of-subscription-details__content of-subscription-details__content--service">
      {subscription?.details?.mobilePbx
        ? getSubscriptionDetailsPbx(
            subscription,
            subscriptionStatus,
            subscription.details,
            <SubscriptionDetailsBillingAccount {...props} />
          )
        : getSubscriptionDetailsSoftwareProduct(
            onShowDialog,
            subscription,
            subscriptionStatus,
            subscription.details,
            <SubscriptionDetailsBillingAccount {...props} />,
            subscription.details.softwareProduct
          )}
      <ButtonGroup
        buttonTypes={
          subscription?.details?.mobilePbx && subscription.subscriptionType === SubscriptionType.MOBILE_PBX
            ? [
                SubscriptionDetailsButtonType.RING_SERVICE,
                SubscriptionDetailsButtonType.SUBMIT_SUPPORT_CASE,
                SubscriptionDetailsButtonType.RING_ADMIN_USERS,
              ]
            : softwareServiceButtonTypes
        }
        subscription={subscription}
        subscriptionStatus={subscriptionStatus}
        onShowDialog={onShowDialog}
        onClickCompanyInfoUser={() => {
          navigate(paths.COMPANY_INFO_RING_ADMINS);
        }}
      />
      <SubscriptionDetailsAccordions {...props} />
    </div>
  );

  return (
    <DetailsWrapper
      classes={['of-subscription-details']}
      content={subscriptionDetails}
      detailsTop={breadCrumbs}
      heading={subscription.subscriptionName}
      headingBottom={subscription.details.softwareProduct?.editionName || ''}
      headingTop=""
      heroPicto={getHeroPicto(subscription)}
      id={`subscription-details-${subscriptionDisplayId}`}
    />
  );
};
