import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { EppDestroyedOrMissingDevice } from './EppDestroyedOrMissingDevice.js';
import { EppSupportOrServiceRequest } from './EppSupportOrServiceRequest.js';
import { SelectGroup } from '../SelectGroup/SelectGroup.js';
import { SelectGroupItem } from '../SelectGroup/SelectGroupItem.js';
import {
  devicesMsg,
  lostDeviceMsg,
  omaElisaForCompaniesMsg,
  supportOrServiceRequestMsg,
  t,
} from '../../common/i18n/index.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { paths } from '../../common/constants/pathVariables.js';
import type { Subscription } from '../../generated/api/models.js';

import './EppMaintenance.scss';

export interface EppMaintenanceAdminProps {
  subscription: Subscription;
  subscriptionId: string;
}

export const EppMaintenanceAdmin = ({ subscription, subscriptionId }: EppMaintenanceAdminProps) => {
  const navigate = useNavigate();
  const onCancel = () => navigate(generatePath(paths.PS_DEVICE, { subscriptionId }));
  const breadCrumbs = (
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.JJTM(devicesMsg), path: paths.PS_DEVICES },
        { name: subscriptionId, path: generatePath(paths.PS_DEVICE, { subscriptionId }) },
        { name: t.C7PN('Support request') },
      ]}
    />
  );

  return (
    <DetailsWrapper
      classes={['of-epp-maintenance']}
      content={
        <>
          <h3 className="ds-padding-bottom--4 ds-margin-top--0">{t.SQYY('Select subject')}</h3>
          <SelectGroup className="ds-font-weight--600">
            <SelectGroupItem title={t.E7S2(supportOrServiceRequestMsg)}>
              <EppSupportOrServiceRequest isEmployee={false} onCancel={onCancel} subscription={subscription} />
            </SelectGroupItem>
            <SelectGroupItem title={t.Z7IG(lostDeviceMsg)}>
              <EppDestroyedOrMissingDevice subscription={subscription} onCancel={onCancel} />
            </SelectGroupItem>
          </SelectGroup>
        </>
      }
      detailsTop={breadCrumbs}
      heading={subscription.subscriptionName}
      headingTop={t.N2CC('Damage or support request')}
      heroPicto="generic-service-filled"
      id="subscription-details"
    />
  );
};
