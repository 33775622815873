import * as CL from '@design-system/component-library';
import { showMoreMsg, t } from '../../../common/i18n/index.js';

export const ShowMoreButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="ds-display--flex ds-justify-content--center ds-padding-top--2">
      <CL.Button color="link" onClick={() => onClick()}>
        {t.H6OA(showMoreMsg)}
      </CL.Button>
    </div>
  );
};
