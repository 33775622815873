import * as CL from '@design-system/component-library';
import {
  emailMsg,
  invoiceDeliveryMethodMsg,
  monthMsg,
  monthlyChargeMsg,
  payerMsg,
  t,
} from '../../../common/i18n/index.js';
import { formatSum } from '../../../common/utils/priceUtils.js';
import { getConfiguredCommercialProducts } from '../../../common/utils/cartProductUtils.js';
import { getTotalSums } from '../../../common/utils/commercialProductUtils.js';
import { useSelector } from 'react-redux';
import type { BillingAccount } from '../../../generated/api/models.js';
import type { State } from '../../../selfservice/common/store.js';

import './PunchoutBillingInfo.scss';

type PunchoutBillingInfoProps = {
  personBillingAccount: BillingAccount;
  className?: string;
};

export const PunchoutBillingInfo = ({ personBillingAccount, className }: PunchoutBillingInfoProps) => {
  const totalMonthlyCharge = useSelector(
    (state: State) =>
      getTotalSums(getConfiguredCommercialProducts(state.deviceCheckout?.cartItems || [])).totalMonthlyRecurringCharge
  );

  const invoiceInfoText = t
    .BPVG(
      'Your invoice will be sent by email. You can find a copy of the invoice in ##Oma Elisa for personal customers##, through which you can also pay the invoice if you wish.'
    )
    .split('##');

  return (
    <div className={`of-punchout-checkout-billing-info ${className || ''}`}>
      <h2 className="of-sub-header ds-h2 ds-margin-bottom--3">{t.NMNN('Oma Laitelasku details')}</h2>
      <CL.Grid>
        <CL.GridRow>
          <CL.GridCol colWidthXS={4} colWidthS={2} colWidthM={3} colWidthL={6} colWidthXL={6}>
            <div className="of-punchout-checkout-billing-info__field">
              <label>{t.PB6S(payerMsg)}</label>
              <span>{personBillingAccount.payerName}</span>
            </div>
            <div className="of-punchout-checkout-billing-info__field">
              <label>{t.H272(invoiceDeliveryMethodMsg)}</label>
              <span>{t.OKYY(emailMsg)}</span>
              <br />
              <span>
                <em>{personBillingAccount.billReceiverEmail}</em>
              </span>
            </div>
          </CL.GridCol>
          <CL.GridCol colWidthS={6} colWidthM={1} colWidthL={6} colWidthXL={6}>
            <div className="of-punchout-checkout-billing-info__field">
              <label>{t.P0PS(monthlyChargeMsg)}</label>
              <span>{`${formatSum(totalMonthlyCharge)}/${t.XXVX(monthMsg)}`}</span>
            </div>
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
      <p>
        {invoiceInfoText[0]}
        <a href="https://verkkoasiointi.elisa.fi/">{invoiceInfoText[1]}</a>
        {invoiceInfoText[2]}
      </p>
    </div>
  );
};
