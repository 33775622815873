import * as CL from '@design-system/component-library';
import { changeOfDeviceMsg, t } from '../../../common/i18n/index.js';
import { getEppActionLocalization } from './eppDeviceChangeUtil.js';
import type { DeliveryOrder } from '../../../generated/api/deliveryOrder.js';
import type { Subscription } from '../../../generated/api/subscription.js';

export interface EppDeviceChangeSummaryProps {
  newEppProduct: DeliveryOrder;
  currentEppProduct: Subscription;
}

export const EppDeviceChangeSummary = ({ newEppProduct, currentEppProduct }: EppDeviceChangeSummaryProps) => {
  const eppAction = getEppActionLocalization(currentEppProduct.details?.device?.replacementAction);
  const currentProductName = currentEppProduct.subscriptionName;
  const replacementProductName = newEppProduct.deliveryOrderName;

  return (
    <CL.Disclaimer
      title={t.ZHBT(changeOfDeviceMsg)}
      icon={<CL.Icon icon="update" color="blue-600" />}
      className="ds-background-color--secondary-blue-100 ds-margin-bottom--5"
    >
      <div className="ds-text--s">
        {eppAction}: {currentProductName}
      </div>
      <div className="ds-text--s">
        {t.OL3F('Replacement')}: {replacementProductName}
      </div>
    </CL.Disclaimer>
  );
};
