import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';

import { BillingAccountSourceSystem } from '../../../../../common/utils/billingAccountUtils.js';
import { CatalogList } from '../../../../../components/CatalogList/CatalogList.js';
import { Outlet, useLoaderData, useParams } from 'react-router-dom';
import {
  getCustomerLevelDiscountedPrices,
  getMyselfSecondaryAccount,
  loadBillChannels,
  loadBillingAccounts,
  loadContacts,
  loadVirtualCatalogs,
} from '../../../../../selfservice/actions/index.js';
import { useAuth } from '../../../AuthProvider.js';
import { useDispatch, useStore } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams.js';
import type { CatalogCode } from '../../../../../common/constants/pathInterfaces.js';
import type { State } from '../../../../../selfservice/common/store.js';
import type { VirtualCatalogResponse } from '../../../../../generated/api/models.js';

export const CompanyInfoCatalogsOutlet = () => {
  const dispatch = useDispatch();
  const { catalogCode } = useParams<CatalogCode>();
  const companyInfo = useStore<State>().getState().selfservice?.companyInfo;
  const discountedPrices = Boolean(companyInfo?.discountedPrices);
  const secondaryAccounts = Boolean(companyInfo?.secondaryAccounts);

  useEffect(() => {
    if (!discountedPrices) {
      dispatch(getCustomerLevelDiscountedPrices());
    }
    if (catalogCode) {
      dispatch(loadVirtualCatalogs(true, catalogCode));
    }
    if (!secondaryAccounts) {
      dispatch(getMyselfSecondaryAccount());
    }
    dispatch(loadBillingAccounts(undefined, undefined, undefined, true, BillingAccountSourceSystem.SFDC));
    dispatch(loadBillChannels());
    dispatch(loadContacts());
  }, [catalogCode, discountedPrices, dispatch, secondaryAccounts]);

  return <Outlet />;
};

export const CompanyInfoCatalogsPath = () => {
  const {
    searchResults: virtualCatalogs,
    resultCountAggregations: aggregations,
    total: totalItems,
  } = useLoaderData() as VirtualCatalogResponse;
  const { search, productType, contractPeriod, status } = useSearchParams<{
    search?: string;
    productType?: string;
    contractPeriod?: string;
    status?: string;
  }>();
  const { authenticatedUser } = useAuth();

  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.CATALOGS}>
      <CatalogList
        searchInput={search}
        totalItems={totalItems}
        virtualCatalogs={virtualCatalogs}
        productTypeFilter={productType}
        contractPeriodFilter={contractPeriod}
        statusFilter={status}
        aggregations={aggregations}
        authenticatedUser={authenticatedUser}
      />
    </CompanyInfoContainer>
  );
};
