import { DialogWrapper } from '../../DialogWrapper/index.js';
import { NewInterworksAccountForm } from './NewInterworksAccountForm.js';
import { useAuth } from '../../../public/site/AuthProvider.js';
import type { BillChannel } from '../../../generated/api/billChannel.js';

export interface NewInterworksAccountDialogProps {
  onCloseDialog: () => void;
  billChannels: BillChannel[];
}

export const NewInterworksAccountDialog = ({ onCloseDialog, billChannels }: NewInterworksAccountDialogProps) => {
  const { authenticatedUser } = useAuth();

  return (
    <DialogWrapper onCloseDialog={onCloseDialog} closeable={true} align="top">
      <NewInterworksAccountForm
        businessId={authenticatedUser?.businessId ?? ''}
        companyName={authenticatedUser?.companyName ?? ''}
        billChannels={billChannels}
        onCancel={onCloseDialog}
      />
    </DialogWrapper>
  );
};
