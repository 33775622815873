import * as CL from '@design-system/component-library';
import { type Contact, ContactType } from '../../../generated/api/models.js';
import { ContactDropdown } from '../../../common/react-hook-form/fields/ContactDropdown.js';
import { Email } from '../../../common/react-hook-form/fields/index.js';
import { GridColumn } from './GridColumn.js';
import { ReceiverTypes } from '../../../common/utils/billingAccountUtils.js';
import { SelectRadio } from '../../../common/react-hook-form/components/SelectRadio.js';
import { emailMsg, t } from '../../../common/i18n/index.js';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';

const OtherReceiver = ({
  billReceiver,
  changeReceiver,
  contacts,
  disabled,
  onClickChangeReceiver,
}: {
  billReceiver: ContactType;
  changeReceiver: boolean;
  contacts: Contact[];
  disabled: boolean;
  onClickChangeReceiver: () => void;
}) => {
  if (billReceiver === ContactType.COMMON_FUNCTION && !changeReceiver) {
    return (
      <>
        <Email disabled={true} name="billingAccount.billReceiverName" label={t.XVHQ(emailMsg)} />
        <CL.Button color="light" onClick={() => onClickChangeReceiver()}>
          {t.ETV2('Change receiver')}
        </CL.Button>
      </>
    );
  }

  return (
    <>
      <ContactDropdown
        labelText={t.VYZS('Contact person')}
        canAddNewContacts={true}
        contacts={contacts}
        name="billingAccount.billReceiverId"
        id="billReceiverId"
        disabled={disabled}
        className="ds-margin-bottom--2"
      />
      <div className="ds-display--flex">
        <CL.Icon icon="information" type="light" size="s" />
        <span className="ds-text--small ds-margin-left--2">
          {t.RJT5('Select a contact from the list or create a new one.')}
        </span>
      </div>
    </>
  );
};

export const BillReceiverEdit = ({ disabled = false, contacts }: { disabled?: boolean; contacts: Contact[] }) => {
  const [changeReceiver, setChangeReceiver] = useState(false);
  const { watch } = useFormContext();
  const billReceiver = watch('billingAccount.billReceiverType');
  const billReceiverSelection = watch('billReceiverSelection');

  return (
    <>
      <CL.GridRow>
        <GridColumn className="of-billing-account-details form-column edit-left">
          <SelectRadio
            name="billReceiverSelection"
            disabled={disabled}
            items={[
              {
                label: t.ZCX7('Same as contact'),
                value: ReceiverTypes.SAME_AS_CONTACT,
              },
              {
                label: t.C6WY('Other'),
                value: ReceiverTypes.OTHER,
              },
            ]}
          />
        </GridColumn>
      </CL.GridRow>
      {billReceiverSelection === ReceiverTypes.OTHER && (
        <CL.GridRow>
          <GridColumn className="of-billing-account-details form-column edit-left">
            <OtherReceiver
              onClickChangeReceiver={() => setChangeReceiver(true)}
              changeReceiver={changeReceiver}
              billReceiver={billReceiver}
              disabled={disabled}
              contacts={contacts}
            />
          </GridColumn>
        </CL.GridRow>
      )}
    </>
  );
};
