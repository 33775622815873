import { HeaderSectionContent, SectionContent } from '../SectionContent.js';
import { SearchResultRow } from '../SearchResultRow.js';
import { SubscriptionActionHeader } from '../../../generated/api/subscriptionActionHeader.js';
import {
  approverContactMsg,
  cancelledMsg,
  deliveredMsg,
  openMsg,
  pendingApprovalMsg,
  processingMsg,
  redeemRequestDisplayIdMsg,
  t,
} from '../../../common/i18n/index.js';
import { formatTimestampToDDMMYYYY } from '../../../common/utils/dateUtils.js';
import { generatePath } from 'react-router-dom';
import { getResultObjectWithHighlightedValues } from '../highlightFunctions.js';
import { paths } from '../../../common/constants/pathVariables.js';
import type { IconColor } from '../../Icon/Icon.js';
import type { SearchResultBaseProps } from '../SearchResultRow.js';

export interface RedeemRequestProps extends SearchResultBaseProps {
  redeemRequest: SubscriptionActionHeader;
}

export interface SubscriptionActionStatus {
  color: IconColor;
  text: string;
}

export const subscriptionActionStatus = (status: SubscriptionActionHeader.StatusEnum): SubscriptionActionStatus => {
  switch (status) {
    case SubscriptionActionHeader.StatusEnum.PENDING:
      return { color: 'neutral-600', text: t.TJ54(openMsg) };
    case SubscriptionActionHeader.StatusEnum.PENDING_APPROVAL:
      return { color: 'light-orange', text: t.ZXA6(pendingApprovalMsg) };
    case SubscriptionActionHeader.StatusEnum.COMPLETED:
      return { color: 'green-600', text: t.ZXA6(deliveredMsg) };
    case SubscriptionActionHeader.StatusEnum.CANCELLED:
      return { color: 'neutral-600', text: t.TJ54(cancelledMsg) };
    case SubscriptionActionHeader.StatusEnum.ERROR:
    default:
      return { color: 'light-orange', text: t.VZZW(processingMsg) };
  }
};

export const RedeemRequest = ({ redeemRequest, matchedFields, searchTerms, ...rest }: RedeemRequestProps) => {
  const {
    subscriptionActionDisplayId,
    subscriptionName,
    subscriptionContactName,
    subscriptionSerialNumber,
    requesterContactName,
    subscriptionDisplayId,
  } = getResultObjectWithHighlightedValues(redeemRequest, matchedFields, searchTerms);
  const { color, text } = subscriptionActionStatus(redeemRequest.status);
  return (
    <SearchResultRow
      icon="invoice"
      upperSection={
        <HeaderSectionContent
          left={
            <>
              {t.B951('Redeem request')}: {subscriptionDisplayId}
            </>
          }
          right={subscriptionContactName}
        />
      }
      lowerSection={
        <SectionContent
          statusColor={color}
          left={`${text} ${formatTimestampToDDMMYYYY(redeemRequest.lastModified) || ''}`}
          right={subscriptionName}
        />
      }
      additionalHighlightableFields={[
        { title: 'IMEI', value: subscriptionSerialNumber, key: 'subscriptionSerialNumber' },
        { title: t.GOZ7(approverContactMsg), value: requesterContactName, key: 'requesterContactName' },
        {
          title: t.K84B(redeemRequestDisplayIdMsg),
          value: subscriptionActionDisplayId,
          key: 'subscriptionActionDisplayId',
        },
      ]}
      matchedFields={matchedFields}
      linkHref={generatePath(paths.CUSTOMER_ORDER_REDEEM_REQUEST, {
        requestId: redeemRequest.subscriptionActionDisplayId,
      })}
      {...rest}
    />
  );
};
