import * as CL from '@design-system/component-library';
import { CompositeList } from '../CompositeList/index.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getPeriodicPriceAsText } from '../SubscriptionDetails/subscriptionDetailsCommon.js';
import { getProductCategoryDetails } from '../CatalogProductsSelection/CatalogProductsSelection.js';
import { pcsMsg, t } from '../../common/i18n/index.js';
import type { CatalogProduct, SupportedModelCategory } from '../../common/utils/catalogUtils.js';
import type { CompositeListColumn } from '../CompositeListHeader/index.js';

import './VirtualCatalogProductSummary.scss';

interface VirtualCatalogProductsSummaryListProps {
  code: string;
  name: string;
  price: string;
}

interface VirtualCatalogProductsSummaryProps {
  products: Record<SupportedModelCategory, Array<CatalogProduct>>;
}

export const VirtualCatalogProductsSummary = ({ products }: VirtualCatalogProductsSummaryProps) => {
  const catalogProductsColumns: CompositeListColumn<VirtualCatalogProductsSummaryListProps>[] = [
    {
      columnId: 'productName',
      ref: 'name',
    },
    {
      columnId: 'productPrice',
      ref: 'price',
    },
  ];

  const getItemsSummary = (
    displayText: string,
    showAdditionalInfo: boolean,
    productCategory: string,
    items: VirtualCatalogProductsSummaryListProps[]
  ) =>
    items.length > 0 && (
      <div key={productCategory} id={`catalog-product-summary-${productCategory}`}>
        <CL.Description
          columns={1}
          items={[
            {
              title: `${displayText} (${items.length} ${t.B3MG(pcsMsg)})`,
              description: showAdditionalInfo
                ? t.X32V('The device selected for the list includes all available memory and colour variations.')
                : '',
            },
          ]}
        />
        <CompositeList
          columns={catalogProductsColumns}
          getRowId={item => item.code}
          items={items}
          mobileTableLayout={true}
          hideHeader={true}
        />
      </div>
    );

  return (
    <div className="of-catalog-product-summaries">
      {Object.values(products).every(value => !value.length) ? (
        <p> {t.UVCL('No products in catalogue.')} </p>
      ) : (
        Object.entries(products).map(([category, product = []]: [SupportedModelCategory, CatalogProduct[]]) => {
          const { displayText, showAdditionalInfo } = getProductCategoryDetails(category);
          return getItemsSummary(
            displayText,
            showAdditionalInfo,
            category,
            product.map(({ code, manufacturer, monthlyRecurringCharge, name, oneTimeCharge }) => ({
              code: code,
              name: `${manufacturer} ${name}`,
              price:
                formatSum(oneTimeCharge) ||
                `${t.OD1L('Starting at')} ${getPeriodicPriceAsText(monthlyRecurringCharge)}`,
            }))
          );
        })
      )}
    </div>
  );
};
