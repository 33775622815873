import { AddOnVisibilityOperationType } from '../../../../../common/enums.js';
import { Spinner } from '../../../siteUtils.js';
import { SubscriptionChangeConfirmation } from '../../../../../components/OrderSubscriptionSelection/SubscriptionChangeConfirmation.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { ensureNonNullable } from '../../../../../common/utils/validationUtils.js';
import { useAddOnRules } from '../../../../../common/hooks/useAddOnRules.js';
import { useAddOnVisibility } from '../../../../../common/hooks/useAddOnVisibility.js';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { AddedAddon } from '../../../../../common/types/addon.js';
import type { CampaignAssociation } from '../../../../../generated/api/campaignAssociation.js';
import type { CommercialProduct } from '../../../../../generated/api/commercialProduct.js';
import type { State } from '../../../../../selfservice/common/store.js';
import type { Subscription } from '../../../../../generated/api/subscription.js';

export interface SubscriptionUpdateNavigationState {
  selectedCommercialProduct: CommercialProduct;
  subscription: Subscription;
  selectedAddOns?: AddedAddon[];
  campaignAssociation?: CampaignAssociation;
  campaignDescription?: string;
}

const validateMandatoryParameters = (
  subscription: Subscription,
  selectedCommercialProduct: CommercialProduct
): void => {
  ensureNonNullable(subscription, 'subscription');
  ensureNonNullable(selectedCommercialProduct, 'selectedCommercialProduct');
};

export const MobileSubUpdateConfirmationPath = () => {
  useAddOnRules();
  const location = useLocation();
  const { selectedCommercialProduct, subscription, selectedAddOns, campaignAssociation, campaignDescription } =
    (location.state as SubscriptionUpdateNavigationState) || {};
  const addOnRules = useSelector((state: State) => state?.selfservice?.subscriptionAddOnRules, deepEqual);

  validateMandatoryParameters(subscription, selectedCommercialProduct);

  const { addOnVisibilities, addOnVisibilitiesError } = useAddOnVisibility(
    selectedCommercialProduct?.commercialProductCode,
    AddOnVisibilityOperationType.CHANGE
  );

  if (addOnVisibilitiesError) {
    throw new Error(
      `Cannot fetch addon visibilities for commercial product ${selectedCommercialProduct?.commercialProductCode}}`
    );
  }

  if (!addOnRules || !addOnVisibilities) {
    return <Spinner />;
  }

  return (
    <SubscriptionChangeConfirmation
      selectedCommercialProduct={selectedCommercialProduct}
      subscription={subscription}
      addOnRules={addOnRules}
      selectedAddOns={selectedAddOns}
      campaignAssociation={campaignAssociation}
      addOnVisibilities={addOnVisibilities}
      campaignDescription={campaignDescription}
    />
  );
};
