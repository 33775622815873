import { CustomerOrderList } from '../../../../../components/CustomerOrderList/CustomerOrderList.js';
import {
  EmployeeMyOrdersBreadcrumb,
  EmployeeMyOrdersNavi,
  EmployeeMyOrdersWrapper,
} from '../../../../../components/EmployeeMyOrdersWrapper/EmployeeMyOrdersWrapper.js';
import { Grid } from '../../../../../components/Grid/Grid.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { loadEmployeeCustomerOrders } from '../../../../../selfservice/actions/index.js';
import { myOrdersMsg, omaElisaForEmployeeMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useEffect } from 'react';
import { useTitle } from '../../../../../common/hooks/useTitle.js';
import type { CompositeListSort } from '../../../../../components/CompositeListHeader/index.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const OwnOrdersPath = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const featureFlags = useStore<State>().getState().config.featureFlags;
  const employeeCustomerOrders = useSelector((s: State) => s.user?.authenticated?.employeeCustomerOrders, deepEqual);

  useTitle([t.H6JV(omaElisaForEmployeeMsg), t.EG6F(myOrdersMsg)]);

  useEffect(() => {
    dispatch(loadEmployeeCustomerOrders(undefined, undefined, undefined, undefined, undefined, undefined, undefined));
  }, [dispatch, featureFlags]);

  return (
    <EmployeeMyOrdersWrapper>
      <EmployeeMyOrdersBreadcrumb />
      <EmployeeMyOrdersNavi />
      <Grid>
        <CustomerOrderList
          items={employeeCustomerOrders ? employeeCustomerOrders.items : undefined}
          onClickCustomerOrder={(id: string) => {
            navigate(generatePath(paths.EMPLOYEE_OWN_ORDER, { subscriptionId: id }));
          }}
          onLoadMoreCustomerOrders={(reporting?: boolean, forceLoad?: boolean, status?: string) => {
            dispatch(
              loadEmployeeCustomerOrders(undefined, undefined, undefined, undefined, reporting, forceLoad, status)
            );
          }}
          onSearchCustomerOrders={(newSearchValue: string, status?: string) =>
            dispatch(
              loadEmployeeCustomerOrders(undefined, undefined, undefined, newSearchValue, false, undefined, status)
            )
          }
          onSortCustomerOrders={(newSort: CompositeListSort, status?: string) =>
            dispatch(loadEmployeeCustomerOrders(undefined, undefined, newSort, undefined, undefined, undefined, status))
          }
          search={employeeCustomerOrders?.search}
          searchResults={employeeCustomerOrders?.searchResults}
          sort={employeeCustomerOrders?.sort}
          total={employeeCustomerOrders?.total}
        />
        <p className="ds-margin-top--5">
          <strong>{t.FZU3(`Can't you see your order here?`)}</strong>{' '}
          {t.QZZR('If you just placed it, no worries. The order will appear here with a little delay.')}
        </p>
      </Grid>
    </EmployeeMyOrdersWrapper>
  );
};
