import * as React from 'react';
import { AccountManagerDetailsCard } from '../AccountManagerDetailsCard/AccountManagerDetailsCard.js';
import { CompositeList } from '../CompositeList/CompositeList.js';
import { ContactChannelsInfoCard } from '../ContactChannelsInfoCard/ContactChannelsInfoCard.js';
import { CustomerOrderStatus } from '../../generated/api/models.js';
import { DialogType } from '../../common/enums.js';
import { Grid } from '../Grid/Grid.js';
import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { NavigationMenu, NavigationMenuItem } from '../NavigationMenu/NavigationMenu.js';
import { buildPathVariable, paths } from '../../common/constants/pathVariables.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getCustomerOrderColumnsV2 } from '../CustomerOrderList/CustomerOrderList.js';
import {
  helloMsg,
  invoicesMsg,
  myProductsAndServicesMsg,
  newSubscriptionMsg,
  notPlacedOrdersMsg,
  omaElisaClassicMsg,
  orderHistoryMsg,
  t,
  welcomeToManageYourBusinessMsg,
  yourLatestOrdersMsg,
} from '../../common/i18n/index.js';
import { invoiceListColumns } from '../InvoiceList/InvoiceList.js';
import { loadCustomerOrders, showDialog } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../../common/hooks/useTitle.js';
import type { CustomerOrder, CustomerOrderHeader, Invoice } from '../../generated/api/models.js';
import type { State } from '../../selfservice/common/store.js';

import './SelfServiceHomeScene.scss';

const MAX_ROW_AMOUNT = 3;

export const SelfServiceHomeScene = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authenticatedUser } = useAuth();
  const { customerOrders, openInvoices, accountManager, classicSiteUrl } = useSelector(
    (state: State) => ({
      customerOrders: state.selfservice?.customerOrders,
      openInvoices: state.selfservice?.invoices?.open || null,
      accountManager: state.selfservice?.companyInfo?.accountManager,
      classicSiteUrl: state.config.classicSiteUrl,
    }),
    deepEqual
  );

  useTitle();

  const waitingOrders =
    customerOrders?.items?.filter(item => item.status === CustomerOrderStatus.PENDING_APPROVAL)?.length ?? 0;

  React.useEffect(() => {
    dispatch(loadCustomerOrders());
  }, [dispatch]);

  return (
    <div className="of-self-service-home-scene">
      <HeroHeading
        heroHeadingType={HeroHeadingType.SELFSERVICE_HOME}
        title={t.WR9Q(helloMsg, authenticatedUser?.firstName || '')}
        subtitle={t.A47Y(welcomeToManageYourBusinessMsg)}
      />
      <Grid>
        <div className="of-self-service-home-scene__navi">
          <NavigationMenu>
            <NavigationMenuItem
              to={paths.INVOICES}
              id="category-menu-item-invoices"
              onClick={(event: React.SyntheticEvent) => {
                event.preventDefault();
                navigate(paths.INVOICES);
              }}
              icon={<span className="ea-icon ea-icon--medium ea-icon--invoice" aria-hidden="true" />}
              label={t.Y7C0(invoicesMsg)}
            />
            <NavigationMenuItem
              to={paths.CUSTOMER_ORDERS}
              id="category-menu-item-customer-orders"
              onClick={(event: React.SyntheticEvent) => {
                event.preventDefault();
                navigate(paths.CUSTOMER_ORDERS);
              }}
              icon={
                <span className="ea-icon ea-icon--medium ea-icon--delivery" aria-hidden="true">
                  {waitingOrders ? (
                    <span className="of-self-service-home-scene__order-count">{waitingOrders}</span>
                  ) : null}
                </span>
              }
              label={t.GCO3(orderHistoryMsg)}
            />
            <NavigationMenuItem
              to={paths.PS_HOME}
              id="category-menu-item-subscriptions"
              onClick={(event: React.SyntheticEvent) => {
                event.preventDefault();
                navigate(paths.PS_HOME);
              }}
              icon={<span className="ea-icon ea-icon--medium ea-icon--devices" aria-hidden="true" />}
              label={t.VWNX(myProductsAndServicesMsg)}
            />
            <NavigationMenuItem
              id="category-menu-item-my-offers"
              to={paths.MY_OFFERS}
              target="_blank"
              rel="noreferrer"
              icon={
                <span className="ea-icon ea-icon--medium ea-icon--diamond of-diamond-enrichment" aria-hidden="true" />
              }
              label={t.GE4D('My offers')}
            />
            <NavigationMenuItem
              id="category-menu-item-new-subscription"
              linkType="clickable"
              onClick={() =>
                dispatch(
                  showDialog({
                    type: DialogType.SELECT_NEW_SUBSCRIPTION_TYPE,
                    hideBlankSim: true,
                    showBlankSimLink: true,
                  })
                )
              }
              icon={<span className="ea-icon ea-icon--medium ea-icon--add" aria-hidden="true" />}
              label={t.OK8J(newSubscriptionMsg)}
            />
            <NavigationMenuItem
              id="category-menu-item-old-omaelisa"
              linkType="external"
              href={classicSiteUrl}
              icon={<span className="ea-icon ea-icon--medium ea-icon--reply" aria-hidden="true" />}
              label={t.SLBP(omaElisaClassicMsg)}
            />
          </NavigationMenu>
        </div>
      </Grid>
      <div className="of-self-service-home-scene__content">
        <div className="of-self-service-home-scene__content-block">
          <CompositeList
            classes={['of-invoice-list']}
            columns={invoiceListColumns()}
            emptyListElement={<span>{t.KHD2('You have no open invoices')}</span>}
            getRowId={(invoice: Invoice) => invoice.invoiceDisplayId}
            topLeft={<h3 className="ea-h3 ea-h3--thick">{t.INV3('Open invoices')}</h3>}
            items={openInvoices && openInvoices.items ? openInvoices.items.slice(0, MAX_ROW_AMOUNT) : undefined}
            onSelectRow={(id: string) => {
              navigate(buildPathVariable(paths.INVOICE, id));
            }}
            topRightLink={{
              onClick: () => {
                navigate(paths.INVOICES);
              },
              text: t.MEHC('All invoices'),
            }}
            topRightHideOnMobile={true}
          />
        </div>
        <div className="of-self-service-home-scene__content-block">
          <CompositeList
            classes={['of-customer-order-list']}
            columns={getCustomerOrderColumnsV2()}
            emptyListElement={<span>{t.EGKZ(notPlacedOrdersMsg)}</span>}
            getRowId={(customerOrderHeader: CustomerOrder | CustomerOrderHeader) =>
              customerOrderHeader.customerOrderDisplayId || ''
            }
            topLeft={<h3 className="ea-h3 ea-h3--thick">{t.WENR(yourLatestOrdersMsg)}</h3>}
            items={customerOrders?.searchResults?.map(res => res.result)?.slice(0, MAX_ROW_AMOUNT)}
            onSelectRow={(id: string) => {
              navigate(buildPathVariable(paths.CUSTOMER_ORDER, id));
            }}
            topRightLink={{
              onClick: () => {
                navigate(paths.CUSTOMER_ORDERS);
              },
              text: t.GCO3(orderHistoryMsg),
            }}
            topRightHideOnMobile={true}
          />
        </div>
        <div className="of-self-service-home-scene__contact-info">
          {accountManager && <AccountManagerDetailsCard accountManager={accountManager} />}
          <ContactChannelsInfoCard />
        </div>
      </div>
    </div>
  );
};
