import { DeviceReport } from '../common/DeviceReport.js';
import { elisaDevicesServiceMsg, t } from '../../../common/i18n/index.js';
import { getEppPhoneReport } from './eppPhoneReportUtil.js';

export const EppPhoneReport = () => {
  return (
    <DeviceReport
      prepareReport={getEppPhoneReport}
      fileName="EppPhoneReport.csv"
      itemClass="ea-picto--epp-phone"
      heading={t.TRE5(elisaDevicesServiceMsg)}
      description={t.VL3Z('This report covers the mobile phones used in your company’s Elisa Devices Service')}
    />
  );
};
