import { FixedBroadbandOrder } from '../../../../components/FixedBroadbandOrder/FixedBroadbandOrder.js';
import { Header } from '../../../../components/Header/Header.js';
import { loadBillChannels } from '../../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const AnonymousFixedBroadbandOrderPath = () => {
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();

  useEffect(() => {
    dispatch(loadBillChannels());
  }, [dispatch]);

  return (
    <>
      {!state?.disableHeader && <Header />}
      <FixedBroadbandOrder currentPath={pathname} disableBreadcrumbs={state?.disableHeader} />
    </>
  );
};
