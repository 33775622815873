import { AutoComplete } from '../../../components/AutoComplete/AutoComplete.js';
import { billingAccountMsg, noSelectionMsg, t } from '../../i18n/index.js';
import { getBillingAccountListItems } from '../../../components/AddOrSelectBillingAccountsV2/AddOrSelectBillingAccounts.js';
import { getTextError } from '../utils.js';
import { useController, useFormContext } from 'react-hook-form';
import type { BillingAccountSearchResponse } from '../../../generated/api/billingAccountSearchResponse.js';
import type { SearchItemProps } from '@design-system/component-library';

interface BillingAccountProps {
  billingAccounts: Array<BillingAccountSearchResponse>;
  name: string;
}

export const BillingAccount = (props: BillingAccountProps) => {
  const name = props.name;
  const formattedBas = getBillingAccountListItems(true, props.billingAccounts, '').map(x => ({ ...x, id: x.value }));
  const methods = useFormContext();
  const { control, setValue, setError } = methods;
  const rules = { required: { value: true, message: t.GMD4('The billing account must be chosen') } };
  const { field, fieldState, formState } = useController({ name, control, rules });
  const error = getTextError(formState.isSubmitted, fieldState.isTouched, fieldState.error);
  const keys = name.split('.');
  const defaultValues = formState.defaultValues;
  const value = keys.reduce((current, key) => (current ? current[key] : undefined), defaultValues);
  const defaultValue = formattedBas.find(i => i.value === value);

  return (
    <AutoComplete<SearchItemProps>
      className="large-min-height"
      inputRef={field.ref}
      options={formattedBas}
      defaultOption={defaultValue}
      getUniqueId={i => i.value}
      getDisplayHtml={i => i.html}
      getDisplayValue={i => i.label}
      onInputBlur={field.onBlur}
      onInputChange={(e, item) => {
        setValue(name, item.value);
        setError(name, { message: undefined });
      }}
      id={`${name}-autocomplete`}
      noOptionsMsg={t.ZW5W(noSelectionMsg)}
      label={t.IFT9(billingAccountMsg)}
      error={error}
    />
  );
};
