import { BILLING_DELIVERY_METHOD, BillingFieldset } from './BillingFieldset.js';
import { BillingContactFieldset } from './BillingContactFieldset.js';
import { ButtonGroupForSubmitAndBack } from '../../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import {
  COMPANY_POSTAL_ADDRESS_FIELD_NAME,
  COMPANY_POSTAL_CODE_FIELD_NAME,
  COMPANY_POST_OFFICE_FIELD_NAME,
  CompanyDetailsFieldset,
} from './CompanyDetailsFieldset.js';
import { CompanyContactFieldset } from './CompanyContactFieldset.js';
import { FormProvider, useForm } from 'react-hook-form';
import type { BILLING_ADDRESS_ALIAS, BILLING_BILL_E_ADDRESS, BILLING_BILL_E_OPERATOR } from './BillingFieldset.js';
import type {
  BILLING_CONTACT_EMAIL_FIELD_NAME,
  BILLING_CONTACT_FIRST_NAME_FIELD_NAME,
  BILLING_CONTACT_LAST_NAME_FIELD_NAME,
  BILLING_CONTACT_PHONE_FIELD_NAME,
} from './BillingContactFieldset.js';
import type { BillChannel } from '../../../generated/api/billChannel.js';
import type { CONTACT_EMAIL_FIELD_NAME, CONTACT_PHONE_FIELD_NAME } from './CompanyContactFieldset.js';

import { CreateInterworksAccountRequest } from '../../../generated/api/createInterworksAccountRequest.js';
import { DEFAULT_COUNTRY_CODE } from '../../../common/utils/validationUtils.js';
import { DialogType } from '../../../common/enums.js';
import { createInterworksAccount, createInterworksRedirectUrl } from '../../../common/fetch.js';
import {
  licenseManagementRedirectFailedHeaderMsg,
  licenseManagementRedirectFailedMsg,
  t,
} from '../../../common/i18n/index.js';
import { showDialog } from '../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { LicenseManagementUrlResponse } from '../../../generated/api/licenseManagementUrlResponse.js';

interface NewInterworksAccountFormProps {
  businessId: string;
  companyName: string;
  billChannels: BillChannel[];
  onCancel: () => void;
}

interface NewInterworksAccountFormValues {
  [COMPANY_POSTAL_ADDRESS_FIELD_NAME]: string;
  [COMPANY_POSTAL_CODE_FIELD_NAME]: string;
  [COMPANY_POST_OFFICE_FIELD_NAME]: string;
  [CONTACT_EMAIL_FIELD_NAME]: string;
  [CONTACT_PHONE_FIELD_NAME]: string;
  [BILLING_CONTACT_FIRST_NAME_FIELD_NAME]: string;
  [BILLING_CONTACT_LAST_NAME_FIELD_NAME]: string;
  [BILLING_CONTACT_EMAIL_FIELD_NAME]: string;
  [BILLING_CONTACT_PHONE_FIELD_NAME]: string;
  [BILLING_DELIVERY_METHOD]: CreateInterworksAccountRequest.BillingDeliveryMethodEnum;
  [BILLING_BILL_E_OPERATOR]: string;
  [BILLING_BILL_E_ADDRESS]: string;
  [BILLING_ADDRESS_ALIAS]: string;
}

const NewInterworksAccountFormDefaultValues = {
  [BILLING_DELIVERY_METHOD]: CreateInterworksAccountRequest.BillingDeliveryMethodEnum.EMAIL,
};

export const NewInterworksAccountForm = (props: NewInterworksAccountFormProps) => {
  const methods = useForm<NewInterworksAccountFormValues>({
    mode: 'all',
    defaultValues: NewInterworksAccountFormDefaultValues,
  });
  const dispatch = useDispatch();
  const { handleSubmit } = methods;

  const onSubmit = async (re: NewInterworksAccountFormValues) => {
    try {
      const req: CreateInterworksAccountRequest = {
        billingAddress: {
          line1: re[COMPANY_POSTAL_ADDRESS_FIELD_NAME],
          postalCode: re[COMPANY_POSTAL_CODE_FIELD_NAME],
          postOffice: re[COMPANY_POST_OFFICE_FIELD_NAME],
          countryCode: DEFAULT_COUNTRY_CODE,
        },
        ...re,
      };
      await createInterworksAccount(req);
      const { url }: LicenseManagementUrlResponse = await (await createInterworksRedirectUrl()).json();
      // Safari is blocking any call to window.open() which is made inside an async call.
      // As we want to open url in a new browser tab, window.location() cannot be used.
      // This is workaround that fixes the Safari issue and also works on other browsers.
      setTimeout(() => {
        window.open(url, '_blank', 'noopener,noreferrer');
      });
    } catch (error) {
      dispatch(
        showDialog({
          body: <>{t.NMRJ(licenseManagementRedirectFailedMsg)}</>,
          header: t.DT3P(licenseManagementRedirectFailedHeaderMsg),
          type: DialogType.GENERIC_INFO_DIALOG,
        })
      );
    }
  };
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(async data => {
          await onSubmit(data).then(props.onCancel);
        })}
        noValidate
      >
        <div className="ds-margin-bottom--4">
          <h3>{t.NF7W('Complete your account information')}</h3>
          <p>{t.G013('In order to use the license management portal, complete the following information')}</p>
        </div>
        <CompanyDetailsFieldset companyName={props.companyName} businessId={props.businessId} />
        <CompanyContactFieldset />
        <BillingContactFieldset />
        <BillingFieldset billChannels={props.billChannels} />
        <ButtonGroupForSubmitAndBack
          className="ds-padding-top--4 ds-text-align--right"
          submitButtonText="Submit"
          onSubmit={() => {}}
          cancelButtonText="Cancel"
          onCancel={props.onCancel}
          submitting={methods.formState.isSubmitting}
        />
      </form>
    </FormProvider>
  );
};
