import { RenderHtmlBlock } from '../../common/utils/htmlUtils.js';
import { createElement } from 'react';
import type { ProductGridProps } from './ProductGrid.js';

export const ProductGridTitle = ({ title, titleTag = 'h1', content }: ProductGridProps) => {
  const renderTitle = () => {
    if (title) {
      return createElement(titleTag, { className: `ds-${titleTag} product-element-title ds-margin-bottom--4` }, title);
    }
    return null;
  };

  return (
    <div className="ea-fgrid">
      <div className="ea-fgrid__item ea-fgrid__item--center ea-fgrid__item--block ea-fgrid__item--phone-6 ea-fgrid__item--tablet-12 ds-text-align--center">
        {renderTitle()}
        {content && (
          <RenderHtmlBlock type="div" className="">
            {content}
          </RenderHtmlBlock>
        )}
      </div>
    </div>
  );
};
