import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { reduceCrudAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { ReportsState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function reportsReducer(
  state: (ReportsState & ActionsHistory) | undefined,
  action: SelfServiceActionTypes
): (ReportsState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.LOAD_EPP_REPORT: {
      return {
        ...reduceCrudAction(action, state),
        eppReport: {
          loading: true,
          report: undefined,
          reportType: action.reportType,
        },
      };
    }

    case TypeKeys.LOAD_EPP_REPORT_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.LOAD_EPP_REPORT, state, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        eppReport: {
          loading: false,
          report: action.report,
          reportType: action.reportType,
        },
        errors: undefined,
      };
    }

    case TypeKeys.LOAD_EPP_REPORT_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.LOAD_EPP_REPORT, state, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        eppReport: {
          loading: false,
        },
        errors: action.errors,
      };
    }

    case TypeKeys.POLL_FOR_MYSELF_CHANGES_FULFILLED: {
      if (action.reportStatusChange) {
        return {
          ...state,
          latestReportStatusChange: action.reportStatusChange,
        };
      }
      return state;
    }

    default:
      return state;
  }
}
