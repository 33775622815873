import * as CL from '@design-system/component-library';
import { BillingAccount } from '../../generated/api/billingAccount.js';
import { BillingAccountDeliveryMethod } from '../../generated/api/billingAccountDeliveryMethod.js';
import { BillingAccountFieldset } from '../BillingAccount/BillingAccountFieldset.js';
import { ContactType } from '../../generated/api/contactType.js';
import { DeliveryMethodFieldset } from '../BillingAccount/DeliveryMethodFieldset.js';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ReceiverTypes,
  createNewBillingAccount,
  getElectronicInvoiceOperatorDropDownOptions,
} from '../../common/utils/billingAccountUtils.js';
import { cancelMsg, confirmMsg, t } from '../../common/i18n/index.js';
import { formatTimestampToDDMMYYYY, getNormalizedAdjustedDate } from '../../common/utils/dateUtils.js';
import { generatePath, useLoaderData, useLocation, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { paths } from '../../common/constants/pathVariables.js';
import { startNotification } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import type { BaLoaderData, CompanyInfoLoaderData } from '../../common/loaders.js';
import type { ContactPersonFormValues, ReceiverType } from '../../common/utils/billingAccountUtils.js';

export const BillingAccountForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const loaderData = useLoaderData() as BaLoaderData;
  const { companyInfo } = useRouteLoaderData('baRoot') as CompanyInfoLoaderData;
  const [isSaving, setIsSaving] = useState(false);
  const defaultPayerAddress = companyInfo?.address;
  const defaultOperator =
    (loaderData.billChannels && getElectronicInvoiceOperatorDropDownOptions(loaderData.billChannels)[0].value) ??
    undefined;

  const fourteenDaysFromNow = getNormalizedAdjustedDate(14, undefined, undefined, false);
  const expirationDate = formatTimestampToDDMMYYYY(fourteenDaysFromNow.getTime());

  const defaultBillingAccount: BillingAccount = {
    payerBusinessId: companyInfo?.businessId,
    payerName: companyInfo?.companyName ?? '',
    additionalBillReceiverEmails: [],
    billLanguage: 'FI',
    billReceiverEmail: '',
    billReceiverId: location?.state?.defaultBillingContactId,
    billReceiverName: '',
    billReceiverType: ContactType.PERSON,
    billingAccountName: companyInfo?.companyName ?? '',
    billingContactId: location?.state?.defaultBillingContactId,
    billFormatType: BillingAccount.BillFormatTypeEnum.CONSOLIDATED_INVOICE_WITH_ITEMIZATION,
    deliveryMethod: location?.state?.defaultDeliveryMethod ?? BillingAccountDeliveryMethod.ELECTRONIC,
    billElectronicOperator: defaultOperator,
    payerAddress: {
      line1: defaultPayerAddress?.line1 ?? '',
      postalCode: defaultPayerAddress?.postalCode ?? '',
      postOffice: defaultPayerAddress?.postOffice ?? '',
      countryCode: 'FIN',
    },
  };

  const defaultContactFormValues: ContactPersonFormValues = {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  };

  const methods = useForm({
    defaultValues: {
      billingAccount: defaultBillingAccount,
      billReceiverSelection: ReceiverTypes.SAME_AS_CONTACT,
      contact: { ...defaultContactFormValues },
      newBillReceiverContact: { ...defaultContactFormValues },
    },
  });

  const onSubmit = async ({
    billingAccount,
    billReceiverSelection,
    contact,
    newBillReceiverContact,
  }: {
    billingAccount: BillingAccount;
    billReceiverSelection: ReceiverType;
    contact: ContactPersonFormValues;
    newBillReceiverContact: ContactPersonFormValues;
  }) => {
    setIsSaving(true);
    const newBillingAccount = { ...billingAccount };

    try {
      const createBillingAccountResponse = await createNewBillingAccount({
        newBillingAccount,
        billReceiverSelection,
        newContact: contact,
        newBillReceiverContact,
        contacts: loaderData.contacts.contacts,
      });

      dispatch(startNotification(t.OS99('Billing account was successfully created.')));
      navigate(
        generatePath(paths.BILLING_ACCOUNT, {
          billingAccountId: createBillingAccountResponse.billingAccountDisplayId,
        }),
        {
          state: {
            showExpirationWarning: true,
            expirationDate,
          },
        }
      );
    } catch (err) {
      dispatch(startNotification(err.message, 'error'));
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <CL.Disclaimer
          className="ds-margin-top--6 ds-margin-bottom--6"
          disclaimerType="info"
          icon={<CL.Icon icon="information" />}
          text={t.FI29(
            'Please note that the billing account is valid for 14 days. If no products or services are ' +
              'ordered for the billing account within this time period, it will be automatically cancelled.'
          )}
        />
        <BillingAccountFieldset billChannels={loaderData.billChannels} contacts={loaderData.contacts} />
        <DeliveryMethodFieldset billChannels={loaderData.billChannels} contacts={loaderData.contacts.contacts ?? []} />
        <div className="ds-display--flex ds-justify-content--flex-end">
          <CL.Button type="submit" className="ds-margin-right--4" loading={isSaving}>
            {t.QVYK(confirmMsg)}
          </CL.Button>
          <CL.Button
            color="light"
            onClick={() => {
              navigate(paths.BILLING_ACCOUNTS);
            }}
          >
            {t.B2V1(cancelMsg)}
          </CL.Button>
        </div>
      </form>
    </FormProvider>
  );
};
