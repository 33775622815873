import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { Checkbox } from '../../common/react-hook-form/components/Checkbox.js';
import { DatePicker } from '../../common/react-hook-form/components/DatePicker.js';
import { DialogType, TerminateType } from '../../common/enums.js';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectRadio } from '../../common/react-hook-form/components/SelectRadio.js';
import { SubscriptionPbxDetails } from '../../generated/api/subscriptionPbxDetails.js';
import { SubscriptionType } from '../../generated/api/subscriptionType.js';
import { closeTerminateSubscriptionForm } from '../../selfservice/actions/index.js';
import { confirmAgreementTerminationMsg, confirmMsg, t } from '../../common/i18n/index.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import {
  getLocalizedDateWithLeadingZeroes,
  getNormalizedAdjustedDate,
  getNormalizedDateFromDateString,
  isWeekend,
} from '../../common/utils/dateUtils.js';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import type { DialogParams } from '../../common/types/dialog.js';
import type { Subscription } from '../../generated/api/subscription.js';
import PbxTypeEnum = SubscriptionPbxDetails.PbxTypeEnum;

import './SubscriptionDetailsMobileTermination.scss';

const TERMINATION_DATE_FIELD_NAME = 'terminationDate';

interface SubscriptionDetailsMobileTerminationProps {
  onShowDialog: (params: DialogParams) => void;
  subscription: Subscription;
  onTerminateSubscription: (
    subscriptionId: string,
    effectiveDate: number,
    donateNumber?: boolean,
    terminateType?: string
  ) => void;
  terminateSubscriptionLoading: boolean;
}

interface FormValues {
  donateNumber: boolean;
  terminationDate: string;
  terminateType: TerminateType;
}

const validateDate = (value: string, firstAllowedDate: Date, lastAllowedDate: Date, donateNumber: boolean) => {
  const givenDate = getNormalizedDateFromDateString(value);
  if (givenDate < firstAllowedDate) {
    return donateNumber
      ? t.LVJT('When transferring mobile number to employee, the first possible termination date is a week from now')
      : t.T4CE('The date cannot be in the past');
  } else if (givenDate > lastAllowedDate) {
    return t.TPQ5('The last possible termination date is three months from now');
  } else if (isWeekend(givenDate)) {
    return t.YAJE('Termination date must be outside weekends');
  } else {
    return undefined;
  }
};

export const SubscriptionDetailsMobileTermination = ({
  onShowDialog,
  subscription,
  onTerminateSubscription,
  terminateSubscriptionLoading,
}: SubscriptionDetailsMobileTerminationProps) => {
  const defaultDate = getNormalizedAdjustedDate(undefined, undefined, undefined, true);
  const dispatch = useDispatch();
  const [firstAllowedDate, setFirstAllowedDate] = useState(defaultDate);
  const lastAllowedDate = getNormalizedAdjustedDate(undefined, 3);
  const methods = useForm<FormValues>({
    defaultValues: {
      donateNumber: false,
      terminationDate: getLocalizedDateWithLeadingZeroes(firstAllowedDate, 'fi'),
      terminateType: TerminateType.ALL,
    },
  });
  const { setValue, handleSubmit, watch, clearErrors, formState } = methods;
  const mobilePbxConfiguration = subscription?.details?.mobile?.pbxConfiguration;
  const isRing = mobilePbxConfiguration?.pbxConfigurationDetails?.pbxType === PbxTypeEnum.RING;
  const ringUsername = mobilePbxConfiguration?.pbxConfigurationDetails.ringUserName || '';
  const ringExtensionNumber = mobilePbxConfiguration?.extensionNumber || '';
  const ringCorporateNumber =
    (mobilePbxConfiguration && formatPhoneNumber(mobilePbxConfiguration.corporateNumber)) || '';
  const isTerminationDateSet = !!formState.dirtyFields.terminationDate;

  const onSubmit = (formValues: FormValues) => {
    const terminationDateAsTimestamp = getNormalizedDateFromDateString(formValues.terminationDate).getTime();
    if (isRing) {
      onShowDialog({
        header: t.EFXH('Service termination'),
        body: (
          <div className="ds-padding-bottom--4">
            <p>{t.IVZR('Subscription will be terminated in few minutes and will no longer be in use.')}</p>
            <p>
              {t.MEMF(
                'We will confirm termination via email. Please note that you will receive a final invoice for a terminated subscription if usage or monthly charges have not yet been invoiced.'
              )}
            </p>
          </div>
        ),
        onConfirm: onCloseDialog => {
          onTerminateSubscription(
            subscription.subscriptionId,
            terminationDateAsTimestamp,
            formValues.donateNumber,
            formValues.terminateType
          );
          onCloseDialog();
        },
        type: DialogType.GENERIC_CONFIRMATION_DIALOG,
      });
    } else {
      onTerminateSubscription(
        subscription.subscriptionId,
        terminationDateAsTimestamp,
        formValues.donateNumber,
        TerminateType.ALL
      );
    }
  };

  const clearAndSetTerminationDate = (date: Date) => {
    clearErrors(TERMINATION_DATE_FIELD_NAME);
    setValue(TERMINATION_DATE_FIELD_NAME, getLocalizedDateWithLeadingZeroes(date, 'fi'));
  };

  const onDonateNumberChanged = (donateNumber: boolean) => {
    const currentSelectedDate = getNormalizedDateFromDateString(watch().terminationDate);
    const oneWeekFromNow = getNormalizedAdjustedDate(7, undefined, undefined, true);
    const twoWeeksFromNow = getNormalizedAdjustedDate(14, undefined, undefined, true);
    const firstAllowedDateToSelect = donateNumber ? oneWeekFromNow : defaultDate;
    setFirstAllowedDate(firstAllowedDateToSelect);

    if (!donateNumber && !isTerminationDateSet) {
      clearAndSetTerminationDate(defaultDate);
    } else if (donateNumber && isTerminationDateSet && currentSelectedDate < oneWeekFromNow) {
      clearAndSetTerminationDate(oneWeekFromNow);
    } else if (donateNumber && !isTerminationDateSet) {
      clearAndSetTerminationDate(twoWeeksFromNow);
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="terminate-subscription-form">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <h3>{t.KLNT(confirmAgreementTerminationMsg)}</h3>
          {subscription.subscriptionType === SubscriptionType.MOBILE && (
            <>
              <Checkbox
                label={t.TF3M('Phone number will be transferred to the user of the subscription')}
                name="donateNumber"
                onChange={onDonateNumberChanged}
              />
              {watch().donateNumber && (
                <div className="terminate-subscription-form__donate-number-information">
                  <p>
                    <b>{t.PVYK('If a mobile phone number is assigned to an employee, ')}</b>{' '}
                    {t.KB2E(
                      'an SMS message will be sent immediately to the user of the subscription. The user must make a number transfer order at least one week before the notice date you selected.'
                    )}
                  </p>
                  <p>
                    {t.A48T(
                      'Ask the user to place a number transfer order well in advance, so that they do not lose their number. The subscription will be terminated automatically on the date you mentioned if the person has not begun using the number.'
                    )}
                  </p>
                  <p>
                    {t.WLWK(
                      `Please notice that transfer to user means changing the subscription to Elisa's consumer subscription.`
                    )}
                  </p>
                </div>
              )}
            </>
          )}
          <div className="terminate-subscription-form__termination-date">
            <DatePicker
              // Workaround to force CL.DatePicker to re-render after setValue for terminationDate
              key={firstAllowedDate.toDateString()}
              disableWeekends={true}
              label={t.H4JG('Termination date')}
              name={TERMINATION_DATE_FIELD_NAME}
              required={true}
              validate={(givenDate: string) =>
                validateDate(givenDate, firstAllowedDate, lastAllowedDate, watch().donateNumber)
              }
              visibleRange={[
                [firstAllowedDate.getFullYear(), firstAllowedDate.getMonth() + 1, firstAllowedDate.getDate()],
                [lastAllowedDate.getFullYear(), lastAllowedDate.getMonth() + 1, lastAllowedDate.getDate()],
              ]}
            />
          </div>
          <div>
            {t.BHWT(
              'Please note that you will receive a final invoice for a terminated subscription if usage or monthly charges have not yet been invoiced.'
            )}
          </div>
          {isRing && (
            <>
              <h4 className="ds-margin-top--6">{t.VU38('Other services')}</h4>
              <strong>{t.SXTT('Ring subscription')}</strong>
              <div className="ds-margin-top--2">
                <SelectRadio
                  name="terminateType"
                  items={[
                    {
                      label: t.TTKS('I want to also terminate Ring-service'),
                      value: TerminateType.ALL,
                    },
                    {
                      label: t.JWJO('I want to continue Ring-service'),
                      value: TerminateType.VOICE,
                    },
                  ]}
                />
              </div>
              {watch().terminateType === TerminateType.ALL
                ? t.UVFA(
                    'Username {} will be removed. Extension {} and corporate number {} will be released.',
                    ringUsername,
                    ringExtensionNumber,
                    ringCorporateNumber
                  )
                : t.QPHB(
                    'Username {}, extension {} and corporate number {} will be retained. To use Ring without mobile, you need to add Software Phone or IP-phone Terminal.',
                    ringUsername,
                    ringExtensionNumber,
                    ringCorporateNumber
                  )}
            </>
          )}
          <div className="terminate-subscription-form__actions">
            <ButtonGroupForSubmitAndBack
              onSubmit={() => {}}
              submitting={terminateSubscriptionLoading}
              onCancel={() => dispatch(closeTerminateSubscriptionForm())}
              cancelDisabled={terminateSubscriptionLoading}
              submitButtonText={t.QVYK(confirmMsg)}
            />
          </div>
        </form>
      </div>
    </FormProvider>
  );
};
