import * as CL from '@design-system/component-library';
import * as React from 'react';
import type { FieldInputProps, FormikHelpers, FormikState, FormikValues } from 'formik';

export interface RadioComponentProps {
  children?: string | JSX.Element;
  className?: string;
  disabled?: boolean;
  field: FieldInputProps<string>;
  form: FormikState<FormikValues> & FormikHelpers<FormikValues>;
  id: string;
  label: string;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement>,
    form: FormikState<FormikValues> & FormikHelpers<FormikValues>
  ) => void;
}

/**
 * Basic radio functionality saves value with given name under values as {name: value}
 * Functionality can be extended by giving onChange function which receives change event and formik tooling
 */
export const RadioComponent = ({
  children,
  className,
  disabled = false,
  field,
  form,
  id,
  label,
  onChange,
}: RadioComponentProps) => {
  return (
    <div className={className}>
      <div className={`of-formik-radio of-formik-radio-${disabled ? 'inactive' : 'active'}`}>
        <CL.Radio
          {...field}
          disabled={disabled}
          id={id}
          label={label}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            field.onChange(e);
            onChange?.(e, form);
          }}
        />
      </div>
      {children && children}
    </div>
  );
};
