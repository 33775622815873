import { EppRedeemTerminateRequestType, SimType } from '../../generated/api/models.js';
import { TypeKeys } from '../actions/index.js';
import { WizardType } from '../../common/enums.js';
import { t, yourMessageWasSentMsg } from '../../common/i18n/index.js';
import type * as CLT from '@design-system/component-library';
import type { NotificationsState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

const getChangeSimMessage = (simType: SimType, isDelivery = false): string => {
  if (simType === SimType.ESIM) {
    return t.DNMO('Thank you! eSIM order submitted successfully.');
  } else if (isDelivery) {
    return t.RTNX('Order shipped successfully');
  }
  return t.XUMF('SIM card activated successfully.');
};

export function notificationsReducer(
  state: NotificationsState | undefined | null,
  action: SelfServiceActionTypes
): NotificationsState | null {
  if (typeof state === 'undefined') {
    return null;
  }

  const stateWithNotification = (notification: CLT.NotificationProps) => {
    if (!state) {
      return {
        items: [notification],
      };
    } else {
      return {
        ...state,
        items: [...(state.items || []), notification],
      };
    }
  };

  switch (action.type) {
    case TypeKeys.ADD_SUPPORT_CASE_FULFILLED: {
      if (action.noSuccessNotification) {
        return state;
      }
      const notification: CLT.NotificationProps = {
        text: action.successNotificationMsg ?? t.Q61D(yourMessageWasSentMsg),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.UPSERT_BILLING_ACCOUNT_FULFILLED: {
      if (action.wizardParams?.type === WizardType.NEW_MOBILE_BROADBAND) {
        return state;
      }

      const notification: CLT.NotificationProps = {
        text: t.FFJ2('Changes were successfully saved'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.UPSERT_BILLING_ACCOUNT_SCHEDULED_CHANGE_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.UA9T('Change request was sent successfully.'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.UPSERT_BILLING_ACCOUNT_SCHEDULED_CHANGE_FAILED: {
      const notification: CLT.NotificationProps = {
        text: t.JXYZ('Change request sending failed.'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'error',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.UPSERT_CONTACT_FULFILLED: {
      if (!action.showNotification) {
        return state;
      }
      const notification: CLT.NotificationProps = {
        text: t.EZ2Q('Contact details updated successfully'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.UPDATE_USER_RIGHTS_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.FFJ2('Changes were successfully saved'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.UPDATE_USER_RIGHTS_FAILED: {
      const notification: CLT.NotificationProps = {
        text: t.RQA1('Saving the data failed. Please retry'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'warning',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX_FULFILLED:
    case TypeKeys.CHANGE_ADDONS_FULFILLED:
    case TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION_FULFILLED:
    case TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.T29V('Change order successful'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO_FAILED: {
      const notification: CLT.NotificationProps = {
        text: t.RQA1('Saving the data failed. Please retry'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'warning',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.CTBU('Billing account change completed'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.CLOSE_NOTIFICATION: {
      if (!state) {
        throw new Error('notifications state should never be empty when closing a notification');
      }
      const { notificationKey } = action;
      return {
        ...state,
        items: state.items ? state.items.filter(notification => notification.id !== notificationKey) : [],
      };
    }

    case TypeKeys.START_NOTIFICATION: {
      return stateWithNotification({
        text: action.notificationMsg,
        hasCloseButton: true,
        id: Date.now().toString(),
        type: action.notificationType ?? 'success',
      });
    }

    case TypeKeys.UPDATE_USER_DETAILS_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.PS8D('Data update was successful.'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      if (action.isCookieConsentUpdated) {
        return state;
      }
      return stateWithNotification(notification);
    }

    case TypeKeys.CHANGE_PASSWORD_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.SDUV('Password changed successfully'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.SUSPEND_SUBSCRIPTION_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.VOR4('Your subscription has now been temporarily deactivated.'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.RESUME_SUBSCRIPTION_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.FUTC('Subscription successfully activated.'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.TERMINATE_SUBSCRIPTION_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.OMP6(
          'Notice of termination has been received. You will receive a separate termination confirmation by email.'
        ),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.RESEND_EMAIL_VERIFICATION_LINK_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.EL9W('Link sent.'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.CHANGE_SIM_CARD_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: getChangeSimMessage(action.simType, action?.isDelivery),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.REVIEW_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: action.approved
          ? t.XKKL('Order confirmation succeeded!')
          : t.YKJA('Rejection of device agreement succeeded.'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.INITIATE_EMPLOYEE_ONBOARDING_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.BFV5('Invitation sent successfully.'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.INITIATE_EMPLOYEE_ONBOARDING_RESEND_FAILED: {
      const notification: CLT.NotificationProps = {
        text: t.RZIU('Invitation sending failed'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'error',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.POST_EPP_REDEEM_TERMINATE_FULFILLED: {
      let notificationContent = '';
      if (
        action.request.requestType === EppRedeemTerminateRequestType.EMPLOYEE_REDEEM ||
        action.request.requestType === EppRedeemTerminateRequestType.COMPANY_REDEEM ||
        action.request.requestType === EppRedeemTerminateRequestType.EMPLOYEE_REDEEM_BY_ADMIN
      ) {
        notificationContent = t.KNS9('Redemption request sent successfully');
      } else if (action.request.requestType === EppRedeemTerminateRequestType.TERMINATE) {
        notificationContent = t.G4ZD('Your cancellation request was successfully sent');
      } else if (
        action.request.requestType === EppRedeemTerminateRequestType.DESTROYED ||
        action.request.requestType === EppRedeemTerminateRequestType.MISSING
      ) {
        notificationContent = t.DUFL('Your ‘device-destroyed’ request was sent');
      }
      const notification: CLT.NotificationProps = {
        text: notificationContent,
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.POST_EPP_MAINTENANCE_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.FAZM('Your device service request was submitted successfully'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.FINALIZE_PAYMENT_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.HA3D('Invoice paid successfully.'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      if (!state) {
        return {
          items: [notification],
        };
      } else {
        return {
          ...state,
          items: [...(state.items || []), notification],
        };
      }
    }

    case TypeKeys.FINALIZE_PAYMENT_FAILED: {
      const notification: CLT.NotificationProps = {
        text: t.NHMG('Payment of the invoice was interrupted, you were not charged.'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'error',
      };
      if (!state) {
        return {
          items: [notification],
        };
      } else {
        return {
          ...state,
          items: [...(state.items || []), notification],
        };
      }
    }

    case TypeKeys.ADD_RING_ADMIN_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.P2BH('Addition of Ring administrator right completed'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.ADD_RING_ADMIN_FAILED: {
      const notification: CLT.NotificationProps = {
        text: t.CAL8('Failed to add Ring administrator right'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'warning',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.REMOVE_RING_ADMIN_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.S7KU('Deletion of Ring administrator right completed'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.REMOVE_RING_ADMIN_FAILED: {
      const notification: CLT.NotificationProps = {
        text: t.UG9B('Failed to delete Ring administrator right'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'warning',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.CHANGE_OFFER_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.YZ8Q('Offer change was successful'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.CHANGE_OFFER_FAILED: {
      const notification: CLT.NotificationProps = {
        text: t.D205('Offer change failed'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'error',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.ADD_DNS_RECORD_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.YPAM('New record was created'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.ADD_DNS_RECORD_FAILED: {
      const notification: CLT.NotificationProps = {
        text: t.PODN('DNS record add failed!'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'error',
      };
      return stateWithNotification(notification);
    }
    case TypeKeys.UPDATE_DNS_RECORD_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.VFZ2('Changes successfully saved'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.UPDATE_DNS_RECORD_FAILED: {
      const notification: CLT.NotificationProps = {
        text: t.HDQI('DNS record update failed!'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'error',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.DELETE_DNS_RECORD_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.I62X('Record deleted'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.DELETE_DNS_RECORD_FAILED: {
      const notification: CLT.NotificationProps = {
        text: t.XUDO('DNS record delete failed!'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'error',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.UPDATE_ACCOUNT_SETTINGS_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.VLSP('Data update was successful.'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.UPDATE_ACCOUNT_SETTINGS_FAILED: {
      const notification: CLT.NotificationProps = {
        text: t.L7BV('We could not update your changes. Please try again later.'),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'error',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.COPY_VIRTUAL_CATALOG_FULFILLED: {
      if (action.accountsFailedToCopyTo?.length) {
        return state;
      }
      const notification: CLT.NotificationProps = {
        text: t.I9RX(`Device lists copied. Complete and publish the lists on the companies' own device list page.`),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.REPLACE_VIRTUAL_CATALOGS_FULFILLED: {
      const notification: CLT.NotificationProps = {
        text: t.FGVX(`Product selection copied succesfully.`),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'success',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.REPLACE_VIRTUAL_CATALOGS_FAILED: {
      const notification: CLT.NotificationProps = {
        text: t.QJG7(`Product selection copy failed.`),
        hasCloseButton: true,
        id: Date.now().toString(),
        type: 'error',
      };
      return stateWithNotification(notification);
    }

    case TypeKeys.LOG_OUT: {
      return null;
    }

    default:
      return state;
  }
}
