import { InvoiceList } from '../../../../../components/InvoiceList/InvoiceList.js';
import {
  InvoiceSystemError,
  Invoices,
  InvoicesSceneTab,
  getInvoiceListCategory,
  getInvoicesForCategory,
} from '../../../../../components/Invoices/Invoices.js';
import { OPEN_INVOICES_HASH, PAID_INVOICES_HASH, t } from '../../../../../common/i18n/index.js';
import { SupportCaseNotification } from '../../../../../components/SupportCaseNotification/SupportCaseNotification.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import type { ClickableText } from '../../../../../components/CompositeList/index.js';
import type { InvoicesLoaderData } from 'common/loaders.js';
import type { NavigateFunction } from 'react-router-dom';

const getEmptyResultText = (category: string, navigate: NavigateFunction): ClickableText | undefined => {
  if (category === 'open') {
    return {
      onClick: () => navigate(`${paths.INVOICES}#${PAID_INVOICES_HASH}`),
      text: t.YFT1('Go to paid invoices'),
    };
  } else if (category === 'paid') {
    return {
      onClick: () => navigate(`${paths.INVOICES}#${OPEN_INVOICES_HASH}`),
      text: t.FTZT('Go to open invoices'),
    };
  }
  return undefined;
};

export const InvoicesPath = () => {
  const { invoices, billingAccounts, supportCases } = useLoaderData() as InvoicesLoaderData;
  const navigate = useNavigate();
  const { hash } = useLocation();

  const category = getInvoiceListCategory(hash);
  const extraEmptySearchResultLink = getEmptyResultText(category, navigate);

  return (
    <InvoiceSystemError>
      <Invoices tab={InvoicesSceneTab.INVOICES}>
        <SupportCaseNotification openSupportCases={supportCases?.searchResults?.map(r => r.result)} />
        <InvoiceList
          category={category}
          extraEmptySearchResultLink={extraEmptySearchResultLink}
          invoices={getInvoicesForCategory(category, invoices?.searchResults?.map(r => r.result) || [])}
          billingAccounts={billingAccounts?.searchResults?.map(r => r.result)}
          total={invoices?.total}
        />
      </Invoices>
    </InvoiceSystemError>
  );
};
