import * as CL from '@design-system/component-library';
import { DeviceChangeOption } from '../../../common/enums.js';
import { submitPunchoutOrder } from '../../../selfservice/actions/punchoutActions.js';
import { t } from '../../../common/i18n/index.js';
import { useDispatch, useSelector } from 'react-redux';
import type { DeliveryAddress, OnlineModelCategory, PunchoutOrderItem } from '../../../generated/api/models.js';
import type { DeviceChangeRequest } from '../../../common/types/device.js';
import type { ShoppingCartItemForCheckout } from '../../../common/types/checkout.js';
import type { State } from '../../../selfservice/common/store.js';

const setDeviceChangeRequest = (
  punchoutOrderItems: PunchoutOrderItem[],
  deviceChangeRequest: DeviceChangeRequest
): PunchoutOrderItem[] => {
  punchoutOrderItems.forEach(punchoutOrderItem => {
    if (deviceChangeRequest.deviceChangeOption === DeviceChangeOption.REDEEM) {
      punchoutOrderItem.deviceChange = {
        changeType: DeviceChangeOption.REDEEM,
        replacedSubscriptionId: deviceChangeRequest.subscriptionDisplayId!,
        employeeRedeemPrice: {
          oneTimeCharge: deviceChangeRequest.deviceChangeRedeemPrice,
          monthlyRecurringCharge: 0,
          payments: 0,
        },
      };
    } else if (deviceChangeRequest.deviceChangeOption === DeviceChangeOption.RETURN) {
      if (punchoutOrderItem.commercialProductCode === deviceChangeRequest.replacementDeviceCommercialProductCode) {
        punchoutOrderItem.deviceChange = {
          changeType: DeviceChangeOption.RETURN,
          replacedSubscriptionId: deviceChangeRequest.replacedSubscriptionId!,
        };
      }
    }
  });
  return punchoutOrderItems;
};

const toOrderItems = (
  cartItems: ShoppingCartItemForCheckout[],
  deviceChangeRequest: DeviceChangeRequest | undefined
): PunchoutOrderItem[] => {
  const punchoutOrderItems = cartItems.map(cartItem => ({
    commercialProductCode: cartItem.commercialProductCodes[0],
    offerCode: cartItem.offerCode,
    commercialProductName: cartItem.productName,
    category: cartItem.category as OnlineModelCategory,
    orderItemQuantity: cartItem.quantity,
    price: {
      oneTimeCharge: cartItem.productPrice.onetime?.price || 0,
      monthlyRecurringCharge: cartItem.productPrice.periodic?.price || 0,
      payments: cartItem.productPrice.periodic?.payments || 0,
    },
    employeePrice: {
      oneTimeCharge: cartItem.price.onetime?.price || 0,
      monthlyRecurringCharge: cartItem.price.periodic?.price || 0,
      payments: cartItem.price.periodic?.payments || 0,
    },
    addOns: cartItem.selectedAddOns.map(addOn => ({
      addOnAssociationCode: addOn.addOnAssociationCode,
      addOnCode: addOn.addOnCode,
    })),
  }));

  return deviceChangeRequest ? setDeviceChangeRequest(punchoutOrderItems, deviceChangeRequest) : punchoutOrderItems;
};

type PunchoutButtonProps = {
  cartItems: ShoppingCartItemForCheckout[];
  catalogCode: string;
  personBillingAccountId?: string;
  className?: string;
  deliveryAddress?: DeliveryAddress;
  deviceChangeRequest?: DeviceChangeRequest;
};

export const PunchoutButton = ({
  cartItems,
  catalogCode,
  personBillingAccountId,
  className,
  deliveryAddress,
  deviceChangeRequest,
}: PunchoutButtonProps) => {
  const dispatch = useDispatch();
  const submitInProgress = useSelector((state: State) => state.selfservice?.punchoutOrders?.saving);

  return (
    <div className={`of-punchout-button ${className || ''}`}>
      <CL.Button
        className="of-confirm-button"
        loading={submitInProgress}
        onClick={() =>
          dispatch(
            submitPunchoutOrder({
              catalogCode,
              orderItems: toOrderItems(cartItems, deviceChangeRequest),
              personBillingAccountId,
              deliveryAddress,
            })
          )
        }
        size="l"
      >
        {t.UGH3('Proceed to confirmation')}
      </CL.Button>
    </div>
  );
};
