import * as CL from '@design-system/component-library';
import { FOUR_G_AND_FIVE_G, ICON_SIM } from '../SubscriptionCard/SubscriptionCardConstants.js';
import { Impression } from '../Impression/Impression.js';
import { SubscriptionCard } from '../SubscriptionCard/SubscriptionCard.js';
import { getTurboNappiSubscriptions } from './turbonappiSubscriptionCardData.js';
import { getViewItemListAnalyticsEvent } from './turbonappiAnalyticsUtils.js';
import { pushToDataLayer } from '../../common/analytics.js';
import { t, technologyMsg } from '../../common/i18n/index.js';
import type { SubscriptionCardProps } from '../SubscriptionCard/SubscriptionCard.js';

import './TurbonappiCards.scss';

export const getFeatures = () => [
  {
    icon: ICON_SIM,
    text: FOUR_G_AND_FIVE_G,
    title: t.F5BJ(technologyMsg),
  },
];

// We have to use function to return the translation, with a variable translation switching won't work
export const getButtonText = () => t.GIIE('Order here');

export const TurbonappiCards = () => {
  const subscriptions: SubscriptionCardProps[] = getTurboNappiSubscriptions();
  return (
    <Impression
      className="ds-padding-bottom--6 ds-padding-top--1"
      onImpression={() => pushToDataLayer(getViewItemListAnalyticsEvent(subscriptions))}
    >
      <h2 className="of-turbonappi-cards__header">{t.VS3P('Subscribe to Turbonappi easily')}</h2>
      <CL.CardContainer sameHeight={false} cols={3}>
        {subscriptions.map(s => (
          <SubscriptionCard
            buttonText={getButtonText()}
            features={getFeatures()}
            recommended={s.recommended}
            key={s.turbonappiOffer}
            name={s.name}
            mainHeader={s.mainHeader}
            description={s.description}
            turbonappiPrice={s.turbonappiPrice}
            priceUnit={s.priceUnit}
            turbonappiPriceAdditionalInfo={s.turbonappiPriceAdditionalInfo}
            turbonappiOffer={s.turbonappiOffer}
            turbonappiFullName={s.turbonappiFullName}
          />
        ))}
      </CL.CardContainer>
    </Impression>
  );
};
