import * as CL from '@design-system/component-library';
import { ContactInfo } from './ContactInfo.js';
import { GridColumn } from './GridColumn.js';
import { InfoFieldWithTooltip } from './InfoFieldWithTooltip.js';
import { emailMsg, t } from '../../../common/i18n/index.js';
import type { Contact } from '../../../generated/api/models.js';

export interface DeliveryMethodEmailProps {
  receiverType?: string;
  selectedContact?: Contact;
  billReceiverName?: string;
  additionalBillReceiverEmails?: string[];
  highlight?: {
    contactInfo?: boolean;
    additionalReceivers?: boolean[];
  };
}

interface AdditionalBillReceiversProps {
  additionalBillReceiverEmails?: string[];
  highlights?: boolean[];
}

const AdditionalBillReceivers = ({ additionalBillReceiverEmails, highlights }: AdditionalBillReceiversProps) => (
  <>
    {additionalBillReceiverEmails?.map((item, index) => (
      <GridColumn
        className="'of-billing-account-details form-column view-field ds-padding-top--3  ds-padding-bottom--1'"
        key={`additionalemail${index + 1}`}
      >
        <InfoFieldWithTooltip
          key={`additionalemail${index + 1}`}
          label={`${t.XVHQ(emailMsg)} ${index + 1}`}
          value={item}
          name={`additionalemail${index + 1}`}
          highlight={highlights?.[index]}
        />
      </GridColumn>
    ))}
  </>
);

export const EmailDeliveryMethodDetails = (props: DeliveryMethodEmailProps) => {
  const { receiverType, selectedContact, billReceiverName, additionalBillReceiverEmails, highlight } = props;
  return (
    <>
      <CL.GridRow>
        <GridColumn className="of-billing-account-details form-column view-field">
          <InfoFieldWithTooltip
            label={receiverType ?? ''}
            value={<ContactInfo selectedContact={selectedContact} billReceiverName={billReceiverName} />}
            name="deliveryContact"
            highlight={highlight?.contactInfo}
          />
        </GridColumn>
      </CL.GridRow>
      <CL.GridRow>
        <GridColumn className="of-billing-account-details form-column view-field ds-padding-top--3  ds-padding-bottom--1">
          <strong>{t.UZ3K('Other receiver')}</strong>
        </GridColumn>
      </CL.GridRow>
      <CL.GridRow>
        <AdditionalBillReceivers
          additionalBillReceiverEmails={additionalBillReceiverEmails}
          highlights={highlight?.additionalReceivers}
        />
      </CL.GridRow>
    </>
  );
};
