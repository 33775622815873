import { SubscriptionPbxDetails, SubscriptionType } from '../../generated/api/models.js';
import { getRingServiceLevelsFromOnlineModel } from '../../components/AttachRing/attachRingDataConverter.js';
import { isMobileVoiceAddOn } from '../../components/SubscriptionDetails/addOnDependencyUtils.js';
import type { AddOnVisibility, OnlineModel, Subscription } from '../../generated/api/models.js';
import type { AddedAddon } from '../types/addon.js';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { ChangeOfferAddons } from '../../components/OrderSubscriptionSelection/subscriptionChangeConfirmationUtils.js';
import type { CompanyInfoState } from '../types/states.js';
import type { ConfiguredCommercialProduct } from '../types/commercialProduct.js';
import type { ServiceLevelAddon } from '../../components/AttachRing/ServiceLevelAndAddonsInterfaces.js';

export const getOpeningFeeAddons = (
  inputAddons: ServiceLevelAddon[],
  dependencies?: DependencyRecord[],
  associations?: AssociationRecord[]
) => {
  const RING_OPENING_FEES_TITLE = 'Elisa Ring avausmaksut';
  const DEPENDENCY_TYPE_INCLUSION = 'Inclusion';
  const DEPENDENCY_TYPE_MANDATORY = 'Mandatory';

  const pbxServiceLeveladdonCodes = inputAddons.map(addOn => {
    return addOn.addonCode || undefined;
  });

  const pbxServiceLevelDependencies = dependencies?.filter(dep => {
    return (
      pbxServiceLeveladdonCodes?.includes(dep.add_on_target__r?.guid__c) &&
      (dep.type__c === DEPENDENCY_TYPE_INCLUSION || dep.type__c === DEPENDENCY_TYPE_MANDATORY)
    );
  });

  const openingFeeAssociationIds = pbxServiceLevelDependencies?.map(dep => dep.add_on_target__r?.id);

  const openingFeeAddOns = associations?.filter(assoc => {
    return (
      openingFeeAssociationIds?.includes(assoc.cspmb__add_on_price_item__c) &&
      assoc.cspmb__add_on_price_item__r.cspmb__one_off_charge__c
    );
  });

  const subscriptionOpeningFeeAddons = associations?.filter(assoc => {
    return assoc.cspmb__group__c === RING_OPENING_FEES_TITLE;
  });

  return openingFeeAddOns && subscriptionOpeningFeeAddons && openingFeeAddOns.concat(subscriptionOpeningFeeAddons);
};

export const getPbxServiceLevelsAndAddons = (
  ringServicesModel?: OnlineModel,
  companyInfo?: CompanyInfoState | null
) => {
  const ringSolution = companyInfo?.pbxSolutions?.find(
    (pbxSolution: Subscription | undefined) => pbxSolution?.subscriptionType === SubscriptionType.MOBILE_PBX
  );

  if (!ringSolution) {
    return {
      pbxServiceLevelAddons: [],
      pbxServiceLevels: [],
    };
  }

  const mobilePbxServiceLevelProduct = ringSolution.details?.mobilePbx?.mobilePbxServiceLevelProduct;
  const productCodes = mobilePbxServiceLevelProduct?.userServiceLevelCommercialProductCodes || [];
  const productAddons = mobilePbxServiceLevelProduct?.addOnServiceLevelSelections || [];

  const serviceLevelsAndAddons = getRingServiceLevelsFromOnlineModel(productCodes, productAddons, ringServicesModel);
  return {
    pbxServiceLevels: serviceLevelsAndAddons.pbxServiceLevels,
    pbxServiceLevelAddons: serviceLevelsAndAddons.pbxServiceLevelAddons,
  };
};

export const isRingSubscription = (subscription: Subscription, pbxSolutions?: Subscription[]): boolean => {
  const isRingEnabledForCompany = pbxSolutions?.some(sub => sub.subscriptionType === SubscriptionType.MOBILE_PBX);
  const isRingEnabledForSub =
    subscription.details?.mobile?.pbxConfiguration?.pbxConfigurationDetails?.pbxType ===
    SubscriptionPbxDetails.PbxTypeEnum.RING;

  return Boolean(isRingEnabledForCompany && isRingEnabledForSub);
};

export const shouldIncludeAddon = (addonCode: string, isRingSub: boolean) =>
  !isRingSub || !isMobileVoiceAddOn(addonCode);

export const filterAddonsFromRingSubs = (addedAddons?: AddedAddon[]): AddedAddon[] =>
  addedAddons?.filter(addon => shouldIncludeAddon(addon.addOnCode, true)) || [];

export const filterVisibilitiesFromRingSubs = (addonVisibilities: AddOnVisibility[]) =>
  addonVisibilities.filter(visibility => shouldIncludeAddon(visibility.addOnGuidCode, true));

export const filterChangeOfferAddonsFromRingSubs = ({
  addOnsToAdd,
  addOnsToUpdate,
  addOnsToRemove,
}: ChangeOfferAddons): ChangeOfferAddons => ({
  addOnsToAdd: filterAddonsFromRingSubs(addOnsToAdd),
  addOnsToUpdate: filterAddonsFromRingSubs(addOnsToUpdate),
  addOnsToRemove,
});

export const filterAddOnsBasedOnRing = (
  configuredCommercialProducts: ConfiguredCommercialProduct[],
  isRingSelected: boolean
) =>
  configuredCommercialProducts.map(ccp => ({
    ...ccp,
    addedAddOns: isRingSelected ? filterAddonsFromRingSubs(ccp.addedAddOns) : ccp.addedAddOns,
  }));
