import { Catalog } from '../../generated/api/models.js';
import { CatalogConfigurationForm, mapFormValuesToBeProcessed } from './CatalogConfigurationForm.js';
import { ContractPeriod } from '../../common/utils/catalogUtils.js';
import { billingAccountDefaultValues } from '../BillingAccount/BillingAccountFieldset.js';
import { deliveryMethodDefaultValues } from '../BillingAccount/DeliveryMethodFieldset.js';
import { isEppSolutionActive } from '../../common/utils/stateUtils.js';
import { newDeviceListMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { userHasEmptyOrSMEPriceGroup } from '../../common/utils/employeeUtils.js';
import type { AuthenticatedUserState, CompanyInfoState } from '../../common/types/states.js';
import type { CatalogProduct } from '../../common/utils/catalogUtils.js';
import type { FormValues, ReadyFormValues } from './CatalogConfigurationForm.js';
import type { VirtualCatalog } from '../../generated/api/models.js';

// TODO: 31.1.2023 - When the new virtualCatalog feature was implemented, all of the old code should've been cleaned up.
//  Unfortunately that was not done, so now we have CatalogConfiguration and VirtualCatalogConfiguration. Didn't dig
//  deep, but it seems the former is used when creating new catalog and the latter when editing an existing catalog.
//  This CatalogConfiguration also uses old redux state `catalogs`, instead of the new `virtualCatalogs`.

export interface CatalogConfigurationProps {
  companyInfo?: CompanyInfoState;
  user?: AuthenticatedUserState | null;
}

export interface CatalogParams {
  catalog: Partial<Catalog>;
  products?: Record<string, Array<CatalogProduct>>;
  virtualCatalog?: VirtualCatalog;
}

export const CatalogConfiguration = (props: CatalogConfigurationProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const companyInfo = props.companyInfo;
  const isEppActive = isEppSolutionActive(props.companyInfo);
  // TODO remove this in next ticket where ba is integrated to catalog-configuration
  //   form. Note, that the billing receiver contact potentially needs to be created.
  //   Check DeliveryMethodFieldset and handle the case when billReceiverId is
  //   CREATE_NEW_CONTACT_OPTION_VALUE.
  const isBaHidden = true;

  const isSmeOrEmptyPriceGroup = userHasEmptyOrSMEPriceGroup(props.user?.segmentPricingGroup);

  const onSubmit = (values: FormValues) => {
    const catalog: ReadyFormValues = mapFormValuesToBeProcessed(values, isBaHidden);
    navigate(paths.COMPANY_INFO_CATALOG_LIST_EDIT, { state: { catalog } });
  };

  const isAllowedForLEPricingGroup = isEppActive ? companyInfo?.eppSolution?.allowDevicesWithRecurringCharges : false;
  const isAllowedForSmeOrEmptyPricingGroup = !isEppActive || companyInfo?.eppSolution?.allowDevicesWithRecurringCharges;
  const isRecurringChargeAllowed = isSmeOrEmptyPriceGroup
    ? isAllowedForSmeOrEmptyPricingGroup
    : isAllowedForLEPricingGroup;

  return (
    <div>
      <h3>{t.MPFC(newDeviceListMsg)}</h3>
      <p>
        {t.B0GP(
          `You can use the device list to set up your company's device portfolio. There can be more than one device list, and you can select one or more devices for a list. Also note, only products of one catalog list can be ordered together, so create list accordingly. You can also list the price limit that the company pays for each device per month. When the OmaLasku service is activated, the employee can also choose a device above the price limit, in which case they will pay the difference themselves.`
        )}
      </p>

      <CatalogConfigurationForm
        isEppActive={isEppActive}
        isRecurringChargeAllowed={Boolean(isRecurringChargeAllowed)}
        isOnetimeChargeAllowed={Boolean(!isEppActive || companyInfo?.eppSolution?.allowDevicesWithOneTimeCharges)}
        onSubmit={onSubmit}
        defaultValues={
          location.state?.defaultValues || {
            name: '',
            productType: isEppActive ? Catalog.ProductTypeEnum.EPP_RECURRING : Catalog.ProductTypeEnum.RECURRING,
            contractPeriod: `${ContractPeriod.MONTHS_24}`,
            damageInsurance: [],
            corporateShare: '',
            billingAccount: {
              ...billingAccountDefaultValues(props.companyInfo?.companyName || ''),
              ...deliveryMethodDefaultValues(),
            },
          }
        }
        isBaHidden={isBaHidden}
      />
    </div>
  );
};
