import { InputComponent } from '../components/InputComponent.js';
import { fieldCantBeEmptyMsg, t } from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../components/InputComponent.js';

export const TextInput = ({
  name = 'text',
  label,
  placeholder,
  required = true,
  disabled = false,
  validate,
  ...rest
}: OptionalInputComponentProps) => {
  const requiredValidate = (value: string) => (required && !value ? t.VPVR(fieldCantBeEmptyMsg) : undefined);
  const combinedValidate = (value: string) => requiredValidate(value) || (validate && validate(value));

  return (
    <InputComponent
      validate={combinedValidate}
      name={name}
      type="text"
      label={label}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      {...rest}
    />
  );
};
