import { ContactListFields } from '../../common/enums.js';
import {
  DEFAULT_ITEMS_PER_PAGE,
  type DefaultListSearchParams,
  ListPagination,
  ListSearch,
  Table,
} from '../Table/index.js';
import { Highlight } from '../Highlight/Highlight.js';
import { HighlightFormattedPhoneNumber } from '../Highlight/HighlightFormattedPhoneNumber.js';
import { Link, generatePath } from 'react-router-dom';
import {
  companyMsg,
  emailMsg,
  eoeInUseColumnMsg,
  eoeInUseFalseMsg,
  eoeInUseTrueMsg,
  nameMsg,
  phoneNumberMsg,
  t,
} from '../../common/i18n/index.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useMultiAccountRowClick } from '../../common/hooks/useMultiAccountRowClick.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type * as CL from '@design-system/component-library';
import type { ContactSearchResponse } from '../../generated/api/models.js';
import type { MultiAccountRowClickFunction } from '../../common/hooks/useMultiAccountRowClick.js';
import type { RealAuthenticatedUserState } from '../../common/types/states.js';

export interface ContactsListProps {
  contacts: ContactSearchResponse[];
  total: number;
  searchInput?: string;
  authenticatedUser?: RealAuthenticatedUserState;
}

const contactsListColumns: CL.Column[] = [
  {
    key: ContactListFields.CONTACT_NAME,
    label: t.VGFI(nameMsg),
    sortable: true,
    sorted: 'asc',
  },
  {
    key: ContactListFields.CONTACT_PHONE_NUMBER,
    label: t.AGIJ(phoneNumberMsg),
    sortable: true,
  },
  {
    key: ContactListFields.CONTACT_EMAIL,
    label: t.OKYY(emailMsg),
    sortable: true,
  },
  {
    key: ContactListFields.CONTACT_EOE_IN_USE,
    label: t.S0FN(eoeInUseColumnMsg),
    sortable: true,
  },
  {
    key: ContactListFields.COMPANY,
    label: t.KJTS(companyMsg),
    sortable: false,
  },
];

const getContactRows = (
  contacts: ContactSearchResponse[],
  onRowClick: MultiAccountRowClickFunction,
  searchInput?: string,
  authenticatedUser?: RealAuthenticatedUserState
) => {
  const searchTerms = searchInput?.split(' ') || [];
  return contacts?.map(contact => {
    const name = `${contact.result.firstName || ''} ${contact.result.lastName || ''}`;
    return {
      [ContactListFields.CONTACT_NAME]: (
        <Link
          to={generatePath(paths.COMPANY_INFO_CONTACT, { contactMasterId: contact.result.contactMasterId || '' })}
          onClick={e => {
            e.preventDefault();
            onRowClick(
              generatePath(paths.COMPANY_INFO_CONTACT, { contactMasterId: contact.result.contactMasterId || '' }),
              getCompanyName(authenticatedUser, contact.result.accountMasterId),
              contact.result.accountMasterId
            );
          }}
        >
          <Highlight text={name} highlights={searchTerms} />
        </Link>
      ),
      [ContactListFields.CONTACT_PHONE_NUMBER]: (
        <HighlightFormattedPhoneNumber
          formattedPhoneNumber={formatPhoneNumber(contact.result.phoneNumber || '', true)}
          search={searchInput}
        />
      ),
      [ContactListFields.CONTACT_EMAIL]: <Highlight text={contact.result.email || ''} highlights={searchTerms} />,
      [ContactListFields.CONTACT_EOE_IN_USE]: contact.result.eoeInUse
        ? t.V34H(eoeInUseTrueMsg)
        : t.MUF5(eoeInUseFalseMsg),
      [ContactListFields.COMPANY]: getCompanyName(authenticatedUser, contact.result.accountMasterId),
    };
  });
};

export const ContactsList = ({ contacts, total, searchInput, authenticatedUser }: ContactsListProps): JSX.Element => {
  const onRowClick = useMultiAccountRowClick();
  const searchParams = useSearchParams<DefaultListSearchParams>();
  const rows = getContactRows(contacts, onRowClick, searchInput, authenticatedUser);

  return (
    <div className="of-contacts-list">
      <ListSearch onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)} />
      <Table columns={contactsListColumns} noItemsText={t.UMEE('No users')} rows={rows || []} />
      <ListPagination totalItems={total} />
    </div>
  );
};
