import * as CL from '@design-system/component-library';
import * as React from 'react';
import { Catalog, EppSolutionResponse } from '../../generated/api/models.js';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { Loading } from '../Loading/Loading.js';
import { t } from '../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import type { AccountHeader, SecondaryAccount } from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';

import './CopyCatalogMultiBusinessDialog.scss';

interface AccountData {
  accountMasterId: string;
  accountName: string;
}

interface CopyCatalogMultiBusinessDialogProps {
  accounts: AccountData[];
  errors?: CommonError[];
  isSubmitInProgress?: boolean;
  onCloseDialog: () => void;
  onSubmit: (selectedAccountMasterIds: string[]) => void;
  selectedAccounts?: string[];
  catalogProductType?: Catalog.ProductTypeEnum;
  secondaryAccounts?: AccountHeader[];
  secondaryAccountsInfo?: SecondaryAccount[];
}

const getNotAllowedAccountsToCopyCatalogTo = (secondaryAccountInfo: SecondaryAccount[]): string[] => {
  return secondaryAccountInfo
    .filter(acc => acc.eppSolution?.eppSolutionStatus !== EppSolutionResponse.EppSolutionStatusEnum.ACTIVE)
    .map(acc => acc.accountMasterId);
};

const getNotAllowedAccounts = (productType?: Catalog.ProductTypeEnum, secondaryAccountInfo?: SecondaryAccount[]) => {
  if (productType === Catalog.ProductTypeEnum.EPP_RECURRING) {
    const notAllowedAccounts = getNotAllowedAccountsToCopyCatalogTo(secondaryAccountInfo || []);
    return {
      notAllowedAccounts,
      notAllowedToCopyMessage: notAllowedAccounts.length
        ? t.SIEL(
            'Customers listed as unavailable do not have existing EPP contracts. Please contact your designated Elisa contact person for creating a new EPP contract.'
          )
        : undefined,
    };
  }
  return {
    notAllowedAccounts: undefined,
    notAllowedToCopyMessage: undefined,
  };
};

const SelectionBoxes = ({
  availableAccounts,
  selectedAccounts,
  notAllowedAccounts,
  onChange,
}: {
  availableAccounts: AccountData[];
  selectedAccounts: Set<string>;
  notAllowedAccounts?: string[];
  onChange: (accountMasterId: string, checked: boolean) => void;
}): JSX.Element => {
  return (
    <>
      {availableAccounts.map((account, index) => {
        return (
          <CL.Checkbox
            id={account.accountMasterId || `${index}`}
            key={`${account.accountMasterId}_${selectedAccounts.has(account.accountMasterId)}`}
            disabled={(notAllowedAccounts?.length && notAllowedAccounts.includes(account.accountMasterId)) || false}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              onChange(account.accountMasterId, e.currentTarget.checked);
            }}
            checked={selectedAccounts.has(account.accountMasterId)}
          >
            {account.accountName || ''}
          </CL.Checkbox>
        );
      })}
    </>
  );
};

const MultipleAccountsDialogContent = ({
  availableAccounts,
  onChange,
  selectedAccounts,
  notAllowedAccounts,
  notAllowedToCopyMessage,
  validationError,
}: {
  availableAccounts: AccountData[];
  onChange: (accountMasterId: string, checked: boolean) => void;
  selectedAccounts: Set<string>;
  notAllowedAccounts?: string[];
  notAllowedToCopyMessage?: string;
  validationError?: string;
}) => {
  return (
    <>
      <div>
        {t.AMXZ(
          'Select the companies to which you want to copy the device list. Be sure to finalize and publish the device list separately.'
        )}
      </div>
      <SelectionBoxes {...{ availableAccounts, selectedAccounts, notAllowedAccounts, onChange }} />
      {validationError && <div className="ea-text--error">{validationError}</div>}
      <div className="ea-disclaimertext">{notAllowedToCopyMessage}</div>
      <div className="ea-disclaimertext">
        {t.UQMA(
          `You can find the companies' own device lists on their own device list page. Copying does not overwrite existing lists, so delete old lists if necessary.`
        )}
      </div>
    </>
  );
};

const getInitiallySelectedAccounts = (accounts: AccountData[]): Set<string> => {
  if (accounts.length === 1) {
    return new Set([accounts[0].accountMasterId]);
  }
  return new Set();
};

export const CopyCatalogMultiBusinessDialog = (props: CopyCatalogMultiBusinessDialogProps) => {
  const {
    onCloseDialog,
    onSubmit,
    accounts,
    errors,
    selectedAccounts: preselectedAccount,
    isSubmitInProgress,
    catalogProductType,
    secondaryAccountsInfo,
  } = props;
  const [selectedAccounts, setSelectedAccounts] = useState(() => getInitiallySelectedAccounts(accounts));
  const [validationError, setValidationError] = useState<string>();

  useEffect(() => {
    if (validationError && selectedAccounts.size) {
      setValidationError(undefined);
    }
  }, [selectedAccounts.size, validationError]);

  useEffect(() => {
    if (preselectedAccount) {
      setSelectedAccounts(new Set(preselectedAccount));
    }
  }, [preselectedAccount?.length]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  const handleCopy = () => {
    if (selectedAccounts.size) {
      onSubmit(Array.from(selectedAccounts));
    } else {
      setValidationError(t.WPOQ('Please select a company to proceed'));
    }
  };

  const { notAllowedAccounts, notAllowedToCopyMessage } = getNotAllowedAccounts(
    catalogProductType,
    secondaryAccountsInfo
  );

  const showLoading = (productType?: Catalog.ProductTypeEnum, secondaryAccountInfo?: SecondaryAccount[]) =>
    productType === Catalog.ProductTypeEnum.EPP_RECURRING && !secondaryAccountInfo;

  return (
    <DialogWrapper
      buttons={[
        {
          color: 'link',
          text: t.B2V1('Cancel'),
          onClick: onCloseDialog,
        },
        {
          text: t.UEOZ('Copy'),
          onClick: handleCopy,
          disabled: !accounts.length || (accounts.length > 1 && showLoading(catalogProductType, secondaryAccountsInfo)),
          isLoading: isSubmitInProgress,
        },
      ]}
      closeable
      header={t.H2ZI('Create a copy of the device list')}
      onCloseDialog={onCloseDialog}
    >
      <div className="of-copy-catalog-multi-business-dialog__content">
        {accounts.length > 1 &&
          (showLoading(catalogProductType, secondaryAccountsInfo) ? (
            <Loading />
          ) : (
            <MultipleAccountsDialogContent
              {...{
                availableAccounts: accounts,
                onChange: (accountMasterId, checked) => {
                  if (checked) {
                    setSelectedAccounts(prev => new Set(prev).add(accountMasterId));
                  } else {
                    setSelectedAccounts(prev => {
                      const next = new Set(prev);
                      next.delete(accountMasterId);
                      return next;
                    });
                  }
                },
                selectedAccounts,
                notAllowedAccounts,
                notAllowedToCopyMessage,
                validationError,
              }}
            />
          ))}
        {accounts.length === 1 && t.KC4V('Are you sure you want to proceed?')}
        {!accounts.length && <Loading />}
        {errors &&
          errors.map((error, i) => (
            <CL.Disclaimer className="ds-text--m" icon={<CL.Icon icon="warning" />} key={i} text={error.message} />
          ))}
      </div>
    </DialogWrapper>
  );
};
