import * as CL from '@design-system/component-library';
import { Await, Link, useAsyncValue, useLoaderData, useNavigate } from 'react-router-dom';
import { BillingAccount } from '../../common/react-hook-form/fields/BillingAccount.js';
import { BillingAccountFieldset } from '../BillingAccount/BillingAccountFieldset.js';
import { CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE } from '../../common/utils/billingAccountUtils.js';
import { CREATE_NEW_CONTACT_OPTION_VALUE } from '../../common/formik/Fields/index.js';
import { Catalog } from '../../generated/api/catalog.js';
import { Checkbox } from '../../common/react-hook-form/components/Checkbox.js';
import { ContractPeriod } from '../../common/utils/catalogUtils.js';
import { DeliveryMethodFieldset } from '../BillingAccount/DeliveryMethodFieldset.js';
import { DialogType } from '../../common/enums.js';
import { EppCategory } from '../../generated/api/eppCategory.js';
import { FormProvider, useForm } from 'react-hook-form';
import { Name, TextInput } from '../../common/react-hook-form/fields/index.js';
import { SelectRadio } from '../../common/react-hook-form/components/SelectRadio.js';
import { Suspense } from 'react';
import { TextAreaComponent } from '../../common/react-hook-form/components/TextAreaComponent.js';
import { WizardActions } from '../WizardActions/index.js';
import {
  agreementPeriodMsg,
  alvZeroMsg,
  cancelMsg,
  catalogCorporateMessageExampleLinkMsg,
  catalogCorporateMessageExampleMsg,
  catalogCorporateMessageHelpMsg,
  catalogCorporateMessageInstructionMsg,
  catalogCorporateMessageLabelMsg,
  catalogCorporateMessageSelectorMsg,
  companysShareOfMonthlyFeeMsg,
  connectDeviceToRegistrationProgramMsg,
  damageInsuranceCoverMsg,
  damageInsuranceMsg,
  deviceEnrollmentProgramAliasHelpMsg,
  deviceEnrollmentProgramAliasInfoMsg,
  deviceEnrollmentProgramMsg,
  deviceToEnrollmentProgramMsg,
  deviceToEnrollmentReadMoreMsg,
  elisaDevicesServiceMsg,
  enrollmentAliasPlaceHolderMsg,
  fieldCantBeEmptyMsg,
  monthMsg,
  nameOfCatalogMsg,
  t,
} from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { showDialog } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { BillChannel } from '../../generated/api/billChannel.js';
import type { BillingAccountCreateFormValues } from '../CreateBillingAccount/CreateBillingAccount.js';
import type { BillingAccountsResponse } from '../../generated/api/billingAccountsResponse.js';
import type { ContactsResponse } from '../../generated/api/contactsResponse.js';
import type { ReceiverType } from '../../common/utils/billingAccountUtils.js';
import type { SelectRadioItem } from '../../common/react-hook-form/components/SelectRadio.js';

import './CatalogConfigurationForm.scss';

interface Contact {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export type FormValues = {
  name: string;
  contractPeriod: string;
  productType: 'EPP_RECURRING' | 'RECURRING' | 'ONETIME';
  corporateShare: string;
  damageInsurance: Array<Exclude<EppCategory, 'PLACEHOLDER'>>;
  isCorporateMessageAdded?: boolean;
  corporateMessage?: string;
  enrollmentProgramConsent?: boolean;
  enrollmentProgramAlias?: string;
  billingAccountId?: string;
  billingAccount?: BillingAccountCreateFormValues;
  contact?: Contact;
  billReceiverSelection?: ReceiverType;
};

export interface ReadyFormValues {
  name: string;
  contractPeriod?: number;
  productType: 'EPP_RECURRING' | 'RECURRING' | 'ONETIME';
  corporateShare?: number;
  damageInsurance: EppCategory[];
  corporateMessage?: string;
  enrollmentProgramConsent: boolean;
  enrollmentProgramAlias: string;
  billingAccountId?: string;
  billingAccount?: BillingAccountCreateFormValues;
  contact?: Contact;
}

export interface CatalogConfigurationFormProps {
  defaultValues?: FormValues;
  isEppActive: boolean;
  isRecurringChargeAllowed: boolean;
  isOnetimeChargeAllowed: boolean;
  onSubmit: (values: FormValues) => void;
  // TODO remove this in next ticket where ba is integrated to catalog-configuration
  //   form. Note, that the billing receiver contact potentially needs to be created.
  //   Check DeliveryMethodFieldset and handle the case when billReceiverId is
  //   CREATE_NEW_CONTACT_OPTION_VALUE.
  isBaHidden?: boolean;
}

const contractPeriodList = [
  { label: `${ContractPeriod.MONTHS_12} kk`, value: `${ContractPeriod.MONTHS_12}` },
  { label: `${ContractPeriod.MONTHS_24} kk`, value: `${ContractPeriod.MONTHS_24}` },
  { label: `${ContractPeriod.MONTHS_36} kk`, value: `${ContractPeriod.MONTHS_36}` },
];

const BAFormWithDeferredData = () => {
  const { billChannels } = useLoaderData() as { billChannels: BillChannel[] };
  const deferredContacts: ContactsResponse = useAsyncValue() as ContactsResponse;
  return (
    <>
      <BillingAccountFieldset billChannels={billChannels} contacts={deferredContacts} />
      <DeliveryMethodFieldset billChannels={billChannels} contacts={deferredContacts.contacts ?? []} />
    </>
  );
};

interface CatalogConfigurationLoaderData {
  billingAccounts: BillingAccountsResponse;
  billChannels: BillChannel[];
  contacts: Promise<ContactsResponse>;
}

const calculateContractPeriod = (fv: FormValues) => {
  return fv.productType !== Catalog.ProductTypeEnum.ONETIME ? parseInt(fv.contractPeriod, 10) : undefined;
};

const calculateCorporateShare = (fv: FormValues) => {
  const number = parseFloat(fv.corporateShare);
  return fv.productType === Catalog.ProductTypeEnum.EPP_RECURRING && !isNaN(number)
    ? Math.round(number * 100)
    : undefined;
};

export const mapFormValuesToBeProcessed = (values: FormValues, isBaHidden?: boolean) => {
  return {
    name: values.name,
    contractPeriod: calculateContractPeriod(values),
    productType: values.productType,
    corporateShare: calculateCorporateShare(values),
    damageInsurance: values.damageInsurance,
    corporateMessage: values.isCorporateMessageAdded ? values.corporateMessage : undefined,
    enrollmentProgramConsent: values.enrollmentProgramConsent || false,
    enrollmentProgramAlias: values.enrollmentProgramConsent ? values.enrollmentProgramAlias || '' : '',
    ...(!isBaHidden
      ? {
          billingAccountId: values.billingAccountId,
          billingAccount:
            values.billingAccountId === CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE ? values.billingAccount : undefined,
          contact:
            values.billingAccount?.billingContactId === CREATE_NEW_CONTACT_OPTION_VALUE ? values.contact : undefined,
        }
      : {}),
  };
};

export const CatalogConfigurationForm = ({
  defaultValues,
  isEppActive,
  isRecurringChargeAllowed,
  isOnetimeChargeAllowed,
  onSubmit,
  isBaHidden,
}: CatalogConfigurationFormProps) => {
  const loaderData = useLoaderData() as CatalogConfigurationLoaderData;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const methods = useForm<FormValues>({ mode: 'all', defaultValues });
  const runtimeValues = methods.watch();

  const productTypeList = [
    isEppActive && { label: t.TRE5(elisaDevicesServiceMsg), value: Catalog.ProductTypeEnum.EPP_RECURRING },
    isRecurringChargeAllowed && {
      label: t.OF9U('Devices subject to monthly fee'),
      value: Catalog.ProductTypeEnum.RECURRING,
    },
    isOnetimeChargeAllowed && {
      label: t.XED2('Device subject to one-time fee'),
      value: Catalog.ProductTypeEnum.ONETIME,
    },
  ].filter(i => i);

  return (
    <FormProvider {...methods}>
      <form className="catalog-configuration" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <Name
          label={t.M6TP(nameOfCatalogMsg)}
          placeholder={t.M6TP(nameOfCatalogMsg)}
          maxLength={65}
          hint={t.WYPE('Max 65 characters')}
          className="width-50"
        />

        <SelectRadio name="productType" label={t.LQ5X('Product types')} items={productTypeList as SelectRadioItem[]} />
        <SelectRadio
          name="contractPeriod"
          label={t.ULI0(agreementPeriodMsg)}
          items={contractPeriodList}
          disabled={runtimeValues.productType === Catalog.ProductTypeEnum.ONETIME}
        />

        {isEppActive && (
          <div className="flex">
            <TextInput
              label={`${t.H8Q4(companysShareOfMonthlyFeeMsg)} (${t.S8TX(alvZeroMsg)})`}
              min={0}
              name="corporateShare"
              required={false}
              className="width-50"
              type="number"
              placeholder=""
              disabled={runtimeValues.productType !== Catalog.ProductTypeEnum.EPP_RECURRING}
            >
              <span className="extension">€/{t.XXVX(monthMsg)}</span>
            </TextInput>
          </div>
        )}

        <h4>{t.QSXP(damageInsuranceMsg)}</h4>
        <p>{t.FLLT(damageInsuranceCoverMsg)}</p>

        <Checkbox
          name="damageInsurance"
          label="Business Pro"
          value={EppCategory.BUSINESS_PRO}
          disabled={runtimeValues.productType !== Catalog.ProductTypeEnum.EPP_RECURRING}
        />
        <Checkbox
          name="damageInsurance"
          label="Business Premium"
          value={EppCategory.BUSINESS_PREMIUM}
          disabled={runtimeValues.productType !== Catalog.ProductTypeEnum.EPP_RECURRING}
        />

        <h4>{t.C8DA(catalogCorporateMessageLabelMsg)}</h4>
        <p>{t.Q57Q(catalogCorporateMessageInstructionMsg)}</p>
        <CL.Button
          className="ds-margin-bottom--3"
          color="light"
          onClick={() => {
            dispatch(
              showDialog({
                body: <>{t.Y0HD(catalogCorporateMessageExampleMsg)}</>,
                header: '',
                type: DialogType.GENERIC_INFO_DIALOG,
              })
            );
          }}
          size="s"
        >
          {t.ZU62(catalogCorporateMessageExampleLinkMsg)}
        </CL.Button>

        <Checkbox
          className="ds-margin-bottom--3"
          name="isCorporateMessageAdded"
          label={t.FFQZ(catalogCorporateMessageSelectorMsg)}
        />

        {runtimeValues.isCorporateMessageAdded && (
          <TextAreaComponent
            rows={5}
            maxLength={500}
            disabled={!runtimeValues.isCorporateMessageAdded}
            hint={t.HQTV(catalogCorporateMessageHelpMsg)}
            name="corporateMessage"
            validate={(value: string, values: FormValues) => {
              if (!values.isCorporateMessageAdded) {
                return;
              }
              return value ? undefined : t.VPVR(fieldCantBeEmptyMsg);
            }}
          />
        )}

        <h4>{t.S14B(deviceEnrollmentProgramMsg)}</h4>
        <p>
          {t.ZIH3(deviceToEnrollmentProgramMsg)}{' '}
          <Link to={paths.DEVICE_ENROLLMENT}>{t.X2UI(deviceToEnrollmentReadMoreMsg)}</Link>
        </p>
        <p>{t.CSOC(deviceEnrollmentProgramAliasHelpMsg)}</p>
        <Checkbox
          className="ds-margin-bottom--3"
          name="enrollmentProgramConsent"
          label={t.MNEQ(connectDeviceToRegistrationProgramMsg)}
        />

        {runtimeValues.enrollmentProgramConsent && (
          <TextInput
            label={t.IBVP(enrollmentAliasPlaceHolderMsg)}
            name="enrollmentProgramAlias"
            required={false}
            placeholder={t.IBVP(enrollmentAliasPlaceHolderMsg)}
            tooltip={t.BJRZ(deviceEnrollmentProgramAliasInfoMsg)}
          />
        )}

        <hr className="ds-margin-bottom--4" />

        {!isBaHidden && (
          <>
            <h4>{t.IXED('Device catalog billing information')}</h4>
            <p>
              {t.ND39(
                'Select the default billing account for Elisa service terminal devices and device lists. In the future, you can use the default billing account or, if you wish, create a device list or subscription-specific billing account.'
              )}
            </p>
            <div className="ds-margin-bottom--3">
              <BillingAccount
                billingAccounts={loaderData.billingAccounts.searchResults || []}
                name="billingAccountId"
              />
            </div>
            {runtimeValues.billingAccountId === CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE && (
              <Suspense
                fallback={
                  <div className="ds-text-align--center">
                    <CL.LoadingSpinner size="l" className="ds-margin-bottom--4" />
                  </div>
                }
              >
                <Await resolve={loaderData.contacts}>
                  <BAFormWithDeferredData />
                </Await>
              </Suspense>
            )}
          </>
        )}

        <WizardActions
          backButtonText={t.B2V1(cancelMsg).toUpperCase()}
          onBackClick={() => navigate(paths.COMPANY_INFO_CATALOGS)}
          onForwardClick={() => {}}
        />
      </form>
    </FormProvider>
  );
};
