import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { CreateNewContact } from '../../../../../components/CreateNewContact/CreateNewContact.js';
import { loadContacts } from '../../../../../selfservice/actions/index.js';
import { useDispatch, useStore } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type { State } from '../../../../../selfservice/common/store.js';

export const CompanyInfoNewContactPath = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const config = useStore<State>().getState().config;

  useEffect(() => {
    dispatch(loadContacts());
  }, [dispatch, pathname]);

  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.CONTACTS} isSingular={true}>
      <CreateNewContact config={config} />
    </CompanyInfoContainer>
  );
};
