import * as CL from '@design-system/component-library';
import { showMoreMsg, t } from '../../common/i18n/index.js';
import { useNavigate } from 'react-router-dom';

export interface MoreResultsProps {
  displayShowMoreButton?: boolean;
  onShowMoreClick?: () => void;
  isMultiBiz: boolean;
  seeAllLinkHref: string;
  setSearchFieldVisible: (visible: boolean) => void;
}

export const MoreResults = ({
  displayShowMoreButton,
  onShowMoreClick,
  isMultiBiz,
  seeAllLinkHref,
  setSearchFieldVisible,
}: MoreResultsProps) => {
  const navigate = useNavigate();
  const onSeeAllClick = () => {
    setSearchFieldVisible(false);
    navigate(seeAllLinkHref);
  };

  return (
    <div
      className={`ds-padding-left--3 ds-padding-right--3 ds-display--flex ds-justify-content--${
        displayShowMoreButton ? 'space-between' : 'flex-end'
      }`}
    >
      {displayShowMoreButton && onShowMoreClick && (
        <CL.Button color="link" onClick={onShowMoreClick} size="s">
          <>
            {t.H6OA(showMoreMsg)}
            <CL.Icon icon="arrow-down" type="filled" />
          </>
        </CL.Button>
      )}
      {!isMultiBiz && (
        <CL.Button color="link" onClick={onSeeAllClick} size="s">
          <>
            {t.G090('See all')}
            <CL.Icon icon="arrow-right-alt" type="filled" />
          </>
        </CL.Button>
      )}
    </div>
  );
};
