import * as CL from '@design-system/component-library';
import { DEFAULT_ITEMS_PER_PAGE, ListPagination, ListSearch, Table } from '../Table/index.js';
import { EmptyOrError, getGenericSystemError } from '../EmptyOrError/index.js';
import { EppComputerReport } from './items/EppComputerReport.js';
import { EppPhoneReport } from './items/EppPhoneReport.js';
import { EppRedeemReport, EppServiceAndRecyclementReport } from './items/EppReport.js';
import { Grid } from '../Grid/Grid.js';
import { GuidanceMessage } from './common/GuidanceMessage.js';
import { OneTimeAndRecurringDeviceReport } from './items/OneTimeAndRecurringDeviceReport.js';
import { ReportAction } from './ReportAction.js';
import { SelectOneList } from '../SelectOneList/index.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { sortArrayByProperty } from '../../common/utils/arrayUtils.js';
import { t } from '../../common/i18n/index.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type { ConfigState } from '../../common/types/states.js';
import type { DefaultListSearchParams } from '../Table/index.js';
import type { ReportItemResponse } from '../../generated/api/reportItemResponse.js';
import type { ReportStatusChange } from '../../generated/api/reportStatusChange.js';
import type { ReportType } from '../../generated/api/reportType.js';

import './Reports.scss';

export interface ReportsProps {
  isEppSolutionActive: boolean;
  config: ConfigState;
  newReporting?: boolean;
  reportStatuses: ReportItemResponse[];
  loading: boolean;
  error: boolean;
  latestReportStatusChange?: ReportStatusChange;
}

export const filterRows = (rows: CL.KeyRow[], searchParams?: DefaultListSearchParams): CL.KeyRow[] => {
  // Apply search
  const filteredRows = searchParams?.search
    ? rows.filter(row =>
        JSON.stringify({ name: row.name, content: row.content })
          .toLowerCase()
          .includes(searchParams.search?.toLowerCase() || '')
      )
    : rows;

  // Apply sort
  const sortedArray =
    searchParams?.sort && searchParams?.order
      ? sortArrayByProperty(filteredRows, searchParams.sort, searchParams.order)
      : filteredRows;

  const offset = searchParams?.offset ? Number.parseInt(searchParams.offset, 10) : 0;
  const items = searchParams?.limit ? Number.parseInt(searchParams.limit, 10) : 30;

  // Apply offset/items
  return sortedArray.slice(offset, offset + items);
};

export const reportListContent = (
  isEppSolutionActive: boolean,
  searchParams: DefaultListSearchParams,
  reportStatuses: ReportItemResponse[],
  latestReportStatusChange?: ReportStatusChange
): CL.KeyRow[] => {
  const defaultReports = [
    {
      name: t.ML02('Purchased devices'),
      content: t.PN10(`Company's mobile devices and accessories`),
      onlineReportType: 'devices_and_accessories_report',
    },
  ];

  const eppReports = [
    {
      name: t.KZ5M('Elisa Device as Service'),
      content: t.RE0N(`Elisa Device as Service's mobile phones and computers`),
      onlineReportType: 'epp_device_mobile_and_pc_report',
    },
    {
      name: t.KQ9B('Life cycle of Elisa Device as Service'),
      content: t.XIOZ(
        `Elisa Device as Service's maintenance works, equipment replacements, redeemed devices and refund transactions`
      ),
      onlineReportType: 'epp_device_lifecycle_report',
    },
  ];

  const rows = (isEppSolutionActive ? [...defaultReports, ...eppReports] : defaultReports).map(report => {
    const currentReport = reportStatuses.find(rs => rs.onlineReportType === report.onlineReportType);
    return {
      ...report,
      action: (
        <ReportAction
          initialReportStatus={currentReport}
          onlineReportType={report.onlineReportType as ReportType}
          latestReportStatusChange={latestReportStatusChange}
        />
      ),
    };
  });

  return filterRows(rows, searchParams);
};

export const Reports = ({
  isEppSolutionActive,
  config,
  newReporting,
  reportStatuses,
  loading,
  error,
  latestReportStatusChange,
}: ReportsProps) => {
  const searchParams = useSearchParams<DefaultListSearchParams>();

  if (newReporting) {
    const columns = [
      { key: 'name', label: t.MWDL('Report name'), sortable: true },
      { key: 'content', label: t.WW24('Contents'), sortable: true },
      { key: 'action', label: t.ASXB('Action'), sortable: true },
    ];

    if (error) {
      return <EmptyOrError {...getGenericSystemError()} />;
    }

    const rows = reportListContent(isEppSolutionActive, searchParams, reportStatuses, latestReportStatusChange);

    return (
      <Grid>
        <div className="of-report-list">
          <CL.NotificationBanner
            text={t.JFDK(
              `The report can take several minutes to complete. You can close your browser and come back later to see if the report is ready. Can't find your order on the report? Some of the order details can still be found in the old OmaElisa.`
            )}
            icon={<CL.Icon icon="information" size="m" type="regular" color="blue-600" />}
          />
          <ListSearch
            className="ds-margin-top--4"
            onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
          />
          <Table columns={columns} isLoading={loading} noItemsText={t.ITD0('No reports')} rows={rows} />
          <ListPagination totalItems={rows.length} />
        </div>
      </Grid>
    );
  }

  const options = [{ element: <OneTimeAndRecurringDeviceReport /> }];
  if (isEppSolutionActive) {
    options.push(
      { element: <EppPhoneReport /> },
      { element: <EppComputerReport /> },
      { element: <EppServiceAndRecyclementReport /> },
      { element: <EppRedeemReport /> }
    );
  }

  return (
    <div className="of-report-list">
      <h2 className="of-report-list__header">{t.DFFI('Reporting')}</h2>
      <GuidanceMessage config={config} />
      <SelectOneList maxItemsAsideTablet={2} maxItemsAsideLaptop={3} options={options} />
    </div>
  );
};
