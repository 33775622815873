import * as CL from '@design-system/component-library';
import {
  BillingAccountSourceSystem,
  CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE,
  getFilteredBillingAccounts,
} from '../../common/utils/billingAccountUtils.js';
import { DialogWrapper, buttonsForSubmitAndBack } from '../DialogWrapper/index.js';
import { confirmMsg, t } from '../../common/i18n/index.js';
import { getBillingAccountListItems } from '../AddOrSelectBillingAccountsV2/AddOrSelectBillingAccounts.js';
import { loadBillingAccounts } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import type { BillingAccountsState } from '../../common/types/states.js';

export interface ChangeBillingAccountDialogAttrs {
  billingAccounts?: BillingAccountsState;
  billingAccountId: string;
  changeRequestInProgress?: boolean;
  headerText: string;
  description: string | JSX.Element;
  detailedView?: boolean;
  searchable?: boolean;
  onBeginCreateBillingAccount: () => void;
  onCloseDialog: () => void;
  onSubmit: (bid: string, bdid: string) => void;
}

export type ChangeBillingAccountDialogProps = ChangeBillingAccountDialogAttrs;

export const ChangeBillingAccountDialog = (props: ChangeBillingAccountDialogProps) => {
  const {
    billingAccountId,
    changeRequestInProgress,
    description,
    headerText,
    onBeginCreateBillingAccount,
    onCloseDialog,
  } = props;

  const [selectedBillingAccountId, setSelectedBillingAccountId] = useState(billingAccountId);
  const [searchText, setSearchText] = useState('');
  const billingAccounts = useMemo(
    () => getFilteredBillingAccounts(props.billingAccounts),
    [props.billingAccounts?.items?.length, props.billingAccounts?.searchResults?.length] /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadBillingAccounts(undefined, undefined, undefined, true, BillingAccountSourceSystem.SFDC));
  }, [dispatch]);

  const onChangeBillingAccountId = (evt: HTMLLIElement) => {
    const selected = evt.dataset.value || selectedBillingAccountId;
    if (selected) {
      if (selected === CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE) {
        onBeginCreateBillingAccount();
      } else {
        setSelectedBillingAccountId(selected);
      }
    }
    setSearchText('');
  };

  const onSubmit = () => {
    const billingAccount = billingAccounts?.find(ba => ba.billingAccountId === selectedBillingAccountId);
    if (billingAccount) {
      props.onSubmit(selectedBillingAccountId, billingAccount.billingAccountDisplayId!);
    }
  };

  const formattedBillingAccounts = getBillingAccountListItems(
    true,
    props.billingAccounts?.searchResults,
    searchText
  ).map(x => {
    return { ...x, id: x.value };
  });

  return (
    <DialogWrapper
      buttons={buttonsForSubmitAndBack(
        onCloseDialog,
        onSubmit,
        undefined,
        undefined,
        t.QVYK(confirmMsg),
        undefined,
        changeRequestInProgress
      )}
      closeable
      header={headerText}
      onCloseDialog={onCloseDialog}
    >
      {typeof description === 'string' ? <p>{description}</p> : description}
      <CL.Combobox
        onValueSelect={item => {
          // Pressing esc calls onValueSelect without an item, so we have to
          // have this check here...
          if (item) {
            onChangeBillingAccountId(item);
          }
        }}
        items={formattedBillingAccounts}
        selectedValue={selectedBillingAccountId}
        onValueChange={value => {
          if (value) {
            setSearchText(value);
          }
        }}
        selectedItemFormat="label"
        i18n_combobox_buttonAriaLabel={t.HVS2('Selected billing account')}
      />
    </DialogWrapper>
  );
};
