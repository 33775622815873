import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { ModelType, SubscriptionCategory } from '../../../../../common/enums.js';
import { OrderSim } from '../../../../../components/OrderSim/OrderSim.js';
import { SimType } from '../../../../../generated/api/models.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { findSubscription, getSubscriptionStatus } from '../../../../../common/utils/subscriptionUtils.js';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  loadBillChannels,
  loadOnlineModelByModelType,
  loadSubscriptionAddOnRules,
  loadSubscriptionAddOnRulesMobilePbx,
  loadSubscriptions,
} from '../../../../../selfservice/actions/index.js';
import { omaElisaForCompaniesMsg, orderSimMsg, t, voiceSubscriptionsMsg } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useAuth } from '../../../AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const MobileSubOrderSimPath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { subscriptionId } = useParams<SubscriptionId>();
  const voiceSubs = useSelector((state: State) => state.selfservice?.subscriptions?.voice || undefined, deepEqual);
  const { authenticatedUser } = useAuth();
  const companyInfo = useSelector((state: State) => state?.selfservice?.companyInfo, deepEqual);
  const subscriptionActions = useSelector((state: State) => state?.selfservice?.subscriptionActions, deepEqual);
  const serviceFees = useSelector((state: State) => state?.selfservice?.subscriptions?.voice?.serviceFees, deepEqual);

  useEffect(() => {
    dispatch(loadSubscriptions({ category: SubscriptionCategory.VOICE, displayId: subscriptionId }));
    // Take to use when Ring Association UI is ready
    dispatch(loadOnlineModelByModelType(ModelType.Ring));
    dispatch(loadSubscriptionAddOnRulesMobilePbx());
    dispatch(loadSubscriptionAddOnRules());
    dispatch(loadBillChannels());
    dispatch(loadSubscriptionAddOnRules());
  }, [dispatch, pathname, subscriptionId]);

  if (!subscriptionId) {
    return null;
  }
  const subscription = findSubscription(subscriptionId, voiceSubs);
  return (
    <OrderSim
      breadCrumbs={
        <BreadCrumbsWithTitle
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.AR0B(voiceSubscriptionsMsg), path: paths.PS_MOBILE_SUBSCRIPTIONS },
            {
              name: subscriptionId,
              path: generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId }),
            },
            { name: t.CVON(orderSimMsg) },
          ]}
        />
      }
      category={SubscriptionCategory.VOICE}
      companyInfo={companyInfo}
      disallowBillableSimChanges={false} // NOE is used by admins, always allow billable SIM changes.
      onClickSubscription={() => {
        navigate(generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId: subscription?.subscriptionDisplayId }));
      }}
      pendingSubscriptionChanges={getSubscriptionStatus(subscription, subscriptionActions?.items).pendingActions}
      simTypeInitialSelection={SimType.ESIM}
      subscription={subscription!}
      user={authenticatedUser}
      simChangeServiceFee={
        serviceFees?.find(serviceFee => serviceFee.subscriptionDisplayId === subscription?.subscriptionDisplayId)?.price
      }
    />
  );
};
