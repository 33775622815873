import * as CL from '@design-system/component-library';
import { CommonErrorType } from '../../common/enums.js';
import { MobiilivarmenneForm } from './MobiilivarmenneForm.js';
import { Spinner } from '../../public/site/siteUtils.js';
import { SystemError } from '../SystemError/SystemError.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import type * as CLT from '@design-system/component-library';
import type { Locale } from '../../common/i18n/index.js';
import type { State } from '../../selfservice/common/store.js';

type Language = 'en' | 'fi' | 'sv';
// eslint-disable-next-line @typescript-eslint/naming-convention
const languages: Record<Locale, Language> = { 'en-GB': 'en', 'fi-FI': 'fi', 'sv-SE': 'sv' };

const getAuthenticationRedirectUri = () => {
  if (window) {
    const port =
      Boolean(window.location.port) && window.location.port !== '443' && window.location.port !== '80'
        ? `:${window.location.port}`
        : '';
    return encodeURIComponent(`${window.location.protocol}//${window.location.hostname}${port}/tunnistaudu/paluu`);
  }
  return undefined;
};

export const getExternalAuthenticationUrl = (
  baseUrl: string,
  clientId: string,
  acr: string,
  state: string,
  locale = 'fi',
  loginHint?: string
) => {
  const stateParam = state ? `&state=${state}` : '';
  const loginHintParam = loginHint ? `&login_hint=${loginHint}` : '';
  return `${baseUrl}/authz?scope=openid&ui_locales=${locale}&response_type=code&redirect_uri=${getAuthenticationRedirectUri()}\
&client_id=${clientId}${stateParam}&acr_values=${encodeURIComponent(acr)}${loginHintParam}`;
};

const getBanks = (configuredBankNames: string[]) => [
  { id: 'op', nameMatcher: /Osuuspankki/ },
  { id: 'nordea', nameMatcher: /Nordea/ },
  { id: 'danskebank', nameMatcher: /Danskebank/ },
  { id: 'handelsbanken', nameMatcher: /Handelsbanken/ },
  { id: 'alandsbanken', nameMatcher: /Ålandsbanken/ },
  { id: 's-pankki', nameMatcher: /S-Pankki/ },
  { id: 'aktia', nameMatcher: /Aktia/ },
  { id: 'pop-pankki', nameMatcher: /POP Pankki/ },
  { id: 'saastopankki', nameMatcher: /^Säästöpankki/ },
  { id: 'omasp', nameMatcher: /Oma Säästöpankki/ },
  {
    id: 'korhopankki',
    // With RP.Login component we can only use one 'korhopankki'.
    // So prefer 'Test SAML method' as it gives us more login/ssn options.
    // OIDC Korhopankki is used with cafes, Korhopankki in UAT
    nameMatcher:
      (configuredBankNames.includes('Test SAML method') && /Test SAML method/) ||
      (configuredBankNames.includes('OIDC Korhopankki') && /OIDC Korhopankki/) ||
      /Korhopankki/,
  },
];

const MOBIILIVARMENNE = 'Mobiilivarmenne';

const navigate = (url: string) => (window.location.href = url);

type ExternalAuthenticationProvidersV2Props = {
  locale: Locale;
  uriState: string;
  headerText?: string;
};

export const ExternalAuthenticationProviders = ({
  locale,
  uriState,
  headerText,
}: ExternalAuthenticationProvidersV2Props) => {
  const { externalAuthMethods } = useAuth();
  const authenticationConfig = useSelector((state: State) => state.config.externalAuthentication, deepEqual);
  const [showMobiilivarmenneForm, setShowMobiilivarmenneForm] = useState(false);

  if (externalAuthMethods === 'failed') {
    return <SystemError errors={[{ message: 'Failed GET external-authentication', type: CommonErrorType.SYSTEM }]} />;
  } else if (externalAuthMethods === 'loading') {
    return <Spinner />;
  }

  const configuredBankNames = externalAuthMethods.map(({ name }) => name);
  const banks = getBanks(configuredBankNames);

  const configuredBankIds = banks
    .filter(({ nameMatcher }) => configuredBankNames.find(name => nameMatcher.test(name)))
    .map(({ id }) => id);

  const mobileAcr = externalAuthMethods.find(({ name }) => name === MOBIILIVARMENNE);

  const configuredBanks = [
    [mobileAcr && mobileAcr.name.toLowerCase()].filter(Boolean),
    configuredBankIds,
  ] as CLT.BankArray;

  if (showMobiilivarmenneForm) {
    return (
      <MobiilivarmenneForm
        onSubmit={phoneNumber => {
          navigate(
            getExternalAuthenticationUrl(
              authenticationConfig.baseUrl,
              authenticationConfig.clientId,
              mobileAcr ? mobileAcr.authenticationContextClassReferences[0] : '',
              uriState,
              languages[locale],
              phoneNumber
            )
          );
        }}
        backToBankSelection={() => setShowMobiilivarmenneForm(false)}
        showHeader={!!headerText}
      />
    );
  }

  return (
    <>
      {headerText && (
        <h3 className="ds-margin-top--0 ds-margin-left--0 ds-margin-right--0 ds-margin-bottom-5">{headerText}</h3>
      )}
      <CL.BankSelectGroup
        className="ds-padding--0"
        banks={configuredBanks}
        onBankSelect={(bank: CLT.Bank) => {
          if (bank.name === MOBIILIVARMENNE) {
            setShowMobiilivarmenneForm(true);
          } else {
            const authMethod = externalAuthMethods.find(({ name }) =>
              banks.find(({ id }) => id === bank.id)?.nameMatcher.test(name)
            );
            navigate(
              getExternalAuthenticationUrl(
                authenticationConfig.baseUrl,
                authenticationConfig.clientId,
                authMethod ? authMethod.authenticationContextClassReferences[0] : '',
                uriState,
                languages[locale]
              )
            );
          }
        }}
      />
    </>
  );
};
