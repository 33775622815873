import * as CL from '@design-system/component-library';
import { DialogType } from '../../common/enums.js';
import { Loading } from '../../components/Loading/index.js';
import { createInterworksRedirectUrl, fetchBillChannels } from '../../../src/common/fetch.js';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import {
  licenseManagementItePortalMsg,
  licenseManagementRedirectFailedHeaderMsg,
  licenseManagementRedirectFailedMsg,
  licenseManagementRedirectInfoMsg,
  licenseManagementRedirectMsg,
  t,
} from '../../common/i18n/index.js';
import { showDialog } from '../../selfservice/actions/index.js';
import { useDispatch, useStore } from 'react-redux';
import { useState } from 'react';
import type { LicenseManagementUrlResponse } from '../../generated/api/licenseManagementUrlResponse.js';
import type { State } from '../../selfservice/common/store.js';

import './LicenseManagement.scss';

export const LicenseManagement = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { featureFlags } = useStore<State>().getState().config;

  const showInfoDialog = () =>
    dispatch(
      showDialog({
        body: <>{t.NMRJ(licenseManagementRedirectFailedMsg)}</>,
        header: t.DT3P(licenseManagementRedirectFailedHeaderMsg),
        type: DialogType.GENERIC_INFO_DIALOG,
      })
    );

  const isNoContractFoundError = (errStatus: number, message: string) =>
    errStatus === 403 && message === 'No contract for license management found';

  const handleRedirectUrlCreationError = async (noContractFoundError: boolean) => {
    if (noContractFoundError) {
      try {
        const billChannels = await fetchBillChannels();
        dispatch(
          showDialog({
            type: DialogType.NEW_INTERWORKS_ACCOUNT_DIALOG,
            billChannels: billChannels,
          })
        );
      } catch (_) {
        showInfoDialog();
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      showInfoDialog();
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const { url }: LicenseManagementUrlResponse = await (await createInterworksRedirectUrl()).json();
      setIsLoading(false);
      // Safari is blocking any call to window.open() which is made inside an async call.
      // As we want to open url in a new browser tab, window.location() cannot be used.
      // This is workaround that fixes the Safari issue and also works on other browsers.
      setTimeout(() => {
        window.open(url, '_blank', 'noopener,noreferrer');
      });
    } catch (err) {
      const errRes = await err.json();
      await handleRedirectUrlCreationError(
        isFeatureEnabled(featureFlags.createInterworksAccount) && isNoContractFoundError(err.status, errRes[0].message)
      );
    }
  };
  return (
    <div className="of-license-management">
      <div>
        <img
          alt=""
          width="80"
          src="https://static.elisa.com/v2/image/2tqybbhjs47b/4qLpyiICIke36t2rgAEMgW/Icon_solutions.svg"
        />
      </div>
      <h4>{t.L866(licenseManagementItePortalMsg)}</h4>
      <div className="of-license-management--description">{t.M0RI(licenseManagementRedirectInfoMsg)}</div>
      <div className="of-license-management--link">
        {isLoading ? (
          <Loading />
        ) : (
          <CL.Button color="light" onClick={onSubmit} disabled={isLoading}>
            {t.Z2W7(licenseManagementRedirectMsg)}
          </CL.Button>
        )}
      </div>
    </div>
  );
};
