import { allPricesVatZeroMsg, t } from '../../common/i18n/index.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getAddOnPrice } from './addOnDependencyUtils.js';
import type { AddOn } from '../../generated/api/addOn.js';

import './AddOnChangeConfirmationDialogContent.scss';

const AddedAddOns = ({ addedAddOns, isEmployee }: { addedAddOns: AddOn[]; isEmployee?: boolean }) => (
  <>
    {addedAddOns.length > 0 && <div className="ds-font-weight--bold ds-margin-top--3">{t.KB82('To be added')}</div>}
    {addedAddOns.map((added, i) => (
      <span key={`added-items-${i}`}>
        <div className="ds-display--flex ds-justify-content--space-between">
          <div className="ds-display--flex ds-align-items--flex-start">{added.addOnProductName}</div>
          <div className="ds-display--flex ds-align-items--flex-end">
            {!isEmployee && getAddOnPrice(added.price?.effectivePrice).text}
          </div>
        </div>
      </span>
    ))}
  </>
);

const RemovedAddOns = ({ removedAddOns, isEmployee }: { removedAddOns: AddOn[]; isEmployee?: boolean }) => (
  <>
    {removedAddOns.length > 0 && <div className="ds-font-weight--bold ds-margin-top--3">{t.R8IE('To be removed')}</div>}
    {removedAddOns.map((removed, i) => (
      <span key={`removed-items-${i}`}>
        <div className="ds-display--flex ds-justify-content--space-between">
          <div className="ds-display--flex ds-align-items--flex-start">{removed.addOnProductName}</div>
          <div className="ds-display--flex ds-align-items--flex-end">
            {!isEmployee && getAddOnPrice(removed.price?.effectivePrice).text}
          </div>
        </div>
      </span>
    ))}
  </>
);

const TotalChangeFee = ({ serviceFee, isEmployee }: { serviceFee?: number; isEmployee: boolean }) =>
  isEmployee ? null : (
    <>
      <div className="ds-display--flex ds-justify-content--space-between ds-margin-top--3">
        <div className="ds-display--flex ds-align-items--flex-start">{t.OKLP('Change fees in total')}</div>
        <div className="ds-display--flex ds-align-items--flex-end">{formatSum(serviceFee) ?? '-'}</div>
      </div>
      <div className="ds-font-size--small">{t.QAKO('Change fees will be added to the next invoice')}</div>
    </>
  );

const PaymentInfo = ({ updatedPrice, isEmployee }: { updatedPrice?: number; isEmployee: boolean }) =>
  isEmployee ? null : (
    <>
      <div className="ds-margin-top--2">
        {t.YZJ3('Monthly subscription fees after ordering ')} {formatSum(updatedPrice) ?? '-'}
      </div>
      <div className="ds-margin-top--2">{t.TEA3(allPricesVatZeroMsg)}</div>
    </>
  );

const RemovalInfo = ({ removedAddOns, isEmployee }: { removedAddOns: AddOn[]; isEmployee: boolean }) =>
  isEmployee ? null : (
    <>
      {removedAddOns.length > 0 && (
        <div className="ds-margin-vertical--3">
          {t.J3XJ(
            'You will receive a final invoice for the terminated service, if usage or monthly fees have not yet been invoiced.'
          )}
        </div>
      )}
    </>
  );

const ActivationInfo = () => (
  <div className="of-addon-change-confirmation-dialog__border-top ds-margin-vertical--2">
    <div className="ds-margin-top--2">
      {t.I6FA('Please note that it may take several minutes to activate the changes.')}
    </div>
  </div>
);

export const AddOnChangeConfirmationDialogContent = ({
  addedAddOns,
  removedAddOns,
  isEmployee,
  updatedPrice,
  serviceFee,
}: {
  addedAddOns: AddOn[];
  removedAddOns: AddOn[];
  isEmployee: boolean;
  updatedPrice?: number;
  serviceFee?: number;
}) => (
  <div className="of-addon-change-confirmation-dialog of-addon-change-confirmation-dialog__border-top ds-margin-top--2">
    <AddedAddOns addedAddOns={addedAddOns} isEmployee={isEmployee} />
    <RemovedAddOns removedAddOns={removedAddOns} isEmployee={isEmployee} />
    <TotalChangeFee serviceFee={serviceFee} isEmployee={isEmployee} />
    <PaymentInfo updatedPrice={updatedPrice} isEmployee={isEmployee} />
    <RemovalInfo removedAddOns={removedAddOns} isEmployee={isEmployee} />
    <ActivationInfo />
  </div>
);
