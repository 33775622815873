import * as CL from '@design-system/component-library';
import { Email, Name, PhoneNumber } from '../../../common/react-hook-form/fields/index.js';
import { GridColumn } from '../../BillingAccountDetails/components/GridColumn.js';
import { firstNameMsg, lastNameMsg, t } from '../../../common/i18n/index.js';

interface CreateContactProps {
  companyName?: string;
  fieldPrefix?: string;
  isEmailMandatory?: boolean;
  isPhoneNumberMandatory?: boolean;
}
export const CreateContact = ({
  companyName,
  fieldPrefix = '',
  isEmailMandatory = true,
  isPhoneNumberMandatory = true,
}: CreateContactProps) => (
  <>
    <CL.Grid>
      <CL.GridRow className="of-purpose-of-use__grid_row">
        <GridColumn className="of-purpose-of-use__grid_col">
          <Name
            name={`${fieldPrefix}firstName`}
            label={t.AIK7(firstNameMsg)}
            placeholder={t.AIK7(firstNameMsg)}
            required={true}
          />
        </GridColumn>
        <GridColumn className="of-purpose-of-use__grid_col">
          <Name
            name={`${fieldPrefix}lastName`}
            label={t.Y41S(lastNameMsg)}
            placeholder={t.Y41S(lastNameMsg)}
            required={true}
          />
        </GridColumn>
      </CL.GridRow>
      <CL.GridRow className="of-purpose-of-use__grid_row">
        <GridColumn className="of-purpose-of-use__grid_col">
          <PhoneNumber name={`${fieldPrefix}phoneNumber`} required={isPhoneNumberMandatory} />
        </GridColumn>
        <GridColumn className="of-purpose-of-use__grid_col">
          <Email name={`${fieldPrefix}email`} required={isEmailMandatory} />
        </GridColumn>
      </CL.GridRow>
    </CL.Grid>
    <p className="ds-padding-top--2">
      {t.W447('Selected company: ')} <b>{companyName}</b>
      {`. ${t.BQO3(
        'Please create a new contact under the company where the person is working. You can choose the right company from the upper right corner of the Corporate OmaElisa main page'
      )}`}
    </p>
  </>
);
