import * as React from 'react';
import * as ReactDOM from 'react-dom';

import './Printable.scss';

export class Printable {
  wrapped: React.ReactElement;
  printContainer: HTMLDivElement | undefined;

  constructor(wrappedComponent: React.ReactElement) {
    this.wrapped = wrappedComponent;
  }

  print() {
    this.unmount();
    this.mount();
  }

  readyToPrint = () => {
    window.print();
    this.unmount();
  };

  mount() {
    this.printContainer = document.createElement('div');
    this.printContainer.className = 'printable';
    document.body.appendChild(this.printContainer);

    const el = React.cloneElement(this.wrapped, { readyToPrint: this.readyToPrint });
    ReactDOM.render(el, this.printContainer);
  }

  unmount() {
    if (this.printContainer) {
      ReactDOM.unmountComponentAtNode(this.printContainer);
      document.body.removeChild(this.printContainer);
      this.printContainer = undefined;
    }
  }
}
