import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
  filterAccountKeyUsers,
  findFromPbxSolutionsByType,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { Grid } from '../../../../../components/Grid/Grid.js';
import { RingAdminList } from '../../../../../components/RingAdminList/RingAdminList.js';
import { SubscriptionType } from '../../../../../generated/api/models.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { getSubscriptionStatus } from '../../../../../common/utils/subscriptionUtils.js';
import { loadAccountKeyUsers, loadContacts } from '../../../../../selfservice/actions/index.js';
import { ringAdministratorsMsg, t } from '../../../../../common/i18n/index.js';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type { AccountKeyUser } from '../../../../../generated/api/models.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const CompanyInfoRingAdminsPath = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const config = useStore<State>().getState().config;
  const accountKeyUsers = useSelector((state: State) => state.selfservice?.accountKeyUsers, deepEqual);
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  const contacts = useSelector((state: State) => state.selfservice?.contacts, deepEqual);
  const ringSubscriptions = companyInfo ? findFromPbxSolutionsByType(companyInfo, SubscriptionType.MOBILE_PBX) : [];
  const subscriptionActions = useSelector((state: State) => state.selfservice?.pendingSubscriptionActions, deepEqual);
  const newAccountKeyUsers: AccountKeyUser[] | undefined = filterAccountKeyUsers(
    contacts ? contacts : undefined,
    accountKeyUsers ? accountKeyUsers : undefined
  );

  useEffect(() => {
    dispatch(loadAccountKeyUsers());
    dispatch(loadContacts());
  }, [dispatch, pathname]);

  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.USERS_NOE}>
      {ringSubscriptions && ringSubscriptions.length > 0 && (
        <Grid>
          <div className="ds-padding-top--4">
            <h3 className="ea-h3 ea-h3--thick">{t.PFI5(ringAdministratorsMsg)}</h3>
          </div>
          <RingAdminList
            config={config}
            errors={contacts ? contacts.errors : undefined}
            items={contacts ? contacts.items : undefined}
            accountKeyUsers={newAccountKeyUsers}
            ringPbxSolutions={ringSubscriptions}
            pendingSubscriptionChanges={
              getSubscriptionStatus(ringSubscriptions[0], subscriptionActions?.items).pendingActions
            }
            sort={accountKeyUsers ? accountKeyUsers.sort : undefined}
          />
        </Grid>
      )}
    </CompanyInfoContainer>
  );
};
