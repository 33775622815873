import { PunchoutProducts } from '../../../../components/Punchout/PunchoutCategoryList.js';
import { loadOnlineModels } from '../../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import type { Category } from '../../../../common/types/category.js';

interface PunchoutStoreProductsProps {
  category: Category;
}

export const PunchoutStoreProducts = ({ category }: PunchoutStoreProductsProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadOnlineModels(category.code, true));
  }, [category, dispatch]);
  return <PunchoutProducts category={category} />;
};
