import * as CL from '@design-system/component-library';
import { ContactType } from '../../../generated/api/contactType.js';
import { Controller, useFormContext } from 'react-hook-form';
import { DialogType } from '../../enums.js';
import { createNewMsg, fieldCantBeEmptyMsg, t } from '../../i18n/index.js';
import { deepEqual } from '../../utils/objectUtils.js';
import { showDialog } from '../../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { Contact } from '../../../generated/api/contact.js';
import type { GenericErrorDuplicateContact } from '../../../generated/api/genericErrorDuplicateContact.js';
import type { SearchItemProps } from '@design-system/component-library';
import type { State } from '../../../selfservice/common/store.js';

export interface ContactDropdownProps {
  className?: string;
  contacts: Contact[];
  labelText?: string;
  name: string;
  canAddNewContacts: boolean;
  tooltipText?: string;
  labelClass?: string;
  onChangeContact?: (selectedContactId: string) => void;
  placeHolderText?: string;
  // In some cases we do not want to open a dialog to create a new contact (checkout)
  createNewDialog?: boolean;
  disabled?: boolean;
  id?: string;
}

export const CREATE_NEW_CONTACT_OPTION_VALUE = 'ADD_NEW';

const getContactOption = (contact: Contact): SearchItemProps => {
  const email = contact.person?.email || '';
  const phoneNumber = contact.person?.phoneNumber || '';

  return {
    label: `${contact.person?.firstName || ''} ${contact.person?.lastName || ''} ${phoneNumber || ''} ${email}`,
    value: contact.contactId || '',
    html: (
      <>
        <div>{`${contact.person?.firstName || ''} ${contact.person?.lastName || ''}`}</div>
        <div className="ds-font-size--small">
          {email && phoneNumber ? `${email} | ${phoneNumber}` : email || phoneNumber}
        </div>
      </>
    ),
  };
};

const ContactDropdownTooltip = (props: { tooltipText?: string }) => {
  return props.tooltipText ? (
    <span className="ds-padding-left--2 of-formik__label tooltip">
      <CL.Tooltip
        triggerElement={<CL.Icon color="brand-blue" icon="information" size="s" />}
        placement="top"
        i18n_tooltip_contentText={props.tooltipText}
        className="ds-padding-left-3 ds-margin-left--3"
      />
    </span>
  ) : null;
};

const getContactOptions = (contacts: Contact[], canAddNewContacts?: boolean): Array<SearchItemProps> => {
  const createNewContactOption: SearchItemProps[] = canAddNewContacts
    ? [
        {
          label: t.GFN5(createNewMsg),
          value: CREATE_NEW_CONTACT_OPTION_VALUE,
          html: <div className="ds-color--blue-600">{t.GFN5(createNewMsg)}</div>,
        },
      ]
    : [];
  const contactOptions: SearchItemProps[] = contacts
    .filter(contact => contact.contactType === ContactType.PERSON)
    .filter(contact => contact.person?.firstName || contact.person?.lastName)
    .map(getContactOption);
  return contactOptions ? createNewContactOption.concat(contactOptions) : createNewContactOption;
};

export const ContactDropdown = ({
  className,
  contacts,
  labelText,
  name,
  canAddNewContacts,
  tooltipText,
  labelClass,
  onChangeContact,
  placeHolderText,
  createNewDialog = true,
  disabled = false,
  id,
}: ContactDropdownProps) => {
  const { control, clearErrors, setValue, setError, getValues } = useFormContext();
  const dispatch = useDispatch();

  // TODO: The whole duplicate contact handling needs to be refactored. This use of redux is currently required.
  const useDuplicateContact: GenericErrorDuplicateContact | undefined = useSelector(
    (state: State) => state.selfservice?.subscriptionActions?.useDuplicateContact,
    deepEqual
  );
  const contactCreated = useSelector(
    (state: State) => state.selfservice?.subscriptionActions?.contactCreated?.contactId
  );

  useEffect(() => {
    const selectedDuplicateContactId = useDuplicateContact?.contactId;
    if (selectedDuplicateContactId) {
      setValue(name, selectedDuplicateContactId);
    }
  }, [useDuplicateContact, setValue, name]);

  useEffect(() => {
    if (contactCreated) {
      setValue(name, contactCreated);
      clearErrors(name);
    }
  }, [contactCreated, name, clearErrors, setValue]);

  const contactOptions = getContactOptions(contacts, canAddNewContacts).map(option => ({
    ...option,
    id: option.value,
  }));

  const selectedContact = contacts?.find(({ contactId }) => contactId === getValues(name));

  const validateContact = (value: string) => {
    if (!value || (createNewDialog === true && value === CREATE_NEW_CONTACT_OPTION_VALUE)) {
      return t.VPVR(fieldCantBeEmptyMsg);
    }
    return undefined;
  };

  const onSelectContact = (evt: HTMLLIElement) => {
    const newValue = evt.dataset.value || '';
    onChangeContact?.(newValue);
    if (newValue === CREATE_NEW_CONTACT_OPTION_VALUE && createNewDialog) {
      dispatch(
        showDialog({
          billingAccountContactIdRef: name,
          type: DialogType.ADD_CONTACT,
        })
      );
      setError(name, {
        type: 'custom',
        message: t.VPVR(fieldCantBeEmptyMsg),
      });
    }
  };

  const isValueAvailable =
    (selectedContact?.contactType === ContactType.PERSON && getValues(name)) ||
    getValues(name) === CREATE_NEW_CONTACT_OPTION_VALUE;
  const addNewContact = canAddNewContacts ? CREATE_NEW_CONTACT_OPTION_VALUE : '';
  const addNewOrEmptyIfPlaceHolderSet = placeHolderText ? '' : addNewContact;

  // If no selected value, We show 'Add new' as default value, unless placeholder is set
  const selectedOrAddNewContact = isValueAvailable ? getValues(name) : addNewOrEmptyIfPlaceHolderSet;

  // Our test setup doesn't seem to like periods in IDs, so we replace them with _
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: { value: true, message: t.VPVR(fieldCantBeEmptyMsg) }, validate: validateContact }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <>
          <div className="ds-input--labelarea">
            <label className={`ds-input--labelarea-label ${labelClass}`} htmlFor={name}>
              {labelText}
            </label>
            <ContactDropdownTooltip tooltipText={tooltipText} />
          </div>
          <CL.Combobox
            name={name}
            className={className}
            disabled={disabled}
            id={id ?? name.replace(/\./g, '_')}
            items={contactOptions}
            onValueSelect={item => {
              // Pressing esc calls onValueSelect without an item, so we have to
              // have this check here...
              if (item) {
                onSelectContact(item);
                const selectedValue = item.dataset.value;
                onChange(selectedValue);
              }
            }}
            selectedValue={selectedOrAddNewContact}
            i18n_combobox_buttonAriaLabel={labelText}
            i18n_combobox_errorMessage={error?.message}
            i18n_combobox_placeholderText={placeHolderText}
          />
        </>
      )}
    />
  );
};
