import * as CL from '@design-system/component-library';
import { retrievingContentMsg, t } from '../../common/i18n/index.js';

import './Loading.scss';

interface LoadingProps {
  big?: boolean;
  bottomPadding?: 0 | 1 | 2 | 4;
  topPadding?: 0 | 1 | 2 | 4;
  text?: boolean;
}

export const Loading = ({ big, bottomPadding = 0, text, topPadding = 4 }: LoadingProps) => (
  <div
    className={`of-loading ${
      text ? '' : 'ds-justify-content--center'
    } of-loading--p-t-${topPadding} of-loading--p-b-${bottomPadding}`}
  >
    <CL.LoadingSpinner i18n_loadingspinner_ariaText={t.FLKQ(retrievingContentMsg)} size={big ? 'xl' : 's'} />
    {text && (
      <span aria-hidden="true" className="ds-padding-left--3">
        {t.FLKQ(retrievingContentMsg)}
      </span>
    )}
  </div>
);
