import * as CL from '@design-system/component-library';
import { formatSum } from '../../common/utils/priceUtils.js';

import './PaidAmountSummary.scss';

export const PaidAmountSummary = ({ amount, summaryText }: { amount: number; summaryText: string }) => {
  return (
    <CL.Grid>
      <CL.GridRow className="of-paid-amount-summary">
        <CL.GridCol className="ds-no-gutter" colWidthS={6} colWidthM={3}>
          <div className="ds-h3">{summaryText}</div>
        </CL.GridCol>
        <CL.GridCol className="ds-no-gutter" colWidthS={6} colWidthM={3}>
          <div className="ds-h3 of-paid-amount-summary__amount">{formatSum(amount)}</div>
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};
