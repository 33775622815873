import * as CL from '@design-system/component-library';
import { CustomerServiceCallLink } from '../../components/CustomerServicePhoneCallLink/CustomerServiceCallLink.js';
import { authValidate } from '../../selfservice/actions/index.js';
import { customerServiceNumberPriceMsg, t } from '../../common/i18n/index.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const ExcavatorSVG = () => (
  <svg
    className="ds-margin-top--6"
    style={{ width: '100%', height: 'auto' }}
    width="555"
    height="420"
    viewBox="0 0 555 420"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M431.233 21.5913L323.673 47.48L326.622 59.7336L434.182 33.8449L431.233 21.5913Z" fill="#D2D2D6" />
    <path d="M369.211 53.4501L299.655 70.2555L300.355 48.7819L364.309 33.377L369.211 53.4501Z" fill="#171C43" />
    <path
      d="M389.283 190.227C389.283 190.227 403.054 190.227 414.958 190.227C426.862 190.227 429.429 194.895 429.429 204.698C429.429 214.501 429.429 233.408 429.429 244.845C429.429 256.282 421.26 266.552 407.722 266.552C394.184 266.552 331.397 266.552 331.397 266.552L338.633 250.213H358.473L389.283 190.227Z"
      fill="#70707A"
    />
    <path
      d="M462.575 324.205C309.225 429.005 257.876 429.472 215.396 410.333C143.739 378.122 192.054 257.217 128.801 278.223C33.1034 309.967 183.652 217.304 67.1813 224.306C-35.9852 230.608 -21.9809 141.68 120.632 91.7302C278.649 36.1791 215.395 190.695 311.326 186.027C392.552 182.059 475.179 112.504 537.265 151.249C574.611 174.59 554.304 261.651 462.575 324.205Z"
      fill="#CDE3FF"
    />
    <path
      d="M302.689 160.353C307.124 164.088 316.928 162.454 324.163 157.086C331.399 151.717 333.733 144.248 329.298 140.747C324.863 137.012 315.06 138.646 307.825 144.015C300.356 149.383 298.255 156.852 302.689 160.353Z"
      fill="#CDE3FF"
    />
    <path
      d="M281.916 364.116H113.862C93.0888 364.116 76.0498 347.311 76.0498 326.304C76.0498 305.531 92.8554 288.492 113.862 288.492H281.916C302.689 288.492 319.728 305.297 319.728 326.304C319.728 347.077 302.689 364.116 281.916 364.116Z"
      fill="#70707A"
    />
    <path
      d="M233.601 364.116H65.5474C44.774 364.116 27.7354 347.311 27.7354 326.304C27.7354 305.531 44.5406 288.492 65.5474 288.492H233.601C254.375 288.492 271.414 305.297 271.414 326.304C271.414 347.077 254.375 364.116 233.601 364.116Z"
      fill="#171C43"
    />
    <path
      d="M90.2867 257.216H195.32C204.657 257.216 212.126 249.747 212.126 240.41V207.733C212.126 198.397 204.657 190.928 195.32 190.928H90.2867C80.9504 190.928 73.4814 198.397 73.4814 207.733V240.41C73.4814 249.513 80.9504 257.216 90.2867 257.216Z"
      fill="#D7856C"
    />
    <path d="M263.011 275.656H69.7489L48.7422 257.216H284.018L263.011 275.656Z" fill="#171C43" />
    <path
      d="M44.5406 257.216H149.574C158.911 257.216 166.38 249.747 166.38 240.41V207.733C166.38 198.397 158.911 190.928 149.574 190.928H44.5406C35.2043 190.928 27.7354 198.397 27.7354 207.733V240.41C27.7354 249.513 35.4377 257.216 44.5406 257.216Z"
      fill="#FFAF38"
    />
    <path
      d="M417.293 0C445.068 0 460.006 9.33633 458.373 30.1097C456.739 50.883 444.135 191.161 444.135 191.161H414.258C414.258 191.161 408.19 122.773 399.554 56.2514C395.586 26.8419 397.22 0 417.293 0Z"
      fill="#FFAF38"
    />
    <path
      d="M417.292 0C415.892 0 414.725 0.233408 413.558 0.466817C435.498 2.33408 447.402 11.6704 445.768 30.1097C444.134 50.883 431.53 191.161 431.53 191.161H444.134C444.134 191.161 456.738 50.883 458.372 30.1097C460.006 9.10292 444.835 0 417.292 0Z"
      fill="#FFBF60"
    />
    <path
      d="M222.163 138.878C222.163 138.878 258.808 94.9971 267.211 83.7935C275.614 72.5899 289.151 59.0522 299.655 59.0522C310.158 59.0522 410.99 59.0522 420.327 59.0522C429.663 59.0522 431.764 73.0567 427.562 79.1254C423.361 85.194 310.392 95.6973 310.392 95.6973L271.412 154.516L222.163 138.878Z"
      fill="#FFAF38"
    />
    <path d="M175.949 257.216V138.878H222.164L248.306 210.534V257.216H175.949Z" fill="#FFAF38" />
    <path d="M273.513 138.878H222.163L248.305 210.534V257.216H299.655V210.534L273.513 138.878Z" fill="#FFBF60" />
    <path d="M188.319 219.405V151.483H213.294L235.701 212.869V236.21L188.319 219.405Z" fill="#E8E8EA" />
    <path
      d="M65.5487 351.513C79.4708 351.513 90.757 340.227 90.757 326.305C90.757 312.383 79.4708 301.097 65.5487 301.097C51.6267 301.097 40.3408 312.383 40.3408 326.305C40.3408 340.227 51.6267 351.513 65.5487 351.513Z"
      fill="#70707A"
    />
    <path
      d="M233.602 351.513C247.525 351.513 258.811 340.227 258.811 326.305C258.811 312.383 247.525 301.097 233.602 301.097C219.68 301.097 208.395 312.383 208.395 326.305C208.395 340.227 219.68 351.513 233.602 351.513Z"
      fill="#70707A"
    />
    <path d="M199.99 320.937H99.1582V331.44H199.99V320.937Z" fill="#70707A" />
    <path d="M233.601 257.216L212.595 275.656H263.011L284.018 257.216H233.601Z" fill="#70707A" />
    <path d="M264.644 151.483H240.136L260.909 212.869H287.051L264.644 151.483Z" fill="#FFF5F7" />
    <path d="M287.053 221.271H260.911V244.612H287.053V221.271Z" fill="#E8E8EA" />
    <path
      d="M423.829 38.0472C429.244 38.0472 433.633 33.6582 433.633 28.2441C433.633 22.8299 429.244 18.4409 423.829 18.4409C418.415 18.4409 414.026 22.8299 414.026 28.2441C414.026 33.6582 418.415 38.0472 423.829 38.0472Z"
      fill="#70707A"
    />
    <path
      d="M145.374 161.986H132.77V147.515C132.77 142.846 129.035 139.112 124.367 139.112V126.508C135.804 126.508 145.374 135.844 145.374 147.515V161.986Z"
      fill="#171C43"
    />
    <path d="M149.573 161.519H128.566V190.928H149.573V161.519Z" fill="#171C43" />
    <path
      d="M426.629 190.227C426.629 190.227 440.4 190.227 452.303 190.227C464.207 190.227 466.775 194.895 466.775 204.698C466.775 214.501 466.775 233.408 466.775 244.845C466.775 256.282 458.605 266.552 445.068 266.552C431.53 266.552 368.743 266.552 368.743 266.552L375.979 250.213H395.818L426.629 190.227Z"
      fill="#70707A"
    />
    <path
      d="M397.688 190.227C397.688 190.227 411.459 190.227 423.363 190.227C435.267 190.227 437.834 194.895 437.834 204.698C437.834 214.501 437.834 233.408 437.834 244.845C437.834 256.282 429.665 266.552 416.127 266.552C402.59 266.552 339.803 266.552 339.803 266.552L347.038 250.213H366.878L397.688 190.227Z"
      fill="#171C43"
    />
    <path d="M235.702 275.653H87.9541V288.491H235.702V275.653Z" fill="#70707A" />
    <path d="M48.7422 207.266V240.41" stroke="#725F36" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M61.3467 207.266V240.41" stroke="#725F36" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M73.9482 207.266V240.41" stroke="#725F36" strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" />
    <path
      d="M417.992 323.502C391.384 323.502 368.743 340.541 360.341 364.115H177.349C183.417 383.255 194.154 400.761 215.394 410.33C256.708 429.003 306.657 429.003 450.203 332.605C440.867 326.77 429.896 323.502 417.992 323.502Z"
      fill="#85DDCD"
    />
    <path
      d="M357.074 293.859C362.101 293.859 366.177 289.784 366.177 284.756C366.177 279.729 362.101 275.653 357.074 275.653C352.046 275.653 347.971 279.729 347.971 284.756C347.971 289.784 352.046 293.859 357.074 293.859Z"
      fill="#85DDCD"
    />
    <path
      d="M355.206 323.505C359.202 323.505 362.442 320.266 362.442 316.269C362.442 312.273 359.202 309.034 355.206 309.034C351.21 309.034 347.971 312.273 347.971 316.269C347.971 320.266 351.21 323.505 355.206 323.505Z"
      fill="#85DDCD"
    />
  </svg>
);

const VIKAPALVELU_PRICE = 'Puhelun hinta on 8,35 snt/puhelu + 16,69snt/min (alv. 24 %)';

const MaintenanceContent = ({ isSevere = false }: MaintenanceProps) => (
  <>
    <h1>Olemme huoltotauolla</h1>
    <p className="ds-text--lead">Teemme verkkosivuillamme huoltotöitä, mutta palaamme pian!</p>
    <p>
      Huoltotoimet eivät vaikuta <a href="https://yrityksille.elisa.fi/omaelisa">OmaElisaan</a> ja pääset kirjautumaan
      sinne normaalisti.
    </p>
    <p>
      Kiireettömissä asioissa voit tarvittaessa{' '}
      <a href="https://yrityksille.elisa.fi/ota-yhteytta">lähettää meille viestin</a> asiakas- tai vikapalveluun.
      {!isSevere && (
        <>
          {' '}
          Kiireellisissä yhteyksien tai palveluiden häiriötilanteissa voit soittaa vikapalveluumme numeroon{' '}
          <a href="tel:010804400">010 80 4400</a>, joka auttaa sinua 24/7. {VIKAPALVELU_PRICE}.
        </>
      )}
    </p>
    {isSevere && (
      <>
        <h2>Tärkeimmät puhelinnumeromme</h2>
        <p>
          <b>Yritysasiakaspalvelumme</b> auttaa sinua numerossa <CustomerServiceCallLink /> (
          {t.HQWW(customerServiceNumberPriceMsg)}).
        </p>
        <p>
          <b>Myyntipalvelumme</b> auttaa sinua löytämään tarpeisiisi sopivan ratkaisun{' '}
          <a href="tel:080004411">0800 04411</a> (ilmainen).
        </p>
        <p>
          <b>Vikapalvelumme</b> auttaa yhteyksien tai palveluiden häiriötilanteissa 24/7 numerossa{' '}
          <a href="tel:010804400">010 80 4400</a>.
          <br />
          <span className="ds-text--xs">{VIKAPALVELU_PRICE}</span>
        </p>
        <p>
          <b>Yritysgurusta</b> saat teknistä neuvontaa numerosta <a href="tel:0600900200">0600 900 200</a>.
          <br />
          <span className="ds-text--xs">Puhelun hinta 2,50€/min + pvm/mpm</span>
        </p>
      </>
    )}
  </>
);

interface MaintenanceProps {
  isSevere?: boolean;
}

export const Maintenance = (props: MaintenanceProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authValidate());
  }, [dispatch]);

  return (
    <CL.Grid className="ds-grid">
      <CL.GridRow justifyCenter>
        <CL.GridCol className="ds-grid__col" colWidth={5}>
          <ExcavatorSVG />
        </CL.GridCol>
        <CL.GridCol className="ds-grid__col" colWidth={5}>
          <MaintenanceContent {...props} />
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};
