import * as CL from '@design-system/component-library';
import { ELECTRONIC_ADDRESS_REGEX } from '../../../common/utils/validationUtils.js';
import { EOperatorSelect } from '../../../common/react-hook-form/fields/EOperatorSelect.js';
import { LanguageDropdown } from '../../../common/react-hook-form/fields/LanguageDropdown.js';
import { TextInput } from '../../../common/react-hook-form/fields/TextInput.js';
import {
  customerNumberMsg,
  deliveryMethodMsg,
  eInvoiceMsg,
  eInvoicingAddressMsg,
  eInvoicingOperatorMsg,
  invoiceLanguageMsg,
  invoicingDetailsMsg,
  t,
} from '../../../common/i18n/index.js';
import { getLanguageDisplayValue } from '../../../common/utils/languageUtils.js';
import { useFormContext } from 'react-hook-form';
import { validateCustomerNumber } from './utils.js';
import type { FormEditProps } from './EOperatorChangeRequestForm.js';

const BILL_LANGUAGE_FIELD_NAME = 'billLanguage';
const EOPERATOR_FIELD_NAME = 'eOperator';

interface BillingDetailsProps {
  requireCustomerNumber: boolean;
}

const validateEInvoiceAddress = (value: string) => {
  if (!ELECTRONIC_ADDRESS_REGEX.test(value)) {
    return t.NMXU(
      'Check the format of the e-invoicing address. The correct format begins with 0037, TE0037, or FI. The e-invoicing address may contain the letters A-Z and the numbers 0-9.'
    );
  }
  return undefined;
};

export const BillingDetails = ({ requireCustomerNumber, isEditing = true }: BillingDetailsProps & FormEditProps) => {
  const { getValues } = useFormContext();
  return (
    <>
      <h4>{t.RPMR(invoicingDetailsMsg)}</h4>
      <CL.Grid>
        {requireCustomerNumber && (
          <CL.GridRow>
            <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
              <TextInput
                name="customerNumber"
                label={t.A00L(customerNumberMsg)}
                tooltip={t.A00N(
                  'You can find your customer number on your latest invoice. If your company has more than one customer number, you can add any of them.'
                )}
                validate={validateCustomerNumber}
                readonly={!isEditing}
              />
            </CL.GridCol>
          </CL.GridRow>
        )}
        <CL.GridRow>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <TextInput
              name="deliveryMethod"
              label={t.G0QN(deliveryMethodMsg)}
              placeholder={t.IK1D(eInvoiceMsg)}
              disabled={isEditing}
              readonly={!isEditing}
            />
          </CL.GridCol>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            {isEditing ? (
              <LanguageDropdown name={BILL_LANGUAGE_FIELD_NAME} label={t.A7DR(invoiceLanguageMsg)} />
            ) : (
              <div className="ds-input">
                <div className="ds-input--labelarea">
                  <label className="ds-input--labelarea-label ">{t.A7DR(invoiceLanguageMsg)}</label>
                </div>
                <div className="ds-input--inputarea">
                  {getLanguageDisplayValue(getValues(BILL_LANGUAGE_FIELD_NAME))}
                </div>
              </div>
            )}
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            {isEditing ? (
              <EOperatorSelect name={EOPERATOR_FIELD_NAME} />
            ) : (
              <TextInput name={EOPERATOR_FIELD_NAME} label={t.WVLB(eInvoicingOperatorMsg)} readonly={true} />
            )}
          </CL.GridCol>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <TextInput
              validate={validateEInvoiceAddress}
              name="eInvoiceAddress"
              label={t.OL7B(eInvoicingAddressMsg)}
              hint={t.VXVC('EDI identifier')}
              required={true}
              readonly={!isEditing}
            />
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
    </>
  );
};
