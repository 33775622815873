import * as CL from '@design-system/component-library';
import { OfferBadge as OfferBadgeSchema } from '../../generated/api/models.js';
import type { BadgeColor } from '@design-system/component-library';

import './OfferBadge.scss';

interface OfferBadgeProps {
  className?: string;
  offerBadge?: OfferBadgeSchema;
}

export const OfferBadge = (props: OfferBadgeProps) => {
  if (!props.offerBadge) {
    return null;
  }
  const { text, type } = props.offerBadge;

  const getBadgeColor = (badgeType: string): BadgeColor => {
    switch (badgeType) {
      case OfferBadgeSchema.TypeEnum.SALE:
        return 'warning';
      case OfferBadgeSchema.TypeEnum.SPECIAL:
        return 'primary';
      case OfferBadgeSchema.TypeEnum.OUT_OF_STOCK:
        return 'secondary';
      default:
        return 'secondary';
    }
  };

  return (
    <div className={props.className}>
      <CL.Badge className={`ds-badge--type-${type.toLowerCase()}`} color={getBadgeColor(type)} text={text} />
    </div>
  );
};
