import { ReportItem } from '../common/ReportItem.js';
import { ReportType, exportDataFromURI, toBinary } from '../common/reportsUtil.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { elisaDevicesServiceMsg, t } from '../../../common/i18n/index.js';
import { loadEppReport } from '../../../selfservice/actions/reportsActions.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../../selfservice/common/store.js';

export interface EppReportProps {
  heading: string;
  description: string;
  itemClass: string;
  type: ReportType;
}

const EppReport = ({ heading, description, type, itemClass }: EppReportProps) => {
  const dispatch = useDispatch();
  const { report, loading, reportType } = useSelector(
    (state: State) => ({
      report: state.selfservice?.reports?.eppReport?.report,
      reportType: state.selfservice?.reports?.eppReport?.reportType,
      loading: state.selfservice?.reports?.eppReport?.loading || false,
    }),
    deepEqual
  );

  useEffect(() => {
    if (report && reportType === type) {
      switch (reportType) {
        case ReportType.EPP_REDEEM_REPORT:
          exportDataFromURI(toBinary(report), 'EppRedeemReport.xls');
          break;
        case ReportType.EPP_SERVICE_REPORT:
          exportDataFromURI(toBinary(report), 'EppServiceAndRecyclementReport.xls');
          break;
      }
    }
  }, [report, reportType, type]);

  return (
    <ReportItem
      itemClass={itemClass}
      heading={heading}
      description={description}
      onClick={() => dispatch(loadEppReport(type))}
      loading={loading && reportType === type}
    />
  );
};

export const EppServiceAndRecyclementReport = () => {
  return (
    <EppReport
      itemClass="ea-picto--maintenance"
      heading={t.TRE5(elisaDevicesServiceMsg)}
      description={t.LDP8(
        'This report covers the Elisa Device Service support cases, redeems and fees for unreturned devices'
      )}
      type={ReportType.EPP_SERVICE_REPORT}
    />
  );
};

export const EppRedeemReport = () => {
  return (
    <EppReport
      itemClass="ea-picto--phone"
      heading={t.QDPY('Redeemed devices')}
      description={t.XK6A('This report includes your company´s redeemed EPP devices')}
      type={ReportType.EPP_REDEEM_REPORT}
    />
  );
};
