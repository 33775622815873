import { ContactOrPurposeSelectGroup } from './ContactOrPurposeSelectGroup.js';
import { CreateOrSelectContactForm } from './CreateOrSelectContactForm.js';
import { PurposeOfUseForm } from './PurposeOfUseForm.js';
import { SelectGroupItem } from '../../SelectGroup/SelectGroupItem.js';
import { SelectedPurposeOfUseOrContact } from '../../../common/enums.js';
import { purposeOfUseMsg, t, userTextMsg } from '../../../common/i18n/index.js';
import { resetField } from '../../../common/formik/index.js';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import type { Contact } from '../../../generated/api/contact.js';
import type { ContactOrPurposeOfUseFormValues } from '../ContactsOrPurposeOfUseUtils.js';

interface ContactOrPurposeOfUseSelectorProps {
  headerLevel?: 'h3' | 'h4' | 'h5';
  id: string;
  isEmailAndPhoneRequired?: boolean;
  required?: boolean;
  formattedContacts?: Contact[];
  onChangeContact?: (selectedContactId?: string) => void;
  onlyPurposeOfUse?: boolean;
}

export const ContactOrPurposeOfUseSelector = ({
  formattedContacts,
  isEmailAndPhoneRequired,
  onChangeContact,
  required,
  onlyPurposeOfUse,
}: ContactOrPurposeOfUseSelectorProps) => {
  const { values, setFieldValue, setFieldTouched, setFieldError } = useFormikContext<ContactOrPurposeOfUseFormValues>();

  useEffect(() => {
    if (values.selectionTab === SelectedPurposeOfUseOrContact.CONTACT) {
      resetField('purposeOfUse', '', { setFieldValue, setFieldTouched, setFieldError });
    } else if (values.selectionTab === SelectedPurposeOfUseOrContact.PURPOSEOFUSE) {
      ['selectContact', 'firstName', 'lastName', 'phoneNumber', 'email'].forEach(fieldId =>
        resetField(fieldId, '', { setFieldValue, setFieldTouched, setFieldError })
      );
    }
    if (onChangeContact) {
      onChangeContact(undefined);
    }
  }, [values.selectionTab, setFieldValue, setFieldTouched, setFieldError]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  const headingText = onlyPurposeOfUse ? t.NKJ8('User or purpose of use') : t.WPXQ('Select user or purpose of use');

  const formComponent = !onlyPurposeOfUse ? (
    <ContactOrPurposeSelectGroup
      className="ds-font-weight--600 ds-padding--0 ds-padding-bottom--2 of-contact-or-purpose-of-use__select-group"
      name="selectionTab"
      selectedItem={values.selectionTab}
    >
      <SelectGroupItem title={t.LUZM(userTextMsg)} id={SelectedPurposeOfUseOrContact.CONTACT}>
        <CreateOrSelectContactForm
          contacts={formattedContacts}
          isEmailAndPhoneRequired={isEmailAndPhoneRequired}
          onChangeContact={onChangeContact}
          required={required}
        />
      </SelectGroupItem>
      <SelectGroupItem title={t.Y8KX(purposeOfUseMsg)} id={SelectedPurposeOfUseOrContact.PURPOSEOFUSE}>
        <PurposeOfUseForm required={required} />
      </SelectGroupItem>
    </ContactOrPurposeSelectGroup>
  ) : (
    <PurposeOfUseForm required={required} />
  );

  return (
    <>
      <h5 className="ds-margin-bottom--2 ds-margin-top--3">{headingText}</h5>
      <p className="ds-margin-vertical--3">
        {t.PV9I(
          'By adding user information, you can manage products and services easier in the OmaElisa Users view. Select the purpose of use only when it involves, for example, a group of users.'
        )}
      </p>
      {formComponent}
    </>
  );
};
