import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type { GenericError } from '../../generated/api/models.js';
import type { ReportType } from '../../components/Reports/common/reportsUtil.js';

export interface LoadEppReportAction extends CrudAction<TypeKeys.LOAD_EPP_REPORT> {
  reportType: ReportType;
}

export const loadEppReport = (reportType: ReportType): LoadEppReportAction => ({
  id: generateActionId(),
  type: TypeKeys.LOAD_EPP_REPORT,
  reportType,
});

export interface LoadEppReportFulfilledAction extends Action {
  report: string;
  reportType: string;
  type: TypeKeys.LOAD_EPP_REPORT_FULFILLED;
}

export const loadEppReportFulfilled = (report: string, reportType: string): LoadEppReportFulfilledAction => {
  return {
    report,
    reportType,
    type: TypeKeys.LOAD_EPP_REPORT_FULFILLED,
  };
};

export type LoadEppReportFailedAction = ErrorAction<TypeKeys.LOAD_EPP_REPORT_FAILED>;

export const loadEppReportFailed: ErrorActionCreator<TypeKeys.LOAD_EPP_REPORT_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): LoadEppReportFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.LOAD_EPP_REPORT_FAILED,
  };
};

export type ReportsActionTypes = LoadEppReportAction | LoadEppReportFulfilledAction | LoadEppReportFailedAction;
