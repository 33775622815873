import * as CL from '@design-system/component-library';
import { OrderSummary, OrderSummaryType } from '../OrderSummary/OrderSummary.js';
import { TurbonappiBreadCrumbs } from './TurbonappiBreadCrumbs.js';
import { TurbonappiOffer } from '../../generated/api/turbonappiOffer.js';
import { VAT_AS_DECIMAL, getVatAsString } from '../../common/utils/taxUtils.js';
import { dayMsg, monthMsg, subscriptionMsg, t, vatPercentageMsg, weekMsg } from '../../common/i18n/index.js';
import { getTurbonappiCheckoutEvent } from './turbonappiAnalyticsUtils.js';
import { getTurbonappiSubscriptionData } from './turbonappiSubscriptionCardData.js';
import { pushToDataLayer } from '../../common/analytics.js';
import { useCallback, useEffect, useState } from 'react';
import { useTurbonappiContext } from './TurbonappiContextProvider.js';
import type { RowItem } from '../OrderSummary/OrderSummaryUtils.js';
import type { TotalSumsItem } from '../../common/utils/commercialProductUtils.js';
import type { TurbonappiData } from './TurbonappiContextProvider.js';
import type { TurbonappiProps } from '../SubscriptionCard/SubscriptionCard.js';

import './TurbonappiOrderPayment.scss';

interface TurbonappiOfferInfo {
  offerText: string;
  dueDate: string;
}

const getDateAfterDays = (days: number) => {
  const today = new Date();
  const futureDate = new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
  const year = futureDate.getFullYear();
  const month = String(futureDate.getMonth() + 1).padStart(2, '0');
  const day = String(futureDate.getDate()).padStart(2, '0');
  return `${day}.${month}.${year}`;
};

const getOfferDetails = (offer?: TurbonappiOffer): TurbonappiOfferInfo => {
  switch (offer) {
    case TurbonappiOffer.DAY:
      return { offerText: `Turbonappi 1000 M ${t.GZ1X(dayMsg)}`, dueDate: getDateAfterDays(1) };
    case TurbonappiOffer.WEEK:
      return { offerText: `Turbonappi 600 M ${t.VY6Q(weekMsg)}`, dueDate: getDateAfterDays(7) };
    case TurbonappiOffer.MONTH:
      return { offerText: `Turbonappi 300 M ${t.ZB7A(monthMsg)}`, dueDate: getDateAfterDays(31) };
    default:
      throw new Error(`Cannot get offer details, because offer "${offer}" is unknown`);
  }
};

const SubscriptionSummary = ({
  turbonappiSubscription,
  turbonappiData,
}: {
  turbonappiSubscription: TurbonappiProps;
  turbonappiData?: TurbonappiData;
}) => (
  <>
    <div>
      <h2 className="ds-h2">{t.KDIV('Order details')}</h2>
    </div>
    <div className="of-turbonappi-order-payment__breakdown">
      <div className="of-turbonappi-order-payment__icon--sim-new" />
      <div className="of-turbonappi-order-payment__breakdown__text-container">
        <div className="of-turbonappi-order-payment__breakdown__text-container__header">
          {turbonappiSubscription.turbonappiFullName}
        </div>
        <div>
          <span className="of-turbonappi-order-payment__breakdown__text-container__price">
            {`${turbonappiSubscription?.turbonappiPrice} ${turbonappiSubscription?.priceUnit}`}
          </span>
          <span>
            {t.T07E('Expires: ')} {getOfferDetails(turbonappiData?.offer).dueDate}
          </span>
        </div>
        <div>
          <span>
            {t.P674(subscriptionMsg)}: {turbonappiData?.msisdn}
          </span>
          <span>
            {t.W1RX('Total price', turbonappiSubscription?.turbonappiPrice)} € (
            {t.A0OJ(vatPercentageMsg, getVatAsString())})
          </span>
        </div>
        <div>
          <span>
            {t.RZXC('Customer email:')} {turbonappiData?.email}
          </span>
        </div>
      </div>
    </div>
  </>
);

const ReadConfirmation = ({
  readConfirmed,
  onReadConfirmed,
}: {
  readConfirmed: boolean;
  onReadConfirmed: (confirmed: boolean) => void;
}) => (
  <div className="ds-padding-top--3 of-turbonappi-order-payment__read-confirmation">
    <CL.Checkbox
      className="ds-color--blue of-turbonappi-order-payment__read-confirmation__elements"
      checked={readConfirmed}
      onChange={() => onReadConfirmed(!readConfirmed)}
    />

    <span className="of-turbonappi-order-payment__read-confirmation__elements">
      {t.Q5XU('I have read and agree to the')}
      <CL.Link
        linkHref="https://yrityksille.elisa.fi/ohje/elisan-sopimusehdot-yritysasiakkaille"
        linkTarget="_blank"
        className="of-turbonappi-order-payment__read-confirmation__link"
      >
        &nbsp;{t.JIMY('Terms of Service')}
      </CL.Link>
    </span>
  </div>
);

const SubmitButton = ({
  submitDisabled,
  onSubmit,
  submitting,
}: {
  submitDisabled: boolean;
  onSubmit: () => void;
  submitting: boolean;
}) => (
  <div className="ds-margin-bottom--3">
    <CL.Button
      type="submit"
      className="ds-margin-left--2"
      disabled={submitDisabled}
      onClick={onSubmit}
      loading={submitting}
    >
      {t.LUW8('Confirm and pay')}
    </CL.Button>
  </div>
);

const getOrderSummaryRowItem = (turbonappiSubscription: TurbonappiProps): RowItem => {
  return {
    name: turbonappiSubscription.turbonappiFullName,
    key: turbonappiSubscription.turbonappiFullName,
    amount: '1',
    onetimeCharge: turbonappiSubscription?.turbonappiPrice,
    monthlyCharge: '0',
  };
};

const getTotalSumsItem = (turbonappiSubscription: TurbonappiProps): TotalSumsItem => {
  const turbonappiCharge = Math.round(parseFloat(turbonappiSubscription.turbonappiPrice.replace(',', '.')) * 100);

  return {
    totalOneTimeCharge: turbonappiCharge,
    totalMonthlyRecurringCharge: 0,
    totalFixedTermCharge: turbonappiCharge,
    totalMonthlyRecurringChargeVat: 0,
    // We want to show only two decimals in OrderSummary
    totalOneTimeChargeVat: Math.round(turbonappiCharge * VAT_AS_DECIMAL),
  };
};

const MainHeader = () => (
  <h1 className="of-turbonappi-order-payment__main-header ds-h1">
    {t.P7MS('Great, it is possible to update your speed!')}
  </h1>
);

export const TurbonappiOrderPayment = () => {
  const { turbonappiData, payTurbonappiSubscription } = useTurbonappiContext();
  const [readConfirmed, setReadConfirmed] = useState(false);

  const onClick = useCallback(() => setReadConfirmed(!readConfirmed), [readConfirmed]);
  const turbonappiSubscription = getTurbonappiSubscriptionData(turbonappiData?.offer);

  useEffect(() => {
    pushToDataLayer(getTurbonappiCheckoutEvent(turbonappiData?.offer));
  }, [turbonappiData?.offer]);

  return (
    <>
      <TurbonappiBreadCrumbs additionalCrumb={true} />
      <div className="of-turbonappi-order-payment">
        <div className="of-turbonappi-order-payment__details">
          <MainHeader />
          <SubscriptionSummary turbonappiSubscription={turbonappiSubscription} turbonappiData={turbonappiData} />
          <hr />
          <OrderSummary
            rowItem={getOrderSummaryRowItem(turbonappiSubscription)}
            summaryType={OrderSummaryType.DETAILS_CLOSED}
            priceIncludesVAT={true}
            totalSumsItem={getTotalSumsItem(turbonappiSubscription)}
          />
          <ReadConfirmation onReadConfirmed={onClick} readConfirmed={readConfirmed} />
          <hr />
          <SubmitButton
            onSubmit={() => payTurbonappiSubscription()}
            submitting={Boolean(turbonappiData?.isPaymentLoading)}
            submitDisabled={!readConfirmed}
          />
        </div>
      </div>
    </>
  );
};
