import * as CL from '@design-system/component-library';
import { useState } from 'react';
import classNames from 'classnames';

import './Multiselector.scss';

export interface MultiselectorItem {
  valueRef?: string;
  displayValue: (() => string | JSX.Element) | JSX.Element | string;
  afterLabelItem?: JSX.Element;
  checked?: boolean;
  disabled?: boolean;
}

const getMultiselectorItemByType = (
  item: MultiselectorItem,
  index: number,
  onClickItem: (item: MultiselectorItem, checked: boolean) => void,
  checkedItemIndex: number,
  chainCheckboxSelection = false,
  isLastItem = false,
  hoveredItemIndex?: number,
  setHoveredItemIndex?: (index: number | undefined) => void,
  disableAll = false
) => {
  const checked = chainCheckboxSelection ? checkedItemIndex >= index : item.checked;
  const disabled = disableAll || item.disabled;
  return (
    <div
      className={`item__content ${item.afterLabelItem ? 'item__content--with-after-label-item' : ''}`}
      key="checkbox"
    >
      <div
        className={classNames(
          'ds-checkbox',
          hoveredItemIndex !== undefined && hoveredItemIndex > index ? 'ds-checkbox--highlight' : '',
          disabled ? 'ds-checkbox--disabled' : ''
        )}
      >
        <input
          type="checkbox"
          id={`multiselector_${item.valueRef || ''}_${index}_checkbox`}
          name={`multiselector_${item.valueRef || ''}_${index}_checkbox`}
          checked={checked}
          onChange={() => {
            onClickItem(item, !checked);
          }}
          onMouseEnter={() => setHoveredItemIndex && setHoveredItemIndex(index)}
          onMouseLeave={() => setHoveredItemIndex && setHoveredItemIndex(undefined)}
          disabled={disabled}
        />
        <label className="ds-checkbox__label" htmlFor={`multiselector_${item.valueRef || ''}_${index}_checkbox`}>
          <div className="of-multiselector__checkbox-container">
            <CL.Icon icon="check" className="ds-checkbox__icon" />
            {!isLastItem && (
              <div
                className={`${chainCheckboxSelection ? 'of-multiselector__checkbox-spacer' : ''} ${
                  chainCheckboxSelection && checkedItemIndex > index
                    ? 'of-multiselector__checkbox-spacer--selected'
                    : ''
                }`}
              />
            )}
          </div>
          <div>{item.displayValue instanceof Function ? item.displayValue() : item.displayValue}</div>
        </label>
      </div>
      {item.afterLabelItem && <div className="item__after-label-item">{item.afterLabelItem}</div>}
    </div>
  );
};

export interface MultiselectorProps {
  chainCheckboxSelection: boolean;
  items: MultiselectorItem[];
  additionalItems?: MultiselectorItem[];
  onItemChange?: (item: MultiselectorItem) => void;
  onAdditionalItemChange?: (item: MultiselectorItem) => void;
  disableAll?: boolean;
}

export const Multiselector = ({
  additionalItems,
  chainCheckboxSelection,
  items,
  onAdditionalItemChange,
  onItemChange,
  disableAll,
}: MultiselectorProps) => {
  const [hoveredItemIndex, setHoveredItemIndex] = useState<number | undefined>(undefined);

  const onClickAdditionalItem = (clickedItem: MultiselectorItem, checked: boolean) => {
    if (onAdditionalItemChange) {
      onAdditionalItemChange({ ...clickedItem, checked });
    }
  };

  const onClickItem = (clickedItem: MultiselectorItem, checked: boolean) => {
    if (onItemChange) {
      onItemChange({ ...clickedItem, checked });
    }
  };

  const reverseCheckedItemIndex = items
    .slice()
    .reverse()
    .findIndex(item => Boolean(item.checked));
  const checkedItemIndex =
    reverseCheckedItemIndex >= 0 ? items.length - 1 - reverseCheckedItemIndex : reverseCheckedItemIndex;

  return (
    <div className="of-multiselector">
      <div className="of-multiselector__items">
        {items.map((item, index) => [
          <div className={`item ${index === items.length - 1 ? 'item--last' : ''}`} key={index}>
            {getMultiselectorItemByType(
              item,
              index,
              onClickItem,
              checkedItemIndex,
              chainCheckboxSelection,
              index === items.length - 1,
              hoveredItemIndex,
              setHoveredItemIndex,
              disableAll
            )}
          </div>,
        ])}
        {additionalItems && <div className="divider" />}
        {additionalItems &&
          additionalItems.map((item, index) => (
            <div className="item item--last" key={index}>
              {getMultiselectorItemByType(
                item,
                index,
                onClickAdditionalItem,
                additionalItems.findIndex(additionalItem => Boolean(additionalItem.checked)),
                undefined,
                true,
                undefined,
                undefined,
                disableAll
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
