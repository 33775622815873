import { Icon } from '../Icon/Icon.js';
import { Input } from '../Input/Input.js';
import { POSTAL_CODE_REGEX } from '../../common/utils/validationUtils.js';
import { postalCodeMsg, t } from '../../common/i18n/index.js';

import './PostalCodeInput.scss';

interface ValidationErrorsMap {
  [s: string]: string;
}

export interface PostalCodeInputProps {
  postalCode?: string;
  setPostalCode: (postalCode: string) => void;
  validationErrors?: ValidationErrorsMap;
  setValidationErrors: (errors?: ValidationErrorsMap) => void;
}

// By default, input type=number allows these characters as well. They do not make
// sense in the context of entering a postal code.
const DISALLOWED_CHARACTERS = ['e', 'E', '+', '-', '.', ','];

/**
 * TODO consider syncing this component with src/common/react-hook-form/fields/PostalCode.tsx
 * so that there would be only one implementation for PostalCode
 */
export const PostalCodeInput = (props: PostalCodeInputProps) => {
  const { postalCode, setPostalCode, validationErrors, setValidationErrors } = props;
  const isValidPostalCode = postalCode ? POSTAL_CODE_REGEX.test(postalCode) : false;

  return (
    <div className="ds-position--relative of-postal-code__wrapper">
      <Input
        data-testid="postal-code-input"
        error={validationErrors?.postalCode}
        placeholder={t.RUAW(postalCodeMsg)}
        label={t.RUAW(postalCodeMsg)}
        name="postalCode"
        onKeyDown={event => {
          if (DISALLOWED_CHARACTERS.includes(event.key)) {
            event.preventDefault();
          }
        }}
        onChange={event => {
          setValidationErrors(undefined);
          setPostalCode(event?.currentTarget?.value);
        }}
        inputMode="numeric"
        type="number"
        min={0}
        max={99999}
        value={postalCode}
      />
      {isValidPostalCode && (
        <span className="of-postal-code__check-icon ds-position--absolute ds-background-color--white">
          <Icon icon="check" color="green-600" />
        </span>
      )}
    </div>
  );
};
