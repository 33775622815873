import { t } from '../../../common/i18n/index.js';
import type { ConfigState } from '../../../common/types/states.js';

export const GuidanceMessage = ({ config }: { config: ConfigState }) => (
  <div className="of-report-list__guide-notification-container">
    <div className="ea-context-notification">
      <div className="ea-context-notification__text">
        <strong>{t.THY8(`Can't find your order from the report?`)}</strong>
        {t.LSXT(' Some of the order information can still be found ')}
        <a href={`${config.classicSiteUrl}/tilaushistoria`} rel="noopener noreferrer">
          {t.IZZ6('from the old OmaElisa')}
        </a>
        .
      </div>
    </div>
  </div>
);
