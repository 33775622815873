import * as File from '../File/index.js';
import { BreadCrumbsWithTitle } from '../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { CaseHistory } from '../CaseHistory/CaseHistory.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { ExpandableText } from '../ExpandableText/ExpandableText.js';
import { Link, generatePath } from 'react-router-dom';
import { Loading } from '../Loading/index.js';
import { SupportCase } from '../../generated/api/models.js';
import { SupportCaseFields } from '../../common/enums.js';
import {
  categoryMsg,
  descriptionMsg,
  omaElisaForCompaniesMsg,
  resolutionMsg,
  stepsMsg,
  supportCaseMsg,
  supportCasesMsg,
  t,
} from '../../common/i18n/index.js';
import { featureTranslation, getColumnTranslation, statusData } from '../../common/utils/supportCaseUtils.js';
import { formatTimeStampToYYYYMMDDHHmm, formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { SupportCaseDataBundle, SupportCaseHeader, SupportCaseHistory } from '../../generated/api/models.js';

import './CaseDetails.scss';

export interface SupportCaseDescriptionBlockProps {
  heading: string;
  paragraphContent: string | JSX.Element;
  paragraphClassName?: string;
}

export interface UpdatedInfoProps {
  timestamp?: number;
  updatedBy?: string;
}

export interface CaseDetailsProps {
  supportCase: SupportCaseDataBundle;
  history?: SupportCaseHistory[];
  authenticatedUser?: AuthenticatedUserState;
}

const SupportCaseDescriptionBlock = ({
  heading,
  paragraphContent,
  paragraphClassName,
}: SupportCaseDescriptionBlockProps) => (
  <div className="description-block">
    <h4>{heading}</h4>
    <div className={`ds-margin-top--1 ${paragraphClassName || ''}`}>{paragraphContent}</div>
  </div>
);

export const CaseDetails = ({ supportCase, history, authenticatedUser }: CaseDetailsProps) => {
  const SHOW_DESCRIPTION_LINES = 3;
  const SHOW_RESOLUTION_LINES = 25;
  const CHAR_LIMIT_FOR_EXPANDABLE_TEXT_DESCRIPTION = 200;
  const CHAR_LIMIT_FOR_EXPANDABLE_TEXT_RESOLUTION = 1500;

  const UpdatedInfo = ({ timestamp, updatedBy }: UpdatedInfoProps) => {
    return (
      <p className="ds-text--s ds-margin-top--2">
        {timestamp && <span>{formatTimestampToDDMMYYYY(timestamp)}</span>}
        {updatedBy && <span>| {updatedBy}</span>}
      </p>
    );
  };

  const getFullName = (sc: SupportCaseHeader) => {
    return `${sc?.firstName} ${sc?.lastName}`;
  };

  const supportCaseCreatedAt = supportCase?.createdAt ? formatTimeStampToYYYYMMDDHHmm(supportCase?.createdAt) : '';

  const SupportCaseHistorySteps = () => {
    return (
      <div className="stepper-block">
        <h2>{t.U2PF(stepsMsg)}</h2>
        <CaseHistory supportCaseHistory={history || []} supportCaseCreatedAt={supportCaseCreatedAt} />
      </div>
    );
  };

  const isMovePaymentDateCase: boolean =
    (supportCase?.featureType === 'Lisää maksuaikaa' || supportCase?.featureType === 'Move the payment date') ?? false;

  return (
    <DetailsWrapper
      classes={['of-case-details']}
      id="case-details"
      detailsTop={
        <BreadCrumbsWithTitle
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.OEZW(supportCasesMsg), path: paths.SUPPORT_CASES },
            { name: supportCase.supportCaseDisplayId },
          ]}
        />
      }
      heading={supportCase.supportCaseDisplayId}
      headingTop={t.EKG9(supportCaseMsg)}
      headingBottom={getCompanyName(authenticatedUser, supportCase?.accountMasterId)}
      content={
        <div>
          <div className="top-grid ">
            <div className="top-grid-block">
              <h4>{getColumnTranslation(SupportCaseFields.STATUS)}</h4>
              <p id="supportCaseStatus" className="ds-margin-top--1">
                <span
                  className={
                    supportCase?.status === SupportCase.StatusEnum.IN_PROGRESS
                      ? `status-data of-background-color--${statusData(supportCase?.status).color}`
                      : `status-data ds-background-color--${statusData(supportCase?.status).color}`
                  }
                >
                  {statusData(supportCase?.status).text}
                </span>
              </p>
            </div>
            <div className="top-grid-block">
              <h4>{getColumnTranslation(SupportCaseFields.SUPPORT_CASE_DISPLAY_ID)}</h4>
              <p id="supportCaseDisplayId" className="ds-margin-top--1">
                {supportCase?.supportCaseDisplayId}
              </p>
            </div>
            <div className="top-grid-block">
              <h4>{getColumnTranslation(SupportCaseFields.FEATURE)}</h4>
              <p id="supportCaseFeature" className="ds-margin-top--1">
                {featureTranslation(supportCase?.feature)}
              </p>
            </div>
            <div className="top-grid-block">
              <h4>{getColumnTranslation(SupportCaseFields.CREATED_AT)}</h4>
              <p id="supportCaseCreatedAt" className="ds-margin-top--1">
                {supportCaseCreatedAt}
              </p>
            </div>
            <div className="top-grid-block">
              <h4>{getColumnTranslation(SupportCaseFields.CONTACT_DETAILS)}</h4>
              <p className="ds-margin-top--1">{getFullName(supportCase)}</p>
            </div>
            <div className="top-grid-block">
              <h4>{getColumnTranslation(SupportCaseFields.LAST_MODIFIED)}</h4>
              <p id="supportCaseLastModified" className="ds-margin-top--1">
                {supportCase?.lastModified ? formatTimeStampToYYYYMMDDHHmm(supportCase?.lastModified) : ''}
              </p>
            </div>
          </div>
          <div className="ds-reset bottom-grid">
            <div className="description">
              <h2>{t.HBW3(descriptionMsg)}</h2>
              {supportCase?.featureType && (
                <>
                  <SupportCaseDescriptionBlock
                    heading={t.CV5H(categoryMsg)}
                    paragraphContent={supportCase.featureType}
                  />
                  <SupportCaseDescriptionBlock
                    heading={getColumnTranslation(SupportCaseFields.INVOICE_NUMBER)}
                    paragraphContent={
                      <>
                        {supportCase?.invoiceNumber && (
                          <Link to={generatePath(paths.INVOICE, { invoiceId: supportCase?.invoiceNumber })}>
                            {supportCase?.invoiceNumber}
                          </Link>
                        )}
                      </>
                    }
                  />
                </>
              )}
              <SupportCaseDescriptionBlock
                heading={
                  isMovePaymentDateCase ? t.HBW3(descriptionMsg) : getColumnTranslation(SupportCaseFields.DESCRIPTION)
                }
                paragraphContent={
                  supportCase?.resolution !== undefined &&
                  supportCase?.description !== undefined &&
                  supportCase.description.length > CHAR_LIMIT_FOR_EXPANDABLE_TEXT_DESCRIPTION ? (
                    <ExpandableText text={supportCase.description || ''} lineClamp={SHOW_DESCRIPTION_LINES} />
                  ) : (
                    <>{supportCase.description}</>
                  )
                }
                paragraphClassName="content-with-line-breaks ds-margin-bottom--0"
              />
              {supportCase.attachments && <File.List fileNames={supportCase.attachments} />}
              <UpdatedInfo
                timestamp={supportCase?.createdAt}
                updatedBy={!isMovePaymentDateCase ? getFullName(supportCase) : undefined}
              />
              {supportCase?.resolution && (
                <>
                  <hr className="ds-margin-bottom--5 ds-margin-top--5" />
                  <h2>{t.YOW5(resolutionMsg)}</h2>
                  <SupportCaseDescriptionBlock
                    heading={t.YP6T('Message from customer service')}
                    paragraphContent={
                      supportCase.resolution.length > CHAR_LIMIT_FOR_EXPANDABLE_TEXT_RESOLUTION ? (
                        <ExpandableText text={supportCase.resolution} lineClamp={SHOW_RESOLUTION_LINES} />
                      ) : (
                        <div>{supportCase.resolution}</div>
                      )
                    }
                    paragraphClassName="content-with-line-breaks ds-margin-bottom--0"
                  />
                  <UpdatedInfo timestamp={history?.slice(-1).pop()?.modifiedDate} />
                </>
              )}
            </div>
            {history ? <SupportCaseHistorySteps /> : <Loading />}
          </div>
        </div>
      }
    />
  );
};
