import * as CL from '@design-system/component-library';
import { Grid } from '../Grid/Grid.js';
import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { InfiniteScrollCompositeList } from '../InfiniteScrollCompositeList/index.js';
import { NavigationMenu, NavigationMenuItem } from '../NavigationMenu/NavigationMenu.js';
import {
  ProductGridFilter,
  ProductGridFilterHeader,
  createFilters,
  filterFn,
} from '../ProductGrid/ProductGridFilter.js';
import { ProductGridSearch, searchFn } from '../ProductGrid/ProductGridSearch.js';
import { ProductGridSort, SortType, sortFn } from '../ProductGrid/ProductGridSort.js';
import { StickyFooter } from '../StickyFooter/index.js';
import { SupportedModelCategories, SupportedModelCategory } from '../../common/utils/catalogUtils.js';
import {
  accessoriesMsg,
  computersMsg,
  continueMsg,
  deviceListsMsg,
  deviceMsg,
  networkingHardwareMsg,
  newDeviceListMsg,
  omaElisaForCompaniesMsg,
  pcsMsg,
  phonesMsg,
  priceMsg,
  t,
  tabletsMsg,
} from '../../common/i18n/index.js';
import { createImageUrl } from '../../common/utils/domUtils.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getPeriodicPriceAsText } from '../SubscriptionDetails/subscriptionDetailsCommon.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useEffect, useMemo, useState } from 'react';
import type { CatalogProduct } from '../../common/utils/catalogUtils.js';
import type { Filters } from '../ProductGrid/ProductGridFilter.js';
import type { InfiniteScrollCompositeListProps } from '../InfiniteScrollCompositeList/index.js';

import './CatalogProductsSelection.scss';

export const getProductCategoryDetails = (category: SupportedModelCategory) => {
  switch (category) {
    case SupportedModelCategory.PHONE:
      return {
        displayText: t.E9IF(phonesMsg),
        showAdditionalInfo: true,
        iconClass: 'phone',
      };
    case SupportedModelCategory.ACCESSORIES:
      return {
        displayText: t.J954(accessoriesMsg),
        showAdditionalInfo: false,
        iconClass: 'headphones',
      };
    case SupportedModelCategory.COMPUTERS:
      return {
        displayText: t.MCW3(computersMsg),
        showAdditionalInfo: false,
        iconClass: 'laptop',
      };
    case SupportedModelCategory.NETWORK_EQUIPMENT:
      return {
        displayText: t.DMM5(networkingHardwareMsg),
        showAdditionalInfo: false,
        iconClass: 'wirelessdevice',
      };
    case SupportedModelCategory.TABLET:
      return {
        displayText: t.XS42(tabletsMsg),
        showAdditionalInfo: false,
        iconClass: 'tablet',
      };
  }
};

interface ProductsSelectionListObject {
  category: string;
  code: string;
  name: JSX.Element;
  startingPrice: JSX.Element;
}

export interface CatalogProductsSelectionProps {
  products: Record<string, Array<CatalogProduct>>;
  preSelectedProducts?: Record<string, Array<CatalogProduct>>;
  onSetProductsForCatalog: (products: Record<string, Array<CatalogProduct>>) => void;
  contractPeriod?: number;
  onClickProductCategory: (category: string) => void;
  imagesBaseUrl: string;
}

// prices can be either oneTime or monthlyRecurring at a time
const getStartingPrice = (product: CatalogProduct): string =>
  formatSum(product.oneTimeCharge) || `Alk. ${getPeriodicPriceAsText(product.monthlyRecurringCharge)}`;

const getItems = (
  category: string,
  categoryProducts: Array<CatalogProduct>,
  displayItemSize: number,
  imagesBaseUrl: string
) => {
  if (categoryProducts) {
    const items = categoryProducts.map(product => ({
      name: (
        <CL.Grid>
          <CL.GridRow>
            <CL.GridCol colsXS={4} colsM={2} colsL={3}>
              <div className="of-catalog-products-selection__images">
                <img
                  alt={product.manufacturer}
                  {...createImageUrl({
                    format: 'png',
                    height: 150,
                    imagesBaseUrl,
                    originalUrl: product.imageUrl,
                    scalingFactors: [0.5, 1, 1.5],
                    width: 150,
                  })}
                />
              </div>
            </CL.GridCol>
            <CL.GridCol
              colsXS={4}
              colsM={4}
              colsXL={8}
              className="ds-display--flex ds-align-items--center of-products-selection__name"
            >
              {`${product.manufacturer} ${product.name}`}
            </CL.GridCol>
          </CL.GridRow>
        </CL.Grid>
      ),
      startingPrice: <span>{getStartingPrice(product)}</span>,
      code: product.code,
      category,
    }));
    return {
      total: categoryProducts.length,
      items: items.slice(0, displayItemSize),
      codes: items.map(item => item.code),
    };
  }
  return { total: 0, items: undefined, codes: [] };
};

const getSelectedProducts = (
  category: string,
  products: Record<string, Array<CatalogProduct>>,
  selectedCategoryProducts: Array<string>
) => (products[category] || []).filter(product => selectedCategoryProducts.includes(product.code));

const displayItemBatchSize = 30;

const CategorySummary = ({ selectedProducts }: { selectedProducts: Record<SupportedModelCategory, string[]> }) => (
  <div className="ds-text--s">
    {Object.entries(selectedProducts)
      .filter(([_, products]) => products.length)
      .map(
        ([category, products]: [SupportedModelCategory, string[]]) =>
          `${getProductCategoryDetails(category).displayText}: ${products.length} ${t.B3MG(pcsMsg)}`
      )
      .join(', ')}
  </div>
);

export const CatalogProductsSelection = (props: CatalogProductsSelectionProps) => {
  const {
    onSetProductsForCatalog,
    products,
    preSelectedProducts,
    contractPeriod,
    onClickProductCategory,
    imagesBaseUrl,
  } = props;
  const [selectedProducts, setSelectedProducts] = useState<Record<string, Array<string>>>(
    preSelectedProducts
      ? Object.entries(preSelectedProducts).reduce((obj, [key]) => {
          return { ...obj, [key]: preSelectedProducts[key].map(values => values.code) };
        }, {})
      : {}
  );
  const [searchString, setSearchString] = useState('');
  const [productCategory, setProductCategory] = useState<SupportedModelCategory>(SupportedModelCategory.PHONE);

  const [sortType, setSortType] = useState(SortType.ORDER_NEWEST);
  const [productItems, setProductItems] = useState<Record<string, Array<CatalogProduct>>>({});
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [displayItemSize, setDisplayItemSize] = useState(displayItemBatchSize);
  const [selectedFilters, setSelectedFilters] = useState<Record<string, Filters>>({});

  useEffect(() => {
    if (products[productCategory] && !selectedFilters[productCategory]) {
      setSelectedFilters({
        ...selectedFilters,
        [productCategory]: createFilters(products[productCategory], productCategory),
      });
    }
  }, [products, productCategory, selectedFilters]);

  useEffect(() => {
    if (!selectedFilters[productCategory]) {
      return;
    }
    setProductItems({
      ...productItems,
      [productCategory]: products[productCategory]
        .filter(searchFn(searchString))
        .filter(filterFn(selectedFilters[productCategory]))
        .sort(sortFn(sortType)),
    });
  }, [searchString, sortType, selectedFilters, productCategory]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  const { total, items, codes } = useMemo(
    () => getItems(productCategory, productItems[productCategory], displayItemSize, imagesBaseUrl),
    [productCategory, productItems, displayItemSize, imagesBaseUrl]
  );

  const removeCodesFromProductsArray = (codesArray: string[], productsArray: string[]) => {
    return productsArray.filter(product => !codesArray.includes(product));
  };

  const addCodesToProductsArray = (codesArray: string[], productsArray?: string[]) => {
    return productsArray ? [...productsArray, ...codesArray] : codesArray;
  };

  const onClickSelectAll = (selected: boolean) => {
    setSelectedProducts(previousSelectedProducts => ({
      ...previousSelectedProducts,
      [productCategory]: selected
        ? addCodesToProductsArray(codes, previousSelectedProducts[productCategory])
        : removeCodesFromProductsArray(codes, previousSelectedProducts[productCategory]),
    }));
  };

  const productsSelectionListProps: InfiniteScrollCompositeListProps<ProductsSelectionListObject> = {
    columns: [
      {
        columnId: 'name',
        heading: t.TPVQ(deviceMsg),
        ref: 'name',
        wide: true,
        headingHideOnMobile: true,
      },
      {
        columnId: 'startingPrice',
        heading: t.V72N(priceMsg),
        ref: 'startingPrice',
        refFormatNumber: getPeriodicPriceAsText,
      },
    ],
    items: items,
    getRowId: (item: ProductsSelectionListObject) => item.code,
    checkListLayout: true,
    onClickCheckedRow: (rowId, item, checked) => {
      checked
        ? setSelectedProducts({
            ...selectedProducts,
            [productCategory]: [...(selectedProducts[productCategory] || []), rowId],
          })
        : setSelectedProducts({
            ...selectedProducts,
            [productCategory]: selectedProducts[productCategory].filter(productId => productId !== rowId),
          });
    },
    onClickSelectAll: onClickSelectAll,
    selectAll:
      (items &&
        items.length > 0 &&
        items.every(
          item => selectedProducts[productCategory] && selectedProducts[productCategory].includes(item.code)
        )) ||
      false,
    showSelectAll: true,
    isItemChecked: item => selectedProducts[item.category]?.includes(item.code) || false,
    emptyListText: t.HUVS('No matching products found'),
    enableInfiniteScroll: items && total !== 0 ? items.length < total : false,
    onInfiniteScrollLoadMore: () => {
      setDisplayItemSize(displayItemSize + displayItemBatchSize);
    },
  };

  const totalSelectedItems = Object.values(selectedProducts)
    .map(category => category.length)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return (
    <div className="of-catalog-products-selection">
      <HeroHeading
        center={true}
        breadCrumbPaths={[
          { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
          { name: t.COBB(deviceListsMsg), path: paths.COMPANY_INFO_CATALOGS },
          { name: t.MPFC(newDeviceListMsg) },
        ]}
        heroHeadingType={HeroHeadingType.BLANK}
        title={getProductCategoryDetails(productCategory).displayText}
        subtitle={
          contractPeriod
            ? `${t.V3UP('Device contract period')} ${contractPeriod} ${t.UGPA('month(s). Quoted prices VAT 0%')}`
            : undefined
        }
      />
      <div className="of-catalog-products-selection__border-bottom">
        <Grid>
          <div className="ea-snapper-fluid-container ds-margin-vertical--3">
            <NavigationMenu>
              {SupportedModelCategories.map(category => {
                const { displayText, iconClass } = getProductCategoryDetails(category);
                return (
                  <NavigationMenuItem
                    key={category}
                    id={category}
                    linkType="clickable"
                    icon={<span className={`ea-icon ea-icon--medium ea-icon--${iconClass}`} />}
                    label={displayText}
                    onClick={() => {
                      setProductCategory(category);
                      onClickProductCategory(category);
                    }}
                  />
                );
              })}
            </NavigationMenu>
          </div>
        </Grid>
      </div>
      <CL.Grid>
        <CL.GridRow justifyCenter>
          <CL.GridCol colsXS={4} colsM={2} colsL={5} colsXL={6}>
            <ProductGridSearch onChange={setSearchString} value={searchString} />
          </CL.GridCol>
          <CL.GridCol colsXS={4} colsM={2} colsL={3} colsXL={2}>
            <div className="of-catalog-products-selection__filter-header">
              <ProductGridFilterHeader onChange={setFiltersExpanded} value={filtersExpanded} />
            </div>
          </CL.GridCol>
          <CL.GridCol colsXS={4} colsM={2} colsL={3} colsXL={2}>
            <div className="of-catalog-products-selection__sort">
              <ProductGridSort onChange={setSortType} />
            </div>
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
      {filtersExpanded && selectedFilters[productCategory] && (
        <Grid>
          <div className="of-catalog-products-selection__filter-content">
            <ProductGridFilter
              filters={selectedFilters[productCategory]}
              onChange={(updatedFilters: Filters) => {
                setSelectedFilters({
                  ...selectedFilters,
                  [productCategory]: updatedFilters,
                });
              }}
              onClose={() => setFiltersExpanded(false)}
            />
          </div>
        </Grid>
      )}
      <Grid>
        <InfiniteScrollCompositeList {...productsSelectionListProps}> </InfiniteScrollCompositeList>
      </Grid>
      <StickyFooter active={Object.values(selectedProducts).some(value => value.length > 0)}>
        <CL.Grid>
          <CL.GridRow justifyCenter className="ds-margin-vertical--3">
            <CL.GridCol colWidthXS={2} colWidthS={3} colWidthL={5}>
              <div>
                <div>
                  {t.Z875('Selected in total')}:{' '}
                  <b>
                    {totalSelectedItems} {t.B3MG(pcsMsg)}
                  </b>
                </div>
                <CategorySummary selectedProducts={selectedProducts} />
              </div>
            </CL.GridCol>
            <CL.GridCol colWidthXS={2} colWidthS={3} colWidthL={5} className="ds-text-align--right">
              <CL.Button
                color="linkblue"
                size="l"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  onSetProductsForCatalog(
                    Object.entries(selectedProducts).reduce((obj, [key, value]) => {
                      if (value.length) {
                        return { ...obj, [key]: getSelectedProducts(key, products, value) };
                      } else {
                        return { ...obj };
                      }
                    }, {})
                  );
                }}
              >
                {t.I62A(continueMsg)}
              </CL.Button>
            </CL.GridCol>
          </CL.GridRow>
        </CL.Grid>
      </StickyFooter>
    </div>
  );
};
