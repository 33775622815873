import * as CL from '@design-system/component-library';
import { BillingAccountInfo } from './BillingAccountInfo.js';
import {
  CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE,
  billingAccountNonVisibleFields,
  getDefaultBillingAccountId,
  isBillingAccountInSfdc,
} from '../../common/utils/billingAccountUtils.js';
import { CreateBillingAccount } from '../CreateBillingAccount/CreateBillingAccount.js';
import { Loading } from '../Loading/index.js';
import {
  billingAccountExtensionNameMsg,
  billingAccountNameMsg,
  billingDetailsMsg,
  loadingWithThreePeriodsMsg,
  t,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getHighlightedFields } from '../../common/utils/searchFieldUtils.js';
import { loadBillingAccounts } from '../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer } from 'react';
import type { BillingAccountOrErrorSupplier, CommonError } from '../../common/types/errors.js';
import type { BillingAccountSearchResponse } from '../../generated/api/models.js';
import type { SearchItemProps } from '@design-system/component-library';
import type { State } from '../../selfservice/common/store.js';

import './AddOrSelectBillingAccounts.scss';

export interface AddOrSelectBillingAccountsProps {
  addNewBA: boolean;
  getBillingAccountSaveValuesFn: (getSelectedBAData: () => SelectedBAData) => void;
  isSaving: boolean;
  setRefCallBack?: (key: string, ref: HTMLElement | null) => void;
  title?: string;
  additionalInformation?: JSX.Element;
  selectedBaId?: string;
  disabled?: boolean;
  createBillingAccountErrors?: CommonError[];
}

interface BASelectedBase {
  error?: string;
}
interface ExistingBASelected extends BASelectedBase {
  selectedBaId: string;
  prepareNewBillingAccountSaveValues?: never;
}
interface NewBASelected extends BASelectedBase {
  selectedBaId?: never;
  prepareNewBillingAccountSaveValues?: BillingAccountOrErrorSupplier;
}

export type SelectedBAData = ExistingBASelected | NewBASelected;

enum ActionType {
  UPDATE_SELECTED_BILLING_ACCOUNT = 'UPDATE_SELECTED_BILLING_ACCOUNT',
  ON_BLUR_WITH_ERROR = 'ON_BLUR_WITH_ERROR',
  ON_SEARCH = 'ON_SEARCH',
  ON_SET_PREPARE_NEW_BILLING_ACCOUNT_SAVE_VALUES = 'ON_SET_PREPARE_NEW_BILLING_ACCOUNT_SAVE_VALUES',
}
interface UpdateSelectedBillingAccountAction {
  type: ActionType.UPDATE_SELECTED_BILLING_ACCOUNT;
  newBaId: string;
}

interface OnBlurWithErrorAction {
  type: ActionType.ON_BLUR_WITH_ERROR;
  error: string;
}

interface OnSearchAction {
  type: ActionType.ON_SEARCH;
  searchText: string;
}

interface OnSetPrepareNewBillingAccountSaveValuesAction {
  type: ActionType.ON_SET_PREPARE_NEW_BILLING_ACCOUNT_SAVE_VALUES;
  fn: BillingAccountOrErrorSupplier;
}

type AddOrSelectBillingAccountsAction =
  | UpdateSelectedBillingAccountAction
  | OnBlurWithErrorAction
  | OnSearchAction
  | OnSetPrepareNewBillingAccountSaveValuesAction;

interface AddOrSelectBillingAccountsState {
  searchText?: string;
  error?: string;
  selectedBillingAccountId?: string;
  prepareNewBillingAccountSaveValues?: BillingAccountOrErrorSupplier;
}

// Ignore these fields, since they are always shown with BA in the dropdown.
const baSearchIgnoreFields = ['billingAccountName', 'billingAccountExtensionName'];

const filterMatchedFields = (matchedFields?: Array<string>) => {
  return matchedFields?.filter(field => !baSearchIgnoreFields.includes(field));
};
export const getBillingAccountListItems = (
  addNewBA: boolean,
  baHeaders?: Array<BillingAccountSearchResponse>,
  searchText?: string
) => {
  const baItems: Array<SearchItemProps> = [];
  if (addNewBA) {
    baItems.push({
      label: t.NCIJ('+ New billing agreement'),
      html: (
        <div className="of-billing-account-search-result">
          <span>{t.NCIJ('+ New billing agreement')}</span>
        </div>
      ),
      value: CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE,
    });
  }
  if (!baHeaders) {
    baItems.push({
      label: t.P1Y9(loadingWithThreePeriodsMsg),
      html: (
        <div className="of-billing-account-search-result">
          <h4 className="ea-h4">{t.P1Y9(loadingWithThreePeriodsMsg)}</h4>
        </div>
      ),
      value: 'Loading',
    });
  } else {
    baHeaders.forEach(baHeader => {
      const matchedFields = filterMatchedFields(baHeader.matchedFields);
      const { billingAccountDisplayId, payerName, payerNameExtension } = baHeader.result;
      const label = [billingAccountDisplayId, payerName, payerNameExtension].filter(Boolean).join(' ');
      baItems.push({
        label,
        html: (
          <div className="of-billing-account-search-result" key={baHeader.result.billingAccountDisplayId}>
            <h4 className="ea-h4">{label}</h4>
            <div>
              {t.RH6T(billingAccountNameMsg)}: {baHeader.result.billingAccountName}
            </div>
            {baHeader.result.billingAccountExtensionName && (
              <div>
                {t.KUTS(billingAccountExtensionNameMsg)}: {baHeader.result.billingAccountExtensionName}
              </div>
            )}
            <div>
              {matchedFields &&
                searchText &&
                getHighlightedFields(baHeader.result, matchedFields, searchText, billingAccountNonVisibleFields)}
            </div>
          </div>
        ),
        value: baHeader.result.billingAccountId || '',
      });
    });
  }
  return baItems;
};

const getSelectedBAData = (
  error?: string,
  prepareNewBillingAccountSaveValues?: BillingAccountOrErrorSupplier,
  selectedBillingAccountId?: string
): SelectedBAData => {
  if (prepareNewBillingAccountSaveValues) {
    return {
      error,
      prepareNewBillingAccountSaveValues,
    };
  } else {
    return {
      error,
      selectedBaId: selectedBillingAccountId,
    };
  }
};

const reducer = (
  state: AddOrSelectBillingAccountsState,
  action: AddOrSelectBillingAccountsAction
): AddOrSelectBillingAccountsState => {
  switch (action.type) {
    case ActionType.UPDATE_SELECTED_BILLING_ACCOUNT:
      return {
        ...state,
        selectedBillingAccountId: action.newBaId,
        searchText: undefined,
        error: undefined,
        prepareNewBillingAccountSaveValues:
          action.newBaId !== CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE
            ? undefined
            : state.prepareNewBillingAccountSaveValues,
      };
    case ActionType.ON_BLUR_WITH_ERROR:
      return {
        ...state,
        error: action.error,
        selectedBillingAccountId: undefined,
      };
    case ActionType.ON_SEARCH:
      return {
        ...state,
        searchText: action.searchText,
      };
    case ActionType.ON_SET_PREPARE_NEW_BILLING_ACCOUNT_SAVE_VALUES:
      return {
        ...state,
        prepareNewBillingAccountSaveValues: action.fn,
      };
    default:
      return { ...state };
  }
};

export const AddOrSelectBillingAccounts = ({
  additionalInformation,
  addNewBA,
  getBillingAccountSaveValuesFn,
  isSaving,
  setRefCallBack,
  title,
  selectedBaId,
  disabled,
  createBillingAccountErrors,
}: AddOrSelectBillingAccountsProps) => {
  const billingAccounts = useSelector((state: State) => state.selfservice?.billingAccounts, deepEqual);
  const companyName = useSelector((state: State) => state.selfservice?.companyInfo?.companyName);
  const defaultSelectedBaId =
    selectedBaId ||
    getDefaultBillingAccountId(
      billingAccounts?.searchResults?.map(header => header.result!)?.filter(isBillingAccountInSfdc)
    );
  const [baState, componentDispatch] = useReducer(reducer, { selectedBillingAccountId: defaultSelectedBaId });
  const { error, prepareNewBillingAccountSaveValues, searchText, selectedBillingAccountId } = baState;
  const dispatch = useDispatch();

  useEffect(() => {
    getBillingAccountSaveValuesFn(() =>
      getSelectedBAData(error, prepareNewBillingAccountSaveValues, selectedBillingAccountId)
    );
  }, [error, selectedBillingAccountId, prepareNewBillingAccountSaveValues]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedBillingAccountId) {
      const chosenBillingAccountDisplayId = billingAccounts?.searchResults?.find(
        item => item.result.billingAccountId === selectedBillingAccountId
      )?.result.billingAccountDisplayId;
      if (chosenBillingAccountDisplayId) {
        dispatch(loadBillingAccounts(chosenBillingAccountDisplayId));
      }
    }
  }, [selectedBillingAccountId]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  const selectedBillingAccount = billingAccounts?.items?.find(ba => ba.billingAccountId === selectedBillingAccountId);
  const filteredBillingAccounts = billingAccounts?.searchResults?.filter(item => isBillingAccountInSfdc(item.result));
  return (
    <div className="of-add-or-select-billing-accounts-v2">
      <h3 className="ds-margin-bottom--1 ds-margin-top--0">{title ?? t.RPMR(billingDetailsMsg)}</h3>
      {additionalInformation}
      <div>
        <CL.Search
          id="billingAccounts"
          items={getBillingAccountListItems(addNewBA, filteredBillingAccounts, searchText)}
          label={t.HVS2('Selected billing account')}
          onSearch={newSearchValue => {
            dispatch(loadBillingAccounts(undefined, undefined, newSearchValue, undefined, undefined, undefined));
            componentDispatch({
              type: ActionType.ON_SEARCH,
              searchText: newSearchValue,
            });
          }}
          onValueChange={evt => {
            componentDispatch({
              type: ActionType.UPDATE_SELECTED_BILLING_ACCOUNT,
              newBaId: evt.dataset.value || '',
            });
            dispatch(loadBillingAccounts(undefined, undefined, '', true));
          }}
          selectedValue={selectedBillingAccountId}
          onFocus={event => {
            event.target.select();
          }}
          onBlur={errorMsg => {
            if (errorMsg) {
              componentDispatch({
                type: ActionType.ON_BLUR_WITH_ERROR,
                error: errorMsg,
              });
            }
          }}
          ariaLabel="Click to search"
          disabled={disabled}
          className={disabled ? 'of-add-or-select-billing-account--disabled' : ''}
        />
        {selectedBillingAccountId === CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE && (
          <CreateBillingAccount
            commonErrors={createBillingAccountErrors || billingAccounts?.errors}
            getPrepareSaveValuesFn={(prepareSaveValues: BillingAccountOrErrorSupplier) => {
              componentDispatch({
                type: ActionType.ON_SET_PREPARE_NEW_BILLING_ACCOUNT_SAVE_VALUES,
                fn: prepareSaveValues,
              });
            }}
            payerName={companyName!}
            isSaving={isSaving}
            setRefCallback={setRefCallBack}
          />
        )}
        {selectedBillingAccountId !== CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE && (
          <div key={selectedBillingAccountId}>
            {selectedBillingAccount ? <BillingAccountInfo billingAccount={selectedBillingAccount} /> : <Loading />}
          </div>
        )}
      </div>
    </div>
  );
};
