import * as CL from '@design-system/component-library';
import { Email, Name, PhoneNumber, TextInput } from '../../common/react-hook-form/fields/index.js';
import { FIRST_NAME_MAX_LENGTH } from '../../common/utils/nameUtils.js';
import { FormProvider, useForm } from 'react-hook-form';
import { SquareTradeErrorModal, hasCartForbiddenSquareTradeAddOn } from '../SquareTradeErrorModal/index.js';
import {
  companyMsg,
  emailMsg,
  firstNameMsg,
  lastNameMsg,
  mobilePhoneNumberMsg,
  nextMsg,
  t,
} from '../../common/i18n/index.js';
import { companyNameValidation } from '../../common/utils/validationUtils.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { useEffect, useState } from 'react';
import type { ActionsHistory } from '../../selfservice/common/store.js';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { ContactPerson } from '../../generated/api/models.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';

import './DeviceCheckoutContactInfo.scss';

export interface DeviceCheckoutContactInfoProps {
  cartItems: ShoppingCartItemForCheckout[];
  companyName: string;
  contactInfo: ContactPerson;
  user?: AuthenticatedUserState & ActionsHistory;
  saveContactInfoAndOpenNextSection: (contactInfo: ContactPerson, validationErrors?: { [s: string]: string }) => void;
}

export const DeviceCheckoutContactInfo = ({
  cartItems,
  companyName,
  contactInfo,
  user,
  saveContactInfoAndOpenNextSection,
}: DeviceCheckoutContactInfoProps) => {
  const userDetailsObj = {
    // Company Name will be always present when an admin is ordering the device, so no defensive checks are needed.
    companyName,
    email: contactInfo?.email ?? '',
    firstName: contactInfo?.firstName ?? '',
    lastName: contactInfo?.lastName ?? '',
    phoneNumber: contactInfo?.phoneNumber ?? '',
  };
  const methods = useForm<ContactPerson>({ values: userDetailsObj });
  const { handleSubmit, getValues } = methods;
  const [editContactInfo, setEditContactInfo] = useState(false);
  const [showSquareTradeErrorModal, setShowSquareTradeErrorModal] = useState(false);

  useEffect(() => {
    setShowSquareTradeErrorModal(hasCartForbiddenSquareTradeAddOn(cartItems, user));
  }, [cartItems, user]);

  useEffect(() => {
    if (
      contactInfo &&
      (!contactInfo.email || !contactInfo.phoneNumber || !contactInfo.lastName || !contactInfo.firstName)
    ) {
      setEditContactInfo(true);
    }
  }, [contactInfo]);

  const saveUpdatedContactInfoAndOpenNextSection = (updatedContactInfo: ContactPerson) => {
    saveContactInfoAndOpenNextSection({ ...contactInfo, ...updatedContactInfo });
    return true;
  };

  return (
    <div className="of-contact-info-container">
      {editContactInfo ? (
        <FormProvider {...methods}>
          <CL.Grid className="ds-margin-top--4">
            <CL.GridRow>
              <CL.GridCol colWidthXS={4} colWidthS={4} colWidthL={5} className="ds-padding-left--0 ds-margin-right--4">
                <TextInput
                  label={t.KJTS(companyMsg)}
                  maxLength={companyNameValidation.maxLength}
                  name="companyName"
                  required
                  disabled
                />
                <Name label={t.AIK7(firstNameMsg)} maxLength={FIRST_NAME_MAX_LENGTH} name="firstName" />
                <Name label={t.Y41S(lastNameMsg)} maxLength={100} name="lastName" />
              </CL.GridCol>
              <CL.GridCol colWidthXS={4} colWidthS={4} colWidthL={5} className="ds-padding-left--0">
                <PhoneNumber label={t.FRYN(mobilePhoneNumberMsg)} name="phoneNumber" />
                <Email label={t.XVHQ(emailMsg)} name="email" required disabled={false} type="text" />
              </CL.GridCol>
            </CL.GridRow>
            <CL.GridRow className="ds-padding-top--5">
              <CL.Button
                size="l"
                className="of-next-button"
                onClick={handleSubmit(() => saveUpdatedContactInfoAndOpenNextSection(getValues()))}
              >
                {t.F0MY(nextMsg).toUpperCase()}
              </CL.Button>
            </CL.GridRow>
          </CL.Grid>
        </FormProvider>
      ) : (
        <div>
          <div className="ds-padding-bottom--3">
            <div>
              <strong>{companyName}</strong>
            </div>
            <div>{`${contactInfo.firstName} ${contactInfo.lastName}`}</div>
            <div>{formatPhoneNumber(contactInfo.phoneNumber || '')}</div>
            <div>{contactInfo.email}</div>
            {/* TODO: Cleanup edit ContactInfo functions etc, If not required in future. */}
          </div>
          <CL.Button size="l" className="of-next-button" onClick={() => saveContactInfoAndOpenNextSection(contactInfo)}>
            {t.F0MY(nextMsg).toUpperCase()}
          </CL.Button>
        </div>
      )}
      <SquareTradeErrorModal isOpen={showSquareTradeErrorModal} onClose={() => setShowSquareTradeErrorModal(false)} />
    </div>
  );
};
